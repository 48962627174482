import "./profile.scss";
import PInfo from "./PInfo";
import PSecurity from "./PSecurity";
import Kyc from "./Kyc";
import LoginHistory from "./PLoginHistory";
import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { ItemNames, setStorage } from "../../utils/RSS";
import { getUserInfo } from "../../utils/Api";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import HeaderMobile from "../home/HeaderMobile";
import BottomNavigationMobile from "../home/BottomNavigationMobile";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Profile() {
  const [tab, setTab] = useState(0);
  const [profileInfo, setProfileInfo] = useState({});
  const location = useLocation()

  useEffect(() => {
    if (location.hash !== "") {
      const number = location.hash.replace('#', '')
      setTab(parseInt(number))
    }
  }, [])

  useEffect(() => {
    if (profileInfo.kyc_status == null) {
      const storeProfileInfo = (profile) => {
        setStorage(ItemNames.USERNAME, profile.username);
        setStorage(ItemNames.EMAILFORMAT, profile.format_email);
        setStorage(ItemNames.AIRDROP, profile.airdrop_count);
        setStorage(ItemNames.EMAILSTATUS, profile.is_email_confirmed);
        setStorage(ItemNames.TFASTATUS, profile.tfa_status);
        setStorage(ItemNames.KYCSTATUS, profile.kyc_status);
        setStorage(ItemNames.REFCOUNT, profile.referral_count);
        setStorage(ItemNames.UID, profile.uid);
        setStorage(ItemNames.TRADEPWDSTATUS, profile.is_trading);
        setStorage(ItemNames.REFNUMBER, profile.refer_id);
        setStorage(ItemNames.SECURITYLVL, profile.security_level);
      };

      getUserInfo(
        (e) => {
          setProfileInfo(e);
          storeProfileInfo(e);
        },
        (e) => { }
      );
    }
  }, []);
  const { t } = useTranslation();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);

  if (pageWidth > 768) {
    return (
      <div className=" profile-main">
        <div className="container">
          <div className="col tab-main">
            <Tabs
              value={tab}
              onChange={(event, number) => {
                setTab(number)
                window.location.replace(`#${number}`)
              }}
              scrollButtons
              variant="scrollable">
              <Tab label={t("Profile_profile")} {...a11yProps(0)} />
              <Tab label={t("Profile_security")} {...a11yProps(1)} />
              <Tab label={t("Profile_KYC")} {...a11yProps(2)} />
              <Tab label={t("Profile_history")} {...a11yProps(3)} />
            </Tabs>

            <TabPanel value={tab} index={0}>
              <PInfo profileInfo={profileInfo} />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <PSecurity profile={profileInfo} />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Kyc profile={profileInfo} />
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <LoginHistory />
            </TabPanel>
          </div>
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className=" profile-main">
          <div className="container" style={{ marginTop: 100, marginBottom: 100 }}>
            <div className="col tab-main">
              <Tabs
                value={tab}
                onChange={(event, number) => {
                  setTab(number)
                  window.location.replace(`#${number}`)
                }}
                scrollButtons
                variant="scrollable">
                <Tab label={t("Profile_profile")} {...a11yProps(0)} />
                <Tab label={t("Profile_security")} {...a11yProps(1)} />
                <Tab label={t("Profile_KYC")} {...a11yProps(2)} />
                <Tab label={t("Profile_history")} {...a11yProps(3)} />
              </Tabs>

              <TabPanel value={tab} index={0}>
                <PInfo profileInfo={profileInfo} />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <PSecurity profile={profileInfo} />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <Kyc profile={profileInfo} />
              </TabPanel>
              <TabPanel value={tab} index={3}>
                <LoginHistory />
              </TabPanel>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Profile;
