import * as React from "react";
import { useState, useContext } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Button, Form } from "react-bootstrap";
import "./convert.scss";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { getConvertPairs } from "../../../utils/Api";
import { ItemNames, getStorage } from "../../../utils/RSS";
import { UserContext } from "../../../contexts/providers/user";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../../home/HeaderMobile";
import BottomNavigationMobile from "../../home/BottomNavigationMobile";

function Convert() {
  const [convertPair, setConvertPair] = useState([]);
  const [firstToken, setFirstToken] = useState({});
  const [secondToken, setSecondToken] = useState({});
  const [amount, setAmount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(5);
  const [count, setCount] = useState(0);
  const [isLoading2, setIsLoading2] = useState(false);
  const [selectValue, setSelectValue] = useState(true);
  const { dispatch } = useContext(UserContext);
  const errorCatch = (error) => { };

  const handleChange = (event) => {
    setFirstToken(event.target.value);
    setSelectValue(false);
  };

  const handleSecondChange = (event) => {
    setSecondToken(event.target.value);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
    setIsDisabled(false);
  };

  useEffect(() => {
    if (convertPair.length === 0) {
      getConvertPairs(setConvertPair, errorCatch);
    }
  }, [convertPair]);

  const delayedForButton = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    setTimeout(() => {
      getConvertPairs(setConvertPair, errorCatch);
      setCount(count + 1);
      setIsLoading(false);
    }, 5000);
  };

  useEffect(() => {
    let intervalId = null;
    if (isLoading) {
      intervalId = setInterval(() => {
        setSecondsLeft(secondsLeft - 1);
      }, 1000);
    } else {
      setSecondsLeft(5);
    }
    return () => clearInterval(intervalId);
  }, [isLoading, secondsLeft]);

  useEffect(() => {
    if (secondToken.symbol !== undefined) {
      setIsDisabled(false);
    }
  }, [secondToken]);

  const confirmConvert = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      setIsLoading2(true);
      let res = await fetch(
        "https://api.easyexchangepro.com/api/v2/convert/send",
        {
          method: "POST",
          body: JSON.stringify({
            from: firstToken.symbol,
            to: "GBR",
            amount: amount,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
          },
        }
      );

      let resJson = await res.json();

      if (resJson.status === 1) {
        dispatch({
          snackColor: "success",
          snackMessage: resJson.msg,
          type: "open_snack",
        });
        dispatch({ type: "toggle_modal", content: resJson.msg });
        event.target.reset();
      } else {
        dispatch({
          snackColor: "warning",
          snackMessage: resJson.msg,
          type: "open_snack",
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading2(false);
    }
  };
  const { state } = useContext(UserContext);
  const { t } = useTranslation();

  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);

  if (pageWidth > 768) {
    return (
      <div className="convert-main">
        <div className="container convert">
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 convert-module m-4">
              <div className="row m-3">
                <h5>{t('Convert_title')}</h5>
                <Form onSubmit={delayedForButton}>
                  <div className="col">
                    <div className="col d-flex justify-content-end">
                      <h6>{t('Global_Available')} : </h6>
                      <h6>
                        {" "}
                        {firstToken.symbol !== undefined
                          ? `${parseFloat(firstToken.amount).toFixed(
                            state.currency.details[firstToken.symbol]
                              .amount_decimal || 6
                          )}`
                          : ""}
                      </h6>
                    </div>
                    <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                      <FormControl fullWidth>
                        <InputLabel
                          className="convert-texts"
                          id="first-token-select"
                        >
                          {t('Global_From')}
                        </InputLabel>
                        <Select
                          className="convert-texts"
                          labelId="first-token-select"
                          id="first-token"
                          value={firstToken}
                          label={t('Global_From')}
                          onChange={handleChange}
                        >
                          {convertPair
                            .filter((item) => item.symbol !== "GBR")
                            .map((item, index) => (
                              <MenuItem key={`convert_pair${index}`} value={item}>
                                {item.symbol}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                      <FormControl fullWidth>
                        <TextField
                          disabled={selectValue}
                          required
                          className="convert-texts"
                          InputProps={{ sx: { height: 57 } }}
                          id="inputValue"
                          label={t('Global_Amount')}
                          type="float"
                          size="medium"
                          name="inputValue"
                          onChange={handleAmountChange}
                          InputLabelProps={{
                            shrink: true,
                            className: "convert-texts",
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                      <FormControl fullWidth>
                        <InputLabel
                          className="convert-texts"
                          id="second-token-select"
                        >
                          {t('Global_To')}
                        </InputLabel>
                        <Select
                          className="convert-texts"
                          labelId="second-token-select"
                          id="second-token"
                          value={secondToken}
                          label={t('Global_To')}
                          onChange={handleSecondChange}
                        >
                          {convertPair
                            .filter((item) => item.symbol === "GBR")
                            .map((item, index) => (
                              <MenuItem key={`convert_pair${index}`} value={item}>
                                {item.symbol}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <div className="col d-flex ">
                      <h6>{t('Global_Available')} : </h6>
                      <h6>
                        {secondToken.symbol !== undefined
                          ? `${parseFloat(secondToken.amount).toFixed(
                            state.currency.details[secondToken.symbol]
                              .amount_decimal || 6
                          )}`
                          : ""}
                      </h6>
                    </div>
                    <Box sx={{ minWidth: 120 }} mt={2}>
                      <h6>
                        {firstToken.symbol} {t('Global_Price')} : {firstToken.EstimatedUSDT}
                      </h6>
                      <h6>
                        {secondToken.symbol} {t('Global_Price')} : {secondToken.EstimatedUSDT}
                      </h6>
                      <h6>
                        {t('Convert_for_each')} {firstToken.symbol} {t('Convert_receive')}:{" "}
                        {secondToken.symbol !== undefined
                          ? `${parseFloat(
                            firstToken.EstimatedUSDT / secondToken.EstimatedUSDT
                          ).toFixed(
                            state.currency.details[secondToken.symbol]
                              .amount_decimal || 6
                          )}`
                          : ""}{" "}
                        {secondToken.symbol}
                      </h6>
                    </Box>
                    {!isLoading && (
                      <Grid
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={2}
                      >
                        <Button type="submit" disabled={isDisabled}>
                          {t('Convert_calc')}
                        </Button>
                      </Grid>
                    )}
                    {isLoading && (
                      <>
                        <Box sx={{ minWidth: 120 }} mt={2}>
                          <h6>
                            {t('Convert_receive')}: ~{" "}
                            {`${(amount * firstToken.EstimatedUSDT) /
                              secondToken.EstimatedUSDT
                              }` !== undefined
                              ? `${parseFloat(
                                (amount * firstToken.EstimatedUSDT) /
                                secondToken.EstimatedUSDT
                              ).toFixed(
                                state.currency.details[secondToken.symbol]
                                  .amount_decimal || 6
                              )}`
                              : ""}
                          </h6>
                        </Box>
                        <Grid
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          mt={2}
                        >
                          <Button onClick={confirmConvert} disabled={isLoading2}>
                            {isLoading2
                              ? t('Global_Loading')
                              : `${isLoading ? `${t('Global_Confirm')} (${secondsLeft}s)` : " "
                              }`}
                          </Button>
                        </Grid>
                      </>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="convert-main mt-2">
          <div className="container convert" style={{ marginBottom: 100 }}>
            <div className="row justify-content-center">
              <div className="col-10 col-md-6 convert-module m-4">
                <div className="row m-3">
                  <h5>{t('Convert_title')}</h5>
                  <Form onSubmit={delayedForButton}>
                    <div className="col">
                      <div className="col d-flex justify-content-end">
                        <h6>{t('Global_Available')} : </h6>
                        <h6>
                          {" "}
                          {firstToken.symbol !== undefined
                            ? `${parseFloat(firstToken.amount).toFixed(
                              state.currency.details[firstToken.symbol]
                                .amount_decimal || 6
                            )}`
                            : ""}
                        </h6>
                      </div>
                      <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                        <FormControl fullWidth>
                          <InputLabel
                            className="convert-texts"
                            id="first-token-select"
                          >
                            {t('Global_From')}
                          </InputLabel>
                          <Select
                            className="convert-texts"
                            labelId="first-token-select"
                            id="first-token"
                            value={firstToken}
                            label={t('Global_From')}
                            onChange={handleChange}
                          >
                            {convertPair
                              .filter((item) => item.symbol !== "GBR")
                              .map((item, index) => (
                                <MenuItem key={`convert_pair${index}`} value={item}>
                                  {item.symbol}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                      <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                        <FormControl fullWidth>
                          <TextField
                            disabled={selectValue}
                            required
                            className="convert-texts"
                            InputProps={{ sx: { height: 57 } }}
                            id="inputValue"
                            label={t('Global_Amount')}
                            type="float"
                            size="medium"
                            name="inputValue"
                            onChange={handleAmountChange}
                            InputLabelProps={{
                              shrink: true,
                              className: "convert-texts",
                            }}
                          />
                        </FormControl>
                      </Box>
                      <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                        <FormControl fullWidth>
                          <InputLabel
                            className="convert-texts"
                            id="second-token-select"
                          >
                            {t('Global_To')}
                          </InputLabel>
                          <Select
                            className="convert-texts"
                            labelId="second-token-select"
                            id="second-token"
                            value={secondToken}
                            label={t('Global_To')}
                            onChange={handleSecondChange}
                          >
                            {convertPair
                              .filter((item) => item.symbol === "GBR")
                              .map((item, index) => (
                                <MenuItem key={`convert_pair${index}`} value={item}>
                                  {item.symbol}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                      <div className="col d-flex ">
                        <h6>{t('Global_Available')} : </h6>
                        <h6>
                          {secondToken.symbol !== undefined
                            ? `${parseFloat(secondToken.amount).toFixed(
                              state.currency.details[secondToken.symbol]
                                .amount_decimal || 6
                            )}`
                            : ""}
                        </h6>
                      </div>
                      <Box sx={{ minWidth: 120 }} mt={2}>
                        <h6>
                          {firstToken.symbol} {t('Global_Price')} : {firstToken.EstimatedUSDT}
                        </h6>
                        <h6>
                          {secondToken.symbol} {t('Global_Price')} : {secondToken.EstimatedUSDT}
                        </h6>
                        <h6>
                          {t('Convert_for_each')} {firstToken.symbol} {t('Convert_receive')}:{" "}
                          {secondToken.symbol !== undefined
                            ? `${parseFloat(
                              firstToken.EstimatedUSDT / secondToken.EstimatedUSDT
                            ).toFixed(
                              state.currency.details[secondToken.symbol]
                                .amount_decimal || 6
                            )}`
                            : ""}{" "}
                          {secondToken.symbol}
                        </h6>
                      </Box>
                      {!isLoading && (
                        <Grid
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          mt={2}
                        >
                          <Button type="submit" disabled={isDisabled}>
                            {t('Convert_calc')}
                          </Button>
                        </Grid>
                      )}
                      {isLoading && (
                        <>
                          <Box sx={{ minWidth: 120 }} mt={2}>
                            <h6>
                              {t('Convert_receive')}: ~{" "}
                              {`${(amount * firstToken.EstimatedUSDT) /
                                secondToken.EstimatedUSDT
                                }` !== undefined
                                ? `${parseFloat(
                                  (amount * firstToken.EstimatedUSDT) /
                                  secondToken.EstimatedUSDT
                                ).toFixed(
                                  state.currency.details[secondToken.symbol]
                                    .amount_decimal || 6
                                )}`
                                : ""}
                            </h6>
                          </Box>
                          <Grid
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mt={2}
                          >
                            <Button onClick={confirmConvert} disabled={isLoading2}>
                              {isLoading2
                                ? t('Global_Loading')
                                : `${isLoading ? `${t('Global_Confirm')} (${secondsLeft}s)` : " "
                                }`}
                            </Button>
                          </Grid>
                        </>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Convert;
