import React, { useContext } from "react";
import "../trade.scss";
import { useParams } from "react-router";
import { UserContext } from "../../../../contexts/providers/user";
import { useTranslation } from "react-i18next";

function TopPriceData() {
  const { tradePair } = useParams();

  const { stateIO } = useContext(UserContext);
  const { state } = useContext(UserContext);
  const tokenName = tradePair.split("_")[0];
  const colorInTrade = () => {
    if (stateIO?.details.color === "text-danger") {
      return "textdanger";
    } else if (stateIO?.details.color === "text-success") {
      return "textsuccess";
    }
  };
  const { t } = useTranslation();

  return (
    <div className="toppricedata-mainD">
      <div className="row">
        <div className="col-4 col-sx-2 pairname-topD">
          <p>{tradePair.replace("_", "/")}</p>
        </div>
        <div className="col-3 col-sx-2 toppricedataD ms-2">
          <p>
            {parseFloat(`${stateIO?.details.price}`).toFixed(
              state?.currency.details[tokenName].price_decimal || 6
            )}
          </p>
          <p className="priceusdtD">
            {parseFloat(`${stateIO?.details.price}`).toFixed(
              state?.currency.details[tokenName].price_decimal || 6
            )}
          </p>
        </div>
        <div className="col-3 col-sx-2 toppricedataD ms-4">
          <p className="data24h-title">{t('Global_24h')} {t('Global_Change')}</p>
          <p className={`change24h-data ${colorInTrade()}`}>
            {parseFloat(`${stateIO?.details.change}`).toFixed(
              state?.currency.details[tokenName].price_decimal || 6
            )}
          </p>
        </div>
        <div className="col-4 col-sx-2 toppricedataD ms-2">
          <p className="data24h-title">{t('Global_24h')} {t('Global_High')}</p>
          <p className="data24h-data">
            {parseFloat(`${stateIO?.details.high}`).toFixed(
              state?.currency.details[tokenName].price_decimal || 6
            )}
          </p>
        </div>
        <div className="col-3 col-sx-2 toppricedataD">
          <p className="data24h-title">{t('Global_24h')} {t('Global_Low')}</p>
          <p className="data24h-data">
            {parseFloat(`${stateIO?.details.low}`).toFixed(
              state?.currency.details[tokenName].price_decimal || 6
            )}
          </p>
        </div>
        <div className="col-3 col-sx-2 toppricedata-volumeD ms-4">
          <p className="data24h-title">{t('Global_24h')} {t('Global_Volume')}({tradePair.split("_")[0]})</p>
          <p className="data24h-data">
            {parseFloat(`${stateIO?.details.base_volume}`).toFixed(
              state?.currency.details[tokenName].amount_decimal || 6
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TopPriceData;
