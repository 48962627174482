import React from "react";
import HomeCarousel from "../../components/carousel/HomeCarousel";
import Imageslider from "../../components/imageslider/Imageslider";
import { Link, NavLink } from "react-router-dom";
import ScrollToTop from "../../utils/ScrollToTop";
import { useTranslation } from "react-i18next";

function HomeTop() {
  const { t } = useTranslation();
  return (
    <div className="row home-top-section  align-content-center">
      <div className="col-12 col-md-4 text-center p-0">
        <div className="top-phone-bg d-flex flex-column justify-content-center">
          <div className="move-left-phone">
            <div className="btn-phone text-center m-1">
              <a
                className="btn-top-insidephone btn"
                href="https://play.google.com/store/apps/details?id=easyexchangepro.com.easy_pro"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Home_Top_Download_From")} <br />
                {t("Home_Top_Playstore")}
              </a>
            </div>
            <div className="btn-phone text-center m-1">
              <a
                className="btn-top-insidephone2 btn"
                href="https://s3p.easyexchangepro.com/public/easy_pro_v_2.0.1.apk"
                download
              >
                <div className="apkIconDiv" id="googleapk"></div>
                <div className="apktext">{t("Home_Top_Download_APK")}</div>
              </a>
            </div>
            <div className="btn-phone text-center m-1">
              <a
                className="btn-top-insidephone btn"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="apkIconDiv" id="appleicon"></div>
                <div className="appletext">
                  {t("Home_Top_Download_From")} <br />
                  {t("Home_Top_APPstore")}
                </div>
              </a>
            </div>
            <div className="btn-phone text-center mt-5">
              <a className="btn-top-insidephone-reg btn">
                <div className="registericon2" id="registericon"></div>
                <div className="regtext">
                  <NavLink as={Link} to="/login" className="nav-link">
                    {t("Home_Top_Register_Now")}
                    <ScrollToTop />
                  </NavLink>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-8 mt-5 top-right">
        <div className="row">
          <div className="col-12 ">
            <HomeCarousel />
          </div>
          <div className="col-12 mt-5">
            <Imageslider />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeTop;
