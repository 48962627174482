import React from "react";
import ImageOne from "../../../assets/images/login-register-icon.svg";
import ImageTwo from "../../../assets/images/form-icon-g.svg";
import ImageThree from "../../../assets/images/transfer-icon-g.png";
import ImageFourth from "../../../assets/images/deposit-lock.png";
import "./easyguarantee.scss";
import { useTranslation } from "react-i18next";
function EG3steps() {
  const { t } = useTranslation();
  return (
    <div className="guarantee-steps">
      <div className="container">
        <h4 className="p-4">{t('EG_EGSteps_title')}</h4>
        <div className="row justify-content-around p-4">
          <div className="col-lg-2 text-center mb-4">
            <img
              className="img-fluid"
              style={{ maxHeight: 130 }}
              src={ImageOne}
              alt=""
            />
            <h4>{t('EG_EGSteps_Feature_1_title')}</h4>
            <p>{t('Global_Login')}{t('EG_EGSteps_Feature_1_description_1')}{t('Global_Register')}</p>
            <p>{t('EG_EGSteps_Feature_1_description_2')}</p>
          </div>
          <div className="col-lg-2 text-center mb-4">
            <img
              className="img-fluid"
              style={{ maxHeight: 130 }}
              src={ImageTwo}
              alt=""
            />
            <h4>{t('EG_EGSteps_Feature_2_title')}</h4>
            <p>{t('EG_EGSteps_Feature_2_description_1')}</p>
            <p>{t('EG_EGSteps_Feature_2_description_2')}</p>
          </div>
          <div className="col-lg-2 text-center mb-4">
            <img
              className="img-fluid pb-5"
              style={{ maxHeight: 130 }}
              src={ImageThree}
              alt=""
            />
            <h4>{t('EG_EGSteps_Feature_3_title')}</h4>
            <p>{t('EG_EGSteps_Feature_3_description_1')}</p>
            <p>{t('EG_EGSteps_Feature_3_description_2')}</p>
          </div>
          <div className="col-lg-3 text-center mb-4">
            <img
              className="img-fluid"
              style={{ maxHeight: 130 }}
              src={ImageFourth}
              alt=""
            />
            <h4>{t('EG_EGSteps_Feature_4_title')}</h4>
            <p>{t('EG_EGSteps_Feature_4_description_1')}</p>
            <p>{t('EG_EGSteps_Feature_4_description_2')}</p>
            <p>{t('EG_EGSteps_Feature_4_description_3')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EG3steps;
