import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { UserContext } from "../providers/user";
import "./dialog.scss";

function InputDialogGlobal({ closeDialog }) {
  const { state, dispatch } = useContext(UserContext);

  return (
    <>
      <Modal show={state.isOpenDialog} onHide={closeDialog} backdrop="static">
        <Modal.Header>
          <Modal.Title>{state.dialogTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={state.submitDialog}>
            <Form.Group className="mb-3">
              <Form.Control
                type="number"
                placeholder={state.dialogContent}
                autoFocus
                name={state.dialogName}
              />
            </Form.Group>
            <div className="row justify-content-around text-center">
              <div className="col">
                <Button variant="secondary" onClick={closeDialog}>
                  Close
                </Button>
              </div>
              <div className="col">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={state.isLoading}
                >
                  {state.isLoading ? "Loading" : `${state.btnText}`}
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default InputDialogGlobal;
