import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "./coininfo.scss";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../home/HeaderMobile";
import BottomNavigationMobile from "../home/BottomNavigationMobile";

function CoinInfo() {
  const { t } = useTranslation();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);
  if (pageWidth > 768) {
    return (
      <div className="coininfo-main">
        <div className="container coininfo-module">
          <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="1">AAVE</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="2">ADA</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="3">ATOM</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="4">AVAX</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="5">BNB</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="6">BTC</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="7">CAKE</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="8">DOGE</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="9">DOT</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="10">EOS</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="11">ETC</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="12">ETH</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="13">FIL</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                  <Nav.Link eventKey="14">GBR</Nav.Link>
                </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="15">LTC</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="16">MATIC</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="17">MKR</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="18">NEAR</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="19">TRX</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="20">XLM</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="21">XRP</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="22">ZEC</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="1">
                    <div
                      className="tab-pane active p-2"
                      id="aave"
                      role="tabpanel"
                    >
                      <div className="">
                        <h6 className="font-weight-bold">AAVE(Aave)</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>January 2017</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>16,000,000</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>--</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')} <span>--</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://aave.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://aave.com/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="https://etherscan.io/token/0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://etherscan.io/token/0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_AAVE')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="2">
                    <div className="tab-pane p-2" id="ada" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">ADA(Cardano)</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>2017-10-01</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>45,000,000,000</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>25,927,070,538</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}{" "}
                              <a
                                href="https://docs.cardano.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://docs.cardano.org/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://www.cardanohub.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://www.cardanohub.org/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="https://cardanoexplorer.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://cardanoexplorer.com/
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_ADA')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="3">
                    <div className="tab-pane  p-2" id="atom" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">Cosmos ATOM</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>January 2014</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span></span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>286,370,297</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}{" "}
                              <span>
                                <a href="https://cosmos.network/resources/whitepaper">
                                  PDF
                                </a>
                              </span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://cosmos.network/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://cosmos.network/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="https://atomscan.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://atomscan.com/
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_Atom')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="4">
                    <div className="tab-pane  p-2" id="avax" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">Avalanche AVAX</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>2020</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>720,000,000</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>315,001,173</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}{" "}
                              <span>https://www.avalabs.org/whitepapers</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://www.avalabs.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://www.avalabs.org/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="https://avascan.info/blockchain/x/asset/FvwEAhmxKfeiG8SnEvq42hc6whRyY3EFYAvebMqDNDGCgxN5Z"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://avascan.info/blockchain/x/asset/FvwEAhmxKfeiG8SnEvq42hc6whRyY3EFYAvebMqDNDGCgxN5Z
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_AVAX')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="5">
                    <div className="tab-pane p-2" id="bnb" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">BNB(Binance Coin)</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>2017-07-07</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>184,665,938</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>152,665,937</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}{" "}
                              <a
                                href="https://resource.bnbstatic.com/books/20190418/1555582477981.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://resource.bnbstatic.com/books/20190418/1555582477981.pdf
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://www.binance.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://www.binance.com/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="https://explorer.binance.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://explorer.binance.org/
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_BNB')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="6">
                    <div className="tab-pane p-2" id="btc" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">BTC(Bitcoin)</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>2008-10-30</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>21,000,000</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>18,247,575</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}{" "}
                              <a
                                href="https://bitcoin.org/bitcoin.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://bitcoin.org/bitcoin.pdf
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://bitcoin.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://bitcoin.org/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="https://www.blockchain.com/explorer"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://www.blockchain.com/explorer
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_BTC')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="7">
                    <div className="tab-pane p-2" id="cake" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">CAKE(PancakeSwap)</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>2020-09-21</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>213,278,097</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>130,470,396</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}{" "}
                              <a
                                href="bscscan.com"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                --
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://pancakeswap.finance/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://pancakeswap.finance/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="https://bscscan.com/token/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://bscscan.com/token/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_Cake')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="8">
                    <div className="tab-pane p-2" id="doge" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">DOGE(Dogecoin)</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>2013-12-11</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>121,162,098,417</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>121,162,098,417</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}{" "}
                              <a
                                href="bscscan.com"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                --
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="http://dogecoin.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                http://dogecoin.com/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="http://dogechain.info/chain/Dogecoin"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                http://dogechain.info/chain/Dogecoin
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_Doge')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="9">
                    <div className="tab-pane p-2" id="dot" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">DOT(Polkadot)</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>--</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>10,000,000</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>--</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}{" "}
                              <a
                                href="https://polkadot.network/PolkaDotPaper.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://polkadot.network/PolkaDotPaper.pdf
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://polkadot.network/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://polkadot.network/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="bscscan.com"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                --
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_Dot')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="10">
                    <div className="tab-pane p-2" id="eos" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">EOS</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>--</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>--</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>--</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}{" "}
                              <a
                                href="bscscan.com"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                --
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="bscscan.com"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                --
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="bscscan.com"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                --
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className=""> </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="11">
                    <div className="tab-pane  p-2" id="etc" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">
                          Ethereum classNameic ETC
                        </h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>2016</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>210,700,000</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>139,257,146 </span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')} <span>--</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://ethereumclassNameic.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://ethereumclassNameic.org/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="https://etcblockexplorer.com/home"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://etcblockexplorer.com/home
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_ETC')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="12">
                    <div className="tab-pane p-2" id="eth" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">ETH(Ethereum)</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>2014-07-23</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>109,542,949</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>109,542,949</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}{" "}
                              <a
                                href="https://github.com/ethereum/wiki/wiki/White-Paper"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://github.com/ethereum/wiki/wiki/White-Paper
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://www.ethereum.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://www.ethereum.org/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="https://www.yitaifang.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://www.yitaifang.com/
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_ETH')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="13">
                    <div className="tab-pane p-2" id="fil" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">FIL(Filecoin)</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>2017-08-09</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>100,000,000</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>100,000,000</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}{" "}
                              <a
                                href="bscscan.com"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                --
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://filecoin.io/zh-cn/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://filecoin.io/zh-cn/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="https://filscan.io/#/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://filscan.io/#/
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_Fil')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="14">
                  <div className="tab-pane  p-2" id="gbr" role="tabpanel">
                    <div className="">
                      <h6 className="font-weight-bold">Gabur GBR</h6>
                      <ul className="">
                        <li className="">
                          <p className="">
                            {t('CoinInfo_Issue_Time')} <span>2022</span>
                          </p>
                        </li>
                        <li className="">
                          <p className="">
                            {t('Global_Total')} {t('Global_Supply')} : <span>550,000,000</span>
                          </p>
                        </li>
                        <li className="">
                          <p className="">
                            {t('Global_Circulation')} : <span>206,777,239</span>{" "}
                          </p>
                        </li>
                        <li className="">
                          <p className="">
                            {t('CoinInfo_White_paper')} <span>--</span>
                          </p>
                        </li>
                        <li className="">
                          <p className="">
                            {t('CoinInfo_Website')}{" "}
                            <a
                              href="https://easyexchangepro.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://easyexchangepro.com/
                            </a>
                          </p>
                        </li>
                        <li className="">
                          <p className="">
                            {t('CoinInfo_Block_Explorer')}{" "}
                            <a
                              href="https://bscscan.com/token/0x0bbe4ebc8a16c759ea82d0950f29aad3e3f10070"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://bscscan.com/token/0x0bbe4ebc8a16c759ea82d0950f29aad3e3f10070
                            </a>
                          </p>
                        </li>
                      </ul>
                      <div className="">
                        {t('CoinInfo_GBR')}
                      </div>
                    </div>
                  </div>
                </Tab.Pane> */}
                  <Tab.Pane eventKey="15">
                    <div className="tab-pane p-2" id="ltc" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">LTC (LITECOIN)</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>--</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>84,000,000</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>70,707,956.28</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}{" "}
                              <a
                                href="bscscan.com"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                --
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://www.litecoin.net/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://www.litecoin.net/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="https://blockchair.com/litecoin"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://blockchair.com/litecoin
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_LTC')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="16">
                    <div className="tab-pane p-2" id="matic" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">MATIC(Matic Token)</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>2019-04-29</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>10,000,000,000</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>3,830,626,432</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}{" "}
                              <a
                                href="bscscan.com"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                --
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://matic.network/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://matic.network/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="https://etherscan.io/token/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://etherscan.io/token/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_Matic')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="17">
                    <div className="tab-pane  p-2" id="mkr" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">Maker MKR</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>2017</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>1,005,577</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>977,631</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}
                              <span>
                                https://makerdao.com/en/whitepaper/#overview-of-the-dai-stablecoin-system
                              </span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://makerdao.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://makerdao.com/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="https://etherscan.io/token/Maker"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://etherscan.io/token/Maker
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_MKR')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="18">
                    <div className="tab-pane  p-2" id="near" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">NEAR Protocol NEAR</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>2017</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>1,000,000,000</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>853,199,805 </span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')} <span>--</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://near.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://near.org/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="https://bscscan.com/token/0x1fa4a73a3f0133f0025378af00236f3abdee5d63"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://bscscan.com/token/0x1fa4a73a3f0133f0025378af00236f3abdee5d63
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_Near')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="19">
                    <div className="tab-pane p-2" id="trx" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">TRX(TRON)</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>--</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>100,000,000,000</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>65,800,000,000</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}{" "}
                              <a
                                href="https://tron.network/technical.html"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://tron.network/technical.html
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://tron.network/enindex.html"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://tron.network/enindex.html
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="https://etherscan.io/token/0xf230b790e05390fc8295f4d3f60332c93bed42e2"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://etherscan.io/token/0xf230b790e05390fc8295f4d3f60332c93bed42e2
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_TRX')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="20">
                    <div className="tab-pane p-2" id="xlm" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">XLM(Stellar)</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>2014-07-31</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>104125061584</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>18766663721</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}{" "}
                              <a
                                href="https://www.stellar.org/papers/stellar-consensus-protocol.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://www.stellar.org/papers/stellar-consensus-protocol.pdf
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://Stellar.org"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://Stellar.org
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="bscscan.com"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                --
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_XLM')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="21">
                    <div className="tab-pane p-2" id="xrp" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">XRt(Ripple)</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>2011-04-17</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>99,991,850,794</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>41,432,141,931</span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}{" "}
                              <a
                                href="https://ripple.com/files/ripple_consensus_whitepaper.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://ripple.com/files/ripple_consensus_whitepaper.pdf
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://ripple.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://ripple.com/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="bscscan.com"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                --
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_XRP')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="22">
                    <div className="tab-pane  p-2" id="zec" role="tabpanel">
                      <div className="">
                        <h6 className="font-weight-bold">Zcash ZEC</h6>
                        <ul className="">
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Issue_Time')} <span>2016</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Total')} {t('Global_Supply')} : <span>21,000,000</span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('Global_Circulation')} : <span>16,280,681 </span>{" "}
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_White_paper')}
                              <span>
                                https://github.com/zcash/zips/blob/master/protocol/protocol.pdf
                              </span>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Website')}{" "}
                              <a
                                href="https://z.cash/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://z.cash/
                              </a>
                            </p>
                          </li>
                          <li className="">
                            <p className="">
                              {t('CoinInfo_Block_Explorer')}{" "}
                              <a
                                href="https://bscscan.com/token/0x1ba42e5193dfa8b03d15dd1b86a3113bbbef8eeb"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://bscscan.com/token/0x1ba42e5193dfa8b03d15dd1b86a3113bbbef8eeb
                              </a>
                            </p>
                          </li>
                        </ul>
                        <div className="">
                          {t('CoinInfo_ZEC')}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="coininfo-main">
          <div className="container coininfo-module">
            <Tab.Container id="left-tabs-example" defaultActiveKey="1">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="1">AAVE</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="2">ADA</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="3">ATOM</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="4">AVAX</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="5">BNB</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="6">BTC</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="7">CAKE</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="8">DOGE</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="9">DOT</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="10">EOS</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="11">ETC</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="12">ETH</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="13">FIL</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                  <Nav.Link eventKey="14">GBR</Nav.Link>
                </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link eventKey="15">LTC</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="16">MATIC</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="17">MKR</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="18">NEAR</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="19">TRX</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="20">XLM</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="21">XRP</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="22">ZEC</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="1">
                      <div
                        className="tab-pane active p-2"
                        id="aave"
                        role="tabpanel"
                      >
                        <div className="">
                          <h6 className="font-weight-bold">AAVE(Aave)</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>January 2017</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>16,000,000</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>--</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')} <span>--</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://aave.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://aave.com/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="https://etherscan.io/token/0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://etherscan.io/token/0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_AAVE')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="2">
                      <div className="tab-pane p-2" id="ada" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">ADA(Cardano)</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>2017-10-01</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>45,000,000,000</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>25,927,070,538</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}{" "}
                                <a
                                  href="https://docs.cardano.org/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://docs.cardano.org/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://www.cardanohub.org/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://www.cardanohub.org/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="https://cardanoexplorer.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://cardanoexplorer.com/
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_ADA')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="3">
                      <div className="tab-pane  p-2" id="atom" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">Cosmos ATOM</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>January 2014</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span></span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>286,370,297</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}{" "}
                                <span>
                                  <a href="https://cosmos.network/resources/whitepaper">
                                    PDF
                                  </a>
                                </span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://cosmos.network/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://cosmos.network/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="https://atomscan.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://atomscan.com/
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_Atom')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="4">
                      <div className="tab-pane  p-2" id="avax" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">Avalanche AVAX</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>2020</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>720,000,000</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>315,001,173</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}{" "}
                                <span>https://www.avalabs.org/whitepapers</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://www.avalabs.org/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://www.avalabs.org/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="https://avascan.info/blockchain/x/asset/FvwEAhmxKfeiG8SnEvq42hc6whRyY3EFYAvebMqDNDGCgxN5Z"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://avascan.info/blockchain/x/asset/FvwEAhmxKfeiG8SnEvq42hc6whRyY3EFYAvebMqDNDGCgxN5Z
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_AVAX')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="5">
                      <div className="tab-pane p-2" id="bnb" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">BNB(Binance Coin)</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>2017-07-07</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>184,665,938</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>152,665,937</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}{" "}
                                <a
                                  href="https://resource.bnbstatic.com/books/20190418/1555582477981.pdf"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://resource.bnbstatic.com/books/20190418/1555582477981.pdf
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://www.binance.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://www.binance.com/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="https://explorer.binance.org/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://explorer.binance.org/
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_BNB')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="6">
                      <div className="tab-pane p-2" id="btc" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">BTC(Bitcoin)</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>2008-10-30</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>21,000,000</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>18,247,575</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}{" "}
                                <a
                                  href="https://bitcoin.org/bitcoin.pdf"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://bitcoin.org/bitcoin.pdf
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://bitcoin.org/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://bitcoin.org/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="https://www.blockchain.com/explorer"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://www.blockchain.com/explorer
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_BTC')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="7">
                      <div className="tab-pane p-2" id="cake" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">CAKE(PancakeSwap)</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>2020-09-21</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>213,278,097</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>130,470,396</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}{" "}
                                <a
                                  href="bscscan.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  --
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://pancakeswap.finance/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://pancakeswap.finance/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="https://bscscan.com/token/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://bscscan.com/token/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_Cake')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="8">
                      <div className="tab-pane p-2" id="doge" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">DOGE(Dogecoin)</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>2013-12-11</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>121,162,098,417</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>121,162,098,417</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}{" "}
                                <a
                                  href="bscscan.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  --
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="http://dogecoin.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  http://dogecoin.com/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="http://dogechain.info/chain/Dogecoin"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  http://dogechain.info/chain/Dogecoin
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_Doge')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="9">
                      <div className="tab-pane p-2" id="dot" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">DOT(Polkadot)</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>--</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>10,000,000</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>--</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}{" "}
                                <a
                                  href="https://polkadot.network/PolkaDotPaper.pdf"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://polkadot.network/PolkaDotPaper.pdf
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://polkadot.network/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://polkadot.network/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="bscscan.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  --
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_Dot')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="10">
                      <div className="tab-pane p-2" id="eos" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">EOS</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>--</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>--</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>--</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}{" "}
                                <a
                                  href="bscscan.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  --
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="bscscan.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  --
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="bscscan.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  --
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className=""> </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="11">
                      <div className="tab-pane  p-2" id="etc" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">
                            Ethereum classNameic ETC
                          </h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>2016</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>210,700,000</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>139,257,146 </span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')} <span>--</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://ethereumclassNameic.org/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://ethereumclassNameic.org/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="https://etcblockexplorer.com/home"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://etcblockexplorer.com/home
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_ETC')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="12">
                      <div className="tab-pane p-2" id="eth" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">ETH(Ethereum)</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>2014-07-23</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>109,542,949</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>109,542,949</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}{" "}
                                <a
                                  href="https://github.com/ethereum/wiki/wiki/White-Paper"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://github.com/ethereum/wiki/wiki/White-Paper
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://www.ethereum.org/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://www.ethereum.org/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="https://www.yitaifang.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://www.yitaifang.com/
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_ETH')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="13">
                      <div className="tab-pane p-2" id="fil" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">FIL(Filecoin)</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>2017-08-09</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>100,000,000</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>100,000,000</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}{" "}
                                <a
                                  href="bscscan.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  --
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://filecoin.io/zh-cn/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://filecoin.io/zh-cn/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="https://filscan.io/#/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://filscan.io/#/
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_Fil')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="14">
                  <div className="tab-pane  p-2" id="gbr" role="tabpanel">
                    <div className="">
                      <h6 className="font-weight-bold">Gabur GBR</h6>
                      <ul className="">
                        <li className="">
                          <p className="">
                            {t('CoinInfo_Issue_Time')} <span>2022</span>
                          </p>
                        </li>
                        <li className="">
                          <p className="">
                            {t('Global_Total')} {t('Global_Supply')} : <span>550,000,000</span>
                          </p>
                        </li>
                        <li className="">
                          <p className="">
                            {t('Global_Circulation')} : <span>206,777,239</span>{" "}
                          </p>
                        </li>
                        <li className="">
                          <p className="">
                            {t('CoinInfo_White_paper')} <span>--</span>
                          </p>
                        </li>
                        <li className="">
                          <p className="">
                            {t('CoinInfo_Website')}{" "}
                            <a
                              href="https://easyexchangepro.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://easyexchangepro.com/
                            </a>
                          </p>
                        </li>
                        <li className="">
                          <p className="">
                            {t('CoinInfo_Block_Explorer')}{" "}
                            <a
                              href="https://bscscan.com/token/0x0bbe4ebc8a16c759ea82d0950f29aad3e3f10070"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://bscscan.com/token/0x0bbe4ebc8a16c759ea82d0950f29aad3e3f10070
                            </a>
                          </p>
                        </li>
                      </ul>
                      <div className="">
                        {t('CoinInfo_GBR')}
                      </div>
                    </div>
                  </div>
                </Tab.Pane> */}
                    <Tab.Pane eventKey="15">
                      <div className="tab-pane p-2" id="ltc" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">LTC (LITECOIN)</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>--</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>84,000,000</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>70,707,956.28</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}{" "}
                                <a
                                  href="bscscan.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  --
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://www.litecoin.net/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://www.litecoin.net/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="https://blockchair.com/litecoin"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://blockchair.com/litecoin
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_LTC')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="16">
                      <div className="tab-pane p-2" id="matic" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">MATIC(Matic Token)</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>2019-04-29</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>10,000,000,000</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>3,830,626,432</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}{" "}
                                <a
                                  href="bscscan.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  --
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://matic.network/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://matic.network/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="https://etherscan.io/token/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://etherscan.io/token/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_Matic')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="17">
                      <div className="tab-pane  p-2" id="mkr" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">Maker MKR</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>2017</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>1,005,577</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>977,631</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}
                                <span>
                                  https://makerdao.com/en/whitepaper/#overview-of-the-dai-stablecoin-system
                                </span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://makerdao.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://makerdao.com/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="https://etherscan.io/token/Maker"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://etherscan.io/token/Maker
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_MKR')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="18">
                      <div className="tab-pane  p-2" id="near" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">NEAR Protocol NEAR</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>2017</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>1,000,000,000</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>853,199,805 </span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')} <span>--</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://near.org/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://near.org/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="https://bscscan.com/token/0x1fa4a73a3f0133f0025378af00236f3abdee5d63"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://bscscan.com/token/0x1fa4a73a3f0133f0025378af00236f3abdee5d63
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_Near')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="19">
                      <div className="tab-pane p-2" id="trx" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">TRX(TRON)</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>--</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>100,000,000,000</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>65,800,000,000</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}{" "}
                                <a
                                  href="https://tron.network/technical.html"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://tron.network/technical.html
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://tron.network/enindex.html"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://tron.network/enindex.html
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="https://etherscan.io/token/0xf230b790e05390fc8295f4d3f60332c93bed42e2"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://etherscan.io/token/0xf230b790e05390fc8295f4d3f60332c93bed42e2
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_TRX')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="20">
                      <div className="tab-pane p-2" id="xlm" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">XLM(Stellar)</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>2014-07-31</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>104125061584</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>18766663721</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}{" "}
                                <a
                                  href="https://www.stellar.org/papers/stellar-consensus-protocol.pdf"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://www.stellar.org/papers/stellar-consensus-protocol.pdf
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://Stellar.org"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://Stellar.org
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="bscscan.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  --
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_XLM')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="21">
                      <div className="tab-pane p-2" id="xrp" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">XRt(Ripple)</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>2011-04-17</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>99,991,850,794</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>41,432,141,931</span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}{" "}
                                <a
                                  href="https://ripple.com/files/ripple_consensus_whitepaper.pdf"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://ripple.com/files/ripple_consensus_whitepaper.pdf
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://ripple.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://ripple.com/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="bscscan.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  --
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_XRP')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="22">
                      <div className="tab-pane  p-2" id="zec" role="tabpanel">
                        <div className="">
                          <h6 className="font-weight-bold">Zcash ZEC</h6>
                          <ul className="">
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Issue_Time')} <span>2016</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Total')} {t('Global_Supply')} : <span>21,000,000</span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('Global_Circulation')} : <span>16,280,681 </span>{" "}
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_White_paper')}
                                <span>
                                  https://github.com/zcash/zips/blob/master/protocol/protocol.pdf
                                </span>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Website')}{" "}
                                <a
                                  href="https://z.cash/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://z.cash/
                                </a>
                              </p>
                            </li>
                            <li className="">
                              <p className="">
                                {t('CoinInfo_Block_Explorer')}{" "}
                                <a
                                  href="https://bscscan.com/token/0x1ba42e5193dfa8b03d15dd1b86a3113bbbef8eeb"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://bscscan.com/token/0x1ba42e5193dfa8b03d15dd1b86a3113bbbef8eeb
                                </a>
                              </p>
                            </li>
                          </ul>
                          <div className="">
                            {t('CoinInfo_ZEC')}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </>
    );
  }
}

export default CoinInfo;
