import { Button } from "@mui/material";
import "./login.scss";
import { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordForm, setPasswordForm] = useState({
    password: "",
    confirm_password: "",
  });
  const [msg, setMsg] = useState("");
  const [queryParams] = useSearchParams();
  const changeForm = (event) => {
    setPasswordForm({
      ...passwordForm,
      [event.target.name]: event.target.value,
    });
  };

  const submitForm = (event) => {
    event.preventDefault();
    setIsLoading(true);
    request2confirm();
  };
  const navigate = useNavigate();
  const request2confirm = async () => {
    let res = await fetch(
      "https://api.easyexchangepro.com/api/v2/forgot/action/password",
      {
        method: "POST",
        body: JSON.stringify({
          token: queryParams.get("token"),
          verify: queryParams.get("verify"),
          new_pwd: passwordForm.password,
          confirm_pwd: passwordForm.confirm_password,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let resJson = await res.json();
    if (resJson.status === 1) {
      setMsg(resJson.msg);
      setTimeout(() => {
        navigate("/login", { replace: true });
      }, 1500);
    } else {
      setMsg(resJson.msg);
    }
    setIsLoading(false);
  };
  const { t } = useTranslation();
  return (
    <div className="reset-pass-main">
      <div className="reset-pass-module">
        <h5 className="pb-5">{t("ResetPassword_title")}</h5>
        <Form onSubmit={submitForm} className="sm">
          <div className="row justify-content-around text-center">
            {msg && <p>{msg}</p>}
            <Form.Group className="mb-3">
              <Form.Control
                type="password"
                placeholder={t("ResetPassword_NewPassword_input")}
                autoFocus
                name="password"
                onChange={changeForm}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="password"
                placeholder={t("ResetPassword_ConfirmPassword_input")}
                autoFocus
                name="confirm_password"
                onChange={changeForm}
                required
              />
            </Form.Group>
          </div>
          <div className="row justify-content-around text-center">
            <div className="col pt-5">
              <Button
                variant="primary"
                type="submit"
                className="btn-security"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                {isLoading ? t("Global_Loading") : t("Global_Submit")}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ResetPassword;
