import React from "react";
import AboutVideo from "../../assets/videos/about.mp4";
import "./aboutus.scss";
import { useTranslation } from "react-i18next";
function ATopvideo() {
  const { t } = useTranslation();
  return (
    <>
      <video className="videoTag" autoPlay loop muted>
        <source src={AboutVideo} type="video/mp4" />
      </video>
      <section className="section1">
        <div className="top">
          <h1 className="h1s1"> {t('About_top_title')} </h1>
          <p className="p1s1">
            {" "}
            {t('About_top_desciption')}
            {" "}
          </p>
        </div>
        <div className="banner">
        <div className="b1">
            <h1 className="h1b1"> {t('About_top_Feature_1')} </h1>
            <p className="p1b1">
              {" "}
              {t('About_top_Feature_1_description_1')}
              <br />
              {t('About_top_Feature_1_description_2')}
              {" "}
            </p>
          </div>
          <div className="b1">
            <h1 className="h1b1"> {t('About_top_Feature_2')} </h1>
            <p className="p1b1"> {t('About_top_Feature_2_description')} </p>
          </div>
          <div className="b1">
            <h1 className="h1b1"> {t('About_top_Feature_3')} </h1>
            <p className="p1b1"> {t('About_top_Feature_3_description')} </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default ATopvideo;
