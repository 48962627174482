import React, { useContext, useState } from "react";
import Card from "react-bootstrap/Card";
import { Button } from "@mui/material";
import "./profile.scss";
import { UserContext } from "../../contexts/providers/user";
import { ItemNames, getStorage } from "../../utils/RSS";
import { Form, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

function PSecurity({ profile }) {
  const [modal2fa, setModal2fa] = useState({
    active_show: false,
    tfa_status: 0,
    tfa_url: "",
  });

  const [tradingPassword, setTradingPassword] = useState({
    active_show: false,
  });

  const [showPWDChanger, setShowPWDChanger] = useState(false);
  const handleClosePWDChanger = () => setShowPWDChanger(false);
  const handleShowPWDChanger = () => setShowPWDChanger(true);

  const [showTradeChanger, setShowTradeChanger] = useState(false);
  const handleCloseTradeChanger = () => setShowTradeChanger(false);
  const handleShowTradeChanger = () => setShowTradeChanger(true);

  const { state, dispatch } = useContext(UserContext);
  const [validated, setValidated] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  //security functions

  const passwordChanger = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      event.stopPropagation();
      const pwdChanger = new FormData(event.currentTarget);
      try {
        let res = await fetch(
          "https://api.easyexchangepro.com/api/v2/change/password",
          {
            method: "POST",
            body: JSON.stringify({
              current_pwd: pwdChanger.get("currentpass"),
              confirm_pwd: pwdChanger.get("confirmpass"),
              new_pwd: pwdChanger.get("newpass"),
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
            },
          }
        );

        let resJson = await res.json();

        if (resJson.status === 1) {
          dispatch({
            snackColor: "success",
            snackMessage: resJson.status,
            type: "open_snack",
          });
          setIsLoading(false);
          event.target.reset();
          setValidated(false);
        } else {
          setIsLoading(false);
          event.preventDefault();
          setShowPWDChanger(true);
          dispatch({
            snackColor: "warning",
            snackMessage: resJson.msg,
            type: "open_snack",
          });
        }
      } catch (err) {
        event.preventDefault();
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  const tradePasswordChanger = async (event) => {
    event.preventDefault();
    try {
      let res = await fetch(
        "https://api.easyexchangepro.com/api/v2/user/trading/forgot",
        {
          method: "GET",
          headers: {
            // "Content-Type": "application/json",
            Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
          },
        }
      );
      let resJson = await res.json();
      if (resJson.status === 1) {
        setTradingPassword({ ...tradingPassword, active_show: true });
        setValidated(false);
      } else {
        event.preventDefault();
        setShowPWDChanger(true);
        dispatch({
          snackColor: "warning",
          snackMessage: resJson.msg,
          type: "open_snack",
        });
      }
    } catch (err) {
      event.preventDefault();
      console.log(err);
    }
  };

  const GoogleActive2ta = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    fetch("https://api.easyexchangepro.com/api/v2/tfa/action", {
      method: "POST",
      body: JSON.stringify({
        tfa_code: form.get("tfa_code"),
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status === 1) {
          setModal2fa({
            ...modal2fa,
            active_show: false,
          });
          setIsLoading(false);
        } else {
          dispatch({
            snackColor: "warning",
            snackMessage: data.msg,
            type: "open_snack",
          });
          setIsLoading(false);
        }
      });
  };

  const Google2faAction = () => {
    setIsLoading(true);
    if (
      profile.tfa_status === 0 &&
      (profile.tfa_url === undefined || profile.tfa_url === "")
    ) {
      fetch("https://api.easyexchangepro.com/api/v2/tfa/action", {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      })
        .then((data) => data.json())
        .then((data) => {
          setIsLoading(false);
          setModal2fa({
            ...modal2fa,
            active_show: true,
            tfa_url: data.data.tfa_url,
          });
        });
    } else if (profile.tfa_status === 0 && profile.tfa_url !== undefined) {
      setIsLoading(false);
      setModal2fa({
        ...modal2fa,
        active_show: true,
        tfa_url: profile.tfa_url,
      });
    } else {
      setIsLoading(false);
      setModal2fa({
        ...modal2fa,
        active_show: true,
      });
    }
  };

  const changeTradingPassword = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const form = new FormData(event.currentTarget);
    try {
      let res = await fetch(
        "https://api.easyexchangepro.com/api/v2/user/trading/password",
        {
          method: "POST",
          body: JSON.stringify({
            password: form.get("password"),
            confirm_password: form.get("confirm_password"),
            forgot_code: form.get("forgot_code"),
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
          },
        }
      );
      let resJson = await res.json();

      if (resJson.status === 1) {
        dispatch({
          snackColor: "success",
          snackMessage: resJson.msg,
          type: "open_snack",
        });
        handleCloseTradeChanger();
        setTradingPassword({ ...tradingPassword, active_show: false });
        setIsLoading(false);
        setValidated(false);
      } else {
        event.preventDefault();
        setShowPWDChanger(true);
        dispatch({
          snackColor: "warning",
          snackMessage: resJson.msg,
          type: "open_snack",
        });
        setIsLoading(false);
      }
    } catch (err) {
      event.preventDefault();
      console.log(err);
      setIsLoading(false);
    }
  };
  const { t } = useTranslation();

  return (
    <div className="security-main  d-flex flex-wrap">
      <div className="col m-2">
        <Card style={{ width: "18rem", height: "12rem" }}>
          <Card.Body className="user-info">
            <Card.Title>{t("Profile_Account_Password_title")}</Card.Title>
            <Card.Subtitle className="mb-5 text-muted">
              {t("Profile_Account_Password_description")}
            </Card.Subtitle>
            <Card.Text>
              <Button variant="contained" onClick={handleShowPWDChanger}>
                {t("Profile_Change_Password_btn")}
              </Button>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="col m-2">
        <Card style={{ width: "18rem", height: "12rem" }}>
          <Card.Body className="user-info">
            <Card.Title>{t("Profile_Sec_Authenticaton_title")}</Card.Title>
            <Card.Subtitle className="mb-4 text-muted">
              {t("Profile_Sec_Authenticaton_description")}
            </Card.Subtitle>
            <Card.Text>
              <Button
                variant="contained"
                onClick={Google2faAction}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                {isLoading
                  ? "Loading..."
                  : `${profile.tfa_status === 0
                    ? t("Profile_Active_2FA")
                    : t("Profile_Disable")
                  }`}
              </Button>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="col m-2">
        <Card style={{ width: "18rem", height: "12rem" }}>
          <Card.Body className="user-info">
            <Card.Title>{t("Profile_Trading_Password")}</Card.Title>
            <Card.Subtitle className="mb-4 text-muted">
              {t("Profile_Trading_Password_description")}
            </Card.Subtitle>
            <Card.Text>
              <Button variant="contained" onClick={handleShowTradeChanger}>
                {t("Profile_Change_trading_password")}
              </Button>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      {/* <div className="col m-2">
        <Card style={{ width: "18rem", height: "12rem" }}>
          <Card.Body className="user-info">
            <Card.Title>Limit IP</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              You can add your trusted IP here, so other IP's cannot login to
              your account.
            </Card.Subtitle>
            <Card.Text>
              <Button variant="contained">Control IP's</Button>
            </Card.Text>
          </Card.Body>
        </Card>
      </div> */}

      {/* password change */}

      <div>
        <Modal
          show={modal2fa.active_show}
          onHide={() => setModal2fa({ ...modal2fa, active_show: false })}
          data-backdrop={"static"}
          data-toggle={"modal"}
        // style={{ width: 200, margin: 0 }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {modal2fa.tfa_url !== ""
                ? t("Profile_Activating_2FA")
                : t("Profile_Disable_2FA")}
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={GoogleActive2ta} autoComplete="off">
            <Modal.Body className="modal-pwd" style={{ height: 360 }}>
              {modal2fa.tfa_url !== "" && (
                <div className="row justify-content-center">
                  <div className="col-12 text-center mb-4">
                    <QRCode value={modal2fa.tfa_url.split('chl=')[1]} className="qrcode-frame" />
                  </div>
                </div>

              )}
              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  placeholder={t("Profile_2FA_Code")}
                  autoFocus
                  name="tfa_code"
                  required
                />
              </Form.Group>
              <div className="row justify-content-around text-center">
                <div className="col">
                  <Button
                    variant="secondary"
                    onClick={() =>
                      setModal2fa({ ...modal2fa, active_show: false })
                    }
                    className="btn-security"
                  >
                    {t("Global_Close")}
                  </Button>
                </div>
                <div className="col">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn-security"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {isLoading ? t("Global_Loading") : t("Profile_Active")}
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Form>
        </Modal>
        <Modal
          show={showPWDChanger}
          onHide={handleClosePWDChanger}
          data-backdrop={"static"}
          data-toggle={"modal"}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("Profile_Change_your_password_title")}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={passwordChanger} autoComplete="new-password">
            <Modal.Body className="modal-pwd">
              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  placeholder={t("Profile_Current_Password")}
                  autoFocus
                  name="currentpass"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  placeholder={t("Profile_New_Password")}
                  autoFocus
                  name="confirmpass"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  placeholder={t("Profile_Confirm_New_Password")}
                  autoFocus
                  name="newpass"
                  required
                />
              </Form.Group>
              <div className="row justify-content-around text-center">
                <div className="col">
                  <Button
                    variant="secondary"
                    onClick={handleClosePWDChanger}
                    className="btn-security"
                  >
                    {t("Global_Close")}
                  </Button>
                </div>
                <div className="col">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn-security"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {isLoading ? t("Global_Loading") : t("Global_Submit")}
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Form>
        </Modal>
        <Modal
          show={showTradeChanger}
          onHide={handleCloseTradeChanger}
          data-backdrop={"static"}
          data-toggle={"modal"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t("Profile_Change_your_Trading_password_title")}
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={changeTradingPassword} autoComplete="new-password">
            <Modal.Body className="modal-pwd">
              {tradingPassword.active_show === false ? (
                <>
                  <h6 className="pb-4">
                    {t("Profile_Change_your_Trading_password_description")}
                  </h6>
                  <div className="row justify-content-around text-center">
                    <div className="col">
                      <Button
                        variant="secondary"
                        onClick={handleCloseTradeChanger}
                        className="btn-security"
                      >
                        {t("Global_Close")}
                      </Button>
                    </div>
                    <div className="col">
                      <Button
                        variant="primary"
                        className="btn-security"
                        onClick={tradePasswordChanger}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : null}
                        {isLoading ? t("Global_Loading") : t("Global_Submit")}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="password"
                      placeholder={t("Global_Password")}
                      autoFocus
                      name="password"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="password"
                      placeholder={
                        t("Global_Confirm") + " " + t("Global_Password")
                      }
                      autoFocus
                      name="confirm_password"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="password"
                      placeholder={t("Profile_forgot_code")}
                      autoFocus
                      name="forgot_code"
                      required
                    />
                  </Form.Group>
                  <div className="row justify-content-around text-center">
                    <div className="col">
                      <Button
                        variant="secondary"
                        onClick={() => {
                          handleCloseTradeChanger();
                          setTradingPassword({
                            ...tradingPassword,
                            active_show: false,
                          });
                        }}
                        className="btn-security"
                      >
                        Close
                      </Button>
                    </div>
                    <div className="col">
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn-security"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : null}
                        {isLoading ? t("Global_Loading") : t("Global_Change")}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default PSecurity;
