import React from "react";
import EasyGuarantee from "../../assets/images/guarantee.png";
import { Button } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import ScrollToTop from "../../utils/ScrollToTop";
import { useTranslation } from "react-i18next";
function Guarantee() {
  const { t } = useTranslation();
  return (
    <div className="container-fluid guarantee-home">
      <div className="container">
        <div className="row ">
          <div className="col-md-6 col-12 mb-5 mt-5">
            <p className="h1 p-2 easyguarantee-title">{t('Global_EasyGuarantee')}</p>
            <p className="h4 p-2 guarantee-subtitle">
              {t('Home_EG_subtitle')}
            </p>
            <p className="h5 text-justify p-5 guarantee-content">
              {t('Home_EG_description')}
            </p>
            <div className="d-flex flex-wrap justify-content-center">
              <Button className="btn guarantee-btn">
                {" "}
                <NavLink as={Link} to="/easyguaranteefaq" className="nav-link">
                  {t('Home_EG_more_info_btn')}

                  <ScrollToTop />
                </NavLink>{" "}
              </Button>
              <Button className="btn guarantee-btn">
                <NavLink as={Link} to="/wheretobuy" className="nav-link">
                  {t('Global_ApplicationForm')}

                  <ScrollToTop />
                </NavLink>
              </Button>
            </div>
          </div>
          <div className="col-md-6 col-12 mb-5 mt-5">
            <img
              src={EasyGuarantee}
              alt=""
              className="p-1 img-fluid float-end mb-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Guarantee;
