import { React, useState, useContext } from "react";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { TextareaAutosize } from "@mui/material";
import { ItemNames, getStorage } from "../../utils/RSS";
import { UserContext } from "../../contexts/providers/user";
import { useTranslation } from "react-i18next";

function TokenListingForm() {
  const [validated, setValidated] = useState(false);
  const { dispatch } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [formToken, setFormToken] = useState({
    token_name: "",
    token_symbol: "",
    nft: false,
    price: "",
    total_supply: "",
    dateofissue: "",
    total_circulation: "",
    market_circulation: "",
    website: "",
    source_code: "",
    telegram: "",
    twitter: "",
    facebook: "",
    reddit: "",
    discord: "",
    submitter_name: "",
    submitter_last_name: "",
    submitter_role: "",
    submitter_mobile: "",
    submitter_email: "",
    about_project: "",
    about_private: "",
    distribution_plan: "",
    ceo_info: "",
    //files
    token_image: "",
    whitepaper: "",
    submitter_images: "",
  });

  const changeFileForm = (event) => {
    setFormToken({
      ...formToken,
      [event.target.name]: event.target.files[0],
    });
  };

  const changeForm = (event) => {
    setFormToken({
      ...formToken,
      [event.target.name]: event.target.value,
    });
  };

  const handleTokenListingSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      setIsLoading(true);
      event.stopPropagation();
      const brokerRegister = new FormData(event.currentTarget);
      try {
        let res = await fetch(
          "https://api.easyexchangepro.com/api/v2/request/add_token",
          {
            method: "POST",
            body: brokerRegister,
            headers: {
              Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
            },
          }
        );
        let resJson = await res.json();

        if (resJson.status === 1 || resJson.status === true) {
          dispatch({ type: "toggle_modal", content: resJson.msg });
          event.target.reset();
          setValidated(true);
        } else {
          dispatch({
            type: "open_snack",
            snackColor: "warning",
            snackMessage: resJson.msg,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const { t } = useTranslation();

  return (
    <div className="tokenlistingform-main">
      <Form
        noValidate
        validated={validated}
        onSubmit={handleTokenListingSubmit}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-11 col-sm-10 col-md-5  m-5">
              <h4>{t('TL_Exchange_info')}</h4>
              <InputGroup className="mt-2">
                <Form.Control
                  type="text"
                  placeholder={t('TL_Token_Name')}
                  name="token_name"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Token_Name_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('TL_Token_symbol')}
                  name="token_symbol"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Token_symbol_Error')}
                </Form.Control.Feedback>
              </InputGroup>

              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>{t('TL_Token_symbol')}</Form.Label>
                <Form.Control
                  type="file"
                  name="token_image"
                  onChange={changeFileForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Token_Symbol_upload_Error')}
                </Form.Control.Feedback>
              </Form.Group>
              <h6>{t('Global_Max_upload')}</h6>
              <Form.Check
                type={"switch"}
                id={`nft`}
                label={`${t('TL_NFT')}?`}
                name="nft"
                onChange={changeForm}
              />

              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('Global_Price')}
                  name="price"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('Global_Price_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('Global_Total')+" "+t('Global_Supply')}
                  name="total_supply"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Total_supply_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="date"
                  placeholder={t('TL_Date_of_issue')}
                  name="dateofissue"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Date_of_issue_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('TL_Total_circulation')}
                  name="total_circulation"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Total_circulation_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('TL_Market_cap_circulation')}
                  name="market_circulation"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Market_cap_circulation_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('Global_Website')}
                  name="website"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('Global_Website_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('TL_Source_code')}
                  name="source_code"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Source_code_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>{t('TL_Whitepaper')}</Form.Label>
                <Form.Control
                  type="file"
                  name="whitepaper"
                  required
                  onChange={changeFileForm}
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Whitepaper_Error')}
                </Form.Control.Feedback>
              </Form.Group>
              <h6>{t('Global_Max_upload')}</h6>
            </div>
            <div className="col-11 col-sm-10 col-md-5  m-5">
              <h4>{t('TL_Communities')}</h4>
              <InputGroup className="mt-2">
                <Form.Control
                  type="text"
                  placeholder={t('TL_Telegram')}
                  name="telegram"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Telegram_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('TL_Twitter')}
                  name="twitter"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Twitter_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('TL_Facebook')}
                  name="facebook"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Facebook_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('TL_Reddit')}
                  name="reddit"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Reddit_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('TL_Discord')}
                  name="discord"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Discord_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <h4 className="mt-2 mb-2">{t('TL_Submitter_info')}</h4>
              <InputGroup className="mt-2">
                <Form.Control
                  type="text"
                  placeholder={t('Global_FName')}
                  name="submitter_name"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('Global_FName_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('Global_LName')}
                  name="submitter_last_name"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('Global_LName_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('TL_Role')}
                  name="submitter_role"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Role_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('Global_Mobile')}
                  name="submitter_mobile"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('Global_Mobile_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="email"
                  placeholder={t('Global_Email')}
                  name="submitter_email"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('Global_Email_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>{t('TL_Personal_ID')}</Form.Label>
                <Form.Control
                  type="file"
                  name="submitter_images"
                  required
                  onChange={changeFileForm}
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Personal_ID_Error')}
                </Form.Control.Feedback>
              </Form.Group>
              <h6>{t('Global_Max_upload')}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-11 m-5">
              <Form.Group className="mt-2">
                <Form.Control
                  as={TextareaAutosize}
                  maxRows={5}
                  placeholder={t('TL_About_project')}
                  name="about_project"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_About_project_Error')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mt-4">
                <Form.Control
                  as={TextareaAutosize}
                  maxRows={5}
                  placeholder={t('TL_About_Private')}
                  name="about_private"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_About_Private_Error')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mt-4">
                <Form.Control
                  as={TextareaAutosize}
                  maxRows={5}
                  placeholder={t('TL_Distribution_plan')}
                  name="distribution_plan"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_Distribution_plan_Error')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mt-4">
                <Form.Control
                  as={TextareaAutosize}
                  maxRows={5}
                  placeholder={t('TL_CEO_info')}
                  name="ceo_info"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('TL_CEO_info_Error')}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row m-2">
            <h5>{t('TL_Policy')}</h5>
            <div className="col-1">
              <Button
                className="submit-btn mb-5"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                {isLoading ? t('Global_Loading') : t('Global_Submit')}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default TokenListingForm;
