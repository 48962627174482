import React, { useContext } from "react";
import "./trade.scss";
import { useParams } from "react-router";
import { UserContext } from "../../../contexts/providers/user";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
function TopPriceData() {
  const { tradePair } = useParams();

  const { stateIO } = useContext(UserContext);
  const { state } = useContext(UserContext);
  const tokenName = tradePair.split("_")[0];
  const [pageTitle, setPageTitle] = useState("Easy Pro");

  useEffect(() => {
    const originalTitle = document.title;
    document.title = pageTitle;
    return () => {
      document.title = originalTitle;
    };
  }, [pageTitle]);

  const updatePageTitle = (newPageTitle) => {
    setPageTitle(newPageTitle);
  };

  useEffect(() => {
    if (stateIO?.details.price) {
      updatePageTitle(
        `${tradePair.replace("_", "/")} ${stateIO.details.price} - Easy Pro`
      );
    }
  }, [stateIO?.details.price, tradePair]);

  const colorInTrade = () => {
    if (stateIO?.details.color === "text-danger") {
      return "textdanger";
    } else if (stateIO?.details.color === "text-success") {
      return "textsuccess";
    }
  };
  const { t } = useTranslation();
  return (
    <div className="toppricedata-main">
      <div className="row">
        <div className="col-1 pairname-top">
          <p>{tradePair.replace("_", "/")}</p>
        </div>
        <div className="col-1 toppricedata">
          <p>
            {parseFloat(`${stateIO?.details.price}`).toFixed(
              state?.currency.details[tokenName].price_decimal || 6
            )}
          </p>
          <p className="priceusdt">
            {parseFloat(`${stateIO?.details.price}`).toFixed(
              state?.currency.details[tokenName].price_decimal || 6
            )}
          </p>
        </div>
        <div className="col-1 toppricedata">
          <p className="data24h-title">{t('Global_24h')} {t('Global_Change')}</p>
          <p className={`change24h-data ${colorInTrade()}`}>
            {parseFloat(`${stateIO?.details.change}`).toFixed(
              state?.currency.details[tokenName].price_decimal || 6
            )}
          </p>
        </div>
        <div className="col-1 toppricedata">
          <p className="data24h-title">{t('Global_24h')} {t('Global_High')}</p>
          <p className="data24h-data">
            {parseFloat(`${stateIO?.details.high}`).toFixed(
              state?.currency.details[tokenName].price_decimal || 6
            )}
          </p>
        </div>
        <div className="col-1 toppricedata">
          <p className="data24h-title">{t('Global_24h')} {t('Global_Low')}</p>
          <p className="data24h-data">
            {parseFloat(`${stateIO?.details.low}`).toFixed(
              state?.currency.details[tokenName].price_decimal || 6
            )}
          </p>
        </div>
        <div className="col-1 toppricedata-volume">
          <p className="data24h-title">{t('Global_24h')} {t('Global_Volume')}({tradePair.split("_")[0]})</p>
          <p className="data24h-data">
            {parseFloat(`${stateIO?.details.base_volume}`).toFixed(
              state?.currency.details[tokenName].amount_decimal || 6
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TopPriceData;
