export const ItemNames = {
  TTOKEN: "ttoken",
  TOKEN: "token",
  USERNAME: "username",
  EMAILFORMAT: "emailformat",
  AIRDROP: "airdrop",
  EMAILSTATUS: "emailstatus",
  TFASTATUS: "tfastatus",
  KYCSTATUS: "kycstatus",
  REFCOUNT: "refcount",
  UID: "uid",
  TRADEPWDSTATUS: "tradepwdstatus",
  REFNUMBER: "refer_id",
  SECURITYLVL: "securitylvl",
  THEMES: "theme_selected",
  FAV_PAIR: "fav_pair"
};

export function getStorage(name) {
  return localStorage.getItem(name);
}

export function setStorage(name, value) {
  return localStorage.setItem(name, value);
}

export function isLoggedIn() {
  return getStorage(ItemNames.TOKEN) !== null;
}

export function setLogOut() {
  return localStorage.clear();
}
