import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slide2 from "../../assets/images/2.png";
import Slide3 from "../../assets/images/3.png";
import Slide4 from "../../assets/images/4.png";
import Slide5 from "../../assets/images/5.png";

const responsive = {
  desktop: {
    breakpoint: { max: 2800, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },

};

function ImageSliderMobile() {
  return (
    <Carousel
      swipeable={false}
      draggable={false}
      showDots={false}
      responsive={responsive}
      ssr={false} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2800}
      keyBoardControl={false}
      customTransition="all 1.5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      <div className="p-1">
        <img src={Slide4} style={{ maxWidth: 280 }} alt="" />
      </div>
      <div className="p-1">
        <img src={Slide2} style={{ maxWidth: 280 }} alt="" />
      </div>
      <div className="p-1">
        <img src={Slide3} style={{ maxWidth: 280 }} alt="" />
      </div>
      <div className="p-1">
        <img src={Slide4} style={{ maxWidth: 280 }} alt="" />
      </div>
      <div className="p-1">
        <img src={Slide5} style={{ maxWidth: 280 }} alt="" />
      </div>
    </Carousel>
  );
}

export default ImageSliderMobile;
