import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./faq.scss";
import parse from "html-react-parser";

function FAccordion() {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    fetch("https://api.easyexchangepro.com/api/v2/public/faqs")
      .then((data) => data.json())
      .then((data) => {
        setQuestions(data.data);
      });
  }, []);

  return (
    <div>
      <Accordion>
        {questions
          .map((question, index) => {
            return (
              <Accordion.Item eventKey={index}>
                <Accordion.Header>{question.question}</Accordion.Header>
                <Accordion.Body>
                  <div>{parse(question.answer)}</div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })
          .reverse()}
      </Accordion>
    </div>
  );
}

export default FAccordion;
