import React from "react";
import "./wheretobuy.scss";
import ImageOne from "../../../assets/images/deposit-broker.png";
import ImageTwo from "../../../assets/images/trade-broker.png";
import ImageThree from "../../../assets/images/buy-broker.png";
import { useTranslation } from "react-i18next";
function WHstart3() {
  const { t } = useTranslation();
  return (
    <div className="sec-howitworks">
      <div className="container">
        <div className="separator">
          <h2> {t('EC_WS_Question')} </h2>
          <h1> {t('EC_WS_title')} </h1>
          <h5> {t('EC_WS_SubTitle')} </h5>
        </div>
        <div className="row pt-5">
          <div className="col-md-4 text-center">
            <div className="howit-content">
              <div className="howit-img">
                <img src={ImageThree} height="170px" alt="" />
              </div>
              <div className="diamond-shape"></div>
              <div className="howit-text">
                <h3> {t('Global_Buy')} </h3>
                <p className="text-pink">
                {t('EC_WS_Feature_1_description')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="howit-content">
              <div className="howit-img">
                <img src={ImageOne} height="170px" alt="" />
              </div>
              <div className="diamond-shape"></div>
              <div className="howit-text">
                <h3> {t('Global_Deposit')} </h3>
                <p className="text-pink">
                {t('EC_WS_Feature_2_description')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="howit-content">
              <div className="howit-img">
                <img src={ImageTwo} height="170px" alt="" />
              </div>
              <div className="diamond-shape"></div>
              <div className="howit-text">
                <h3> {t('Global_Trade')} </h3>
                <p className="text-pink"> 
                {t('EC_WS_Feature_3_description')}
                 </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WHstart3;
