import React, { useContext } from "react";
import "./transfer.scss";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { getTransferWallet } from "../../../utils/Api";
import { useEffect } from "react";
import { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { TextField } from "@mui/material";
import { Button, Form, Spinner } from "react-bootstrap";
import { ItemNames, getStorage } from "../../../utils/RSS";
import { UserContext } from "../../../contexts/providers/user";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../../home/HeaderMobile";
import BottomNavigationMobile from "../../home/BottomNavigationMobile";

function Transfer() {
  const { t } = useTranslation();
  const errorCatch = (error) => { };

  const { dispatch } = useContext(UserContext);

  const [transfer, setTransfer] = useState({});
  const [amount, setAmount] = useState({});
  useEffect(() => {
    getTransferWallet(setTransfer, errorCatch);
  }, []);

  const [state, setState] = React.useState({
    transferSide: false,
    amount: 0,
  });

  const [switchText, setSwitchText] = useState(t('Transfer_Main_to_Trade'));

  const changeForm = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
    setAmount(event.target.value);
  };
  const switchChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    ChangeSwitchName(event);
  };

  function ChangeSwitchName(event) {
    if (event.target.checked === false) {
      setSwitchText(t('Transfer_Main_to_Trade'));
    } else {
      setSwitchText(t('Transfer_Trade_to_Main'));
    }
  }

  const [selectedToken, setSelectedToken] = useState({});
  const [mainWallet, setMainWallet] = useState(0);
  const [tradeWallet, setTradeWallet] = useState(0);
  const [isLoading, setIsLoading] = useState(false);


  const handleTokenChange = (event) => {
    setSelectedToken(event.target.value);
    setMainWallet(event.target.value.main_amount);
    setTradeWallet(event.target.value.trading_amount);
  };

  const requestToTransfer = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (selectedToken.symbol === undefined) {
      event.preventDefault();
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: t('Transfer_select_Error'),
      });
    } else if (state.amount === 0) {
      event.preventDefault();
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: t('Global_Amount_Error'),
      });
    } else {
      let uri = "https://api.easyexchangepro.com/api/v2/wallet/transfer";
      setIsLoading(true);
      let data = {
        from: state.transferSide === true ? "trading" : "main",
        amount: state.amount,
        currency: selectedToken.symbol,
      };
      fetch(uri, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.status === 1) {
            dispatch({
              type: "open_snack",
              snackColor: "success",
              snackMessage: data.msg,
            });
            getTransferWallet(setTransfer, errorCatch);
            setMainWallet(0);
            setTradeWallet(0);
            setIsLoading(false);
          } else {
            dispatch({
              type: "open_snack",
              snackColor: "warning",
              snackMessage: data.msg,
            });
          }
        });
    }
  };

  const checkValidation = (event) => {
    event.preventDefault();
    event.stopPropagation();
    requestToTransfer(event);
  };

  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);
  if (pageWidth > 768) {
    return (
      <div className="transfer-main">
        <div className="container">
          <div className="row transfer-module">
            <div className="col">
              <div className="row justify-content-between">
                <div className="col-6">
                  <h5>{t('Transfer_Transfer')}</h5>
                </div>
                <div className="col-6">
                  <h6 className="available-trans">
                    {t('Global_Available')}: {state.transferSide ? tradeWallet.toFixed(8) : mainWallet.toFixed(8)}
                  </h6>
                </div>
              </div>

              <Box sx={{ minWidth: 120 }} mt={2}>
                <Form onSubmit={checkValidation}>
                  <FormControl fullWidth>
                    <Select value={selectedToken} onChange={handleTokenChange}>
                      {Object.keys(transfer)
                        .sort((a, b) => a.localeCompare(b))
                        .map((token) => (
                          <MenuItem key={token} value={transfer[token]}>
                            <img
                              src={transfer[token].image}
                              style={{ width: 25, height: 25, marginRight: 10 }}
                              alt={"currency"}
                            />
                            {token}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <div className="mt-4">
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={state.transferSide}
                              onChange={switchChange}
                              name="transferSide"
                            />
                          }
                          label={switchText}
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                  <div className="mt-4">
                    <FormControl fullWidth>
                      <TextField
                        className="withdraw-texts"
                        InputProps={{ sx: { height: 57 } }}
                        id="address"
                        label={t('Global_Amount')}
                        name="amount"
                        size="medium"
                        InputLabelProps={{
                          shrink: true,
                          className: "withdraw-texts",
                        }}
                        onChange={changeForm}
                      />
                    </FormControl>
                  </div>
                  <div className="text-center mt-5">
                    <Button type="submit" disabled={isLoading}>
                      {isLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}
                      {isLoading ? t('Global_Loading') : t('Transfer_Transfer')}
                    </Button>
                  </div>
                </Form>
              </Box>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="transfer-main">
          <div className="container">
            <div className="row transfer-module-m">
              <div className="col">
                <div className="row justify-content-between">
                  <div className="col-6">
                    <h5>{t('Transfer_Transfer')}</h5>
                  </div>
                  <div className="col-6">
                    <h6 className="available-trans">
                      {t('Global_Available')}: {state.transferSide ? (tradeWallet).toFixed(8) : mainWallet.toFixed(8)}
                    </h6>
                  </div>
                </div>

                <Box sx={{ minWidth: 120 }} mt={2}>
                  <Form onSubmit={checkValidation}>
                    <FormControl fullWidth>
                      <Select value={selectedToken} onChange={handleTokenChange}>
                        {Object.keys(transfer)
                          .sort((a, b) => a.localeCompare(b))
                          .map((token) => (
                            <MenuItem key={token} value={transfer[token]}>
                              <img
                                src={transfer[token].image}
                                style={{ width: 25, height: 25, marginRight: 10 }}
                                alt={"currency"}
                              />
                              {token}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <div className="mt-4">
                      <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={state.transferSide}
                                onChange={switchChange}
                                name="transferSide"
                              />
                            }
                            label={switchText}
                          />
                        </FormGroup>
                      </FormControl>
                    </div>
                    <div className="mt-4">
                      <FormControl fullWidth>
                        <TextField
                          className="withdraw-texts"
                          InputProps={{ sx: { height: 57 } }}
                          id="address"
                          label={t('Global_Amount')}
                          name="amount"
                          size="medium"
                          InputLabelProps={{
                            shrink: true,
                            className: "withdraw-texts",
                          }}
                          onChange={changeForm}
                        />
                      </FormControl>
                    </div>
                    <div className="text-center mt-5">
                      <Button type="submit" disabled={isLoading}>
                        {isLoading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : null}
                        {isLoading ? t('Global_Loading') : t('Transfer_Transfer')}
                      </Button>
                    </div>
                  </Form>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Transfer;
