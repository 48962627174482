import React from "react";
import "./wheretobuy.scss";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Applybtn() {
  const { t } = useTranslation();
  return (
    <div className="apply-section">
      <p className="content-apply">
        {t('EC_WA_title')}
      </p>
      <div className="btn-holder">
        <Button className="apply-btn">{t('EC_WA_Decription')}</Button>
      </div>
    </div>
  );
}

export default Applybtn;
