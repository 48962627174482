import { Button } from "react-bootstrap";
import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RotatingLines } from "react-loader-spinner";
import moment from "moment";
import { UserContext } from "../../contexts/providers/user";
import { getWithdrawHistory } from "../../utils/Api";
import { FileCopy } from "@mui/icons-material";

function WithdrawHistory() {
    const { state } = useContext(UserContext);
    const [historyWitData, setHistoryWitData] = useState([]);
    const copyToClipBoard = useRef([]);
    const errorCatch = (error) => { };

    useEffect(() => {
        getWithdrawHistory(setHistoryWitData, errorCatch);
    }, []);

    const handleCopy = (index) => {
        const textToCopy = copyToClipBoard.current[index]?.innerText;
        if (textToCopy) {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(textToCopy);
            } else {
                const tempTextArea = document.createElement("textarea");
                tempTextArea.value = textToCopy;
                document.body.appendChild(tempTextArea);
                tempTextArea.select();
                document.execCommand("copy");
                document.body.removeChild(tempTextArea);
            }
        }
    };


    if (historyWitData === null) {
        return (
            <div className="container-fluid rotating-loader-lines">
                <div className="row justify-content-center">
                    <div className="col text-center">
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="35"
                            visible={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="container wit-his-main">
            <TableContainer component={Paper} className="table-main">
                <Table sx={{ minWidth: 800 }} aria-label="simple table dark">
                    <TableHead className="table-head">
                        <TableRow className="table-row">
                            <TableCell className="table-row" align="left" width={120}>
                                Date & Time              </TableCell>
                            <TableCell className="table-row" align="center" width={110}>
                                Token              </TableCell>
                            <TableCell className="table-row" align="left" width={50}>
                                Amount
                            </TableCell>
                            <TableCell className="table-row" align="left" width={100}>
                                Status
                            </TableCell>
                            <TableCell className="table-row" align="left" width={100}>
                                TxID
                            </TableCell>
                            <TableCell className="table-row" align="left">
                                Copy
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {historyWitData?.map((row, index) => (
                            <TableRow
                                className="table-row"
                                key={row.currency}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell className="table-row" align="left">
                                    {moment(row.updated_at).format('YYYY-MM-DD HH:mm:ss')}
                                </TableCell>
                                <TableCell
                                    className="table-row"
                                    component="th"
                                    scope="row"
                                    align="left"
                                >
                                    <div className="container">
                                        <div className="row d-flex justify-content-start">
                                            <div className="col-6 text-start p-0">
                                                <img
                                                    src={state.currency.images[row.currency]}
                                                    style={{
                                                        width: 25,
                                                        height: 25,
                                                        marginLeft: 10,
                                                        marginBottom: 5,
                                                    }}
                                                    alt={"currency"}
                                                />
                                            </div>
                                            <div className="col-6 text-start">
                                                {row.currency}
                                            </div>
                                        </div>
                                    </div>


                                </TableCell>

                                <TableCell className="table-row" align="left">
                                    {row.amount}
                                </TableCell>
                                <TableCell className={`  ${row.status}`} align="left">
                                    {row.status}
                                </TableCell>
                                <TableCell className="table-row" align="left">
                                    <span ref={(el) => (copyToClipBoard.current[index] = el)} className="span-txid-deposit">
                                        {row?.reference_no}
                                    </span>
                                    {row.reference_no}
                                </TableCell>

                                <TableCell className="table-row" align="left">
                                    <Button
                                        onClick={() => handleCopy(index)}
                                        endIcon={<FileCopy fontSize="10" />}
                                        className="market-table-btn"
                                    >
                                        Copy
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default WithdrawHistory;
