import Accordion from "react-bootstrap/Accordion";
import TradeChart from "./TradeChartE";
import { useTranslation } from "react-i18next";

function ChartDropdown() {
  const { t } = useTranslation();

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t('Trade_Open_Chart')}</Accordion.Header>
        <Accordion.Body>
          <TradeChart />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default ChartDropdown;
