import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { ItemNames, getStorage } from "../../../../utils/RSS";

let tvScriptLoadingPromise;

export default function TradeMainChart(props) {
  const onLoadScriptRef = useRef();
  const [chartTheme, setChartTheme] = useState("light");

  useEffect(() => {
    if (
      getStorage(ItemNames.THEMES) === "light-theme" ||
      getStorage(ItemNames.THEMES) === "" ||
      getStorage(ItemNames.THEMES) === undefined
    ) {
      setChartTheme("light");
    } else {
      setChartTheme("dark");
    }
  });
  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }
    if (tvScriptLoadingPromise) {
      tvScriptLoadingPromise.then(
        () => onLoadScriptRef.current && onLoadScriptRef.current()
      );
    }

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      let options = {
        height: 367,
        width: { calculatedWidth },
        // autosize: true,
        symbol: `BINANCE:${props.tradePair.replace("_", "")}`,
        interval: "D",
        timezone: "Etc/UTC",
        theme: chartTheme,
        style: "1",
        locale: "en",
        toolbar_bg: "#f1f3f6",
        enable_publishing: false,
        // hide_top_toolbar: true,
        // hide_legend: true,
        container_id: "tradingview_5d703",
      };

      new window.TradingView.widget(options);
    }
  }, [props.tradePair, chartTheme]);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const calculatedWidth = pageWidth - 50;
  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="tradingview-widget-container">
      <div id="tradingview_5d703" />
    </div>
  );
}
