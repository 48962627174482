import React, { useEffect, useState } from "react";
import "./guaranteefaq.scss";
import { Link, NavLink } from "react-router-dom";
import ScrollToTop from "../../../utils/ScrollToTop";
import { t } from "i18next";
import HeaderMobile from "../../home/HeaderMobile";
import BottomNavigationMobile from "../../home/BottomNavigationMobile";
function Guaranteefaq() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);
  if (pageWidth > 768) {
    return (
      <>
        <div className="container-fluid guarantee-information">
          <div className="container p-4 guarantee-section">
            <div className="row">
              <div className="col">
                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q1')}
                </p>
                <p>
                  {t('EG_EGFaq_A1')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q2')}
                </p>
                <p>
                  {t('EG_EGFaq_A2')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q3')}
                </p>
                <p>
                  {t('EG_EGFaq_A3')}
                </p>
                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q4')}
                </p>
                <p>
                  {t('EG_EGFaq_A4')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q5')}
                </p>
                <p>
                  {t('EG_EGFaq_A5')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q6')}
                </p>
                <p>
                  {t('EG_EGFaq_A6')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q7')}
                </p>
                <p>
                  {t('EG_EGFaq_A7')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q8')}
                </p>
                <p>
                  {t('EG_EGFaq_A8')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q9')}
                </p>
                <p>
                  {t('EG_EGFaq_A9')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q10')}
                </p>
                <p>
                  {t('EG_EGFaq_A10')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q11')}
                </p>
                <p>
                  {t('EG_EGFaq_A11')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q12')}
                </p>
                <p>
                  {t('EG_EGFaq_A12')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q13')}
                </p>
                <p>
                  {t('EG_EGFaq_A13')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q14')}
                </p>
                <p>
                  {t('EG_EGFaq_A14')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q15')}
                </p>
                <p>
                  {t('EG_EGFaq_A15')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q16')}
                </p>
                <p>
                  {t('EG_EGFaq_A16')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q17')}
                </p>
                <p>
                  {t('EG_EGFaq_A17')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q18')}
                </p>
                <p>
                  {t('EG_EGFaq_A18')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q19')}
                </p>
                <p>
                  {t('EG_EGFaq_A19')}
                </p>
                <p>
                  <NavLink as={Link} to="/easyguaranteeform" className="nav-link">
                    {t('EG_EGFaq_Application_form')}
                    <ScrollToTop />
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="container-fluid guarantee-information">
          <div className="container p-4 guarantee-section" style={{ marginTop: 50, marginBottom: 50 }}>
            <div className="row">
              <div className="col">
                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q1')}
                </p>
                <p>
                  {t('EG_EGFaq_A1')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q2')}
                </p>
                <p>
                  {t('EG_EGFaq_A2')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q3')}
                </p>
                <p>
                  {t('EG_EGFaq_A3')}
                </p>
                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q4')}
                </p>
                <p>
                  {t('EG_EGFaq_A4')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q5')}
                </p>
                <p>
                  {t('EG_EGFaq_A5')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q6')}
                </p>
                <p>
                  {t('EG_EGFaq_A6')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q7')}
                </p>
                <p>
                  {t('EG_EGFaq_A7')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q8')}
                </p>
                <p>
                  {t('EG_EGFaq_A8')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q9')}
                </p>
                <p>
                  {t('EG_EGFaq_A9')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q10')}
                </p>
                <p>
                  {t('EG_EGFaq_A10')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q11')}
                </p>
                <p>
                  {t('EG_EGFaq_A11')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q12')}
                </p>
                <p>
                  {t('EG_EGFaq_A12')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q13')}
                </p>
                <p>
                  {t('EG_EGFaq_A13')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q14')}
                </p>
                <p>
                  {t('EG_EGFaq_A14')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q15')}
                </p>
                <p>
                  {t('EG_EGFaq_A15')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q16')}
                </p>
                <p>
                  {t('EG_EGFaq_A16')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q17')}
                </p>
                <p>
                  {t('EG_EGFaq_A17')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q18')}
                </p>
                <p>
                  {t('EG_EGFaq_A18')}
                </p>

                <p className="font-weight-bold pt-4">
                  {t('EG_EGFaq_Q19')}
                </p>
                <p>
                  {t('EG_EGFaq_A19')}
                </p>
                <p>
                  <NavLink as={Link} to="/easyguaranteeform" className="nav-link">
                    {t('EG_EGFaq_Application_form')}
                    <ScrollToTop />
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Guaranteefaq;
