import React from "react";
import { useTranslation } from "react-i18next";

function ARoadmap() {
  const { t } = useTranslation();
  return (
    <>
      <section className="section3">
        <h2 className="h2s3"> {t('About_road_title')} </h2>
        <h3 className="h3s3">
          {t('About_road_description_1')}
          <br />
          {t('About_road_description_2')}

        </h3>
      </section>
      <section id="timeline">
        <div className="tl-item">
          <div className="tl-bg"></div>

          <div className="tl-year">
            <p className="f2 heading--sanSerif">{t('About_road_year_1')}</p>
          </div>

          <div className="tl-content">
            <ul>
              <li> Unique product: Easy Guarantee </li>
              <li> + 2 languages </li>
              <li> + 10 brokers in 5 countries </li>
              <li> + 10 more employees </li>
              <li> + 10 more pairs </li>
            </ul>
          </div>
        </div>

        <div className="tl-item">
          <div className="tl-bg"></div>

          <div className="tl-year">
            <p className="f2 heading--sanSerif">{t('About_road_year_2')}</p>
          </div>

          <div className="tl-content">
            <ul>
              <li> New product, Easy Card  </li>
              <li> API for business integration </li>
              <li> The easy card will be an efficient method for transactions </li>
              <li> + 2 Languages </li>
              <li>  + 25 employees </li>
              <li> + 10 pairs </li>
            </ul>
          </div>
        </div>

        <div className="tl-item">
          <div className="tl-bg"></div>

          <div className="tl-year">
            <p className="f2 heading--sanSerif">{t('About_road_year_3')}</p>
          </div>

          <div className="tl-content">
            <ul>
              <li> New product, Easy Wallet </li>
              <li>Easy pro Academy</li>
              {/* <li> {t('About_road_year_2_description_2')} </li>
              <li> {t('About_road_year_2_description_3')} </li> */}
              {/* <li> {t('About_road_year_2_description_4')} </li> */}
              <li> + 2 Languages </li>
              {/* <li> {t('About_road_year_2_description_6')} </li> */}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default ARoadmap;
