import React, { useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";

import MarketTable from "./MarketTable";
import { useTranslation } from "react-i18next";

function MarketTabs() {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Tabs value={currentTabIndex} onChange={handleTabChange}>
        <Tab label={t('Market_tab_Spot')} />
      </Tabs>

      {/* TAB 1 Contents */}
      {currentTabIndex === 0 && (
        <Box sx={{ p: 3 }}>
          <MarketTable />
        </Box>
      )}

      {/* TAB 2 Contents */}
      {currentTabIndex === 1 && (
        <Box sx={{ p: 3 }}>
          <Typography variant="h5">{t('Market_tab_Favorite')}</Typography>
        </Box>
      )}
    </React.Fragment>
  );
}

export default MarketTabs;
