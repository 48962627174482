import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  ModalFooter,
  Spinner,
} from "react-bootstrap";
import TradeSlider from "./SliderB";
import { ItemNames, getStorage, isLoggedIn } from "../../../../utils/RSS";
import { sendRequestEmit } from "../../../../contexts/reducer/socket";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { UserContext } from "../../../../contexts/providers/user";
import { toFixed } from "../../../../utils/math";
import { useTranslation } from "react-i18next";

function Limit({
  side = "sell",
  from,
  to,
  balance_from,
  balance_to,
  last_price,
}) {
  const { t } = useTranslation();
  const { tradePair } = useParams();
  const { state, dispatch } = useContext(UserContext);
  const [limitForm, setLimitForm] = useState({
    price: 0,
    amount: 0,
    total: 0,
  });

  useLayoutEffect(() => {
    setLimitForm({
      price: 0,
      amount: 0,
      total: 0,
    });
  }, [tradePair]);

  useEffect(() => {
    if (limitForm.price === 0 && last_price !== null) {
      setLimitForm({
        ...limitForm,
        price: last_price,
      });
    }
  }, [last_price, limitForm, setLimitForm]);

  const inputChange = (name) => {
    return (event) => {
      const form = limitForm;
      form[name] = event.target.value;
      let value = event.target.value;
      value = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      const decimalIndex = value.indexOf(".");

      switch (name) {
        case "price":
          if (decimalIndex !== -1) {
            const decimals = state.currency.details[from].price_decimal || 4;
            const decimalPlaces = value.length - decimalIndex - 1;
            if (decimalPlaces > decimals) {
              value = value.slice(0, decimalIndex + decimals + 1);
            }
          }

          break;
        case "amount":
          if (decimalIndex !== -1) {
            const decimals = state.currency.details[from].amount_decimal || 4;
            const decimalPlaces = value.length - decimalIndex - 1;
            if (decimalPlaces > decimals) {
              value = value.slice(0, decimalIndex + decimals + 1);
            }
          }
          form.total = `${toFixed(
            parseFloat(form.price * form.amount),
            state.currency.details[to].total_decimal || 4
          )}`;
          break;
        case "total":
          if (decimalIndex !== -1) {
            const decimals = state.currency.details[to].total_decimal || 4;
            const decimalPlaces = value.length - decimalIndex - 1;
            if (decimalPlaces > decimals) {
              value = value.slice(0, decimalIndex + decimals + 1);
            }
          }
          form.amount = `${toFixed(
            parseFloat(form.total / form.price),
            state.currency.details[from].amount_decimal || 4
          )}`;
          break;
        default:
          if (form.amount === 0 && form.total !== 0) {
            form.amount = `${toFixed(
              parseFloat(form.total / form.price),
              state.currency.details[from].amount_decimal || 4
            )}`;
          } else {
            form.total = `${toFixed(
              parseFloat(form.price * form.amount),
              state.currency.details[to].total_decimal || 4
            )}`;
          }
          break;
      }
      form[name] = value;
      setLimitForm({ ...form });
    };
  };

  const sendLimitRequest = () => {
    setIsloading(true);
    var data = {
      amount: limitForm.amount,
      price: limitForm.price,
      pair: `${from}_${to}`,
      order: "limit",
      type: side,
      ip_address: "1.1.1.1",
      trading_pwd: state.trading_password,
      token: getStorage(ItemNames.TOKEN),
      device: "web",
    };
    sendRequestEmit("trade:limit", data);
    setLimitForm({
      price: 0,
      amount: 0,
      total: 0,
    });
    setIsloading(false);
  };

  const slideChange = (e) => {
    let percentage = e.target.textContent;
    let amount_calculated = balance_to * (percentage / 100);
    let total_calculated = balance_from * (percentage / 100);
    if (side === "buy") {
      setLimitForm({
        ...limitForm,
        total: toFixed(
          parseFloat(amount_calculated),
          state.currency.details[to].amount_decimal || 6
        ),
        amount: toFixed(
          parseFloat(amount_calculated / limitForm.price),
          state.currency.details[from].amount_decimal || 6
        ),
      });
    } else {
      setLimitForm({
        ...limitForm,
        amount: toFixed(
          parseFloat(total_calculated),
          state.currency.details[from].amount_decimal || 6
        ),
        total: toFixed(
          parseFloat(total_calculated * limitForm.price),
          state.currency.details[to].amount_decimal || 6
        ),
      });
    }
  };

  const navigate = useNavigate();
  const goToKYCPage = () => {
    navigate("/profile");
  };

  const goToLoginPage = () => {
    navigate("/login");
  };

  const checkBTNState = () => {
    if (isLoggedIn() === true) {
      if (state.user_profile.kyc_status !== 3) {
        return (
          <Button
            variant="success"
            className="btn-limit-buy"
            onClick={goToKYCPage}
          >
            {t('Trade_KYC_Error')}
          </Button>
        );
      } else if (state.trading_password === "") {
        return (
          <Button
            variant="success"
            className="btn-limit-buy"
            onClick={openTradingPass}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            {isLoading ? t('Global_Loading') : t('Trade_Trading_Password_btn')}
          </Button>
        );
      } else {
        return (
          <Button
            variant="success"
            className={side === "sell" ? "btn-limit-sell" : "btn-limit-buy"}
            onClick={warningHandlerLimit}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            {isLoading ? t('Global_Loading') : `${side === "sell" ? t('Global_Sell') : t('Global_Buy')}`}
          </Button>
        );
      }
    } else if (isLoggedIn() === false) {
      return (
        <Button
          variant="success"
          className="btn-limit-buy"
          onClick={goToLoginPage}
        >
          {t('Trade_Login_Register')}
        </Button>
      );
    }
  };
  const [isLoading, setIsloading] = useState(false);

  const [showTPWDstate, setShowTPWDstate] = useState(false);
  const handleCloseTPWDstate = () => setShowTPWDstate(false);
  const handleShowTPWDstate = () => setShowTPWDstate(true);

  const [showTPWDinput, setShowTPWDinput] = useState(false);
  const handleCloseTPWDinput = () => setShowTPWDinput(false);
  const handleShowTPWDinput = () => setShowTPWDinput(true);

  const [tradingPassword, setTradingPassword] = useState(null);

  const handleChangeTradingPassword = (event) => {
    setTradingPassword(event.target.value);
  };

  const openTradingPass = () => {
    if ("trading_pwd" === "trading_pwd1") {
      handleShowTPWDstate();
    } else {
      handleShowTPWDinput();
    }
  };

  const goToTPWDpage = () => {
    navigate("/profile");
  };

  const checkTradingPwdValidity = () => {
    if (tradingPassword === null || tradingPassword === "") {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: t('Trade_Password_Error'),
      });
    } else {
      conFirmTPWD();
    }
  };

  const conFirmTPWD = () => {
    setIsloading(true);
    var uri = "https://api.easyexchangepro.com/api/v2/user/trading/check";
    var data = { password: tradingPassword };
    fetch(uri, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status === 1) {
          dispatch({ type: "set:trading_pass", password: tradingPassword });
          dispatch({
            type: "open_snack",
            snackColor: "success",
            snackMessage: data.msg,
          });
          handleCloseTPWDinput();
          setIsloading(false);
        } else {
          dispatch({
            type: "open_snack",
            snackColor: "warning",
            snackMessage: data.msg,
          });
          setIsloading(false);
        }
      });
  };

  const minimumOrderLimit = 20;
  const balanceToDecimaled = `${toFixed(
    parseFloat(balance_to),
    state.currency.details[to].amount_decimal || 4
  )}`;
  const balanceFromDecimaled = `${toFixed(
    parseFloat(balance_from),
    state.currency.details[to].amount_decimal || 4
  )} `;
  const warningHandlerLimit = () => {
    if (
      limitForm.price === "" ||
      limitForm.price === null ||
      limitForm.price === 0
    ) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: t('Global_Price_Error'),
      });
    } else if (
      limitForm.amount === "" ||
      limitForm.amount === null ||
      limitForm.amount === 0
    ) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: t('Global_Amount_Error'),
      });
    } else if (
      limitForm.total === "" ||
      limitForm.total === null ||
      limitForm.total === 0
    ) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: t('Global_Total_Error'),
      });
    } else if (limitForm.total < minimumOrderLimit) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: t('Trade_Min_Error'),
      });
    } else if (side === "buy" && limitForm.total > balance_to) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: `${t('Trade_Balance_Error')} ${balanceToDecimaled} ${to}`,
      });
    } else if (side === "sell" && limitForm.amount > balance_from) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: `${t('Trade_Balance_Error')} ${balanceFromDecimaled} ${from}`,
      });
    } else {
      sendLimitRequest();
    }
  };
  return (
    <div className="limit-buy-main">
      <div className="row">
        <div className="col available-section">
          <p>{t('Global_Available')}:</p>
          <p>
            {side === "sell"
              ? `${toFixed(
                  parseFloat(balance_from),
                  state.currency.details[from].amount_decimal || 6
                )} ${from}`
              : `${toFixed(
                  parseFloat(balance_to),
                  state.currency.details[to].amount_decimal || 4
                )} ${to}`}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <InputGroup className="mb-1">
            <InputGroup.Text>{t('Global_Price')}</InputGroup.Text>
            <Form.Control
              aria-label="price"
              onChange={inputChange("price")}
              value={limitForm.price}
            />
            <InputGroup.Text>{to}</InputGroup.Text>
          </InputGroup>
          <InputGroup className="mb-1">
            <InputGroup.Text>{t('Global_Amount')}</InputGroup.Text>
            <Form.Control
              aria-label="amount"
              onChange={inputChange("amount")}
              value={limitForm.amount}
            />
            <InputGroup.Text>{from}</InputGroup.Text>
          </InputGroup>
          <div className="row">
            <div className="col slider-trade">
              <TradeSlider onchange={slideChange} />
            </div>
          </div>
          <InputGroup className="">
            <InputGroup.Text>{t('Global_Total')}</InputGroup.Text>
            <Form.Control
              aria-label="price"
              value={limitForm.total}
              onChange={inputChange("total")}
            />
            <InputGroup.Text>{to}</InputGroup.Text>
          </InputGroup>
          <div className="col">{checkBTNState()}</div>
        </div>
      </div>
      <Modal
        show={showTPWDstate}
        onHide={handleCloseTPWDstate}
        className="modal-withdraw"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Trade_Trading_password_Error')}</Modal.Title>
        </Modal.Header>
        <ModalFooter>
          <Button className="cancel-withdraw" onClick={handleCloseTPWDstate}>
          {t('Global_Cancel')}
          </Button>
          <Button className="confirm-withdraw" onClick={goToTPWDpage}>
          {t('Trade_goto_security')}
          </Button>
        </ModalFooter>
      </Modal>
      <>
        <Modal
          show={showTPWDinput}
          onHide={handleCloseTPWDinput}
          className="modal-withdraw"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('Trade_Trading_password_title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-list">
            <FormControl fullWidth>
              <TextField
                className="withdraw-texts"
                InputProps={{ sx: { height: 57 } }}
                id="tradePass"
                label={t('Trade_Trading_password_input')}
                size="medium"
                type="password"
                name="trading_password"
                onChange={handleChangeTradingPassword}
                InputLabelProps={{
                  shrink: true,
                  className: "withdraw-texts",
                }}
                inputProps={{
                  maxLength: 6,

                  form: {
                    autocomplete: "off",
                  },
                }}
              />
            </FormControl>
          </Modal.Body>
          <ModalFooter>
            <Button className="cancel-withdraw" onClick={handleCloseTPWDinput}>
            {t('Global_Cancel')}
            </Button>
            <Button
              className="confirm-withdraw"
              onClick={checkTradingPwdValidity}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              {isLoading ? t('Global_Loading') : t('Global_Confirm')}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    </div>
  );
}

export default Limit;
