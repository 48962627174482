import { React, useContext, useEffect, useState } from "react";
import OTC1 from "../../../assets/images/otc1.png";
import OTC2 from "../../../assets/images/otc2.png";
import OTC3 from "../../../assets/images/otc3.png";
import OTC4 from "../../../assets/images/otc4.png";
import OTC5 from "../../../assets/images/otc5.png";
import OTC6 from "../../../assets/images/otc6.png";
import OTC7 from "../../../assets/images/otc7.png";
import OTC8 from "../../../assets/images/otc8.png";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { TextareaAutosize } from "@mui/material";
import "./otc.scss";
import { ItemNames, getStorage } from "../../../utils/RSS";
import { UserContext } from "../../../contexts/providers/user";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../../home/HeaderMobile";
import BottomNavigationMobile from "../../home/BottomNavigationMobile";

function Otc() {
  const { state, dispatch } = useContext(UserContext);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleOTCSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      setIsLoading(true);
      event.stopPropagation();
      const otcForm = new FormData(event.currentTarget);
      try {
        let res = await fetch(
          "https://api.easyexchangepro.com/api/v2/request/otc",
          {
            method: "POST",
            body: JSON.stringify({
              name: otcForm.get("name"),
              familyname: otcForm.get("familyname"),
              mobile: otcForm.get("mobile"),
              email: otcForm.get("email"),
              comment: otcForm.get("comment"),
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
            },
          }
        );

        let resJson = await res.json();

        if (resJson.status === 1) {
          dispatch({
            snackColor: "success",
            snackMessage: resJson.msg,
            type: "open_snack",
          });
          dispatch({ type: "toggle_modal", content: resJson.msg });
          event.target.reset();
          setValidated(false);
        } else {
          dispatch({
            snackColor: "warning",
            snackMessage: resJson.msg,
            type: "open_snack",
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const { t } = useTranslation();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);

  if (pageWidth > 768) {
    return (
      <div className="broker-reg-page pt-4 py-0">
        <div className="container">
          <div className="row mb-3">
            <div className="col-lg-12">
              <h3>{t('OTC_title')}</h3>
            </div>
          </div>
          <div className="row mb-5 align-items-center">
            <div className="col-lg-12">
              <p>
                {t('OTC_description')}
              </p>
            </div>
          </div>
          <div className="row otc-div justify-content-center">
            <div className="col-lg-3">
              <div className="otc-det-div p-3">
                <div className="imgdiv">
                  <img
                    src={OTC1}
                    className="d-block mx-auto img-fluid"
                    alt="otc"
                  />
                </div>
                <p>
                  {t('OTC_Feature_1')}
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="otc-det-div p-3">
                <div className="imgdiv">
                  <img
                    src={OTC2}
                    className="d-block mx-auto img-fluid"
                    alt="otc"
                  />
                </div>
                <p>
                  {t('OTC_Feature_2')}

                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="otc-det-div p-3">
                <div className="imgdiv">
                  <img
                    src={OTC3}
                    className="d-block mx-auto img-fluid"
                    alt="otc"
                  />
                </div>
                <p>
                  {t('OTC_Feature_3')}{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="otc-det-div p-3">
                <div className="imgdiv">
                  <img
                    src={OTC4}
                    className="d-block mx-auto img-fluid"
                    alt="otc"
                  />
                </div>
                <p>
                  {t('OTC_Feature_4')}
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="otc-det-div p-3">
                <div className="imgdiv">
                  <img
                    src={OTC5}
                    className="d-block mx-auto img-fluid"
                    alt="otc"
                  />
                </div>
                <p>{t('OTC_Feature_5')} </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="otc-det-div p-3">
                <div className="imgdiv">
                  <img
                    src={OTC6}
                    className="d-block mx-auto img-fluid"
                    alt="otc"
                  />
                </div>
                <p>
                  {t('OTC_Feature_6')}
                  {" "}
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="otc-det-div p-3">
                <div className="imgdiv">
                  <img
                    src={OTC7}
                    className="d-block mx-auto img-fluid"
                    alt="otc"
                  />
                </div>
                <p>
                  {t('OTC_Feature_7')}
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="otc-det-div p-3">
                <div className="imgdiv">
                  <img
                    src={OTC8}
                    className="d-block mx-auto img-fluid"
                    alt="otc"
                  />
                </div>
                <p>
                  {t('OTC_Feature_8')}
                  {" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="support-otc-div py-5">
          <div className="container">
            <h5>{t('OTC_Support_title')}</h5>
            <p>
              {t('OTC_Support_description')}
            </p>
            <ul className="pl-3 my-5">
              <li>{t('OTC_list_1')}</li>
              <li>{t('OTC_list_2')}</li>
              <li>
                {t('OTC_list_3')}
              </li>
              <li>
                {t('OTC_list_4')}
              </li>
            </ul>
            <div className="row">
              <div className="col-lg-8">
                <div className="container">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleOTCSubmit}
                  >
                    <div className="row mt-5 justify-content-center">
                      <div className="col-11">
                        <InputGroup className="mt-2">
                          <Form.Control
                            type="text"
                            placeholder={t('Global_FName')}
                            name="name"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t('Global_FName_Error')}
                          </Form.Control.Feedback>
                        </InputGroup>
                        <InputGroup className="mt-2">
                          <Form.Control
                            type="text"
                            placeholder={t('Global_LName')}
                            name="familyname"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t('Global_LName_Error')}
                          </Form.Control.Feedback>
                        </InputGroup>
                        <InputGroup className="mt-2">
                          <Form.Control
                            type="text"
                            placeholder={t('Global_Mobile')}
                            name="mobile"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t('Global_Mobile_Error')}
                          </Form.Control.Feedback>
                        </InputGroup>
                        <InputGroup className="mt-2">
                          <Form.Control
                            type="email"
                            placeholder={t('Global_Email')}
                            name="email"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t('Global_Email_Error')}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-11">
                        <Form.Group className="mt-2">
                          <Form.Control
                            as={TextareaAutosize}
                            maxRows={3}
                            placeholder={t('Global_Purpose')}
                            name="purpose"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t('Global_Purpose_Error')}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row mt-5 justify-content-center">
                      <div className="col-11">
                        <Form.Check
                          type="checkbox"
                          id=""
                          label={t('Global_Accept_Terms')}
                          className="mt-2"
                          name="legalname"
                          required
                          feedback={t('Global_Accept_Terms_Error')}
                          feedbackType="invalid"
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-2 text-center">
                        <Button
                          className="submit-btn mt-3"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : null}
                          {isLoading ? t('Global_Loading') : t('Global_Submit')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="broker-reg-page pt-4 py-0">
          <div className="container mt-4">
            <div className="row mb-3">
              <div className="col-lg-12">
                <h3>{t('OTC_title')}</h3>
              </div>
            </div>
            <div className="row mb-5 align-items-center">
              <div className="col-lg-12">
                <p>
                  {t('OTC_description')}
                </p>
              </div>
            </div>
            <div className="row otc-div justify-content-center">
              <div className="col-lg-3">
                <div className="otc-det-div p-3">
                  <div className="imgdiv">
                    <img
                      src={OTC1}
                      className="d-block mx-auto img-fluid"
                      alt="otc"
                    />
                  </div>
                  <p>
                    {t('OTC_Feature_1')}
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="otc-det-div p-3">
                  <div className="imgdiv">
                    <img
                      src={OTC2}
                      className="d-block mx-auto img-fluid"
                      alt="otc"
                    />
                  </div>
                  <p>
                    {t('OTC_Feature_2')}

                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="otc-det-div p-3">
                  <div className="imgdiv">
                    <img
                      src={OTC3}
                      className="d-block mx-auto img-fluid"
                      alt="otc"
                    />
                  </div>
                  <p>
                    {t('OTC_Feature_3')}{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="otc-det-div p-3">
                  <div className="imgdiv">
                    <img
                      src={OTC4}
                      className="d-block mx-auto img-fluid"
                      alt="otc"
                    />
                  </div>
                  <p>
                    {t('OTC_Feature_4')}
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="otc-det-div p-3">
                  <div className="imgdiv">
                    <img
                      src={OTC5}
                      className="d-block mx-auto img-fluid"
                      alt="otc"
                    />
                  </div>
                  <p>{t('OTC_Feature_5')} </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="otc-det-div p-3">
                  <div className="imgdiv">
                    <img
                      src={OTC6}
                      className="d-block mx-auto img-fluid"
                      alt="otc"
                    />
                  </div>
                  <p>
                    {t('OTC_Feature_6')}
                    {" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="otc-det-div p-3">
                  <div className="imgdiv">
                    <img
                      src={OTC7}
                      className="d-block mx-auto img-fluid"
                      alt="otc"
                    />
                  </div>
                  <p>
                    {t('OTC_Feature_7')}
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="otc-det-div p-3">
                  <div className="imgdiv">
                    <img
                      src={OTC8}
                      className="d-block mx-auto img-fluid"
                      alt="otc"
                    />
                  </div>
                  <p>
                    {t('OTC_Feature_8')}
                    {" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="support-otc-div py-5" style={{ marginBottom: 100 }}>
            <div className="container">
              <h5>{t('OTC_Support_title')}</h5>
              <p>
                {t('OTC_Support_description')}
              </p>
              <ul className="pl-3 my-5">
                <li>{t('OTC_list_1')}</li>
                <li>{t('OTC_list_2')}</li>
                <li>
                  {t('OTC_list_3')}
                </li>
                <li>
                  {t('OTC_list_4')}
                </li>
              </ul>
              <div className="row">
                <div className="col-lg-8">
                  <div className="container">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleOTCSubmit}
                    >
                      <div className="row mt-5 justify-content-center">
                        <div className="col-11">
                          <InputGroup className="mt-2">
                            <Form.Control
                              type="text"
                              placeholder={t('Global_FName')}
                              name="name"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {t('Global_FName_Error')}
                            </Form.Control.Feedback>
                          </InputGroup>
                          <InputGroup className="mt-2">
                            <Form.Control
                              type="text"
                              placeholder={t('Global_LName')}
                              name="familyname"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {t('Global_LName_Error')}
                            </Form.Control.Feedback>
                          </InputGroup>
                          <InputGroup className="mt-2">
                            <Form.Control
                              type="text"
                              placeholder={t('Global_Mobile')}
                              name="mobile"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {t('Global_Mobile_Error')}
                            </Form.Control.Feedback>
                          </InputGroup>
                          <InputGroup className="mt-2">
                            <Form.Control
                              type="email"
                              placeholder={t('Global_Email')}
                              name="email"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {t('Global_Email_Error')}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-11">
                          <Form.Group className="mt-2">
                            <Form.Control
                              as={TextareaAutosize}
                              maxRows={3}
                              placeholder={t('Global_Purpose')}
                              name="purpose"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {t('Global_Purpose_Error')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row mt-5 justify-content-center">
                        <div className="col-11">
                          <Form.Check
                            type="checkbox"
                            id=""
                            label={t('Global_Accept_Terms')}
                            className="mt-2"
                            name="legalname"
                            required
                            feedback={t('Global_Accept_Terms_Error')}
                            feedbackType="invalid"
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-2 text-center">
                          <Button
                            className="submit-btn mt-3"
                            type="submit"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null}
                            {isLoading ? t('Global_Loading') : t('Global_Submit')}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Otc;
