import React, { useEffect, useState } from "react";
import "./faq.scss";
import FAccordion from "./FAccordion";
import HeaderMobile from "../home/HeaderMobile";
import BottomNavigationMobile from "../home/BottomNavigationMobile";
function Faq() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);
  if (pageWidth > 768) {
    return (
      <div className="faq-main">
        <div className="faq-module container">
          <FAccordion />
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="faq-main">
          <div className="faq-module container" style={{ marginTop: 150, marginBottom: 200 }}>
            <FAccordion />
          </div>
        </div>
      </>
    );
  }
}

export default Faq;
