import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value) {
  return `${value}°C`;
}

export default function TradeSlider({ onchange = () => {} }) {
  return (
    <Box>
      <Slider
        aria-label="Temperature"
        defaultValue={0}
        getAriaValueText={valuetext}
        step={25}
        marks
        min={0}
        max={100}
        valueLabelDisplay="auto"
        color="warning"
        onChangeCommitted={onchange}
      />
    </Box>
  );
}
