import { React, useState, useContext } from "react";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { ItemNames, getStorage } from "../../utils/RSS";
import { UserContext } from "../../contexts/providers/user";
import CountryList from "../../utils/Countrylist";
import "./profile.scss";
import { useTranslation } from "react-i18next";
function Kyc({ profile }) {
  const [validated, setValidated] = useState(false);
  const { state, dispatch } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const [kycForm, setKycForm] = useState({
    firstname: "",
    lastname: "",
    id_number: "",
    id_type: "",
    id_proof: "",
    id_proof1: "",
    selfie_proof: "",
  });

  const onChangeKycForm = (e) => {
    setKycForm({
      ...kycForm,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeKycFileForm = (e) => {
    setKycForm({
      ...kycForm,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleTokenListingSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setIsLoading(true)
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      event.stopPropagation();
      const formU = new FormData();
      Object.keys(kycForm).forEach((item) => {
        formU.append(item, kycForm[item]);
      });
      try {
        let res = await fetch(
          "https://api.easyexchangepro.com/api/v2/request/update/kyc",
          {
            method: "POST",
            body: formU,
            headers: {
              Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
            },
          }
        );
        let resJson = await res.json();
        if (resJson.status === 1) {
          dispatch({ type: "toggle_modal", content: resJson.msg });
          event.target.reset();
          setValidated(true);
          setIsLoading(false)
        } else {
          setIsLoading(false)
          dispatch({
            type: "open_snack",
            snackColor: "warning",
            snackMessage: resJson.msg,
          });
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false)
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: 'Failed',
        });
      }
    }
  };
  const { t } = useTranslation();

  if (profile.kyc_status === 3) {
    return <div className="brokerform-main">{t('Profile_KYC_Approved')}</div>;
  } else if (profile.kyc_status === 1) {
    return <div className="brokerform-main">{t('Profile_KYC_Pending')}</div>;
  }
  return (
    <div className="brokerform-main">
      <Form onSubmit={handleTokenListingSubmit}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-8 col-sm-5  m-5">
              <h4>{t('Profile_KYC_title')}</h4>
              <InputGroup className="mt-2">
                <Form.Control
                  type="text"
                  placeholder={t('Global_FName')}
                  onChange={onChangeKycForm}
                  name="firstname"
                  required
                />
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('Global_LName')}
                  onChange={onChangeKycForm}
                  name="lastname"
                  required
                />
              </InputGroup>
              <div className="col country-btn mt-4">
                <CountryList />
              </div>
              <InputGroup className="mt-4">
                <Form.Select
                  aria-label="Default select example"
                  onChange={onChangeKycForm}
                  name="id_type"
                  required
                >
                  <option>{t('Profile_KYC_select_doc')}</option>
                  <option value="1">{t('Profile_KYC_select_doc_ID')}</option>
                  <option value="2">{t('Profile_KYC_select_doc_pass')}</option>
                  <option value="2">{t('Profile_KYC_select_doc_Drive')}</option>
                </Form.Select>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('Global_IDNum')}
                  onChange={onChangeKycForm}
                  name="id_number"
                  required
                />
              </InputGroup>
            </div>
          </div>
          <h4 className="m-4">{t('Profile_KYC_Upload_title')}</h4>
          <h6 className="m-4">{t('Global_Max_upload')}</h6>
          <div className="row   ms-5">
            <div className="col-11 col-sm-8 col-lg-5">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>{t('Profile_KYC_Front')}</Form.Label>
                <Form.Control
                  type="file"
                  required
                  name="id_proof"
                  onChange={onChangeKycFileForm}
                />
              </Form.Group>
            </div>
            <div className="col-11 col-sm-8 col-lg-5  ">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>{t('Profile_KYC_Back')}</Form.Label>
                <Form.Control
                  type="file"
                  required
                  name="id_proof1"
                  onChange={onChangeKycFileForm}
                />
              </Form.Group>
            </div>
            <div className="col-11 col-sm-8 col-lg-5  ">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>{t('Profile_KYC_Selfie')}</Form.Label>
                <Form.Control
                  type="file"
                  required
                  name="selfie_proof"
                  onChange={onChangeKycFileForm}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row m-2">
            <h6>{t('Global_Policy')}</h6>
            <div className="col-1">
              <Button
                className="submit-btn m-3"
                disabled={isLoading}
                type="submit"
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}

                {isLoading ? t('Global_Loading') : t('Global_Submit')}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default Kyc;
