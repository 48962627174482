import { io } from "socket.io-client";

let socket = null;
let socket_data = {
    trade_pair: null,
    callback_map: {

    }
}
let tradeWS_URL = 'wss://traapi.easyexchangepro.com';


export const listenToEvent = (eventName, cb) => {
    if (socket_data.callback_map[eventName] != null) {
        socket?.off(eventName, cb)
    }
    socket_data.callback_map[eventName] = true
    socket?.on(eventName, cb)
}

export const sendRequestEmit = (event, data) => {
    socket?.emit(event, data);
}

export const reducerIO = (state, action) => {
    switch (action.type) {
        case "start_socket":
            if (socket == null) {
                socket = io(tradeWS_URL);
            }
            if (state.trade_pair !== action.trade_pair) {
                return {
                    ...state,
                    buys: [],
                    sells: [],
                    histories: [],
                    socket: socket,
                    trade_pair: action.trade_pair
                }
            } else {
                return {
                    ...state,
                    buys: [],
                    sells: [],
                    histories: [],
                    socket: socket,
                    trade_pair: action.trade_pair
                }
            }
        case "LISTEN:pair_info":
            if (state.trade_pair !== action.trade_pair) {
                return {
                    ...state,
                    buys: [],
                    sells: [],
                    histories: [],
                    socket: socket,
                    trade_pair: action.trade_pair,
                    details: {
                        last: 0,
                        first: 0,
                        low: 0,
                        high: 0,
                        base_volume: 0,
                        volume: 0,
                        change: 0,
                        price: 0,
                        color: "text-success"
                    },
                }
            }
            return {
                ...state,
                trade_pair: action.trade_pair
            }
        case "set_trade_info":
            return {
                ...state,
                buys: action.buys,
                sells: action.sells,
                histories: action.histories
            };
        case "set:trade_pair:details":
            return {
                ...state,
                details: action.details
            };
        case "set:trade_pairs":
            return {
                ...state,
                pairs: action.pairs
            };
        case "set:user:balances":
            let balances = {}
            action.wallets.map((item) => {
                balances[item.currency] = item.amount
                return item
            })
            return {
                ...state,
                user_balances: balances
            };
        case "set:user:orders":
            return {
                ...state,
                user_orders: {
                    open: action.orders.open,
                    matches: action.orders.matches,
                    orders: action.orders.orders
                }
            }
        default:
            return state;
    }
};

export const initialStateSocket = {
    socket: socket,
    trade_pair: null,
    details: {
        last: 0,
        first: 0,
        low: 0,
        high: 0,
        base_volume: 0,
        volume: 0,
        change: 0,
        price: 0,
        color: "text-success"
    },
    pairs: [
        {
            currency: 'USDT',
            pairs: []
        }
    ],
    user_balances: {

    },
    user_orders: {
        open: [],
        matches: [],
        orders: []
    },
    buys: [],
    sells: [],
    histories: []
};