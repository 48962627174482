import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./modal.scss";

const ModalGlobal = ({ content, closeModal }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  return (
    <div
      className="modal show  "
      style={{
        display: "block",
        position: "centered",
        backgroundColor: "#38383894",
      }}
    >
      <Modal.Dialog>
        <Modal.Body>
          <p>{content}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="success" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};

export default ModalGlobal;
