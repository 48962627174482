import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SimpleBar from "simplebar-react";
import PairTitle from "./PairTitleC";
import EachPair from "./EachPairC";
import { UserContext } from "../../../../contexts/providers/user";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PairListTrade() {
  const [value, setValue] = useState(0);
  const { stateIO, state } = React.useContext(UserContext);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { t } = useTranslation();

  return (
    <div className="pairlist-mainC">
      <Box>
        <Box sx={{ borderBottom: 0, borderColor: "divider", width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {stateIO.pairs.sort().map((item, index) => {
              return (
                <Tab
                  label={<Typography fontSize={12}>{item.currency}</Typography>}
                  {...a11yProps(index)}
                />
              );
            })}
            <Tab
              label={<Typography fontSize={12}>{t('Trade_FAV')}</Typography>}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <PairTitle />
        {stateIO.pairs.sort().map((item, index) => {
          return (
            <TabPanel value={value} index={index} className="pair-list">
              <SimpleBar
                style={{ maxHeight: 228, paddingRight: 15, marginRight: 18 }}
                autoHide={false}
              >
                {item.pairs
                  .sort((a, b) => a.pair.localeCompare(b.pair))
                  .map((pair) => {
                    return <EachPair pair={pair} />;
                  })}
              </SimpleBar>
            </TabPanel>
          );
        })}
        <TabPanel value={value} index={1} className="fav-pair-list">
          <SimpleBar
            style={{ maxHeight: 228, paddingRight: 15, marginRight: 18 }}
            autoHide={false}
          >
            {stateIO.pairs.sort().map((item, index) => {
              return (
                <>
                  {item.pairs
                    .filter((pair) => {
                      return state.pair_fav.includes(pair.pair);
                    })
                    .sort((a, b) => a.pair.localeCompare(b.pair))
                    .map((pair) => {
                      return <EachPair pair={pair} />;
                    })}
                </>
              );
            })}
          </SimpleBar>
        </TabPanel>
      </Box>
    </div>
  );
}
