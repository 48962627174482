import React from "react";
import "./aboutus.scss";
import ImageOne from "../../assets/images/about4.png";
import ImageTwo from "../../assets/images/about5.png";
import ImageThree from "../../assets/images/about2.png";
import ImageFour from "../../assets/images/about6.png";
import ImageFive from "../../assets/images/about3.png";
import ImageSix from "../../assets/images/about1.png";
import { useTranslation } from "react-i18next";

function ASecond() {
  const { t } = useTranslation();
  return (
    <section className="section2">
      <h1 className="h1s2"> {t('About_second_title')} </h1>
      <h2 className="h2s2">
        {" "}
        {t('About_second_subtitle')}
        {" "}
      </h2>
      <h3 className="h3s2">
        {t('About_second_description_1')}
         <br />
        {t('About_second_description_2')}
      </h3>
      <div className="insection2">
        <div className="i1">
          <img src={ImageOne} height="100px" alt="" />
          <h2> {t('Global_Secure')} </h2>
          <p>
          {t('About_second_Feature_1_description_1')} <br /> {t('About_second_Feature_1_description_2')}
          </p>
        </div>
        <div className="i1">
          <img src={ImageTwo} height="100px" alt="" />
          <h2> {t('About_second_Feature_2_title')} </h2>
          <p>
          {t('About_second_Feature_2_description_1')} <br /> {t('About_second_Feature_2_description_2')}
          </p>
        </div>

        <div className="i1">
          <img src={ImageThree} height="100px" alt="" />
          <h2> {t('About_second_Feature_3_title')} </h2>
          <p>
          {t('About_second_Feature_3_description_1')} <br /> {t('About_second_Feature_3_description_2')}
          </p>
        </div>

        <div className="i1">
          <img src={ImageFour} height="100px" alt="" />
          <h2> {t('Global_Fast')} </h2>
          <p>
          {t('About_second_Feature_4_description_1')} <br /> {t('About_second_Feature_4_description_2')}
          </p>
        </div>

        <div className="i1">
          <img src={ImageFive} height="100px" alt="" />
          <h2> {t('About_second_Feature_5_title')} </h2>
          <p>
          {t('About_second_Feature_5_description_1')} <br /> {t('About_second_Feature_5_description_2')}
          </p>
        </div>

        <div className="i1">
          <img src={ImageSix} height="100px" alt="" />
          <h2>{t('About_second_Feature_6_title')}</h2>
          <p>
          {t('About_second_Feature_6_description_1')} <br /> {t('About_second_Feature_6_description_2')}
          </p>
        </div>
      </div>
    </section>
  );
}

export default ASecond;
