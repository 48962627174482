import { React, useState, useContext, useRef } from "react";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import "./brokerregister.scss";
import { UserContext } from "../../../contexts/providers/user";
import { ItemNames, getStorage } from "../../../utils/RSS";
import { useTranslation } from "react-i18next";

function Brokerform() {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const { state, dispatch } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const formBroker = useRef({});

  const [form, setForm] = useState({
    legal_name: "",
    register_country: "",
    register_number: "",
    monthly_transaction: "",
    monthly_distribution: "",
    mobile: "",
    email: "",
    website: "",
    submitter_first_name: "",
    submitter_last_name: "",
    job: "",
    dateofbirth: "",
    residence: "",
    citizenship: "",
    id_number: "",
    personal_document: "",
    exchange_document: "",
  });

  const changeFileForm = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.files[0],
    });
  };

  const changeForm = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleBrokerSubmit = async (event) => {
    event.preventDefault();
    if (event.currentTarget.checkValidity() === false) {
      setValidated(true);
    } else {
      setIsLoading(true);
      event.stopPropagation();
      try {
        const formData = new FormData();
        Object.keys(form).forEach((name) => {
          formData.append(name, form[name]);
        });
        let res = await fetch(
          "https://api.easyexchangepro.com/api/v2/request/add_broker",
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
            },
          }
        );

        let resJson = await res.json();

        if (resJson.status === 1) {
          dispatch({ type: "toggle_modal", content: resJson.msg });
          event.target.reset();
          setValidated(true);
        } else {
          dispatch({
            type: "open_snack",
            snackColor: "warning",
            snackMessage: resJson.msg,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };
  return (
    <div className="brokerform-main">
      <Form
        key={formBroker}
        // noValidate
        // validated={validated}
        onSubmit={handleBrokerSubmit}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-11 col-sm-10 col-md-5  m-5">
              <h4>{t('EC_BR_Exchange_info')}</h4>
              <InputGroup className="mt-2">
                <Form.Control
                  type="text"
                  placeholder={t('EC_BR_Legal')}
                  name="legal_name"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('EC_BR_Legal_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('EC_BR_RegCountry')}
                  name="register_country"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('EC_BR_RegCountry_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('EC_BR_RegNum')}
                  name="register_number"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('EC_BR_RegNum_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('EC_BR_MonTxVal')}
                  name="monthly_transaction"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('EC_BR_MonTxVal_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('EC_BR_ECMonDis')}
                  name="monthly_distribution"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('EC_BR_ECMonDis_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('Global_Mobile')}
                  name="mobile"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('Global_Mobile_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('Global_Email')}
                  name="email"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('Global_Email_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('EC_BR_Website')}
                  name="website"
                  onChange={changeForm}
                />
              </InputGroup>
            </div>
            <div className="col-11 col-sm-10 col-md-5  m-5">
              <h4>{t('EC_BR_Submitter_info')}</h4>
              <InputGroup className="mt-2">
                <Form.Control
                  type="text"
                  placeholder={t('Global_FName')}
                  name="submitter_first_name"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('Global_FName_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('Global_LName')}
                  name="submitter_last_name"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('Global_LName_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('EC_BR_Job')}
                  name="job"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('EC_BR_Job_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="date"
                  placeholder={t('EC_BR_Birth')}
                  name="dateofbirth"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('EC_BR_Birth_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('EC_BR_Country')}
                  name="residence"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('EC_BR_Country_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('EC_BR_Citizen')}
                  name="citizenship"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('EC_BR_Citizen_Error')}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-4">
                <Form.Control
                  type="text"
                  placeholder={t('Global_IDNum')}
                  name="id_number"
                  onChange={changeForm}
                  required
                />
                <Form.Control.Feedback type="invalid">
                {t('Global_IDNum_Error')}
                </Form.Control.Feedback>
              </InputGroup>
            </div>
          </div>
          <h4 className="m-4">{t('EC_BR_Upload_title')}</h4>
          <h6 className="m-4">{t('Global_Max_upload')}</h6>
          <div className="row   ms-5">
            <div className="col-11 col-sm-10 col-md-5">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>{t('EC_BR_Exchange_Doc')}</Form.Label>
                <Form.Control
                  type="file"
                  name="exchange_document"
                  required
                  onChange={changeFileForm}
                />
                <Form.Control.Feedback type="invalid">
                {t('EC_BR_Exchange_Doc_Error')}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-11 col-sm-10 col-md-5 ">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>{t('EC_BR_Peronal_ID_Doc')}</Form.Label>
                <Form.Control
                  type="file"
                  name="personal_document"
                  required
                  onChange={changeFileForm}
                />
                <Form.Control.Feedback type="invalid">
                {t('EC_BR_Peronal_ID_Doc_Error')}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row m-2">
            <h5>{t('Global_Policy')}</h5>
            <div className="col-1">
              <Button
                className="submit-btn mb-5"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                {isLoading ? t('Global_Loading') : t('Global_Submit')}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default Brokerform;
