import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../../assets/images/EEP_Logo.svg";
import PersonIcon from "@mui/icons-material/Person";
import "../navbar/navbar.scss";
import * as React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import SelectTheme from "../../utils/Themeselector";
import Notificationtop from "../../pages/notification/Notificationtop";
import { IconButton } from "@mui/material";
import ScrollToTop from "../../utils/ScrollToTop";
import LanguageSelector from "../translation/LanguageSelector";
import { UserContext } from "../../contexts/providers/user";
import { useContext } from "react";
import { isLoggedIn } from "../../utils/RSS";
import { useTranslation } from "react-i18next";

function MenuWithColapse() {
  const { state, dispatch } = useContext(UserContext);
  const { t } = useTranslation();
  const handleNavDropdownSelect = () => {
    // Close the dropdown menu after selecting a link
    document.getElementById("basic-navbar-nav")?.click();
  };
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const { pathname } = useLocation();

  const regex3 = new RegExp("/chart/.*");
  const location = useLocation();

  React.useEffect(() => {
    setDropdownOpen(false);
  }, [location]);

  const handleLanguageSelectorClick = (e) => {
    e.stopPropagation();
  };

  const handleThemeSelectorClick = (e) => {
    e.stopPropagation();
  };

  if (regex3.test(pathname)) return null;
  return (
    <>
      {["xxl"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="mb-3 navbar-main fixed-top"
          sticky="top"
        >
          <Container fluid>
            <NavLink to="/">
              <Navbar.Brand>
                <div className="logohere"></div>
                <ScrollToTop />
              </Navbar.Brand>
            </NavLink>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              onClick={() => setDropdownOpen(!dropdownOpen)}
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              show={dropdownOpen}
              onHide={() => setDropdownOpen(false)}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <p className="title-logo">Easy Pro</p>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-start flex-grow-1 pe-3">
                  <NavDropdown
                    title="Easy Card"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                    aria-controls="basic-navbar-nav"
                    onSelect={handleNavDropdownSelect}
                  >
                    <NavLink as={Link} to="/wheretobuy" className="nav-link">
                      {t("Home_EC_Where_to_buy")}
                      <ScrollToTop />
                    </NavLink>
                    <NavLink
                      as={Link}
                      to="/alreadypurchased"
                      className="nav-link"
                    >
                      {t("Already_Purchased")}
                      <ScrollToTop />
                    </NavLink>
                    <NavLink
                      as={Link}
                      to="/brokerregister"
                      className="nav-link"
                    >
                      {t("EC_BR_title")}
                      <ScrollToTop />
                    </NavLink>
                    {/* <NavDropdown.Divider /> */}
                  </NavDropdown>
                  <NavDropdown
                    title="Easy Guarantee"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavLink as={Link} to="/easyguarantee" className="nav-link">
                      Easy Guarantee
                      <ScrollToTop />
                    </NavLink>
                    <NavLink
                      as={Link}
                      to="/easyguaranteefaq"
                      className="nav-link"
                    >
                      Easy Guarantee FAQ
                      <ScrollToTop />
                    </NavLink>
                    <NavLink
                      as={Link}
                      to="/easyguaranteeform"
                      className="nav-link"
                    >
                      {t("Global_ApplicationForm")}
                      <ScrollToTop />
                    </NavLink>
                  </NavDropdown>
                  <NavDropdown
                    title={t("Global_Products")}
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavLink as={Link} to="/market" className="nav-link">
                      {t("Trade_Market")}
                      <ScrollToTop />
                    </NavLink>
                    <NavLink
                      as={Link}
                      to="/trade/BTC_USDT"
                      className="nav-link"
                    >
                      {t("Global_Trade")}
                      <ScrollToTop />
                    </NavLink>
                    {/* <NavDropdown.Divider /> */}
                    <NavLink as={Link} to="/convert" className="nav-link">
                      {t("Global_Convert")}
                      <ScrollToTop />
                    </NavLink>
                    <NavLink as={Link} to="/otc" className="nav-link">
                      OTC
                      <ScrollToTop />
                    </NavLink>
                  </NavDropdown>
                  <NavDropdown
                    title={t("Global_Transaction")}
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavLink as={Link} to="/withdraw" className="nav-link">
                      {t("Global_Withdraw")}
                      <ScrollToTop />
                    </NavLink>
                    <NavLink as={Link} to="/deposit" className="nav-link">
                      {t("Global_Deposit")}
                      <ScrollToTop />
                    </NavLink>
                    <NavLink as={Link} to="/transfer" className="nav-link">
                      {t("Global_Transfer")}
                      <ScrollToTop />
                    </NavLink>
                  </NavDropdown>
                  {/* <NavLink as={Link} to="/gabur" className="nav-link">
                    Gabur
                    <ScrollToTop />
                  </NavLink> */}
                </Nav>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <NavLink as={Link} to="/assets" className="nav-link">
                    {t("Api_2")}
                    <ScrollToTop />
                  </NavLink>

                  <div className="row d-flex flex-row icon-navbar-main">
                    <div className="col icon-navbar-ui">
                      <NavLink as={Link} to="/profile" className="nav-link-sec">
                        <IconButton
                          size="small"
                          style={{ color: "#38E54D" }}
                          aria-label="profile"
                          className="  icon-navbar-ui-profile"
                        >
                          <PersonIcon
                            fontSize="medium"
                            className="  icon-navbar-ui-profile"
                          />
                        </IconButton>
                        <ScrollToTop />
                      </NavLink>
                    </div>
                    <div className="col icon-navbar-ui">
                      <Notificationtop />
                    </div>
                    {/* <div className="col icon-navbar-ui">
                      <NavLink className="nav-link-sec">
                        <SupportChat />
                      </NavLink>
                    </div> */}

                  </div>
                  {isLoggedIn() ? (
                    <NavLink as={Link} to="/login" className="nav-link-sec">
                      <Button
                        className="me-1"
                        variant="warning"
                        onClick={() => dispatch({ type: "Logout" })}
                      >
                        Log Out
                      </Button>
                      <ScrollToTop />
                    </NavLink>
                  ) : (
                    <NavLink as={Link} to="/login" className="nav-link-sec">
                      <Button className="me-1" variant="warning">
                        Enter
                      </Button>
                      <ScrollToTop />
                    </NavLink>
                  )}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            <LanguageSelector />
            <SelectTheme />
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default MenuWithColapse;
