import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import { NavLink } from "react-router-dom";
import "./themeselector.scss";

import { useTranslation } from "react-i18next";
function LanguageSelector() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng);
  };
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <div>
            <NavLink className="nav-link-sec">
              <IconButton
                className="language-icon"
                size="medium"
                color="info"
                // style={{ color: "#38E54D" }}
                aria-label="profile"
                {...bindTrigger(popupState)}
              >
                <LanguageRoundedIcon fontSize="medium" />
              </IconButton>
            </NavLink>
          </div>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{ style: { width: "80px", height: "240px" } }}
          >
            <div>
              <Box
                noValidate
                component="form"
                sx={{
                  flexDirection: "column",
                  m: "auto",
                  width: "fit-content",
                }}
              >
                <Button
                  size="medium"
                  color="info"
                  variant="text"
                  onClick={() => changeLanguage("en")}
                  sx={{ marginRight: "10px" }}
                >
                  English
                </Button>
                <Button
                  size="medium"
                  color="info"
                  variant="text"
                  onClick={() => changeLanguage("ru")}
                  sx={{ marginRight: "10px" }}
                >
                  Russian
                </Button>
                <Button
                  size="medium"
                  color="info"
                  variant="text"
                  onClick={() => changeLanguage("tr")}
                  sx={{ marginRight: "10px" }}
                >
                  Turkish
                </Button>
                {/* <Button
                  size="medium"
                  color="info"
                  variant="text"
                  onClick={() => changeLanguage("de")}
                  sx={{ marginRight: "10px" }}
                >
                  German
                </Button>
                <Button
                  size="medium"
                  color="info"
                  variant="text"
                  onClick={() => changeLanguage("fr")}
                  sx={{ marginRight: "10px" }}
                >
                  French
                </Button> */}
                <Button
                  size="medium"
                  color="info"
                  variant="text"
                  onClick={() => changeLanguage("ch")}
                  sx={{ marginRight: "10px" }}
                >
                  Chinese
                </Button>
                <Button
                  size="medium"
                  color="info"
                  variant="text"
                  onClick={() => changeLanguage("fa")}
                  sx={{ marginRight: "10px" }}
                >
                  Persian
                </Button>
                <Button
                  size="medium"
                  color="info"
                  variant="text"
                  onClick={() => changeLanguage("ar")}
                  sx={{ marginRight: "15px" }}
                >
                  Arabic
                </Button>
              </Box>
            </div>
          </Popover>
        </div>
      )}
    </PopupState>
    // <React.Fragment>
    //
    // </React.Fragment>
  );
}

export default LanguageSelector;
