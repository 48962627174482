import React from "react";
import "./apidocument.scss";
import { useTranslation } from "react-i18next";
function ApiDocument() {
  const { t } = useTranslation();
  return (
    <div className="apidocuments-main">
      <div className="apidocument-module container">
        <div class="row">
          <div class="col-6 main-content">
            <p>{t('Api_title')}</p>
            <span>1-</span>
            <a
              href="https://traapi.easyexchangepro.com/api/summary"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Api_1')}
            </a>
            <br />
            <span>2-</span>
            <a
              href="https://traapi.easyexchangepro.com/api/assets"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Api_2')}
            </a>
            <br />
            <span>3-</span>
            <a
              href="https://traapi.easyexchangepro.com/api/ticker"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Api_3')}
            </a>
            <br />
            <span>4-</span>
            <a
              href="https://traapi.easyexchangepro.com/api/orderbook/BTC_USDT"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Api_4')}
            </a>
            <br />
            <span>5-</span>
            <a
              href="https://traapi.easyexchangepro.com/api/trades/BTC_USDT"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Api_5')}
            </a>
            <br />
            <span>6-</span>
            <a
              href="http://traapi.easyexchangepro.com/api/24h_volume"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Api_6')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApiDocument;
