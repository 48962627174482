import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import Slide1 from "../../assets/images/1.png";
import Slide2 from "../../assets/images/2.png";
import Slide3 from "../../assets/images/3.png";
import Slide4 from "../../assets/images/4.png";
import Slide5 from "../../assets/images/5.png";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1200, min: 550 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 550, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function Pricebox() {
  return (
    <Carousel
      swipeable={false}
      draggable={false}
      showDots={false}
      responsive={responsive}
      ssr={false} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={3000}
      keyBoardControl={false}
      customTransition="all 1.5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      <div className="p-1">
        <img src={Slide4} width={250} height={120} alt="" />
      </div>
      <div className="p-1">
        <img src={Slide2} width={250} height={120} alt="" />
      </div>
      <div className="p-1">
        <img src={Slide3} width={250} height={120} alt="" />
      </div>
      <div className="p-1">
        <img src={Slide4} width={250} height={120} alt="" />
      </div>
      <div className="p-1">
        <img src={Slide5} width={250} height={120} alt="" />
      </div>
    </Carousel>
  );
}

export default Pricebox;
