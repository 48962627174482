import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Menubar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import { UserProvider } from "./contexts/providers/user";
import { RoutesMap } from "./route";
import { useEffect, useState } from "react";

function App() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);

  if (pageWidth > 768) {
    return (
      <UserProvider>
        <div className="main-app">
          <BrowserRouter>
            <nav className="navbar-app">
              <Menubar />
            </nav>
            <RoutesMap />
            <footer>
              <Footer />
            </footer>
          </BrowserRouter>
        </div>
      </UserProvider>
    );
  } else if (pageWidth <= 768) {
    return (
      <UserProvider>
        <div className="main-app">
          <BrowserRouter>

            <RoutesMap />

          </BrowserRouter>
        </div>
      </UserProvider>
    )
  }
}

export default App;
