import React from "react";
import { initialState, reducer } from "../reducer/user";
import { initialStateSocket, reducerIO } from "../reducer/socket";

export const UserContext = React.createContext({
  state: initialState,
  dispatch: () => null,
});

export const UserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [stateIO, dispatchIO] = React.useReducer(reducerIO, initialStateSocket);

  return (
    <UserContext.Provider value={{ state, dispatch, stateIO, dispatchIO }}>
      {children}
    </UserContext.Provider>
  );
};
