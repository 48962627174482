import { ItemNames, getStorage } from "./RSS";

export function getSummary(setFunction, setErrorFunction) {
  fetch("https://traapi.easyexchangepro.com/api/summary_with_change")
    .then((response) => response.json())
    .then((data) => setFunction(data))
    .catch((error) => setErrorFunction(error));
}

export function getAnnouncement(setFunction, setErrorFunction) {
  fetch("https://api.easyexchangepro.com/api/v2/public/announcements")
    .then((response) => response.json())
    .then((data) => setFunction(data))
    .catch((error) => setErrorFunction(error));
}

export function getMainWallet(setFunction, setErrorFunction) {
  fetch("https://api.easyexchangepro.com/api/v2/wallet/balances", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.data.wallets);
    })
    .catch((error) => setErrorFunction(error));
}

export function getTradeWallet(setFunction, setErrorFunction) {
  fetch("https://api.easyexchangepro.com/api/v2/wallet/trading/balances", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => setFunction(data.data.wallets))
    .catch((error) => setErrorFunction(error));
}

export function getUserInfo(setFunction, setErrorFunction) {
  fetch("https://api.easyexchangepro.com/api/v2/user/info", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.user);
    })
    .catch((error) => setErrorFunction(error));
}

export function getReferral(setFunction, setErrorFunction) {
  fetch("https://api.easyexchangepro.com/api/v2/user/referrals", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.data.levelOne);
    })
    .catch((error) => setErrorFunction(error));
}

export function getReferralHistory(setFunction, setErrorFunction) {
  fetch("https://api.easyexchangepro.com/api/v2/user/referrals_history", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.data);
    })
    .catch((error) => setErrorFunction(error));
}

export function getNotification(setFunction, setErrorFunction) {
  fetch("https://api.easyexchangepro.com/api/v2/user/notifications", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.data);
    })
    .catch((error) => setErrorFunction(error));
}

export function getConvertPairs(setFunction, setErrorFunction) {
  fetch("https://api.easyexchangepro.com/api/v2/convert/pairs", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.data);
    })
    .catch((error) => setErrorFunction(error));
}

export function getDepositCurrency(setFunction, setErrorFunction) {
  fetch("https://trnsapiion.easyexchangepro.com/trans/get_currencies", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.data);
    })
    .catch((error) => setErrorFunction(error));
}

export function getWithdrawInfo(setFunction, setErrorFunction) {
  fetch("https://trnsapiion.easyexchangepro.com/trans/send", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      let currencies = {};
      if (data.success === 2) {
        return setErrorFunction(data.msg);
      }
      data.send.crypto_currency.forEach((element) => {
        if (currencies[element.symbol]) {
          let network = element.networktype || element.symbol;
          currencies[element.symbol].networks.push(network);
        } else {
          let network = element.networktype || element.symbol;
          currencies[element.symbol] = {
            symbol: element.symbol,
            min: element.min,
            max: element.max,
            fee: element.fee,
            type: element.type,
            image: element.image,
            fee_type: element.fee_type,
            networks: [network],
            name: element.name,
            balance: data.send.wallet[element.symbol],
          };
        }
      });
      setFunction(currencies);
    })
    .catch((error) => setErrorFunction(error));
}

export function getTransferWallet(setFunction, setErrorFunction) {
  fetch("https://api.easyexchangepro.com/api/v2/wallet/balances/overall", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.data.wallets);
    })
    .catch((error) => setErrorFunction(error));
}

export function getWithdrawHistory(setFunction, setErrorFunction) {
  fetch("https://api.easyexchangepro.com/api/v2/history/withdraws", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {

      setFunction(data.data);
    })
    .catch((error) => setErrorFunction(error));
}


export function getDepositHistory(setFunction, setErrorFunction) {
  fetch("https://api.easyexchangepro.com/api/v2/history/deposits", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.data);
    })
    .catch((error) => setErrorFunction(error));
}