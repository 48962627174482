import "./marketinhome.scss";
import { Button } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getSummary } from "../../utils/Api";
import { RotatingLines } from "react-loader-spinner";
import { Link, NavLink } from "react-router-dom";
import { UserContext } from "../../contexts/providers/user";
import { useContext } from "react";
import { t } from "i18next";
function createData(token, lastPrice, high, low, change24, volume24h, trade) {
  return { token, lastPrice, high, low, change24, volume24h, trade };
}

function handleClick(id) { }

const homeCurrencies = ["BTC", "ETH", "ADA", "BNB", "TRX"];

export default function MarketInHome() {
  const { state } = useContext(UserContext);
  const [summaryData, setSummaryData] = useState(null);

  const errorCatch = (error) => { };

  useEffect(() => {
    getSummary(setSummaryData, errorCatch);
  }, []);

  if (!summaryData) {
    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col text-center">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="35"
              visible={true}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <TableContainer component={Paper} className="table-main">
      <Table sx={{ minWidth: 600 }} aria-label="simple table dark">
        <TableHead className="table-head">
          <TableRow className="table-row">
            <TableCell className="table-row" align="left">
              {t('Global_Icon')}
            </TableCell>
            <TableCell className="table-row" align="left">
              {t('Global_Pair')}
            </TableCell>
            <TableCell className="table-row" align="right">
              {t('Global_LastPrice')}
            </TableCell>
            <TableCell className="table-row" align="right">
              {t('Global_24h')} {t('Global_High')}
            </TableCell>
            <TableCell className="table-row" align="right">
              {t('Global_24h')} {t('Global_Low')}
            </TableCell>
            <TableCell className="table-row" align="right">
              {t('Global_24h')} {t('Global_Change')}
            </TableCell>
            <TableCell className="table-row" align="right">
              {t('Global_24h')} {t('Global_Volume')}
            </TableCell>
            <TableCell className="table-row" align="center">
              {t('Global_Trade')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summaryData
            .filter((item) => {
              return homeCurrencies.includes(item.base_currency);
            })
            .sort((a, b) => a.base_currency.localeCompare(b.base_currency))
            .map((row) => (
              <TableRow
                className="table-row"
                key={row.base_currency}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <img
                    src={state.currency.images[row.base_currency]}
                    style={{ width: 25, height: 25, marginRight: 10 }}
                    alt={"currency"}
                  />
                </TableCell>
                <TableCell className="table-row" component="th" scope="row">
                  {row.trading_pairs}
                </TableCell>
                <TableCell className="table-row" align="right">
                  {row.last_price}
                </TableCell>
                <TableCell className="table-row" align="right">
                  {row.highest_price_24h}
                </TableCell>
                <TableCell className="table-row" align="right">
                  {row.lowest_price_24h}
                </TableCell>
                <TableCell className="table-row" align="right">
                  {row.change}
                </TableCell>
                <TableCell className="table-row" align="right">
                  {parseFloat(`${row.quote_volume}`).toFixed(2)}
                </TableCell>
                <TableCell className="table-row" align="right">
                  <NavLink
                    as={Link}
                    to={`/trade/${row.trading_pairs}`}
                    className="nav-link"
                  >
                    <Button className="market-table-btn">{t('Global_Trade')}</Button>
                  </NavLink>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
