import { TextareaAutosize } from "@mui/material";
import { React, useState, useContext } from "react";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { UserContext } from "../../../contexts/providers/user";
import "./guaranteeform.scss";
import { ItemNames, getStorage } from "../../../utils/RSS";
import { useTranslation } from "react-i18next";

function EGform() {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const { state, dispatch } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const handleGuaranteeSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      setIsLoading(true);
      event.stopPropagation();
      const brokerRegister = new FormData(event.currentTarget);
      try {
        let res = await fetch(
          "https://api.easyexchangepro.com/api/v2/request/apply/guarantee",
          {
            method: "POST",
            body: JSON.stringify({
              name: brokerRegister.get("userid"),
              email: brokerRegister.get("emailaddress"),
              startdays: brokerRegister.get("startdate"),
              enddays: brokerRegister.get("endtime"),
              usdtwalletaddress: brokerRegister.get("usdtwallet"),
              usdtamount: brokerRegister.get("usdtamount"),
              validityperiod: brokerRegister.get("validatydays"),
              selecttype: brokerRegister.get("condition"),
              purpose: brokerRegister.get("purpose"),
              benname: brokerRegister.get("benefuserid"),
              benemail: brokerRegister.get("beneemail"),
              benusdtwallet: brokerRegister.get("beneusdtwallet"),
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
            },
          }
        );

        let resJson = await res.json();

        if (resJson.status === 1) {
          dispatch({ type: "toggle_modal", content: resJson.msg });
          event.target.reset();
          setValidated(true);
        } else {
          dispatch({
            type: "open_snack",
            snackColor: "warning",
            snackMessage: resJson.msg,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h3>{t('EG_AF_title')}</h3>
          <h6>
          {t('EG_AF_description')}
          </h6>
        </div>
      </div>
      <Form noValidate validated={validated} onSubmit={handleGuaranteeSubmit}>
        <div className="row mt-5 justify-content-center">
          <h6>{t('EG_AP_Applicant')}</h6>
          <div className="col-9 col-md-5">
            <InputGroup className="mt-2">
              <Form.Control
                type="text"
                placeholder={t('Global_UID')}
                name="userid"
                required
              />
              <Form.Control.Feedback type="invalid">
              {t('Global_UID_Error')}
              </Form.Control.Feedback>
            </InputGroup>
            <InputGroup className="mt-2">
              <Form.Control
                type="text"
                placeholder={t('Global_Email')}
                name="emailaddress"
                required
              />
              <Form.Control.Feedback type="invalid">
              {t('Global_Email_Error')}
              </Form.Control.Feedback>
            </InputGroup>
            <InputGroup className="mt-2">
              <Form.Control
                type="date"
                placeholder={t('EG_AP_SDate')}
                name="startdate"
                required
              />
              <Form.Control.Feedback type="invalid">
              {t('EG_AP_SDate_Error')}
              </Form.Control.Feedback>
            </InputGroup>
            <InputGroup className="mt-2">
              <Form.Control
                type="date"
                placeholder={t('EG_AP_EDate')}
                name="endtime"
                required
              />
              <Form.Control.Feedback type="invalid">
              {t('EG_AP_EDate_Error')}
              </Form.Control.Feedback>
            </InputGroup>
          </div>

          <div className="col-9 col-md-5">
            <InputGroup className="mt-2">
              <Form.Control
                type="text"
                placeholder={t('EG_AP_WalletApp')}
                name="usdtwallet"
                required
              />
              <Form.Control.Feedback type="invalid">
              {t('EG_AP_WalletApp_Error')}
              </Form.Control.Feedback>
            </InputGroup>
            <InputGroup className="mt-2">
              <Form.Control
                type="text"
                placeholder={t('EG_AP_Amount')}
                name="usdtamount"
                required
              />
              <Form.Control.Feedback type="invalid">
              {t('EG_AP_Amount_Error')}
              </Form.Control.Feedback>
            </InputGroup>
            <InputGroup className="mt-2">
              <Form.Control
                type="text"
                placeholder={t('EG_AP_VPD')}
                name="validatydays"
                required
              />
              <Form.Control.Feedback type="invalid">
              {t('EG_AP_VPD_Error')}
              </Form.Control.Feedback>
            </InputGroup>

            <Form.Select className="mt-2" name="condition" required>
              <option value={1}>{t('EG_AP_Select_Conditional')}</option>
              <option value={2}>{t('EG_AP_Select_Unconditional')}</option>
            </Form.Select>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-9 col-md-10">
            <Form.Group className="mt-2">
              <Form.Control
                as={TextareaAutosize}
                maxRows={3}
                placeholder={t('Global_Purpose')}
                name="purpose"
                required
              />
              <Form.Control.Feedback type="invalid">
              {t('Global_Purpose_Error')}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="row mt-5 justify-content-center">
          <h6>{t('EG_AP_Beneficiary')}</h6>
          <div className="col-9 col-md-5">
            <InputGroup className="mt-2">
              <Form.Control
                type="text"
                placeholder={t('EG_AP_BenUID')}
                name="benefuserid"
                required
              />
              <Form.Control.Feedback type="invalid">
              {t('EG_AP_BenUID_Error')}
              </Form.Control.Feedback>
            </InputGroup>
            <InputGroup className="mt-2">
              <Form.Control
                type="text"
                placeholder={t('EG_AP_BenEmail')}
                name="beneemail"
                required
              />
              <Form.Control.Feedback type="invalid">
              {t('EG_AP_BenEmail_Error')}
              </Form.Control.Feedback>
            </InputGroup>
            <Form.Check
              type="checkbox"
              id=""
              label={t('Global_Accept_Terms')}
              className="mt-2"
              name="legalname"
              required
              feedback={t('Global_Accept_Terms_Error')}
              feedbackType="invalid"
            />
          </div>
          <div className="col-9 col-md-5">
            <InputGroup className="mt-2">
              <Form.Control
                type="text"
                placeholder={t('EG_AP_WalletBen')}
                name="beneusdtwallet"
                required
              />
              <Form.Control.Feedback type="invalid">
              {t('EG_AP_WalletBen_Error')}
              </Form.Control.Feedback>
            </InputGroup>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-2">
            <Button
              className="submit-btn mt-3 mb-5"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              {isLoading ? t('Global_Loading') : t('Global_Submit')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default EGform;
