import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { React, useState, useContext, useEffect } from "react";
import "./alreadypurchased.scss";
import PhoneImage from "../../../assets/images/easyphone.png";
import { UserContext } from "../../../contexts/providers/user";
import { ItemNames, getStorage } from "../../../utils/RSS";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../../home/HeaderMobile";
import BottomNavigationMobile from "../../home/BottomNavigationMobile";

function Alreadypurchased() {
  const [validated, setValidated] = useState(false);
  const { state, dispatch } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleEasySubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      setIsLoading(true);
      event.stopPropagation();
      const easyCode = new FormData(event.currentTarget);
      try {
        let res = await fetch(
          "https://api.easyexchangepro.com/api/v2/request/apply/easy_card",
          {
            method: "POST",
            body: JSON.stringify({
              code: easyCode.get("easycode"),
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
            },
          }
        );

        let resJson = await res.json();

        if (resJson.status === 0) {
          dispatch({
            type: "open_snack",
            snackColor: "warning",
            snackMessage: resJson.msg,
          });
        } else if (resJson.status === 4) {
          dispatch({ type: "toggle_modal", content: resJson.msg });
          setValidated(true);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const { t } = useTranslation();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);

  if (pageWidth > 768) {
    return (
      <div className="easy-main">
        <div className="container">
          <div className="easycard-page">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="row easy-card-div p-5  mb-3">
                  <div className="col-lg-8">
                    <div className="row">
                      <h1>{t('Global_EasyCard')}</h1>
                      <div className="d-flex title-div">
                        <p className="mb-5">
                          {t('EC_AP_description')}
                        </p>
                      </div>
                      <div className="col">
                        <Form
                          noValidate
                          validated={validated}
                          onSubmit={handleEasySubmit}
                        >
                          <InputGroup>
                            <Form.Control
                              type="text"
                              placeholder={t('EC_AP_Code')}
                              maxLength={20}
                              minLength={20}
                              name="easycode"
                              required
                            />
                            <Button
                              className="p-2 easy-btn"
                              type="submit"
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : null}
                              {isLoading ? t('Global_Loading') : t('Global_Submit')}
                            </Button>
                            <Form.Control.Feedback type="invalid">
                              {t('EC_AP_Code_Error')}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <img
                      src={PhoneImage}
                      className="img-flud d-block mx-auto"
                      alt="easy card"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="easy-main-m">
          <div className="container">
            <div className="easycard-page-m">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="row easy-card-div p-5  mb-3">
                    <div className="col-lg-8">
                      <div className="row">
                        <h1>{t('Global_EasyCard')}</h1>
                        <div className="d-flex title-div">
                          <p className="mb-5">
                            {t('EC_AP_description')}
                          </p>
                        </div>
                        <div className="col">
                          <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleEasySubmit}
                          >
                            <InputGroup>
                              <Form.Control
                                type="text"
                                placeholder={t('EC_AP_Code')}
                                maxLength={20}
                                minLength={20}
                                name="easycode"
                                required
                              />
                              <Button
                                className="p-2 easy-btn"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                {isLoading ? t('Global_Loading') : t('Global_Submit')}
                              </Button>
                              <Form.Control.Feedback type="invalid">
                                {t('EC_AP_Code_Error')}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <img
                        src={PhoneImage}
                        className="img-flud d-block mx-auto"
                        alt="easy card"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Alreadypurchased;
