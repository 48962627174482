import React, { useEffect, useState } from "react";
import "./tokenlisting.scss";
import TokenListingForm from "./TokenListingForm";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../home/HeaderMobile";
import BottomNavigationMobile from "../home/BottomNavigationMobile";
import TokenListingFormMobile from "./TokenListingFormMobile";
function TokenListing() {
  const { t } = useTranslation();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);
  if (pageWidth > 768) {
    return (
      <div className="tokenlisting-main">
        <div className="container">
          <div className="row">
            <div className="tokenlisting-form">
              <h4 className="tokenlisting-title">
                {t('TL_title')}
              </h4>
              <TokenListingForm />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="tokenlisting-main">
          <div className="container" style={{ marginTop: 60, marginBottom: 100 }}>
            <div className="row">
              <div className="tokenlisting-form">
                <h4 className="tokenlisting-title">
                  {t('TL_title')}
                </h4>
                <TokenListingFormMobile />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TokenListing;
