import { Button } from "react-bootstrap";
import { useContext, useEffect } from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RotatingLines } from "react-loader-spinner";
import { getSummary } from "../../../utils/Api";
import { Link, NavLink } from "react-router-dom";
import { UserContext } from "../../../contexts/providers/user";
import { useTranslation } from "react-i18next";

export default function MarketTable() {
  const { state } = useContext(UserContext);
  const [summaryData, setSummaryData] = useState(null);

  const errorCatch = (error) => { };

  useEffect(() => {
    getSummary(setSummaryData, errorCatch);
  }, []);
  const { t } = useTranslation();

  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);

  if (pageWidth > 768) {
    if (!summaryData) {
      return (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col text-center">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="35"
                visible={true}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <TableContainer component={Paper} className="table-main">
        <Table sx={{ minWidth: 600 }} aria-label="simple table dark">
          <TableHead className="table-head">
            <TableRow className="table-row">
              <TableCell className="table-row" align="left">
                {t('Global_Pair')}
              </TableCell>
              <TableCell className="table-row" align="right">
                {t('Global_LastPrice')}
              </TableCell>
              <TableCell className="table-row" align="right">
                {t('Global_24h')} {t('Global_High')}
              </TableCell>
              <TableCell className="table-row" align="right">
                {t('Global_24h')} {t('Global_Low')}
              </TableCell>
              <TableCell className="table-row" align="right">
                {t('Global_24h')} {t('Global_Change')}
              </TableCell>
              <TableCell className="table-row" align="right">
                {t('Global_24h')} {t('Global_Volume')}
              </TableCell>
              <TableCell className="table-row" align="right">
                {t('Global_Trade')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {summaryData
              .sort((a, b) => a.base_currency.localeCompare(b.base_currency))
              .map((row) => (
                <TableRow
                  className="table-row"
                  key={row.base_currency}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell className="table-row" component="th" scope="row">
                    <img
                      src={state.currency.images[row.base_currency]}
                      style={{ width: 25, height: 25, marginRight: 10 }}
                      alt={"currency"}
                    />
                    {row.trading_pairs.replace("_", "/")}
                  </TableCell>
                  <TableCell className="table-row" align="right">
                    {row.last_price}
                  </TableCell>
                  <TableCell className="table-row" align="right">
                    {row.highest_price_24h}
                  </TableCell>
                  <TableCell className="table-row" align="right">
                    {row.lowest_price_24h}
                  </TableCell>
                  <TableCell className="table-row" align="right">
                    {row.change}
                  </TableCell>
                  <TableCell className="table-row" align="right">
                    {parseFloat(row.quote_volume).toFixed(2)}
                  </TableCell>
                  <TableCell className="table-row" align="right">
                    <NavLink
                      as={Link}
                      to={`/trade/${row.trading_pairs}`}
                      className="nav-link"
                    >
                      <Button className="market-table-btn">{t('Global_Trade')}</Button>
                    </NavLink>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else if (pageWidth <= 768) {
    if (!summaryData) {
      return (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col text-center">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="35"
                visible={true}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      < >

        {summaryData.map((row, index) => (
          <div className="container-fluid market-data-mobile" key={index}>
            <div className="row justify-content-center" style={{ width: "100%" }}>
              <div className="col-12">
                <div className="row mb-2">
                  <div className="col-2">
                    <img
                      src={state.currency.images[row.base_currency]}
                      style={{ width: 25, height: 25, marginRight: 10 }}
                      alt="currency"
                    />
                  </div>
                  <div className="col-7 mt-1" style={{ fontSize: 15 }}>
                    <span>{row.base_currency}</span> <span style={{ fontSize: 9 }}>USDT</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-7">
                    {t('Global_LastPrice')}
                  </div>
                  <div className="col-5 text-end">
                    {row.last_price}
                  </div>
                </div>

                <div className="row">
                  <div className="col-7">
                    {t('Global_24h')} {t('Global_High')}
                  </div>
                  <div className="col-5 text-end">
                    {row.highest_price_24h}
                  </div>
                </div>

                <div className="row">
                  <div className="col-7">
                    {t('Global_24h')} {t('Global_Low')}
                  </div>
                  <div className="col-5 text-end">
                    {row.lowest_price_24h}
                  </div>
                </div>

                <div className="row">
                  <div className="col-7" style={{ color: row.change <= 0 ? 'red' : 'green' }}>

                    {t('Global_24h')} {t('Global_Change')}
                  </div>
                  <div className="col-5 text-end" style={{ color: row.change <= 0 ? 'red' : 'green' }}>
                    {row.change}
                  </div>
                </div>

                <div className="row">
                  <div className="col-7">
                    {t('Global_24h')} {t('Global_Volume')}
                  </div>
                  <div className="col-5 text-end">
                    {parseFloat(row.quote_volume).toFixed(2)}
                  </div>
                </div>

                <NavLink as={Link} to={`/trade/${row.trading_pairs}`} className="nav-link">
                  <Button className="market-table-btn-mobile">{t('Global_Trade')}</Button>
                </NavLink>
              </div>
            </div>
          </div>

        ))}
      </ >
    );
  }
}
