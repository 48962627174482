import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  ModalFooter,
  Spinner,
} from "react-bootstrap";
import TradeSlider from "./SliderB";
import { sendRequestEmit } from "../../../../contexts/reducer/socket";
import { ItemNames, getStorage, isLoggedIn } from "../../../../utils/RSS";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../contexts/providers/user";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { toFixed } from "../../../../utils/math";
import { useTranslation } from "react-i18next";

function Market({
  side = "sell",
  from,
  to,
  balance_from,
  balance_to,
  last_price,
}) {
  const [marketForm, setMarketForm] = useState({
    amount: 0,
    total: 0,
  });
  const { t } = useTranslation();


  const inputChange = (name) => {
    return (event) => {
      const form = marketForm;
      form[name] = event.target.value;
      let value = event.target.value;
      value = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      const decimalIndex = value.indexOf(".");
      if (side === "sell") {
        if (decimalIndex !== -1) {
          const decimals = state.currency.details[from].amount_decimal || 4;
          const decimalPlaces = value.length - decimalIndex - 1;
          if (decimalPlaces > decimals) {
            value = value.slice(0, decimalIndex + decimals + 1);
          }
        }
        form.total = form.amount * last_price;
      } else {
        if (decimalIndex !== -1) {
          const decimals = state.currency.details[to].amount_decimal || 4;
          const decimalPlaces = value.length - decimalIndex - 1;
          if (decimalPlaces > decimals) {
            value = value.slice(0, decimalIndex + decimals + 1);
          }
        }
        form.total = form.amount / last_price;
      }
      form[name] = value;
      setMarketForm({ ...form });
    };
  };

  const sendMarketRequest = () => {
    setIsloading(true);
    var data = {
      amount: marketForm.amount,
      pair: `${from}_${to}`,
      order: "market",
      type: side,
      trading_pwd: state.trading_password,
      token: getStorage(ItemNames.TOKEN),
      device: "web",
    };
    sendRequestEmit("trade:market", data);
    setMarketForm({ amount: 0 });
    setIsloading(false);
  };

  const slideChange = (e) => {
    let percentage = e.target.textContent;
    let balance = side === "sell" ? balance_from : balance_to;
    let amount_calculated = balance * (percentage / 100);

    setMarketForm({
      ...marketForm,
      amount:
        side === "sell"
          ? toFixed(
              parseFloat(amount_calculated),
              state.currency.details[from].amount_decimal || 6
            )
          : toFixed(
              parseFloat(amount_calculated),
              state.currency.details[to].amount_decimal || 6
            ),
      total:
        side === "sell"
          ? last_price * amount_calculated
          : amount_calculated / last_price,
    });
  };

  const navigate = useNavigate();
  const goToKYCPage = () => {
    navigate("/profile");
  };

  const goToLoginPage = () => {
    navigate("/login");
  };

  const checkBTNState = () => {
    if (isLoggedIn() === true) {
      if (state.user_profile.kyc_status !== 3) {
        return (
          <Button
            variant="success"
            className="btn-market-buy"
            onClick={goToKYCPage}
          >
            {t('Trade_KYC_Error')}
          </Button>
        );
      } else if (state.trading_password === "") {
        return (
          <Button
            variant="success"
            className="btn-market-buy"
            onClick={openTradingPass}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            {isLoading ? t('Global_Loading') : t('Trade_Trading_Password_btn')}
          </Button>
        );
      } else {
        return (
          <Button
            variant="success"
            className={side === "sell" ? "btn-market-sell" : "btn-market-buy"}
            onClick={warningHandlerMarket}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            {isLoading ?  t('Global_Loading') : `${side === "sell" ? t('Global_Sell') : t('Global_Buy')}`}
          </Button>
        );
      }
    } else if (isLoggedIn() === false) {
      return (
        <Button
          variant="success"
          className="btn-limit-buy"
          onClick={goToLoginPage}
        >
          {t('Trade_Login_Register')}
        </Button>
      );
    }
  };
  const [isLoading, setIsloading] = useState(false);

  const { state, dispatch } = useContext(UserContext);
  const [showTPWDstate, setShowTPWDstate] = useState(false);
  const handleCloseTPWDstate = () => setShowTPWDstate(false);
  const handleShowTPWDstate = () => setShowTPWDstate(true);

  const [showTPWDinput, setShowTPWDinput] = useState(false);
  const handleCloseTPWDinput = () => setShowTPWDinput(false);
  const handleShowTPWDinput = () => setShowTPWDinput(true);

  const [tradingPassword, setTradingPassword] = useState(null);

  const handleChangeTradingPassword = (event) => {
    setTradingPassword(event.target.value);
  };

  const openTradingPass = () => {
    if ("trading_pwd" === "trading_pwd1") {
      handleShowTPWDstate();
    } else {
      handleShowTPWDinput();
    }
  };

  const goToTPWDpage = () => {
    navigate("/profile");
  };

  const checkTradingPwdValidity = () => {
    if (tradingPassword === null || tradingPassword === "") {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: t('Trade_Password_Error'),
      });
    } else {
      conFirmTPWD();
    }
  };
  const conFirmTPWD = () => {
    setIsloading(true);
    var uri = "https://api.easyexchangepro.com/api/v2/user/trading/check";
    var data = { password: tradingPassword };
    fetch(uri, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status === 1) {
          dispatch({ type: "set:trading_pass", password: tradingPassword });
          dispatch({
            type: "open_snack",
            snackColor: "success",
            snackMessage: data.msg,
          });
          handleCloseTPWDinput();
          setIsloading(false);
        } else {
          dispatch({
            type: "open_snack",
            snackColor: "warning",
            snackMessage: data.msg,
          });
          setIsloading(false);
        }
      });
  };

  const minimumOrderLimit = 20;
  const balanceToDecimaled = `${toFixed(
    parseFloat(balance_to),
    state.currency.details[to].amount_decimal || 4
  )}`;
  const balanceFromDecimaled = `${toFixed(
    parseFloat(balance_from),
    state.currency.details[to].amount_decimal || 4
  )} `;

  const warningHandlerMarket = () => {
    if (
      marketForm.amount === "" ||
      marketForm.amount === null ||
      marketForm.amount === 0
    ) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: t('Global_Amount_Error'),
      });
    } else if (side === "buy" && marketForm.amount > balance_to) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: `${t('Trade_Balance_Error')} ${balanceToDecimaled} ${to}`,
      });
    } else if (side === "sell" && marketForm.amount > balance_from) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: `${t('Trade_Balance_Error')} ${balanceFromDecimaled} ${from}`,
      });
    } else if (side === "buy" && marketForm.amount < minimumOrderLimit) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: t('Trade_Min_Error'),
      });
    } else if (side === "sell" && marketForm.total < minimumOrderLimit) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: t('Trade_Min_Error'),
      });
    } else {
      sendMarketRequest();
    }
  };
  return (
    <div className="limit-buy-main">
      <div className="row  ">
        <div className="col available-section">
          <p>{t('Global_Available')}:</p>
          <p>
            {side === "sell"
              ? `${toFixed(
                  parseFloat(balance_from),
                  state.currency.details[from].amount_decimal || 6
                )}    ${from}`
              : `${toFixed(
                  parseFloat(balance_to),
                  state.currency.details[to].amount_decimal || 4
                )} 
               ${to}`}
          </p>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <InputGroup className="mb-1">
            <InputGroup.Text>{t('Global_Amount')}</InputGroup.Text>
            <Form.Control
              aria-label="amount"
              value={marketForm.amount}
              onChange={inputChange("amount")}
            />
            <InputGroup.Text>{side === "sell" ? from : to}</InputGroup.Text>
          </InputGroup>
          <div className="row">
            <div className="col slider-trade">
              <TradeSlider onchange={slideChange} />
            </div>
          </div>
          <div className="mb-4">
            <div className="col-12 market-total-value">
            {t('Trade_approximately')}
            </div>
            <div className="row justify-content-between">
              <div className="col-8 market-total-value">
                {toFixed(
                  parseFloat(marketForm.total),
                  state.currency.details[`${side === "sell" ? to : from}`]
                    .amount_decimal || 6
                )}{" "}
              </div>
              <div className="col-4 market-total-value text-end">
                {side === "sell" ? to : from}
              </div>
            </div>
          </div>

          <div className="col">{checkBTNState()}</div>
        </div>
      </div>
      <Modal
        show={showTPWDstate}
        onHide={handleCloseTPWDstate}
        className="modal-withdraw"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Trade_Trading_password_Error')}</Modal.Title>
        </Modal.Header>
        <ModalFooter>
          <Button className="cancel-withdraw" onClick={handleCloseTPWDstate}>
          {t('Global_Cancel')}
          </Button>
          <Button className="confirm-withdraw" onClick={goToTPWDpage}>
          {t('Trade_goto_security')}
          </Button>
        </ModalFooter>
      </Modal>
      <>
        <Modal
          show={showTPWDinput}
          onHide={handleCloseTPWDinput}
          className="modal-withdraw"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('Trade_Trading_password_title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-list">
            <FormControl fullWidth>
              <TextField
                required
                className="withdraw-texts"
                InputProps={{ sx: { height: 57 } }}
                id="tradePass"
                label={t('Trade_Trading_password_input')}
                size="medium"
                type="password"
                name="trading_password"
                onChange={handleChangeTradingPassword}
                InputLabelProps={{
                  shrink: true,
                  className: "withdraw-texts",
                }}
                inputProps={{
                  maxLength: 6,

                  form: {
                    autocomplete: "off",
                  },
                }}
              />
            </FormControl>
          </Modal.Body>
          <ModalFooter>
            <Button className="cancel-withdraw" onClick={handleCloseTPWDinput}>
            {t('Global_Cancel')}
            </Button>
            <Button
              className="confirm-withdraw"
              onClick={checkTradingPwdValidity}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              {isLoading ? t('Global_Loading') : t('Global_Confirm')}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    </div>
  );
}

export default Market;
