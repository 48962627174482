import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { UserContext } from "../../../contexts/providers/user";
import moment from "moment";
import { Button } from "react-bootstrap";
import { ItemNames, getStorage } from "../../../utils/RSS";
import { useContext } from "react";
import { toFixed } from "../../../utils/math";
import { useTranslation } from "react-i18next";

const TIME_FORMAT = "YYYY-M-D H:m:s";


export default function OpenHistoryTable({ orders = [], type = "open" }) {
  if (type === "open") {
    return Open(orders);
  } else if (type === "match") {
    return MatchTable(orders);
  }
  return TradeTable(orders);
}

const getNumberFromObject = (num) => {
  if (typeof num === "object") {
    return num["$numberDecimal"];
  }

  return num;
};

const Open = (orders) => {
  const { state, dispatch, dispatchIO } = useContext(UserContext);

  const CancelOrderRequest = (order_id) => {
    let uri = `https://traapi.easyexchangepro.com/trade/cancel/${order_id}`;
    fetch(uri, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === 1) {
          dispatch({
            type: "open_snack",
            snackColor: "success",
            snackMessage: data.msg,
          });
          dispatchIO({ type: "set:user:orders", orders: data.data.history });
          dispatchIO({ type: "set:user:balances", wallets: data.data.wallets });
        } else {
          dispatch({
            type: "open_snack",
            snackColor: "warning",
            snackMessage: data.msg,
          });
        }
      });
  };
  const { t } = useTranslation();
  return (
    <TableContainer
      component={Paper}
      className="security-table"
      sx={{ minWidth: 1050, maxHeight: 275 }}
    >
      <Table
        sx={{ minWidth: 1050, maxHeight: 275 }}
        aria-label="simple table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('Trade_Date_Time')}</TableCell>
            <TableCell align="left">{t('Global_Pair')}</TableCell>
            <TableCell align="left">{t('Trade_Type')}</TableCell>
            <TableCell align="left">{t('Global_Amount')}</TableCell>
            <TableCell align="left">{t('Global_Price')}</TableCell>
            <TableCell align="left">{t('Global_Total')}</TableCell>
            <TableCell align="left">{t('Global_Status')}</TableCell>
            <TableCell align="left">{t('Trade_Action')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row) => (
            <TableRow
              key={row.created_at}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                {moment(row.created_at).format(TIME_FORMAT)}
              </TableCell>
              <TableCell align="left">
                <img
                  src={state.currency.images[row.pair.from]}
                  style={{ width: 25, height: 25, marginRight: 10 }}
                  alt={"currency"}
                />
                {row.pair.from}_{row.pair.to}
              </TableCell>
              <TableCell align="left">
                {row.order_type} [{row.side}]
              </TableCell>
              <TableCell align="left">
                {toFixed(
                  parseFloat(`${getNumberFromObject(row.amount)}`),
                  state.currency.details[row.pair.from].amount_decimal || 6
                )}
              </TableCell>
              <TableCell align="left">
                {toFixed(
                  parseFloat(`${getNumberFromObject(row.price)}`),
                  state.currency.details[row.pair.from].price_decimal || 6
                )}
              </TableCell>
              <TableCell align="left">
                {toFixed(
                  parseFloat(`${getNumberFromObject(row.total)}`),
                  state.currency.details[row.pair.from].total_decimal || 6
                )}
              </TableCell>
              <TableCell align="left">{row.status}</TableCell>
              <TableCell align="left">
                <Button onClick={() => CancelOrderRequest(row._id)}>
                {t('Global_Cancel')}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TradeTable = (orders) => {
  const { state } = React.useContext(UserContext);
  const { t } = useTranslation();

  return (
    <TableContainer
      component={Paper}
      className="security-table"
      sx={{ minWidth: 1050, maxHeight: 275 }}
    >
      <Table
        sx={{ minWidth: 1050, maxHeight: 275 }}
        aria-label="simple table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
          <TableCell align="left">{t('Trade_Date_Time')}</TableCell>
            <TableCell align="left">{t('Global_Pair')}</TableCell>
            <TableCell align="left">{t('Trade_Type')}</TableCell>
            <TableCell align="left">{t('Global_Amount')}</TableCell>
            <TableCell align="left">{t('Global_Price')}</TableCell>
            <TableCell align="left">{t('Global_Total')}</TableCell>
            <TableCell align="left">{t('Global_Status')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row) => (
            <TableRow
              key={row.created_at}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                {moment(row.created_at).format(TIME_FORMAT)}
              </TableCell>
              <TableCell align="left">
                <img
                  src={state.currency.images[row.pair.from]}
                  style={{ width: 25, height: 25, marginRight: 10 }}
                  alt={"currency"}
                />
                {row.pair.from}_{row.pair.to}
              </TableCell>
              <TableCell align="left">
                {row.order_type} [{row.side}]
              </TableCell>
              <TableCell align="left">
                {toFixed(
                  parseFloat(`${getNumberFromObject(row.amount)}`),
                  state.currency.details[row.pair.from].amount_decimal || 6
                )}
              </TableCell>
              <TableCell align="left">
                {toFixed(
                  parseFloat(`${getNumberFromObject(row.price)}`),
                  state.currency.details[row.pair.from].price_decimal || 6
                )}
              </TableCell>
              <TableCell align="left">
                {toFixed(
                  parseFloat(`${getNumberFromObject(row.total)}`),
                  state.currency.details[row.pair.from].total_decimal || 6
                )}
              </TableCell>
              <TableCell align="left">{row.status}</TableCell>
              <TableCell align="left">{row.action}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MatchTable = (orders) => {
  const { state } = useContext(UserContext);
  const { t } = useTranslation();
  return (
    <TableContainer
      component={Paper}
      className="security-table"
      sx={{ minWidth: 1050, maxHeight: 275 }}
    >
      <Table
        stickyHeader
        sx={{ minWidth: 1050, maxHeight: 275 }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
          <TableCell align="left">{t('Trade_Date_Time')}</TableCell>
            <TableCell align="left">{t('Global_Pair')}</TableCell>
            <TableCell align="left">{t('Trade_Type')}</TableCell>
            <TableCell align="left">{t('Global_Amount')}</TableCell>
            <TableCell align="left">{t('Global_Price')}</TableCell>
            <TableCell align="left">{t('Global_Fees')}</TableCell>
            <TableCell align="left">{t('Global_Total')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row) => (
            <TableRow
              key={row.created_at}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                {moment(row.created_at).format(TIME_FORMAT)}
              </TableCell>
              <TableCell align="left">
                <img
                  src={state.currency.images[row.pair.from]}
                  style={{ width: 25, height: 25, marginRight: 10 }}
                  alt={"currency"}
                />
                {row.pair.from}_{row.pair.to}
              </TableCell>
              <TableCell align="left">
                {row.order_type} [{row.side}]
              </TableCell>
              <TableCell align="left">
                {toFixed(
                  parseFloat(row.amount),
                  state.currency.details[row.pair.from].amount_decimal || 6
                )}
              </TableCell>
              <TableCell align="left">
                {toFixed(
                  parseFloat(row.price),
                  state.currency.details[row.pair.from].price_decimal || 6
                )}
              </TableCell>
              <TableCell align="left">
                {parseFloat(row.fee).toFixed(6)}
              </TableCell>
              <TableCell align="left">
                {toFixed(
                  parseFloat(row.total),
                  state.currency.details[row.pair.from].total_decimal || 6
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
