import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import "./profile.scss";
import MarkEmailReadRoundedIcon from "@mui/icons-material/MarkEmailReadRounded";
import PatternRoundedIcon from "@mui/icons-material/PatternRounded";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
import FingerprintRoundedIcon from "@mui/icons-material/FingerprintRounded";
import HttpsRoundedIcon from "@mui/icons-material/HttpsRounded";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { Modal } from "react-bootstrap";
import { getReferral } from "../../utils/Api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function PInfo({ profileInfo }) {
  const errorCatch = (error) => {};

  const [referralList, setReferralList] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (referralList.length === 0) {
      getReferral(setReferralList, errorCatch);
    }
  }, []);

  const kycStatus = () => {
    if (profileInfo.kyc_status === 3) {
      return t('Profile_KYC_Verified');
    } else if (profileInfo.kyc_status === 2) {
      return t('Profile_KYC_Pending');
    } else if (profileInfo.kyc_status === 1) {
      return t('Profile_KYC_Rejected');
    } else {
      return t('Profile_KYC_NotUploaded');
    }
  };

  const securityLevel = () => {
    if (profileInfo.security_level === 1) {
      return t('Global_Low');
    } else if (profileInfo.security_level === 2) {
      return t('Profile_Sec_lvl_Medium');
    } else if (profileInfo.security_level === 3) {
      return t('Global_High');
    } else if (profileInfo.security_level === 4) {
      return t('Profile_Sec_lvl_VeryHigh');
    }
  };

  let navigate = useNavigate();
  const goToCommission = () => {
    let path = `/commission`;
    navigate(path);
  };
  const { t } = useTranslation();

  return (
    <div className="">
      <div className="row d-flex align-items-center">
        <div className="col">
          <Card style={{ width: "17rem", height: "16rem" }}>
            <Card.Body className="user-info">
              <Card.Title>{t('Profile_User_info_title')}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
              {t('Profile_User_info_description')}
              </Card.Subtitle>
              <Card.Text>
                <p>{t('Global_Email')}: {profileInfo.format_email}</p>
              </Card.Text>
              <Card.Text>
                <p>{t('Global_Username')}: {profileInfo.username}</p>
              </Card.Text>
              <Card.Text>
                <p>{t('Global_UID')}: {profileInfo.uid}</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col">
          <Card style={{ width: "17rem", height: "16rem" }}>
            <Card.Body className="user-info">
              <Card.Title className="card-title-security">
              {t('Profile_Sec_info_title')}
              </Card.Title>
              <Card.Text>
                <p>
                  <MarkEmailReadRoundedIcon style={{ color: "#38E54D" }} />{" "}
                  {t('Global_Email')} {t('Global_OTP')}:{" "}
                  {profileInfo.is_email_confirmed === true && t('Profile_Activated')}
                </p>
              </Card.Text>
              <Card.Text>
                <p>
                  <PatternRoundedIcon style={{ color: "#38E54D" }} /> {t('Global_2FA')}:
                  {profileInfo.tfa_status ? t('Profile_Activated') : t('Profile_Disabled')}
                </p>
              </Card.Text>
              <Card.Text>
                <p>
                  <VpnKeyRoundedIcon style={{ color: "#38E54D" }} /> {t('Profile_Trading_Password')}: {profileInfo.is_trading === true && t('Profile_Activated')}
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col">
          <Card style={{ width: "17rem", height: "16rem" }}>
            <Card.Body className="user-info">
              <Card.Title className="card-title-security">
              {t('Profile_Account_status_title')}
              </Card.Title>
              <Card.Text>
                <p>
                  <FingerprintRoundedIcon style={{ color: "#38E54D" }} /> {t('Profile_KYC')}:
                  {kycStatus()}
                </p>
              </Card.Text>
              <Card.Text>
                <p>
                  <HttpsRoundedIcon style={{ color: "#38E54D" }} /> {t('Profile_Sec_level')}: {securityLevel()}
                </p>
              </Card.Text>
              <Card.Text>
                <p>
                  <EmojiEventsRoundedIcon style={{ color: "#38E54D" }} />{" "}
                  {t('Profile_Airdrop_point')}: {profileInfo.airdrop_count}
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col">
          <Card style={{ width: "17rem", height: "16rem" }}>
            <Card.Body className="user-info">
              <Card.Title className="card-title-security">{t('Profile_Referrals_title')}</Card.Title>
              <Card.Text>
                <p>
                  <EventNoteRoundedIcon style={{ color: "#38E54D" }} /> {t('Profile_Num_Referrals')}: {profileInfo.referral_count}
                </p>
              </Card.Text>
              <Card.Text>
                <p>
                  <HttpsRoundedIcon style={{ color: "#38E54D" }} /> {t('Profile_Referral_ID')}:
                  {profileInfo.refer_id}
                </p>
              </Card.Text>
              <CardActions>
                <Button size="small" onClick={handleShow}>
                {t('Profile_Referral_List_btn')}
                </Button>
                <Button size="small" onClick={goToCommission}>
                {t('Profile_Commissions_btn')}
                </Button>
              </CardActions>
            </Card.Body>
          </Card>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{t('Profile_Referral_List_btn')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-list">
              {referralList.map((item, index) => {
                return (
                  <>
                    <ul>
                      <li Key={index}>{t('Profile_User')}: {item.username}</li>
                    </ul>
                  </>
                );
              })}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default PInfo;
