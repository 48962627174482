import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ViewListIcon from "@mui/icons-material/ViewList";
import ReorderIcon from "@mui/icons-material/Reorder";
import { pink, green, grey } from "@mui/material/colors";
import UserOrderTitle from "./UserOrderTitle";
import UserOrder from "./UserOrder";
import LastPriceModule from "./LastPriceModule";
import SimpleBar from "simplebar-react";
import { UserContext } from "../../../contexts/providers/user";
import { useContext } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserOrderList() {
  const [value, setValue] = useState(0);
  const { stateIO } = useContext(UserContext);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="orderlist-main">
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              icon={<ViewListIcon />}
              sx={{ color: grey[500] }}
              {...a11yProps(0)}
            />
            <Tab
              icon={<ReorderIcon />}
              sx={{ color: pink[500] }}
              {...a11yProps(1)}
            />
            <Tab
              icon={<ReorderIcon />}
              sx={{ color: green[500] }}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <UserOrderTitle />
        <TabPanel value={value} index={0}>
          {stateIO.sells
            .slice(0, 15)
            .reverse()
            .map((item, index) => {
              return (
                <UserOrder
                  key={`limit_order_sell${index}`}
                  order={item}
                  side={"sell"}
                />
              );
            })}
          <LastPriceModule />
          {stateIO.buys.slice(0, 15).map((item, index) => {
            return (
              <UserOrder
                key={`limit_order_buy${index}`}
                order={item}
                side={"buys"}
              />
            );
          })}
        </TabPanel>
        <TabPanel value={value} index={1} className="user-allorders">
          <SimpleBar
            style={{ maxHeight: 625, paddingRight: 10 }}
            autoHide={false}
          >
            {stateIO.sells.map((item, index) => {
              return (
                <UserOrder
                  key={`order_sell${index}`}
                  order={item}
                  side={"sell"}
                />
              );
            })}
          </SimpleBar>
        </TabPanel>
        <TabPanel value={value} index={2} className="user-allorders">
          <SimpleBar
            style={{ maxHeight: 625, paddingRight: 10 }}
            autoHide={false}
          >
            {stateIO.buys.map((item, index) => {
              return (
                <UserOrder
                  key={`order_buy${index}`}
                  order={item}
                  side={"buy"}
                />
              );
            })}
          </SimpleBar>
        </TabPanel>
      </Box>
    </div>
  );
}
