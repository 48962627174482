import React, { useContext } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { UserContext } from "../../../contexts/providers/user";
function LastPriceModule() {
  const { stateIO } = useContext(UserContext);

  const colorInTrade = () => {
    if (stateIO?.details.color === "text-danger") {
      return "textdanger";
    } else if (stateIO?.details.color === "text-success") {
      return "textsuccess";
    }
  };
  return (
    <div className="row last-price-orderlisl">
      <div className="col-3 text-start">
        <p className={`last-price-orderlist-center ${colorInTrade()}`}>
          {stateIO?.details.price}
        </p>
      </div>
      <div className="col-1">
        <p className={`arrow-in-orderlist text-center ${colorInTrade()}`}>
          {stateIO?.details.color === "text-success" && <KeyboardArrowUpIcon />}
          {stateIO?.details.color === "text-danger" && (
            <KeyboardArrowDownIcon />
          )}
        </p>
      </div>
      <div className="col-4">
        <p className="last-price-usd-orderlist"> ${stateIO?.details.price}</p>
      </div>
    </div>
  );
}

export default LastPriceModule;
