import React, { useContext, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { UserContext } from "../../contexts/providers/user";
import { getSummary } from "../../utils/Api";
import { Link, NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const responsive = {
  desktop: {
    breakpoint: { max: 2800, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

function PriceSliderMobile() {
  const { state } = useContext(UserContext);
  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    getSummary(
      (data) => setSummaryData(data), // Set data on successful fetch
      (error) => console.error(error) // Log error on failure
    );
  }, []);
  const { t } = useTranslation();
  return (
    <>
      {summaryData.length > 0 ? (
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={false}
          customTransition="all 1.5s"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        >
          {/* <div className="carousel-items">11111</div>
          <div className="carousel-items">2222</div> */}
          {summaryData.map((row, index) => (
            <div className="container-fluid carousel-items" key={index}>
              <div className="row justify-content-center" style={{ width: "100%" }}>
                <div className="col-12">
                  <div className="row mb-2">
                    <div className="col-2">
                      <img
                        src={state.currency.images[row.base_currency]}
                        style={{ width: 25, height: 25, marginRight: 10 }}
                        alt="currency"
                      />
                    </div>
                    <div className="col-7 mt-1" style={{ fontSize: 15 }}>
                      <span>{row.base_currency}</span> <span style={{ fontSize: 9 }}>USDT</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-7">
                      {t('Global_LastPrice')}
                    </div>
                    <div className="col-5 text-end">
                      {row.last_price}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-7">
                      {t('Global_24h')} {t('Global_High')}
                    </div>
                    <div className="col-5 text-end">
                      {row.highest_price_24h}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-7">
                      {t('Global_24h')} {t('Global_Low')}
                    </div>
                    <div className="col-5 text-end">
                      {row.lowest_price_24h}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-7" style={{ color: row.change <= 0 ? 'red' : 'green' }}>

                      {t('Global_24h')} {t('Global_Change')}
                    </div>
                    <div className="col-5 text-end" style={{ color: row.change <= 0 ? 'red' : 'green' }}>
                      {row.change}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-7">
                      {t('Global_24h')} {t('Global_Volume')}
                    </div>
                    <div className="col-5 text-end">
                      {parseFloat(row.quote_volume).toFixed(2)}
                    </div>
                  </div>

                  <NavLink as={Link} to={`/trade/${row.trading_pairs}`} className="nav-link">
                    <Button className="market-table-btn-mobile">{t('Global_Trade')}</Button>
                  </NavLink>
                </div>
              </div>
            </div>

          ))}
        </Carousel >
      ) : (
        <p></p>
      )
      }
    </>
  );
}

export default PriceSliderMobile;
