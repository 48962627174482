import React from "react";

import "./notificationpage.scss";
function Notificationpage(props) {
  return (
    <div className="notification-main">
      <div className="container notification-module">
        <div className="row">
          <div className="col">
            <ul>
              {/* {props.list.map((item, index) => (
                <li>{item.message}</li>
              ))} */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notificationpage;
