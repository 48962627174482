import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function BottomNavigationMobile() {
    const navigate = useNavigate()
    const goToPage = (path) => {
        navigate(path)
    }

    return (
        <div className='container-fluid'>
            <div className="row justify-content-between">
                <div className="col-3 text-center">
                    <Button className='btn-navigation-m' onClick={() => goToPage('/')}>
                        <HomeIcon className='btn-navigation-m-icon' />
                    </Button>
                </div>
                <div className="col-3 text-center">
                    <Button className='btn-navigation-m' onClick={() => goToPage('/market')}>
                        <BarChartIcon className='btn-navigation-m-icon' />
                    </Button>
                </div>
                <div className="col-3 text-center">
                    <Button className='btn-navigation-m' onClick={() => goToPage('/trade/BTC_USDT')}>
                        <CandlestickChartIcon className='btn-navigation-m-icon' />
                    </Button>
                </div>
                <div className="col-3 text-center">
                    <Button className='btn-navigation-m' onClick={() => goToPage('/assets')}>
                        <AccountBalanceWalletIcon className='btn-navigation-m-icon' />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default BottomNavigationMobile
