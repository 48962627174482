import React, { useEffect, useState } from "react";
import "./guaranteeform.scss";

import EGform from "./EGform";
import HeaderMobile from "../../home/HeaderMobile";
import BottomNavigationMobile from "../../home/BottomNavigationMobile";

function Guaranteeform() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);
  if (pageWidth > 768) {
    return (
      <div className="guaranteeform-main">
        <div className="guaranteeform-module">
          <EGform />
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="guaranteeform-main">
          <div className="guaranteeform-module" style={{ marginTop: 120, marginBottom: 100 }}>
            <EGform />
          </div>
        </div>
      </>
    );
  }
}

export default Guaranteeform;
