import React from "react";
import ImageOne from "../../../assets/images/fast-icon-g.svg";
import ImageTwo from "../../../assets/images/reliable-icon-g.svg";
import ImageThree from "../../../assets/images/secure-icon.svg";
import "./easyguarantee.scss";
import { useTranslation } from "react-i18next";

function EGtexts() {
  const { t } = useTranslation();
  return (
    <>
      <div className="bg-white-eg">
        <div className="guarantee-exp ">
          <h2>{t('EG_EGText_title')}</h2>
          <p>
          {t('EG_EGText_Description_1')}
          </p>
          <p>
          {t('EG_EGText_Description_2')}
          </p>
        </div>
      </div>

      <div className="triples-main">
        <div className="triples">
          <div className="triples-box">
            <div className="triple-img">
              <img
                className="img-fluid mt-4"
                style={{ maxHeight: 50 }}
                src={ImageOne}
                alt=""
              />
              <h4>{t('Global_Fast')}</h4>
              <p className="p-triplebox">
                {t('EG_EGText_Feature_1_description')}{" "}
              </p>
            </div>
          </div>
          <div className="triples-box">
            <div className="triple-img">
              <img
                className="img-fluid mt-4"
                style={{ maxHeight: 50 }}
                src={ImageTwo}
                alt=""
              />
              <h4> {t('EG_EGText_Feature_2_title')} </h4>
              <p className="p-triplebox">
              {t('EG_EGText_Feature_2_description')}
              </p>
            </div>
          </div>
          <div className="triples-box">
            <div className="triple-img">
              <img
                className="img-fluid mt-4"
                style={{ maxHeight: 50 }}
                src={ImageThree}
                alt=""
              />
              <h4> {t('Global_Secure')} </h4>
              <p className="p-triplebox">
              {t('EG_EGText_Feature_3_description')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EGtexts;
