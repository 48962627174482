import { useContext, useState } from "react";
import "./deposit.scss";
import QRCode from "react-qr-code";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { Button } from "react-bootstrap";
import { Grid } from "@mui/material";
import { getDepositCurrency } from "../../../utils/Api";
import { useEffect } from "react";
import { ItemNames, getStorage } from "../../../utils/RSS";
// import Image from "../../../assets/Untitled.png";
import { UserContext } from "../../../contexts/providers/user";
import CopyButton from "../../../components/CopyBtn";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../../home/HeaderMobile";
import BottomNavigationMobile from "../../home/BottomNavigationMobile";
function Deposit() {
  const { state } = useContext(UserContext);
  const [tokenSelected, setTokenSelected] = useState("");
  const [networkSelected, setNetworkSelected] = useState("");
  const [isTokenSelected, setIsTokenSelected] = useState(true);
  const [cryptoAddress, setCryptoAddress] = useState(null);

  const errorCatch = (error) => { };

  const [depositList, setDepositList] = useState([]);

  useEffect(() => {
    getDepositCurrency(setDepositList, errorCatch);
  }, []);

  const tokenOptions = Object.keys(depositList)
    .sort()
    .map((token) => (
      <MenuItem value={token} key={token}>
        <img
          src={state.currency.images[token]}
          // src={depositList.image}
          style={{ width: 25, height: 25, marginRight: 10 }}
          alt={"currency"}
        />
        {token}
      </MenuItem>
    ));

  const networkOptions = depositList[tokenSelected]
    ? Object.keys(depositList[tokenSelected]).map((network) => {
      const fixedNetworkName = fixNetworkNames(network);

      return (
        <MenuItem value={network} key={network}>
          {fixedNetworkName}
        </MenuItem>
      );
    })
    : null;

  const handleTokenChange = (event) => {
    setCryptoAddress(null);
    setTokenSelected(event.target.value);
    setIsTokenSelected(false);
    setNetworkSelected("");
  };

  const handleNetworkChange = (event) => {
    setNetworkSelected(event.target.value);
    requestGetAddress(depositList[tokenSelected][event.target.value]);
  };

  const requestGetAddress = (currency) => {
    var host = "https://trnsapiion.easyexchangepro.com/trans/createAddress";
    fetch(host, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
      body: JSON.stringify({
        currency: tokenSelected,
        currency_id: currency["id"],
        networktype: currency["network"],
        type: currency["type"],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setCryptoAddress(data.address);
      });
    // .catch((error) => setErrorFunction(error));
  };

  function fixNetworkNames(item) {
    if (item.toString().toUpperCase() === "BEP20") {
      return "Binance BEP20";
    } else if (item.toString().toUpperCase() === "TRX") {
      return "Tron TRC20";
    } else if (item.toString().toUpperCase() === "BTC") {
      return "Bitcoin Network";
    } else if (item.toString().toUpperCase() === "ETH") {
      return "Ethereum ERC20";
    } else if (item.toString().toUpperCase() === "DOGE") {
      return "Dogecoin";
    } else if (item.toString().toUpperCase() === "LTC") {
      return "Litecoin Network";
    } else {
      return item.toString().toUpperCase();
    }
  }
  const { t } = useTranslation();

  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);

  if (pageWidth > 768) {
    return (
      <div className="deposit-main">
        <div className="container deposit">
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 deposit-module m-4">
              <div className="row m-3">
                <div className="col">
                  <h5>{t('Global_Deposit')}</h5>
                  <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                    <h6>{t('Deposit_select_Token')}</h6>

                    <FormControl fullWidth>
                      <Select value={tokenSelected} onChange={handleTokenChange}>
                        <MenuItem value=""></MenuItem>
                        {tokenOptions}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                    <h6 hidden={isTokenSelected}>{t('Deposit_select_Network')}</h6>
                    <FormControl fullWidth>
                      <Select
                        value={networkSelected}
                        onChange={handleNetworkChange}
                        hidden={isTokenSelected}
                      >
                        <MenuItem value=""></MenuItem>
                        {networkOptions}
                      </Select>
                    </FormControl>
                  </Box>

                  {cryptoAddress && (
                    <>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-9 d-flex align-items-center">
                            <h6 className="deposit-address">
                              {t('Deposit_Address')}: {cryptoAddress}
                            </h6>
                          </div>
                          <div className="col-3 d-flex align-items-center mb-2">
                            <CopyButton text={cryptoAddress} />
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          height: "auto",
                          margin: "0 auto",
                          maxWidth: 200,
                          width: "100%",
                        }}
                      >
                        <QRCode
                          size={512}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={cryptoAddress}
                          viewBox={`0 0 512 512`}
                        />
                      </div>
                      <div>
                        <p className="pt-4">
                          {t('Deposit_attention')}{" "}
                        </p>
                        <p className="attention-section">
                          <span className="text-danger">{t('Deposit_Address')}: </span>
                          {cryptoAddress}
                        </p>
                        <p className="attention-section">
                          <span className="text-danger">{t('Deposit_Network')}: </span>
                          {networkSelected.toUpperCase()}
                        </p>
                        <p className="text-danger">
                          {t('Deposit_Warning')}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="deposit-main mt-2 ">
          <div className="container deposit" style={{ marginBottom: 100 }}>
            <div className="row justify-content-center">
              <div className="col-10 col-md-6 deposit-module m-4">
                <div className="row m-3">
                  <div className="col">
                    <h5>{t('Global_Deposit')}</h5>
                    <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                      <h6>{t('Deposit_select_Token')}</h6>

                      <FormControl fullWidth>
                        <Select value={tokenSelected} onChange={handleTokenChange}>
                          <MenuItem value=""></MenuItem>
                          {tokenOptions}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                      <h6 hidden={isTokenSelected}>{t('Deposit_select_Network')}</h6>
                      <FormControl fullWidth>
                        <Select
                          value={networkSelected}
                          onChange={handleNetworkChange}
                          hidden={isTokenSelected}
                        >
                          <MenuItem value=""></MenuItem>
                          {networkOptions}
                        </Select>
                      </FormControl>
                    </Box>

                    {cryptoAddress && (
                      <>
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 d-flex align-items-center">
                              <h6 className="deposit-address" style={{ fontSize: 12 }}>
                                {t('Deposit_Address')}: {cryptoAddress}
                              </h6>
                            </div>
                            <div className="col-12 text-end mb-2">
                              <CopyButton text={cryptoAddress} />
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            height: "auto",
                            margin: "0 auto",
                            maxWidth: 150,
                            width: "100%",
                          }}
                        >
                          <QRCode
                            size={400}
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                              width: "100%",
                            }}
                            value={cryptoAddress}
                            viewBox={`0 0 400 400`}
                          />
                        </div>
                        <div>
                          <p className="pt-4" style={{ fontSize: 12 }}>
                            {t('Deposit_attention')}{" "}
                          </p>
                          <p className="attention-section" style={{ fontSize: 12 }}>
                            <span className="text-danger">{t('Deposit_Address')}: </span>
                            {cryptoAddress}
                          </p>
                          <p className="attention-section" style={{ fontSize: 12 }}>
                            <span className="text-danger">{t('Deposit_Network')}: </span>
                            {networkSelected.toUpperCase()}
                          </p>
                          <p className="text-danger" style={{ fontSize: 12 }}>
                            {t('Deposit_Warning')}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Deposit;
