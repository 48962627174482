const resources = {
  en: {
    translation: {
      ///////////////////// GLOBAL
      Already_Purchased: "Already purchased",
      Global_Products: "Products",
      Global_Transaction: "Transaction",
      Global_Icon: "Icon",
      Global_Pair: "Pair",
      Global_LastPrice: "Last Price",
      Global_Price: "Price",
      Global_24h: "24h",
      Global_High: "High",
      Global_Low: "Low",
      Global_Change: "Change",
      Global_Volume: "Volume",
      Global_Trade: "Trade",
      Global_EasyGuarantee: "Easy Guarantee",
      Global_ApplicationForm: "Application Form",
      Global_EasyCard: "Easy Card",
      Global_Secure: "Secure",
      Global_Fast: "Fast",
      Global_Convert: "Convert",
      Global_Gabur: "Gabur",
      Global_Token: "Token",
      Global_Total: "Total",
      Global_Amount: "Amount",
      Global_USDT: "USDT",
      Global_Stop: "Stop",
      Global_Limit: "Limit",
      Global_Supply: "Supply",
      Global_Whitepaper: "Whitepaper",
      Global_Circulation: "Circulation",
      Global_Website: "Website",
      Global_Buy: "Buy",
      Global_Sell: "Sell",
      Global_Loading: "Loading...",
      Global_Submit: "Submit",
      Global_Cancel: "Cancel",
      Global_Confirm: "Confirm",
      Global_Close: "Close",
      Global_OTP: "OTP",
      Global_Email: "Email",
      Global_2FA: "2FA",
      Global_Password: "Password",
      Global_FName: "First Name",
      Global_LName: "Last Name",
      Global_IDNum: "ID Number",
      Global_Login: "Login",
      Global_Register: "Register",
      Global_Transfer: "Transfer",
      Global_UID: "User ID",
      Global_Purpose: "Purpose",
      Global_Mobile: "Mobile",
      Global_Deposit: "Deposit",
      Global_Withdraw: "Withdraw",
      Global_Fee: "Fee",
      Global_Fees: "Fees",
      Global_OTC: "OTC",
      Global_Support: "Support",
      Global_Download: "Download",
      Global_From: "From",
      Global_To: "To",
      Global_Now: "Now",
      Global_Digital: "Digital",
      Global_crypto: "crypto",
      Global_cash: "cash",
      Global_wallet: "wallet",
      Global_account: "account",
      Global_Exchange: "Exchange",
      Global_trading: "trading",
      Global_Accept_Terms:
        "By checking the box you will accept our Terms & Conditions.",
      Global_Available: "Available",
      Global_Username: "Username",
      Global_Time: "Time",
      Global_Status: "Status",
      Global_Network_Address: "Network Address",
      Global_Max_upload: "Max Upload Size For Each File 10 MB",
      Global_Policy:
        "By Submitting Your Form You Will Accept Our Privacy Policy",
      Global_UID_Error: "Please enter User ID.",
      Global_IDNum_Error: "Please enter ID Number.",
      Global_Mobile_Error: "Please enter Mobile.",
      Global_Price_Error: "Please enter Price.",
      Global_Stop_Error: "Please enter Stop Price.",
      Global_Amount_Error: "Please enter Amount.",
      Global_Total_Error: "Please enter Total.",
      Global_Email_Error: "Please enter Email.",
      Global_Password_Error: "Please enter Password.",
      Global_FName_Error: "Please enter First Name.",
      Global_LName_Error: "Please enter Last Name.",
      Global_Website_Error: "Please enter Web Site.",
      Global_Purpose_Error: "Please explain Purpose of request.",
      Global_Accept_Terms_Error: "You must agree before submitting.",
      Global_about: "About US",
      Global_coin_info: "Coin info",
      Global_token_listing: "Token listing",
      Global_logout: "Logout",
      // Home
      //// Top
      Home_Top_Download_From: "Download from",
      Home_Top_Playstore: "Playstore",
      Home_Top_Download_APK: "Download APK",
      Home_Top_APPstore: "APPstore",
      Home_Top_Register_Now: "Register Now",
      //// carousel
      Home_Carousel_1_title: "Transparency and Security",
      Home_Carousel_1_description:
        "We have made transparent details about Gabur Token in Whitepaper for your decision on investing. Moreover, The Gabur token smart contract has been audited to ensure the highest level of security",
      Home_Carousel_2_title: "Easy Guarantee",
      Home_Carousel_2_description:
        "For the first time in digital world Easy Exchange Pro offers the Easy Guarantee system for Guarantee money transfer between companies and for business contracts",
      Home_Carousel_3_title: "24/7 Support",
      Home_Carousel_3_description:
        "You can contact support team with ticket system or send an email to support@easyexchangepro.com",
      //// Easy Guarantee
      Home_EG_subtitle: "First Digital Guarantee In Crypto World",
      Home_EG_description:
        "For the first time in digital world Easy Exchange Pro offers the Easy Guarantee system for Guarantee money transfer between companies and for business contracts.",
      Home_EG_more_info_btn: "More Info",
      //// Easy Card
      Home_EC_subtitle_1: "The only way to",
      Home_EC_subtitle_2: "buy crypto in cash",
      Home_EC_description_1:
        "Get the Easy Card prepaid code near you, and deposit",
      Home_EC_description_2:
        "the Gabur Tokens into your wallet. Safe and reliable without a",
      Home_EC_description_3:
        "bank or credit card. Its the only way to buy crypto in cash.",
      Home_EC_Feature_1_title: "Independent",
      Home_EC_Feature_1_description_1: "Buy crypto without a bank account or",
      Home_EC_Feature_1_description_2: "a credit card",
      Home_EC_Feature_2_description_1: "All your assets are under your",
      Home_EC_Feature_2_description_2: "own control",
      Home_EC_Feature_3_description_1: "Deposit cash into your wallet",
      Home_EC_Feature_3_description_2: "in seconds",
      Home_EC_Feature_4_title: "Easy",
      Home_EC_Feature_4_description_1: "Youre doing what youve always",
      Home_EC_Feature_4_description_2: "done",
      Home_EC_Feature_5_title: "No Deposit Fee",
      Home_EC_Feature_5_description_1: "Deposit without it costing you",
      Home_EC_Feature_5_description_2: "anything",
      Home_EC_Feature_6_description_1: "You can convert the Gabur token to",
      Home_EC_Feature_6_description_2: "other currencies.",
      Home_EC_Where_to_buy: "Where to buy",
      //// Mobile Section
      Home_MS_title:
        "Start trading easy with the Easy Exchange Pro mobile app.",
      Home_MS_list_1: "24/7 access to your assets anywhere",
      Home_MS_list_2: "Safe and Secure",
      Home_MS_list_3: "User-Friendly Interface",
      Home_MS_list_4: "Advanced trading Tools",
      Home_MS_list_5: "All-In-One App",
      Home_MS_dl_for_ios: "Download For iOS",
      Home_MS_dl_for_android: "Download For Android",
      //about
      //// A Top video
      About_top_title: "Our Mission",
      About_top_desciption:
        "Our mission consists of building an ecosystem granting everyone the right to easily access the digital currencies world, hence the financial freedom.",
      About_top_Feature_1: "600,000",
      About_top_Feature_1_description_1: "Registered users ",
      About_top_Feature_1_description_2: " ",
      About_top_Feature_2: "2 Locations",
      About_top_Feature_2_description: "Our locations in 2 countries",
      About_top_Feature_3: "+28 Countries",
      About_top_Feature_3_description: "Services in over 28 Countries",
      ///// A Second
      About_second_title: "Why choose Easy Exchange pro?",
      About_second_subtitle:
        "Easy for beginners and advanced for professionals",
      About_second_description_1:
        "Although the cryptocurrencies world can be a bit complex, we are here to help! Our platform facilitates trading.",
      About_second_description_2:
        "We provide you with tools to sell and buy the most popular cryptocurrencies all in one place, while being protected with industry leading and secured.",
      About_second_Feature_1_description_1:
        "Protected with security-leading industry",
      About_second_Feature_1_description_2: "based on cryptographic technology",
      About_second_Feature_2_title: "Easy to use",
      About_second_Feature_2_description_1:
        "We have designed a simple and easy",
      About_second_Feature_2_description_2:
        "platform and put it at your disposal",
      About_second_Feature_3_title: "Advanced use (for professionals)",
      About_second_Feature_3_description_1:
        "We developed an advanced trading interface",
      About_second_Feature_3_description_2:
        "that delivers professional experience to our users",
      About_second_Feature_4_description_1: "Trade your favorite currencies",
      About_second_Feature_4_description_2: "in a split second",
      About_second_Feature_5_title: "Buy in Cash",
      About_second_Feature_5_description_1:
        "The easiest way to buy cryptocurrency",
      About_second_Feature_5_description_2: "in cash is exclusive to Easy Card",
      About_second_Feature_6_title: "24/7",
      About_second_Feature_6_description_1:
        "Start trading cryptocurrencies and you will have our support at all times.",
      About_second_Feature_6_description_2: "",
      //// Road Map
      About_road_title: "Road Map",
      About_road_description_1:
        "The development of Easy exchange pro has been organized within three years.",
      About_road_description_2:
        "Here you will find an overview of our goals set to be accomplished in the years to come.",
      About_road_year_1: "2022",
      About_road_year_1_description_1: "Unique product: Easy Guarantee",
      About_road_year_1_description_2: "+ 6 languages",
      About_road_year_1_description_3: "+ 10 brokers in 5 countries",
      About_road_year_1_description_4: "+ 100 more employees",
      About_road_year_1_description_5: "+ 10 more pairs",
      About_road_year_2: "2023",
      About_road_year_2_description_1: "New product, Easy Wallet",
      About_road_year_2_description_2: "Easy pro Academy",
      About_road_year_2_description_3: "API for business integration",
      About_road_year_2_description_4:
        "The easy card will be an efficient method for transactions",
      About_road_year_2_description_5: "+ 6 Languages",
      About_road_year_2_description_6: "+ 200 Brokers in 16 countries",
      About_road_year_2_description_7: "+ 5 fiat currencies",
      About_road_year_2_description_8: "+ 250 employees",
      About_road_year_2_description_9: "+ 30 pairs",
      About_road_year_2_description_10: "+100 credibility coins",
      About_road_year_2_description_11:
        "GABUR listing on other world-class platforms",
      About_road_year_3: "2024",
      About_road_year_3_description_1: "Unique product: Crypto Bank",
      About_road_year_3_description_2: "+ 6 languages",
      About_road_year_3_description_3: "+ 1000 employees",
      About_road_year_3_description_4: "+ 100 pairs",
      About_road_year_3_description_5: "+ 130 credibility coins",
      About_road_year_3_description_6:
        "GABUR listing on other world-class platforms",
      // API Documents
      Api_title: "API Documents",
      Api_1: "Summary",
      Api_2: "Assets",
      Api_3: "Ticker",
      Api_4: "Orderbook",
      Api_5: "Trades",
      Api_6: "24h Volume",
      // Asset
      Asset_Tab_1: "All assets",
      Asset_Tab_2: "Main wallet",
      Asset_Tab_3: "Trading wallet",
      Asset_Total_BTC: "Total assets in BTC",
      Asset_In_Withdraw: "In withdraw",
      Asset_In_Order: "In Order",
      // Contact us
      ContactUs_email_title: "Get in touch with us:",
      ContactUs_email: "Support@easyexchangepro.com",
      ContactUs_faq_title: "You can check our FAQ page to find your answer:",
      ContactUs_faq: "",
      ContactUs_ticket_title: "Or you can raise a ticket:",
      ContactUs_ticket: "",
      ContactUs_social_title: "Find and follow us on:",

      // Coin info
      CoinInfo_Issue_Time: "Issue Time :",
      CoinInfo_White_paper: "Whitepaper:",
      CoinInfo_Website: "Website:",
      CoinInfo_Block_Explorer: "Block Explorer:",
      CoinInfo_AAVE:
        "Aave is an open source and non-custodial protocol enabling the creation of money markets. Users can earn interest on deposits and borrow assets. Deposit interest rate and loan interest rate of both borrowers and lenders are calculated through algorithms based on the platform's borrowing volume and deposit volume, and the platform uses Chainlink's oracles to ensure the fairness of the collateral price.",
      CoinInfo_ADA:
        "Cardano is a decentralised public blockchain and cryptocurrency project and is fully open source. Cardano is developing a smart contract platform which seeks to deliver more advanced features than any protocol previously developed. It is the first blockchain platform to evolve out of a scientific philosophy and a research-first driven approach.",
      CoinInfo_Atom:
        "In a nutshell, Cosmos bills itself as a project that solves some of the “hardest problems” facing the blockchain industry. It aims to offer an antidote to “slow, expensive, unscalable and environmentally harmful” proof-of-work protocols, like those used by Bitcoin, by offering an ecosystem of connected blockchains. The project’s other goals include making blockchain technology less complex and difficult for developers thanks to a modular framework that demystifies decentralized apps. Last but not least, an Interblockchain Communication protocol makes it easier for blockchain networks to communicate with each other — preventing fragmentation in the industry. Cosmos’ origins can be dated back to 2014, when Tendermint, a core contributor to the network, was founded. In 2016, a white paper for Cosmos was published — and a token sale was held the following year. ATOM tokens are earned through a hybrid proof-of-stake algorithm, and they help to keep the Cosmos Hub, the project’s flagship blockchain, secure. This cryptocurrency also has a role in the network’s governance.",
      CoinInfo_AVAX:
        "Avalanche is a layer one blockchain that functions as a platform for decentralized applications and custom blockchain networks. It is one of Ethereum’s rivals, aiming to unseat Ethereum as the most popular blockchain for smart contracts. It aims to do so by having a higher transaction output of up to 6,500 transactions per second while not compromising scalability. This is made possible by Avalanche’s unique architecture. The Avalanche network consists of three individual blockchains: the X-Chain, C-Chain and P-Chain. Each chain has a distinct purpose, which is radically different from the approach Bitcoin and Ethereum use, namely having all nodes validate all transactions. Avalanche blockchains even use different consensus mechanisms based on their use cases. After its mainnet launch in 2020, Avalanche has worked on developing its own ecosystem of DApps and DeFi. Different Ethereum-based projects such as SushiSwap and TrueUSD have integrated with Avalanche. Furthermore, the platform is constantly working on improving interoperability between its own ecosystem and Ethereum, like through the development of bridges.",
      CoinInfo_BNB:
        "From the ICO, Binance issued its own coin called the Binance Coin, with BNB as the symbol. BNB runs natively on Binance chain. The coin was established with a total supply of 200 million. Every quarter, we will destroy BNB based on the trading volume on our crypto-to-crypto platform until we destroy 50% of all the BNB. All transactions will be on the blockchain. We eventually will destroy 100MM BNB, leaving 100MM BNB remaining. The BNB coin itself has multiple forms of utility, essentially being the underlying gas that powers the Binance Ecosystem. The current most prominent use cases include using BNB to: - Pay for trading fees on the exchange, obtaining the equivalent of a 50% discount on trades (during the first year). - Crypto.com has included support for Binance’s BNB token to its platform which includes the MCO Visa Card and mobile app.",
      CoinInfo_BTC:
        "Bitcoin is a cryptocurrency and a digital payment system invented by an unknown programmer, or a group of programmers, under the name Satoshi Nakamoto.It was released as open-source software in 2009.The system is peer-to-peer, and transactions take place between users directly, without an intermediary. These transactions are verified by network nodes and recorded in a public distributed ledger called a blockchain. Since the system works without a central repository or single administrator, bitcoin is called the first decentralized digital currency.Besides being created as a reward for mining, bitcoin can be exchanged for other currencies, products, and services in legal or black markets.As of February 2015, over 100,000 merchants and vendors accepted bitcoin as payment. According to research produced by Cambridge University in 2017, there are 2.9 to 5.8 million unique users using a cryptocurrency wallet, most of them using bitcoin.",
      CoinInfo_Cake:
        "PancakeSwap, built on Binance Smart Chain (BSC), is a decentralized exchange for swapping BEP-20 tokens. It is fast, cheap, and allows anyone to participate. Users can get FLIP (LP token) for staking and get CAKE token as reward. CAKE holders can swap CAKE for SYRUP for additional incentivized staking. PancakeSwap uses an automated market maker (AMM) model. It means that while you trade digital assets on the platform, there isn’t an order book where you’re matched with someone else. Instead, you trade against a liquidity pool. Traders deposit funds into the pool, receiving liquidity provider (or LP) tokens in return. They can use those tokens to reclaim their share, plus a portion of the trading fees.",
      CoinInfo_Doge:
        "Dogecoin is a cryptocurrency focused on actual utility as a currency. We provide fast block times and very low fees which make Dogecoin suitable for usage in micro-transactions but also as payment option for online shops. Dogecoin has been adopted as such by online retailers and can be used easily as means of consumer to consumer money transfer too.",
      CoinInfo_Dot:
        'Polkadot is a global well-known blockchain cross-chain project, which is created by Ethereum co-founder and Ethereum yellow book author Gavin Wood. It aims to solve the communication and data transmission obstacles between different blockchains and was rated as one of the "4 major projects that decide the direction of digital currency development in 2019".',
      CoinInfo_ETC:
        "Ethereum classNameic (ETC) is a hard fork of Ethereum (ETH) that launched in July 2016. Its main function is as a smart contract network, with the ability to host and support decentralized applications (DApps). Its native token is ETC. Since its launch, Ethereum classNameic has sought to differentiate itself from Ethereum, with the two networks’ technical roadmap diverging further and further from each other with time. Ethereum classNameic first set out to preserve the integrity of the existing Ethereum blockchain after a major hacking event led to the theft of 3.6 million ETH.",
      CoinInfo_ETH:
        'Ethereum (ETH) is an open-source, public, blockchain-based distributed computing platform featuring smart contract (scripting) functionality, which facilitates online contractual agreements. Ethereum also provides a cryptocurrency token called "ether", which can be transferred between accounts and used to compensate participant nodes for computations performed. "Gas", an internal transaction pricing mechanism, is used to mitigate spamand allocate resources on the network.The value token of the Ethereum blockchain is called ether. It is listed under the diminutive ETH and traded on cryptocurrency exchanges. It is also used to pay for transaction fees and computational services on the Ethereum network.',
      CoinInfo_Fil:
        "The InterPlanetary File system (IPFS) is a global, peer-to-peer distributed version of the file system that aims to supplement (or even replace) the hypertext transfer protocol (HTTP) that currently dominates the internet, by connecting all computing devices with the same file system. The principle is to replace the address based on the domain name with a content-based address, that is, the user is looking for content that is not an address but stored in a certain place. There is no need to verify the identity of the sender, but only the hash of the content. It can ensure the web to be faster, safer, more robust and more durable.Filecoin is an incentive layer on IPFS and a a decentralized storage market built on IPFS based on the token incentive model.FIL6 is the Filecoin contract issued 6 months after its launch",
      CoinInfo_GBR:
        "Gabur (GBR) is a utility token for the Easy Exchange Pro platform. The Gabur is using for the Easy Card, a system to buy crypto without cash, and also Easy Guarantee which is a system to guarantee payment between companies and their customers.",
      CoinInfo_LTC:
        "Litecoin is a peer-to-peer Internet currency that enables instant, near-zero cost payments to anyone in the world. Litecoin is an open source, global payment network that is fully decentralized without any central authorities. Mathematics secures the network and empowers individuals to control their own finances. Litecoin features faster transaction confirmation times and improved storage efficiency than the leading math-based currency. With substantial industry support, trade volume and liquidity, Litecoin is a proven medium of commerce complementary to Bitcoin",
      CoinInfo_Matic:
        "Matic Network is a layer-2 scaling solution with side-chains for off-chain computation, while ensuring asset security using the Plasma framework and a decentralized network of Proof-of-Stake (PoS) validators. It aims to offer scalable and instant blockchain transactions. By utilizing adapted version of the Plasma framework, the system is built on PoS checkpoints that are pushed to the Ethereum main-chain. As a result, it is expected to allow each Matic sidechain to theoretically achieve 2^16 transactions per block. Matic Network has been a significant contributor to the Ethereum ecosystem, having worked on implementations of Plasma MVP (Minimum Viable Plasma), the WalletConnect protocol, and Dagger, a popular Ethereum event notification engine. Many projects have already been building applications on and integrating with Matic. MATIC is an ERC-20 token running on Ethereum, it used to pay for services on the Matic Network but also serves as a settlement currency between users who operate within the Matic ecosystem.",
      CoinInfo_MKR:
        "Maker (MKR) is the governance token of the MakerDAO and Maker Protocol — respectively a decentralized organization and a software platform, both based on the Ethereum blockchain — that allows users to issue and manage the DAI stablecoin. Initially conceived in 2015 and fully launched in December 2017, Maker is a project whose task is to operate DAI, a community-managed decentralized cryptocurrency with a stable value soft-pegged to the US dollar. MKR tokens act as a kind of voting share for the organization that manages DAI; while they do not pay dividends to their holders, they do give the holders voting rights over the development of Maker Protocol and are expected to appreciate in value in accordance with the success of DAI itself. The Maker ecosystem is one of the earliest projects on the decentralized finance (DeFi) scene: the industry that seeks to build decentralized financial products on top of smart-contract-enabled blockchains, such as Ethereum.",
      CoinInfo_Near:
        "NEAR Protocol is a layer-one blockchain that was designed as a community-run cloud computing platform and that eliminates some of the limitations that have been bogging competing blockchains, such as low transaction speeds, low throughput and poor interoperability. This provides the ideal environment for DApps and creates a developer and user-friendly platform. For instance, NEAR uses human-readable account names, unlike the cryptographic wallet addresses common to Ethereum. NEAR also introduces unique solutions to scaling problems and has its own consensus mechanism called “Doomslug.” NEAR Protocol is being built by the NEAR Collective, its community that is updating the initial code and releasing updates to the ecosystem. Its declared goal is to build a platform that is “secure enough to manage high value assets like money or identity and performant enough to make them useful for everyday people.” Flux, a protocol that allows developers to create markets based on assets, commodities, real-world events, and Mintbase, an NFT minting platform are examples of projects being built on NEAR Protocol.",
      CoinInfo_TRX:
        "Tron is one of the largest blockchain based operating systems in the world. TRON is an ambitious project dedicated to building the infrastructure for a truly decentralized Internet. The Tron Protocol, one of the largest blockchain based operating systems in the world, offers scalable, high-availability and high-throughput support that underlies all the decentralized applications in the TRON ecosystem. TRON Protocol and the TVM allow anyone to develop DAPPs for themselves or their communities, with smart contracts making decentralized crowdfunding and token issuance easier than ever. Tron DAPP projects already include Peiwo, Obike, Uplive, game.com, Kitty live and Mico, with 100M+ active users from more than 100 countries and regions around the world.",
      CoinInfo_XLM:
        "Stellar network is a free, open-source network that connects diverse financial systems and lets anyone build low-cost financial services—payments, savings, loans, insurance—for their community. It is supported by Stellar.org, a Silicon Valley based non-profit organization. The Stellar network enables money to move directly between people, companies and financial institutions as easily as email. This inter-connectivity means more access for individuals, lower costs for banks, and more revenue for businesses. Token use: Stellar is an open-source, decentralized protocol for digital currency to fiat currency transfers which allows cross-border transactions between any pair of currencies.",
      CoinInfo_XRP:
        "Ripple (XRP) is an independent digital asset that is native to the Ripple Consensus Ledger. With proven governance and the fastest transaction confirmation of its kind, XRP is said to be the most efficient settlement option for financial institutions and liquidity providers seeking global reach, accessibility and fast settlement finality for interbank flows.",
      CoinInfo_ZEC:
        "NZcash is a decentralized cryptocurrency focused on privacy and anonymity. It uses the zk-SNARK zero-knowledge proof technology that allows nodes on the network to verify transactions without revealing any sensitive information about those transactions. Contrary to a common misunderstanding, the majority of cryptocurrencies on the market, including Bitcoin (BTC), are not anonymous, but rather pseudonymous; while they do not explicitly reveal the identities of their users, each user has their own public address or addresses which can be traced back to them via the methods of data science and blockchain forensics. Zcash transactions, on the other hand, still have to be relayed via a public blockchain, but unlike pseudonymous cryptocurrencies, ZEC transactions by default do not reveal the sending and receiving addresses or the amount being sent. There is an option, however, to reveal this data for the purposes of auditing or regulatory compliance. Zcash was first released on October 28, 2016, and it was originally based on Bitcoin’s codebase.",
      // Easy Card
      //// Where To Buy
      ///// Top
      EC_WT_Title: "Buy Easy Card",
      EC_WT_Description:
        "You can buy Easy Card from one of our Broker near you and charge your account without any visa or Credit Card",
      EC_WT_Find_Title: "Find a Broker near you",
      EC_WT_Find_btn: "Find",
      EC_WT_Find_input: "Enter your city",
      ///// Apply
      EC_WA_title:
        "Apply to get easy card license and become one of our partners and make a benefit.",
      EC_WA_Decription: "Apply",
      ///// Start
      EC_WS_Question: "HOW DOES EASY CARD WORK?",
      EC_WS_title: "Get started in 3 easy steps",
      EC_WS_SubTitle: "All the process in less than 3 minutes.",
      EC_WS_Feature_1_description:
        "Find a broker near you and buy the Easy Card",
      EC_WS_Feature_2_description:
        "Enter your Easy Card code to deposit your Gabur Tokens in your wallet",
      EC_WS_Feature_3_description: "Now, you can start trading",
      //// Already Purchased
      EC_AP_description:
        "Enter your code here, Gabur tokens will be deposited into your wallet, automatically.",
      EC_AP_Code: "Enter your code",
      EC_AP_Code_Error: "Please enter 20 digits code.",
      //// Broker
      ///// Register
      EC_BR_title: "Register as a Broker",
      ///// Form
      EC_BR_Exchange_info: "Exchange Information",
      EC_BR_Legal: "Legal name",
      EC_BR_Legal_Error: "Please enterregisteredcountry Legal name.",
      EC_BR_RegCountry: "Registered country",
      EC_BR_RegCountry_Error: "Please enter Registered country.",
      EC_BR_RegNum: "Registration number",
      EC_BR_RegNum_Error: "Please enter Registration number.",
      EC_BR_MonTxVal: "Monthly transaction value",
      EC_BR_MonTxVal_Error: "Please enter Monthly transaction value.",
      EC_BR_ECMonDis: "Easy Card Monthly Distribution",
      EC_BR_ECMonDis_Error: "Please enter Easy Card Monthly Distribution.",
      EC_BR_Website: "Website (If you have)",
      EC_BR_Submitter_info: "Submitter Information",
      EC_BR_Job: "Job Title",
      EC_BR_Job_Error: "Please enter Job Title.",
      EC_BR_Birth: "Date Of Birth",
      EC_BR_Birth_Error: "Please enter Date Of Birth.",
      EC_BR_Country: "Country Of Residence",
      EC_BR_Country_Error: "Please enter Country Of Residence.",
      EC_BR_Citizen: "Country Of Citizenship",
      EC_BR_Citizen_Error: "Please enter Country Of Citizenship.",
      EC_BR_Upload_title: "Please Upload Exchange & Personal Documents:",
      EC_BR_Exchange_Doc: "Exchange Registration Document",
      EC_BR_Exchange_Doc_Error: "Please upload Exchange Registration Document.",
      EC_BR_Peronal_ID_Doc: "Personal ID Document",
      EC_BR_Peronal_ID_Doc_Error: "Please upload Personal ID Document.",
      // Easy Guarantee
      //// Easy Guarantee
      ////// top
      EG_EGTop_title: "Easy Guarantee",
      EG_EGTop_Description_1: "The only digital crypto guarantee",
      EG_EGTop_Description_2: "For the first time ever in the world",
      ////// Text
      EG_EGText_title: "What is Easy Guarantee?",
      EG_EGText_Description_1:
        "The Easy guarantee is a type of USDT backstop offered by Easyexchangepro, where the lender will ensure that the liabilities of a debtor will be met. In other words, if the debtor fails to settle a debt, Easyexchangepro will cover it.",
      EG_EGText_Description_2:
        "An Easy Guarantee enables the applicant to acquire goods, buy equipment, draw down a loan, or against a down payment, etc",
      EG_EGText_Feature_1_description:
        "You will be able to issue an Easy Guarantee for your business purposes in 5 minutes.",
      EG_EGText_Feature_2_title: "Reliable",
      EG_EGText_Feature_2_description:
        "Easy Guarantee is not just a payment commitment document; Rather, USDT will be deposited into the beneficiarys account as the most liquid digital currency.",
      EG_EGText_Feature_3_description:
        "The issued Easy Guarantee is 100% safe & secured for the beneficiary and can be converted or withdrawn at maturity.",
      ////// Steps
      EG_EGSteps_title: "You can issue Easy Guarantee in 4 easy steps:",
      EG_EGSteps_Feature_1_title: "Step 1",
      EG_EGSteps_Feature_1_description_1: "/",
      EG_EGSteps_Feature_1_description_2: "In our platform",
      EG_EGSteps_Feature_2_title: "Step 2",
      EG_EGSteps_Feature_2_description_1: "Fill out the application",
      EG_EGSteps_Feature_2_description_2: "form and submit",
      EG_EGSteps_Feature_3_title: "Step 3",
      EG_EGSteps_Feature_3_description_1: "Transfer amount of",
      EG_EGSteps_Feature_3_description_2: "Easy Guarantee with USDT",
      EG_EGSteps_Feature_4_title: "Step 4",
      EG_EGSteps_Feature_4_description_1: "The guarantee has been issued and",
      EG_EGSteps_Feature_4_description_2: "the USDT was deposited & locked in",
      EG_EGSteps_Feature_4_description_3: "the beneficiary’s account",
      ////// Bot
      EG_EGBot_title: "For Companies & Individuals",
      EG_EGBot_description:
        "Companies and individuals as our users are able to issue a Guarantee in small or large amounts for their business purposes in favor of the beneficiary.",
      //// FAQ
      EG_EGFaq_Q1: "What is the Easy Guarantee?",
      EG_EGFaq_A1:
        "Easy Guarantee is a type of guarantee with cryptocurrency (USDT) backstop, based on that the borrowers/buyers guarantee that their debts to the creditor will be fulfilled.",
      EG_EGFaq_Q2: "Who is the applicant?",
      EG_EGFaq_A2:
        "The applicant is the party who will request to issue the Easy Guarantee.",
      EG_EGFaq_Q3: "Which currency is acceptable for issuing a guarantee?",
      EG_EGFaq_A3: "Only USDT.",
      EG_EGFaq_Q4: "Is any USDT wallet address acceptable?",
      EG_EGFaq_A4:
        "No, only applicant's and beneficiary's USDT wallet address are accepted in Easy Exchange Pro.",
      EG_EGFaq_Q5: "Who is the beneficiary?",
      EG_EGFaq_A5: "The party in whose favor the guarantee was issued.",
      EG_EGFaq_Q6: "What is the conditional Easy guarantee?",
      EG_EGFaq_A6:
        "The request to release the guarantee amount by the beneficiary must be approved by the applicant.",
      EG_EGFaq_Q7: "What is the unconditional Easy guarantee?",
      EG_EGFaq_A7:
        "The amount of Easy Guarantee will be released on the beneficiary's first demand.",
      EG_EGFaq_Q8: "How can users apply?",
      EG_EGFaq_A8: "Fill out the application form and submit it.",
      EG_EGFaq_Q9: "What will come after submitting the form?",
      EG_EGFaq_A9:
        "Applicant will receive a first email to confirm the request, then a second email containing the USDT wallet address to transfer the Easy Guarantee amount.",
      EG_EGFaq_Q10: "How much can the user issue an Easy guarantee?",
      EG_EGFaq_A10:
        "User can issue an easy guarantee of as much as USDT balance in his/her account.",
      EG_EGFaq_Q11:
        "Does the applicant need to have USDT balance to request and issue Easy Guarantee?",
      EG_EGFaq_A11:
        "Yes, the users can request to issue an Easy Guarantee of as much as USDT balance in their account.",
      EG_EGFaq_Q12: "When will be issued Easy Guarantee?",
      EG_EGFaq_A12:
        "Upon the applicant’s transfer of the USDT, the Easy Guarantee will issue immediately and the amount of USDT will be blocked in the beneficiary's account based on the validity period.",
      EG_EGFaq_Q13:
        "How will any dispute regarding guarantee release be resolved between the applicant and the beneficiary?",
      EG_EGFaq_A13:
        "In case of any dispute, any of the parties can send their objection to easyguarantee@easyexchangepro.com and our legal department will decide within one month after reviewing the documents of the parties.",
      EG_EGFaq_Q14:
        "Do the applicant and the beneficiary both have to be users of the Easyexchangepro platform?",
      EG_EGFaq_A14: "Yes, it must.",
      EG_EGFaq_Q15:
        "When can the beneficiary request to release the Easy Guarantees amount?",
      EG_EGFaq_A15:
        "The beneficiary can request to release the amount of Easy Guarantee in his/her account during the validity period of the Easy Guarantee.",
      EG_EGFaq_Q16:
        "If the beneficiary does not request to release the amount of Guarantee during the validity period of the Easy Guarantee, what will occur?",
      EG_EGFaq_A16:
        "The amount of the Easy Guarantee will be credited to the applicant’s account automatically.",
      EG_EGFaq_Q17: "How long does it take to issue an easy guarantee?",
      EG_EGFaq_A17: "Less than 5 minutes.",
      EG_EGFaq_Q18: "How much does it cost to issue an easy guarantee?",
      EG_EGFaq_A18: "0.4% of the easy guarantee’s amount.",
      EG_EGFaq_Q19: "Who is to be in charge to pay the fee?",
      EG_EGFaq_A19: "The applicant.",
      EG_EGFaq_Application_form: "Application Form",
      //// Application Form
      EG_AF_title: "Easy Guarantee application form",
      EG_AF_description:
        "Note: Please read the Easy Guarantee Questions section carefully before filling out the application form.",
      EG_AP_Applicant: "Applicant:",
      EG_AP_SDate: "Start Date",
      EG_AP_SDate_Error: "Please enter Start Date.",
      EG_AP_EDate: "End Date",
      EG_AP_EDate_Error: "Please enter End Date.",
      EG_AP_WalletApp: "USDT Wallet Address Easy Exchange Pro",
      EG_AP_WalletApp_Error: "Please enter USDT Wallet Address.",
      EG_AP_Amount: "USDT Amount",
      EG_AP_Amount_Error: "Please enter USDT Amount.",
      EG_AP_VPD: "Validity Period Days",
      EG_AP_VPD_Error: "Please enter Validity Period Days.",
      EG_AP_Select_Conditional: "Conditional",
      EG_AP_Select_Unconditional: "Unconditional",
      EG_AP_Beneficiary: "Beneficiary:",
      EG_AP_BenUID: "Beneficiary User ID",
      EG_AP_BenUID_Error: "Please enter Beneficiary User ID.",
      EG_AP_BenEmail: "Beneficiary Email Address",
      EG_AP_BenEmail_Error: "Please enter Beneficiary Email Address.",
      EG_AP_WalletBen: "Beneficiary USDT wallet address in Easy Exchange Pro",
      EG_AP_WalletBen_Error: "Please enter Beneficiary USDT wallet.",
      // Fee
      Fee_title_1: "Maker orders",
      Fee_description_1:
        "Maker orders create (make) liquidity on a market by being entered onto the order book. In other words, maker orders are not filled when they are placed, but instead wait until a future order is placed that matches it. A maker order can be on either a buy or sell order. When an existing order on the order book is matched with a newly placed order (the taker), the maker order in the transaction is charged the maker fee. If a newly entered order does not at once match with any existing orders or is not entirely filled by existing orders, the unfilled order gets placed in the order book and becomes a maker order for a future trade.",
      Fee_title_2: "Taker orders",
      Fee_description_2:
        "Taker orders reduce (take away) liquidity on a market. Taker orders execute at once and take volume off the order book. A taker order can be on either a buy or sell order. When a new order is placed and it matches against another order already on the order book (the maker), the taker in the transaction is charged the taker fee.",
      Fee_title_3: "Transaction fees",
      Fee_subtitle_3_1: "Blockchain:",
      Fee_description_3_1:
        "Specific blockchain network fees may be incurred when a user moves tokens on the blockchain to and from Easy Exchange Pro.",
      Fee_description_3_2:
        "Easy Exchange Pro does not charge fees for deposits. Please be aware that some tokens or coins require us to move your funds to another address before being credited. This means the coin or token’s network will charge you the normal transaction fee for the fund transfer, which Easy Exchange Pro cannot avoid.",
      Fee_description_3_3:
        "Easy Exchange Pro charges a small fee on withdrawals for a token or coin with a built-in network transfer fee. The network fee is view-able by clicking the withdraw button on the withdrawal window for each token or coin with a built-in network transfer fee. The network fee is view-able by clicking the withdraw button on the withdrawal window for each token or coin.",
      Fee_table_1_col_1: "Level",
      Fee_table_1_col_2: "30Day Trade Volume (BTC)",
      Fee_table_1_col_3: "Maker",
      Fee_table_1_col_4: "Taker",
      Fee_table_2_col_1: "Currency Symbol",
      Fee_table_2_col_2: "Currency name",
      // Gabur
      //// Top
      GBR_top_title_1: "EASY EXCHANGE PRO",
      GBR_top_title_3: "The evolution of finance",
      //// Second
      GBR_Second_title_1: "SECURITY SMART CONTRACT",
      GBR_Second_title_2: "Gabur Token Audit",
      GBR_Second_description:
        "A smart contract consists of special instructions stored in the blockchain. This contract can perform several actions according to a series of pre-programmed parameters within an unchangeable, transparent, and completely safe way. Therefore, the GABUR Tokens Smart Contract has been audited to ensure the highest levels of security.",
      GBR_Second_Security_btn: "Security Audit",
      //// Description
      GBR_Description_title_1: "Gabur Tokens Description",
      GBR_Description_list_1_1: "Utility Token name: GABUR",
      GBR_Description_list_1_2: "Symbol: GBR",
      GBR_Description_list_1_3: "GABUR Tokens Total Supply: 550M",
      GBR_Description_list_1_4: "GABUR Tokens are not subject to an increase",
      GBR_Description_list_1_5:
        "GABUR Tokens will run natively on the BEP20 network following the BSC standard.",
      GBR_Description_list_1_6: "Token burn: 165M GABUR Tokens will be burned",
      GBR_Description_title_2: "Gabur Tokens Distribution",
      GBR_Description_list_2_1: "Public sale",
      GBR_Description_list_2_2: "Easy card reserve",
      GBR_Description_list_2_3: "Team",
      GBR_Description_list_2_4: "Advisors",
      GBR_Description_list_2_5: "Private sale",
      GBR_Description_list_2_6: "Airdrop",
      //// Contract
      GBR_Contract_subtitle: "Gabur token contract address",
      //// Added
      GBR_Added_subtitle: "Gabur Token Plan",
      GBR_Added_title: "What is the added value of Gabor Token?",
      GBR_Added_list_1:
        "Development of Easy Exchange pro according to our roadmap commitment",
      GBR_Added_list_2:
        "Creating innovative ideas on the development of Easy Card, which shall enable it to become a significant feature to be used in transactions",
      GBR_Added_list_3: "GABUR Tokens are not subject to an increase",
      GBR_Added_list_4:
        "Innovative development aiming to make digital assets more practical",
      GBR_Added_list_5: "Burning GABUR Tokens quarterly",
      GBR_Added_list_6: "The lock-up period for tokens",
      GBR_Added_list_7:
        "Ability to welcome users looking for a stable and secure way to settle payments in more than 60 countries",
      GBR_Added_list_8:
        "Having an experienced team in both, the field of cryptocurrencies and the financial field, with extensive international financial connections",
      GBR_Added_list_9:
        "We will add new and unique products according to our roadmap",
      // Login
      Login_title: "Welcome to Easy Pro",
      Login_subtitle: "Login to Continue",
      Login_btn_Loading: "Login",
      Login_problem: "Login problem?",
      Login_click_here: "Click here",
      Login_register: "Click here to Register",
      Login_protected: "This site is protected by reCAPTCHA and the Google",
      Login_privacy: "Privacy Policy",
      Login_and: "and",
      Login_terms: "Terms of Service",
      Login_ResetModal_title: "You can reset your 2FA or Password",
      Login_ResetModal_Reset2FA_btn: "Reset 2FA",
      Login_ResetModal_ForgetPassword: "Forgot Password?",
      Login_ResetPassword_title:
        "Enter your email to receive password reset link",
      Login_Reset2FA_title: "Enter your email to receive 2FA reset link",
      Login_OTP_title: "Enter received OTP",
      //// Reset Password
      ResetPassword_title: "Reset Your Password",
      ResetPassword_NewPassword_input: "New Password",
      ResetPassword_ConfirmPassword_input: "Confirm New Password",
      // Privacy And Policy
      Privacy_1_title: "INTRODUCTION",
      Privacy_1_desciption:
        "Thanks indeed for visiting Easyexchangepro.com, as a digital asset trading website. The purpose of this Privacy Policy is to inform you (user, customer) with an understanding that how Easyexchangepro (or we, or our, or us or platform) collect personal information from our clients and prospective clients to provide them with our products and services and ensure that we can meet their needs when providing these products and services, as well as when providing them with any respective information. This privacy policy applies to the Site and all Services offered by Easyexchangepro.",
      Privacy_2_title: "PERSONALLY IDENTIFIABLE",
      Privacy_2_desciption:
        "We may collect personally identifiable information from Users in a variety of ways, including, but not limited to, when Users visit our Site, register on the Site, place an order, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Site anonymously. When interacting with us on the Site, Users can always refuse to supply personal data to us, except that it may prevent them from engaging in certain Site related activities or transactions. We may collect non-personally identifiable information about Users when they interact with our Site.",
      Privacy_3_title: "WHAT INFORMATION DO WE COLLECT?",
      Privacy_3_desciption_1:
        "Easyexchangepro collects information about you when you use our websites (including the Site) and other online products and services and throughout other interactions and services, you have with us. We will collect your personal data in accordance with the legislative purpose of the DPA. We may also collect and store certain information automatically when you visit the Site. Examples include the internet protocol (IP) address used to connect your computer or device to the internet, connection information such as browser type and version, your operating system and platform, a unique reference number linked to the data you enter on our system, log in details, the full URL clickstream to, through and from the Site (including date and time), cookie number and your activity on our Site, including the pages you visited, the searches you made and, if relevant, the products/services you purchased.",
      Privacy_3_desciption_2:
        "We may receive information about you from third parties if you use any websites or social media platforms operated by third parties (for example, Facebook, Instagram, Twitter, etc.) and, if such functionality is available, you have chosen to link your profile on the Site with your profile on those other websites or social media platforms.",
      Privacy_4_title: "INDIVIDUAL AND COMPANY WHICH WE MAY COLLECT INCLUDE:",
      Privacy_4_subtitle_1: "For individual:",
      Privacy_4_list_1_1: "• Email address",
      Privacy_4_list_1_2: "• Mobile phone number",
      Privacy_4_list_1_3:
        "• Full legal name (including former name, and names in local language)",
      Privacy_4_list_1_4: "• Nationality",
      Privacy_4_list_1_5:
        "• Passport number, or any government-issued ID number",
      Privacy_4_list_1_6: "• Date of birth",
      Privacy_4_list_1_7:
        "• Proof of identity (e.g., passport, driver’s license, or government-issued ID)",
      Privacy_4_list_1_8: "• Residential address",
      Privacy_4_list_1_9: "• Proof of residency",
      Privacy_4_list_1_10:
        "• Additional Personal Data or documentation at the discretion of our Compliance Team",
      Privacy_4_subtitle_2: "For companies:",
      Privacy_4_list_2_1:
        "• Company legal name (including the legal name in local language)",
      Privacy_4_list_2_2: "• Incorporation/registration Information",
      Privacy_4_list_2_3:
        "• The full legal name of all beneficial owners, directors, and legal representatives",
      Privacy_4_list_2_4:
        "• Address (principal place of business and/or other physical locations)",
      Privacy_4_list_2_5: "• Proof of legal existence",
      Privacy_4_list_2_6: "• Description of the business",
      Privacy_4_list_2_7:
        "• Percentage of ownership for Individual/company owners",
      Privacy_4_list_2_8:
        "• Contact information of owners, principals, and executive management (as applicable)",
      Privacy_4_list_2_9:
        "• Proof of identity (e.g., passport, driver’s license, or government-issued ID) for the significant individual beneficial owner of the institutional customer entity",
      Privacy_4_list_2_10:
        "• Personal Data for each entity’s significant beneficial owner of the institutional customer entity (see the “Individual Customer” section above for details on what Personal Data we collect for individuals)",
      Privacy_4_list_2_11: "• Source of wealth",
      Privacy_4_list_2_12:
        "• Amount of bitcoin or other digital assets projected to be injected",
      Privacy_5_title: "COOKIES USAGE",
      Privacy_5_desciption:
        "When you access our Sites and Services, we or companies we work with may place cookies on your computer or another device. Of course, you can block or deactivate cookies in your browser settings. But these technologies help us better understand user behavior and inform us about which parts of our websites people have visited. We use first-party and third-party cookies to recognize you as an Easyexchangepro user, customize Coinbase Services, content, and advertising, measure promotional effectiveness, and collect information about your computer or another access device to mitigate risk, helping prevent fraud, and promote trust and safety. We may place cookies from third-party service providers who may use information about your visits to other websites to target advertisements for products and services available from Easyexchangepro. We do not control the types of information collected and stored by these third-party cookies. You should check the third-party's website for more information on how they use cookies.",
      Privacy_6_title: "LAWFUL BASIS FOR PROCESSING YOUR PERSONAL INFORMATION",
      Privacy_6_desciption_1:
        "We will process your personal information on the following bases and for the following purposes:",
      Privacy_6_desciption_2:
        "carrying out your instructions or responding to any inquiry given by (or purported to be given by) you or on your behalf including responding to your inquiries and complaints, or responding to or dealing with your interactions with us; registering you as a customer of Easyexchangepro and/or to deal with, process and/or administer the account that you may open with us, whether a membership account or otherwise, including to facilitate your transactions or activities on the Site or your transactions or activities with us; administering, facilitating, processing and/or dealing with your relationship with us, any transactions or activities carried out by you on the Site or with us. This includes processing your application, orders, and payment transactions; implementing transactions and the supply of products and/or services to you that you have requested; administering, facilitating, processing, and/or dealing in any matters relating to your use or access of the Site, including identifying you for login to the Site, our portals and other online services provided by or on behalf of us; monitoring, processing and/or tracking your use of the Site in order to provide you with a seamless experience, facilitating or administering your use of the Site, and/or to assist us in improving your experience in using the Site; assessing and processing your request for the purchase of and/or subscription to our products and/or services. contacting you or communicating with you via phone/voice call, text message and/or fax message, email and/or postal mail for the purposes of administering and/or managing your use of the Site, your membership and/or account with us, your relationship with us or any transactions made by you with us.",
      Privacy_7_title: "HOW WE USE YOUR PERSONAL DATA",
      Privacy_7_desciption_1:
        "Easyexchangepro uses Personal Data to communicate with you and to administer, deliver, improve, and personalize the Service. Easyexchangepro might also generate generic data out of any Personal Data we collect and use it for our own purposes. We may also use such data to communicate with you in relation to other products or services offered by Easyexchangepro and/or its partners. We do not share your Personal Data with third parties (other than partners in connection with their services to Easyexchangepro) except where you have given your consent and further detailed below.",
      Privacy_7_desciption_2:
        "We may share your Personal Data with third parties:",
      Privacy_7_desciption_3:
        "If we think that sharing it is necessary to enforce the Terms of Service. To comply with government agencies, including regulators, law enforcement, and/or justice departments. To third parties who provide services to Easyexchangepro (such as administration or technical services). In connection with the sale or transfer of our business or any part thereof.",
      Privacy_7_desciption_4:
        "Additionally, we have implemented international standards to prevent money laundering, terrorist financing and will implement final Digital Asset rules and regulations when effective, which will likely require us to undertake due diligence on our customers. This may include the use of third-party data and service providers which we will cross-reference with your personal information.",
      Privacy_8_title: "STORING YOUR PERSONAL DATA",
      Privacy_8_desciption:
        "The data that we collect from you may be transferred to and stored at, a destination outside of the U.A.E. It may also be processed by staff operating outside of U.A.E who work for us or for one of our suppliers. By submitting your personal data, you agree to this transfer, storing, or processing, except for customers located in the EEA, as detailed above. All information you provide to us is stored on our and/or third-party cloud servers",
      Privacy_9_title: "MARKETING COMMUNICATIONS",
      Privacy_9_desciption:
        "We may use your personal information to send you marketing communications by email or other agreed forms (including social media campaigns), to ensure you are always kept up to date with our latest products and services. If we send you marketing communications, we will do so based on your consent and registered marketing preferences. Users can opt out of these marketing communications at any moment. If you do not want to receive these communications, please send an email to support@easyexchangepro.com",
      Privacy_10_title: "DATA SECURITY",
      Privacy_10_desciption:
        "We endeavor to protect Easyexchangepro and you from unauthorized access, alteration, disclosure, or destruction of Personal Data we collect and store. We take various measures to ensure information security, including encryption of the Easyexchangepro communications with SSL; required two-factor authentication for all sessions; periodic review of our Personal Data collection, storage, and processing practices; and restricted access to your Personal Data on a need-to-know basis for our employees and vendors who are subject to strict contractual confidentiality obligations.",
      Privacy_11_title:
        "TRANSFER OF PERSONAL INFORMATION OUTSIDE OF THE EEA and UK and YOUR COUNTRY",
      Privacy_11_desciption:
        "We may transfer your personal information outside the EEA and UK to other Company subsidiaries, service providers, and business partners (i.e., Data Processors) who are engaged on our behalf. To the extent that we transfer your personal information outside of the EEA and UK, we will ensure that the transfer is lawful and that Data Processors in third countries are obliged to comply with the European Union (EU) General Data Protection Act 2016 and the UK Data Protection Act 2018. Also, by using our products and services, you consent to your Personal Data being transferred to other countries, including countries that have differing levels of privacy and data protection laws than your country. In all such transfers, we will protect your personal information as described in this Privacy Notice and ensure that appropriate information sharing contractual agreements are in place.",
      Privacy_12_title: "COMPLIANT PROCESS",
      Privacy_12_desciption:
        "If you have any complaint or grievance regarding how we are handling your personal data or about how we are complying with the CPT (when in force), we welcome you to contact us with your complaint or grievance and should be addressed to CPT@Easyexchangepro.com Where you are sending an email in which you are submitting a complaint, your indication at the subject header that it is a CPT complaint would assist us in attending to your complaint speedily by passing it on to the relevant staff in our organization to handle. We will certainly strive to deal with any complaint or grievance that you may have speedily and fairly.",
      Privacy_13_title: "YOUR ACCEPTANCE",
      Privacy_13_desciption:
        "By using Easyexchangepro Site, you signify your acceptance of this policy and Terms of Use.",
      Privacy_14_title: "CHANGES TO THIS PRIVACY POLICY",
      Privacy_14_desciption:
        "Any changes we may make to our Privacy Policy in the future will be posted on this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.",
      // Product Convert
      Convert_title: "Convert",
      Convert_for_each: "For each",
      Convert_receive: "you will receive",
      Convert_calc: "Calculate Convert",
      // Product Market Tabs
      Market_tab_Spot: "Spot",
      Market_tab_Favorite: "Favorite",
      // Product OTC
      OTC_title: "Easy Pro OTC desk",
      OTC_description:
        "Traders who want to exchange cryptocurrency-to, especially in large volumes, typically decide to use OTC (over the counter) cryptocurrency trading. The Easy Pro OTC desk will provide you with execution and settlement services that are discreet, secure, and ultra-competitive.",
      OTC_Feature_1:
        "We conduct OTC trades in a confidential manner without significant impact on markets.",
      OTC_Feature_2:
        "Our trading desk respects privacy with all of the safety protocols on Easy pro.",
      OTC_Feature_3:
        "Settlements occur within the Easy pro ecosystem and are generally completed.",
      OTC_Feature_4: "The settlement will be completed within a few minutes",
      OTC_Feature_5: "There’s no 3rd party at our desk",
      OTC_Feature_6:
        "Trade occurs in the Easy pro account then you no need to create a new wallet.",
      OTC_Feature_7:
        "The Coins/Tokens you are trading will simply be moved in and out of your account manually by our team.",
      OTC_Feature_8:
        "Trades details can only be found in the “Distribution History” section on your account.",
      OTC_Support_title: "Support OTC",
      OTC_Support_description:
        "The OTC desk provides global coverage is open 24/7, with the exception of major holidays OTC@Easyexchangepro.com",
      OTC_list_1: "Please fill out the OTC form and send us",
      OTC_list_2: "Our team is available to quote, trade and settle ",
      OTC_list_3:
        "Once you accept our offer the trade is confirmed and our team will send you detail and instruction of trade via email.",
      OTC_list_4:
        "When we receive your end of the deal. OTC desk will proceed to settle our end on your wallet or bank account",
      // Product Trade
      Trade_KYC_Error: "Please upload your KYC",
      Trade_Trading_password_Error: "Please enable Trading Password",
      Trade_Trading_password_title: "Enter your trading password",
      Trade_Trading_password_input: "Trading Password",
      Trade_Trading_Password_btn: "Enter Trading Password",
      Trade_goto_security: "Go to Security",
      Trade_FAV: "FAV",
      Trade_Open_Orders: "Open orders",
      Trade_Order_history: "Order history",
      Trade_Trade_history: "Trade history",
      Trade_Date_Time: "Date & Time",
      Trade_Type: "Type",
      Trade_Action: "Action",
      Trade_Login_Register: "Login/Register",
      Trade_Password_Error: "Enter your password please.",
      Trade_Balance_Error: "Insufficient balance, you have",
      Trade_Min_Error: "Order should be higher than 20 USDT",
      Trade_approximately: "You will receive approximately",
      Trade_Market: "Market",
      Trade_Order_List: "Order List",
      Trade_Order_History: "Order History",
      Trade_Pairs: "Pairs",
      Trade_Open_Chart: "Open Chart",
      // Profile
      Profile_profile: "Profile",
      Profile_security: "Security",
      Profile_history: "History",
      Profile_Security_log_history: "Security log history",
      Profile_Login_history: "Login history",
      Profile_User_info_title: "User information",
      Profile_User_info_description:
        "Your unique account information, that you need to keep secure.",
      Profile_UID: "UID",
      Profile_Sec_info_title: "Security Information",
      Profile_Activated: "Activated",
      Profile_Disabled: "Disabled",
      Profile_Trading_Password: "Trading Password",
      Profile_Account_status_title: "Account Status",
      Profile_KYC: "KYC",
      Profile_Sec_level: "Security Level",
      Profile_Airdrop_point: "Airdrop Point",
      Profile_Referrals_title: "Referrals",
      Profile_Num_Referrals: "Number of referrals",
      Profile_Referral_ID: "Referral ID",
      Profile_Referral_List_btn: "Referral List",
      Profile_Commissions_btn: "Commissions",
      Profile_User: "User",
      Profile_KYC_Verified: "Verified",
      Profile_KYC_Pending: "Pending",
      Profile_KYC_Rejected: "Rejected",
      Profile_KYC_NotUploaded: "Not Uploaded",
      Profile_Sec_lvl_Medium: "Medium",
      Profile_Sec_lvl_VeryHigh: "Very High",
      Profile_Account_Password_title: "Account Password",
      Profile_Account_Password_description:
        "You can change your password here.",
      Profile_Change_Password_btn: "Change Password",
      Profile_Change_your_password_title: "Change your password",
      Profile_Current_Password: "Current Password",
      Profile_New_Password: "New Password",
      Profile_Confirm_New_Password: "Confirm New Password",
      Profile_Sec_Authenticaton_title: "Second Factor Authentication",
      Profile_Sec_Authenticaton_description:
        "You can Activate/Deactivate your 2FA here.",
      Profile_Active_2FA: "Activate 2FA",
      Profile_Activating_2FA: "Activating 2fa",
      Profile_Disable: "Disable",
      Profile_Disable_2FA: "Disable 2fa",
      Profile_2FA_Code: "2fa code",
      Profile_Active: "Active",
      Profile_Trading_Password_description:
        "You can change your Trading password here.",
      Profile_Change_trading_password: "Change trading password",
      Profile_Change_your_Trading_password_title:
        "Change your Trading password",
      Profile_Change_your_Trading_password_description:
        "Do you want to change your Trading Password?",
      Profile_forgot_code: "forgot code",
      Profile_KYC_title: "Personal Account KYC",
      Profile_KYC_select_doc: "Select your Document type",
      Profile_KYC_select_doc_ID: "ID card",
      Profile_KYC_select_doc_pass: "Passport",
      Profile_KYC_select_doc_Drive: "Driver lisence with ID number",
      Profile_KYC_Upload_title: "Please Upload Your Documents:",
      Profile_KYC_Front: "Front of Document",
      Profile_KYC_Back: "Back of Document",
      Profile_KYC_Selfie: "Selfie with Document in your hand",
      Profile_KYC_Approved: "Your KYC is already approved.",
      Profile_KYC_Pending: "Your KYC is pending.",
      Profile_Currency: "Currency",
      Profile_Type: "Type",
      Profile_Login: "Login",
      Profile_Region: "Region",
      Profile_Login_device: "Login device",
      // Register
      Reg_verify_email: "Verify email",
      Reg_enter_otp: "Enter OTP",
      Reg_Confirm_Password: "Confirm Password",
      Reg_Referral_Code: "Referral Code (Optional)",
      // Terms Of Use
      Term_1_title: "1-Welcome to Easy Pro",
      Term_1_description_1:
        "Thanks indeed for visiting (Easyexchangepro / we, hereinafter referred to as the Easy pro) By visiting, accessing, or using easyexchangepro.com and associated application program interface or mobile applications (platforms), You (“User” or “You”) consent to the Terms of Service (the “Terms”), so please read them carefully.",
      Term_1_description_2:
        "The Terms constitute the agreement and understanding regarding the use of any or all the Services, and any manner of accessing them, between You and the following service provider (“we,” “us” or “our” platform) Easyexchangepro.",
      Term_2_title: "2-Key Definitions",
      Term_2_description_1:
        "Capitalized terms not otherwise defined in these Terms will have the following meaning:",
      Term_2_description_2:
        "“External Account” means any Digital Token Account: from which you may transfer funds into your Easy Pro  Account, or to which you may transfer funds from your Easy Pro Account and which has been approved by Easy pro for the foregoing purposes.",
      Term_2_description_3: "“Funds” means Digital Tokens.",
      Term_2_description_4: "“Fiat Currency” means any national currency.",
      Term_2_description_5:
        "“Easy Pro Account” means a user account accessible via the Services where Funds may be stored by Easy pro on behalf of a user.",
      Term_2_description_6:
        "“Easy Pro Help Center” means Easy pro’s customer service center with address at Support@easyexchagepro.com -us where you could get online support from Easy pro.",
      Term_2_description_7:
        "“Digital Tokens” means Bitcoins, Ripples, and other digital mediums of exchange that may be purchased, sold, or traded via the Services.",
      Term_2_description_8:
        "“Digital Token Account” means any Digital Token address or account owned or operated by you that is maintained outside of the Services, and is not owned, controlled, or operated by Easyexchangepro.",
      Term_3_title: "3- Accept terms",
      Term_3_description_1:
        "You should read the Terms, and any document referred to in them very carefully. If there is anything that You do not understand in the Terms and any document referred to herein, you should discuss this matter with us and seek the necessary clarification.",
      Term_3_description_2:
        "You are at least 18 years of age and have the full capacity to accept these Terms and enter a transaction involving digital assets. You have not been deprived of the right to use our service and have the full capacity for legal action. If you do not meet the above condition, please do not register our Site or Platform, otherwise, the platforms may suspend or terminate your account at any time.",
      Term_3_description_3:
        "You’re entering and performing these Terms are not prohibited by the laws and regulations of the country or region to which you belong, reside, pay tax or carry out business activities or other business. If you do not meet the above conditions, you should immediately terminate the registration or stop using our Platforms services.",
      Term_3_description_4:
        "Our Platform has the right to modify these Terms from time to time or to formulate and modify various specific rules according to these Terms and publish them in the relevant system sections of Platforms without separately notifying you.",
      Term_3_description_5:
        "You should pay attention to the changes in these Terms and the specific rules from time to time. If you continue to use the Service after the changes in the content of these Terms and the specific rules, you are deemed to have fully read, understood, and accepted the amended Terms and the specific rules and to use the services of our Platforms in accordance therewith.",
      Term_3_description_6:
        "You also agree that Easy Pro may, by giving notice, at its sole discretion immediately terminate your access to our Platform and to your account. You agree and acknowledge our right to limit, suspend or terminate the service and your user account, prohibit your access to our Platform and their content, services, and tools, delay or remove hosted content, and take technical and legal steps to keep you off our Platform or any further steps to recover any loss or damages caused if we determine at our sole discretion that you are or may be in breach of any laws, regulations, the rights of third parties, or any of these Terms or Platform’s policies. Easy Pro shall not be liable for any loss or damage caused to you or any third party because of exercising our rights under this clause.",
      Term_4_title: "4- Our Trading Services",
      Term_4_description:
        "Easy Pro provides an online platform with a simple and convenient way to trade one type of digital asset or another type of digital asset. Easy Pro provides you with a platform that matches your orders with open orders from other users of our Services.",
      Term_4_subheader_1: "Spot.",
      Term_4_subtitle_1: "Market Order:",
      Term_4_description_1:
        "A Market Order is placed to buy or sell a Specified Quantity or a Specific Gross Amount of Targeted tokens at the best available Latest Market Price.",
      Term_4_subtitle_2: "Limit Order:",
      Term_4_description_2:
        "A Limit Order is placed to buy or sell a Specific Quantity of the Targeted Token at a Specific Price and shall be filled at such Specific Quantity and Specific Price.",
      Term_4_subtitle_3: "Stop Market Order:",
      Term_4_description_3:
        "A Stop Market Order is placed to buy a Specific Quantity of Targeted Token or to buy a Specific Gross Amount of Targeted Token at the Latest Market Price when the upper limit of the Preset Stop Price is triggered. This means that when the Latest Market Price reach or beyond the upper limit of the Preset Stop Price, this order will be executed and filled at the Latest Market Price which may end up higher than the upper limit of the Preset Stop Price.",
      Term_4_subtitle_4: "Stop Limit Order:",
      Term_4_description_4:
        "A Stop Limit Order is placed to sell a Specific Quantity of Targeted Token at the Specific Price when the inferior limit of Preset Stop Price is triggered. This means that when the Latest Market Price reaches or is below the inferior limit of the Preset Stop Price, this order will be executed and filled at the Specific Quantity as well as the Specific Price.",
      Term_4_subheader_2: "Easy card",
      Term_4_description_5:
        "Refers to transactions in which Gabur tokens are exchanged for fiat currency and deposited in the account of the user.",
      Term_5_title: "5- Scope of Service and Your Rights and Obligations",
      Term_5_description_1:
        "Users shall not register multiple accounts for any purpose. Users may not use another User’s accounts. Users are prohibited in any form from utilizing the Site to engage in illegal activities. Without the authorization or permission of Easy Pro, the User shall not use any of the commercial activities in the name of this Site or in any form of the Site as a place, platform, or medium for engaging in commercial activities.",
      Term_5_description_2:
        "Users shall comply with all laws and regulations and bear the responsibility and legal consequences of any of their own actions involving the Site and Services.",
      Term_5_description_3:
        "Users have the right to view the information regarding their accounts on our Platform and have the right to operate the functions provided by our Platform. The user understands and agrees that our Platform can adjust the service content, service type, and service form on the Platform at any time according to the actual situation at our sole discretion. Our Platform is not responsible for any negative impact or loss caused to you or any third party due to Platform’s adjustments.",
      Term_5_description_4:
        "Depending on your country of residence, you may not be able to use all the functions of the Site or the Platform. Without prejudice to our right of suspending certain services to users from a specific country, it is your responsibility to ensure that in accessing our Site, Platform, and services, you are complying with the laws and regulations in your country of residence and/or the country from which you access this Site, the Platform, and our services. you are responsible for any disputes or losses caused by your failure to promptly modify or confirm the status of the transaction or failure to submit the relevant application. Our Platforms do not assume any responsibility.",
      Term_5_description_5:
        "Engaging in trades in cryptographic tokens may be highly risky. Please do not use Easy Pro’s services for trading in Digital assets if you do not understand these risks.",
      Term_6_title: "6- Registration Process",
      Term_6_description_1:
        "You agree to provide a valid email address, a mobile phone number, and other information in accordance with the requirements on the user registration page of this platform. You can use the email address, mobile phone number or any other manner permitted to log in to this platform. Where it is necessary and in accordance with the requirements of applicable laws and regulations of relevant jurisdictions, you shall provide your real name, identity card, and other information required by applicable laws, regulations, the Terms of Privacy, and anti-money-laundering terms, and constantly update your registration data so that they will be timely, detailed, and accurate as is required. All the original typed data will be referenced as registration information. You shall be responsible for the authenticity, integrity, and accuracy of such information and bear any direct or indirect loss and adverse consequences arising out of it.",
      Term_6_description_2:
        "If any of the applicable laws, regulations, rules, orders, and other regulatory documents of the sovereign country or region in which you are based requires that mobile phone accounts must be based on real names, you hereby confirm that the mobile phone number you provide for registration purposes has gone through the real-name registration procedure. If you cannot provide such a mobile phone number as is required, any direct or indirect losses and adverse consequences arising therefrom and affecting you shall be borne by you.",
      Term_6_description_3:
        "After you provide the required registration information in a legal, complete, and valid manner and such information passes relevant verification, you shall have the right to obtain an account and a password of Easy exchange pro. Upon obtaining such an account and password, your registration shall be deemed as successful, and you can log into the platform as a member thereof. You agree to receive emails and/or short messages sent by the platform related to the management and operation thereof.",
      Term_6_description_4:
        "Particularly during periods of high volume, illiquidity, fast movement, or volatility in the marketplace for any, or one or more Digital Tokens, the actual market rate at which a trade is executed may be different from the prevailing rate indicated via the Services at the time of your order or trade. You understand that we are not liable for any such price fluctuations. In the event of a market disruption or Force Majeure event, Easy exchange pro may do one or more of the following: suspend access to the Services; or prevent you from completing any actions via the Services, including closing any open positions. Following any such event, when trading resumes, you acknowledge that prevailing market rates may differ significantly from the rates available prior to such an event.",
      Term_6_description_5:
        "You hereby authorize Easy exchange pro to attempt and/or perform any such correction, reversal, cancellation, voiding, or amendment described in this section. Easy exchange pro. provides no guarantee or warranty that any such attempt will be successful and will have no responsibility or liability for the Manifest Error or the making or failure of any correction attempt, except if caused by the gross negligence, willful default, or fraud of Easy exchange pro. There is a risk that you may be prejudiced by any action or omission of Easy exchange pro in this regard, and you accept that you have no recourse whatsoever against Easy exchange pro.",
      Term_7_title: "7- Fees",
      Term_7_description_1:
        "You agree to pay for transactions through our Fees Structure service, which may change from time to time. Changes to the Fee Structure are effective as of the effective date indicated in the posting of the revised Fee Structure to the Services and will apply prospectively to any trades that take place following the effective date of such revised Fee Structure.",
      Term_7_description_2:
        "In addition to the costs, your external account may incur costs in connection with your use of your designated external account through the Services. Costs incurred by your external account provider are not reflected on the Transactions page, which contains information about applicable costs. You are solely responsible for paying any fees imposed by an External Account provider.",
      Term_7_description_3:
        "You authorize us, or our designated payment processor, to charge or deduct your Easy Pro Account Funds for any applicable Fees owed in connection with trades you complete via the Services.",
      Term_8_title: "8- Communication",
      Term_8_description_1:
        "You agree and consent to receive electronically all communications, agreements, documents, receipts, notices, and disclosures (collectively, “Communications”) that Easy Pro provides in connection with your Easy Pro Account and/or use of the Easy Pro Services.",
      Term_8_description_2:
        "Updating Contact Information. It is your responsibility to keep your email address on file with Easy Pro up to date so that Easy exchange pro can communicate with you electronically.",
      Term_9_title: "9- Intellectual Property",
      Term_9_description:
        "Unless otherwise indicated by us, all copyright and other intellectual property rights in all content and other materials contained on our website/platform or provided in connection with the Services, including, without limitation, the Easy Pro logo and all designs, text, graphics, pictures, information, data, software, sound files, other files and the selection and arrangement thereof are the proprietary property of Easy Pro or our licensors or suppliers and are protected by international copyright laws and other intellectual property rights laws.",
      Term_10_title: "10- Unclaimed or Disputed Property",
      Term_10_description_1:
        "If for any reason Easy Pro is holding Funds in your Easy  Account, such Funds remain unclaimed, and Easy Pro is unable to return your Funds to your designated External Account after a period of three years after your last login to Easy Pro or any specified period notified by Easy Pro, and Easy Pro determines in good faith that it is not able to trace you, you agree that all such Funds shall be deemed to have been abandoned by you in favor of Easy Pro and may be appropriated by Easy Pro to and for itself, and you thereafter shall have no right to claim such Funds.",
      Term_10_description_2:
        "If Easy Pro receives notice that any Digital Tokens held in your Easy Pro Account are alleged to have been stolen or otherwise are not lawfully possessed by you, Easy Pro may, but has no obligation to, place an administrative hold on or freeze the affected Digital Tokens or your Easy Pro Account. If Easy Pro does place an administrative hold on or freeze some or all your Digital Tokens, Easy Pro may continue such hold until such time as the dispute has been resolved and evidence of the resolution acceptable to Easy Pro has been provided to Easy Pro in a form acceptable to Easy Pro. Easy Pro will not involve itself in any such dispute or the resolution of the dispute. You agree that Easy Pro will have no liability or responsibility for any losses, damages, or prejudice arising from or in connection with such hold or freeze, or for your inability to withdraw Digital Tokens or execute trades during the period of any such hold or freeze.",
      Term_11_title: "11- Complains about your local laws",
      Term_11_description:
        "It is your sole responsibility to determine whether, and to what extent, any taxes apply to any transactions You conduct through the Services, and to withhold, collect, report, and remit the correct amounts of taxes to the appropriate tax authorities. When using our Services, you acknowledge that your actions are in a legal and proper manner and your financial sources are not from illegal activities. We may discretionarily or in coordination with local law enforcement authorities seize, restrict or close out your account, fiat, and/or cryptocurrencies.",
      Term_12_title: "12- Suspension; Termination",
      Term_12_description_1:
        "In the event of any Force Majeure Event breach of these. Terms or Conditions, or any other event where the provision of services to Easy pro is commercially unreasonable, we may, at our sole discretion and without liability to you, with or without prior notice, grant you access to all or part of Suspend it. We may also immediately, without notice, terminate your access to the Services at our sole discretion and delete or deactivate your Easy exchange pro account and all related information and files on this account without liability to you, including in cases where you are violating one of these conditions. In the event of discontinuation of all Services or termination of your access to the Services or deletion or deactivation of your Easy Pro Account: all amounts payable by you to Easy Pro will immediately become due. the platform can cancel any open orders or other transaction requests that are pending at the time of discontinuation or termination or deletion or deactivation. the platform will, where possible, return any Funds stored in your Easy Exchange pro Account not otherwise owed to Easy Pro and/or will use commercially reasonable efforts to provide you with a period 4 months to transfer affected Digital Tokens from your Easy Pro Account, unless prohibited by applicable laws or regulations or by order of law enforcement or governmental authority, or Easy Pro believes you have committed fraud, negligence or other misconduct; and/or may take such other steps as Easy Pro deems necessary or desirable to protect its own interests. We are not and shall not be responsible or liable for any loss or damages incurred because of or arising from any actions taken under this section.",
      Term_12_description_2:
        "Also, if the user has not made any deposits, transactions, or logins for a period of three months by any reason, we may delete or deactivate the user's account, without notifying the User and without assuming any responsibility",
      Term_13_title: "13- Descramble of liability",
      Term_13_subtitle_1: "13.1 Network Service Provider:",
      Term_13_description_1:
        'as a third-party platform for "network service provider", does not guarantee the information and services provided on this Site can fully meet the needs of users. We shall not be liable for errors, insults, defamation, nonfeasance, obscenity, pornography, or blasphemy that may occur during the process of acceptance of the Parties’ web service.',
      Term_13_subtitle_2: "13. 2 Service Interruption:",
      Term_13_description_2:
        "Based on the special nature of the Internet, we do not guarantee that the service will not be interrupted; the timeliness and security of the service are also not guaranteed.",
      Term_13_subtitle_3: "13.3 Safety of the Network:",
      Term_13_description_3:
        "We try to provide a safe network environment to the users; however, we do not guarantee that the Site or its servers are free of viruses or other potentially harmful factors; therefore, the user should use the industry’s recognized software to check and kill any virus in the files downloaded from the Site.",
      Term_13_subtitle_4: "13. 4 User Information:",
      Term_13_description_4:
        "we are not responsible for the failure of preservation, modification, deletion, or storage of the information provided by the user. Nor will we be liable for the typographical errors, negligence, etc. We have the right but no obligation to improve or correct any omission, error of any part of this Site.",
      Term_13_subtitle_5:
        "13. 5 Accuracy, Completeness, and Reliability of the Site:",
      Term_13_description_5:
        "Unless we have expressly agreed in writing, we shall not guarantee the accuracy, completeness, reliability of any content, such as but not limited to, advertising from the platform in any manner (including but not limited to, containing, connecting, by way of, or downloading) from the Site; we are not responsible for any products, services, information or materials purchased or obtained by the user according to the content information on this website. The user bears the risk of using the content of this website.",
      Term_13_subtitle_6: "13. 6 User Opinion:",
      Term_13_description_6:
        "The user comments published by users of the Site, are only on behalf of the user’s personal point of view. It does not mean that this Site agrees with their views or confirms their description. This Site does not bear any legal responsibility caused by any user comments.",
      Term_13_subtitle_7: "13. 7 Announcements:",
      Term_13_description_7:
        "Regarding notices issued to the users, we will deliver these notices through a formal page announcement, station letter, e-mail, customer service phone call, SMS, or regular mail delivery. We do not bear any legal responsibility for any winning, discount activities or information that are delivered by other channels.",
      Term_13_subtitle_8:
        "13. 8 Charges, Commissions, Transactions, and Other Fees:",
      Term_13_description_8:
        "we have the right to adjust the recharge, commission, transaction, and other fees according to market conditions, and terminate any promotional efforts early.",
      Term_14_title: "14- Jurisdiction",
      Term_14_description:
        "These Terms shall be governed by and construed in accordance with the laws of Dubai. The courts of Dubai shall have exclusive jurisdiction to settle any dispute arising from or connected with these Terms (including a dispute relating to the existence, validity, or termination of these Terms or the consequences of their nullity or any non-contractual obligation arising out of or in connection with these Terms).",
      Term_15_title: "15. Miscellaneous",
      Term_15_description_1:
        "These Terms set forth the complete understanding of the parties with respect to the subject matter hereof and supersedes all prior understandings and communications relating thereto our Platforms reserve the right of final interpretation of these Terms within the scope permitted by law. These Terms and related pages of our Platforms may refer to each other. If there is any conflict, these Terms shall prevail. In addition, if some of the provisions in these Terms are deemed invalid or unenforceable, the other provisions in these Terms will remain in effect. In the event of any conflict between these Terms and any other agreement, you may have with Easy Exchange pro the terms of that other agreement will prevail only if these Terms are specifically identified and declared to be overridden by such other agreement.",
      Term_15_description_2:
        "Any failure or delay by Easy Exchange Pro to enforce any of these Terms or to exercise any right hereunder shall not be construed as a waiver to any extent of our rights.",
      Term_16_title: "16- Limitation of Liability",
      Term_16_description:
        "Except as otherwise required by law, in no event shall Easy exchange pro, our directors, officers, employees, agents, Brokers or contractors be liable for any special, indirect, or consequential damages, or any other damages of any kind, including but not limited to loss of use, loss of profits or loss of data, whether in an action in contract, tort (including but not limited to negligence) or otherwise, arising out of or in any way connected with the use of or inability to use our Services or the Easy exchange pro Materials, including without limitation any damages caused by or resulting from reliance by any user on any information obtained from Easy exchange pro , or that result from mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses, delays in operation or transmission or any failure of performance, whether or not resulting from a force majeure event, communications failure, theft of, destruction of or unauthorized access to Easy exchange pro’s records, programs or services. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the limitations of this section may not apply to you.",
      Term_17_title: "17- Miscellany",
      Term_17_subtitle_1: "17 -1 Agency",
      Term_17_description_1:
        "Nothing in this Agreement shall be deemed to have created, implied, or otherwise treated us as your agent, trustee, or another representative unless it is provided otherwise in this Agreement.",
      Term_17_subtitle_2: "17- 2 Waiver",
      Term_17_description_2:
        "Our or your waiver of the right to hold the other party liable for breaches of agreement or any other liability as is agreed upon in this Agreement shall not be construed or deemed as a waiver of the right to hold the other party for other breaches of contract; a failure to exercise any right or remedy shall not be construed in any way as a waiver of such right or remedy.",
      Term_17_subtitle_3: "17- 3 Goodwill",
      Term_17_description_3:
        "You acknowledge and agree that, before filing any dispute or claim, you will contact us through support@easyexchangepro.com to solve the dispute in an informal way where we will try to solve the relevant dispute internally as soon as possible; The Parties agree to negotiate and solve any dispute in good faith (such negotiation shall be confidential and protected under applicable rules, and will not be used as evidence in any proceeding).",
      Term_17_subtitle_4: "17- 4 Assignment of rights",
      Term_17_description_4:
        "The rights and obligations agreed in this Agreement shall be equally binding on the assignees, the heirs, executors, and administrators of the parties hereto who benefit from the rights and obligations. Without our consent, you may not transfer to any third party any of your rights or obligations hereunder, provided, however, we may, at any time, assign our rights and obligations under this Agreement to any third party with forty (40) days' notices to you.",
      Term_17_subtitle_5: "17- 5 Severability",
      Term_17_description_5:
        "The invalidity or unenforceability of any of these Terms shall not affect the validity or enforceability of any other of these Terms, all of which shall remain in full force and effect.",
      Term_17_subtitle_6: "17- 6 Governing language and translations",
      Term_17_description_6:
        "These Terms, the Privacy Policy, and other agreements or communications notified through the Services have been drafted in English. Although translations in other languages of any of the foregoing documents may be available, such translations may not be up to date or complete. Accordingly, you agree that in the event of any conflict between the English language version of the foregoing documents and any other translations thereof, the English language version of such documents shall govern and prevail.",
      // Token Listing
      TL_title: "Fill the form for listing your token",
      TL_Exchange_info: "Exchange Information",
      TL_Token_Name: "Exchange Information",
      TL_Token_Name_Error: "Please enter Token name.",
      TL_Token_symbol: "Token symbol",
      TL_Token_symbol_Error: "Please enter Token symbol.",
      TL_Token_Symbol_upload_Error: "Please upload Token Symbol.",
      TL_NFT: "NFT",
      TL_Total_supply_Error: "Please enter Total supply.",
      TL_Date_of_issue: "Date of issue",
      TL_Date_of_issue_Error: "Please enter Date of issue.",
      TL_Total_circulation: "Total circulation",
      TL_Total_circulation_Error: "Please enter Total circulation.",
      TL_Market_cap_circulation: "Market cap circulation",
      TL_Market_cap_circulation_Error: "Please enter Market cap circulation.",
      TL_Source_code: "Source code",
      TL_Source_code_Error: "Please enter Source code.",
      TL_Whitepaper: "Whitepaper",
      TL_Whitepaper_Error: "Please upload Whitepaper.",
      TL_Communities: "Communities",
      TL_Telegram: "Telegram",
      TL_Telegram_Error: "Please enter Telegram.",
      TL_Twitter: "Twitter",
      TL_Twitter_Error: "Please enter Twitter.",
      TL_Facebook: "Facebook",
      TL_Facebook_Error: "Please enter Facebook.",
      TL_Reddit: "Reddit",
      TL_Reddit_Error: "Please enter Reddit.",
      TL_Discord: "Discord",
      TL_Discord_Error: "Please enter Discord.",
      TL_Submitter_info: "Submitter information",
      TL_Role: "Role",
      TL_Role_Error: "Please enter Role.",
      TL_Personal_ID: "Personal ID",
      TL_Personal_ID_Error: "Please upload Personal ID.",
      TL_About_project: "About project",
      TL_About_project_Error: "Please enter About project.",
      TL_About_Private: "About Private Sale, ICO/IEO/IDO",
      TL_About_Private_Error: "Please enter About Private Sale, ICO/IEO/IDO.",
      TL_Distribution_plan: "Distribution plan",
      TL_Distribution_plan_Error: "Please enter Distribution plan.",
      TL_CEO_info: "CEO info/CV Linkedin",
      TL_CEO_info_Error: "Please enter CEO info/CV Linkedin.",
      TL_Policy: "By Submitting Your Form You Will Accept Our Privacy Policy",
      // Deposit
      Deposit_select_Token: "Please select Token:",
      Deposit_select_Network: "Please select Network:",
      Deposit_Address: "Address",
      Deposit_attention:
        "Please pay attention to withdraw address, and selected network.",
      Deposit_Network: "Network",
      Deposit_Warning:
        "If you deposit to wrong address or with wrong network, you will loose your assets.",
      // Transfer
      Transfer_Transfer: "Transfer",
      Transfer_select_Error: "Please select token",
      Transfer_Balance_Error: "Insufficient balance.",
      Transfer_Main_to_Trade: "Main to Trade",
      Transfer_Trade_to_Main: "Trade to Main",
      // Withdraw
      Withdraw_balance_Error: "Insufficient balance",
      Withdraw_Amount_low_Error: "Withdraw amount cannot be smaller than ",
      Withdraw_Amount_high_Error: "Withdraw amount cannot be larger than ",
      Withdraw_2FA_Error_1: "You need to activate your",
      Withdraw_2FA_Error_2: "Two Factor Authentication (2FA)",
      Withdraw_2FA_Error_3: "for withdraw.",
      Withdraw_KYC_Error_1: "You need to pass",
      Withdraw_KYC_Error_2: "KYC Process",
      Withdraw_KYC_Error_3: "for withdraw.",
      Withdraw_Goto_Profile: "Click to Go to Profile page",
      Withdraw_min: "Min withdraw amount",
      Withdraw_max: "Max withdraw amount",
      Withdraw_fee: "Withdraw Fee",
      Withdraw_Unconfirmed_attention_1: "Please check your selected ",
      Withdraw_Unconfirmed_attention_3: " and Selected ",
      Withdraw_Unconfirmed_attention_4: "NETWORK",
      Withdraw_Unconfirmed_attention_5:
        "if you send to incorrect network address you will loose your assets and EasyPro Platform does not have any responsibility for lost assets.",
      Withdraw_Selected_token: "Selected Token",
      Withdraw_Selected_Network: "Selected Network",
      Withdraw_Confirm_Mail_2FA: "Confirm Mail & Google authentication",
      Withdraw_Confirmed: "Withdraw Confirmed",
      Withdraw_Confirmed_attention_1: "Please check your ",
      Withdraw_Confirmed_attention_2: "EMAIL INBOX",
      Withdraw_Confirmed_attention_3: " and ",
      Withdraw_Confirmed_attention_4: "APPROVE",
      Withdraw_Confirmed_attention_5:
        " the email that sent to you for finishing the withdraw process.",
    },
  },
  ru: {
    translation: {
      ///////////////////// GLOBAL
      Already_Purchased: "Уже купили",
      Global_Products: "Продукты",
      Global_Transaction: "Сделка",
      Global_Icon: "Символ",
      Global_Pair: "Пара",
      Global_LastPrice: "Последняя цена",
      Global_Price: "цену",
      Global_24h: "24h",
      Global_High: "Высокий",
      Global_Low: "Низкий",
      Global_Change: "Изменить",
      Global_Volume: "Объем",
      Global_Trade: "Торгуйте",
      Global_EasyGuarantee: "Easy Guarantee",
      Global_ApplicationForm: "Форма заявки",
      Global_EasyCard: "Easy Card",
      Global_Secure: "Безопасный",
      Global_Fast: "Быстро",
      Global_Convert: "Конвертировать",
      Global_Gabur: "Габур",
      Global_Token: "Токен",
      Global_Total: "Всего",
      Global_Amount: "Количество",
      Global_USDT: "USDT",
      Global_Stop: "Стоп",
      Global_Limit: "Лимит",
      Global_Supply: "Поставка",
      Global_Whitepaper: "Белая бумага",
      Global_Circulation: "Оборот",
      Global_Website: "Веб-сайт",
      Global_Buy: "Купить",
      Global_Sell: "Продать",
      Global_Loading: "Загрузка...",
      Global_Submit: "Отправить",
      Global_Cancel: "Отмена",
      Global_Confirm: "Подтвердить",
      Global_Close: "Закрыть",
      Global_OTP: "OTP",
      Global_Email: "Email",
      Global_2FA: "2FA",
      Global_Password: "Пароль",
      Global_FName: "Имя",
      Global_LName: "Фамилия",
      Global_IDNum: "Номер ID",
      Global_Login: "Войти",
      Global_Register: "Зарегистрироваться",
      Global_Transfer: "Перевод",
      Global_UID: "ID пользователя",
      Global_Purpose: "Цель",
      Global_Mobile: "Мобильный",
      Global_Deposit: "Депозит",
      Global_Withdraw: "Вывод",
      Global_Fee: "Комиссия",
      Global_Fees: "Комиссии",
      Global_OTC: "OTC",
      Global_Support: "Поддержка",
      Global_Download: "Скачать",
      Global_From: "От",
      Global_To: "К",
      Global_Now: "Сейчас",
      Global_Digital: "Цифровой",
      Global_crypto: "крипто",
      Global_cash: "наличные",
      Global_wallet: "кошелек",
      Global_account: "аккаунт",
      Global_Exchange: "Обмен",
      Global_trading: "торговля",
      Global_Accept_Terms:
        "Отметив эту галочку, вы соглашаетесь с нашими условиями и положениями.",
      Global_Available: "Доступно",
      Global_Username: "Имя пользователя",
      Global_Time: "Время",
      Global_Status: "Статус",
      Global_Network_Address: "Сетевой адрес",
      Global_Max_upload: "Максимальный размер загружаемого файла: 10 МБ",
      Global_Policy:
        "Отправляя вашу форму, вы соглашаетесь с нашей политикой конфиденциальности.",
      Global_UID_Error: "Пожалуйста, введите ID пользователя.",
      Global_IDNum_Error: "Пожалуйста, введите номер ID.",
      Global_Mobile_Error: "Пожалуйста, введите мобильный номер.",
      Global_Price_Error: "Пожалуйста, введите цену.",
      Global_Stop_Error: "Пожалуйста, введите стоп-цену.",
      Global_Amount_Error: "Пожалуйста, введите количество.",
      Global_Total_Error: "Пожалуйста, введите общую сумму.",
      Global_Email_Error: "Пожалуйста, введите Email.",
      Global_Password_Error: "Пожалуйста, введите пароль.",
      Global_FName_Error: "Пожалуйста, введите имя.",
      Global_LName_Error: "Пожалуйста, введите фамилию.",
      Global_Website_Error: "Пожалуйста, введите адрес веб-сайта.",
      Global_Purpose_Error: "Пожалуйста, укажите цель запроса.",
      Global_Accept_Terms_Error:
        "Вы должны согласиться, прежде чем отправить форму.",
      Global_about: 'О нас',
      Global_coin_info: "Информация о монете",
      Global_token_listing: "Листинг токенов",
      Global_logout: "Выйти",
      // Home
      //// Top
      Home_Top_Download_From: "Скачать для",
      Home_Top_Playstore: "Playstore",
      Home_Top_Download_APK: "Скачать APK",
      Home_Top_APPstore: "APPstore",
      Home_Top_Register_Now: "Зарегистрироваться сейчас",
      //// carousel
      Home_Carousel_1_title: "Прозрачность и безопасность",
      Home_Carousel_1_description:
        "Мы предоставляем подробную информацию о токене Gabur в Белой бумаге, чтобы вы могли принять решение об инвестировании. Кроме того, смарт-контракт токена Gabur был протестирован для обеспечения высокого уровня безопасности.",
      Home_Carousel_2_title: "Простая гарантия",
      Home_Carousel_2_description:
        "Впервые в цифровом мире Easy Exchange Pro предлагает систему простой гарантии для гарантированного перевода денег между компаниями и для деловых контрактов.",
      Home_Carousel_3_title: "Поддержка 24/7",
      Home_Carousel_3_description:
        "Вы можете связаться с командой поддержки с помощью системы обращений или отправить электронное письмо на support@easyexchangepro.com.",
      //// Easy Guarantee
      Home_EG_subtitle: "Первая цифровая гарантия в мире криптовалют",
      Home_EG_description:
        "Впервые в цифровом мире Easy Exchange Pro предлагает систему простой гарантии для гарантированного перевода денег между компаниями и для деловых контрактов.",
      Home_EG_more_info_btn: "Подробнее",
      //// Easy Card
      Home_EC_subtitle_1: "Единственный способ",
      Home_EC_subtitle_2: "купить криптовалюту наличными",
      Home_EC_description_1:
        "Получите предоплаченный код Easy Card рядом с вами и пополните",
      Home_EC_description_2: "кошелек токенов Gabur. Безопасно и надежно без",
      Home_EC_description_3:
        "банковской карты или кредитной карты. Это единственный способ купить криптовалюту наличными.",

      Home_EC_Feature_1_title: "Независимый",
      Home_EC_Feature_1_description_1:
        "Купить криптовалюту без банковского счета или",
      Home_EC_Feature_1_description_2: "кредитной карты",
      Home_EC_Feature_2_description_1: "Все ваши активы находятся под",
      Home_EC_Feature_2_description_2: "вашим контролем.",
      Home_EC_Feature_3_description_1: "Внесите USDT в свой кошелек",
      Home_EC_Feature_3_description_2: "считанные секунды.",
      Home_EC_Feature_4_title: "Легко",
      Home_EC_Feature_4_description_1: "Ты делаешь то, что всегда",
      Home_EC_Feature_4_description_2: "делал",
      Home_EC_Feature_5_title: "Без комиссии за депозит",
      Home_EC_Feature_5_description_1: "Депозит без затрат",
      Home_EC_Feature_5_description_2: "каких-либо.",
      Home_EC_Feature_6_description_1: "Вы можете конвертировать токены GABUR",
      Home_EC_Feature_6_description_2: "в другие валюты.",
      Home_EC_Where_to_buy: "Где купить",
      //// Mobile Section
      Home_MS_title:
        "Начните торговать легко с мобильным приложением Easy Exchange Pro.",
      Home_MS_list_1: "24/7 доступ к вашим активам в любом месте.",
      Home_MS_list_2: "Надежно и безопасно.",
      Home_MS_list_3: "Удобный интерфейс",
      Home_MS_list_4: "расширенными торговыми инструментами.",
      Home_MS_list_5: "Универсальное приложение",
      Home_MS_dl_for_ios: "Скачать для iOS",
      Home_MS_dl_for_android: "Скачать для Android",
      //about
      //// A Top video
      About_top_title: "Наша миссия",
      About_top_desciption:
        "Наша миссия состоит в создании экосистемы, предоставляющей каждому право легкого доступа к миру цифровых валют, а значит и финансовой свободы.",
      About_top_Feature_1: "600,000",
      About_top_Feature_1_description_1: "Зарегистрированные пользователи",
      About_top_Feature_1_description_2: " ",
      About_top_Feature_2: "2 локаций",
      About_top_Feature_2_description: "Наши представительства в 2 странах",
      About_top_Feature_3: "+28 стран",
      About_top_Feature_3_description: "Услуги в более чем 28 странах",
      ///// A Second
      About_second_title: "Почему стоит выбрать Easy Exchange pro?",
      About_second_subtitle:
        "Простой для начинающих и продвинутый для профессионалов",
      About_second_description_1:
        "Хотя мир криптовалют может быть немного сложным, мы здесь, чтобы помочь! Наша платформа упрощает торговлю",
      About_second_description_2:
        "Мы предоставляем вам инструменты для продажи и покупки самых популярных криптовалют в одном месте, защищенные лучшими в отрасли средствами",
      About_second_Feature_1_description_1:
        "Защищено ведущей в отрасли системой безопасности на ",
      About_second_Feature_1_description_2:
        "основе криптографической технологии",
      About_second_Feature_2_title: "Простота использования ",
      About_second_Feature_2_description_1:
        "Мы разработали простую и удобную платформу ",
      About_second_Feature_2_description_2:
        "и предоставили ее в ваше распоряжение",
      About_second_Feature_3_title: "Расширенное использование",
      About_second_Feature_3_description_1:
        "Мы разработали продвинутый торговый интерфейс, ",
      About_second_Feature_3_description_2:
        "который обеспечивает профессиональный опыт для наших пользователей",
      About_second_Feature_4_description_1: "Торгуйте любимыми валютами ",
      About_second_Feature_4_description_2: "за доли секунды",
      About_second_Feature_5_title: "Купить за наличные",
      About_second_Feature_5_description_1:
        "Самый простой способ купить криптовалюту за ",
      About_second_Feature_5_description_2:
        "наличные — это эксклюзив от Easy Card.",
      About_second_Feature_6_title: "24/7",
      About_second_Feature_6_description_1:
        "Начните торговать криптовалютами, и вы всегда будете получать нашу поддержку.",
      About_second_Feature_6_description_2: "",
      //// Road Map
      About_road_title: "Дорожная карта",
      About_road_description_1:
        "Разработка Easy exchange pro велась в течение трех лет ",
      About_road_description_2:
        "Здесь вы найдете обзор наших целей, которые мы поставили перед собой в ближайшие годы.",
      About_road_year_1: "2022",
      About_road_year_1_description_1: "Уникальный продукт: Easy Guarantee",
      About_road_year_1_description_2: "Еще 5 языков",
      About_road_year_1_description_3: "Еще 10 брокеров в 5 странах",
      About_road_year_1_description_4: "Еще 100 сотрудников",
      About_road_year_1_description_5: "Еще 10 пар",
      About_road_year_2: "2023",
      About_road_year_2_description_1:
        "Новый продукт: Easy Wallet (Простой Кошелек)",
      About_road_year_2_description_2: "Easy Pro Academy (Академия Easy Pro)",
      About_road_year_2_description_3: "API для интеграции бизнеса",
      About_road_year_2_description_4:
        "Easy Card (Простая Карта) станет эффективным методом для проведения транзакций",
      About_road_year_2_description_5: "+ 6 языков",
      About_road_year_2_description_6: "+ 200 брокеров в 16 странах",
      About_road_year_2_description_7: "+ 5 фиатных валют",
      About_road_year_2_description_8: "+ 250 сотрудников",
      About_road_year_2_description_9: "+ 30 пар валют",
      About_road_year_2_description_10: "+100 монет доверия",
      About_road_year_2_description_11:
        "Листинг GABUR на других платформах мирового класса",
      About_road_year_3: "2024",
      About_road_year_3_description_1: "Уникальный продукт: Крипто Банк",
      About_road_year_3_description_2: "+ 6 языков",
      About_road_year_3_description_3: "+ 1000 сотрудников",
      About_road_year_3_description_4: "+ 100 пар валют",
      About_road_year_3_description_5: "+ 130 монет доверия",
      About_road_year_3_description_6:
        "GABUR listing on other world-class platforms",
      // API Documents
      Api_title: "Документы по API",
      Api_1: "Описание",
      Api_2: "Активы",
      Api_3: "Тикер",
      Api_4: "Стакан ордеров",
      Api_5: "Сделки",
      Api_6: "Объем за 24 часа",
      // Asset
      Asset_Tab_1: "Обзор активов",
      Asset_Tab_2: "Основной счет",
      Asset_Tab_3: "Торговый счет",
      Asset_Total_BTC: "Всего активов в BTC",
      Asset_In_Withdraw: "В отзыве",
      Asset_In_Order: "Чтобы",
      // Contact us
      ContactUs_email_title: "Свяжитесь с нами:",
      ContactUs_email: "Support@easyexchangepro.com",
      ContactUs_faq_title:
        "Вы можете проверить нашу страницу Часто задаваемых вопросов, чтобы найти ответ:",
      ContactUs_faq: "",
      ContactUs_ticket_title: "Или вы можете создать обращение:",
      ContactUs_ticket: "",
      ContactUs_social_title: "Найдите и следите за нами в социальных сетях:",

      // Coin info
      CoinInfo_Issue_Time: "Issue Time :",
      CoinInfo_White_paper: "Whitepaper:",
      CoinInfo_Website: "Website:",
      CoinInfo_Block_Explorer: "Block Explorer:",
      CoinInfo_AAVE:
        "Aave is an open source and non-custodial protocol enabling the creation of money markets. Users can earn interest on deposits and borrow assets. Deposit interest rate and loan interest rate of both borrowers and lenders are calculated through algorithms based on the platform's borrowing volume and deposit volume, and the platform uses Chainlink's oracles to ensure the fairness of the collateral price.",
      CoinInfo_ADA:
        "Cardano is a decentralised public blockchain and cryptocurrency project and is fully open source. Cardano is developing a smart contract platform which seeks to deliver more advanced features than any protocol previously developed. It is the first blockchain platform to evolve out of a scientific philosophy and a research-first driven approach.",
      CoinInfo_Atom:
        "In a nutshell, Cosmos bills itself as a project that solves some of the “hardest problems” facing the blockchain industry. It aims to offer an antidote to “slow, expensive, unscalable and environmentally harmful” proof-of-work protocols, like those used by Bitcoin, by offering an ecosystem of connected blockchains. The project’s other goals include making blockchain technology less complex and difficult for developers thanks to a modular framework that demystifies decentralized apps. Last but not least, an Interblockchain Communication protocol makes it easier for blockchain networks to communicate with each other — preventing fragmentation in the industry. Cosmos’ origins can be dated back to 2014, when Tendermint, a core contributor to the network, was founded. In 2016, a white paper for Cosmos was published — and a token sale was held the following year. ATOM tokens are earned through a hybrid proof-of-stake algorithm, and they help to keep the Cosmos Hub, the project’s flagship blockchain, secure. This cryptocurrency also has a role in the network’s governance.",
      CoinInfo_AVAX:
        "Avalanche is a layer one blockchain that functions as a platform for decentralized applications and custom blockchain networks. It is one of Ethereum’s rivals, aiming to unseat Ethereum as the most popular blockchain for smart contracts. It aims to do so by having a higher transaction output of up to 6,500 transactions per second while not compromising scalability. This is made possible by Avalanche’s unique architecture. The Avalanche network consists of three individual blockchains: the X-Chain, C-Chain and P-Chain. Each chain has a distinct purpose, which is radically different from the approach Bitcoin and Ethereum use, namely having all nodes validate all transactions. Avalanche blockchains even use different consensus mechanisms based on their use cases. After its mainnet launch in 2020, Avalanche has worked on developing its own ecosystem of DApps and DeFi. Different Ethereum-based projects such as SushiSwap and TrueUSD have integrated with Avalanche. Furthermore, the platform is constantly working on improving interoperability between its own ecosystem and Ethereum, like through the development of bridges.",
      CoinInfo_BNB:
        "From the ICO, Binance issued its own coin called the Binance Coin, with BNB as the symbol. BNB runs natively on Binance chain. The coin was established with a total supply of 200 million. Every quarter, we will destroy BNB based on the trading volume on our crypto-to-crypto platform until we destroy 50% of all the BNB. All transactions will be on the blockchain. We eventually will destroy 100MM BNB, leaving 100MM BNB remaining. The BNB coin itself has multiple forms of utility, essentially being the underlying gas that powers the Binance Ecosystem. The current most prominent use cases include using BNB to: - Pay for trading fees on the exchange, obtaining the equivalent of a 50% discount on trades (during the first year). - Crypto.com has included support for Binance’s BNB token to its platform which includes the MCO Visa Card and mobile app.",
      CoinInfo_BTC:
        "Bitcoin is a cryptocurrency and a digital payment system invented by an unknown programmer, or a group of programmers, under the name Satoshi Nakamoto.It was released as open-source software in 2009.The system is peer-to-peer, and transactions take place between users directly, without an intermediary. These transactions are verified by network nodes and recorded in a public distributed ledger called a blockchain. Since the system works without a central repository or single administrator, bitcoin is called the first decentralized digital currency.Besides being created as a reward for mining, bitcoin can be exchanged for other currencies, products, and services in legal or black markets.As of February 2015, over 100,000 merchants and vendors accepted bitcoin as payment. According to research produced by Cambridge University in 2017, there are 2.9 to 5.8 million unique users using a cryptocurrency wallet, most of them using bitcoin.",
      CoinInfo_Cake:
        "PancakeSwap, built on Binance Smart Chain (BSC), is a decentralized exchange for swapping BEP-20 tokens. It is fast, cheap, and allows anyone to participate. Users can get FLIP (LP token) for staking and get CAKE token as reward. CAKE holders can swap CAKE for SYRUP for additional incentivized staking. PancakeSwap uses an automated market maker (AMM) model. It means that while you trade digital assets on the platform, there isn’t an order book where you’re matched with someone else. Instead, you trade against a liquidity pool. Traders deposit funds into the pool, receiving liquidity provider (or LP) tokens in return. They can use those tokens to reclaim their share, plus a portion of the trading fees.",
      CoinInfo_Doge:
        "Dogecoin is a cryptocurrency focused on actual utility as a currency. We provide fast block times and very low fees which make Dogecoin suitable for usage in micro-transactions but also as payment option for online shops. Dogecoin has been adopted as such by online retailers and can be used easily as means of consumer to consumer money transfer too.",
      CoinInfo_Dot:
        'Polkadot is a global well-known blockchain cross-chain project, which is created by Ethereum co-founder and Ethereum yellow book author Gavin Wood. It aims to solve the communication and data transmission obstacles between different blockchains and was rated as one of the "4 major projects that decide the direction of digital currency development in 2019".',
      CoinInfo_ETC:
        "Ethereum classNameic (ETC) is a hard fork of Ethereum (ETH) that launched in July 2016. Its main function is as a smart contract network, with the ability to host and support decentralized applications (DApps). Its native token is ETC. Since its launch, Ethereum classNameic has sought to differentiate itself from Ethereum, with the two networks’ technical roadmap diverging further and further from each other with time. Ethereum classNameic first set out to preserve the integrity of the existing Ethereum blockchain after a major hacking event led to the theft of 3.6 million ETH.",
      CoinInfo_ETH:
        'Ethereum (ETH) is an open-source, public, blockchain-based distributed computing platform featuring smart contract (scripting) functionality, which facilitates online contractual agreements. Ethereum also provides a cryptocurrency token called "ether", which can be transferred between accounts and used to compensate participant nodes for computations performed. "Gas", an internal transaction pricing mechanism, is used to mitigate spamand allocate resources on the network.The value token of the Ethereum blockchain is called ether. It is listed under the diminutive ETH and traded on cryptocurrency exchanges. It is also used to pay for transaction fees and computational services on the Ethereum network.',
      CoinInfo_Fil:
        "The InterPlanetary File system (IPFS) is a global, peer-to-peer distributed version of the file system that aims to supplement (or even replace) the hypertext transfer protocol (HTTP) that currently dominates the internet, by connecting all computing devices with the same file system. The principle is to replace the address based on the domain name with a content-based address, that is, the user is looking for content that is not an address but stored in a certain place. There is no need to verify the identity of the sender, but only the hash of the content. It can ensure the web to be faster, safer, more robust and more durable.Filecoin is an incentive layer on IPFS and a a decentralized storage market built on IPFS based on the token incentive model.FIL6 is the Filecoin contract issued 6 months after its launch",
      CoinInfo_GBR:
        "Gabur (GBR) is a utility token for the Easy Exchange Pro platform. The Gabur is using for the Easy Card, a system to buy crypto without cash, and also Easy Guarantee which is a system to guarantee payment between companies and their customers.",
      CoinInfo_LTC:
        "Litecoin is a peer-to-peer Internet currency that enables instant, near-zero cost payments to anyone in the world. Litecoin is an open source, global payment network that is fully decentralized without any central authorities. Mathematics secures the network and empowers individuals to control their own finances. Litecoin features faster transaction confirmation times and improved storage efficiency than the leading math-based currency. With substantial industry support, trade volume and liquidity, Litecoin is a proven medium of commerce complementary to Bitcoin",
      CoinInfo_Matic:
        "Matic Network is a layer-2 scaling solution with side-chains for off-chain computation, while ensuring asset security using the Plasma framework and a decentralized network of Proof-of-Stake (PoS) validators. It aims to offer scalable and instant blockchain transactions. By utilizing adapted version of the Plasma framework, the system is built on PoS checkpoints that are pushed to the Ethereum main-chain. As a result, it is expected to allow each Matic sidechain to theoretically achieve 2^16 transactions per block. Matic Network has been a significant contributor to the Ethereum ecosystem, having worked on implementations of Plasma MVP (Minimum Viable Plasma), the WalletConnect protocol, and Dagger, a popular Ethereum event notification engine. Many projects have already been building applications on and integrating with Matic. MATIC is an ERC-20 token running on Ethereum, it used to pay for services on the Matic Network but also serves as a settlement currency between users who operate within the Matic ecosystem.",
      CoinInfo_MKR:
        "Maker (MKR) is the governance token of the MakerDAO and Maker Protocol — respectively a decentralized organization and a software platform, both based on the Ethereum blockchain — that allows users to issue and manage the DAI stablecoin. Initially conceived in 2015 and fully launched in December 2017, Maker is a project whose task is to operate DAI, a community-managed decentralized cryptocurrency with a stable value soft-pegged to the US dollar. MKR tokens act as a kind of voting share for the organization that manages DAI; while they do not pay dividends to their holders, they do give the holders voting rights over the development of Maker Protocol and are expected to appreciate in value in accordance with the success of DAI itself. The Maker ecosystem is one of the earliest projects on the decentralized finance (DeFi) scene: the industry that seeks to build decentralized financial products on top of smart-contract-enabled blockchains, such as Ethereum.",
      CoinInfo_Near:
        "NEAR Protocol is a layer-one blockchain that was designed as a community-run cloud computing platform and that eliminates some of the limitations that have been bogging competing blockchains, such as low transaction speeds, low throughput and poor interoperability. This provides the ideal environment for DApps and creates a developer and user-friendly platform. For instance, NEAR uses human-readable account names, unlike the cryptographic wallet addresses common to Ethereum. NEAR also introduces unique solutions to scaling problems and has its own consensus mechanism called “Doomslug.” NEAR Protocol is being built by the NEAR Collective, its community that is updating the initial code and releasing updates to the ecosystem. Its declared goal is to build a platform that is “secure enough to manage high value assets like money or identity and performant enough to make them useful for everyday people.” Flux, a protocol that allows developers to create markets based on assets, commodities, real-world events, and Mintbase, an NFT minting platform are examples of projects being built on NEAR Protocol.",
      CoinInfo_TRX:
        "Tron is one of the largest blockchain based operating systems in the world. TRON is an ambitious project dedicated to building the infrastructure for a truly decentralized Internet. The Tron Protocol, one of the largest blockchain based operating systems in the world, offers scalable, high-availability and high-throughput support that underlies all the decentralized applications in the TRON ecosystem. TRON Protocol and the TVM allow anyone to develop DAPPs for themselves or their communities, with smart contracts making decentralized crowdfunding and token issuance easier than ever. Tron DAPP projects already include Peiwo, Obike, Uplive, game.com, Kitty live and Mico, with 100M+ active users from more than 100 countries and regions around the world.",
      CoinInfo_XLM:
        "Stellar network is a free, open-source network that connects diverse financial systems and lets anyone build low-cost financial services—payments, savings, loans, insurance—for their community. It is supported by Stellar.org, a Silicon Valley based non-profit organization. The Stellar network enables money to move directly between people, companies and financial institutions as easily as email. This inter-connectivity means more access for individuals, lower costs for banks, and more revenue for businesses. Token use: Stellar is an open-source, decentralized protocol for digital currency to fiat currency transfers which allows cross-border transactions between any pair of currencies.",
      CoinInfo_XRP:
        "Ripple (XRP) is an independent digital asset that is native to the Ripple Consensus Ledger. With proven governance and the fastest transaction confirmation of its kind, XRP is said to be the most efficient settlement option for financial institutions and liquidity providers seeking global reach, accessibility and fast settlement finality for interbank flows.",
      CoinInfo_ZEC:
        "NZcash is a decentralized cryptocurrency focused on privacy and anonymity. It uses the zk-SNARK zero-knowledge proof technology that allows nodes on the network to verify transactions without revealing any sensitive information about those transactions. Contrary to a common misunderstanding, the majority of cryptocurrencies on the market, including Bitcoin (BTC), are not anonymous, but rather pseudonymous; while they do not explicitly reveal the identities of their users, each user has their own public address or addresses which can be traced back to them via the methods of data science and blockchain forensics. Zcash transactions, on the other hand, still have to be relayed via a public blockchain, but unlike pseudonymous cryptocurrencies, ZEC transactions by default do not reveal the sending and receiving addresses or the amount being sent. There is an option, however, to reveal this data for the purposes of auditing or regulatory compliance. Zcash was first released on October 28, 2016, and it was originally based on Bitcoin’s codebase.",
      // Easy Card
      //// Where To Buy
      ///// Top
      EC_WT_Title: "Buy Easy Card",
      EC_WT_Description:
        "Вы можете купить Easy Card у одного из наших брокеров рядом с вами и пополнить свой счет без визы или кредитной карты.",
      EC_WT_Find_Title: "Найдите брокера рядом с вами",
      EC_WT_Find_btn: "Hайти",
      EC_WT_Find_input: "Введите свой город",
      ///// Apply
      EC_WA_title:
        "Подайте заявку на получение лицензии Easy Card, станьте одним из наших партнеров и получайте прибыль.",
      EC_WA_Decription: "Применять",
      ///// Start
      EC_WS_Question: "Как работает Easy card?",
      EC_WS_title: "Начните в 3 простых шага",
      EC_WS_SubTitle: "Весь процесс менее чем за 3 минуты.",
      EC_WS_Feature_1_description:
        "Найдите ближайшего к вам брокера и купите Easy Card",
      EC_WS_Feature_2_description:
        "Введите код Easy Card, чтобы внести токены Gabur в свой кошелек.",
      EC_WS_Feature_3_description: "Теперь вы можете начать торговать",
      //// Already Purchased
      EC_AP_description:
        "Введите свой код здесь, токены Gabur будут зачислены на ваш кошелек автоматически.",
      EC_AP_Code: "Введите ваш код",
      EC_AP_Code_Error: "Пожалуйста, введите 20-значный код.",
      //// Broker
      ///// Register
      EC_BR_title: "Регистрация брокера",
      ///// Form
      EC_BR_Exchange_info: "Обмен информацией",
      EC_BR_Legal: "Юридическое название",
      EC_BR_Legal_Error: "Please enterregisteredcountry Legal name.",
      EC_BR_RegCountry: "Страна регистрации",
      EC_BR_RegCountry_Error: "Страна регистрации обязательна",
      EC_BR_RegNum: "Регистрационный номер",
      EC_BR_RegNum_Error: "Регистрационный номер обязателен",
      EC_BR_MonTxVal: "Ежемесячный объем транзакций",
      EC_BR_MonTxVal_Error: "Требуется месячный объем транзакций",
      EC_BR_ECMonDis: "Ежемесячная рассылка Easy Card",
      EC_BR_ECMonDis_Error: "Требуется ежемесячная рассылка Easy Card",
      EC_BR_Website: "Сайт (если есть)",
      EC_BR_Submitter_info: "Информация об отправителе",
      EC_BR_Job: "Название работы",
      EC_BR_Job_Error: "Требуется название должности",
      EC_BR_Birth: "Дата рождения",
      EC_BR_Birth_Error: "Укажите дату",
      EC_BR_Country: "Страна проживания",
      EC_BR_Country_Error: "Страна проживания обязательна",
      EC_BR_Citizen: "Гражданство",
      EC_BR_Citizen_Error: "Требуется гражданство",
      EC_BR_Upload_title: "Пожалуйста, загрузите обменные и личные документы:",
      EC_BR_Exchange_Doc: "Регистрационный документ биржи",
      EC_BR_Exchange_Doc_Error:
        "Пожалуйста, загрузите регистрационный документ биржи.",
      EC_BR_Peronal_ID_Doc: "Личный документ, удостоверяющий личность",
      EC_BR_Peronal_ID_Doc_Error:
        "Пожалуйста, загрузите документ, удостоверяющий личность.",
      // Easy Guarantee
      //// Easy Guarantee
      ////// top
      EG_EGTop_title: "Простая гарантия",
      EG_EGTop_Description_1: "Единственная цифровая крипто-гарантия",
      EG_EGTop_Description_2: "Впервые в мире",
      ////// Text
      EG_EGText_title: "Что такое Easy Guarantee?",
      EG_EGText_Description_1:
        "Easy Guarantee - это тип гарантии с обратной связью в USDT, предлагаемый Easyexchangepro, где кредитор гарантирует выполнение обязательств должника. Другими словами, если должник не выплатит долг, Easyexchangepro покроет его.",
      EG_EGText_Description_2:
        "Easy Guarantee позволяет заявителю приобрести товары, купить оборудование, получить кредит или залог, и т. д.",
      EG_EGText_Feature_1_description:
        "Вы сможете оформить Easy Guarantee для ваших деловых целей за 5 минут.",
      EG_EGText_Feature_2_title: "Надежный",
      EG_EGText_Feature_2_description:
        "Easy Guarantee - это не просто документ об обязательстве платежа; скорее, USDT будет внесен на счет получателя в качестве наиболее ликвидной цифровой валюты.",
      EG_EGText_Feature_3_description:
        "Выпущенная Easy Guarantee на 100% безопасна и гарантирована для получателя и может быть конвертирована или выведена по истечении срока.",
      ////// Steps
      EG_EGSteps_title: "Вы можете оформить Easy Guarantee в 4 простых шага:",
      EG_EGSteps_Feature_1_title: "Шаг 1",
      EG_EGSteps_Feature_1_description_1: "/",
      EG_EGSteps_Feature_1_description_2: "На нашей платформе",
      EG_EGSteps_Feature_2_title: "Шаг 2",
      EG_EGSteps_Feature_2_description_1: "Заполните заявку",
      EG_EGSteps_Feature_2_description_2: "и отправьте",
      EG_EGSteps_Feature_3_title: "Шаг 3",
      EG_EGSteps_Feature_3_description_1: "Переведите сумму",
      EG_EGSteps_Feature_3_description_2: "Easy Guarantee в USDT",
      EG_EGSteps_Feature_4_title: "Шаг 4",
      EG_EGSteps_Feature_4_description_1: "Гарантия была оформлена, и",
      EG_EGSteps_Feature_4_description_2: "USDT был зачислен и заблокирован",
      EG_EGSteps_Feature_4_description_3: "на счете получателя",

      ////// Bot
      EG_EGBot_title: "Для компаний и частных лиц",
      EG_EGBot_description:
        "Компании и частные лица в качестве наших пользователей могут оформить Гарантию на малые или большие суммы в своих деловых целях в пользу получателя.",
      //// FAQ
      EG_EGFaq_Q1: "Что такое Easy Guarantee (Простая Гарантия)?",
      EG_EGFaq_A1:
        "Easy Guarantee (Простая Гарантия) - это тип гарантии с обеспечением в виде криптовалюты (USDT), основанный на том, что заемщики/покупатели гарантируют выполнение своих обязательств перед кредитором.",
      EG_EGFaq_Q2: "Кто является заявителем?",
      EG_EGFaq_A2:
        "Заявитель - это сторона, которая будет просить о выдаче Easy Guarantee (Простой Гарантии).",
      EG_EGFaq_Q3: "Какая валюта принимается для выдачи гарантии?",
      EG_EGFaq_A3: "Только USDT.",
      EG_EGFaq_Q4: "Можно использовать любой адрес кошелька USDT?",
      EG_EGFaq_A4:
        "Нет, в Easy Exchange Pro принимаются только адреса кошельков USDT заявителя и выгодоприобретателя.",
      EG_EGFaq_Q5: "Кто является выгодоприобретателем?",
      EG_EGFaq_A5: "Это сторона, в пользу которой была выдана гарантия.",
      EG_EGFaq_Q6: "Что такое условная Easy Guarantee (Простая Гарантия)?",
      EG_EGFaq_A6:
        "Запрос на освобождение суммы гарантии со стороны выгодоприобретателя должен быть одобрен заявителем.",
      EG_EGFaq_Q7: "Что такое безусловная Easy Guarantee (Простая Гарантия)?",
      EG_EGFaq_A7:
        "Сумма Easy Guarantee (Простой Гарантии) будет выплачена по первому требованию выгодоприобретателя.",
      EG_EGFaq_Q8: "Как пользователи могут подать заявку?",
      EG_EGFaq_A8: "Заполните форму заявки и отправьте ее.",
      EG_EGFaq_Q9: "Что произойдет после отправки формы?",
      EG_EGFaq_A9:
        "Заявитель получит первое электронное письмо для подтверждения заявки, а затем второе электронное письмо, содержащее адрес кошелька USDT для перевода суммы Easy Guarantee (Простой Гарантии).",
      EG_EGFaq_Q10:
        "На какую сумму пользователь может выдать Easy Guarantee (Простую Гарантию)?",
      EG_EGFaq_A10:
        "Пользователь может выдать Easy Guarantee (Простую Гарантию) в размере суммы USDT на его/её счету.",
      EG_EGFaq_Q11:
        "Нужно ли заявителю иметь сумму USDT на счету для запроса и выдачи Easy Guarantee (Простой Гарантии)?",
      EG_EGFaq_A11:
        "Да, пользователи могут запросить выдачу Easy Guarantee (Простой Гарантии) в размере суммы USDT на их счете.",
      EG_EGFaq_Q12: "Когда будет выдана Easy Guarantee (Простая Гарантия)?",
      EG_EGFaq_A12:
        "После перевода USDT со стороны заявителя Easy Guarantee (Простая Гарантия) будет выдана немедленно, и сумма USDT будет заблокирована на счету выгодоприобретателя на основании срока действия.",
      EG_EGFaq_Q13:
        "Как будет разрешаться любой спор, касающийся освобождения гарантии между заявителем и выгодоприобретателем?",
      EG_EGFaq_A13:
        "В случае возникновения спора любая из сторон может отправить свое возражение по адресу easyguarantee@easyexchangepro.com, и наша юридическая служба примет решение в течение одного месяца после рассмотрения документов сторон.",
      EG_EGFaq_Q14:
        "Должны ли заявитель и выгодоприобретатель быть пользователями платформы Easyexchangepro?",
      EG_EGFaq_A14: "Да, обязательно.",
      EG_EGFaq_Q15:
        "Когда выгодоприобретатель может запросить выплату суммы Easy Guarantee (Простой Гарантии)?",
      EG_EGFaq_A15:
        "Выгодоприобретатель может запросить выплату суммы Easy Guarantee (Простой Гарантии) на своем счету в течение срока действия Easy Guarantee (Простой Гарантии).",
      EG_EGFaq_Q16:
        "Что произойдет, если выгодоприобретатель не запросит выплату суммы гарантии в течение срока действия Easy Guarantee (Простой Гарантии)?",
      EG_EGFaq_A16:
        "Сумма Easy Guarantee (Простой Гарантии) будет автоматически перечислена на счет заявителя.",
      EG_EGFaq_Q17:
        "Сколько времени занимает выдача Easy Guarantee (Простой Гарантии)?",
      EG_EGFaq_A17: "Менее 5 минут.",
      EG_EGFaq_Q18: "Сколько стоит выдача Easy Guarantee (Простой Гарантии)?",
      EG_EGFaq_A18: "0,4% от суммы Easy Guarantee (Простой Гарантии).",
      EG_EGFaq_Q19: "Кто несет расходы по оплате комиссии?",
      EG_EGFaq_A19: "Заявитель.",
      EG_EGFaq_Application_form: "Форма заявки",
      //// Application Form
      EG_AF_title: "Форма заявки на Easy Guarantee",
      EG_AF_description:
        "Примечание: Пожалуйста, внимательно ознакомьтесь с разделом Вопросы по Easy Guarantee перед заполнением формы заявки.",
      EG_AP_Applicant: "Заявитель:",
      EG_AP_SDate: "Дата начала",
      EG_AP_SDate_Error: "Пожалуйста, введите дату начала.",
      EG_AP_EDate: "Дата окончания",
      EG_AP_EDate_Error: "Пожалуйста, введите дату окончания.",
      EG_AP_WalletApp: "Адрес кошелька USDT Easy Exchange Pro",
      EG_AP_WalletApp_Error: "Пожалуйста, введите адрес кошелька USDT.",
      EG_AP_Amount: "Сумма USDT",
      EG_AP_Amount_Error: "Пожалуйста, введите сумму USDT.",
      EG_AP_VPD: "Срок действия (в днях)",
      EG_AP_VPD_Error: "Пожалуйста, введите срок действия (в днях).",
      EG_AP_Select_Conditional: "Условный",
      EG_AP_Select_Unconditional: "Безусловный",
      EG_AP_Beneficiary: "Получатель:",
      EG_AP_BenUID: "Идентификатор пользователя получателя",
      EG_AP_BenUID_Error:
        "Пожалуйста, введите идентификатор пользователя получателя.",
      EG_AP_BenEmail: "Адрес электронной почты получателя",
      EG_AP_BenEmail_Error:
        "Пожалуйста, введите адрес электронной почты получателя.",
      EG_AP_WalletBen: "Адрес кошелька USDT получателя в Easy Exchange Pro",
      EG_AP_WalletBen_Error:
        "Пожалуйста, введите адрес кошелька USDT получателя.",

      // Fee
      Fee_title_1: "Ордера создающие ликвидность (Мейкер)",
      Fee_description_1:
        "Ордера создающие ликвидность (Мейкер) размещаются на биржевом стакане и создают ликвидность на рынке. Другими словами, ордера мейкеров не исполняются немедленно после размещения, а ждут, пока появится ордер, который сможет с ними сопоставиться. Ордер мейкера может быть как на покупку, так и на продажу. Когда существующий ордер на биржевом стакане сопоставляется с только что размещенным ордером (тейкером), комиссия за транзакцию взимается с ордера мейкера. Если только что размещенный ордер не сопоставляется с какими-либо существующими ордерами или не исполняется полностью существующими ордерами, неисполненный ордер помещается на биржевой стакан и становится ордером мейкера для будущей сделки.",
      Fee_title_2: "Ордера забирающие ликвидность (Тейкер)",
      Fee_description_2:
        "Ордера забирающие ликвидность (Тейкер) уменьшают ликвидность на рынке. Ордера тейкеры исполняются немедленно и удаляют объем из биржевого стакана. Ордер тейкера может быть как на покупку, так и на продажу. Когда размещается новый ордер и он сопоставляется с другим ордером, уже находящимся на биржевом стакане (мейкером), комиссия за транзакцию взимается с ордера тейкера.",
      Fee_title_3: "Комиссии за транзакции",
      Fee_subtitle_3_1: "Блокчейн:",
      Fee_description_3_1:
        "При перемещении токенов по блокчейну на Easy Exchange Pro могут взиматься конкретные комиссии сети блокчейна.",
      Fee_description_3_2:
        "Easy Exchange Pro не взимает комиссии за депозиты. Обратите внимание, что некоторые токены или монеты требуют перемещения ваших средств на другой адрес перед зачислением. Это означает, что сеть монеты или токена будет взимать с вас обычную комиссию за трансфер средств, чего не избежать Easy Exchange Pro.",
      Fee_description_3_3:
        "Easy Exchange Pro взимает небольшую комиссию за вывод токена или монеты с встроенной комиссией сети. Комиссия сети отображается при нажатии кнопки вывода на окне вывода для каждого токена или монеты с встроенной комиссией сети. Комиссия сети отображается при нажатии кнопки вывода на окне вывода для каждого токена или монеты.",
      Fee_table_1_col_1: "Уровень",
      Fee_table_1_col_2: "Объем торгов за 30 дней (BTC)",
      Fee_table_1_col_3: "Мейкер",
      Fee_table_1_col_4: "Тейкер",
      Fee_table_2_col_1: "Символ валюты",
      Fee_table_2_col_2: "Название валюты",

      // Gabur
      //// Top
      GBR_top_title_1: "EASY EXCHANGE PRO",
      GBR_top_title_3: "Эволюция финансов",
      //// Second
      GBR_Second_title_1: "БЕЗОПАСНЫЙ СМАРТ-КОНТРАКТ",
      GBR_Second_title_2: "Проверка Gabur Token",
      GBR_Second_description:
        "Смарт-контракт состоит из специальных инструкций, хранящихся в блокчейне. Этот контракт может выполнять несколько действий в соответствии с заранее заданными параметрами способом, незыблемым, прозрачным и полностью безопасным. Поэтому смарт-контракт GABUR Tokens прошел аудит для обеспечения высоких уровней безопасности.",
      GBR_Second_Security_btn: "Аудит безопасности",
      //// Description
      GBR_Description_title_1: "Описание Gabur Tokens",
      GBR_Description_list_1_1: "Имя утилитарного токена: GABUR",
      GBR_Description_list_1_2: "Символ: GBR",
      GBR_Description_list_1_3: "Общее количество GABUR Tokens: 550 млн",
      GBR_Description_list_1_4: "GABUR Tokens не подлежат увеличению",
      GBR_Description_list_1_5:
        "GABUR Tokens будут работать на сети BEP20, следуя стандарту BSC.",
      GBR_Description_list_1_6:
        "Сжигание токенов: 165 млн GABUR Tokens будут сожжены",
      GBR_Description_title_2: "Распределение Gabur Tokens",
      GBR_Description_list_2_1: "Публичная продажа",
      GBR_Description_list_2_2: "Резерв Easy card",
      GBR_Description_list_2_3: "Команда",
      GBR_Description_list_2_4: "Консультанты",
      GBR_Description_list_2_5: "Приватная продажа",
      GBR_Description_list_2_6: "Аирдроп",
      //// Contract
      GBR_Contract_subtitle: "Адрес контракта Gabur token",
      //// Added
      GBR_Added_subtitle: "План Gabur Token",
      GBR_Added_title: "В чем заключается добавленная стоимость Gabur Token?",
      GBR_Added_list_1:
        "Развитие Easy Exchange pro в соответствии с нашими обязательствами по дорожной карте",
      GBR_Added_list_2:
        "Создание инновационных идей по развитию Easy Card, которая должна стать значимой функцией для использования в транзакциях",
      GBR_Added_list_3: "GABUR Tokens не подлежат увеличению",
      GBR_Added_list_4:
        "Инновационное развитие, направленное на повышение практичности цифровых активов",
      GBR_Added_list_5: "Ежеквартальное сжигание GABUR Tokens",
      GBR_Added_list_6: "Период блокировки токенов",
      GBR_Added_list_7:
        "Возможность привлечения пользователей, ищущих стабильный и безопасный способ осуществления платежей в более чем 60 странах",
      GBR_Added_list_8:
        "Наличие опытной команды в сфере криптовалют и финансовой сфере с широкими международными финансовыми связями",
      GBR_Added_list_9:
        "Мы будем добавлять новые и уникальные продукты в соответствии с нашей дорожной картой",

      // Login
      Login_title: "Добро пожаловать в Easy Pro",
      Login_subtitle: "Войдите, чтобы продолжить",
      Login_btn_Loading: "Войти",
      Login_problem: "Проблемы с входом?",
      Login_click_here: "Нажмите здесь",
      Login_register: "Нажмите здесь, чтобы зарегистрироваться",
      Login_protected: "Этот сайт защищен reCAPTCHA и Google",
      Login_privacy: "Политика конфиденциальности",
      Login_and: "и",
      Login_terms: "Условия использования",
      Login_ResetModal_title: "Вы можете сбросить 2FA или пароль",
      Login_ResetModal_Reset2FA_btn: "Сбросить 2FA",
      Login_ResetModal_ForgetPassword: "Забыли пароль?",
      Login_ResetPassword_title:
        "Введите свой адрес электронной почты, чтобы получить ссылку для сброса пароля",
      Login_Reset2FA_title:
        "Введите свой адрес электронной почты, чтобы получить ссылку для сброса 2FA",
      Login_OTP_title: "Введите полученный код OTP",

      //// Reset Password
      ResetPassword_title: "Сброс пароля",
      ResetPassword_NewPassword_input: "Новый пароль",
      ResetPassword_ConfirmPassword_input: "Подтвердите новый пароль",

      // Privacy And Policy
      Privacy_1_title: "INTRODUCTION",
      Privacy_1_desciption:
        "Thanks indeed for visiting Easyexchangepro.com, as a digital asset trading website. The purpose of this Privacy Policy is to inform you (user, customer) with an understanding that how Easyexchangepro (or we, or our, or us or platform) collect personal information from our clients and prospective clients to provide them with our products and services and ensure that we can meet their needs when providing these products and services, as well as when providing them with any respective information. This privacy policy applies to the Site and all Services offered by Easyexchangepro.",
      Privacy_2_title: "PERSONALLY IDENTIFIABLE",
      Privacy_2_desciption:
        "We may collect personally identifiable information from Users in a variety of ways, including, but not limited to, when Users visit our Site, register on the Site, place an order, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Site anonymously. When interacting with us on the Site, Users can always refuse to supply personal data to us, except that it may prevent them from engaging in certain Site related activities or transactions. We may collect non-personally identifiable information about Users when they interact with our Site.",
      Privacy_3_title: "WHAT INFORMATION DO WE COLLECT?",
      Privacy_3_desciption_1:
        "Easyexchangepro collects information about you when you use our websites (including the Site) and other online products and services and throughout other interactions and services, you have with us. We will collect your personal data in accordance with the legislative purpose of the DPA. We may also collect and store certain information automatically when you visit the Site. Examples include the internet protocol (IP) address used to connect your computer or device to the internet, connection information such as browser type and version, your operating system and platform, a unique reference number linked to the data you enter on our system, log in details, the full URL clickstream to, through and from the Site (including date and time), cookie number and your activity on our Site, including the pages you visited, the searches you made and, if relevant, the products/services you purchased.",
      Privacy_3_desciption_2:
        "We may receive information about you from third parties if you use any websites or social media platforms operated by third parties (for example, Facebook, Instagram, Twitter, etc.) and, if such functionality is available, you have chosen to link your profile on the Site with your profile on those other websites or social media platforms.",
      Privacy_4_title: "INDIVIDUAL AND COMPANY WHICH WE MAY COLLECT INCLUDE:",
      Privacy_4_subtitle_1: "For individual:",
      Privacy_4_list_1_1: "• Email address",
      Privacy_4_list_1_2: "• Mobile phone number",
      Privacy_4_list_1_3:
        "• Full legal name (including former name, and names in local language)",
      Privacy_4_list_1_4: "• Nationality",
      Privacy_4_list_1_5:
        "• Passport number, or any government-issued ID number",
      Privacy_4_list_1_6: "• Date of birth",
      Privacy_4_list_1_7:
        "• Proof of identity (e.g., passport, driver’s license, or government-issued ID)",
      Privacy_4_list_1_8: "• Residential address",
      Privacy_4_list_1_9: "• Proof of residency",
      Privacy_4_list_1_10:
        "• Additional Personal Data or documentation at the discretion of our Compliance Team",
      Privacy_4_subtitle_2: "For companies:",
      Privacy_4_list_2_1:
        "• Company legal name (including the legal name in local language)",
      Privacy_4_list_2_2: "• Incorporation/registration Information",
      Privacy_4_list_2_3:
        "• The full legal name of all beneficial owners, directors, and legal representatives",
      Privacy_4_list_2_4:
        "• Address (principal place of business and/or other physical locations)",
      Privacy_4_list_2_5: "• Proof of legal existence",
      Privacy_4_list_2_6: "• Description of the business",
      Privacy_4_list_2_7:
        "• Percentage of ownership for Individual/company owners",
      Privacy_4_list_2_8:
        "• Contact information of owners, principals, and executive management (as applicable)",
      Privacy_4_list_2_9:
        "• Proof of identity (e.g., passport, driver’s license, or government-issued ID) for the significant individual beneficial owner of the institutional customer entity",
      Privacy_4_list_2_10:
        "• Personal Data for each entity’s significant beneficial owner of the institutional customer entity (see the “Individual Customer” section above for details on what Personal Data we collect for individuals)",
      Privacy_4_list_2_11: "• Source of wealth",
      Privacy_4_list_2_12:
        "• Amount of bitcoin or other digital assets projected to be injected",
      Privacy_5_title: "COOKIES USAGE",
      Privacy_5_desciption:
        "When you access our Sites and Services, we or companies we work with may place cookies on your computer or another device. Of course, you can block or deactivate cookies in your browser settings. But these technologies help us better understand user behavior and inform us about which parts of our websites people have visited. We use first-party and third-party cookies to recognize you as an Easyexchangepro user, customize Coinbase Services, content, and advertising, measure promotional effectiveness, and collect information about your computer or another access device to mitigate risk, helping prevent fraud, and promote trust and safety. We may place cookies from third-party service providers who may use information about your visits to other websites to target advertisements for products and services available from Easyexchangepro. We do not control the types of information collected and stored by these third-party cookies. You should check the third-party's website for more information on how they use cookies.",
      Privacy_6_title: "LAWFUL BASIS FOR PROCESSING YOUR PERSONAL INFORMATION",
      Privacy_6_desciption_1:
        "We will process your personal information on the following bases and for the following purposes:",
      Privacy_6_desciption_2:
        "carrying out your instructions or responding to any inquiry given by (or purported to be given by) you or on your behalf including responding to your inquiries and complaints, or responding to or dealing with your interactions with us; registering you as a customer of Easyexchangepro and/or to deal with, process and/or administer the account that you may open with us, whether a membership account or otherwise, including to facilitate your transactions or activities on the Site or your transactions or activities with us; administering, facilitating, processing and/or dealing with your relationship with us, any transactions or activities carried out by you on the Site or with us. This includes processing your application, orders, and payment transactions; implementing transactions and the supply of products and/or services to you that you have requested; administering, facilitating, processing, and/or dealing in any matters relating to your use or access of the Site, including identifying you for login to the Site, our portals and other online services provided by or on behalf of us; monitoring, processing and/or tracking your use of the Site in order to provide you with a seamless experience, facilitating or administering your use of the Site, and/or to assist us in improving your experience in using the Site; assessing and processing your request for the purchase of and/or subscription to our products and/or services. contacting you or communicating with you via phone/voice call, text message and/or fax message, email and/or postal mail for the purposes of administering and/or managing your use of the Site, your membership and/or account with us, your relationship with us or any transactions made by you with us.",
      Privacy_7_title: "HOW WE USE YOUR PERSONAL DATA",
      Privacy_7_desciption_1:
        "Easyexchangepro uses Personal Data to communicate with you and to administer, deliver, improve, and personalize the Service. Easyexchangepro might also generate generic data out of any Personal Data we collect and use it for our own purposes. We may also use such data to communicate with you in relation to other products or services offered by Easyexchangepro and/or its partners. We do not share your Personal Data with third parties (other than partners in connection with their services to Easyexchangepro) except where you have given your consent and further detailed below.",
      Privacy_7_desciption_2:
        "We may share your Personal Data with third parties:",
      Privacy_7_desciption_3:
        "If we think that sharing it is necessary to enforce the Terms of Service. To comply with government agencies, including regulators, law enforcement, and/or justice departments. To third parties who provide services to Easyexchangepro (such as administration or technical services). In connection with the sale or transfer of our business or any part thereof.",
      Privacy_7_desciption_4:
        "Additionally, we have implemented international standards to prevent money laundering, terrorist financing and will implement final Digital Asset rules and regulations when effective, which will likely require us to undertake due diligence on our customers. This may include the use of third-party data and service providers which we will cross-reference with your personal information.",
      Privacy_8_title: "STORING YOUR PERSONAL DATA",
      Privacy_8_desciption:
        "The data that we collect from you may be transferred to and stored at, a destination outside of the U.A.E. It may also be processed by staff operating outside of U.A.E who work for us or for one of our suppliers. By submitting your personal data, you agree to this transfer, storing, or processing, except for customers located in the EEA, as detailed above. All information you provide to us is stored on our and/or third-party cloud servers",
      Privacy_9_title: "MARKETING COMMUNICATIONS",
      Privacy_9_desciption:
        "We may use your personal information to send you marketing communications by email or other agreed forms (including social media campaigns), to ensure you are always kept up to date with our latest products and services. If we send you marketing communications, we will do so based on your consent and registered marketing preferences. Users can opt out of these marketing communications at any moment. If you do not want to receive these communications, please send an email to support@easyexchangepro.com",
      Privacy_10_title: "DATA SECURITY",
      Privacy_10_desciption:
        "We endeavor to protect Easyexchangepro and you from unauthorized access, alteration, disclosure, or destruction of Personal Data we collect and store. We take various measures to ensure information security, including encryption of the Easyexchangepro communications with SSL; required two-factor authentication for all sessions; periodic review of our Personal Data collection, storage, and processing practices; and restricted access to your Personal Data on a need-to-know basis for our employees and vendors who are subject to strict contractual confidentiality obligations.",
      Privacy_11_title:
        "TRANSFER OF PERSONAL INFORMATION OUTSIDE OF THE EEA and UK and YOUR COUNTRY",
      Privacy_11_desciption:
        "We may transfer your personal information outside the EEA and UK to other Company subsidiaries, service providers, and business partners (i.e., Data Processors) who are engaged on our behalf. To the extent that we transfer your personal information outside of the EEA and UK, we will ensure that the transfer is lawful and that Data Processors in third countries are obliged to comply with the European Union (EU) General Data Protection Act 2016 and the UK Data Protection Act 2018. Also, by using our products and services, you consent to your Personal Data being transferred to other countries, including countries that have differing levels of privacy and data protection laws than your country. In all such transfers, we will protect your personal information as described in this Privacy Notice and ensure that appropriate information sharing contractual agreements are in place.",
      Privacy_12_title: "COMPLIANT PROCESS",
      Privacy_12_desciption:
        "If you have any complaint or grievance regarding how we are handling your personal data or about how we are complying with the CPT (when in force), we welcome you to contact us with your complaint or grievance and should be addressed to CPT@Easyexchangepro.com Where you are sending an email in which you are submitting a complaint, your indication at the subject header that it is a CPT complaint would assist us in attending to your complaint speedily by passing it on to the relevant staff in our organization to handle. We will certainly strive to deal with any complaint or grievance that you may have speedily and fairly.",
      Privacy_13_title: "YOUR ACCEPTANCE",
      Privacy_13_desciption:
        "By using Easyexchangepro Site, you signify your acceptance of this policy and Terms of Use.",
      Privacy_14_title: "CHANGES TO THIS PRIVACY POLICY",
      Privacy_14_desciption:
        "Any changes we may make to our Privacy Policy in the future will be posted on this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.",
      // Product Convert
      Convert_title: "Конвертировать",
      Convert_for_each: "За каждую",
      Convert_receive: "вы получите",
      Convert_calc: "Рассчитать конвертацию",

      // Product Market Tabs
      Market_tab_Spot: "Спот",
      Market_tab_Favorite: "Избранное",

      // Product OTC
      OTC_title: "Easy Pro OTC-стол",
      OTC_description:
        "Трейдеры, желающие обменивать криптовалюту, особенно в больших объемах, обычно решают использовать OTC (внебиржевую) торговлю криптовалютой. Easy Pro OTC-стол предоставит вам услуги исполнения и расчетов, которые являются конфиденциальными, безопасными и очень конкурентоспособными.",
      OTC_Feature_1:
        "Мы проводим OTC-сделки конфиденциально без существенного влияния на рынки.",
      OTC_Feature_2:
        "Наш торговый стол соблюдает конфиденциальность со всеми протоколами безопасности на Easy Pro.",
      OTC_Feature_3:
        "Расчеты производятся в рамках экосистемы Easy Pro и обычно завершаются.",
      OTC_Feature_4: "Расчет будет завершен в течение нескольких минут",
      OTC_Feature_5: "На нашем столе нет сторонних лиц",
      OTC_Feature_6:
        "Торговля происходит на счете Easy Pro, вам не нужно создавать новый кошелек.",
      OTC_Feature_7:
        "Монеты/токены, с которыми вы торгуете, просто перемещаются в ваш аккаунт вручную нашей командой.",
      OTC_Feature_8:
        "Детали сделок можно найти только в разделе «История распределения» в вашем аккаунте.",
      OTC_Support_title: "Поддержка OTC",
      OTC_Support_description:
        "OTC-стол предоставляет глобальное покрытие и работает 24/7, за исключением крупных праздников OTC@Easyexchangepro.com",
      OTC_list_1: "Пожалуйста, заполните форму OTC и отправьте нам",
      OTC_list_2:
        "Наша команда готова предложить котировки, провести торговлю и осуществить расчеты",
      OTC_list_3:
        "После принятия нашего предложения сделка подтверждается, и наша команда отправит вам подробности и инструкции по сделке по электронной почте.",
      OTC_list_4:
        "Получив вашу часть сделки, OTC-стол приступит к расчету на ваш кошелек или банковский счет",
      // Other texts
      Trade_KYC_Error: "Загрузите ваш KYC",
      Trade_Trading_password_Error: "Активируйте Торговый пароль",
      Trade_Trading_password_title: "Введите ваш торговый пароль",
      Trade_Trading_password_input: "Торговый пароль",
      Trade_Trading_Password_btn: "Ввести торговый пароль",
      Trade_goto_security: "Перейти в раздел Безопасность",
      Trade_FAV: "FAV",
      Trade_Open_Orders: "Открытые ордера",
      Trade_Order_history: "История ордеров",
      Trade_Trade_history: "История торгов",
      Trade_Date_Time: "Дата и время",
      Trade_Type: "Тип",
      Trade_Action: "Действие",
      Trade_Login_Register: "Вход/Регистрация",
      Trade_Password_Error: "Введите ваш пароль, пожалуйста.",
      Trade_Balance_Error: "Недостаточный баланс, у вас есть",
      Trade_Min_Error: "Сумма ордера должна быть выше 20 USDT",
      Trade_approximately: "Вы получите примерно",
      Trade_Market: "Рынок",
      Trade_Order_List: "Список ордеров",
      Trade_Order_History: "История ордеров",
      Trade_Pairs: "Пары",
      Trade_Open_Chart: "Открыть график",
      Profile_profile: "профиль",
      Profile_security: "безопасность",
      Profile_history: "история",
      Profile_Security_log_history: "Журнал безопасности",
      Profile_Login_history: "История входов",
      Profile_User_info_title: "Информация о пользователе",
      Profile_User_info_description:
        "Ваша уникальная информация об аккаунте, которую вам необходимо хранить в безопасности.",
      Profile_UID: "UID",
      Profile_Sec_info_title: "Информация о безопасности",
      Profile_Activated: "Активирован",
      Profile_Disabled: "Отключен",
      Profile_Trading_Password: "Торговый пароль",
      Profile_Account_status_title: "Статус аккаунта",
      Profile_KYC: "KYC",
      Profile_Sec_level: "Уровень безопасности",
      Profile_Airdrop_point: "Бонусные очки",
      Profile_Referrals_title: "Рефералы",
      Profile_Num_Referrals: "Количество рефералов",
      Profile_Referral_ID: "ID реферала",
      Profile_Referral_List_btn: "Список рефералов",
      Profile_Commissions_btn: "Комиссии",
      Profile_User: "Пользователь",
      Profile_KYC_Verified: "Подтверждено",
      Profile_KYC_Pending: "Ожидает",
      Profile_KYC_Rejected: "Отклонено",
      Profile_KYC_NotUploaded: "Не загружено",
      Profile_Sec_lvl_Medium: "Средний",
      Profile_Sec_lvl_VeryHigh: "Очень высокий",
      Profile_Account_Password_title: "Пароль аккаунта",
      Profile_Account_Password_description:
        "Вы можете изменить ваш пароль здесь.",
      Profile_Change_Password_btn: "Изменить пароль",
      Profile_Change_your_password_title: "Изменить ваш пароль",
      Profile_Current_Password: "Текущий пароль",
      Profile_New_Password: "Новый пароль",
      Profile_Confirm_New_Password: "Подтвердите новый пароль",
      Profile_Sec_Authenticaton_title: "Двухфакторная аутентификация",
      Profile_Sec_Authenticaton_description:
        "Вы можете активировать/отключить 2FA здесь.",
      Profile_Active_2FA: "Активировать 2FA",
      Profile_Activating_2FA: "Активация 2FA",
      Profile_Disable: "Отключить",
      Profile_Disable_2FA: "Отключить 2FA",
      Profile_2FA_Code: "Код 2FA",
      Profile_Active: "Активно",
      Profile_Trading_Password_description:
        "Вы можете изменить ваш торговый пароль здесь.",
      Profile_Change_trading_password: "Изменить торговый пароль",
      Profile_Change_your_Trading_password_title:
        "Изменить ваш торговый пароль",
      Profile_Change_your_Trading_password_description:
        "Хотите изменить ваш торговый пароль?",
      Profile_forgot_code: "забыли код",
      Profile_KYC_title: "Личный KYC-аккаунт",
      Profile_KYC_select_doc: "Выберите тип документа",
      Profile_KYC_select_doc_ID: "Удостоверение личности",
      Profile_KYC_select_doc_pass: "Паспорт",
      Profile_KYC_select_doc_Drive:
        "Водительские права с номером удостоверения личности",
      Profile_KYC_Upload_title: "Пожалуйста, загрузите ваши документы:",
      Profile_KYC_Front: "Передняя сторона документа",
      Profile_KYC_Back: "Задняя сторона документа",
      Profile_KYC_Selfie: "Селфи с документом в руке",
      Profile_KYC_Approved: "Ваш KYC уже подтвержден.",
      Profile_KYC_Pending: "Ваш KYC находится на рассмотрении.",
      Profile_Currency: "Валюта",
      Profile_Type: "Тип",
      Profile_Login: "Логин",
      Profile_Region: "Регион",
      Profile_Login_device: "Устройство входа",
      Reg_verify_email: "Подтвердите email",
      Reg_enter_otp: "Введите OTP",
      Reg_Confirm_Password: "Подтвердите пароль",
      Reg_Referral_Code: "Реферальный код (необязательно)",

      // Terms Of Use
      Term_1_title: "1-Welcome to Easy Pro",
      Term_1_description_1:
        "Thanks indeed for visiting (Easyexchangepro / we, hereinafter referred to as the Easy pro) By visiting, accessing, or using easyexchangepro.com and associated application program interface or mobile applications (platforms), You (“User” or “You”) consent to the Terms of Service (the “Terms”), so please read them carefully.",
      Term_1_description_2:
        "The Terms constitute the agreement and understanding regarding the use of any or all the Services, and any manner of accessing them, between You and the following service provider (“we,” “us” or “our” platform) Easyexchangepro.",
      Term_2_title: "2-Key Definitions",
      Term_2_description_1:
        "Capitalized terms not otherwise defined in these Terms will have the following meaning:",
      Term_2_description_2:
        "“External Account” means any Digital Token Account: from which you may transfer funds into your Easy Pro  Account, or to which you may transfer funds from your Easy Pro Account and which has been approved by Easy pro for the foregoing purposes.",
      Term_2_description_3: "“Funds” means Digital Tokens.",
      Term_2_description_4: "“Fiat Currency” means any national currency.",
      Term_2_description_5:
        "“Easy Pro Account” means a user account accessible via the Services where Funds may be stored by Easy pro on behalf of a user.",
      Term_2_description_6:
        "“Easy Pro Help Center” means Easy pro’s customer service center with address at Support@easyexchagepro.com -us where you could get online support from Easy pro.",
      Term_2_description_7:
        "“Digital Tokens” means Bitcoins, Ripples, and other digital mediums of exchange that may be purchased, sold, or traded via the Services.",
      Term_2_description_8:
        "“Digital Token Account” means any Digital Token address or account owned or operated by you that is maintained outside of the Services, and is not owned, controlled, or operated by Easyexchangepro.",
      Term_3_title: "3- Accept terms",
      Term_3_description_1:
        "You should read the Terms, and any document referred to in them very carefully. If there is anything that You do not understand in the Terms and any document referred to herein, you should discuss this matter with us and seek the necessary clarification.",
      Term_3_description_2:
        "You are at least 18 years of age and have the full capacity to accept these Terms and enter a transaction involving digital assets. You have not been deprived of the right to use our service and have the full capacity for legal action. If you do not meet the above condition, please do not register our Site or Platform, otherwise, the platforms may suspend or terminate your account at any time.",
      Term_3_description_3:
        "You’re entering and performing these Terms are not prohibited by the laws and regulations of the country or region to which you belong, reside, pay tax or carry out business activities or other business. If you do not meet the above conditions, you should immediately terminate the registration or stop using our Platforms services.",
      Term_3_description_4:
        "Our Platform has the right to modify these Terms from time to time or to formulate and modify various specific rules according to these Terms and publish them in the relevant system sections of Platforms without separately notifying you.",
      Term_3_description_5:
        "You should pay attention to the changes in these Terms and the specific rules from time to time. If you continue to use the Service after the changes in the content of these Terms and the specific rules, you are deemed to have fully read, understood, and accepted the amended Terms and the specific rules and to use the services of our Platforms in accordance therewith.",
      Term_3_description_6:
        "You also agree that Easy Pro may, by giving notice, at its sole discretion immediately terminate your access to our Platform and to your account. You agree and acknowledge our right to limit, suspend or terminate the service and your user account, prohibit your access to our Platform and their content, services, and tools, delay or remove hosted content, and take technical and legal steps to keep you off our Platform or any further steps to recover any loss or damages caused if we determine at our sole discretion that you are or may be in breach of any laws, regulations, the rights of third parties, or any of these Terms or Platform’s policies. Easy Pro shall not be liable for any loss or damage caused to you or any third party because of exercising our rights under this clause.",
      Term_4_title: "4- Our Trading Services",
      Term_4_description:
        "Easy Pro provides an online platform with a simple and convenient way to trade one type of digital asset or another type of digital asset. Easy Pro provides you with a platform that matches your orders with open orders from other users of our Services.",
      Term_4_subheader_1: "Spot.",
      Term_4_subtitle_1: "Market Order:",
      Term_4_description_1:
        "A Market Order is placed to buy or sell a Specified Quantity or a Specific Gross Amount of Targeted tokens at the best available Latest Market Price.",
      Term_4_subtitle_2: "Limit Order:",
      Term_4_description_2:
        "A Limit Order is placed to buy or sell a Specific Quantity of the Targeted Token at a Specific Price and shall be filled at such Specific Quantity and Specific Price.",
      Term_4_subtitle_3: "Stop Market Order:",
      Term_4_description_3:
        "A Stop Market Order is placed to buy a Specific Quantity of Targeted Token or to buy a Specific Gross Amount of Targeted Token at the Latest Market Price when the upper limit of the Preset Stop Price is triggered. This means that when the Latest Market Price reach or beyond the upper limit of the Preset Stop Price, this order will be executed and filled at the Latest Market Price which may end up higher than the upper limit of the Preset Stop Price.",
      Term_4_subtitle_4: "Stop Limit Order:",
      Term_4_description_4:
        "A Stop Limit Order is placed to sell a Specific Quantity of Targeted Token at the Specific Price when the inferior limit of Preset Stop Price is triggered. This means that when the Latest Market Price reaches or is below the inferior limit of the Preset Stop Price, this order will be executed and filled at the Specific Quantity as well as the Specific Price.",
      Term_4_subheader_2: "Easy card",
      Term_4_description_5:
        "Refers to transactions in which Gabur tokens are exchanged for fiat currency and deposited in the account of the user.",
      Term_5_title: "5- Scope of Service and Your Rights and Obligations",
      Term_5_description_1:
        "Users shall not register multiple accounts for any purpose. Users may not use another User’s accounts. Users are prohibited in any form from utilizing the Site to engage in illegal activities. Without the authorization or permission of Easy Pro, the User shall not use any of the commercial activities in the name of this Site or in any form of the Site as a place, platform, or medium for engaging in commercial activities.",
      Term_5_description_2:
        "Users shall comply with all laws and regulations and bear the responsibility and legal consequences of any of their own actions involving the Site and Services.",
      Term_5_description_3:
        "Users have the right to view the information regarding their accounts on our Platform and have the right to operate the functions provided by our Platform. The user understands and agrees that our Platform can adjust the service content, service type, and service form on the Platform at any time according to the actual situation at our sole discretion. Our Platform is not responsible for any negative impact or loss caused to you or any third party due to Platform’s adjustments.",
      Term_5_description_4:
        "Depending on your country of residence, you may not be able to use all the functions of the Site or the Platform. Without prejudice to our right of suspending certain services to users from a specific country, it is your responsibility to ensure that in accessing our Site, Platform, and services, you are complying with the laws and regulations in your country of residence and/or the country from which you access this Site, the Platform, and our services. you are responsible for any disputes or losses caused by your failure to promptly modify or confirm the status of the transaction or failure to submit the relevant application. Our Platforms do not assume any responsibility.",
      Term_5_description_5:
        "Engaging in trades in cryptographic tokens may be highly risky. Please do not use Easy Pro’s services for trading in Digital assets if you do not understand these risks.",
      Term_6_title: "6- Registration Process",
      Term_6_description_1:
        "You agree to provide a valid email address, a mobile phone number, and other information in accordance with the requirements on the user registration page of this platform. You can use the email address, mobile phone number or any other manner permitted to log in to this platform. Where it is necessary and in accordance with the requirements of applicable laws and regulations of relevant jurisdictions, you shall provide your real name, identity card, and other information required by applicable laws, regulations, the Terms of Privacy, and anti-money-laundering terms, and constantly update your registration data so that they will be timely, detailed, and accurate as is required. All the original typed data will be referenced as registration information. You shall be responsible for the authenticity, integrity, and accuracy of such information and bear any direct or indirect loss and adverse consequences arising out of it.",
      Term_6_description_2:
        "If any of the applicable laws, regulations, rules, orders, and other regulatory documents of the sovereign country or region in which you are based requires that mobile phone accounts must be based on real names, you hereby confirm that the mobile phone number you provide for registration purposes has gone through the real-name registration procedure. If you cannot provide such a mobile phone number as is required, any direct or indirect losses and adverse consequences arising therefrom and affecting you shall be borne by you.",
      Term_6_description_3:
        "After you provide the required registration information in a legal, complete, and valid manner and such information passes relevant verification, you shall have the right to obtain an account and a password of Easy exchange pro. Upon obtaining such an account and password, your registration shall be deemed as successful, and you can log into the platform as a member thereof. You agree to receive emails and/or short messages sent by the platform related to the management and operation thereof.",
      Term_6_description_4:
        "Particularly during periods of high volume, illiquidity, fast movement, or volatility in the marketplace for any, or one or more Digital Tokens, the actual market rate at which a trade is executed may be different from the prevailing rate indicated via the Services at the time of your order or trade. You understand that we are not liable for any such price fluctuations. In the event of a market disruption or Force Majeure event, Easy exchange pro may do one or more of the following: suspend access to the Services; or prevent you from completing any actions via the Services, including closing any open positions. Following any such event, when trading resumes, you acknowledge that prevailing market rates may differ significantly from the rates available prior to such an event.",
      Term_6_description_5:
        "You hereby authorize Easy exchange pro to attempt and/or perform any such correction, reversal, cancellation, voiding, or amendment described in this section. Easy exchange pro. provides no guarantee or warranty that any such attempt will be successful and will have no responsibility or liability for the Manifest Error or the making or failure of any correction attempt, except if caused by the gross negligence, willful default, or fraud of Easy exchange pro. There is a risk that you may be prejudiced by any action or omission of Easy exchange pro in this regard, and you accept that you have no recourse whatsoever against Easy exchange pro.",
      Term_7_title: "7- Fees",
      Term_7_description_1:
        "You agree to pay for transactions through our Fees Structure service, which may change from time to time. Changes to the Fee Structure are effective as of the effective date indicated in the posting of the revised Fee Structure to the Services and will apply prospectively to any trades that take place following the effective date of such revised Fee Structure.",
      Term_7_description_2:
        "In addition to the costs, your external account may incur costs in connection with your use of your designated external account through the Services. Costs incurred by your external account provider are not reflected on the Transactions page, which contains information about applicable costs. You are solely responsible for paying any fees imposed by an External Account provider.",
      Term_7_description_3:
        "You authorize us, or our designated payment processor, to charge or deduct your Easy Pro Account Funds for any applicable Fees owed in connection with trades you complete via the Services.",
      Term_8_title: "8- Communication",
      Term_8_description_1:
        "You agree and consent to receive electronically all communications, agreements, documents, receipts, notices, and disclosures (collectively, “Communications”) that Easy Pro provides in connection with your Easy Pro Account and/or use of the Easy Pro Services.",
      Term_8_description_2:
        "Updating Contact Information. It is your responsibility to keep your email address on file with Easy Pro up to date so that Easy exchange pro can communicate with you electronically.",
      Term_9_title: "9- Intellectual Property",
      Term_9_description:
        "Unless otherwise indicated by us, all copyright and other intellectual property rights in all content and other materials contained on our website/platform or provided in connection with the Services, including, without limitation, the Easy Pro logo and all designs, text, graphics, pictures, information, data, software, sound files, other files and the selection and arrangement thereof are the proprietary property of Easy Pro or our licensors or suppliers and are protected by international copyright laws and other intellectual property rights laws.",
      Term_10_title: "10- Unclaimed or Disputed Property",
      Term_10_description_1:
        "If for any reason Easy Pro is holding Funds in your Easy  Account, such Funds remain unclaimed, and Easy Pro is unable to return your Funds to your designated External Account after a period of three years after your last login to Easy Pro or any specified period notified by Easy Pro, and Easy Pro determines in good faith that it is not able to trace you, you agree that all such Funds shall be deemed to have been abandoned by you in favor of Easy Pro and may be appropriated by Easy Pro to and for itself, and you thereafter shall have no right to claim such Funds.",
      Term_10_description_2:
        "If Easy Pro receives notice that any Digital Tokens held in your Easy Pro Account are alleged to have been stolen or otherwise are not lawfully possessed by you, Easy Pro may, but has no obligation to, place an administrative hold on or freeze the affected Digital Tokens or your Easy Pro Account. If Easy Pro does place an administrative hold on or freeze some or all your Digital Tokens, Easy Pro may continue such hold until such time as the dispute has been resolved and evidence of the resolution acceptable to Easy Pro has been provided to Easy Pro in a form acceptable to Easy Pro. Easy Pro will not involve itself in any such dispute or the resolution of the dispute. You agree that Easy Pro will have no liability or responsibility for any losses, damages, or prejudice arising from or in connection with such hold or freeze, or for your inability to withdraw Digital Tokens or execute trades during the period of any such hold or freeze.",
      Term_11_title: "11- Complains about your local laws",
      Term_11_description:
        "It is your sole responsibility to determine whether, and to what extent, any taxes apply to any transactions You conduct through the Services, and to withhold, collect, report, and remit the correct amounts of taxes to the appropriate tax authorities. When using our Services, you acknowledge that your actions are in a legal and proper manner and your financial sources are not from illegal activities. We may discretionarily or in coordination with local law enforcement authorities seize, restrict or close out your account, fiat, and/or cryptocurrencies.",
      Term_12_title: "12- Suspension; Termination",
      Term_12_description_1:
        "In the event of any Force Majeure Event breach of these. Terms or Conditions, or any other event where the provision of services to Easy pro is commercially unreasonable, we may, at our sole discretion and without liability to you, with or without prior notice, grant you access to all or part of Suspend it. We may also immediately, without notice, terminate your access to the Services at our sole discretion and delete or deactivate your Easy exchange pro account and all related information and files on this account without liability to you, including in cases where you are violating one of these conditions. In the event of discontinuation of all Services or termination of your access to the Services or deletion or deactivation of your Easy Pro Account: all amounts payable by you to Easy Pro will immediately become due. the platform can cancel any open orders or other transaction requests that are pending at the time of discontinuation or termination or deletion or deactivation. the platform will, where possible, return any Funds stored in your Easy Exchange pro Account not otherwise owed to Easy Pro and/or will use commercially reasonable efforts to provide you with a period 4 months to transfer affected Digital Tokens from your Easy Pro Account, unless prohibited by applicable laws or regulations or by order of law enforcement or governmental authority, or Easy Pro believes you have committed fraud, negligence or other misconduct; and/or may take such other steps as Easy Pro deems necessary or desirable to protect its own interests. We are not and shall not be responsible or liable for any loss or damages incurred because of or arising from any actions taken under this section.",
      Term_12_description_2:
        "Also, if the user has not made any deposits, transactions, or logins for a period of three months by any reason, we may delete or deactivate the user's account, without notifying the User and without assuming any responsibility",
      Term_13_title: "13- Descramble of liability",
      Term_13_subtitle_1: "13.1 Network Service Provider:",
      Term_13_description_1:
        'as a third-party platform for "network service provider", does not guarantee the information and services provided on this Site can fully meet the needs of users. We shall not be liable for errors, insults, defamation, nonfeasance, obscenity, pornography, or blasphemy that may occur during the process of acceptance of the Parties’ web service.',
      Term_13_subtitle_2: "13. 2 Service Interruption:",
      Term_13_description_2:
        "Based on the special nature of the Internet, we do not guarantee that the service will not be interrupted; the timeliness and security of the service are also not guaranteed.",
      Term_13_subtitle_3: "13.3 Safety of the Network:",
      Term_13_description_3:
        "We try to provide a safe network environment to the users; however, we do not guarantee that the Site or its servers are free of viruses or other potentially harmful factors; therefore, the user should use the industry’s recognized software to check and kill any virus in the files downloaded from the Site.",
      Term_13_subtitle_4: "13. 4 User Information:",
      Term_13_description_4:
        "we are not responsible for the failure of preservation, modification, deletion, or storage of the information provided by the user. Nor will we be liable for the typographical errors, negligence, etc. We have the right but no obligation to improve or correct any omission, error of any part of this Site.",
      Term_13_subtitle_5:
        "13. 5 Accuracy, Completeness, and Reliability of the Site:",
      Term_13_description_5:
        "Unless we have expressly agreed in writing, we shall not guarantee the accuracy, completeness, reliability of any content, such as but not limited to, advertising from the platform in any manner (including but not limited to, containing, connecting, by way of, or downloading) from the Site; we are not responsible for any products, services, information or materials purchased or obtained by the user according to the content information on this website. The user bears the risk of using the content of this website.",
      Term_13_subtitle_6: "13. 6 User Opinion:",
      Term_13_description_6:
        "The user comments published by users of the Site, are only on behalf of the user’s personal point of view. It does not mean that this Site agrees with their views or confirms their description. This Site does not bear any legal responsibility caused by any user comments.",
      Term_13_subtitle_7: "13. 7 Announcements:",
      Term_13_description_7:
        "Regarding notices issued to the users, we will deliver these notices through a formal page announcement, station letter, e-mail, customer service phone call, SMS, or regular mail delivery. We do not bear any legal responsibility for any winning, discount activities or information that are delivered by other channels.",
      Term_13_subtitle_8:
        "13. 8 Charges, Commissions, Transactions, and Other Fees:",
      Term_13_description_8:
        "we have the right to adjust the recharge, commission, transaction, and other fees according to market conditions, and terminate any promotional efforts early.",
      Term_14_title: "14- Jurisdiction",
      Term_14_description:
        "These Terms shall be governed by and construed in accordance with the laws of Dubai. The courts of Dubai shall have exclusive jurisdiction to settle any dispute arising from or connected with these Terms (including a dispute relating to the existence, validity, or termination of these Terms or the consequences of their nullity or any non-contractual obligation arising out of or in connection with these Terms).",
      Term_15_title: "15. Miscellaneous",
      Term_15_description_1:
        "These Terms set forth the complete understanding of the parties with respect to the subject matter hereof and supersedes all prior understandings and communications relating thereto our Platforms reserve the right of final interpretation of these Terms within the scope permitted by law. These Terms and related pages of our Platforms may refer to each other. If there is any conflict, these Terms shall prevail. In addition, if some of the provisions in these Terms are deemed invalid or unenforceable, the other provisions in these Terms will remain in effect. In the event of any conflict between these Terms and any other agreement, you may have with Easy Exchange pro the terms of that other agreement will prevail only if these Terms are specifically identified and declared to be overridden by such other agreement.",
      Term_15_description_2:
        "Any failure or delay by Easy Exchange Pro to enforce any of these Terms or to exercise any right hereunder shall not be construed as a waiver to any extent of our rights.",
      Term_16_title: "16- Limitation of Liability",
      Term_16_description:
        "Except as otherwise required by law, in no event shall Easy exchange pro, our directors, officers, employees, agents, Brokers or contractors be liable for any special, indirect, or consequential damages, or any other damages of any kind, including but not limited to loss of use, loss of profits or loss of data, whether in an action in contract, tort (including but not limited to negligence) or otherwise, arising out of or in any way connected with the use of or inability to use our Services or the Easy exchange pro Materials, including without limitation any damages caused by or resulting from reliance by any user on any information obtained from Easy exchange pro , or that result from mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses, delays in operation or transmission or any failure of performance, whether or not resulting from a force majeure event, communications failure, theft of, destruction of or unauthorized access to Easy exchange pro’s records, programs or services. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the limitations of this section may not apply to you.",
      Term_17_title: "17- Miscellany",
      Term_17_subtitle_1: "17 -1 Agency",
      Term_17_description_1:
        "Nothing in this Agreement shall be deemed to have created, implied, or otherwise treated us as your agent, trustee, or another representative unless it is provided otherwise in this Agreement.",
      Term_17_subtitle_2: "17- 2 Waiver",
      Term_17_description_2:
        "Our or your waiver of the right to hold the other party liable for breaches of agreement or any other liability as is agreed upon in this Agreement shall not be construed or deemed as a waiver of the right to hold the other party for other breaches of contract; a failure to exercise any right or remedy shall not be construed in any way as a waiver of such right or remedy.",
      Term_17_subtitle_3: "17- 3 Goodwill",
      Term_17_description_3:
        "You acknowledge and agree that, before filing any dispute or claim, you will contact us through support@easyexchangepro.com to solve the dispute in an informal way where we will try to solve the relevant dispute internally as soon as possible; The Parties agree to negotiate and solve any dispute in good faith (such negotiation shall be confidential and protected under applicable rules, and will not be used as evidence in any proceeding).",
      Term_17_subtitle_4: "17- 4 Assignment of rights",
      Term_17_description_4:
        "The rights and obligations agreed in this Agreement shall be equally binding on the assignees, the heirs, executors, and administrators of the parties hereto who benefit from the rights and obligations. Without our consent, you may not transfer to any third party any of your rights or obligations hereunder, provided, however, we may, at any time, assign our rights and obligations under this Agreement to any third party with forty (40) days' notices to you.",
      Term_17_subtitle_5: "17- 5 Severability",
      Term_17_description_5:
        "The invalidity or unenforceability of any of these Terms shall not affect the validity or enforceability of any other of these Terms, all of which shall remain in full force and effect.",
      Term_17_subtitle_6: "17- 6 Governing language and translations",
      Term_17_description_6:
        "These Terms, the Privacy Policy, and other agreements or communications notified through the Services have been drafted in English. Although translations in other languages of any of the foregoing documents may be available, such translations may not be up to date or complete. Accordingly, you agree that in the event of any conflict between the English language version of the foregoing documents and any other translations thereof, the English language version of such documents shall govern and prevail.",
      // Token Listing
      TL_title: "Заполните форму для листинга вашего токена",
      TL_Exchange_info: "Информация о бирже",
      TL_Token_Name: "Название токена",
      TL_Token_Name_Error: "Пожалуйста, введите название токена.",
      TL_Token_symbol: "Символ токена",
      TL_Token_symbol_Error: "Пожалуйста, введите символ токена.",
      TL_Token_Symbol_upload_Error: "Пожалуйста, загрузите символ токена.",
      TL_NFT: "NFT",
      TL_Total_supply_Error: "Пожалуйста, введите общее предложение.",
      TL_Date_of_issue: "Дата выпуска",
      TL_Date_of_issue_Error: "Пожалуйста, введите дату выпуска.",
      TL_Total_circulation: "Общий объем обращения",
      TL_Total_circulation_Error: "Пожалуйста, введите общий объем обращения.",
      TL_Market_cap_circulation: "Капитализация рынка обращения",
      TL_Market_cap_circulation_Error:
        "Пожалуйста, введите капитализацию рынка обращения.",
      TL_Source_code: "Исходный код",
      TL_Source_code_Error: "Пожалуйста, введите исходный код.",
      TL_Whitepaper: "Белая книга",
      TL_Whitepaper_Error: "Пожалуйста, загрузите белую книгу.",
      TL_Communities: "Сообщества",
      TL_Telegram: "Telegram",
      TL_Telegram_Error:
        "Пожалуйста, введите ваше имя пользователя в Telegram.",
      TL_Twitter: "Twitter",
      TL_Twitter_Error: "Пожалуйста, введите ваше имя пользователя в Twitter.",
      TL_Facebook: "Facebook",
      TL_Facebook_Error:
        "Пожалуйста, введите ваше имя пользователя в Facebook.",
      TL_Reddit: "Reddit",
      TL_Reddit_Error: "Пожалуйста, введите ваше имя пользователя в Reddit.",
      TL_Discord: "Discord",
      TL_Discord_Error: "Пожалуйста, введите ваше имя пользователя в Discord.",
      TL_Submitter_info: "Информация о отправителе",
      TL_Role: "Роль",
      TL_Role_Error: "Пожалуйста, введите вашу роль.",
      TL_Personal_ID: "Личный идентификатор",
      TL_Personal_ID_Error: "Пожалуйста, загрузите ваш личный идентификатор.",
      TL_About_project: "О проекте",
      TL_About_project_Error: "Пожалуйста, введите информацию о проекте.",
      TL_About_Private: "О частной продаже, ICO/IEO/IDO",
      TL_About_Private_Error:
        "Пожалуйста, введите информацию о частной продаже, ICO/IEO/IDO.",
      TL_Distribution_plan: "План распределения",
      TL_Distribution_plan_Error: "Пожалуйста, введите план распределения.",
      TL_CEO_info: "Информация о CEO/резюме на Linkedin",
      TL_CEO_info_Error:
        "Пожалуйста, введите информацию о CEO/резюме на Linkedin.",
      TL_Policy:
        "Отправляя вашу форму, вы принимаете нашу Политику конфиденциальности.",
      // Deposit
      Deposit_select_Token: "Пожалуйста, выберите токен:",
      Deposit_select_Network: "Пожалуйста, выберите сеть:",
      Deposit_Address: "Адрес",
      Deposit_attention:
        "Пожалуйста, обратите внимание на адрес для вывода и выбранную сеть.",
      Deposit_Network: "Сеть",
      Deposit_Warning:
        "Если вы отправите депозит на неправильный адрес или выберете неправильную сеть, вы потеряете свои активы.",
      // Transfer
      Transfer_Transfer: "Перевод",
      Transfer_select_Error: "Пожалуйста, выберите токен.",
      Transfer_Balance_Error: "Недостаточный баланс.",
      Transfer_Main_to_Trade: "Основной на Торговый",
      Transfer_Trade_to_Main: "Торговый на Основной",
      // Withdraw
      Withdraw_balance_Error: "Недостаточный баланс.",
      Withdraw_Amount_low_Error: "Сумма вывода не может быть меньше ",
      Withdraw_Amount_high_Error: "Сумма вывода не может быть больше ",
      Withdraw_2FA_Error_1: "Вам необходимо активировать",
      Withdraw_2FA_Error_2: "двухфакторную аутентификацию (2FA)",
      Withdraw_2FA_Error_3: "для вывода.",
      Withdraw_KYC_Error_1: "Вам необходимо пройти",
      Withdraw_KYC_Error_2: "процесс KYC",
      Withdraw_KYC_Error_3: "для вывода.",
    },
  },
  tr: {
    translation: {
      ///////////////////// GLOBAL
      Already_Purchased: "Zaten satın alındı",
      Global_Products: "Ürünler",
      Global_Transaction: "işlem",
      Global_Icon: "Simge",
      Global_Pair: "Çift",
      Global_LastPrice: "Son Fiyat",
      Global_Price: "Fiyat",
      Global_24h: "24s",
      Global_High: "Yüksek",
      Global_Low: "Düşük",
      Global_Change: "Değişim",
      Global_Volume: "Hacim",
      Global_Trade: "İşlem",
      Global_EasyGuarantee: "Kolay Garanti",
      Global_ApplicationForm: "Başvuru Formu",
      Global_EasyCard: "Kolay Kart",
      Global_Secure: "Güvenli",
      Global_Fast: "Hızlı",
      Global_Convert: "Dönüştür",
      Global_Gabur: "Gabur",
      Global_Token: "Token",
      Global_Total: "Toplam",
      Global_Amount: "Miktar",
      Global_USDT: "USDT",
      Global_Stop: "Dur",
      Global_Limit: "Limit",
      Global_Supply: "Arz",
      Global_Whitepaper: "Beyaz Kağıt",
      Global_Circulation: "Dolaşım",
      Global_Website: "Web sitesi",
      Global_Buy: "Satın Al",
      Global_Sell: "Sat",
      Global_Loading: "Yükleniyor...",
      Global_Submit: "Gönder",
      Global_Cancel: "İptal",
      Global_Confirm: "Onayla",
      Global_Close: "Kapat",
      Global_OTP: "OTP",
      Global_Email: "E-posta",
      Global_2FA: "2FA",
      Global_Password: "Şifre",
      Global_FName: "Ad",
      Global_LName: "Soyad",
      Global_IDNum: "Kimlik Numarası",
      Global_Login: "Giriş",
      Global_Register: "Kayıt Ol",
      Global_Transfer: "Transfer",
      Global_UID: "Kullanıcı ID",
      Global_Purpose: "Amaç",
      Global_Mobile: "Mobil",
      Global_Deposit: "Yatırım",
      Global_Withdraw: "Çekim",
      Global_Fee: "Komisyon",
      Global_Fees: "Komisyonlar",
      Global_OTC: "OTC",
      Global_Support: "Destek",
      Global_Download: "İndir",
      Global_From: "Kimden",
      Global_To: "Kime",
      Global_Now: "Şimdi",
      Global_Digital: "Dijital",
      Global_crypto: "kripto",
      Global_cash: "nakit",
      Global_wallet: "cüzdan",
      Global_account: "hesap",
      Global_Exchange: "Borsa",
      Global_trading: "ticaret",
      Global_Accept_Terms:
        "Kutuyu işaretleyerek Şartlar ve Koşullarımızı kabul edeceksiniz.",
      Global_Available: "Mevcut",
      Global_Username: "Kullanıcı Adı",
      Global_Time: "Zaman",
      Global_Status: "Durum",
      Global_Network_Address: "Ağ Adresi",
      Global_Max_upload: "Her Dosya İçin Maksimum Yükleme Boyutu 10 MB",
      Global_Policy:
        "Formunuzu Göndererek Gizlilik Politikamızı Kabul Edeceksiniz",
      Global_UID_Error: "Lütfen Kullanıcı Kimliği girin.",
      Global_IDNum_Error: "Lütfen Kimlik Numarası girin.",
      Global_Mobile_Error: "Lütfen Mobil Numara girin.",
      Global_Price_Error: "Lütfen Fiyat girin.",
      Global_Stop_Error: "Lütfen Duraklama Fiyatı girin.",
      Global_Amount_Error: "Lütfen Miktar girin.",
      Global_Total_Error: "Lütfen Toplam girin.",
      Global_Email_Error: "Lütfen E-posta girin.",
      Global_Password_Error: "Lütfen Şifre girin.",
      Global_FName_Error: "Lütfen Adınızı girin.",
      Global_LName_Error: "Lütfen Soyadınızı girin.",
      Global_Website_Error: "Lütfen Web Sitesi girin.",
      Global_Purpose_Error: "Lütfen Talep Amacını açıklayın.",
      Global_Accept_Terms_Error: "Göndermeden önce kabul etmelisiniz.",
      Global_about: 'Hakkımızda',
      Global_coin_info: "Madeni para bilgisi",
      Global_token_listing: "Jeton listeleme",
      Global_logout: "Çıkış Yap",
      // Home
      //// Top
      Home_Top_Download_From: "Şuradan indir",
      Home_Top_Playstore: "Playstore",
      Home_Top_Download_APK: "APK İndir",
      Home_Top_APPstore: "APPstore",
      Home_Top_Register_Now: "Şimdi Kaydol",
      //// carousel
      Home_Carousel_1_title: "Şeffaflık ve Güvenlik",
      Home_Carousel_1_description:
        "Gabur Token hakkında karar vermeniz için Beyaz Kağıtta şeffaf ayrıntılar paylaştık. Ayrıca, Gabur token akıllı sözleşmesi en üst düzeyde güvenlik sağlamak için denetlenmiştir.",
      Home_Carousel_2_title: "Kolay Garanti",
      Home_Carousel_2_description:
        "Dijital dünyada ilk kez Easy Exchange Pro, şirketler arasında ve iş sözleşmeleri için Kolay Garanti sistemi sunmaktadır.",
      Home_Carousel_3_title: "7/24 Destek",
      Home_Carousel_3_description:
        "Destek ekibiyle ticket sistemi aracılığıyla iletişime geçebilir veya support@easyexchangepro.com adresine e-posta gönderebilirsiniz.",
      //// Easy Guarantee
      Home_EG_subtitle: "Kripto Dünyasında İlk Dijital Garanti",
      Home_EG_description:
        "Dijital dünyada ilk kez Easy Exchange Pro, şirketler arasında ve iş sözleşmeleri için Kolay Garanti sistemi sunmaktadır.",
      Home_EG_more_info_btn: "Daha Fazla Bilgi",
      //// Easy Card
      Home_EC_subtitle_1: "Nakit olarak",
      Home_EC_subtitle_2: "kripto satın almanın tek yolu",
      Home_EC_description_1:
        "Yakınınızdaki Easy Card ön ödemeli kodunu alın ve",
      Home_EC_description_2: "Gabur Token'ları cüzdanınıza yatırın. Banka veya",
      Home_EC_description_3:
        "kredi kartı olmadan güvenli ve güvenilir bir şekilde.",
      Home_EC_Feature_1_title: "Bağımsız",
      Home_EC_Feature_1_description_1:
        "Bir banka hesabı veya kredi kartı olmadan",
      Home_EC_Feature_1_description_2: "kripto satın alın",
      Home_EC_Feature_2_description_1: "Tüm varlıklarınız kendi",
      Home_EC_Feature_2_description_2: "kontrolünüz altında",
      Home_EC_Feature_3_description_1: "Saniyeler içinde nakit para yatırma",
      Home_EC_Feature_3_description_2: "işlemi",
      Home_EC_Feature_4_title: "Kolay",
      Home_EC_Feature_4_description_1: "Her zaman yaptığınızı",
      Home_EC_Feature_4_description_2: "yapıyorsunuz",
      Home_EC_Feature_5_title: "Yatırım Ücreti Yok",
      Home_EC_Feature_5_description_1: "Hiçbir maliyet olmadan",
      Home_EC_Feature_5_description_2: "yatırım yapın",
      Home_EC_Feature_6_description_1: "Gabur token'ı diğer para birimlerine",
      Home_EC_Feature_6_description_2: "dönüştürebilirsiniz.",
      Home_EC_Where_to_buy: "Nereden satın alınır",
      //// Mobile Section
      Home_MS_title:
        "Kolayca işlem yapmaya başlayın, Easy Exchange Pro mobil uygulaması ile.",
      Home_MS_list_1: "Varlıklarınıza her yerden 24/7 erişim",
      Home_MS_list_2: "Güvenli ve Korunaklı",
      Home_MS_list_3: "Kullanıcı Dostu Arayüz",
      Home_MS_list_4: "Gelişmiş İşlem Araçları",
      Home_MS_list_5: "Hepsi Bir Arada Uygulama",
      Home_MS_dl_for_ios: "iOS İçin İndir",
      Home_MS_dl_for_android: "Android İçin İndir",
      //about
      //// A Top video
      About_top_title: "Misyonumuz",
      About_top_desciption:
        "Misyonumuz, herkese dijital para birimlerine kolayca erişme hakkı tanıyan bir ekosistem oluşturmaktır, böylece finansal özgürlüğü sağlamaktır.",
      About_top_Feature_1: "600,000",
      About_top_Feature_1_description_1:
        "kayıtlı kullanıcılar",
      About_top_Feature_1_description_2: " ",
      About_top_Feature_2: "6 Konum",
      About_top_Feature_2_description: "6 ülkedeki konumlarımız",
      About_top_Feature_3: "+100 Ülke",
      About_top_Feature_3_description: "100'den fazla ülkede hizmetlerimiz",
      ///// A Second
      About_second_title: "Neden Easy Exchange Pro'yu seçmelisiniz?",
      About_second_subtitle:
        "Yeni başlayanlar için kolay, profesyoneller için ileri düzey",
      About_second_description_1:
        "Kripto para dünyası biraz karmaşık olabilir, ancak buradayız! Platformumuz işlem yapmayı kolaylaştırır.",
      About_second_description_2:
        "En popüler kripto para birimlerini tek bir yerde satın alıp satabilmeniz için size araçlar sunuyoruz ve sektör lideri güvenlikle korunuyorsunuz.",
      About_second_Feature_1_description_1:
        "Şifreleme teknolojisine dayanan güvenlik lideri",
      About_second_Feature_1_description_2: "temelli koruma",
      About_second_Feature_2_title: "Kullanımı Kolay",
      About_second_Feature_2_description_1:
        "Basit ve kolay bir platform tasarladık",
      About_second_Feature_2_description_2: "ve size sunuyoruz",
      About_second_Feature_3_title:
        "İleri Düzey Kullanım (profesyoneller için)",
      About_second_Feature_3_description_1:
        "Kullanıcılarımıza profesyonel deneyim",
      About_second_Feature_3_description_2:
        "sunan gelişmiş bir işlem arayüzü geliştirdik",
      About_second_Feature_4_description_1: "Favori para birimlerinizi",
      About_second_Feature_4_description_2: "bir an içinde alışveriş yapın",
      About_second_Feature_5_title: "Nakit ile Satın Al",

      About_second_Feature_5_description_1:
        "Kripto para birimi satın almanın en kolay yolu",
      About_second_Feature_5_description_2: "yalnızca Easy Card'a özgüdür",
      About_second_Feature_6_title: "24/7",
      About_second_Feature_6_description_1:
        "Kripto para birimleriyle işlem yapmaya başlayın ve her zaman desteğimizi alın.",
      About_second_Feature_6_description_2: "",
      //// Road Map
      About_road_title: "Yol Haritası",
      About_road_description_1:
        "Easy Exchange Pro'nun geliştirilmesi üç yıl boyunca organize edilmiştir.",
      About_road_description_2:
        "Burada, gelecek yıllarda gerçekleştirilmesi planlanan hedeflerimizin bir genel bakışını bulacaksınız.",
      About_road_year_1: "2022",
      About_road_year_1_description_1: "Benzersiz ürün: Easy Guarantee",
      About_road_year_1_description_2: "+ 6 dil",
      About_road_year_1_description_3: "+ 5 ülkede 10 aracı kurum",
      About_road_year_1_description_4: "+ 100 daha fazla çalışan",
      About_road_year_1_description_5: "+ 10 daha fazla işlem çifti",
      About_road_year_2: "2023",
      About_road_year_2_description_1: "Yeni ürün: Easy Wallet",
      About_road_year_2_description_2: "Easy Pro Academy",
      About_road_year_2_description_3: "İşletme entegrasyonu için API",
      About_road_year_2_description_4:
        "Easy Card, işlemler için etkili bir yöntem olacak",
      About_road_year_2_description_5: "+ 6 Dil",
      About_road_year_2_description_6: "+ 16 ülkede 200 Aracı Kurum",
      About_road_year_2_description_7: "+ 5 fiat para birimi",
      About_road_year_2_description_8: "+ 250 çalışan",
      About_road_year_2_description_9: "+ 30 işlem çifti",
      About_road_year_2_description_10: "+100 güvenilirlik jetonu",
      About_road_year_2_description_11:
        "GABUR'un diğer dünya standartlarında platformlarda listelenmesi",
      About_road_year_3: "2024",
      About_road_year_3_description_1: "Benzersiz ürün: Kripto Bankası",
      About_road_year_3_description_2: "+ 6 dil",
      About_road_year_3_description_3: "+ 1000 çalışan",
      About_road_year_3_description_4: "+ 100 işlem çifti",
      About_road_year_3_description_5: "+ 130 güvenilirlik jetonu",
      About_road_year_3_description_6:
        "GABUR'un diğer dünya standartlarında platformlarda listelenmesi",
      // API Documents
      Api_title: "API Belgeleri",
      Api_1: "Özet",
      Api_2: "Varlıklar",
      Api_3: "Ticker",
      Api_4: "Sipariş Defteri",
      Api_5: "İşlemler",
      Api_6: "24 Saatlik Hacim",
      // Varlık
      Asset_Tab_1: "Tüm varlıklar",
      Asset_Tab_2: "Ana cüzdan",
      Asset_Tab_3: "İşlem cüzdanı",
      Asset_Total_BTC: "BTC cinsinden toplam varlıklar",
      Asset_In_Withdraw: "Çekimde",
      Asset_In_Order: "Emirde",
      // Bize Ulaşın
      ContactUs_email_title: "Bizimle iletişime geçin:",
      ContactUs_email: "Support@easyexchangepro.com",
      ContactUs_faq_title:
        "Cevabını bulmak için SSS sayfamızı kontrol edebilirsiniz:",
      ContactUs_faq: "",
      ContactUs_ticket_title: "Ya da bir destek talebi oluşturabilirsiniz:",
      ContactUs_ticket: "",
      ContactUs_social_title: "Bizi bulun ve takip edin:",

      // Coin info
      CoinInfo_Issue_Time: "Issue Time :",
      CoinInfo_White_paper: "Whitepaper:",
      CoinInfo_Website: "Website:",
      CoinInfo_Block_Explorer: "Block Explorer:",
      CoinInfo_AAVE:
        "Aave is an open source and non-custodial protocol enabling the creation of money markets. Users can earn interest on deposits and borrow assets. Deposit interest rate and loan interest rate of both borrowers and lenders are calculated through algorithms based on the platform's borrowing volume and deposit volume, and the platform uses Chainlink's oracles to ensure the fairness of the collateral price.",
      CoinInfo_ADA:
        "Cardano is a decentralised public blockchain and cryptocurrency project and is fully open source. Cardano is developing a smart contract platform which seeks to deliver more advanced features than any protocol previously developed. It is the first blockchain platform to evolve out of a scientific philosophy and a research-first driven approach.",
      CoinInfo_Atom:
        "In a nutshell, Cosmos bills itself as a project that solves some of the “hardest problems” facing the blockchain industry. It aims to offer an antidote to “slow, expensive, unscalable and environmentally harmful” proof-of-work protocols, like those used by Bitcoin, by offering an ecosystem of connected blockchains. The project’s other goals include making blockchain technology less complex and difficult for developers thanks to a modular framework that demystifies decentralized apps. Last but not least, an Interblockchain Communication protocol makes it easier for blockchain networks to communicate with each other — preventing fragmentation in the industry. Cosmos’ origins can be dated back to 2014, when Tendermint, a core contributor to the network, was founded. In 2016, a white paper for Cosmos was published — and a token sale was held the following year. ATOM tokens are earned through a hybrid proof-of-stake algorithm, and they help to keep the Cosmos Hub, the project’s flagship blockchain, secure. This cryptocurrency also has a role in the network’s governance.",
      CoinInfo_AVAX:
        "Avalanche is a layer one blockchain that functions as a platform for decentralized applications and custom blockchain networks. It is one of Ethereum’s rivals, aiming to unseat Ethereum as the most popular blockchain for smart contracts. It aims to do so by having a higher transaction output of up to 6,500 transactions per second while not compromising scalability. This is made possible by Avalanche’s unique architecture. The Avalanche network consists of three individual blockchains: the X-Chain, C-Chain and P-Chain. Each chain has a distinct purpose, which is radically different from the approach Bitcoin and Ethereum use, namely having all nodes validate all transactions. Avalanche blockchains even use different consensus mechanisms based on their use cases. After its mainnet launch in 2020, Avalanche has worked on developing its own ecosystem of DApps and DeFi. Different Ethereum-based projects such as SushiSwap and TrueUSD have integrated with Avalanche. Furthermore, the platform is constantly working on improving interoperability between its own ecosystem and Ethereum, like through the development of bridges.",
      CoinInfo_BNB:
        "From the ICO, Binance issued its own coin called the Binance Coin, with BNB as the symbol. BNB runs natively on Binance chain. The coin was established with a total supply of 200 million. Every quarter, we will destroy BNB based on the trading volume on our crypto-to-crypto platform until we destroy 50% of all the BNB. All transactions will be on the blockchain. We eventually will destroy 100MM BNB, leaving 100MM BNB remaining. The BNB coin itself has multiple forms of utility, essentially being the underlying gas that powers the Binance Ecosystem. The current most prominent use cases include using BNB to: - Pay for trading fees on the exchange, obtaining the equivalent of a 50% discount on trades (during the first year). - Crypto.com has included support for Binance’s BNB token to its platform which includes the MCO Visa Card and mobile app.",
      CoinInfo_BTC:
        "Bitcoin is a cryptocurrency and a digital payment system invented by an unknown programmer, or a group of programmers, under the name Satoshi Nakamoto.It was released as open-source software in 2009.The system is peer-to-peer, and transactions take place between users directly, without an intermediary. These transactions are verified by network nodes and recorded in a public distributed ledger called a blockchain. Since the system works without a central repository or single administrator, bitcoin is called the first decentralized digital currency.Besides being created as a reward for mining, bitcoin can be exchanged for other currencies, products, and services in legal or black markets.As of February 2015, over 100,000 merchants and vendors accepted bitcoin as payment. According to research produced by Cambridge University in 2017, there are 2.9 to 5.8 million unique users using a cryptocurrency wallet, most of them using bitcoin.",
      CoinInfo_Cake:
        "PancakeSwap, built on Binance Smart Chain (BSC), is a decentralized exchange for swapping BEP-20 tokens. It is fast, cheap, and allows anyone to participate. Users can get FLIP (LP token) for staking and get CAKE token as reward. CAKE holders can swap CAKE for SYRUP for additional incentivized staking. PancakeSwap uses an automated market maker (AMM) model. It means that while you trade digital assets on the platform, there isn’t an order book where you’re matched with someone else. Instead, you trade against a liquidity pool. Traders deposit funds into the pool, receiving liquidity provider (or LP) tokens in return. They can use those tokens to reclaim their share, plus a portion of the trading fees.",
      CoinInfo_Doge:
        "Dogecoin is a cryptocurrency focused on actual utility as a currency. We provide fast block times and very low fees which make Dogecoin suitable for usage in micro-transactions but also as payment option for online shops. Dogecoin has been adopted as such by online retailers and can be used easily as means of consumer to consumer money transfer too.",
      CoinInfo_Dot:
        'Polkadot is a global well-known blockchain cross-chain project, which is created by Ethereum co-founder and Ethereum yellow book author Gavin Wood. It aims to solve the communication and data transmission obstacles between different blockchains and was rated as one of the "4 major projects that decide the direction of digital currency development in 2019".',
      CoinInfo_ETC:
        "Ethereum classNameic (ETC) is a hard fork of Ethereum (ETH) that launched in July 2016. Its main function is as a smart contract network, with the ability to host and support decentralized applications (DApps). Its native token is ETC. Since its launch, Ethereum classNameic has sought to differentiate itself from Ethereum, with the two networks’ technical roadmap diverging further and further from each other with time. Ethereum classNameic first set out to preserve the integrity of the existing Ethereum blockchain after a major hacking event led to the theft of 3.6 million ETH.",
      CoinInfo_ETH:
        'Ethereum (ETH) is an open-source, public, blockchain-based distributed computing platform featuring smart contract (scripting) functionality, which facilitates online contractual agreements. Ethereum also provides a cryptocurrency token called "ether", which can be transferred between accounts and used to compensate participant nodes for computations performed. "Gas", an internal transaction pricing mechanism, is used to mitigate spamand allocate resources on the network.The value token of the Ethereum blockchain is called ether. It is listed under the diminutive ETH and traded on cryptocurrency exchanges. It is also used to pay for transaction fees and computational services on the Ethereum network.',
      CoinInfo_Fil:
        "The InterPlanetary File system (IPFS) is a global, peer-to-peer distributed version of the file system that aims to supplement (or even replace) the hypertext transfer protocol (HTTP) that currently dominates the internet, by connecting all computing devices with the same file system. The principle is to replace the address based on the domain name with a content-based address, that is, the user is looking for content that is not an address but stored in a certain place. There is no need to verify the identity of the sender, but only the hash of the content. It can ensure the web to be faster, safer, more robust and more durable.Filecoin is an incentive layer on IPFS and a a decentralized storage market built on IPFS based on the token incentive model.FIL6 is the Filecoin contract issued 6 months after its launch",
      CoinInfo_GBR:
        "Gabur (GBR) is a utility token for the Easy Exchange Pro platform. The Gabur is using for the Easy Card, a system to buy crypto without cash, and also Easy Guarantee which is a system to guarantee payment between companies and their customers.",
      CoinInfo_LTC:
        "Litecoin is a peer-to-peer Internet currency that enables instant, near-zero cost payments to anyone in the world. Litecoin is an open source, global payment network that is fully decentralized without any central authorities. Mathematics secures the network and empowers individuals to control their own finances. Litecoin features faster transaction confirmation times and improved storage efficiency than the leading math-based currency. With substantial industry support, trade volume and liquidity, Litecoin is a proven medium of commerce complementary to Bitcoin",
      CoinInfo_Matic:
        "Matic Network is a layer-2 scaling solution with side-chains for off-chain computation, while ensuring asset security using the Plasma framework and a decentralized network of Proof-of-Stake (PoS) validators. It aims to offer scalable and instant blockchain transactions. By utilizing adapted version of the Plasma framework, the system is built on PoS checkpoints that are pushed to the Ethereum main-chain. As a result, it is expected to allow each Matic sidechain to theoretically achieve 2^16 transactions per block. Matic Network has been a significant contributor to the Ethereum ecosystem, having worked on implementations of Plasma MVP (Minimum Viable Plasma), the WalletConnect protocol, and Dagger, a popular Ethereum event notification engine. Many projects have already been building applications on and integrating with Matic. MATIC is an ERC-20 token running on Ethereum, it used to pay for services on the Matic Network but also serves as a settlement currency between users who operate within the Matic ecosystem.",
      CoinInfo_MKR:
        "Maker (MKR) is the governance token of the MakerDAO and Maker Protocol — respectively a decentralized organization and a software platform, both based on the Ethereum blockchain — that allows users to issue and manage the DAI stablecoin. Initially conceived in 2015 and fully launched in December 2017, Maker is a project whose task is to operate DAI, a community-managed decentralized cryptocurrency with a stable value soft-pegged to the US dollar. MKR tokens act as a kind of voting share for the organization that manages DAI; while they do not pay dividends to their holders, they do give the holders voting rights over the development of Maker Protocol and are expected to appreciate in value in accordance with the success of DAI itself. The Maker ecosystem is one of the earliest projects on the decentralized finance (DeFi) scene: the industry that seeks to build decentralized financial products on top of smart-contract-enabled blockchains, such as Ethereum.",
      CoinInfo_Near:
        "NEAR Protocol is a layer-one blockchain that was designed as a community-run cloud computing platform and that eliminates some of the limitations that have been bogging competing blockchains, such as low transaction speeds, low throughput and poor interoperability. This provides the ideal environment for DApps and creates a developer and user-friendly platform. For instance, NEAR uses human-readable account names, unlike the cryptographic wallet addresses common to Ethereum. NEAR also introduces unique solutions to scaling problems and has its own consensus mechanism called “Doomslug.” NEAR Protocol is being built by the NEAR Collective, its community that is updating the initial code and releasing updates to the ecosystem. Its declared goal is to build a platform that is “secure enough to manage high value assets like money or identity and performant enough to make them useful for everyday people.” Flux, a protocol that allows developers to create markets based on assets, commodities, real-world events, and Mintbase, an NFT minting platform are examples of projects being built on NEAR Protocol.",
      CoinInfo_TRX:
        "Tron is one of the largest blockchain based operating systems in the world. TRON is an ambitious project dedicated to building the infrastructure for a truly decentralized Internet. The Tron Protocol, one of the largest blockchain based operating systems in the world, offers scalable, high-availability and high-throughput support that underlies all the decentralized applications in the TRON ecosystem. TRON Protocol and the TVM allow anyone to develop DAPPs for themselves or their communities, with smart contracts making decentralized crowdfunding and token issuance easier than ever. Tron DAPP projects already include Peiwo, Obike, Uplive, game.com, Kitty live and Mico, with 100M+ active users from more than 100 countries and regions around the world.",
      CoinInfo_XLM:
        "Stellar network is a free, open-source network that connects diverse financial systems and lets anyone build low-cost financial services—payments, savings, loans, insurance—for their community. It is supported by Stellar.org, a Silicon Valley based non-profit organization. The Stellar network enables money to move directly between people, companies and financial institutions as easily as email. This inter-connectivity means more access for individuals, lower costs for banks, and more revenue for businesses. Token use: Stellar is an open-source, decentralized protocol for digital currency to fiat currency transfers which allows cross-border transactions between any pair of currencies.",
      CoinInfo_XRP:
        "Ripple (XRP) is an independent digital asset that is native to the Ripple Consensus Ledger. With proven governance and the fastest transaction confirmation of its kind, XRP is said to be the most efficient settlement option for financial institutions and liquidity providers seeking global reach, accessibility and fast settlement finality for interbank flows.",
      CoinInfo_ZEC:
        "NZcash is a decentralized cryptocurrency focused on privacy and anonymity. It uses the zk-SNARK zero-knowledge proof technology that allows nodes on the network to verify transactions without revealing any sensitive information about those transactions. Contrary to a common misunderstanding, the majority of cryptocurrencies on the market, including Bitcoin (BTC), are not anonymous, but rather pseudonymous; while they do not explicitly reveal the identities of their users, each user has their own public address or addresses which can be traced back to them via the methods of data science and blockchain forensics. Zcash transactions, on the other hand, still have to be relayed via a public blockchain, but unlike pseudonymous cryptocurrencies, ZEC transactions by default do not reveal the sending and receiving addresses or the amount being sent. There is an option, however, to reveal this data for the purposes of auditing or regulatory compliance. Zcash was first released on October 28, 2016, and it was originally based on Bitcoin’s codebase.",
      // Easy Card
      //// Where To Buy
      ///// Top
      EC_WT_Title: "Easy Kart Satın Al",
      EC_WT_Description:
        "Yakınındaki bir Broker'dan Easy Kart satın alabilir ve Visa veya Kredi Kartı olmadan hesabınızı doldurabilirsiniz.",
      EC_WT_Find_Title: "Yakınındaki Broker'ı Bul",
      EC_WT_Find_btn: "Bul",
      EC_WT_Find_input: "Şehrinizi girin",
      ///// Apply
      EC_WA_title:
        "Easy Kart lisansı almak ve ortaklarımızdan biri olmak için başvurun ve faydalanın.",
      EC_WA_Decription: "Başvur",
      ///// Start
      EC_WS_Question: "EASY KART NASIL ÇALIŞIR?",
      EC_WS_title: "3 kolay adımda başlayın",
      EC_WS_SubTitle: "Tüm süreç 3 dakikadan kısa sürer.",
      EC_WS_Feature_1_description:
        "Yakınındaki bir broker bulun ve Easy Kart'ı satın alın",
      EC_WS_Feature_2_description:
        "Easy Kart kodunuzu girerek Gabur Token'larınızı cüzdanınıza yatırın",
      EC_WS_Feature_3_description: "Şimdi işlem yapmaya başlayabilirsiniz",
      //// Already Purchased
      EC_AP_description:
        "Kodunuzu buraya girin, Gabur Token'ları otomatik olarak cüzdanınıza yatırılır.",
      EC_AP_Code: "Kodunuzu girin",
      EC_AP_Code_Error: "Lütfen 20 haneli kodu girin.",
      //// Broker
      ///// Register
      EC_BR_title: "Broker olarak kaydolun",
      ///// Form
      EC_BR_Exchange_info: "Borsa Bilgileri",
      EC_BR_Legal: "Ticari Ünvan",
      EC_BR_Legal_Error: "Lütfen kayıtlı ülkenin ticari ünvanını girin.",
      EC_BR_RegCountry: "Kayıtlı Ülke",
      EC_BR_RegCountry_Error: "Lütfen Kayıtlı Ülke'yi girin.",
      EC_BR_RegNum: "Kayıt Numarası",
      EC_BR_RegNum_Error: "Lütfen Kayıt Numarası'nı girin.",
      EC_BR_MonTxVal: "Aylık İşlem Hacmi",
      EC_BR_MonTxVal_Error: "Lütfen Aylık İşlem Hacmi'ni girin.",
      EC_BR_ECMonDis: "Easy Kart Aylık Dağıtımı",
      EC_BR_ECMonDis_Error: "Lütfen Easy Kart Aylık Dağıtımı'nı girin.",
      EC_BR_Website: "Web Sitesi (Varsa)",
      EC_BR_Submitter_info: "Gönderen Bilgisi",
      EC_BR_Job: "İş Ünvanı",
      EC_BR_Job_Error: "Lütfen İş Ünvanı'nı girin.",
      EC_BR_Birth: "Doğum Tarihi",
      EC_BR_Birth_Error: "Lütfen Doğum Tarihi'ni girin.",
      EC_BR_Country: "İkamet Ülkesi",
      EC_BR_Country_Error: "Lütfen İkamet Ülkesi'ni girin.",
      EC_BR_Citizen: "Vatandaşlık Ülkesi",
      EC_BR_Citizen_Error: "Lütfen Vatandaşlık Ülkesi'ni girin.",
      EC_BR_Upload_title: "Borsa ve Kişisel Belgeleri Yükleyin:",
      EC_BR_Exchange_Doc: "Borsa Kayıt Belgesi",
      EC_BR_Exchange_Doc_Error: "Lütfen Borsa Kayıt Belgesi'ni yükleyin.",
      EC_BR_Peronal_ID_Doc: "Kişisel Kimlik Belgesi",
      EC_BR_Peronal_ID_Doc_Error: "Lütfen Kişisel Kimlik Belgesi'ni yükleyin.",
      // Easy Guarantee
      //// Easy Guarantee
      ////// top
      EG_EGTop_title: "Easy Guarantee",
      EG_EGTop_Description_1: "Dijital dünyadaki tek kripto teminat",
      EG_EGTop_Description_2: "Dünyada ilk defa",
      ////// Text
      EG_EGText_title: "Easy Guarantee Nedir?",
      EG_EGText_Description_1:
        "Easy Guarantee, Easyexchangepro tarafından sunulan bir tür USDT teminatıdır. Borçlunun yükümlülüklerinin yerine getirileceğini garanti eden bir tür geri ödeme belgesidir. Başka bir deyişle, borçlu bir borcu ödeyemezse, Easyexchangepro bunu karşılayacaktır.",
      EG_EGText_Description_2:
        "Easy Guarantee, başvuru sahibinin mal almasını, ekipman satın almasını, bir kredi çekmesini veya bir peşin ödeme karşılığında kullanmasını sağlar, vb.",
      EG_EGText_Feature_1_description:
        "İş amaçları için Easy Guarantee'yi 5 dakikada çıkarabileceksiniz.",
      EG_EGText_Feature_2_title: "Güvenilir",
      EG_EGText_Feature_2_description:
        "Easy Guarantee yalnızca bir ödeme taahhüdü belgesi değildir; Aksine, en likit dijital para birimi olarak USDT, alıcının hesabına yatırılacaktır.",
      EG_EGText_Feature_3_description:
        "Çıkarılan Easy Guarantee, %100 güvenli ve alıcı için güvence altına alınmış olup vade sonunda dönüştürülebilir veya çekilebilir.",
      ////// Steps
      EG_EGSteps_title: "Easy Guarantee'yi 4 kolay adımda çıkarabilirsiniz:",
      EG_EGSteps_Feature_1_title: "Adım 1",
      EG_EGSteps_Feature_1_description_1: "Platformumuzda",
      EG_EGSteps_Feature_1_description_2: "/",
      EG_EGSteps_Feature_2_title: "Adım 2",
      EG_EGSteps_Feature_2_description_1: "Başvuru formunu doldurun",
      EG_EGSteps_Feature_2_description_2: "ve gönderin",
      EG_EGSteps_Feature_3_title: "Adım 3",
      EG_EGSteps_Feature_3_description_1: "USDT ile Easy Guarantee",
      EG_EGSteps_Feature_3_description_2: "miktarını transfer edin",
      EG_EGSteps_Feature_4_title: "Adım 4",
      EG_EGSteps_Feature_4_description_1: "Garanti çıkarıldı ve",
      EG_EGSteps_Feature_4_description_2: "USDT alıcının hesabına",
      EG_EGSteps_Feature_4_description_3: "yatırıldı ve kilitlendi",
      ////// Bot
      EG_EGBot_title: "Şirketler ve Bireyler İçin",
      EG_EGBot_description:
        "Kullanıcılarımız olarak şirketler ve bireyler, iş amaçları doğrultusunda bir Garantiyi küçük veya büyük miktarlarda, lehtar lehine çıkarabilirler.",
      //// FAQ
      EG_EGFaq_Q1: "Easy Guarantee Nedir?",
      EG_EGFaq_A1:
        "Easy Guarantee, kripto para birimi (USDT) destekli bir garanti türüdür. Borçlular/alıcılar, alacaklıya olan borçlarını yerine getireceklerini garanti ederler.",
      EG_EGFaq_Q2: "Başvuru sahibi kimdir?",
      EG_EGFaq_A2: "Başvuru sahibi, Easy Guarantee çıkarmayı talep eden taraf.",
      EG_EGFaq_Q3: "Garanti çıkarmak için hangi para birimi kabul edilir?",
      EG_EGFaq_A3: "Yalnızca USDT.",
      EG_EGFaq_Q4: "Herhangi bir USDT cüzdan adresi kabul edilir mi?",
      EG_EGFaq_A4:
        "Hayır, yalnızca başvuru sahibinin ve lehtarın USDT cüzdan adresleri Easy Exchange Pro'da kabul edilir.",
      EG_EGFaq_Q5: "Lehtar kimdir?",
      EG_EGFaq_A5: "Garanti çıkarılan taraf.",
      EG_EGFaq_Q6: "Koşullu Easy guarantee nedir?",
      EG_EGFaq_A6:
        "Garanti miktarının serbest bırakılması için lehtarın talebinin başvuru sahibi tarafından onaylanması gerekmektedir.",
      EG_EGFaq_Q7: "Koşulsuz Easy guarantee nedir?",
      EG_EGFaq_A7:
        "Easy Guarantee miktarının serbest bırakılması, lehtarın ilk talebi üzerine gerçekleşir.",
      EG_EGFaq_Q8: "Kullanıcılar nasıl başvurabilir?",
      EG_EGFaq_A8: "Başvuru formunu doldurup gönderin.",
      EG_EGFaq_Q9: "Formu gönderdikten sonra ne olacak?",
      EG_EGFaq_A9:
        "Başvuru sahibi, talebi onaylamak için ilk bir e-posta alacak ve ardından Easy Guarantee miktarını transfer etmek için USDT cüzdan adresini içeren ikinci bir e-posta alacaktır.",
      EG_EGFaq_Q10: "Kullanıcı ne kadar Easy guarantee çıkarabilir?",
      EG_EGFaq_A10:
        "Kullanıcı, hesabındaki USDT bakiyesi kadar Easy guarantee çıkarabilir.",
      EG_EGFaq_Q11:
        "Easy guarantee çıkarmak için başvuru sahibinin USDT bakiyesi olması gerekiyor mu?",
      EG_EGFaq_A11:
        "Evet, kullanıcılar USDT bakiyeleri kadar Easy guarantee çıkarmak için başvuruda bulunabilirler.",
      EG_EGFaq_Q12: "Easy guarantee ne zaman çıkarılacak?",
      EG_EGFaq_A12:
        "Başvuru sahibinin USDT transferinden sonra Easy Guarantee hemen çıkarılacak ve USDT miktarı geçerlilik süresine bağlı olarak lehtarın hesabında bloke edilecektir.",
      EG_EGFaq_Q13:
        "Başvuru sahibi ve lehtar arasında garanti miktarının serbest bırakılmasıyla ilgili herhangi bir anlaşmazlık nasıl çözülecek?",
      EG_EGFaq_A13:
        "Herhangi bir anlaşmazlık durumunda, tarafların belgeleri gözden geçirildikten sonra yasal departmanımız bir ay içinde karar verecektir ve itirazlarını easyguarantee@easyexchangepro.com adresine iletebilirler.",
      EG_EGFaq_Q14:
        "Başvuru sahibi ve lehtarın her ikisi de Easyexchangepro platformunun kullanıcısı olmalı mı?",
      EG_EGFaq_A14: "Evet, olmalı.",
      EG_EGFaq_Q15:
        "Lehtar, Easy Guarantee miktarının serbest bırakılmasını ne zaman talep edebilir?",
      EG_EGFaq_A15:
        "Lehtar, Easy Guarantee'nin geçerlilik süresi boyunca hesabındaki Easy Guarantee miktarının serbest bırakılmasını talep edebilir.",
      EG_EGFaq_Q16:
        "Lehtar, Easy Guarantee'nin geçerlilik süresi boyunca garanti miktarının serbest bırakılmasını talep etmezse ne olur?",
      EG_EGFaq_A16:
        "Easy Guarantee miktarı otomatik olarak başvuru sahibinin hesabına alacak olarak kaydedilir.",
      EG_EGFaq_Q17: "Easy guarantee çıkarmak ne kadar sürer?",
      EG_EGFaq_A17: "5 dakikadan daha kısa bir sürede.",
      EG_EGFaq_Q18: "Easy guarantee çıkarmak ne kadara mal olur?",
      EG_EGFaq_A18: "Easy guarantee miktarının %0.4'ü.",
      EG_EGFaq_Q19: "Masrafı kim ödeyecek?",
      EG_EGFaq_A19: "Başvuru sahibi.",
      EG_EGFaq_Application_form: "Başvuru Formu",
      //// Application Form
      EG_AF_title: "Easy Guarantee Başvuru Formu",
      EG_AF_description:
        "Not: Lütfen başvuru formunu doldurmadan önce Easy Guarantee Soruları bölümünü dikkatlice okuyun.",
      EG_AP_Applicant: "Başvuru Sahibi:",
      EG_AP_SDate: "Başlangıç Tarihi",
      EG_AP_SDate_Error: "Lütfen Başlangıç Tarihi'ni girin.",
      EG_AP_EDate: "Bitiş Tarihi",
      EG_AP_EDate_Error: "Lütfen Bitiş Tarihi'ni girin.",
      EG_AP_WalletApp: "Easy Exchange Pro'daki USDT Cüzdan Adresi",
      EG_AP_WalletApp_Error: "Lütfen USDT Cüzdan Adresi'ni girin.",
      EG_AP_Amount: "USDT Miktarı",
      EG_AP_Amount_Error: "Lütfen USDT Miktarı'nı girin.",
      EG_AP_VPD: "Geçerlilik Süresi (Gün)",
      EG_AP_VPD_Error: "Lütfen Geçerlilik Süresi (Gün)'nü girin.",
      EG_AP_Select_Conditional: "Koşullu",
      EG_AP_Select_Unconditional: "Koşulsuz",
      EG_AP_Beneficiary: "Lehtar:",
      EG_AP_BenUID: "Lehtar Kullanıcı Kimliği",
      EG_AP_BenUID_Error: "Lütfen Lehtar Kullanıcı Kimliği'ni girin.",
      EG_AP_BenEmail: "Lehtar E-posta Adresi",
      EG_AP_BenEmail_Error: "Lütfen Lehtar E-posta Adresi'ni girin.",
      EG_AP_WalletBen: "Easy Exchange Pro'daki Lehtar USDT cüzdan adresi",
      EG_AP_WalletBen_Error: "Lütfen Lehtar USDT cüzdan adresini girin.",
      // Fee
      Fee_title_1: "Maker emirler",
      Fee_description_1:
        "Maker emirler, likiditeyi piyasada oluştururken (yapar) emir defterine girilir. Başka bir deyişle, maker emirler, yerleştirildiği anda doldurulmaz, ancak ileride onunla eşleşen bir gelecek emri yerleştirildiğinde bekler. Bir maker emri, alım veya satım emri olabilir. Mevcut bir emir, yeni yerleştirilen bir emirle (alıcı) eşleştiğinde, işlemdeki maker emri maker ücreti alır. Yeni girilen bir emir mevcut emirlerle hemen eşleşmez veya mevcut emirler tarafından tamamen doldurulmazsa, tamamlanmamış emir gelecekteki bir işlem için bir maker emri haline gelir.",
      Fee_title_2: "Taker emirler",
      Fee_description_2:
        "Taker emirler, piyasada likiditeyi azaltır (alır). Taker emirler hemen gerçekleşir ve emir defterinden hacim çeker. Bir taker emri, alım veya satım emri olabilir. Yeni bir emir yerleştirildiğinde ve zaten emir defterindeki başka bir emirle eşleşirse (maker), işlemdeki taker taker ücreti alır.",
      Fee_title_3: "İşlem ücretleri",
      Fee_subtitle_3_1: "Blockchain:",
      Fee_description_3_1:
        "Belirli bir blockchain ağı üzerindeki tokenların Easy Exchange Pro'da taşınması sırasında özel blockchain ağı ücretleri oluşabilir.",
      Fee_description_3_2:
        "Easy Exchange Pro, mevduatlar için ücret talep etmez. Lütfen bazı tokenların veya coinlerin kredilendirilmeden önce fonlarınızı başka bir adrese taşımamız gerektiğini unutmayın. Bu, coin veya tokenin ağı tarafından normal işlem ücretinin size yansıtılacağı anlamına gelir ve Easy Exchange Pro'nun bunu önlemesi mümkün değildir.",
      Fee_description_3_3:
        "Easy Exchange Pro, dahili bir ağ transfer ücretine sahip bir token veya coini çekme işlemlerinde küçük bir ücret talep eder. Dahili ağ transfer ücretine sahip her bir token veya coin için çekme penceresinde çekme düğmesine tıklanarak ağ ücreti görüntülenebilir.",
      Fee_table_1_col_1: "Seviye",
      Fee_table_1_col_2: "30 Gün İşlem Hacmi (BTC)",
      Fee_table_1_col_3: "Maker",
      Fee_table_1_col_4: "Taker",
      Fee_table_2_col_1: "Para Birimi Sembolü",
      Fee_table_2_col_2: "Para Birimi Adı",
      // Gabur
      //// Top
      GBR_top_title_1: "EASY EXCHANGE PRO",
      GBR_top_title_3: "Finansın evrimi",
      //// Second
      GBR_Second_title_1: "GÜVENLİK AKILLI KONTRAT",
      GBR_Second_title_2: "Gabur Token Denetimi",
      GBR_Second_description:
        "Akıllı bir kontrat, blockchain'de depolanan özel talimatları içeren bir sözleşmedir. Bu kontrat, belirli bir dizi önceden programlanmış parametreye göre bir dizi eylem gerçekleştirebilir ve değiştirilemez, şeffaf ve tamamen güvenli bir şekilde çalışabilir. Bu nedenle, GABUR Token Akıllı Kontratı, en yüksek güvenlik seviyelerini sağlamak için denetlenmiştir.",
      GBR_Second_Security_btn: "Güvenlik Denetimi",
      //// Description
      GBR_Description_title_1: "Gabur Token Açıklaması",
      GBR_Description_list_1_1: "Utility Token adı: GABUR",
      GBR_Description_list_1_2: "Sembol: GBR",
      GBR_Description_list_1_3: "GABUR Token Toplam Arzı: 550M",
      GBR_Description_list_1_4:
        "GABUR Token'larının artışa tabi olması söz konusu değildir",
      GBR_Description_list_1_5:
        "GABUR Token'lar, BSC standardına uygun olarak BEP20 ağında doğal olarak çalışacaktır.",
      GBR_Description_list_1_6: "Token yakma: 165M GABUR Token yakılacak",
      GBR_Description_title_2: "Gabur Token Dağıtımı",
      GBR_Description_list_2_1: "Halka arz",
      GBR_Description_list_2_2: "Easy card rezervi",
      GBR_Description_list_2_3: "Ekip",
      GBR_Description_list_2_4: "Danışmanlar",
      GBR_Description_list_2_5: "Özel satış",
      GBR_Description_list_2_6: "Airdrop",
      //// Contract
      GBR_Contract_subtitle: "Gabur token sözleşme adresi",
      //// Added
      GBR_Added_subtitle: "Gabur Token Eklenen Değer",
      GBR_Added_title: "Gabur Token'ın eklenen değeri nedir?",
      GBR_Added_list_1:
        "Easy Exchange Pro'nun yol haritası taahhütlerimize göre geliştirilmesi",
      GBR_Added_list_2:
        "İşlemlerde kullanılacak önemli bir özellik haline gelmesini sağlayacak Easy Card'ın geliştirilmesine yönelik yenilikçi fikirlerin oluşturulması",
      GBR_Added_list_3: "GABUR Token'ları artışa tabi değildir",
      GBR_Added_list_4:
        "Dijital varlıkları daha pratik hale getirmeyi amaçlayan yenilikçi gelişmeler",
      GBR_Added_list_5: "GABUR Token'larının her çeyrekte yakılması",
      GBR_Added_list_6: "Tokenlar için kilitlenme süresi",
      GBR_Added_list_7:
        "Dünya genelinde 60'tan fazla ülkede ödemeleri düzenlemek için istikrarlı ve güvenli bir yol arayan kullanıcıları karşılama yeteneği",
      GBR_Added_list_8:
        "Kripto para birimleri ve finans alanında deneyimli, geniş uluslararası finans bağlantılarına sahip bir ekibe sahip olma",
      GBR_Added_list_9:
        "Yol haritamıza göre yeni ve benzersiz ürünler ekleyeceğiz",
      // Login
      Login_title: "Easy Pro'ya Hoş Geldiniz",
      Login_subtitle: "Devam etmek için giriş yapın",
      Login_btn_Loading: "Giriş Yap",
      Login_problem: "Giriş sorunu mu yaşıyorsunuz?",
      Login_click_here: "Buraya tıklayın",
      Login_register: "Kaydolmak için buraya tıklayın",
      Login_protected: "Bu site reCAPTCHA ve Google tarafından korunmaktadır",
      Login_privacy: "Gizlilik Politikası",
      Login_and: "ve",
      Login_terms: "Hizmet Şartları",
      Login_ResetModal_title: "2FA veya Şifrenizi sıfırlayabilirsiniz",
      Login_ResetModal_Reset2FA_btn: "2FA'yı Sıfırla",
      Login_ResetModal_ForgetPassword: "Şifrenizi mi unuttunuz?",
      Login_ResetPassword_title:
        "Şifre sıfırlama bağlantısı almak için e-postanızı girin",
      Login_Reset2FA_title:
        "2FA sıfırlama bağlantısı almak için e-postanızı girin",
      Login_OTP_title: "Alınan OTP'yi girin",
      //// Reset Password
      ResetPassword_title: "Şifrenizi Sıfırlayın",
      ResetPassword_NewPassword_input: "Yeni Şifre",
      ResetPassword_ConfirmPassword_input: "Yeni Şifreyi Onayla",
      // Privacy And Policy
      Privacy_1_title: "INTRODUCTION",
      Privacy_1_desciption:
        "Thanks indeed for visiting Easyexchangepro.com, as a digital asset trading website. The purpose of this Privacy Policy is to inform you (user, customer) with an understanding that how Easyexchangepro (or we, or our, or us or platform) collect personal information from our clients and prospective clients to provide them with our products and services and ensure that we can meet their needs when providing these products and services, as well as when providing them with any respective information. This privacy policy applies to the Site and all Services offered by Easyexchangepro.",
      Privacy_2_title: "PERSONALLY IDENTIFIABLE",
      Privacy_2_desciption:
        "We may collect personally identifiable information from Users in a variety of ways, including, but not limited to, when Users visit our Site, register on the Site, place an order, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Site anonymously. When interacting with us on the Site, Users can always refuse to supply personal data to us, except that it may prevent them from engaging in certain Site related activities or transactions. We may collect non-personally identifiable information about Users when they interact with our Site.",
      Privacy_3_title: "WHAT INFORMATION DO WE COLLECT?",
      Privacy_3_desciption_1:
        "Easyexchangepro collects information about you when you use our websites (including the Site) and other online products and services and throughout other interactions and services, you have with us. We will collect your personal data in accordance with the legislative purpose of the DPA. We may also collect and store certain information automatically when you visit the Site. Examples include the internet protocol (IP) address used to connect your computer or device to the internet, connection information such as browser type and version, your operating system and platform, a unique reference number linked to the data you enter on our system, log in details, the full URL clickstream to, through and from the Site (including date and time), cookie number and your activity on our Site, including the pages you visited, the searches you made and, if relevant, the products/services you purchased.",
      Privacy_3_desciption_2:
        "We may receive information about you from third parties if you use any websites or social media platforms operated by third parties (for example, Facebook, Instagram, Twitter, etc.) and, if such functionality is available, you have chosen to link your profile on the Site with your profile on those other websites or social media platforms.",
      Privacy_4_title: "INDIVIDUAL AND COMPANY WHICH WE MAY COLLECT INCLUDE:",
      Privacy_4_subtitle_1: "For individual:",
      Privacy_4_list_1_1: "• Email address",
      Privacy_4_list_1_2: "• Mobile phone number",
      Privacy_4_list_1_3:
        "• Full legal name (including former name, and names in local language)",
      Privacy_4_list_1_4: "• Nationality",
      Privacy_4_list_1_5:
        "• Passport number, or any government-issued ID number",
      Privacy_4_list_1_6: "• Date of birth",
      Privacy_4_list_1_7:
        "• Proof of identity (e.g., passport, driver’s license, or government-issued ID)",
      Privacy_4_list_1_8: "• Residential address",
      Privacy_4_list_1_9: "• Proof of residency",
      Privacy_4_list_1_10:
        "• Additional Personal Data or documentation at the discretion of our Compliance Team",
      Privacy_4_subtitle_2: "For companies:",
      Privacy_4_list_2_1:
        "• Company legal name (including the legal name in local language)",
      Privacy_4_list_2_2: "• Incorporation/registration Information",
      Privacy_4_list_2_3:
        "• The full legal name of all beneficial owners, directors, and legal representatives",
      Privacy_4_list_2_4:
        "• Address (principal place of business and/or other physical locations)",
      Privacy_4_list_2_5: "• Proof of legal existence",
      Privacy_4_list_2_6: "• Description of the business",
      Privacy_4_list_2_7:
        "• Percentage of ownership for Individual/company owners",
      Privacy_4_list_2_8:
        "• Contact information of owners, principals, and executive management (as applicable)",
      Privacy_4_list_2_9:
        "• Proof of identity (e.g., passport, driver’s license, or government-issued ID) for the significant individual beneficial owner of the institutional customer entity",
      Privacy_4_list_2_10:
        "• Personal Data for each entity’s significant beneficial owner of the institutional customer entity (see the “Individual Customer” section above for details on what Personal Data we collect for individuals)",
      Privacy_4_list_2_11: "• Source of wealth",
      Privacy_4_list_2_12:
        "• Amount of bitcoin or other digital assets projected to be injected",
      Privacy_5_title: "COOKIES USAGE",
      Privacy_5_desciption:
        "When you access our Sites and Services, we or companies we work with may place cookies on your computer or another device. Of course, you can block or deactivate cookies in your browser settings. But these technologies help us better understand user behavior and inform us about which parts of our websites people have visited. We use first-party and third-party cookies to recognize you as an Easyexchangepro user, customize Coinbase Services, content, and advertising, measure promotional effectiveness, and collect information about your computer or another access device to mitigate risk, helping prevent fraud, and promote trust and safety. We may place cookies from third-party service providers who may use information about your visits to other websites to target advertisements for products and services available from Easyexchangepro. We do not control the types of information collected and stored by these third-party cookies. You should check the third-party's website for more information on how they use cookies.",
      Privacy_6_title: "LAWFUL BASIS FOR PROCESSING YOUR PERSONAL INFORMATION",
      Privacy_6_desciption_1:
        "We will process your personal information on the following bases and for the following purposes:",
      Privacy_6_desciption_2:
        "carrying out your instructions or responding to any inquiry given by (or purported to be given by) you or on your behalf including responding to your inquiries and complaints, or responding to or dealing with your interactions with us; registering you as a customer of Easyexchangepro and/or to deal with, process and/or administer the account that you may open with us, whether a membership account or otherwise, including to facilitate your transactions or activities on the Site or your transactions or activities with us; administering, facilitating, processing and/or dealing with your relationship with us, any transactions or activities carried out by you on the Site or with us. This includes processing your application, orders, and payment transactions; implementing transactions and the supply of products and/or services to you that you have requested; administering, facilitating, processing, and/or dealing in any matters relating to your use or access of the Site, including identifying you for login to the Site, our portals and other online services provided by or on behalf of us; monitoring, processing and/or tracking your use of the Site in order to provide you with a seamless experience, facilitating or administering your use of the Site, and/or to assist us in improving your experience in using the Site; assessing and processing your request for the purchase of and/or subscription to our products and/or services. contacting you or communicating with you via phone/voice call, text message and/or fax message, email and/or postal mail for the purposes of administering and/or managing your use of the Site, your membership and/or account with us, your relationship with us or any transactions made by you with us.",
      Privacy_7_title: "HOW WE USE YOUR PERSONAL DATA",
      Privacy_7_desciption_1:
        "Easyexchangepro uses Personal Data to communicate with you and to administer, deliver, improve, and personalize the Service. Easyexchangepro might also generate generic data out of any Personal Data we collect and use it for our own purposes. We may also use such data to communicate with you in relation to other products or services offered by Easyexchangepro and/or its partners. We do not share your Personal Data with third parties (other than partners in connection with their services to Easyexchangepro) except where you have given your consent and further detailed below.",
      Privacy_7_desciption_2:
        "We may share your Personal Data with third parties:",
      Privacy_7_desciption_3:
        "If we think that sharing it is necessary to enforce the Terms of Service. To comply with government agencies, including regulators, law enforcement, and/or justice departments. To third parties who provide services to Easyexchangepro (such as administration or technical services). In connection with the sale or transfer of our business or any part thereof.",
      Privacy_7_desciption_4:
        "Additionally, we have implemented international standards to prevent money laundering, terrorist financing and will implement final Digital Asset rules and regulations when effective, which will likely require us to undertake due diligence on our customers. This may include the use of third-party data and service providers which we will cross-reference with your personal information.",
      Privacy_8_title: "STORING YOUR PERSONAL DATA",
      Privacy_8_desciption:
        "The data that we collect from you may be transferred to and stored at, a destination outside of the U.A.E. It may also be processed by staff operating outside of U.A.E who work for us or for one of our suppliers. By submitting your personal data, you agree to this transfer, storing, or processing, except for customers located in the EEA, as detailed above. All information you provide to us is stored on our and/or third-party cloud servers",
      Privacy_9_title: "MARKETING COMMUNICATIONS",
      Privacy_9_desciption:
        "We may use your personal information to send you marketing communications by email or other agreed forms (including social media campaigns), to ensure you are always kept up to date with our latest products and services. If we send you marketing communications, we will do so based on your consent and registered marketing preferences. Users can opt out of these marketing communications at any moment. If you do not want to receive these communications, please send an email to support@easyexchangepro.com",
      Privacy_10_title: "DATA SECURITY",
      Privacy_10_desciption:
        "We endeavor to protect Easyexchangepro and you from unauthorized access, alteration, disclosure, or destruction of Personal Data we collect and store. We take various measures to ensure information security, including encryption of the Easyexchangepro communications with SSL; required two-factor authentication for all sessions; periodic review of our Personal Data collection, storage, and processing practices; and restricted access to your Personal Data on a need-to-know basis for our employees and vendors who are subject to strict contractual confidentiality obligations.",
      Privacy_11_title:
        "TRANSFER OF PERSONAL INFORMATION OUTSIDE OF THE EEA and UK and YOUR COUNTRY",
      Privacy_11_desciption:
        "We may transfer your personal information outside the EEA and UK to other Company subsidiaries, service providers, and business partners (i.e., Data Processors) who are engaged on our behalf. To the extent that we transfer your personal information outside of the EEA and UK, we will ensure that the transfer is lawful and that Data Processors in third countries are obliged to comply with the European Union (EU) General Data Protection Act 2016 and the UK Data Protection Act 2018. Also, by using our products and services, you consent to your Personal Data being transferred to other countries, including countries that have differing levels of privacy and data protection laws than your country. In all such transfers, we will protect your personal information as described in this Privacy Notice and ensure that appropriate information sharing contractual agreements are in place.",
      Privacy_12_title: "COMPLIANT PROCESS",
      Privacy_12_desciption:
        "If you have any complaint or grievance regarding how we are handling your personal data or about how we are complying with the CPT (when in force), we welcome you to contact us with your complaint or grievance and should be addressed to CPT@Easyexchangepro.com Where you are sending an email in which you are submitting a complaint, your indication at the subject header that it is a CPT complaint would assist us in attending to your complaint speedily by passing it on to the relevant staff in our organization to handle. We will certainly strive to deal with any complaint or grievance that you may have speedily and fairly.",
      Privacy_13_title: "YOUR ACCEPTANCE",
      Privacy_13_desciption:
        "By using Easyexchangepro Site, you signify your acceptance of this policy and Terms of Use.",
      Privacy_14_title: "CHANGES TO THIS PRIVACY POLICY",
      Privacy_14_desciption:
        "Any changes we may make to our Privacy Policy in the future will be posted on this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.",
      // Product Convert
      Convert_title: "Dönüştür",
      Convert_for_each: "Her biri için",
      Convert_receive: "alacaksınız",
      Convert_calc: "Dönüşümü Hesapla",
      // Product Market Tabs
      Market_tab_Spot: "Spot",
      Market_tab_Favorite: "Favori",
      // Product OTC
      OTC_title: "Easy Pro OTC masası",
      OTC_description:
        "Özellikle büyük hacimlerde kripto para birimlerini takas etmek isteyen işlemciler genellikle OTC (tezgah üstü) kripto para birimi ticaretini kullanmaya karar verir. Easy Pro OTC masası, size gizli, güvenli ve rekabetçi bir şekilde yürütme ve yerleştirme hizmetleri sunacaktır.",
      OTC_Feature_1:
        "Piyasalara önemli bir etkisi olmaksızın OTC işlemlerini gizli bir şekilde gerçekleştiriyoruz.",
      OTC_Feature_2:
        "Ticaret masamız, Easy Pro üzerinde tüm güvenlik protokollerine uygun bir şekilde gizliliğe saygı duyar.",
      OTC_Feature_3:
        "Yerleştirmeler Easy Pro ekosisteminde gerçekleşir ve genellikle tamamlanır.",
      OTC_Feature_4: "Yerleşim işlemi birkaç dakika içinde tamamlanır",
      OTC_Feature_5: "Masa başında 3. taraf yok",
      OTC_Feature_6:
        "İşlem Easy Pro hesabında gerçekleşir, bu nedenle yeni bir cüzdan oluşturmanıza gerek yoktur.",
      OTC_Feature_7:
        "Takas yapmak istediğiniz Coinler / Tokenler, ekibimiz tarafından hesabınıza manuel olarak taşınır ve çıkarılır.",
      OTC_Feature_8:
        "İşlem ayrıntıları, hesabınızdaki 'Dağıtım Geçmişi' bölümünde bulunabilir.",
      OTC_Support_title: "OTC Destek",
      OTC_Support_description:
        "OTC masası, dünya genelinde 7/24 hizmet vermektedir (büyük tatil günleri hariç) OTC@easyexchangepro.com",
      OTC_list_1: "Lütfen OTC formunu doldurup bize gönderin",
      OTC_list_2:
        "Ekibimiz teklif verme, işlem yapma ve yerleşim sağlama konusunda size yardımcı olabilir",
      OTC_list_3:
        "Teklifimizi kabul ettiğinizde işlem onaylanır ve ekibimiz işlem ayrıntılarını ve talimatlarını size e-posta yoluyla gönderir.",
      OTC_list_4:
        "Sizden anlaşmanın son tarafını aldığımızda, OTC masası, sizin cüzdanınıza veya banka hesabınıza yerleşimi gerçekleştirir",
      // Product Trade
      Trade_KYC_Error: "Lütfen KYC'nizi yükleyin",
      Trade_Trading_password_Error: "Lütfen Ticaret Şifresini etkinleştirin",
      Trade_Trading_password_title: "Ticaret şifrenizi girin",
      Trade_Trading_password_input: "Ticaret Şifresi",
      Trade_Trading_Password_btn: "Ticaret Şifresini Girin",
      Trade_goto_security: "Güvenliğe Git",
      Trade_FAV: "FAV",
      Trade_Open_Orders: "Açık Emirler",
      Trade_Order_history: "Emir Geçmişi",
      Trade_Trade_history: "İşlem Geçmişi",
      Trade_Date_Time: "Tarih ve Saat",
      Trade_Type: "Tip",
      Trade_Action: "İşlem",
      Trade_Login_Register: "Giriş/Kayıt Ol",
      Trade_Password_Error: "Lütfen şifrenizi girin.",
      Trade_Balance_Error: "Yetersiz bakiye, elinizde",
      Trade_Min_Error: "Emir 20 USDT'den yüksek olmalıdır",
      Trade_approximately: "Tahmini olarak alacaksınız",
      Trade_Market: "Piyasa",
      Trade_Order_List: "Emir Listesi",
      Trade_Order_History: "Emir Geçmişi",
      Trade_Pairs: "Çiftler",
      Trade_Open_Chart: "Grafik Aç",
      // Profile
      Profile_profile: "profil",
      Profile_security: "güvenlik",
      Profile_history: "geçmiş",
      Profile_Security_log_history: "Güvenlik giriş geçmişi",
      Profile_Login_history: "Giriş geçmişi",
      Profile_User_info_title: "Kullanıcı bilgileri",
      Profile_User_info_description:
        "Güvende tutmanız gereken benzersiz hesap bilgileriniz.",
      Profile_UID: "UID",
      Profile_Sec_info_title: "Güvenlik Bilgileri",
      Profile_Activated: "Aktif",
      Profile_Disabled: "Devre dışı",
      Profile_Trading_Password: "Ticaret Şifresi",
      Profile_Account_status_title: "Hesap Durumu",
      Profile_KYC: "KYC",
      Profile_Sec_level: "Güvenlik Seviyesi",
      Profile_Airdrop_point: "Airdrop Puanı",
      Profile_Referrals_title: "Referanslar",
      Profile_Num_Referrals: "Referans Sayısı",
      Profile_Referral_ID: "Referans Kimliği",
      Profile_Referral_List_btn: "Referans Listesi",
      Profile_Commissions_btn: "Komisyonlar",
      Profile_User: "Kullanıcı",
      Profile_KYC_Verified: "Doğrulandı",
      Profile_KYC_Pending: "Bekliyor",
      Profile_KYC_Rejected: "Reddedildi",
      Profile_KYC_NotUploaded: "Yüklenmedi",
      Profile_Sec_lvl_Medium: "Orta",
      Profile_Sec_lvl_VeryHigh: "Çok Yüksek",
      Profile_Account_Password_title: "Hesap Şifresi",
      Profile_Account_Password_description:
        "Şifrenizi buradan değiştirebilirsiniz.",
      Profile_Change_Password_btn: "Şifre Değiştir",
      Profile_Change_your_password_title: "Şifrenizi değiştirin",
      Profile_Current_Password: "Geçerli Şifre",
      Profile_New_Password: "Yeni Şifre",
      Profile_Confirm_New_Password: "Yeni Şifreyi Onayla",
      Profile_Sec_Authenticaton_title: "İkinci Faktör Kimlik Doğrul ama",
      Profile_Sec_Authenticaton_description:
        "2FA'yı buradan etkinleştirebilir/devre dışı bırakabilirsiniz.",
      Profile_Active_2FA: "2FA'yı Etkinleştir",
      Profile_Activating_2FA: "2FA Etkinleştiriliyor",
      Profile_Disable: "Devre Dışı Bırak",
      Profile_Disable_2FA: "2FA'yı Devre Dışı Bırak",
      Profile_2FA_Code: "2FA kodu",
      Profile_Active: "Aktif",
      Profile_Trading_Password_description:
        "Ticaret şifrenizi buradan değiştirebilirsiniz.",
      Profile_Change_trading_password: "Ticaret şifrenizi değiştirin",
      Profile_Change_your_Trading_password_title:
        "Ticaret Şifrenizi Değiştirin",
      Profile_Change_your_Trading_password_description:
        "Ticaret Şifrenizi değiştirmek istiyor musunuz?",
      Profile_forgot_code: "kodu unuttum",
      Profile_KYC_title: "Kişisel Hesap KYC'si",
      Profile_KYC_select_doc: "Belge türünü seçin",
      Profile_KYC_select_doc_ID: "Kimlik kartı",
      Profile_KYC_select_doc_pass: "Pasaport",
      Profile_KYC_select_doc_Drive: "Sürücü belgesi ile kimlik numarası",
      Profile_KYC_Upload_title: "Lütfen Belgelerinizi Yükleyin:",
      Profile_KYC_Front: "Dokümanın Ön Yüzü",
      Profile_KYC_Back: "Dokümanın Arka Yüzü",
      Profile_KYC_Selfie: "Dokümanı elinizde tutarak Selfie",
      Profile_KYC_Approved: "KYC'niz zaten onaylandı.",
      Profile_KYC_Pending: "KYC'niz beklemede.",
      Profile_Currency: "Para Birimi",
      Profile_Type: "Tür",
      Profile_Login: "Giriş",
      Profile_Region: "Bölge",
      Profile_Login_device: "Giriş cihazı",
      // Register
      Reg_verify_email: "E-postayı doğrula",
      Reg_enter_otp: "OTP'yi girin",
      Reg_Confirm_Password: "Şifreyi Onayla",
      Reg_Referral_Code: "Referans Kodu (İsteğe bağlı)",
      // Terms Of Use
      Term_1_title: "1-Welcome to Easy Pro",
      Term_1_description_1:
        "Thanks indeed for visiting (Easyexchangepro / we, hereinafter referred to as the Easy pro) By visiting, accessing, or using easyexchangepro.com and associated application program interface or mobile applications (platforms), You (“User” or “You”) consent to the Terms of Service (the “Terms”), so please read them carefully.",
      Term_1_description_2:
        "The Terms constitute the agreement and understanding regarding the use of any or all the Services, and any manner of accessing them, between You and the following service provider (“we,” “us” or “our” platform) Easyexchangepro.",
      Term_2_title: "2-Key Definitions",
      Term_2_description_1:
        "Capitalized terms not otherwise defined in these Terms will have the following meaning:",
      Term_2_description_2:
        "“External Account” means any Digital Token Account: from which you may transfer funds into your Easy Pro  Account, or to which you may transfer funds from your Easy Pro Account and which has been approved by Easy pro for the foregoing purposes.",
      Term_2_description_3: "“Funds” means Digital Tokens.",
      Term_2_description_4: "“Fiat Currency” means any national currency.",
      Term_2_description_5:
        "“Easy Pro Account” means a user account accessible via the Services where Funds may be stored by Easy pro on behalf of a user.",
      Term_2_description_6:
        "“Easy Pro Help Center” means Easy pro’s customer service center with address at Support@easyexchagepro.com -us where you could get online support from Easy pro.",
      Term_2_description_7:
        "“Digital Tokens” means Bitcoins, Ripples, and other digital mediums of exchange that may be purchased, sold, or traded via the Services.",
      Term_2_description_8:
        "“Digital Token Account” means any Digital Token address or account owned or operated by you that is maintained outside of the Services, and is not owned, controlled, or operated by Easyexchangepro.",
      Term_3_title: "3- Accept terms",
      Term_3_description_1:
        "You should read the Terms, and any document referred to in them very carefully. If there is anything that You do not understand in the Terms and any document referred to herein, you should discuss this matter with us and seek the necessary clarification.",
      Term_3_description_2:
        "You are at least 18 years of age and have the full capacity to accept these Terms and enter a transaction involving digital assets. You have not been deprived of the right to use our service and have the full capacity for legal action. If you do not meet the above condition, please do not register our Site or Platform, otherwise, the platforms may suspend or terminate your account at any time.",
      Term_3_description_3:
        "You’re entering and performing these Terms are not prohibited by the laws and regulations of the country or region to which you belong, reside, pay tax or carry out business activities or other business. If you do not meet the above conditions, you should immediately terminate the registration or stop using our Platforms services.",
      Term_3_description_4:
        "Our Platform has the right to modify these Terms from time to time or to formulate and modify various specific rules according to these Terms and publish them in the relevant system sections of Platforms without separately notifying you.",
      Term_3_description_5:
        "You should pay attention to the changes in these Terms and the specific rules from time to time. If you continue to use the Service after the changes in the content of these Terms and the specific rules, you are deemed to have fully read, understood, and accepted the amended Terms and the specific rules and to use the services of our Platforms in accordance therewith.",
      Term_3_description_6:
        "You also agree that Easy Pro may, by giving notice, at its sole discretion immediately terminate your access to our Platform and to your account. You agree and acknowledge our right to limit, suspend or terminate the service and your user account, prohibit your access to our Platform and their content, services, and tools, delay or remove hosted content, and take technical and legal steps to keep you off our Platform or any further steps to recover any loss or damages caused if we determine at our sole discretion that you are or may be in breach of any laws, regulations, the rights of third parties, or any of these Terms or Platform’s policies. Easy Pro shall not be liable for any loss or damage caused to you or any third party because of exercising our rights under this clause.",
      Term_4_title: "4- Our Trading Services",
      Term_4_description:
        "Easy Pro provides an online platform with a simple and convenient way to trade one type of digital asset or another type of digital asset. Easy Pro provides you with a platform that matches your orders with open orders from other users of our Services.",
      Term_4_subheader_1: "Spot.",
      Term_4_subtitle_1: "Market Order:",
      Term_4_description_1:
        "A Market Order is placed to buy or sell a Specified Quantity or a Specific Gross Amount of Targeted tokens at the best available Latest Market Price.",
      Term_4_subtitle_2: "Limit Order:",
      Term_4_description_2:
        "A Limit Order is placed to buy or sell a Specific Quantity of the Targeted Token at a Specific Price and shall be filled at such Specific Quantity and Specific Price.",
      Term_4_subtitle_3: "Stop Market Order:",
      Term_4_description_3:
        "A Stop Market Order is placed to buy a Specific Quantity of Targeted Token or to buy a Specific Gross Amount of Targeted Token at the Latest Market Price when the upper limit of the Preset Stop Price is triggered. This means that when the Latest Market Price reach or beyond the upper limit of the Preset Stop Price, this order will be executed and filled at the Latest Market Price which may end up higher than the upper limit of the Preset Stop Price.",
      Term_4_subtitle_4: "Stop Limit Order:",
      Term_4_description_4:
        "A Stop Limit Order is placed to sell a Specific Quantity of Targeted Token at the Specific Price when the inferior limit of Preset Stop Price is triggered. This means that when the Latest Market Price reaches or is below the inferior limit of the Preset Stop Price, this order will be executed and filled at the Specific Quantity as well as the Specific Price.",
      Term_4_subheader_2: "Easy card",
      Term_4_description_5:
        "Refers to transactions in which Gabur tokens are exchanged for fiat currency and deposited in the account of the user.",
      Term_5_title: "5- Scope of Service and Your Rights and Obligations",
      Term_5_description_1:
        "Users shall not register multiple accounts for any purpose. Users may not use another User’s accounts. Users are prohibited in any form from utilizing the Site to engage in illegal activities. Without the authorization or permission of Easy Pro, the User shall not use any of the commercial activities in the name of this Site or in any form of the Site as a place, platform, or medium for engaging in commercial activities.",
      Term_5_description_2:
        "Users shall comply with all laws and regulations and bear the responsibility and legal consequences of any of their own actions involving the Site and Services.",
      Term_5_description_3:
        "Users have the right to view the information regarding their accounts on our Platform and have the right to operate the functions provided by our Platform. The user understands and agrees that our Platform can adjust the service content, service type, and service form on the Platform at any time according to the actual situation at our sole discretion. Our Platform is not responsible for any negative impact or loss caused to you or any third party due to Platform’s adjustments.",
      Term_5_description_4:
        "Depending on your country of residence, you may not be able to use all the functions of the Site or the Platform. Without prejudice to our right of suspending certain services to users from a specific country, it is your responsibility to ensure that in accessing our Site, Platform, and services, you are complying with the laws and regulations in your country of residence and/or the country from which you access this Site, the Platform, and our services. you are responsible for any disputes or losses caused by your failure to promptly modify or confirm the status of the transaction or failure to submit the relevant application. Our Platforms do not assume any responsibility.",
      Term_5_description_5:
        "Engaging in trades in cryptographic tokens may be highly risky. Please do not use Easy Pro’s services for trading in Digital assets if you do not understand these risks.",
      Term_6_title: "6- Registration Process",
      Term_6_description_1:
        "You agree to provide a valid email address, a mobile phone number, and other information in accordance with the requirements on the user registration page of this platform. You can use the email address, mobile phone number or any other manner permitted to log in to this platform. Where it is necessary and in accordance with the requirements of applicable laws and regulations of relevant jurisdictions, you shall provide your real name, identity card, and other information required by applicable laws, regulations, the Terms of Privacy, and anti-money-laundering terms, and constantly update your registration data so that they will be timely, detailed, and accurate as is required. All the original typed data will be referenced as registration information. You shall be responsible for the authenticity, integrity, and accuracy of such information and bear any direct or indirect loss and adverse consequences arising out of it.",
      Term_6_description_2:
        "If any of the applicable laws, regulations, rules, orders, and other regulatory documents of the sovereign country or region in which you are based requires that mobile phone accounts must be based on real names, you hereby confirm that the mobile phone number you provide for registration purposes has gone through the real-name registration procedure. If you cannot provide such a mobile phone number as is required, any direct or indirect losses and adverse consequences arising therefrom and affecting you shall be borne by you.",
      Term_6_description_3:
        "After you provide the required registration information in a legal, complete, and valid manner and such information passes relevant verification, you shall have the right to obtain an account and a password of Easy exchange pro. Upon obtaining such an account and password, your registration shall be deemed as successful, and you can log into the platform as a member thereof. You agree to receive emails and/or short messages sent by the platform related to the management and operation thereof.",
      Term_6_description_4:
        "Particularly during periods of high volume, illiquidity, fast movement, or volatility in the marketplace for any, or one or more Digital Tokens, the actual market rate at which a trade is executed may be different from the prevailing rate indicated via the Services at the time of your order or trade. You understand that we are not liable for any such price fluctuations. In the event of a market disruption or Force Majeure event, Easy exchange pro may do one or more of the following: suspend access to the Services; or prevent you from completing any actions via the Services, including closing any open positions. Following any such event, when trading resumes, you acknowledge that prevailing market rates may differ significantly from the rates available prior to such an event.",
      Term_6_description_5:
        "You hereby authorize Easy exchange pro to attempt and/or perform any such correction, reversal, cancellation, voiding, or amendment described in this section. Easy exchange pro. provides no guarantee or warranty that any such attempt will be successful and will have no responsibility or liability for the Manifest Error or the making or failure of any correction attempt, except if caused by the gross negligence, willful default, or fraud of Easy exchange pro. There is a risk that you may be prejudiced by any action or omission of Easy exchange pro in this regard, and you accept that you have no recourse whatsoever against Easy exchange pro.",
      Term_7_title: "7- Fees",
      Term_7_description_1:
        "You agree to pay for transactions through our Fees Structure service, which may change from time to time. Changes to the Fee Structure are effective as of the effective date indicated in the posting of the revised Fee Structure to the Services and will apply prospectively to any trades that take place following the effective date of such revised Fee Structure.",
      Term_7_description_2:
        "In addition to the costs, your external account may incur costs in connection with your use of your designated external account through the Services. Costs incurred by your external account provider are not reflected on the Transactions page, which contains information about applicable costs. You are solely responsible for paying any fees imposed by an External Account provider.",
      Term_7_description_3:
        "You authorize us, or our designated payment processor, to charge or deduct your Easy Pro Account Funds for any applicable Fees owed in connection with trades you complete via the Services.",
      Term_8_title: "8- Communication",
      Term_8_description_1:
        "You agree and consent to receive electronically all communications, agreements, documents, receipts, notices, and disclosures (collectively, “Communications”) that Easy Pro provides in connection with your Easy Pro Account and/or use of the Easy Pro Services.",
      Term_8_description_2:
        "Updating Contact Information. It is your responsibility to keep your email address on file with Easy Pro up to date so that Easy exchange pro can communicate with you electronically.",
      Term_9_title: "9- Intellectual Property",
      Term_9_description:
        "Unless otherwise indicated by us, all copyright and other intellectual property rights in all content and other materials contained on our website/platform or provided in connection with the Services, including, without limitation, the Easy Pro logo and all designs, text, graphics, pictures, information, data, software, sound files, other files and the selection and arrangement thereof are the proprietary property of Easy Pro or our licensors or suppliers and are protected by international copyright laws and other intellectual property rights laws.",
      Term_10_title: "10- Unclaimed or Disputed Property",
      Term_10_description_1:
        "If for any reason Easy Pro is holding Funds in your Easy  Account, such Funds remain unclaimed, and Easy Pro is unable to return your Funds to your designated External Account after a period of three years after your last login to Easy Pro or any specified period notified by Easy Pro, and Easy Pro determines in good faith that it is not able to trace you, you agree that all such Funds shall be deemed to have been abandoned by you in favor of Easy Pro and may be appropriated by Easy Pro to and for itself, and you thereafter shall have no right to claim such Funds.",
      Term_10_description_2:
        "If Easy Pro receives notice that any Digital Tokens held in your Easy Pro Account are alleged to have been stolen or otherwise are not lawfully possessed by you, Easy Pro may, but has no obligation to, place an administrative hold on or freeze the affected Digital Tokens or your Easy Pro Account. If Easy Pro does place an administrative hold on or freeze some or all your Digital Tokens, Easy Pro may continue such hold until such time as the dispute has been resolved and evidence of the resolution acceptable to Easy Pro has been provided to Easy Pro in a form acceptable to Easy Pro. Easy Pro will not involve itself in any such dispute or the resolution of the dispute. You agree that Easy Pro will have no liability or responsibility for any losses, damages, or prejudice arising from or in connection with such hold or freeze, or for your inability to withdraw Digital Tokens or execute trades during the period of any such hold or freeze.",
      Term_11_title: "11- Complains about your local laws",
      Term_11_description:
        "It is your sole responsibility to determine whether, and to what extent, any taxes apply to any transactions You conduct through the Services, and to withhold, collect, report, and remit the correct amounts of taxes to the appropriate tax authorities. When using our Services, you acknowledge that your actions are in a legal and proper manner and your financial sources are not from illegal activities. We may discretionarily or in coordination with local law enforcement authorities seize, restrict or close out your account, fiat, and/or cryptocurrencies.",
      Term_12_title: "12- Suspension; Termination",
      Term_12_description_1:
        "In the event of any Force Majeure Event breach of these. Terms or Conditions, or any other event where the provision of services to Easy pro is commercially unreasonable, we may, at our sole discretion and without liability to you, with or without prior notice, grant you access to all or part of Suspend it. We may also immediately, without notice, terminate your access to the Services at our sole discretion and delete or deactivate your Easy exchange pro account and all related information and files on this account without liability to you, including in cases where you are violating one of these conditions. In the event of discontinuation of all Services or termination of your access to the Services or deletion or deactivation of your Easy Pro Account: all amounts payable by you to Easy Pro will immediately become due. the platform can cancel any open orders or other transaction requests that are pending at the time of discontinuation or termination or deletion or deactivation. the platform will, where possible, return any Funds stored in your Easy Exchange pro Account not otherwise owed to Easy Pro and/or will use commercially reasonable efforts to provide you with a period 4 months to transfer affected Digital Tokens from your Easy Pro Account, unless prohibited by applicable laws or regulations or by order of law enforcement or governmental authority, or Easy Pro believes you have committed fraud, negligence or other misconduct; and/or may take such other steps as Easy Pro deems necessary or desirable to protect its own interests. We are not and shall not be responsible or liable for any loss or damages incurred because of or arising from any actions taken under this section.",
      Term_12_description_2:
        "Also, if the user has not made any deposits, transactions, or logins for a period of three months by any reason, we may delete or deactivate the user's account, without notifying the User and without assuming any responsibility",
      Term_13_title: "13- Descramble of liability",
      Term_13_subtitle_1: "13.1 Network Service Provider:",
      Term_13_description_1:
        'as a third-party platform for "network service provider", does not guarantee the information and services provided on this Site can fully meet the needs of users. We shall not be liable for errors, insults, defamation, nonfeasance, obscenity, pornography, or blasphemy that may occur during the process of acceptance of the Parties’ web service.',
      Term_13_subtitle_2: "13. 2 Service Interruption:",
      Term_13_description_2:
        "Based on the special nature of the Internet, we do not guarantee that the service will not be interrupted; the timeliness and security of the service are also not guaranteed.",
      Term_13_subtitle_3: "13.3 Safety of the Network:",
      Term_13_description_3:
        "We try to provide a safe network environment to the users; however, we do not guarantee that the Site or its servers are free of viruses or other potentially harmful factors; therefore, the user should use the industry’s recognized software to check and kill any virus in the files downloaded from the Site.",
      Term_13_subtitle_4: "13. 4 User Information:",
      Term_13_description_4:
        "we are not responsible for the failure of preservation, modification, deletion, or storage of the information provided by the user. Nor will we be liable for the typographical errors, negligence, etc. We have the right but no obligation to improve or correct any omission, error of any part of this Site.",
      Term_13_subtitle_5:
        "13. 5 Accuracy, Completeness, and Reliability of the Site:",
      Term_13_description_5:
        "Unless we have expressly agreed in writing, we shall not guarantee the accuracy, completeness, reliability of any content, such as but not limited to, advertising from the platform in any manner (including but not limited to, containing, connecting, by way of, or downloading) from the Site; we are not responsible for any products, services, information or materials purchased or obtained by the user according to the content information on this website. The user bears the risk of using the content of this website.",
      Term_13_subtitle_6: "13. 6 User Opinion:",
      Term_13_description_6:
        "The user comments published by users of the Site, are only on behalf of the user’s personal point of view. It does not mean that this Site agrees with their views or confirms their description. This Site does not bear any legal responsibility caused by any user comments.",
      Term_13_subtitle_7: "13. 7 Announcements:",
      Term_13_description_7:
        "Regarding notices issued to the users, we will deliver these notices through a formal page announcement, station letter, e-mail, customer service phone call, SMS, or regular mail delivery. We do not bear any legal responsibility for any winning, discount activities or information that are delivered by other channels.",
      Term_13_subtitle_8:
        "13. 8 Charges, Commissions, Transactions, and Other Fees:",
      Term_13_description_8:
        "we have the right to adjust the recharge, commission, transaction, and other fees according to market conditions, and terminate any promotional efforts early.",
      Term_14_title: "14- Jurisdiction",
      Term_14_description:
        "These Terms shall be governed by and construed in accordance with the laws of Dubai. The courts of Dubai shall have exclusive jurisdiction to settle any dispute arising from or connected with these Terms (including a dispute relating to the existence, validity, or termination of these Terms or the consequences of their nullity or any non-contractual obligation arising out of or in connection with these Terms).",
      Term_15_title: "15. Miscellaneous",
      Term_15_description_1:
        "These Terms set forth the complete understanding of the parties with respect to the subject matter hereof and supersedes all prior understandings and communications relating thereto our Platforms reserve the right of final interpretation of these Terms within the scope permitted by law. These Terms and related pages of our Platforms may refer to each other. If there is any conflict, these Terms shall prevail. In addition, if some of the provisions in these Terms are deemed invalid or unenforceable, the other provisions in these Terms will remain in effect. In the event of any conflict between these Terms and any other agreement, you may have with Easy Exchange pro the terms of that other agreement will prevail only if these Terms are specifically identified and declared to be overridden by such other agreement.",
      Term_15_description_2:
        "Any failure or delay by Easy Exchange Pro to enforce any of these Terms or to exercise any right hereunder shall not be construed as a waiver to any extent of our rights.",
      Term_16_title: "16- Limitation of Liability",
      Term_16_description:
        "Except as otherwise required by law, in no event shall Easy exchange pro, our directors, officers, employees, agents, Brokers or contractors be liable for any special, indirect, or consequential damages, or any other damages of any kind, including but not limited to loss of use, loss of profits or loss of data, whether in an action in contract, tort (including but not limited to negligence) or otherwise, arising out of or in any way connected with the use of or inability to use our Services or the Easy exchange pro Materials, including without limitation any damages caused by or resulting from reliance by any user on any information obtained from Easy exchange pro , or that result from mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses, delays in operation or transmission or any failure of performance, whether or not resulting from a force majeure event, communications failure, theft of, destruction of or unauthorized access to Easy exchange pro’s records, programs or services. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the limitations of this section may not apply to you.",
      Term_17_title: "17- Miscellany",
      Term_17_subtitle_1: "17 -1 Agency",
      Term_17_description_1:
        "Nothing in this Agreement shall be deemed to have created, implied, or otherwise treated us as your agent, trustee, or another representative unless it is provided otherwise in this Agreement.",
      Term_17_subtitle_2: "17- 2 Waiver",
      Term_17_description_2:
        "Our or your waiver of the right to hold the other party liable for breaches of agreement or any other liability as is agreed upon in this Agreement shall not be construed or deemed as a waiver of the right to hold the other party for other breaches of contract; a failure to exercise any right or remedy shall not be construed in any way as a waiver of such right or remedy.",
      Term_17_subtitle_3: "17- 3 Goodwill",
      Term_17_description_3:
        "You acknowledge and agree that, before filing any dispute or claim, you will contact us through support@easyexchangepro.com to solve the dispute in an informal way where we will try to solve the relevant dispute internally as soon as possible; The Parties agree to negotiate and solve any dispute in good faith (such negotiation shall be confidential and protected under applicable rules, and will not be used as evidence in any proceeding).",
      Term_17_subtitle_4: "17- 4 Assignment of rights",
      Term_17_description_4:
        "The rights and obligations agreed in this Agreement shall be equally binding on the assignees, the heirs, executors, and administrators of the parties hereto who benefit from the rights and obligations. Without our consent, you may not transfer to any third party any of your rights or obligations hereunder, provided, however, we may, at any time, assign our rights and obligations under this Agreement to any third party with forty (40) days' notices to you.",
      Term_17_subtitle_5: "17- 5 Severability",
      Term_17_description_5:
        "The invalidity or unenforceability of any of these Terms shall not affect the validity or enforceability of any other of these Terms, all of which shall remain in full force and effect.",
      Term_17_subtitle_6: "17- 6 Governing language and translations",
      Term_17_description_6:
        "These Terms, the Privacy Policy, and other agreements or communications notified through the Services have been drafted in English. Although translations in other languages of any of the foregoing documents may be available, such translations may not be up to date or complete. Accordingly, you agree that in the event of any conflict between the English language version of the foregoing documents and any other translations thereof, the English language version of such documents shall govern and prevail.",
      // Token Listing
      TL_title: "Tokenunuzun listelenmesi için formu doldurun",
      TL_Exchange_info: "Borsa Bilgileri",
      TL_Token_Name: "Token Adı",
      TL_Token_Name_Error: "Lütfen Token adını girin.",
      TL_Token_symbol: "Token simgesi",
      TL_Token_symbol_Error: "Lütfen Token simgesini girin.",
      TL_Token_Symbol_upload_Error: "Lütfen Token Simgesini yükleyin.",
      TL_NFT: "NFT",
      TL_Total_supply_Error: "Lütfen Toplam arzı girin.",
      TL_Date_of_issue: "Yayın tarihi",
      TL_Date_of_issue_Error: "Lütfen Yayın tarihini girin.",
      TL_Total_circulation: "Toplam dolaşım",
      TL_Total_circulation_Error: "Lütfen Toplam dolaşımı girin.",
      TL_Market_cap_circulation: "Piyasa değeri dolaşımı",
      TL_Market_cap_circulation_Error: "Lütfen Piyasa değeri dolaşımını girin.",
      TL_Source_code: "Kaynak kodu",
      TL_Source_code_Error: "Lütfen Kaynak kodunu girin.",
      TL_Whitepaper: "Beyaz kağıt",
      TL_Whitepaper_Error: "Lütfen Beyaz kağıdı yükleyin.",
      TL_Communities: "Topluluklar",
      TL_Telegram: "Telegram",
      TL_Telegram_Error: "Lütfen Telegram adresini girin.",
      TL_Twitter: "Twitter",
      TL_Twitter_Error: "Lütfen Twitter adresini girin.",
      TL_Facebook: "Facebook",
      TL_Facebook_Error: "Lütfen Facebook adresini girin.",
      TL_Reddit: "Reddit",
      TL_Reddit_Error: "Lütfen Reddit adresini girin.",
      TL_Discord: "Discord",
      TL_Discord_Error: "Lütfen Discord adresini girin.",
      TL_Submitter_info: "Gönderici bilgileri",
      TL_Role: "Rol",
      TL_Role_Error: "Lütfen Rolü girin.",
      TL_Personal_ID: "Kişisel Kimlik",
      TL_Personal_ID_Error: "Lütfen Kişisel Kimliği yükleyin.",
      TL_About_project: "Proje hakkında",
      TL_About_project_Error: "Lütfen Proje hakkında bilgi girin.",
      TL_About_Private: "Özel Satış, ICO/IEO/IDO Hakkında",
      TL_About_Private_Error:
        "Lütfen Özel Satış, ICO/IEO/IDO hakkında bilgi girin.",
      TL_Distribution_plan: "Dağıtım planı",
      TL_Distribution_plan_Error: "Lütfen Dağıtım planını girin.",
      TL_CEO_info: "CEO bilgisi/CV Linkedin",
      TL_CEO_info_Error: "Lütfen CEO bilgisi/CV Linkedin adresini girin.",
      TL_Policy: "Formunuzu Göndererek Gizlilik Politikamızı Kabul Edersiniz",
      // Deposit
      Deposit_select_Token: "Lütfen Token seçin:",
      Deposit_select_Network: "Lütfen Ağ seçin:",
      Deposit_Address: "Adres",
      Deposit_attention: "Lütfen çekme adresine ve seçilen ağa dikkat edin.",
      Deposit_Network: "Ağ",
      Deposit_Warning:
        "Yanlış adrese veya yanlış ağla yatırım yaparsanız, varlıklarınızı kaybedersiniz.",
      // Transfer
      Transfer_Transfer: "Transfer",
      Transfer_select_Error: "Lütfen token seçin",
      Transfer_Balance_Error: "Yetersiz bakiye.",
      Transfer_Main_to_Trade: "Ana hesaptan Ticaret hesabına",
      Transfer_Trade_to_Main: "Ticaret hesabından Ana hesaba",
      // Withdraw
      Withdraw_balance_Error: "Yetersiz bakiye",
      Withdraw_Amount_low_Error: "Çekim miktarı ",
      Withdraw_Amount_high_Error: "Çekim miktarı ",
      Withdraw_2FA_Error_1: "Çekim için",
      Withdraw_2FA_Error_2: "İki Faktörlü Kimlik Doğrulama (2FA)",
      Withdraw_2FA_Error_3: "etkinleştirmeniz gerekmektedir.",
      Withdraw_KYC_Error_1: "Çekim yapmak için",
      Withdraw_KYC_Error_2: "KYC Süreci'ni geçmeniz gerekmektedir.",
      Withdraw_KYC_Error_3: "",
      Withdraw_Goto_Profile: "Profil sayfasına gitmek için tıklayın",
      Withdraw_min: "Min çekim miktarı",
      Withdraw_max: "Max çekim miktarı",
      Withdraw_fee: "Çekim Ücreti",
      Withdraw_Unconfirmed_attention_1: "Lütfen seçtiğiniz ",
      Withdraw_Unconfirmed_attention_3: " ve Seçilen ",
      Withdraw_Unconfirmed_attention_4: "AĞI",
      Withdraw_Unconfirmed_attention_5:
        " kontrol edin, yanlış ağ adresine gönderirseniz varlıklarınızı kaybedersiniz ve EasyPro Platformu, kaybolan varlıklardan herhangi bir sorumluluk taşımaz.",
      Withdraw_Selected_token: "Seçilen Token",
      Withdraw_Selected_Network: "Seçilen Ağ",
      Withdraw_Confirm_Mail_2FA:
        "E-posta ve Google kimlik doğrulamayı onaylayın",
      Withdraw_Confirmed: "Çekim Onaylandı",
      Withdraw_Confirmed_attention_1: "Lütfen ",
      Withdraw_Confirmed_attention_2: "E-POSTA GELİŞ KUTUNUZU",
      Withdraw_Confirmed_attention_3: " ve ",
      Withdraw_Confirmed_attention_4: "ONAYLA",
      Withdraw_Confirmed_attention_5:
        " düğmesine tıklayarak çekim işlemi için size gönderilen e-postayı onaylayın.",
    },
  },
  fa: {
    translation: {
      ///////////////////// GLOBAL
      Already_Purchased: "قبلا خریداری شده است",
      Global_Products: "محصولات",
      Global_Transaction: "تراکنش",
      Global_Icon: "آیکون",
      Global_Pair: "زوج",
      Global_LastPrice: "قیمت آخرین",
      Global_Price: "قیمت",
      Global_24h: "24 ساعت",
      Global_High: "بالا",
      Global_Low: "پایین",
      Global_Change: "تغییر",
      Global_Volume: "حجم",
      Global_Trade: "معامله",
      Global_EasyGuarantee: "ضمانت آسان",
      Global_ApplicationForm: "فرم درخواست",
      Global_EasyCard: "کارت آسان",
      Global_Secure: "امن",
      Global_Fast: "سریع",
      Global_Convert: "تبدیل",
      Global_Gabur: "گابور",
      Global_Token: "توکن",
      Global_Total: "مجموع",
      Global_Amount: "مقدار",
      Global_USDT: "USDT",
      Global_Stop: "توقف",
      Global_Limit: "محدودیت",
      Global_Supply: "عرضه",
      Global_Whitepaper: "کاغذ سفید",
      Global_Circulation: "گردش",
      Global_Website: "وبسایت",
      Global_Buy: "خرید",
      Global_Sell: "فروش",
      Global_Loading: "بارگذاری...",
      Global_Submit: "ارسال",
      Global_Cancel: "لغو",
      Global_Confirm: "تأیید",
      Global_Close: "بستن",
      Global_OTP: "OTP",
      Global_Email: "ایمیل",
      Global_2FA: "2FA",
      Global_Password: "رمز عبور",
      Global_FName: "نام",
      Global_LName: "نام خانوادگی",
      Global_IDNum: "شماره شناسنامه",
      Global_Login: "ورود",
      Global_Register: "ثبت نام",
      Global_Transfer: "انتقال",
      Global_UID: "شناسه کاربری",
      Global_Purpose: "هدف",
      Global_Mobile: "موبایل",
      Global_Deposit: "سپرده",
      Global_Withdraw: "برداشت",
      Global_Fee: "هزینه",
      Global_Fees: "هزینه ها",
      Global_OTC: "OTC",
      Global_Support: "پشتیبانی",
      Global_Download: "دانلود",
      Global_From: "از",
      Global_To: "به",
      Global_Now: "اکنون",
      Global_Digital: "دیجیتال",
      Global_crypto: "رمز ارز",
      Global_cash: "نقدی",
      Global_wallet: "کیف پول",
      Global_account: "حساب",
      Global_Exchange: "صرافی",
      Global_trading: "معامله گری",
      Global_Accept_Terms:
        "با تیک زدن در این جعبه، شرایط و ضوابط ما را قبول می کنید.",
      Global_Available: "موجود",
      Global_Username: "نام کاربری",
      Global_Time: "زمان",
      Global_Status: "وضعیت",
      Global_Network_Address: "آدرس شبکه",
      Global_Max_upload: "حداکثر حجم بارگذاری برای هر فایل 10 مگابایت",
      Global_Policy: "با ارسال فرم، سیاست حفظ حریم خصوصی ما را قبول می کنید.",
      Global_UID_Error: "لطفاً شناسه کاربری را وارد کنید.",
      Global_IDNum_Error: "لطفاً شماره شناسنامه را وارد کنید.",
      Global_Mobile_Error: "لطفاً شماره موبایل را وارد کنید.",
      Global_Price_Error: "لطفاً قیمت را وارد کنید.",
      Global_Stop_Error: "لطفاً قیمت توقف را وارد کنید.",
      Global_Amount_Error: "لطفاً مقدار را وارد کنید.",
      Global_Total_Error: "لطفاً مجموع را وارد کنید.",
      Global_Email_Error: "لطفاً ایمیل را وارد کنید.",
      Global_Password_Error: "لطفاً رمز عبور را وارد کنید.",
      Global_FName_Error: "لطفاً نام را وارد کنید.",
      Global_LName_Error: "لطفاً نام خانوادگی را وارد کنید.",
      Global_Website_Error: "لطفاً آدرس وبسایت را وارد کنید.",
      Global_Purpose_Error: "لطفاً هدف درخواست را توضیح دهید.",
      Global_Accept_Terms_Error: "قبل از ارسال باید موافقت کنید.",
      Global_about: 'درباره ما',
      Global_coin_info: "اطلاعات سکه",
      Global_token_listing: "فهرست توکن",
      Global_logout: "خروج",
      // Home
      //// Top
      Home_Top_Download_From: "دانلود از",
      Home_Top_Playstore: "پلی استور",
      Home_Top_Download_APK: "دانلود APK",
      Home_Top_APPstore: "اپ استور",
      Home_Top_Register_Now: "همین حالا ثبت نام کنید",
      //// carousel
      Home_Carousel_1_title: "شفافیت و امنیت",
      Home_Carousel_1_description:
        "ما جزئیات شفافیت در مورد توکن گابور را در کاغذ سفید خود ارائه داده ایمتا برای شما در تصمیم گیری در مورد سرمایه گذاری قابل مشاهده باشد. علاوه بر این، قرارداد هوشمند توکن گابور مورد بازبینی قرار گرفته است تا بالاترین سطح امنیت را تضمین کند.",
      Home_Carousel_2_title: "ضمانت آسان",
      Home_Carousel_2_description:
        "برای اولین بار در دنیای دیجیتال، Easy Exchange Pro سیستم ضمانت آسان را برای انتقال وجه بین شرکت ها و قراردادهای تجاری ارائه می دهد.",
      Home_Carousel_3_title: "پشتیبانی 24/7",
      Home_Carousel_3_description:
        "شما می توانید با استفاده از سیستم تیکت با تیم پشتیبانی تماس بگیرید یا ایمیلی به آدرس support@easyexchangepro.com ارسال کنید.",
      //// Easy Guarantee
      Home_EG_subtitle: "اولین ضمانت دیجیتال در دنیای رمزارز",
      Home_EG_description:
        "برای اولین بار در دنیای دیجیتال، Easy Exchange Pro سیستم ضمانت آسان را برای انتقال وجه بین شرکت ها و قراردادهای تجاری ارائه می دهد.",
      Home_EG_more_info_btn: "اطلاعات بیشتر",
      //// Easy Card
      Home_EC_subtitle_1: "تنها راه",
      Home_EC_subtitle_2: "خرید رمزارز با پول نقد",
      Home_EC_description_1: "کد پیش پرداخت Easy Card را نزدیک شما تهیه کنید و",
      Home_EC_description_2:
        "توکن های گابور را در کیف پول خود واریز کنید. امن و قابل اعتماد بدون",
      Home_EC_description_3:
        "بانک یا کارت اعتباری. این تنها راه خرید رمزارز با پول نقد است.",
      Home_EC_Feature_1_title: "مستقل",
      Home_EC_Feature_1_description_1: "خرید رمزارز بدون حساب بانکی یا",
      Home_EC_Feature_1_description_2: "کارت اعتباری",
      Home_EC_Feature_2_description_1: "تمام دارایی های شما در زیر",
      Home_EC_Feature_2_description_2: "کنترل شماست",
      Home_EC_Feature_3_description_1: "پول نقد را در چند ثانیه واریز",
      Home_EC_Feature_3_description_2: "کنید",
      Home_EC_Feature_4_title: "آسان",
      Home_EC_Feature_4_description_1: "شما دارید کاری را انجام",
      Home_EC_Feature_4_description_2: "می دهید که همیشه انجام می دادید",
      Home_EC_Feature_5_title: "بدون هزینه واریز",
      Home_EC_Feature_5_description_1: "واریز پول بدون هیچ هزینه ای",
      Home_EC_Feature_5_description_2: "برای شما",
      Home_EC_Feature_6_description_1: "شما می توانید توکن گابور را به",
      Home_EC_Feature_6_description_2: "ارزهای دیگر تبدیل کنید.",
      Home_EC_Where_to_buy: "جای خرید",
      //// Mobile Section
      Home_MS_title: "شروع معامله آسان با اپلیکیشن موبایل Easy Exchange Pro.",
      Home_MS_list_1: "دسترسی 24 ساعته به دارایی های خود در هر مکان",
      Home_MS_list_2: "امنیت و اعتماد",
      Home_MS_list_3: "رابط کاربری ساده و دوستانه",
      Home_MS_list_4: "ابزارهای معاملات پیشرفته",
      Home_MS_list_5: "اپلیکیشن همه کاره",
      Home_MS_dl_for_ios: "دانلود برای iOS",
      Home_MS_dl_for_android: "دانلود برای اندروید",
      //about
      //// A Top video
      About_top_title: "ماموریت ما",
      About_top_desciption:
        "ماموریت ما در ایجاد یک اکوسیستم است که به همه افراد حق دسترسی آسان به دنیای ارزهای دیجیتال و در نتیجه آزادی مالی را می دهد.",
      About_top_Feature_1: "600,000",
      About_top_Feature_1_description_1: "",
      About_top_Feature_1_description_2: "کاربران ثبت نام شده",
      About_top_Feature_2: "2 موقعیت",
      About_top_Feature_2_description: "موقعیت ما در 2 کشور",
      About_top_Feature_3: "+28 کشور",
      About_top_Feature_3_description: "ارائه خدمات در بیش از 28 کشور",
      ///// A Second
      About_second_title: "چرا انتخاب Easy Exchange Pro؟",
      About_second_subtitle: "آسان برای مبتدیان و پیشرفته برای حرفه ای ها",
      About_second_description_1:
        "اگرچه دنیای رمزارزها ممکن است کمی پیچیده باشد، ما اینجا هستیم تا کمکتان کنیم! پلتفرم ما معاملات را آسان می کند.",
      About_second_description_2:
        "ما ابزارهایی را برای خرید و فروش رمزارزهای محبوب را در یک مکان فراهم می کنیم، در حالی که با استفاده از روش های امنیتی پیشرو در صنعت محافظت می شوید.",
      About_second_Feature_1_description_1: "تحت حفاظت از امنیت برتر صنعت",
      About_second_Feature_1_description_2: "بر اساس فناوری رمزنگاری",
      About_second_Feature_2_title: "آسان در استفاده",
      About_second_Feature_2_description_1:
        "ما یک پلتفرم ساده و آسان طراحی کرده ایم",
      About_second_Feature_2_description_2:
        "و آن را در اختیار شما قرار داده ایم",
      About_second_Feature_3_title: "استفاده پیشرفته (برای حرفه ای ها)",
      About_second_Feature_3_description_1:
        "ما یک رابط معاملاتی پیشرفته توسعه داده ایم",
      About_second_Feature_3_description_2:
        "که تجربه حرفه ای را برای کاربرانمان فراهم می کند",
      About_second_Feature_4_description_1: "معامله ارزهای مورد علاقه خود را",
      About_second_Feature_4_description_2: "در لحظه انجام دهید",
      About_second_Feature_5_title: "خرید با پول نقد",
      About_second_Feature_5_description_1: "آسان ترین راه برای خرید رمزارز",
      About_second_Feature_5_description_2: "با پول نقد، انحصاری Easy Card است",
      About_second_Feature_6_title: "24/7",
      About_second_Feature_6_description_1:
        "شروع معامله رمزارزها و شما در هر لحظه از پشتیبانی ما پشتیبانی خواهید کرد.",
      About_second_Feature_6_description_2: "",
      //// Road Map
      About_road_title: "نقشه راه",
      About_road_description_1:
        "توسعه Easy Exchange Pro در مدت سه سال سازماندهی شده است.",
      About_road_description_2:
        "در اینجا می توانید بررسی کلی از اهداف ما را که قرار است در سال های آینده انجام شود، بیابید.",
      About_road_year_1: "سال 2022",
      About_road_year_1_description_1: "محصول منحصر به فرد: Easy Guarantee",
      About_road_year_1_description_2: "+ 6 زبان",
      About_road_year_1_description_3: "+ 10 کارگزار در 5 کشور",
      About_road_year_1_description_4: "+ 100 کارمند بیشتر",
      About_road_year_1_description_5: "+ 10 جفت ارز دیگر",
      About_road_year_2: "سال 2023",
      About_road_year_2_description_1: "محصول جدید، Easy Wallet",
      About_road_year_2_description_2: "آکادمی Easy Pro",
      About_road_year_2_description_3: "API برای یکپارچگی کسب و کار",
      About_road_year_2_description_4:
        "کارت آسان روشی کارآمد برای معاملات خواهد بود",
      About_road_year_2_description_5: "+ 6 زبان",
      About_road_year_2_description_6: "+ 200 کارگزار در 16 کشور",
      About_road_year_2_description_7: "+ 5 ارز فیات",
      About_road_year_2_description_8: "+ 250 کارمند",
      About_road_year_2_description_9: "+ 30 جفت ارز",
      About_road_year_2_description_10: "+ 100 سکه قابل اعتماد دیگر",
      About_road_year_2_description_11:
        "لیستینگ GABUR در پلتفرم های جهانی دیگر",
      About_road_year_3: "سال 2024",
      About_road_year_3_description_1: "محصول منحصر به فرد: بانک رمزارز",
      About_road_year_3_description_2: "+ 6 زبان",
      About_road_year_3_description_3: "+ 1000 کارمند",
      About_road_year_3_description_4: "+ 100 جفت ارز",
      About_road_year_3_description_5: "+ 130 سکه قابل اعتماد",
      About_road_year_3_description_6: "لیستینگ GABUR در پلتفرم های جهانی دیگر",
      // API Documents
      Api_title: "مستندات API",
      Api_1: "خلاصه",
      Api_2: "دارایی‌ها",
      Api_3: "نماد",
      Api_4: "دفتر سفارشات",
      Api_5: "معاملات",
      Api_6: "حجم ۲۴ ساعته",
      // Asset
      Asset_Tab_1: "تمام دارایی‌ها",
      Asset_Tab_2: "کیف پول اصلی",
      Asset_Tab_3: "کیف پول معاملاتی",
      Asset_Total_BTC: "کل دارایی‌ها به بیتکوین",
      Asset_In_Withdraw: "در حال برداشت",
      Asset_In_Order: "در حال سفارش",
      // Contact us
      ContactUs_email_title: "با ما در تماس باشید:",
      ContactUs_email: "Support@easyexchangepro.com",
      ContactUs_faq_title: "می توانید صفحه پرسش و پاسخ ما را بررسی کنید:",
      ContactUs_faq: "",
      ContactUs_ticket_title:
        "یا می توانید تیکت برقراری تماس با ما ارسال کنید:",
      ContactUs_ticket: "",
      ContactUs_social_title: "ما را در شبکه‌های اجتماعی دنبال کنید:",

      // Coin info
      CoinInfo_Issue_Time: "Issue Time :",
      CoinInfo_White_paper: "Whitepaper:",
      CoinInfo_Website: "Website:",
      CoinInfo_Block_Explorer: "Block Explorer:",
      CoinInfo_AAVE:
        "Aave is an open source and non-custodial protocol enabling the creation of money markets. Users can earn interest on deposits and borrow assets. Deposit interest rate and loan interest rate of both borrowers and lenders are calculated through algorithms based on the platform's borrowing volume and deposit volume, and the platform uses Chainlink's oracles to ensure the fairness of the collateral price.",
      CoinInfo_ADA:
        "Cardano is a decentralised public blockchain and cryptocurrency project and is fully open source. Cardano is developing a smart contract platform which seeks to deliver more advanced features than any protocol previously developed. It is the first blockchain platform to evolve out of a scientific philosophy and a research-first driven approach.",
      CoinInfo_Atom:
        "In a nutshell, Cosmos bills itself as a project that solves some of the “hardest problems” facing the blockchain industry. It aims to offer an antidote to “slow, expensive, unscalable and environmentally harmful” proof-of-work protocols, like those used by Bitcoin, by offering an ecosystem of connected blockchains. The project’s other goals include making blockchain technology less complex and difficult for developers thanks to a modular framework that demystifies decentralized apps. Last but not least, an Interblockchain Communication protocol makes it easier for blockchain networks to communicate with each other — preventing fragmentation in the industry. Cosmos’ origins can be dated back to 2014, when Tendermint, a core contributor to the network, was founded. In 2016, a white paper for Cosmos was published — and a token sale was held the following year. ATOM tokens are earned through a hybrid proof-of-stake algorithm, and they help to keep the Cosmos Hub, the project’s flagship blockchain, secure. This cryptocurrency also has a role in the network’s governance.",
      CoinInfo_AVAX:
        "Avalanche is a layer one blockchain that functions as a platform for decentralized applications and custom blockchain networks. It is one of Ethereum’s rivals, aiming to unseat Ethereum as the most popular blockchain for smart contracts. It aims to do so by having a higher transaction output of up to 6,500 transactions per second while not compromising scalability. This is made possible by Avalanche’s unique architecture. The Avalanche network consists of three individual blockchains: the X-Chain, C-Chain and P-Chain. Each chain has a distinct purpose, which is radically different from the approach Bitcoin and Ethereum use, namely having all nodes validate all transactions. Avalanche blockchains even use different consensus mechanisms based on their use cases. After its mainnet launch in 2020, Avalanche has worked on developing its own ecosystem of DApps and DeFi. Different Ethereum-based projects such as SushiSwap and TrueUSD have integrated with Avalanche. Furthermore, the platform is constantly working on improving interoperability between its own ecosystem and Ethereum, like through the development of bridges.",
      CoinInfo_BNB:
        "From the ICO, Binance issued its own coin called the Binance Coin, with BNB as the symbol. BNB runs natively on Binance chain. The coin was established with a total supply of 200 million. Every quarter, we will destroy BNB based on the trading volume on our crypto-to-crypto platform until we destroy 50% of all the BNB. All transactions will be on the blockchain. We eventually will destroy 100MM BNB, leaving 100MM BNB remaining. The BNB coin itself has multiple forms of utility, essentially being the underlying gas that powers the Binance Ecosystem. The current most prominent use cases include using BNB to: - Pay for trading fees on the exchange, obtaining the equivalent of a 50% discount on trades (during the first year). - Crypto.com has included support for Binance’s BNB token to its platform which includes the MCO Visa Card and mobile app.",
      CoinInfo_BTC:
        "Bitcoin is a cryptocurrency and a digital payment system invented by an unknown programmer, or a group of programmers, under the name Satoshi Nakamoto.It was released as open-source software in 2009.The system is peer-to-peer, and transactions take place between users directly, without an intermediary. These transactions are verified by network nodes and recorded in a public distributed ledger called a blockchain. Since the system works without a central repository or single administrator, bitcoin is called the first decentralized digital currency.Besides being created as a reward for mining, bitcoin can be exchanged for other currencies, products, and services in legal or black markets.As of February 2015, over 100,000 merchants and vendors accepted bitcoin as payment. According to research produced by Cambridge University in 2017, there are 2.9 to 5.8 million unique users using a cryptocurrency wallet, most of them using bitcoin.",
      CoinInfo_Cake:
        "PancakeSwap, built on Binance Smart Chain (BSC), is a decentralized exchange for swapping BEP-20 tokens. It is fast, cheap, and allows anyone to participate. Users can get FLIP (LP token) for staking and get CAKE token as reward. CAKE holders can swap CAKE for SYRUP for additional incentivized staking. PancakeSwap uses an automated market maker (AMM) model. It means that while you trade digital assets on the platform, there isn’t an order book where you’re matched with someone else. Instead, you trade against a liquidity pool. Traders deposit funds into the pool, receiving liquidity provider (or LP) tokens in return. They can use those tokens to reclaim their share, plus a portion of the trading fees.",
      CoinInfo_Doge:
        "Dogecoin is a cryptocurrency focused on actual utility as a currency. We provide fast block times and very low fees which make Dogecoin suitable for usage in micro-transactions but also as payment option for online shops. Dogecoin has been adopted as such by online retailers and can be used easily as means of consumer to consumer money transfer too.",
      CoinInfo_Dot:
        'Polkadot is a global well-known blockchain cross-chain project, which is created by Ethereum co-founder and Ethereum yellow book author Gavin Wood. It aims to solve the communication and data transmission obstacles between different blockchains and was rated as one of the "4 major projects that decide the direction of digital currency development in 2019".',
      CoinInfo_ETC:
        "Ethereum classNameic (ETC) is a hard fork of Ethereum (ETH) that launched in July 2016. Its main function is as a smart contract network, with the ability to host and support decentralized applications (DApps). Its native token is ETC. Since its launch, Ethereum classNameic has sought to differentiate itself from Ethereum, with the two networks’ technical roadmap diverging further and further from each other with time. Ethereum classNameic first set out to preserve the integrity of the existing Ethereum blockchain after a major hacking event led to the theft of 3.6 million ETH.",
      CoinInfo_ETH:
        'Ethereum (ETH) is an open-source, public, blockchain-based distributed computing platform featuring smart contract (scripting) functionality, which facilitates online contractual agreements. Ethereum also provides a cryptocurrency token called "ether", which can be transferred between accounts and used to compensate participant nodes for computations performed. "Gas", an internal transaction pricing mechanism, is used to mitigate spamand allocate resources on the network.The value token of the Ethereum blockchain is called ether. It is listed under the diminutive ETH and traded on cryptocurrency exchanges. It is also used to pay for transaction fees and computational services on the Ethereum network.',
      CoinInfo_Fil:
        "The InterPlanetary File system (IPFS) is a global, peer-to-peer distributed version of the file system that aims to supplement (or even replace) the hypertext transfer protocol (HTTP) that currently dominates the internet, by connecting all computing devices with the same file system. The principle is to replace the address based on the domain name with a content-based address, that is, the user is looking for content that is not an address but stored in a certain place. There is no need to verify the identity of the sender, but only the hash of the content. It can ensure the web to be faster, safer, more robust and more durable.Filecoin is an incentive layer on IPFS and a a decentralized storage market built on IPFS based on the token incentive model.FIL6 is the Filecoin contract issued 6 months after its launch",
      CoinInfo_GBR:
        "Gabur (GBR) is a utility token for the Easy Exchange Pro platform. The Gabur is using for the Easy Card, a system to buy crypto without cash, and also Easy Guarantee which is a system to guarantee payment between companies and their customers.",
      CoinInfo_LTC:
        "Litecoin is a peer-to-peer Internet currency that enables instant, near-zero cost payments to anyone in the world. Litecoin is an open source, global payment network that is fully decentralized without any central authorities. Mathematics secures the network and empowers individuals to control their own finances. Litecoin features faster transaction confirmation times and improved storage efficiency than the leading math-based currency. With substantial industry support, trade volume and liquidity, Litecoin is a proven medium of commerce complementary to Bitcoin",
      CoinInfo_Matic:
        "Matic Network is a layer-2 scaling solution with side-chains for off-chain computation, while ensuring asset security using the Plasma framework and a decentralized network of Proof-of-Stake (PoS) validators. It aims to offer scalable and instant blockchain transactions. By utilizing adapted version of the Plasma framework, the system is built on PoS checkpoints that are pushed to the Ethereum main-chain. As a result, it is expected to allow each Matic sidechain to theoretically achieve 2^16 transactions per block. Matic Network has been a significant contributor to the Ethereum ecosystem, having worked on implementations of Plasma MVP (Minimum Viable Plasma), the WalletConnect protocol, and Dagger, a popular Ethereum event notification engine. Many projects have already been building applications on and integrating with Matic. MATIC is an ERC-20 token running on Ethereum, it used to pay for services on the Matic Network but also serves as a settlement currency between users who operate within the Matic ecosystem.",
      CoinInfo_MKR:
        "Maker (MKR) is the governance token of the MakerDAO and Maker Protocol — respectively a decentralized organization and a software platform, both based on the Ethereum blockchain — that allows users to issue and manage the DAI stablecoin. Initially conceived in 2015 and fully launched in December 2017, Maker is a project whose task is to operate DAI, a community-managed decentralized cryptocurrency with a stable value soft-pegged to the US dollar. MKR tokens act as a kind of voting share for the organization that manages DAI; while they do not pay dividends to their holders, they do give the holders voting rights over the development of Maker Protocol and are expected to appreciate in value in accordance with the success of DAI itself. The Maker ecosystem is one of the earliest projects on the decentralized finance (DeFi) scene: the industry that seeks to build decentralized financial products on top of smart-contract-enabled blockchains, such as Ethereum.",
      CoinInfo_Near:
        "NEAR Protocol is a layer-one blockchain that was designed as a community-run cloud computing platform and that eliminates some of the limitations that have been bogging competing blockchains, such as low transaction speeds, low throughput and poor interoperability. This provides the ideal environment for DApps and creates a developer and user-friendly platform. For instance, NEAR uses human-readable account names, unlike the cryptographic wallet addresses common to Ethereum. NEAR also introduces unique solutions to scaling problems and has its own consensus mechanism called “Doomslug.” NEAR Protocol is being built by the NEAR Collective, its community that is updating the initial code and releasing updates to the ecosystem. Its declared goal is to build a platform that is “secure enough to manage high value assets like money or identity and performant enough to make them useful for everyday people.” Flux, a protocol that allows developers to create markets based on assets, commodities, real-world events, and Mintbase, an NFT minting platform are examples of projects being built on NEAR Protocol.",
      CoinInfo_TRX:
        "Tron is one of the largest blockchain based operating systems in the world. TRON is an ambitious project dedicated to building the infrastructure for a truly decentralized Internet. The Tron Protocol, one of the largest blockchain based operating systems in the world, offers scalable, high-availability and high-throughput support that underlies all the decentralized applications in the TRON ecosystem. TRON Protocol and the TVM allow anyone to develop DAPPs for themselves or their communities, with smart contracts making decentralized crowdfunding and token issuance easier than ever. Tron DAPP projects already include Peiwo, Obike, Uplive, game.com, Kitty live and Mico, with 100M+ active users from more than 100 countries and regions around the world.",
      CoinInfo_XLM:
        "Stellar network is a free, open-source network that connects diverse financial systems and lets anyone build low-cost financial services—payments, savings, loans, insurance—for their community. It is supported by Stellar.org, a Silicon Valley based non-profit organization. The Stellar network enables money to move directly between people, companies and financial institutions as easily as email. This inter-connectivity means more access for individuals, lower costs for banks, and more revenue for businesses. Token use: Stellar is an open-source, decentralized protocol for digital currency to fiat currency transfers which allows cross-border transactions between any pair of currencies.",
      CoinInfo_XRP:
        "Ripple (XRP) is an independent digital asset that is native to the Ripple Consensus Ledger. With proven governance and the fastest transaction confirmation of its kind, XRP is said to be the most efficient settlement option for financial institutions and liquidity providers seeking global reach, accessibility and fast settlement finality for interbank flows.",
      CoinInfo_ZEC:
        "NZcash is a decentralized cryptocurrency focused on privacy and anonymity. It uses the zk-SNARK zero-knowledge proof technology that allows nodes on the network to verify transactions without revealing any sensitive information about those transactions. Contrary to a common misunderstanding, the majority of cryptocurrencies on the market, including Bitcoin (BTC), are not anonymous, but rather pseudonymous; while they do not explicitly reveal the identities of their users, each user has their own public address or addresses which can be traced back to them via the methods of data science and blockchain forensics. Zcash transactions, on the other hand, still have to be relayed via a public blockchain, but unlike pseudonymous cryptocurrencies, ZEC transactions by default do not reveal the sending and receiving addresses or the amount being sent. There is an option, however, to reveal this data for the purposes of auditing or regulatory compliance. Zcash was first released on October 28, 2016, and it was originally based on Bitcoin’s codebase.",
      // Easy Card
      //// Where To Buy
      ///// Top
      EC_WT_Title: "خرید کارت ایزی",
      EC_WT_Description:
        "شما می توانید کارت ایزی را از یکی از بروکرهای ما نزدیک به شما خریداری کرده و حساب خود را بدون ویزا یا کارت اعتباری شارژ کنید.",
      EC_WT_Find_Title: "یافتن یک بروکر نزدیک به شما",
      EC_WT_Find_btn: "یافتن",
      EC_WT_Find_input: "شهر خود را وارد کنید",
      ///// Apply
      EC_WA_title:
        "درخواست دریافت مجوز کارت ایزی و تبدیل شدن به یکی از شرکای ما و کسب سود.",
      EC_WA_Decription: "درخواست",
      ///// Start
      EC_WS_Question: "چگونه کارت ایزی کار می کند؟",
      EC_WS_title: "شروع به سه مرحله آسان",
      EC_WS_SubTitle: "تمام فرآیند در کمتر از 3 دقیقه.",
      EC_WS_Feature_1_description:
        "یک بروکر نزدیک به شما را پیدا کنید و کارت ایزی را خریداری کنید",
      EC_WS_Feature_2_description:
        "کد کارت ایزی خود را وارد کنید تا توکن های گابور را در کیف پول خود واریز کنید",
      EC_WS_Feature_3_description: "اکنون می توانید شروع به معامله کنید",
      //// Already Purchased
      EC_AP_description:
        "کد خود را در اینجا وارد کنید ، توکن های گابور به طور خودکار در کیف پول شما وارد می شوند.",
      EC_AP_Code: "کد خود را وارد کنید",
      EC_AP_Code_Error: "لطفاً کد ۲۰ رقمی را وارد کنید.",
      //// Broker
      ///// Register
      EC_BR_title: "ثبت نام به عنوان یک بروکر",
      ///// Form
      EC_BR_Exchange_info: "اطلاعات صرافی",
      EC_BR_Legal: "نام قانونی",
      EC_BR_Legal_Error: "لطفاً نام قانونی کشور ثبت شده را وارد کنید.",
      EC_BR_RegCountry: "کشور ثبت شده",
      EC_BR_RegCountry_Error: "لطفاً کشور ثبت شده را وارد کنید.",
      EC_BR_RegNum: "شماره ثبت",
      EC_BR_RegNum_Error: "لطفاً شماره ثبت را وارد کنید.",
      EC_BR_MonTxVal: "ارزش معاملات ماهانه",
      EC_BR_MonTxVal_Error: "لطفاً ارزش معاملات ماهانه را وارد کنید.",
      EC_BR_ECMonDis: "توزیع ماهانه کارت ایزی",
      EC_BR_ECMonDis_Error: "لطفاً توزیع ماهانه کارت ایزی را وارد کنید.",
      EC_BR_Website: "وب سایت (اگر دارید)",
      EC_BR_Submitter_info: "اطلاعات فرستنده",
      EC_BR_Job: "عنوان شغلی",
      EC_BR_Job_Error: "لطفاً عنوان شغلی را وارد کنید.",
      EC_BR_Birth: "تاریخ تولد",
      EC_BR_Birth_Error: "لطفاً تاریخ تولد را وارد کنید.",
      EC_BR_Country: "کشور اقامت",
      EC_BR_Country_Error: "لطفاً کشور اقامت را وارد کنید.",
      EC_BR_Citizen: "کشور تابعیت",
      EC_BR_Citizen_Error: "لطفاً کشور تابعیت را وارد کنید.",
      EC_BR_Upload_title: "لطفاً مدارک صرافی و شخصی خود را بارگذاری کنید:",
      EC_BR_Exchange_Doc: "مدرک ثبت صرافی",
      EC_BR_Exchange_Doc_Error: "لطفاً مدرک ثبت صرافی را بارگذاری کنید.",
      EC_BR_Peronal_ID_Doc: "مدرک شناسایی شخصی",
      EC_BR_Peronal_ID_Doc_Error: "لطفاً مدرک شناسایی شخصی را بارگذاری کنید.",
      // Easy Guarantee
      //// Easy Guarantee
      ////// top
      EG_EGTop_title: "تضمین آسان",
      EG_EGTop_Description_1: "تضمین دیجیتالی تنها",
      EG_EGTop_Description_2: "برای اولین بار در جهان",
      ////// Text
      EG_EGText_title: "تضمین آسان چیست؟",
      EG_EGText_Description_1:
        "تضمین آسان نوعی ضمانت نامه USDT است که توسط Easyexchangepro ارائه می شود و در آن بدهکار تضمین می کند که بدهی های یک بدهکار را بپردازد. به عبارت دیگر ، اگر بدهکار نتواند بدهی را تسویه کند ، Easyexchangepro آن را پوشش می دهد.",
      EG_EGText_Description_2:
        "یک تضمین آسان به درخواست کننده امکان خرید کالاها ، خرید تجهیزات ، گرفتن وام یا برای استفاده در مقابل پیش پرداخت و ... را می دهد",
      EG_EGText_Feature_1_description:
        "شما قادر خواهید بود تضمین آسان را برای اهداف تجاری خود در 5 دقیقه صادر کنید.",
      EG_EGText_Feature_2_title: "قابل اعتماد",
      EG_EGText_Feature_2_description:
        "تضمین آسان تنها یک سند تعهد پرداخت نیست؛ بلکه USDT به عنوان پراکندگی رایج ترین ارز دیجیتال در حساب دریافت کننده واریز می شود.",
      EG_EGText_Feature_3_description:
        "تضمین آسان صادر شده برای دریافت کننده 100٪ ایمن و مطمئن است و می تواند در زمان سررسید تبدیل یا برداشت شود.",
      ////// Steps
      EG_EGSteps_title:
        "شما می توانید تضمین آسان را در 4 مرحله آسان صادر کنید:",
      EG_EGSteps_Feature_1_title: "مرحله 1",
      EG_EGSteps_Feature_1_description_1: "/",
      EG_EGSteps_Feature_1_description_2: "در پلتفرم ما",
      EG_EGSteps_Feature_2_title: "مرحله 2",
      EG_EGSteps_Feature_2_description_1: "فرم درخواست را پر کنید و ارسال کنید",
      EG_EGSteps_Feature_3_title: "مرحله 3",
      EG_EGSteps_Feature_3_description_1: "مقدار تضمین آسان را با USDT",
      EG_EGSteps_Feature_3_description_2: "انتقال دهید",
      EG_EGSteps_Feature_4_title: "مرحله 4",
      EG_EGSteps_Feature_4_description_1: "تضمین صادر شده است و",
      EG_EGSteps_Feature_4_description_2:
        "USDT در حساب دریافت کننده واریز و قفل شده است",
      ////// Bot
      EG_EGBot_title: "برای شرکت‌ها و افراد",
      EG_EGBot_description:
        "شرکت‌ها و افراد به عنوان کاربران ما قادر هستند برای اهداف تجاری خود در رفته بانفیتی تضمین به مقدار کم یا زیاد صادر کنند. ",
      //// FAQ
      EG_EGFaq_Q1: "What is the Easy Guarantee?",
      EG_EGFaq_A1:
        "Easy Guarantee is a type of guarantee with cryptocurrency (USDT) backstop, based on that the borrowers/buyers guarantee that their debts to the creditor will be fulfilled.",
      EG_EGFaq_Q2: "Who is the applicant?",
      EG_EGFaq_A2:
        "The applicant is the party who will request to issue the Easy Guarantee.",
      EG_EGFaq_Q3: "Which currency is acceptable for issuing a guarantee?",
      EG_EGFaq_A3: "Only USDT.",
      EG_EGFaq_Q4: "Is any USDT wallet address acceptable?",
      EG_EGFaq_A4:
        "No, only applicant's and beneficiary's USDT wallet address are accepted in Easy Exchange Pro.",
      EG_EGFaq_Q5: "Who is the beneficiary?",
      EG_EGFaq_A5: "The party in whose favor the guarantee was issued.",
      EG_EGFaq_Q6: "What is the conditional Easy guarantee?",
      EG_EGFaq_A6:
        "The request to release the guarantee amount by the beneficiary must be approved by the applicant.",
      EG_EGFaq_Q7: "What is the unconditional Easy guarantee?",
      EG_EGFaq_A7:
        "The amount of Easy Guarantee will be released on the beneficiary's first demand.",
      EG_EGFaq_Q8: "How can users apply?",
      EG_EGFaq_A8: "Fill out the application form and submit it.",
      EG_EGFaq_Q9: "What will come after submitting the form?",
      EG_EGFaq_A9:
        "Applicant will receive a first email to confirm the request, then a second email containing the USDT wallet address to transfer the Easy Guarantee amount.",
      EG_EGFaq_Q10: "How much can the user issue an Easy guarantee?",
      EG_EGFaq_A10:
        "User can issue an easy guarantee of as much as USDT balance in his/her account.",
      EG_EGFaq_Q11:
        "Does the applicant need to have USDT balance to request and issue Easy Guarantee?",
      EG_EGFaq_A11:
        "Yes, the users can request to issue an Easy Guarantee of as much as USDT balance in their account.",
      EG_EGFaq_Q12: "When will be issued Easy Guarantee?",
      EG_EGFaq_A12:
        "Upon the applicant’s transfer of the USDT, the Easy Guarantee will issue immediately and the amount of USDT will be blocked in the beneficiary's account based on the validity period.",
      EG_EGFaq_Q13:
        "How will any dispute regarding guarantee release be resolved between the applicant and the beneficiary?",
      EG_EGFaq_A13:
        "In case of any dispute, any of the parties can send their objection to easyguarantee@easyexchangepro.com and our legal department will decide within one month after reviewing the documents of the parties.",
      EG_EGFaq_Q14:
        "Do the applicant and the beneficiary both have to be users of the Easyexchangepro platform?",
      EG_EGFaq_A14: "Yes, it must.",
      EG_EGFaq_Q15:
        "When can the beneficiary request to release the Easy Guarantees amount?",
      EG_EGFaq_A15:
        "The beneficiary can request to release the amount of Easy Guarantee in his/her account during the validity period of the Easy Guarantee.",
      EG_EGFaq_Q16:
        "If the beneficiary does not request to release the amount of Guarantee during the validity period of the Easy Guarantee, what will occur?",
      EG_EGFaq_A16:
        "The amount of the Easy Guarantee will be credited to the applicant’s account automatically.",
      EG_EGFaq_Q17: "How long does it take to issue an easy guarantee?",
      EG_EGFaq_A17: "Less than 5 minutes.",
      EG_EGFaq_Q18: "How much does it cost to issue an easy guarantee?",
      EG_EGFaq_A18: "0.4% of the easy guarantee’s amount.",
      EG_EGFaq_Q19: "Who is to be in charge to pay the fee?",
      EG_EGFaq_A19: "The applicant.",
      EG_EGFaq_Application_form: "Application Form",
      //// Application Form
      EG_AF_title: "فرم درخواست ضمانت آسان",
      EG_AF_description:
        "توجه: لطفاً قبل از پر کردن فرم درخواست ضمانت آسان، بخش سوالات ضمانت آسان را به دقت مطالعه کنید.",
      EG_AP_Applicant: "درخواست کننده:",
      EG_AP_SDate: "تاریخ شروع",
      EG_AP_SDate_Error: "لطفاً تاریخ شروع را وارد کنید.",
      EG_AP_EDate: "تاریخ پایان",
      EG_AP_EDate_Error: "لطفاً تاریخ پایان را وارد کنید.",
      EG_AP_WalletApp: "آدرس کیف پول USDT در Easy Exchange Pro",
      EG_AP_WalletApp_Error: "لطفاً آدرس کیف پول USDT را وارد کنید.",
      EG_AP_Amount: "مقدار USDT",
      EG_AP_Amount_Error: "لطفاً مقدار USDT را وارد کنید.",
      EG_AP_VPD: "تعداد روزهای اعتبار",
      EG_AP_VPD_Error: "لطفاً تعداد روزهای اعتبار را وارد کنید.",
      EG_AP_Select_Conditional: "مشروط",
      EG_AP_Select_Unconditional: "غیرمشروط",
      EG_AP_Beneficiary: "بازگرداننده:",
      EG_AP_BenUID: "شناسه کاربری بازگرداننده",
      EG_AP_BenUID_Error: "لطفاً شناسه کاربری بازگرداننده را وارد کنید.",
      EG_AP_BenEmail: "آدرس ایمیل بازگرداننده",
      EG_AP_BenEmail_Error: "لطفاً آدرس ایمیل بازگرداننده را وارد کنید.",
      EG_AP_WalletBen: "آدرس کیف پول USDT بازگرداننده در Easy Exchange Pro",
      EG_AP_WalletBen_Error:
        "لطفاً آدرس کیف پول USDT بازگرداننده را وارد کنید.",
      // Fee
      Fee_title_1: "سفارش‌های سازنده",
      Fee_description_1:
        "سفارش‌های سازنده (Maker orders) با وارد شدن به دفتر سفارشات، نقدینگی را در بازار ایجاد می‌کنند. به عبارت دیگر، سفارش‌های سازنده هنگام قرار گرفتن، تکمیل نمی‌شوند و صبر می‌کنند تا سفارش آینده‌ای وارد شود که با آن تطبیق داشته باشد. سفارش سازنده می‌تواند یک سفارش خرید یا فروش باشد. وقتی یک سفارش موجود در دفتر سفارشات با یک سفارش جدید (تکنده) تطبیق پیدا کند، هزینه سفارش سازنده در معامله دریافت می‌شود. اگر سفارش جدیدی به‌طور آنی با هیچ سفارش موجود تطبیق نیافته باشد یا کاملاً توسط سفارش‌های موجود تکمیل نشود، سفارش تکمیل نشده در دفتر سفارشات قرار می‌گیرد و برای معاملات آینده به سفارش سازنده تبدیل می‌شود.",
      Fee_title_2: "سفارش‌های تکنده",
      Fee_description_2:
        "سفارش‌های تکنده (Taker orders) نقدینگی را در بازار کاهش می‌دهند. سفارش‌های تکنده به‌طور آنی اجرا می‌شوند و حجم را از دفتر سفارشات کم می‌کنند. سفارش تکنده می‌تواند یک سفارش خرید یا فروش باشد. وقتی یک سفارش جدید قرار داده می‌شود و با یک سفارش دیگری که قبلاً در دفتر سفارشات وجود دارد (سفارش سازنده) تطبیق پیدا کند، هزینه سفارش تکنده در معامله دریافت می‌شود.",
      Fee_title_3: "هزینه‌های تراکنش",
      Fee_subtitle_3_1: "بلاکچین:",
      Fee_description_3_1:
        "هزینه‌های خاص شبکه بلاکچین ممکن است در هنگام انتقال توکن‌ها بین Easy Exchange Pro و بلاکچین برای کاربر پیش بیایند.",
      Fee_description_3_2:
        "Easy Exchange Pro هزینه برداشت را دریافت نمی‌کند. لطفاً توجه داشته باشید که برخی توکن‌ها یا سکه‌ها نیاز دارند تا ما اقدام به انتقال اموال شما به آدرس دیگری قبل از اعتبارسنجی کنیم. این به این معنی است که شبکه سکه یا توکن برای انتقال اموال، هزینه عادی تراکنش را از شما دریافت خواهد کرد و Easy Exchange Pro نمی‌تواند از آن اجتناب کند.",
      Fee_description_3_3:
        "Easy Exchange Pro هزینه کوچکی را برای برداشت توکن یا سکه با هزینه انتقال شبکه داخلی دریافت می‌کند. هزینه شبکه را می‌توانید با کلیک بر روی دکمه برداشت در پنجره برداشت برای هر توکن یا سکه با هزینه انتقال شبکه داخلی مشاهده کنید. هزینه شبکه را می‌توانید با کلیک بر روی دکمه برداشت در پنجره برداشت برای هر توکن یا سکه با هزینه انتقال شبکه داخلی مشاهده کنید.",
      Fee_table_1_col_1: "سطح",
      Fee_table_1_col_2: "حجم معاملات 30 روزه (BTC)",
      Fee_table_1_col_3: "سازنده",
      Fee_table_1_col_4: "تکنده",
      Fee_table_2_col_1: "نماد ارز",
      Fee_table_2_col_2: "نام ارز",
      // Gabur
      //// Top
      GBR_top_title_1: "ایزی اکسچنج پرو",
      GBR_top_title_3: "تکامل مالی",
      //// Second
      GBR_Second_title_1: "قرارداد هوشمند امنیتی",
      GBR_Second_title_2: "بررسی توکن گابور",
      GBR_Second_description:
        "یک قرارداد هوشمند شامل دستورالعمل‌های ویژه‌ای است که در بلاکچین ذخیره می‌شود. این قرارداد می‌تواند بر اساس یک سری پارامترهای قبل برنامه‌ریزی شده، به صورتی غیرقابل تغییر، شفاف و کاملاً ایمن، عملیات مختلفی را انجام دهد. بنابراین، قرارداد هوشمند توکن گابور برای تضمین سطوح بالای امنیت، مورد بررسی قرار گرفته است.",
      GBR_Second_Security_btn: "بررسی امنیت",
      //// Description
      GBR_Description_title_1: "توضیحات توکن گابور",
      GBR_Description_list_1_1: "نام توکن خدماتی: گابور",
      GBR_Description_list_1_2: "نماد: GBR",
      GBR_Description_list_1_3: "تامین کلی توکن‌های گابور: ۵۵۰ میلیون",
      GBR_Description_list_1_4: "توکن‌های گابور قابل افزایش نیستند",
      GBR_Description_list_1_5:
        "توکن‌های گابور به صورت بومی در شبکه BEP20 و به استاندارد BSC اجرا خواهند شد.",
      GBR_Description_list_1_6:
        "سوزاندن توکن: ۱۶۵ میلیون توکن گابور سوخته خواهند شد",
      GBR_Description_title_2: "توزیع توکن گابور",
      GBR_Description_list_2_1: "فروش عمومی",
      GBR_Description_list_2_2: "رزرو کارت ایزی",
      GBR_Description_list_2_3: "تیم",
      GBR_Description_list_2_4: "مشاوران",
      GBR_Description_list_2_5: "فروش خصوصی",
      GBR_Description_list_2_6: "آیردراپ",
      //// Contract
      GBR_Contract_subtitle: "آدرس قرارداد توکن گابور",
      //// Added
      GBR_Added_subtitle: "طرح توکن گابور",
      GBR_Added_title: "چه ارزش افزوده‌ای برای توکن گابور وجود دارد؟",
      GBR_Added_list_1:
        "توسعه ایزی اکسچنج پرو بر اساس راه‌کارهای مشخص شده در برنامه‌ریزی ما",
      GBR_Added_list_2:
        "ایجاد ایده‌های نوآورانه برای توسعه کارت ایزی به گونه‌ای که آن را به یک ویژگی قابل توجه برای استفاده در معاملات تبدیل می‌کند",
      GBR_Added_list_3: "توکن‌های گابور قابل افزایش نیستند",
      GBR_Added_list_4:
        "توسعه نوآورانه به منظور تبدیل دارایی‌های دیجیتال به چیزی عملی‌تر",
      GBR_Added_list_5: "سوزاندن توکن‌های گابور در بازه‌های سه‌ماهه",
      GBR_Added_list_6: "دوره قفل‌سازی برای توکن‌ها",
      GBR_Added_list_7:
        "قابلیت پذیرش کاربرانی که به دنبال روشی پایدار و امن برای تسویه پرداخت در بیش از ۶۰ کشور هستند",
      GBR_Added_list_8:
        "داشتن تیمی مجرب در زمینه رمزارز و حوزه مالی با ارتباطات مالی بین‌المللی گسترده",
      GBR_Added_list_9:
        "افزودن محصولات جدید و منحصربه‌فرد بر اساس برنامه‌ریزی ما",
      // Login
      Login_title: "به ایزی پرو خوش آمدید",
      Login_subtitle: "ورود برای ادامه",
      Login_btn_Loading: "ورود",
      Login_problem: "مشکل در ورود؟",
      Login_click_here: "اینجا کلیک کنید",
      Login_register: "اینجا برای ثبت‌نام کلیک کنید",
      Login_protected: "این سایت توسط reCAPTCHA و Google محافظت می‌شود",
      Login_privacy: "سیاست حفظ حریم خصوصی",
      Login_and: "و",
      Login_terms: "شرایط استفاده",
      Login_ResetModal_title:
        "می‌توانید ۲FA خود را یا رمزعبور خود را بازنشانی کنید",
      Login_ResetModal_Reset2FA_btn: "بازنشانی ۲FA",
      Login_ResetModal_ForgetPassword: "رمزعبور خود را فراموش کرده‌اید؟",
      Login_ResetPassword_title:
        "ایمیل خود را وارد کنید تا لینک بازنشانی رمزعبور دریافت کنید",
      Login_Reset2FA_title:
        "ایمیل خود را وارد کنید تا لینک بازنشانی ۲FA دریافت کنید",
      Login_OTP_title: "OTP دریافت شده را وارد کنید",
      //// Reset Password
      ResetPassword_title: "بازنشانی رمزعبور",
      ResetPassword_NewPassword_input: "رمزعبور جدید",
      ResetPassword_ConfirmPassword_input: "تأیید رمزعبور جدید",
      // Privacy And Policy
      Privacy_1_title: "INTRODUCTION",
      Privacy_1_desciption:
        "Thanks indeed for visiting Easyexchangepro.com, as a digital asset trading website. The purpose of this Privacy Policy is to inform you (user, customer) with an understanding that how Easyexchangepro (or we, or our, or us or platform) collect personal information from our clients and prospective clients to provide them with our products and services and ensure that we can meet their needs when providing these products and services, as well as when providing them with any respective information. This privacy policy applies to the Site and all Services offered by Easyexchangepro.",
      Privacy_2_title: "PERSONALLY IDENTIFIABLE",
      Privacy_2_desciption:
        "We may collect personally identifiable information from Users in a variety of ways, including, but not limited to, when Users visit our Site, register on the Site, place an order, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Site anonymously. When interacting with us on the Site, Users can always refuse to supply personal data to us, except that it may prevent them from engaging in certain Site related activities or transactions. We may collect non-personally identifiable information about Users when they interact with our Site.",
      Privacy_3_title: "WHAT INFORMATION DO WE COLLECT?",
      Privacy_3_desciption_1:
        "Easyexchangepro collects information about you when you use our websites (including the Site) and other online products and services and throughout other interactions and services, you have with us. We will collect your personal data in accordance with the legislative purpose of the DPA. We may also collect and store certain information automatically when you visit the Site. Examples include the internet protocol (IP) address used to connect your computer or device to the internet, connection information such as browser type and version, your operating system and platform, a unique reference number linked to the data you enter on our system, log in details, the full URL clickstream to, through and from the Site (including date and time), cookie number and your activity on our Site, including the pages you visited, the searches you made and, if relevant, the products/services you purchased.",
      Privacy_3_desciption_2:
        "We may receive information about you from third parties if you use any websites or social media platforms operated by third parties (for example, Facebook, Instagram, Twitter, etc.) and, if such functionality is available, you have chosen to link your profile on the Site with your profile on those other websites or social media platforms.",
      Privacy_4_title: "INDIVIDUAL AND COMPANY WHICH WE MAY COLLECT INCLUDE:",
      Privacy_4_subtitle_1: "For individual:",
      Privacy_4_list_1_1: "• Email address",
      Privacy_4_list_1_2: "• Mobile phone number",
      Privacy_4_list_1_3:
        "• Full legal name (including former name, and names in local language)",
      Privacy_4_list_1_4: "• Nationality",
      Privacy_4_list_1_5:
        "• Passport number, or any government-issued ID number",
      Privacy_4_list_1_6: "• Date of birth",
      Privacy_4_list_1_7:
        "• Proof of identity (e.g., passport, driver’s license, or government-issued ID)",
      Privacy_4_list_1_8: "• Residential address",
      Privacy_4_list_1_9: "• Proof of residency",
      Privacy_4_list_1_10:
        "• Additional Personal Data or documentation at the discretion of our Compliance Team",
      Privacy_4_subtitle_2: "For companies:",
      Privacy_4_list_2_1:
        "• Company legal name (including the legal name in local language)",
      Privacy_4_list_2_2: "• Incorporation/registration Information",
      Privacy_4_list_2_3:
        "• The full legal name of all beneficial owners, directors, and legal representatives",
      Privacy_4_list_2_4:
        "• Address (principal place of business and/or other physical locations)",
      Privacy_4_list_2_5: "• Proof of legal existence",
      Privacy_4_list_2_6: "• Description of the business",
      Privacy_4_list_2_7:
        "• Percentage of ownership for Individual/company owners",
      Privacy_4_list_2_8:
        "• Contact information of owners, principals, and executive management (as applicable)",
      Privacy_4_list_2_9:
        "• Proof of identity (e.g., passport, driver’s license, or government-issued ID) for the significant individual beneficial owner of the institutional customer entity",
      Privacy_4_list_2_10:
        "• Personal Data for each entity’s significant beneficial owner of the institutional customer entity (see the “Individual Customer” section above for details on what Personal Data we collect for individuals)",
      Privacy_4_list_2_11: "• Source of wealth",
      Privacy_4_list_2_12:
        "• Amount of bitcoin or other digital assets projected to be injected",
      Privacy_5_title: "COOKIES USAGE",
      Privacy_5_desciption:
        "When you access our Sites and Services, we or companies we work with may place cookies on your computer or another device. Of course, you can block or deactivate cookies in your browser settings. But these technologies help us better understand user behavior and inform us about which parts of our websites people have visited. We use first-party and third-party cookies to recognize you as an Easyexchangepro user, customize Coinbase Services, content, and advertising, measure promotional effectiveness, and collect information about your computer or another access device to mitigate risk, helping prevent fraud, and promote trust and safety. We may place cookies from third-party service providers who may use information about your visits to other websites to target advertisements for products and services available from Easyexchangepro. We do not control the types of information collected and stored by these third-party cookies. You should check the third-party's website for more information on how they use cookies.",
      Privacy_6_title: "LAWFUL BASIS FOR PROCESSING YOUR PERSONAL INFORMATION",
      Privacy_6_desciption_1:
        "We will process your personal information on the following bases and for the following purposes:",
      Privacy_6_desciption_2:
        "carrying out your instructions or responding to any inquiry given by (or purported to be given by) you or on your behalf including responding to your inquiries and complaints, or responding to or dealing with your interactions with us; registering you as a customer of Easyexchangepro and/or to deal with, process and/or administer the account that you may open with us, whether a membership account or otherwise, including to facilitate your transactions or activities on the Site or your transactions or activities with us; administering, facilitating, processing and/or dealing with your relationship with us, any transactions or activities carried out by you on the Site or with us. This includes processing your application, orders, and payment transactions; implementing transactions and the supply of products and/or services to you that you have requested; administering, facilitating, processing, and/or dealing in any matters relating to your use or access of the Site, including identifying you for login to the Site, our portals and other online services provided by or on behalf of us; monitoring, processing and/or tracking your use of the Site in order to provide you with a seamless experience, facilitating or administering your use of the Site, and/or to assist us in improving your experience in using the Site; assessing and processing your request for the purchase of and/or subscription to our products and/or services. contacting you or communicating with you via phone/voice call, text message and/or fax message, email and/or postal mail for the purposes of administering and/or managing your use of the Site, your membership and/or account with us, your relationship with us or any transactions made by you with us.",
      Privacy_7_title: "HOW WE USE YOUR PERSONAL DATA",
      Privacy_7_desciption_1:
        "Easyexchangepro uses Personal Data to communicate with you and to administer, deliver, improve, and personalize the Service. Easyexchangepro might also generate generic data out of any Personal Data we collect and use it for our own purposes. We may also use such data to communicate with you in relation to other products or services offered by Easyexchangepro and/or its partners. We do not share your Personal Data with third parties (other than partners in connection with their services to Easyexchangepro) except where you have given your consent and further detailed below.",
      Privacy_7_desciption_2:
        "We may share your Personal Data with third parties:",
      Privacy_7_desciption_3:
        "If we think that sharing it is necessary to enforce the Terms of Service. To comply with government agencies, including regulators, law enforcement, and/or justice departments. To third parties who provide services to Easyexchangepro (such as administration or technical services). In connection with the sale or transfer of our business or any part thereof.",
      Privacy_7_desciption_4:
        "Additionally, we have implemented international standards to prevent money laundering, terrorist financing and will implement final Digital Asset rules and regulations when effective, which will likely require us to undertake due diligence on our customers. This may include the use of third-party data and service providers which we will cross-reference with your personal information.",
      Privacy_8_title: "STORING YOUR PERSONAL DATA",
      Privacy_8_desciption:
        "The data that we collect from you may be transferred to and stored at, a destination outside of the U.A.E. It may also be processed by staff operating outside of U.A.E who work for us or for one of our suppliers. By submitting your personal data, you agree to this transfer, storing, or processing, except for customers located in the EEA, as detailed above. All information you provide to us is stored on our and/or third-party cloud servers",
      Privacy_9_title: "MARKETING COMMUNICATIONS",
      Privacy_9_desciption:
        "We may use your personal information to send you marketing communications by email or other agreed forms (including social media campaigns), to ensure you are always kept up to date with our latest products and services. If we send you marketing communications, we will do so based on your consent and registered marketing preferences. Users can opt out of these marketing communications at any moment. If you do not want to receive these communications, please send an email to support@easyexchangepro.com",
      Privacy_10_title: "DATA SECURITY",
      Privacy_10_desciption:
        "We endeavor to protect Easyexchangepro and you from unauthorized access, alteration, disclosure, or destruction of Personal Data we collect and store. We take various measures to ensure information security, including encryption of the Easyexchangepro communications with SSL; required two-factor authentication for all sessions; periodic review of our Personal Data collection, storage, and processing practices; and restricted access to your Personal Data on a need-to-know basis for our employees and vendors who are subject to strict contractual confidentiality obligations.",
      Privacy_11_title:
        "TRANSFER OF PERSONAL INFORMATION OUTSIDE OF THE EEA and UK and YOUR COUNTRY",
      Privacy_11_desciption:
        "We may transfer your personal information outside the EEA and UK to other Company subsidiaries, service providers, and business partners (i.e., Data Processors) who are engaged on our behalf. To the extent that we transfer your personal information outside of the EEA and UK, we will ensure that the transfer is lawful and that Data Processors in third countries are obliged to comply with the European Union (EU) General Data Protection Act 2016 and the UK Data Protection Act 2018. Also, by using our products and services, you consent to your Personal Data being transferred to other countries, including countries that have differing levels of privacy and data protection laws than your country. In all such transfers, we will protect your personal information as described in this Privacy Notice and ensure that appropriate information sharing contractual agreements are in place.",
      Privacy_12_title: "COMPLIANT PROCESS",
      Privacy_12_desciption:
        "If you have any complaint or grievance regarding how we are handling your personal data or about how we are complying with the CPT (when in force), we welcome you to contact us with your complaint or grievance and should be addressed to CPT@Easyexchangepro.com Where you are sending an email in which you are submitting a complaint, your indication at the subject header that it is a CPT complaint would assist us in attending to your complaint speedily by passing it on to the relevant staff in our organization to handle. We will certainly strive to deal with any complaint or grievance that you may have speedily and fairly.",
      Privacy_13_title: "YOUR ACCEPTANCE",
      Privacy_13_desciption:
        "By using Easyexchangepro Site, you signify your acceptance of this policy and Terms of Use.",
      Privacy_14_title: "CHANGES TO THIS PRIVACY POLICY",
      Privacy_14_desciption:
        "Any changes we may make to our Privacy Policy in the future will be posted on this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.",
      // Product Convert
      Convert_title: "تبدیل",
      Convert_for_each: "برای هر",
      Convert_receive: "شما دریافت خواهید کرد",
      Convert_calc: "محاسبه تبدیل",
      // Product Market Tabs
      Market_tab_Spot: "نقدی",
      Market_tab_Favorite: "مورد علاقه",
      // Product OTC
      OTC_title: "میز اوراق بهادار Easy Pro",
      OTC_description:
        "تجاریانی که می‌خواهند ارزهای رمزنگاری را به ویژه به حجم بالا تبدیل کنند، معمولاً تصمیم می‌گیرند از معاملات رمزنگاری OTC (بیرون از بازار) استفاده کنند. میز اوراق بهادار Easy Pro خدمات اجرا و تسویه‌یی را ارائه می‌دهد که به طور محرمانه، ایمن و بسیار رقابتی است.",
      OTC_Feature_1:
        "ما معاملات OTC را به طور محرمانه بدون تأثیر قابل توجهی بر بازارها انجام می‌دهیم.",
      OTC_Feature_2:
        "میز معاملات ما با رعایت حریم خصوصی و با استفاده از تمام پروتکل‌های امنیتی در Easy Pro انجام می‌شود.",
      OTC_Feature_3:
        "تسویه‌ها در حوزه Easy Pro انجام می‌شوند و به طور کلی تکمیل می‌شوند.",
      OTC_Feature_4: "تسویه در عرض چند دقیقه انجام می‌شود",
      OTC_Feature_5: "در میز ما هیچ شخص ثالثی وجود ندارد",
      OTC_Feature_6:
        "معامله در حساب Easy Pro انجام می‌شود، بنابراین نیازی به ایجاد یک کیف پول جدید نیست.",
      OTC_Feature_7:
        "سکه/توکنی که شما در حال معامله‌یی هستید به راحتی توسط تیم ما به صورت دستی از حساب شما منتقل می‌شود.",
      OTC_Feature_8:
        "جزئیات معاملات فقط در بخش 'تاریخچه توزیع' در حساب شما قابل مشاهده است.",
      OTC_Support_title: "پشتیبانی OTC",
      OTC_Support_description:
        "میز اوراق بهادار تحت پوشش جهانی در طول شبانه‌روز، به جز تعطیلات اصلی، برای پاسخگویی در ایمیل OTC@Easyexchangepro.com در دسترس است",
      OTC_list_1: "لطفاً فرم OTC را پر کنید و برای ما ارسال کنید",
      OTC_list_2: "تیم ما در دسترس است برای به ارزش گذاری، معامله و تسویه",
      OTC_list_3:
        "پس از پذیرش پیشنهاد ما، معامله تأیید می‌شود و تیم ما جزئیات و راهنمایی معامله را از طریق ایمیل به شما ارسال خواهد کرد.",
      OTC_list_4:
        "وقتی شما انجام معامله را دریافت کنید، میز اوراق بهادار اقدام به تسویه نهایی روی کیف پول یا حساب بانکی خود می‌کند",
      // Product Trade
      Trade_KYC_Error: "لطفاً KYC خود را بارگذاری کنید",
      Trade_Trading_password_Error: "لطفاً رمز عبور معاملات خود را فعال کنید",
      Trade_Trading_password_title: "رمز عبور معاملات خود را وارد کنید",
      Trade_Trading_password_input: "رمز عبور معاملات",
      Trade_Trading_Password_btn: "ورود با رمز عبور معاملات",
      Trade_goto_security: "به امنیت بروید",
      Trade_FAV: "مورد علاقه",
      Trade_Open_Orders: "سفارشات باز",
      Trade_Order_history: "تاریخچه سفارش",
      Trade_Trade_history: "تاریخچه معامله",
      Trade_Date_Time: "تاریخ و زمان",
      Trade_Type: "نوع",
      Trade_Action: "عملیات",
      Trade_Login_Register: "ورود/ثبت‌نام",
      Trade_Password_Error: "لطفاً رمزعبور خود را وارد کنید.",
      Trade_Balance_Error: "موجودی کافی نیست، شما دارید",
      Trade_Min_Error: "سفارش باید بالاتر از 20 USDT باشد",
      Trade_approximately: "تقریباً دریافت خواهید کرد",
      Trade_Market: "مارکت",
      Trade_Order_List: "لیست سفارشات",
      Trade_Order_History: "تاریخچه سفارش",
      Trade_Pairs: "جفت‌ها",
      Trade_Open_Chart: "باز کردن نمودار",
      // Profile
      Profile_profile: "پروفایل",
      Profile_security: "امنیت",
      Profile_history: "تاریخچه",
      Profile_Security_log_history: "تاریخچه ورودهای امنیتی",
      Profile_Login_history: "تاریخچه ورود",
      Profile_User_info_title: "اطلاعات کاربری",
      Profile_User_info_description:
        "اطلاعات منحصر به فرد حساب کاربری شما که باید آن را محافظت کنید.",
      Profile_UID: "شناسه کاربری",
      Profile_Sec_info_title: "اطلاعات امنیتی",
      Profile_Activated: "فعال",
      Profile_Disabled: "غیرفعال",
      Profile_Trading_Password: "رمز عبور معاملات",
      Profile_Account_status_title: "وضعیت حساب",
      Profile_KYC: "KYC",
      Profile_Sec_level: "سطح امنیت",
      Profile_Airdrop_point: "امتیاز Airdrop",
      Profile_Referrals_title: "معرفی",
      Profile_Num_Referrals: "تعداد ارجاع‌ها",
      Profile_Referral_ID: "شناسه معرف",
      Profile_Referral_List_btn: "لیست معرفی",
      Profile_Commissions_btn: "کمیسیون",
      Profile_User: "کاربر",
      Profile_KYC_Verified: "تأیید شده",
      Profile_KYC_Pending: "در حال بررسی",
      Profile_KYC_Rejected: "رد شده",
      Profile_KYC_NotUploaded: "بارگذاری نشده",
      Profile_Sec_lvl_Medium: "متوسط",
      Profile_Sec_lvl_VeryHigh: "بسیار بالا",
      Profile_Account_Password_title: "رمز عبور حساب",
      Profile_Account_Password_description:
        "شما می‌توانید رمز عبور خود را در اینجا تغییر دهید.",
      Profile_Change_Password_btn: "تغییر رمز عبور",
      Profile_Change_your_password_title: "رمز عبور خود را تغییر دهید",
      Profile_Current_Password: "رمز عبور فعلی",
      Profile_New_Password: "رمز عبور جدید",
      Profile_Confirm_New_Password: "تأیید رمز عبور جدید",
      Profile_Sec_Authenticaton_title: "احراز هویت دو عاملی",
      Profile_Sec_Authenticaton_description:
        "شما می‌توانید 2FA خود را فعال/غیرفعال کنید.",
      Profile_Active_2FA: "فعال کردن 2FA",
      Profile_Activating_2FA: "در حال فعالسازی 2FA",
      Profile_Disable: "غیرفعال کردن",
      Profile_Disable_2FA: "غیرفعال کردن 2FA",
      Profile_2FA_Code: "کد 2FA",
      Profile_Active: "فعال",
      Profile_Trading_Password_description:
        "شما می‌توانید رمز عبور معاملات خود را در اینجا تغییر دهید.",
      Profile_Change_trading_password: "تغییر رمز عبور معاملات",
      Profile_Change_your_Trading_password_title:
        "رمز عبور معاملات خود را تغییر دهید",
      Profile_Change_your_Trading_password_description:
        "آیا می‌خواهید رمز عبور معاملات خود را تغییر دهید؟",
      Profile_forgot_code: "کد را فراموش کرده‌اید؟",
      Profile_KYC_title: "KYC حساب شخصی",
      Profile_KYC_select_doc: "نوع سند خود را انتخاب کنید",
      Profile_KYC_select_doc_ID: "کارت شناسایی",
      Profile_KYC_select_doc_pass: "گذرنامه",
      Profile_KYC_select_doc_Drive: "گواهینامه رانندگی با شماره شناسنامه",
      Profile_KYC_Upload_title: "لطفاً اسناد خود را بارگذاری کنید:",
      Profile_KYC_Front: "روی سند",
      Profile_KYC_Back: "پشت سند",
      Profile_KYC_Selfie: "عکس سلفی با سند در دست شما",
      Profile_KYC_Approved: "KYC شما قبلاً تأیید شده است.",
      Profile_KYC_Pending: "KYC شما در حال بررسی است.",
      Profile_Currency: "ارز",
      Profile_Type: "نوع",
      Profile_Login: "ورود",
      Profile_Region: "منطقه",
      Profile_Login_device: "دستگاه ورود",
      // Register
      Reg_verify_email: "تأیید ایمیل",
      Reg_enter_otp: "وارد کردن OTP",
      Reg_Confirm_Password: "تأیید رمز عبور",
      Reg_Referral_Code: "کد معرف (اختیاری)",
      // Terms Of Use
      Term_1_title: "1-Welcome to Easy Pro",
      Term_1_description_1:
        "Thanks indeed for visiting (Easyexchangepro / we, hereinafter referred to as the Easy pro) By visiting, accessing, or using easyexchangepro.com and associated application program interface or mobile applications (platforms), You (“User” or “You”) consent to the Terms of Service (the “Terms”), so please read them carefully.",
      Term_1_description_2:
        "The Terms constitute the agreement and understanding regarding the use of any or all the Services, and any manner of accessing them, between You and the following service provider (“we,” “us” or “our” platform) Easyexchangepro.",
      Term_2_title: "2-Key Definitions",
      Term_2_description_1:
        "Capitalized terms not otherwise defined in these Terms will have the following meaning:",
      Term_2_description_2:
        "“External Account” means any Digital Token Account: from which you may transfer funds into your Easy Pro  Account, or to which you may transfer funds from your Easy Pro Account and which has been approved by Easy pro for the foregoing purposes.",
      Term_2_description_3: "“Funds” means Digital Tokens.",
      Term_2_description_4: "“Fiat Currency” means any national currency.",
      Term_2_description_5:
        "“Easy Pro Account” means a user account accessible via the Services where Funds may be stored by Easy pro on behalf of a user.",
      Term_2_description_6:
        "“Easy Pro Help Center” means Easy pro’s customer service center with address at Support@easyexchagepro.com -us where you could get online support from Easy pro.",
      Term_2_description_7:
        "“Digital Tokens” means Bitcoins, Ripples, and other digital mediums of exchange that may be purchased, sold, or traded via the Services.",
      Term_2_description_8:
        "“Digital Token Account” means any Digital Token address or account owned or operated by you that is maintained outside of the Services, and is not owned, controlled, or operated by Easyexchangepro.",
      Term_3_title: "3- Accept terms",
      Term_3_description_1:
        "You should read the Terms, and any document referred to in them very carefully. If there is anything that You do not understand in the Terms and any document referred to herein, you should discuss this matter with us and seek the necessary clarification.",
      Term_3_description_2:
        "You are at least 18 years of age and have the full capacity to accept these Terms and enter a transaction involving digital assets. You have not been deprived of the right to use our service and have the full capacity for legal action. If you do not meet the above condition, please do not register our Site or Platform, otherwise, the platforms may suspend or terminate your account at any time.",
      Term_3_description_3:
        "You’re entering and performing these Terms are not prohibited by the laws and regulations of the country or region to which you belong, reside, pay tax or carry out business activities or other business. If you do not meet the above conditions, you should immediately terminate the registration or stop using our Platforms services.",
      Term_3_description_4:
        "Our Platform has the right to modify these Terms from time to time or to formulate and modify various specific rules according to these Terms and publish them in the relevant system sections of Platforms without separately notifying you.",
      Term_3_description_5:
        "You should pay attention to the changes in these Terms and the specific rules from time to time. If you continue to use the Service after the changes in the content of these Terms and the specific rules, you are deemed to have fully read, understood, and accepted the amended Terms and the specific rules and to use the services of our Platforms in accordance therewith.",
      Term_3_description_6:
        "You also agree that Easy Pro may, by giving notice, at its sole discretion immediately terminate your access to our Platform and to your account. You agree and acknowledge our right to limit, suspend or terminate the service and your user account, prohibit your access to our Platform and their content, services, and tools, delay or remove hosted content, and take technical and legal steps to keep you off our Platform or any further steps to recover any loss or damages caused if we determine at our sole discretion that you are or may be in breach of any laws, regulations, the rights of third parties, or any of these Terms or Platform’s policies. Easy Pro shall not be liable for any loss or damage caused to you or any third party because of exercising our rights under this clause.",
      Term_4_title: "4- Our Trading Services",
      Term_4_description:
        "Easy Pro provides an online platform with a simple and convenient way to trade one type of digital asset or another type of digital asset. Easy Pro provides you with a platform that matches your orders with open orders from other users of our Services.",
      Term_4_subheader_1: "Spot.",
      Term_4_subtitle_1: "Market Order:",
      Term_4_description_1:
        "A Market Order is placed to buy or sell a Specified Quantity or a Specific Gross Amount of Targeted tokens at the best available Latest Market Price.",
      Term_4_subtitle_2: "Limit Order:",
      Term_4_description_2:
        "A Limit Order is placed to buy or sell a Specific Quantity of the Targeted Token at a Specific Price and shall be filled at such Specific Quantity and Specific Price.",
      Term_4_subtitle_3: "Stop Market Order:",
      Term_4_description_3:
        "A Stop Market Order is placed to buy a Specific Quantity of Targeted Token or to buy a Specific Gross Amount of Targeted Token at the Latest Market Price when the upper limit of the Preset Stop Price is triggered. This means that when the Latest Market Price reach or beyond the upper limit of the Preset Stop Price, this order will be executed and filled at the Latest Market Price which may end up higher than the upper limit of the Preset Stop Price.",
      Term_4_subtitle_4: "Stop Limit Order:",
      Term_4_description_4:
        "A Stop Limit Order is placed to sell a Specific Quantity of Targeted Token at the Specific Price when the inferior limit of Preset Stop Price is triggered. This means that when the Latest Market Price reaches or is below the inferior limit of the Preset Stop Price, this order will be executed and filled at the Specific Quantity as well as the Specific Price.",
      Term_4_subheader_2: "Easy card",
      Term_4_description_5:
        "Refers to transactions in which Gabur tokens are exchanged for fiat currency and deposited in the account of the user.",
      Term_5_title: "5- Scope of Service and Your Rights and Obligations",
      Term_5_description_1:
        "Users shall not register multiple accounts for any purpose. Users may not use another User’s accounts. Users are prohibited in any form from utilizing the Site to engage in illegal activities. Without the authorization or permission of Easy Pro, the User shall not use any of the commercial activities in the name of this Site or in any form of the Site as a place, platform, or medium for engaging in commercial activities.",
      Term_5_description_2:
        "Users shall comply with all laws and regulations and bear the responsibility and legal consequences of any of their own actions involving the Site and Services.",
      Term_5_description_3:
        "Users have the right to view the information regarding their accounts on our Platform and have the right to operate the functions provided by our Platform. The user understands and agrees that our Platform can adjust the service content, service type, and service form on the Platform at any time according to the actual situation at our sole discretion. Our Platform is not responsible for any negative impact or loss caused to you or any third party due to Platform’s adjustments.",
      Term_5_description_4:
        "Depending on your country of residence, you may not be able to use all the functions of the Site or the Platform. Without prejudice to our right of suspending certain services to users from a specific country, it is your responsibility to ensure that in accessing our Site, Platform, and services, you are complying with the laws and regulations in your country of residence and/or the country from which you access this Site, the Platform, and our services. you are responsible for any disputes or losses caused by your failure to promptly modify or confirm the status of the transaction or failure to submit the relevant application. Our Platforms do not assume any responsibility.",
      Term_5_description_5:
        "Engaging in trades in cryptographic tokens may be highly risky. Please do not use Easy Pro’s services for trading in Digital assets if you do not understand these risks.",
      Term_6_title: "6- Registration Process",
      Term_6_description_1:
        "You agree to provide a valid email address, a mobile phone number, and other information in accordance with the requirements on the user registration page of this platform. You can use the email address, mobile phone number or any other manner permitted to log in to this platform. Where it is necessary and in accordance with the requirements of applicable laws and regulations of relevant jurisdictions, you shall provide your real name, identity card, and other information required by applicable laws, regulations, the Terms of Privacy, and anti-money-laundering terms, and constantly update your registration data so that they will be timely, detailed, and accurate as is required. All the original typed data will be referenced as registration information. You shall be responsible for the authenticity, integrity, and accuracy of such information and bear any direct or indirect loss and adverse consequences arising out of it.",
      Term_6_description_2:
        "If any of the applicable laws, regulations, rules, orders, and other regulatory documents of the sovereign country or region in which you are based requires that mobile phone accounts must be based on real names, you hereby confirm that the mobile phone number you provide for registration purposes has gone through the real-name registration procedure. If you cannot provide such a mobile phone number as is required, any direct or indirect losses and adverse consequences arising therefrom and affecting you shall be borne by you.",
      Term_6_description_3:
        "After you provide the required registration information in a legal, complete, and valid manner and such information passes relevant verification, you shall have the right to obtain an account and a password of Easy exchange pro. Upon obtaining such an account and password, your registration shall be deemed as successful, and you can log into the platform as a member thereof. You agree to receive emails and/or short messages sent by the platform related to the management and operation thereof.",
      Term_6_description_4:
        "Particularly during periods of high volume, illiquidity, fast movement, or volatility in the marketplace for any, or one or more Digital Tokens, the actual market rate at which a trade is executed may be different from the prevailing rate indicated via the Services at the time of your order or trade. You understand that we are not liable for any such price fluctuations. In the event of a market disruption or Force Majeure event, Easy exchange pro may do one or more of the following: suspend access to the Services; or prevent you from completing any actions via the Services, including closing any open positions. Following any such event, when trading resumes, you acknowledge that prevailing market rates may differ significantly from the rates available prior to such an event.",
      Term_6_description_5:
        "You hereby authorize Easy exchange pro to attempt and/or perform any such correction, reversal, cancellation, voiding, or amendment described in this section. Easy exchange pro. provides no guarantee or warranty that any such attempt will be successful and will have no responsibility or liability for the Manifest Error or the making or failure of any correction attempt, except if caused by the gross negligence, willful default, or fraud of Easy exchange pro. There is a risk that you may be prejudiced by any action or omission of Easy exchange pro in this regard, and you accept that you have no recourse whatsoever against Easy exchange pro.",
      Term_7_title: "7- Fees",
      Term_7_description_1:
        "You agree to pay for transactions through our Fees Structure service, which may change from time to time. Changes to the Fee Structure are effective as of the effective date indicated in the posting of the revised Fee Structure to the Services and will apply prospectively to any trades that take place following the effective date of such revised Fee Structure.",
      Term_7_description_2:
        "In addition to the costs, your external account may incur costs in connection with your use of your designated external account through the Services. Costs incurred by your external account provider are not reflected on the Transactions page, which contains information about applicable costs. You are solely responsible for paying any fees imposed by an External Account provider.",
      Term_7_description_3:
        "You authorize us, or our designated payment processor, to charge or deduct your Easy Pro Account Funds for any applicable Fees owed in connection with trades you complete via the Services.",
      Term_8_title: "8- Communication",
      Term_8_description_1:
        "You agree and consent to receive electronically all communications, agreements, documents, receipts, notices, and disclosures (collectively, “Communications”) that Easy Pro provides in connection with your Easy Pro Account and/or use of the Easy Pro Services.",
      Term_8_description_2:
        "Updating Contact Information. It is your responsibility to keep your email address on file with Easy Pro up to date so that Easy exchange pro can communicate with you electronically.",
      Term_9_title: "9- Intellectual Property",
      Term_9_description:
        "Unless otherwise indicated by us, all copyright and other intellectual property rights in all content and other materials contained on our website/platform or provided in connection with the Services, including, without limitation, the Easy Pro logo and all designs, text, graphics, pictures, information, data, software, sound files, other files and the selection and arrangement thereof are the proprietary property of Easy Pro or our licensors or suppliers and are protected by international copyright laws and other intellectual property rights laws.",
      Term_10_title: "10- Unclaimed or Disputed Property",
      Term_10_description_1:
        "If for any reason Easy Pro is holding Funds in your Easy  Account, such Funds remain unclaimed, and Easy Pro is unable to return your Funds to your designated External Account after a period of three years after your last login to Easy Pro or any specified period notified by Easy Pro, and Easy Pro determines in good faith that it is not able to trace you, you agree that all such Funds shall be deemed to have been abandoned by you in favor of Easy Pro and may be appropriated by Easy Pro to and for itself, and you thereafter shall have no right to claim such Funds.",
      Term_10_description_2:
        "If Easy Pro receives notice that any Digital Tokens held in your Easy Pro Account are alleged to have been stolen or otherwise are not lawfully possessed by you, Easy Pro may, but has no obligation to, place an administrative hold on or freeze the affected Digital Tokens or your Easy Pro Account. If Easy Pro does place an administrative hold on or freeze some or all your Digital Tokens, Easy Pro may continue such hold until such time as the dispute has been resolved and evidence of the resolution acceptable to Easy Pro has been provided to Easy Pro in a form acceptable to Easy Pro. Easy Pro will not involve itself in any such dispute or the resolution of the dispute. You agree that Easy Pro will have no liability or responsibility for any losses, damages, or prejudice arising from or in connection with such hold or freeze, or for your inability to withdraw Digital Tokens or execute trades during the period of any such hold or freeze.",
      Term_11_title: "11- Complains about your local laws",
      Term_11_description:
        "It is your sole responsibility to determine whether, and to what extent, any taxes apply to any transactions You conduct through the Services, and to withhold, collect, report, and remit the correct amounts of taxes to the appropriate tax authorities. When using our Services, you acknowledge that your actions are in a legal and proper manner and your financial sources are not from illegal activities. We may discretionarily or in coordination with local law enforcement authorities seize, restrict or close out your account, fiat, and/or cryptocurrencies.",
      Term_12_title: "12- Suspension; Termination",
      Term_12_description_1:
        "In the event of any Force Majeure Event breach of these. Terms or Conditions, or any other event where the provision of services to Easy pro is commercially unreasonable, we may, at our sole discretion and without liability to you, with or without prior notice, grant you access to all or part of Suspend it. We may also immediately, without notice, terminate your access to the Services at our sole discretion and delete or deactivate your Easy exchange pro account and all related information and files on this account without liability to you, including in cases where you are violating one of these conditions. In the event of discontinuation of all Services or termination of your access to the Services or deletion or deactivation of your Easy Pro Account: all amounts payable by you to Easy Pro will immediately become due. the platform can cancel any open orders or other transaction requests that are pending at the time of discontinuation or termination or deletion or deactivation. the platform will, where possible, return any Funds stored in your Easy Exchange pro Account not otherwise owed to Easy Pro and/or will use commercially reasonable efforts to provide you with a period 4 months to transfer affected Digital Tokens from your Easy Pro Account, unless prohibited by applicable laws or regulations or by order of law enforcement or governmental authority, or Easy Pro believes you have committed fraud, negligence or other misconduct; and/or may take such other steps as Easy Pro deems necessary or desirable to protect its own interests. We are not and shall not be responsible or liable for any loss or damages incurred because of or arising from any actions taken under this section.",
      Term_12_description_2:
        "Also, if the user has not made any deposits, transactions, or logins for a period of three months by any reason, we may delete or deactivate the user's account, without notifying the User and without assuming any responsibility",
      Term_13_title: "13- Descramble of liability",
      Term_13_subtitle_1: "13.1 Network Service Provider:",
      Term_13_description_1:
        'as a third-party platform for "network service provider", does not guarantee the information and services provided on this Site can fully meet the needs of users. We shall not be liable for errors, insults, defamation, nonfeasance, obscenity, pornography, or blasphemy that may occur during the process of acceptance of the Parties’ web service.',
      Term_13_subtitle_2: "13. 2 Service Interruption:",
      Term_13_description_2:
        "Based on the special nature of the Internet, we do not guarantee that the service will not be interrupted; the timeliness and security of the service are also not guaranteed.",
      Term_13_subtitle_3: "13.3 Safety of the Network:",
      Term_13_description_3:
        "We try to provide a safe network environment to the users; however, we do not guarantee that the Site or its servers are free of viruses or other potentially harmful factors; therefore, the user should use the industry’s recognized software to check and kill any virus in the files downloaded from the Site.",
      Term_13_subtitle_4: "13. 4 User Information:",
      Term_13_description_4:
        "we are not responsible for the failure of preservation, modification, deletion, or storage of the information provided by the user. Nor will we be liable for the typographical errors, negligence, etc. We have the right but no obligation to improve or correct any omission, error of any part of this Site.",
      Term_13_subtitle_5:
        "13. 5 Accuracy, Completeness, and Reliability of the Site:",
      Term_13_description_5:
        "Unless we have expressly agreed in writing, we shall not guarantee the accuracy, completeness, reliability of any content, such as but not limited to, advertising from the platform in any manner (including but not limited to, containing, connecting, by way of, or downloading) from the Site; we are not responsible for any products, services, information or materials purchased or obtained by the user according to the content information on this website. The user bears the risk of using the content of this website.",
      Term_13_subtitle_6: "13. 6 User Opinion:",
      Term_13_description_6:
        "The user comments published by users of the Site, are only on behalf of the user’s personal point of view. It does not mean that this Site agrees with their views or confirms their description. This Site does not bear any legal responsibility caused by any user comments.",
      Term_13_subtitle_7: "13. 7 Announcements:",
      Term_13_description_7:
        "Regarding notices issued to the users, we will deliver these notices through a formal page announcement, station letter, e-mail, customer service phone call, SMS, or regular mail delivery. We do not bear any legal responsibility for any winning, discount activities or information that are delivered by other channels.",
      Term_13_subtitle_8:
        "13. 8 Charges, Commissions, Transactions, and Other Fees:",
      Term_13_description_8:
        "we have the right to adjust the recharge, commission, transaction, and other fees according to market conditions, and terminate any promotional efforts early.",
      Term_14_title: "14- Jurisdiction",
      Term_14_description:
        "These Terms shall be governed by and construed in accordance with the laws of Dubai. The courts of Dubai shall have exclusive jurisdiction to settle any dispute arising from or connected with these Terms (including a dispute relating to the existence, validity, or termination of these Terms or the consequences of their nullity or any non-contractual obligation arising out of or in connection with these Terms).",
      Term_15_title: "15. Miscellaneous",
      Term_15_description_1:
        "These Terms set forth the complete understanding of the parties with respect to the subject matter hereof and supersedes all prior understandings and communications relating thereto our Platforms reserve the right of final interpretation of these Terms within the scope permitted by law. These Terms and related pages of our Platforms may refer to each other. If there is any conflict, these Terms shall prevail. In addition, if some of the provisions in these Terms are deemed invalid or unenforceable, the other provisions in these Terms will remain in effect. In the event of any conflict between these Terms and any other agreement, you may have with Easy Exchange pro the terms of that other agreement will prevail only if these Terms are specifically identified and declared to be overridden by such other agreement.",
      Term_15_description_2:
        "Any failure or delay by Easy Exchange Pro to enforce any of these Terms or to exercise any right hereunder shall not be construed as a waiver to any extent of our rights.",
      Term_16_title: "16- Limitation of Liability",
      Term_16_description:
        "Except as otherwise required by law, in no event shall Easy exchange pro, our directors, officers, employees, agents, Brokers or contractors be liable for any special, indirect, or consequential damages, or any other damages of any kind, including but not limited to loss of use, loss of profits or loss of data, whether in an action in contract, tort (including but not limited to negligence) or otherwise, arising out of or in any way connected with the use of or inability to use our Services or the Easy exchange pro Materials, including without limitation any damages caused by or resulting from reliance by any user on any information obtained from Easy exchange pro , or that result from mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses, delays in operation or transmission or any failure of performance, whether or not resulting from a force majeure event, communications failure, theft of, destruction of or unauthorized access to Easy exchange pro’s records, programs or services. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the limitations of this section may not apply to you.",
      Term_17_title: "17- Miscellany",
      Term_17_subtitle_1: "17 -1 Agency",
      Term_17_description_1:
        "Nothing in this Agreement shall be deemed to have created, implied, or otherwise treated us as your agent, trustee, or another representative unless it is provided otherwise in this Agreement.",
      Term_17_subtitle_2: "17- 2 Waiver",
      Term_17_description_2:
        "Our or your waiver of the right to hold the other party liable for breaches of agreement or any other liability as is agreed upon in this Agreement shall not be construed or deemed as a waiver of the right to hold the other party for other breaches of contract; a failure to exercise any right or remedy shall not be construed in any way as a waiver of such right or remedy.",
      Term_17_subtitle_3: "17- 3 Goodwill",
      Term_17_description_3:
        "You acknowledge and agree that, before filing any dispute or claim, you will contact us through support@easyexchangepro.com to solve the dispute in an informal way where we will try to solve the relevant dispute internally as soon as possible; The Parties agree to negotiate and solve any dispute in good faith (such negotiation shall be confidential and protected under applicable rules, and will not be used as evidence in any proceeding).",
      Term_17_subtitle_4: "17- 4 Assignment of rights",
      Term_17_description_4:
        "The rights and obligations agreed in this Agreement shall be equally binding on the assignees, the heirs, executors, and administrators of the parties hereto who benefit from the rights and obligations. Without our consent, you may not transfer to any third party any of your rights or obligations hereunder, provided, however, we may, at any time, assign our rights and obligations under this Agreement to any third party with forty (40) days' notices to you.",
      Term_17_subtitle_5: "17- 5 Severability",
      Term_17_description_5:
        "The invalidity or unenforceability of any of these Terms shall not affect the validity or enforceability of any other of these Terms, all of which shall remain in full force and effect.",
      Term_17_subtitle_6: "17- 6 Governing language and translations",
      Term_17_description_6:
        "These Terms, the Privacy Policy, and other agreements or communications notified through the Services have been drafted in English. Although translations in other languages of any of the foregoing documents may be available, such translations may not be up to date or complete. Accordingly, you agree that in the event of any conflict between the English language version of the foregoing documents and any other translations thereof, the English language version of such documents shall govern and prevail.",
      TL_title: "فرم را برای لیست کردن توکن خود پر کنید",
      TL_Exchange_info: "اطلاعات صرافی",
      TL_Token_Name: "نام توکن",
      TL_Token_Name_Error: "لطفاً نام توکن را وارد کنید.",
      TL_Token_symbol: "نماد توکن",
      TL_Token_symbol_Error: "لطفاً نماد توکن را وارد کنید.",
      TL_Token_Symbol_upload_Error: "لطفاً نماد توکن را بارگذاری کنید.",
      TL_NFT: "NFT",
      TL_Total_supply_Error: "لطفاً مجموع عرضه را وارد کنید.",
      TL_Date_of_issue: "تاریخ صدور",
      TL_Date_of_issue_Error: "لطفاً تاریخ صدور را وارد کنید.",
      TL_Total_circulation: "کل مدارک",
      TL_Total_circulation_Error: "لطفاً کل مدارک را وارد کنید.",
      TL_Market_cap_circulation: "حجم بازار مدارک",
      TL_Market_cap_circulation_Error: "لطفاً حجم بازار مدارک را وارد کنید.",
      TL_Source_code: "کد منبع",
      TL_Source_code_Error: "لطفاً کد منبع را وارد کنید.",
      TL_Whitepaper: "Whitepaper",
      TL_Whitepaper_Error: "لطفاً فایل Whitepaper را بارگذاری کنید.",
      TL_Communities: "انجمن‌ها",
      TL_Telegram: "تلگرام",
      TL_Telegram_Error: "لطفاً تلگرام را وارد کنید.",
      TL_Twitter: "توییتر",
      TL_Twitter_Error: "لطفاً توییتر را وارد کنید.",
      TL_Facebook: "فیسبوک",
      TL_Facebook_Error: "لطفاً فیسبوک را وارد کنید.",
      TL_Reddit: "ردیت",
      TL_Reddit_Error: "لطفاً ردیت را وارد کنید.",
      TL_Discord: "دیسکورد",
      TL_Discord_Error: "لطفاً دیسکورد را وارد کنید.",
      TL_Submitter_info: "اطلاعات ارسال کننده",
      TL_Role: "نقش",
      TL_Role_Error: "لطفاً نقش را وارد کنید.",
      TL_Personal_ID: "شناسه شخصی",
      TL_Personal_ID_Error: "لطفاً شناسه شخصی را بارگذاری کنید.",
      TL_About_project: "درباره پروژه",
      TL_About_project_Error: "لطفاً درباره پروژه را وارد کنید.",
      TL_About_Private: "درباره فروش خصوصی، ICO/IEO/IDO",
      TL_About_Private_Error:
        "لطفاً درباره فروش خصوصی، ICO/IEO/IDO را وارد کنید.",
      TL_Distribution_plan: "طرح توزیع",
      TL_Distribution_plan_Error: "لطفاً طرح توزیع را وارد کنید.",
      TL_CEO_info: "اطلاعات مدیر عامل/CV لینکداین",
      TL_CEO_info_Error: "لطفاً اطلاعات مدیر عامل/CV لینکداین را وارد کنید.",
      TL_Policy: "با ارسال فرم خود شما قوانین حریم خصوصی ما را پذیرفته‌اید.",
      // Deposit
      Deposit_select_Token: "لطفاً توکن را انتخاب کنید:",
      Deposit_select_Network: "لطفاً شبکه را انتخاب کنید:",
      Deposit_Address: "آدرس",
      Deposit_attention: "لطفاً به آدرس برداشت و شبکه انتخاب شده توجه کنید.",
      Deposit_Network: "شبکه",
      Deposit_Warning:
        "در صورت واریز به آدرس یا با شبکه اشتباه، دارایی‌های خود را از دست خواهید داد.",
      // Transfer
      Transfer_Transfer: "انتقال",
      Transfer_select_Error: "لطفاً توکن را انتخاب کنید",
      Transfer_Balance_Error: "موجودی کافی نیست.",
      Transfer_Main_to_Trade: "از حساب اصلی به حساب تجاری",
      Transfer_Trade_to_Main: "از حساب تجاری به حساب اصلی",
      // Withdraw
      Withdraw_balance_Error: "موجودی کافی نیست",
      Withdraw_Amount_low_Error: "مقدار برداشت نمی‌تواند کوچکتر از",
      Withdraw_Amount_high_Error: "مقدار برداشت نمی‌تواند بزرگتر از",
      Withdraw_2FA_Error_1: "شما برای برداشت نیاز به فعال سازی",
      Withdraw_2FA_Error_2: "شناسایی دو مرحله‌ای (2FA)",
      Withdraw_2FA_Error_3: "دارید.",
      Withdraw_KYC_Error_1: "شما برای برداشت باید",
      Withdraw_KYC_Error_2: "فرایند KYC",
      Withdraw_KYC_Error_3: "را پشت سر بگذارید.",
      Withdraw_Goto_Profile: "برای رفتن به صفحه پروفایل کلیک کنید",
      Withdraw_min: "حداقل مقدار برداشت",
      Withdraw_max: "حداکثر مقدار برداشت",
      Withdraw_fee: "هزینه برداشت",
      Withdraw_Unconfirmed_attention_1: "لطفاً ",
      Withdraw_Unconfirmed_attention_3: "انتخاب شده و ",
      Withdraw_Unconfirmed_attention_4: "شبکه انتخاب شده را بررسی کنید",
      Withdraw_Unconfirmed_attention_5:
        "در صورت ارسال به آدرس شبکه اشتباه، دارایی‌هایتان را از دست خواهید داد و پلتفرم EasyPro هیچ مسئولیتی در قبال دارایی‌های گمشده نخواهد داشت.",
      Withdraw_Selected_token: "توکن انتخاب شده",
      Withdraw_Selected_Network: "شبکه انتخاب شده",
      Withdraw_Confirm_Mail_2FA: "تأیید ایمیل و شناسایی گوگل",
      Withdraw_Confirmed: "برداشت تأیید شد",
      Withdraw_Confirmed_attention_1: "لطفاً ",
      Withdraw_Confirmed_attention_2: "صندوق پیام ",
      Withdraw_Confirmed_attention_3: "ایمیل ",
      Withdraw_Confirmed_attention_4: "را بررسی کنید",
      Withdraw_Confirmed_attention_5:
        " و ایمیل ارسال شده برای تکمیل فرآیند برداشت را ",
      Withdraw_Confirmed_attention_6: "تأیید کنید.",
      Withdraw_Confirmed_attention_7:
        "در صورت عدم دریافت ایمیل، لطفاً صندوق اسپم (غیرهایمیل) را هم بررسی کنید.",
    },
  },
  ch: {
    translation: {
      ///////////////////// GLOBAL
      Already_Purchased: "已经购买",
      Global_Products: "产品",
      Global_Transaction: "交易",
      Global_Icon: "图标",
      Global_Pair: "交易对",
      Global_LastPrice: "最新价格",
      Global_Price: "价格",
      Global_24h: "24小时",
      Global_High: "最高价",
      Global_Low: "最低价",
      Global_Change: "涨跌幅",
      Global_Volume: "成交量",
      Global_Trade: "交易",
      Global_EasyGuarantee: "易保",
      Global_ApplicationForm: "申请表",
      Global_EasyCard: "易卡",
      Global_Secure: "安全",
      Global_Fast: "快速",
      Global_Convert: "兑换",
      Global_Gabur: "Gabur",
      Global_Token: "代币",
      Global_Total: "总计",
      Global_Amount: "数量",
      Global_USDT: "USDT",
      Global_Stop: "止损",
      Global_Limit: "限价",
      Global_Supply: "供应量",
      Global_Whitepaper: "白皮书",
      Global_Circulation: "流通量",
      Global_Website: "网站",
      Global_Buy: "购买",
      Global_Sell: "出售",
      Global_Loading: "加载中...",
      Global_Submit: "提交",
      Global_Cancel: "取消",
      Global_Confirm: "确认",
      Global_Close: "关闭",
      Global_OTP: "动态验证码",
      Global_Email: "邮箱",
      Global_2FA: "双重认证",
      Global_Password: "密码",
      Global_FName: "名字",
      Global_LName: "姓氏",
      Global_IDNum: "身份证号码",
      Global_Login: "登录",
      Global_Register: "注册",
      Global_Transfer: "转账",
      Global_UID: "用户ID",
      Global_Purpose: "目的",
      Global_Mobile: "手机号码",
      Global_Deposit: "存款",
      Global_Withdraw: "提款",
      Global_Fee: "费用",
      Global_Fees: "费用",
      Global_OTC: "场外交易",
      Global_Support: "支持",
      Global_Download: "下载",
      Global_From: "从",
      Global_To: "到",
      Global_Now: "现在",
      Global_Digital: "数字",
      Global_crypto: "加密",
      Global_cash: "现金",
      Global_wallet: "钱包",
      Global_account: "账户",
      Global_Exchange: "交易所",
      Global_trading: "交易",
      Global_Accept_Terms: "勾选此框表示您接受我们的条款和条件。",
      Global_Available: "可用",
      Global_Username: "用户名",
      Global_Time: "时间",
      Global_Status: "状态",
      Global_Network_Address: "网络地址",
      Global_Max_upload: "每个文件的最大上传大小为10 MB",
      Global_Policy: "提交表单即表示您接受我们的隐私政策",
      Global_UID_Error: "请输入用户ID。",
      Global_IDNum_Error: "请输入身份证号码。",
      Global_Mobile_Error: "请输入手机号码。",
      Global_Price_Error: "请输入价格。",
      Global_Stop_Error: "请输入止损价。",
      Global_Amount_Error: "请输入数量。",
      Global_Total_Error: "请输入总计。",
      Global_Email_Error: "请输入邮箱。",
      Global_Password_Error: "请输入密码。",
      Global_FName_Error: "请输入名字。",
      Global_LName_Error: "请输入姓氏。",
      Global_Website_Error: "请输入网站。",
      Global_Purpose_Error: "请解释请求的目的。",
      Global_Accept_Terms_Error: "必须同意后才能提交。",
      Global_about: '关于我们',
      Global_coin_info: "硬币信息",
      Global_token_listing: "代币上市",
      Global_logout: "登出",
      // Home
      //// Top
      Home_Top_Download_From: "从以下位置下载",
      Home_Top_Playstore: "Playstore",
      Home_Top_Download_APK: "下载APK",
      Home_Top_APPstore: "APPstore",
      Home_Top_Register_Now: "立即注册",
      //// carousel
      Home_Carousel_1_title: "透明和安全",
      Home_Carousel_1_description:
        "我们已经在白皮书中透明地提供了有关Gabur Token的详细信息，供您决定是否进行投资。此外，Gabur代币的智能合约已经经过审核，以确保最高水平的安全性。",
      Home_Carousel_2_title: "Easy Guarantee",
      Home_Carousel_2_description:
        "Easy Exchange Pro在数字世界中首次提供Easy Guarantee系统，用于在公司之间进行担保金转移和商业合同。",
      Home_Carousel_3_title: "24/7支持",
      Home_Carousel_3_description:
        "您可以使用工单系统与支持团队联系，或发送电子邮件至support@easyexchangepro.com",
      //// Easy Guarantee
      Home_EG_subtitle: "加密世界中的首个数字担保",
      Home_EG_description:
        "Easy Exchange Pro首次在数字世界中提供Easy Guarantee系统，用于在公司之间进行担保金转移和商业合同。",
      Home_EG_more_info_btn: "了解更多",
      //// Easy Card
      Home_EC_subtitle_1: "唯一的",
      Home_EC_subtitle_2: "现金购买加密货币方式",
      Home_EC_description_1:
        "获取您附近的Easy Card预付代码，并将Gabur代币存入您的钱包中。安全可靠，无需",
      Home_EC_description_2: "银行或信用卡。这是唯一的现金购买加密货币的方式。",
      Home_EC_Feature_1_title: "独立",
      Home_EC_Feature_1_description_1: "无需银行账户或",
      Home_EC_Feature_1_description_2: "信用卡即可购买加密货币",
      Home_EC_Feature_2_description_1: "您所有的资产都在您自己的",
      Home_EC_Feature_2_description_2: "控制之下",
      Home_EC_Feature_3_description_1: "在几秒钟内将现金存入您的",
      Home_EC_Feature_3_description_2: "钱包中",
      Home_EC_Feature_4_title: "简单",
      Home_EC_Feature_4_description_1: "您正在做您一直在做的",
      Home_EC_Feature_4_description_2: "事情",
      Home_EC_Feature_5_title: "无存款费用",
      Home_EC_Feature_5_description_1: "存款无需费用",
      Home_EC_Feature_5_description_2: "",
      Home_EC_Feature_6_description_1: "您可以将Gabur代币兑换为",
      Home_EC_Feature_6_description_2: "其他货币。",
      Home_EC_Where_to_buy: "购买途径",
      //// Mobile Section
      Home_MS_title: "使用Easy Exchange Pro移动应用程序轻松开始交易。",
      Home_MS_list_1: "随时随地访问您的资产",
      Home_MS_list_2: "安全可靠",
      Home_MS_list_3: "用户友好界面",
      Home_MS_list_4: "高级交易工具",
      Home_MS_list_5: "一体化应用",
      Home_MS_dl_for_ios: "iOS下载",
      Home_MS_dl_for_android: "安卓下载",
      //about
      //// A Top video
      About_top_title: "我们的使命",
      About_top_desciption:
        "我们的使命是建立一个生态系统，赋予每个人轻松进入数字货币世界的权利，从而实现财务自由。",
      About_top_Feature_1: "600,000",
      About_top_Feature_1_description_1: "注册用户",
      About_top_Feature_2: "2个地点",
      About_top_Feature_2_description: "我们在2个国家设有办事处",
      About_top_Feature_3: "+28个国家",
      About_top_Feature_3_description: "在28多个国家提供服务",
      ///// A Second
      About_second_title: "为什么选择Easy Exchange Pro？",
      About_second_subtitle: "对初学者而言易用，对专业人士而言先进",
      About_second_description_1:
        "尽管加密货币世界可能有点复杂，但我们在这里帮助您！我们的平台简化了交易。",
      About_second_description_2:
        "我们为您提供了在一个地方买卖最流行的加密货币的工具，同时受到行业领先的保护和安全措施。",
      About_second_Feature_1_description_1:
        "采用基于加密技术的行业领先的安全保护",
      About_second_Feature_1_description_2: "",
      About_second_Feature_2_title: "易于使用",
      About_second_Feature_2_description_1: "我们设计了一个简单易用的",
      About_second_Feature_2_description_2: "平台，并将其提供给您使用",
      About_second_Feature_3_title: "高级功能（面向专业人士）",
      About_second_Feature_3_description_1: "我们开发了一种先进的交易界面",
      About_second_Feature_3_description_2: "为用户提供专业的交易体验",
      About_second_Feature_4_description_1: "以刹那间完成交易",
      About_second_Feature_4_description_2: "您喜爱的货币",
      About_second_Feature_5_title: "现金购买",
      About_second_Feature_5_description_1: "购买加密货币的最简便方式",
      About_second_Feature_5_description_2: "只有Easy Card才能实现",
      About_second_Feature_6_title: "24/7",
      About_second_Feature_6_description_1:
        "开始交易加密货币，您将随时得到我们的支持。",
      About_second_Feature_6_description_2: "",
      //// Road Map
      About_road_title: "路线图",
      About_road_description_1: "Easy Exchange Pro的开发计划分为三年。",
      About_road_description_2:
        "这里您将了解到我们在未来几年计划实现的目标概述。",
      About_road_year_1: "2022年",
      About_road_year_1_description_1: "独特产品：Easy Guarantee",
      About_road_year_1_description_2: "+6种语言",
      About_road_year_1_description_3: "5个国家的10家经纪人",
      About_road_year_1_description_4: "+100名员工",
      About_road_year_1_description_5: "+10个交易对",
      About_road_year_2: "2023年",
      About_road_year_2_description_1: "新产品：Easy Wallet",
      About_road_year_2_description_2: "Easy Pro Academy",
      About_road_year_2_description_3: "用于业务集成的API",
      About_road_year_2_description_4: "Easy Card将成为交易的高效方法",
      About_road_year_2_description_5: "+6种语言",
      About_road_year_2_description_6: "16个国家的200家经纪人",
      About_road_year_2_description_7: "+5种法定货币",
      About_road_year_2_description_8: "+250名员工",
      About_road_year_2_description_9: "+30个交易对",
      About_road_year_2_description_10: "+100个可信度代币",
      About_road_year_2_description_11: "GABUR代币在其他世界级平台上上市",
      About_road_year_3: "2024年",
      About_road_year_3_description_1: "独特产品：加密货币银行",
      About_road_year_3_description_2: "+6种语言",
      About_road_year_3_description_3: "+1000名员工",
      About_road_year_3_description_4: "+100个交易对",
      About_road_year_3_description_5: "+130个可信度代币",
      About_road_year_3_description_6: "GABUR代币在其他世界级平台上上市",
      // API Documents
      Api_title: "API文档",
      Api_1: "概要",
      Api_2: "资产",
      Api_3: "行情",
      Api_4: "订单簿",
      Api_5: "交易记录",
      Api_6: "24小时交易量",
      // Asset
      Asset_Tab_1: "所有资产",
      Asset_Tab_2: "主钱包",
      Asset_Tab_3: "交易钱包",
      Asset_Total_BTC: "BTC中的总资产",
      Asset_In_Withdraw: "正在提取中",
      Asset_In_Order: "正在下单中",
      // Contact us
      ContactUs_email_title: "与我们联系：",
      ContactUs_email: "Support@easyexchangepro.com",
      ContactUs_faq_title: "您可以查看我们的常见问题解答页面以找到答案：",
      ContactUs_faq: "",
      ContactUs_ticket_title: "或者您可以提交工单：",
      ContactUs_ticket: "",
      ContactUs_social_title: "在以下社交媒体上找到并关注我们：",

      // Coin info
      CoinInfo_Issue_Time: "Issue Time :",
      CoinInfo_White_paper: "Whitepaper:",
      CoinInfo_Website: "Website:",
      CoinInfo_Block_Explorer: "Block Explorer:",
      CoinInfo_AAVE:
        "Aave is an open source and non-custodial protocol enabling the creation of money markets. Users can earn interest on deposits and borrow assets. Deposit interest rate and loan interest rate of both borrowers and lenders are calculated through algorithms based on the platform's borrowing volume and deposit volume, and the platform uses Chainlink's oracles to ensure the fairness of the collateral price.",
      CoinInfo_ADA:
        "Cardano is a decentralised public blockchain and cryptocurrency project and is fully open source. Cardano is developing a smart contract platform which seeks to deliver more advanced features than any protocol previously developed. It is the first blockchain platform to evolve out of a scientific philosophy and a research-first driven approach.",
      CoinInfo_Atom:
        "In a nutshell, Cosmos bills itself as a project that solves some of the “hardest problems” facing the blockchain industry. It aims to offer an antidote to “slow, expensive, unscalable and environmentally harmful” proof-of-work protocols, like those used by Bitcoin, by offering an ecosystem of connected blockchains. The project’s other goals include making blockchain technology less complex and difficult for developers thanks to a modular framework that demystifies decentralized apps. Last but not least, an Interblockchain Communication protocol makes it easier for blockchain networks to communicate with each other — preventing fragmentation in the industry. Cosmos’ origins can be dated back to 2014, when Tendermint, a core contributor to the network, was founded. In 2016, a white paper for Cosmos was published — and a token sale was held the following year. ATOM tokens are earned through a hybrid proof-of-stake algorithm, and they help to keep the Cosmos Hub, the project’s flagship blockchain, secure. This cryptocurrency also has a role in the network’s governance.",
      CoinInfo_AVAX:
        "Avalanche is a layer one blockchain that functions as a platform for decentralized applications and custom blockchain networks. It is one of Ethereum’s rivals, aiming to unseat Ethereum as the most popular blockchain for smart contracts. It aims to do so by having a higher transaction output of up to 6,500 transactions per second while not compromising scalability. This is made possible by Avalanche’s unique architecture. The Avalanche network consists of three individual blockchains: the X-Chain, C-Chain and P-Chain. Each chain has a distinct purpose, which is radically different from the approach Bitcoin and Ethereum use, namely having all nodes validate all transactions. Avalanche blockchains even use different consensus mechanisms based on their use cases. After its mainnet launch in 2020, Avalanche has worked on developing its own ecosystem of DApps and DeFi. Different Ethereum-based projects such as SushiSwap and TrueUSD have integrated with Avalanche. Furthermore, the platform is constantly working on improving interoperability between its own ecosystem and Ethereum, like through the development of bridges.",
      CoinInfo_BNB:
        "From the ICO, Binance issued its own coin called the Binance Coin, with BNB as the symbol. BNB runs natively on Binance chain. The coin was established with a total supply of 200 million. Every quarter, we will destroy BNB based on the trading volume on our crypto-to-crypto platform until we destroy 50% of all the BNB. All transactions will be on the blockchain. We eventually will destroy 100MM BNB, leaving 100MM BNB remaining. The BNB coin itself has multiple forms of utility, essentially being the underlying gas that powers the Binance Ecosystem. The current most prominent use cases include using BNB to: - Pay for trading fees on the exchange, obtaining the equivalent of a 50% discount on trades (during the first year). - Crypto.com has included support for Binance’s BNB token to its platform which includes the MCO Visa Card and mobile app.",
      CoinInfo_BTC:
        "Bitcoin is a cryptocurrency and a digital payment system invented by an unknown programmer, or a group of programmers, under the name Satoshi Nakamoto.It was released as open-source software in 2009.The system is peer-to-peer, and transactions take place between users directly, without an intermediary. These transactions are verified by network nodes and recorded in a public distributed ledger called a blockchain. Since the system works without a central repository or single administrator, bitcoin is called the first decentralized digital currency.Besides being created as a reward for mining, bitcoin can be exchanged for other currencies, products, and services in legal or black markets.As of February 2015, over 100,000 merchants and vendors accepted bitcoin as payment. According to research produced by Cambridge University in 2017, there are 2.9 to 5.8 million unique users using a cryptocurrency wallet, most of them using bitcoin.",
      CoinInfo_Cake:
        "PancakeSwap, built on Binance Smart Chain (BSC), is a decentralized exchange for swapping BEP-20 tokens. It is fast, cheap, and allows anyone to participate. Users can get FLIP (LP token) for staking and get CAKE token as reward. CAKE holders can swap CAKE for SYRUP for additional incentivized staking. PancakeSwap uses an automated market maker (AMM) model. It means that while you trade digital assets on the platform, there isn’t an order book where you’re matched with someone else. Instead, you trade against a liquidity pool. Traders deposit funds into the pool, receiving liquidity provider (or LP) tokens in return. They can use those tokens to reclaim their share, plus a portion of the trading fees.",
      CoinInfo_Doge:
        "Dogecoin is a cryptocurrency focused on actual utility as a currency. We provide fast block times and very low fees which make Dogecoin suitable for usage in micro-transactions but also as payment option for online shops. Dogecoin has been adopted as such by online retailers and can be used easily as means of consumer to consumer money transfer too.",
      CoinInfo_Dot:
        'Polkadot is a global well-known blockchain cross-chain project, which is created by Ethereum co-founder and Ethereum yellow book author Gavin Wood. It aims to solve the communication and data transmission obstacles between different blockchains and was rated as one of the "4 major projects that decide the direction of digital currency development in 2019".',
      CoinInfo_ETC:
        "Ethereum classNameic (ETC) is a hard fork of Ethereum (ETH) that launched in July 2016. Its main function is as a smart contract network, with the ability to host and support decentralized applications (DApps). Its native token is ETC. Since its launch, Ethereum classNameic has sought to differentiate itself from Ethereum, with the two networks’ technical roadmap diverging further and further from each other with time. Ethereum classNameic first set out to preserve the integrity of the existing Ethereum blockchain after a major hacking event led to the theft of 3.6 million ETH.",
      CoinInfo_ETH:
        'Ethereum (ETH) is an open-source, public, blockchain-based distributed computing platform featuring smart contract (scripting) functionality, which facilitates online contractual agreements. Ethereum also provides a cryptocurrency token called "ether", which can be transferred between accounts and used to compensate participant nodes for computations performed. "Gas", an internal transaction pricing mechanism, is used to mitigate spamand allocate resources on the network.The value token of the Ethereum blockchain is called ether. It is listed under the diminutive ETH and traded on cryptocurrency exchanges. It is also used to pay for transaction fees and computational services on the Ethereum network.',
      CoinInfo_Fil:
        "The InterPlanetary File system (IPFS) is a global, peer-to-peer distributed version of the file system that aims to supplement (or even replace) the hypertext transfer protocol (HTTP) that currently dominates the internet, by connecting all computing devices with the same file system. The principle is to replace the address based on the domain name with a content-based address, that is, the user is looking for content that is not an address but stored in a certain place. There is no need to verify the identity of the sender, but only the hash of the content. It can ensure the web to be faster, safer, more robust and more durable.Filecoin is an incentive layer on IPFS and a a decentralized storage market built on IPFS based on the token incentive model.FIL6 is the Filecoin contract issued 6 months after its launch",
      CoinInfo_GBR:
        "Gabur (GBR) is a utility token for the Easy Exchange Pro platform. The Gabur is using for the Easy Card, a system to buy crypto without cash, and also Easy Guarantee which is a system to guarantee payment between companies and their customers.",
      CoinInfo_LTC:
        "Litecoin is a peer-to-peer Internet currency that enables instant, near-zero cost payments to anyone in the world. Litecoin is an open source, global payment network that is fully decentralized without any central authorities. Mathematics secures the network and empowers individuals to control their own finances. Litecoin features faster transaction confirmation times and improved storage efficiency than the leading math-based currency. With substantial industry support, trade volume and liquidity, Litecoin is a proven medium of commerce complementary to Bitcoin",
      CoinInfo_Matic:
        "Matic Network is a layer-2 scaling solution with side-chains for off-chain computation, while ensuring asset security using the Plasma framework and a decentralized network of Proof-of-Stake (PoS) validators. It aims to offer scalable and instant blockchain transactions. By utilizing adapted version of the Plasma framework, the system is built on PoS checkpoints that are pushed to the Ethereum main-chain. As a result, it is expected to allow each Matic sidechain to theoretically achieve 2^16 transactions per block. Matic Network has been a significant contributor to the Ethereum ecosystem, having worked on implementations of Plasma MVP (Minimum Viable Plasma), the WalletConnect protocol, and Dagger, a popular Ethereum event notification engine. Many projects have already been building applications on and integrating with Matic. MATIC is an ERC-20 token running on Ethereum, it used to pay for services on the Matic Network but also serves as a settlement currency between users who operate within the Matic ecosystem.",
      CoinInfo_MKR:
        "Maker (MKR) is the governance token of the MakerDAO and Maker Protocol — respectively a decentralized organization and a software platform, both based on the Ethereum blockchain — that allows users to issue and manage the DAI stablecoin. Initially conceived in 2015 and fully launched in December 2017, Maker is a project whose task is to operate DAI, a community-managed decentralized cryptocurrency with a stable value soft-pegged to the US dollar. MKR tokens act as a kind of voting share for the organization that manages DAI; while they do not pay dividends to their holders, they do give the holders voting rights over the development of Maker Protocol and are expected to appreciate in value in accordance with the success of DAI itself. The Maker ecosystem is one of the earliest projects on the decentralized finance (DeFi) scene: the industry that seeks to build decentralized financial products on top of smart-contract-enabled blockchains, such as Ethereum.",
      CoinInfo_Near:
        "NEAR Protocol is a layer-one blockchain that was designed as a community-run cloud computing platform and that eliminates some of the limitations that have been bogging competing blockchains, such as low transaction speeds, low throughput and poor interoperability. This provides the ideal environment for DApps and creates a developer and user-friendly platform. For instance, NEAR uses human-readable account names, unlike the cryptographic wallet addresses common to Ethereum. NEAR also introduces unique solutions to scaling problems and has its own consensus mechanism called “Doomslug.” NEAR Protocol is being built by the NEAR Collective, its community that is updating the initial code and releasing updates to the ecosystem. Its declared goal is to build a platform that is “secure enough to manage high value assets like money or identity and performant enough to make them useful for everyday people.” Flux, a protocol that allows developers to create markets based on assets, commodities, real-world events, and Mintbase, an NFT minting platform are examples of projects being built on NEAR Protocol.",
      CoinInfo_TRX:
        "Tron is one of the largest blockchain based operating systems in the world. TRON is an ambitious project dedicated to building the infrastructure for a truly decentralized Internet. The Tron Protocol, one of the largest blockchain based operating systems in the world, offers scalable, high-availability and high-throughput support that underlies all the decentralized applications in the TRON ecosystem. TRON Protocol and the TVM allow anyone to develop DAPPs for themselves or their communities, with smart contracts making decentralized crowdfunding and token issuance easier than ever. Tron DAPP projects already include Peiwo, Obike, Uplive, game.com, Kitty live and Mico, with 100M+ active users from more than 100 countries and regions around the world.",
      CoinInfo_XLM:
        "Stellar network is a free, open-source network that connects diverse financial systems and lets anyone build low-cost financial services—payments, savings, loans, insurance—for their community. It is supported by Stellar.org, a Silicon Valley based non-profit organization. The Stellar network enables money to move directly between people, companies and financial institutions as easily as email. This inter-connectivity means more access for individuals, lower costs for banks, and more revenue for businesses. Token use: Stellar is an open-source, decentralized protocol for digital currency to fiat currency transfers which allows cross-border transactions between any pair of currencies.",
      CoinInfo_XRP:
        "Ripple (XRP) is an independent digital asset that is native to the Ripple Consensus Ledger. With proven governance and the fastest transaction confirmation of its kind, XRP is said to be the most efficient settlement option for financial institutions and liquidity providers seeking global reach, accessibility and fast settlement finality for interbank flows.",
      CoinInfo_ZEC:
        "NZcash is a decentralized cryptocurrency focused on privacy and anonymity. It uses the zk-SNARK zero-knowledge proof technology that allows nodes on the network to verify transactions without revealing any sensitive information about those transactions. Contrary to a common misunderstanding, the majority of cryptocurrencies on the market, including Bitcoin (BTC), are not anonymous, but rather pseudonymous; while they do not explicitly reveal the identities of their users, each user has their own public address or addresses which can be traced back to them via the methods of data science and blockchain forensics. Zcash transactions, on the other hand, still have to be relayed via a public blockchain, but unlike pseudonymous cryptocurrencies, ZEC transactions by default do not reveal the sending and receiving addresses or the amount being sent. There is an option, however, to reveal this data for the purposes of auditing or regulatory compliance. Zcash was first released on October 28, 2016, and it was originally based on Bitcoin’s codebase.",
      // Easy Card
      //// Where To Buy
      ///// Top
      EC_WT_Title: "购买Easy Card",
      EC_WT_Description:
        "您可以从附近的经纪人处购买Easy Card，并无需使用信用卡即可充值您的账户",
      EC_WT_Find_Title: "查找附近的经纪人",
      EC_WT_Find_btn: "查找",
      EC_WT_Find_input: "输入您的城市",
      ///// Apply
      EC_WA_title: "申请获得Easy Card许可并成为我们的合作伙伴并获利。",
      EC_WA_Decription: "申请",
      ///// Start
      EC_WS_Question: "Easy Card如何工作？",
      EC_WS_title: "3个简单步骤开始使用",
      EC_WS_SubTitle: "整个过程不到3分钟。",
      EC_WS_Feature_1_description: "找到您附近的经纪人并购买Easy Card",
      EC_WS_Feature_2_description:
        "输入您的Easy Card代码，将Gabur Tokens存入您的钱包",
      EC_WS_Feature_3_description: "现在，您可以开始交易了",
      //// Already Purchased
      EC_AP_description: "在此处输入您的代码，Gabur tokens将自动存入您的钱包。",
      EC_AP_Code: "输入您的代码",
      EC_AP_Code_Error: "请输入20位数的代码。",
      //// Broker
      ///// Register
      EC_BR_title: "注册为经纪人",
      ///// Form
      EC_BR_Exchange_info: "交易所信息",
      EC_BR_Legal: "法定名称",
      EC_BR_Legal_Error: "请输入注册国家法定名称。",
      EC_BR_RegCountry: "注册国家",
      EC_BR_RegCountry_Error: "请输入注册国家。",
      EC_BR_RegNum: "注册号码",
      EC_BR_RegNum_Error: "请输入注册号码。",
      EC_BR_MonTxVal: "月交易金额",
      EC_BR_MonTxVal_Error: "请输入月交易金额。",
      EC_BR_ECMonDis: "Easy Card每月分配量",
      EC_BR_ECMonDis_Error: "请输入Easy Card每月分配量。",
      EC_BR_Website: "网站（如果有）",
      EC_BR_Submitter_info: "提交者信息",
      EC_BR_Job: "职位",
      EC_BR_Job_Error: "请输入职位。",
      EC_BR_Birth: "出生日期",
      EC_BR_Birth_Error: "请输入出生日期。",
      EC_BR_Country: "居住国家",
      EC_BR_Country_Error: "请输入居住国家。",
      EC_BR_Citizen: "公民国家",
      EC_BR_Citizen_Error: "请输入公民国家。",
      EC_BR_Upload_title: "请上传交易所和个人文件：",
      EC_BR_Exchange_Doc: "交易所注册文件",
      EC_BR_Exchange_Doc_Error: "请上传交易所注册文件。",
      EC_BR_Peronal_ID_Doc: "个人身份证件",
      EC_BR_Peronal_ID_Doc_Error: "请上传个人身份证件。",
      // Easy Guarantee
      //// Easy Guarantee
      ////// top
      EG_EGTop_title: "易保函",
      EG_EGTop_Description_1: "唯一的数字加密保函",
      EG_EGTop_Description_2: "全球首次",
      ////// Text
      EG_EGText_title: "什么是易保函？",
      EG_EGText_Description_1:
        "易保函是Easyexchangepro提供的一种以USDT为支持的担保方式，借款人将确保债务的履行。换句话说，如果债务人未能偿还债务，Easyexchangepro将为其提供担保。",
      EG_EGText_Description_2:
        "易保函使申请人能够获取商品，购买设备，借款或支付定金等。",
      EG_EGText_Feature_1_description:
        "您可以在5分钟内为您的业务目的发行易保函。",
      EG_EGText_Feature_2_title: "可靠",
      EG_EGText_Feature_2_description:
        "易保函不仅是一份支付承诺文件；而且，USDT将作为最具流动性的数字货币存入受益人账户。",
      EG_EGText_Feature_3_description:
        "发行的易保函对受益人来说是100％安全和可靠的，并且在到期时可以转换或提取。",
      ////// Steps
      EG_EGSteps_title: "您可以通过4个简单的步骤发行易保函：",
      EG_EGSteps_Feature_1_title: "第1步",
      EG_EGSteps_Feature_1_description_1: "在我们的平台上",
      EG_EGSteps_Feature_1_description_2: "/",
      EG_EGSteps_Feature_2_title: "第2步",
      EG_EGSteps_Feature_2_description_1: "填写申请表格并提交",
      EG_EGSteps_Feature_2_description_2: "",
      EG_EGSteps_Feature_3_title: "第3步",
      EG_EGSteps_Feature_3_description_1: "以USDT支付金额",
      EG_EGSteps_Feature_3_description_2: "作为易保函",
      EG_EGSteps_Feature_4_title: "第4步",
      EG_EGSteps_Feature_4_description_1: "担保已发出，并且",
      EG_EGSteps_Feature_4_description_2: "USDT已存入并锁定在",
      EG_EGSteps_Feature_4_description_3: "受益人账户中",
      ////// Bot
      EG_EGBot_title: "适用于公司和个人",
      EG_EGBot_description:
        "作为我们的用户，公司和个人可以根据受益人的要求，以小额或大额发行担保函，用于业务目的。",
      //// FAQ
      EG_EGFaq_Q1: "什么是易保函？",
      EG_EGFaq_A1:
        "易保函是一种基于加密货币（USDT）担保的担保方式，借款人/购买方保证履行其对债权人的债务。",
      EG_EGFaq_Q2: "申请人是谁？",
      EG_EGFaq_A2: "申请人是请求发行易保函的一方。",
      EG_EGFaq_Q3: "发行担保函可以使用哪种货币？",
      EG_EGFaq_A3: "只能使用USDT。",
      EG_EGFaq_Q4: "是否接受任何USDT钱包地址？",
      EG_EGFaq_A4: "不，Easy Exchange Pro仅接受申请人和受益人的USDT钱包地址。",
      EG_EGFaq_Q5: "受益人是谁？",
      EG_EGFaq_A5: "担保函发出的受益人。",
      EG_EGFaq_Q6: "什么是有条件的易保函？",
      EG_EGFaq_A6: "必须经过申请人的批准，受益人才能请求释放担保金额。",
      EG_EGFaq_Q7: "什么是无条件的易保函？",
      EG_EGFaq_A7: "易保函金额将在受益人首次要求时释放。",
      EG_EGFaq_Q8: "用户如何申请？",
      EG_EGFaq_A8: "填写申请表格并提交。",
      EG_EGFaq_Q9: "提交表单后会发生什么？",
      EG_EGFaq_A9:
        "申请人将收到第一封电子邮件确认请求，然后收到第二封电子邮件，其中包含转移易保函金额的USDT钱包地址。",
      EG_EGFaq_Q10: "用户可以发行多少金额的易保函？",
      EG_EGFaq_A10: "用户可以发行等于其账户中的USDT余额的易保函。",
      EG_EGFaq_Q11: "申请人在请求和发行易保函时需要具备USDT余额吗？",
      EG_EGFaq_A11: "是的，用户可以请求发行等于其账户中的USDT余额的易保函。",
      EG_EGFaq_Q12: "何时发行易保函？",
      EG_EGFaq_A12:
        "申请人转移USDT后，易保函将立即发行，并根据有效期在受益人的账户中锁定相应金额的USDT。",
      EG_EGFaq_Q13: "申请人和受益人之间关于担保金额释放的任何争议将如何解决？",
      EG_EGFaq_A13:
        "如有任何争议，任何一方都可以将其  异议发送至easyguarantee@easyexchangepro.com，我们的法务部门将在审核各方文件后的一个月内做出决定。",
      EG_EGFaq_Q14: "申请人和受益人是否都必须是Easyexchangepro平台的用户？",
      EG_EGFaq_A14: "是的，必须如此。",
      EG_EGFaq_Q15: "受益人何时可以请求释放易保函金额？",
      EG_EGFaq_A15:
        "受益人可以在易保函的有效期内请求释放其账户中的易保函金额。",
      EG_EGFaq_Q16:
        "如果受益人在易保函的有效期内未请求释放担保金额，将会发生什么？",
      EG_EGFaq_A16: "易保函的金额将自动存入申请人的账户。",
      EG_EGFaq_Q17: "发行易保函需要多长时间？",
      EG_EGFaq_A17: "少于5分钟。",
      EG_EGFaq_Q18: "发行易保函需要多少费用？",
      EG_EGFaq_A18: "易保函金额的0.4%。",
      EG_EGFaq_Q19: "由谁负责支付费用？",
      EG_EGFaq_A19: "由申请人支付。",
      EG_EGFaq_Application_form: "申请表格",
      //// Application Form
      EG_AF_title: "易保函申请表格",
      EG_AF_description: "注意：在填写申请表格之前，请仔细阅读易保函问题部分。",
      EG_AP_Applicant: "申请人：",
      EG_AP_SDate: "开始日期",
      EG_AP_SDate_Error: "请输入开始日期。",
      EG_AP_EDate: "结束日期",
      EG_AP_EDate_Error: "请输入结束日期。",
      EG_AP_WalletApp: "Easy Exchange Pro的USDT钱包地址",
      EG_AP_WalletApp_Error: "请输入USDT钱包地址。",
      EG_AP_Amount: "USDT金额",
      EG_AP_Amount_Error: "请输入USDT金额。",
      EG_AP_VPD: "有效期（天）",
      EG_AP_VPD_Error: "请输入有效期天数。",
      EG_AP_Select_Conditional: "有条件的",
      EG_AP_Select_Unconditional: "无条件的",
      EG_AP_Beneficiary: "受益人：",
      EG_AP_BenUID: "受益人用户ID",
      EG_AP_BenUID_Error: "请输入受益人用户ID。",
      EG_AP_BenEmail: "受益人电子邮件地址",
      EG_AP_BenEmail_Error: "请输入受益人电子邮件地址。",
      EG_AP_WalletBen: "受益人Easy Exchange Pro的USDT钱包地址",
      EG_AP_WalletBen_Error: "请输入受益人USDT钱包地址。",
      // Fee
      Fee_title_1: "Maker订单",
      Fee_description_1:
        "Maker订单通过被输入到订单簿上，在市场上创造（制造）流动性。换句话说，Maker订单在下单时不会被成交，而是等待未来与之匹配的订单。Maker订单可以是买单或卖单。当订单簿上的现有订单与新下单的订单（Taker）匹配时，交易中的Maker订单将被收取Maker费用。如果新输入的订单不立即与任何现有订单匹配或未完全被现有订单成交，未成交的订单将被放置在订单簿中，成为未来交易的Maker订单。",
      Fee_title_2: "Taker订单",
      Fee_description_2:
        "Taker订单减少（减少）市场上的流动性。Taker订单立即执行并从订单簿中消耗交易量。Taker订单可以是买单或卖单。当新订单被下单并与订单簿上已存在的另一个订单（Maker）匹配时，交易中的Taker将被收取Taker费用。",
      Fee_title_3: "交易手续费",
      Fee_subtitle_3_1: "区块链：",
      Fee_description_3_1:
        "在Easy Exchange Pro上的令牌在区块链上进行转移时，可能会产生特定的区块链网络费用。",
      Fee_description_3_2:
        "Easy Exchange Pro不收取存款费用。请注意，某些令牌或币种要求我们在存入资金之前将资金转移到另一个地址。这意味着该币种或令牌的网络将为资金转移收取正常的交易费用，Easy Exchange Pro无法避免。",
      Fee_description_3_3:
        "Easy Exchange Pro对于具有内置网络转账费用的令牌或币种的提款收取少量费用。通过点击每个具有内置网络转账费用的令牌或币种的提款窗口上的提款按钮，可以查看网络费用。",
      Fee_table_1_col_1: "等级",
      Fee_table_1_col_2: "30天交易量（BTC）",
      Fee_table_1_col_3: "Maker费用",
      Fee_table_1_col_4: "Taker费用",
      Fee_table_2_col_1: "货币符号",
      Fee_table_2_col_2: "货币名称",
      // Gabur
      //// Top
      GBR_top_title_1: "EASY EXCHANGE PRO",
      GBR_top_title_3: "金融的演进",
      //// Second
      GBR_Second_title_1: "安全智能合约",
      GBR_Second_title_2: "Gabur Token审计",
      GBR_Second_description:
        "智能合约包含在区块链中存储的特殊指令。该合约可以根据一系列预先编程的参数执行多个操作，以不可更改、透明和完全安全的方式。因此，GABUR Tokens智能合约已经通过审计，以确保最高水平的安全性。",
      GBR_Second_Security_btn: "安全审计",
      //// Description
      GBR_Description_title_1: "Gabur Tokens描述",
      GBR_Description_list_1_1: "实用令牌名称：GABUR",
      GBR_Description_list_1_2: "符号：GBR",
      GBR_Description_list_1_3: "GABUR令牌总供应量：550M",
      GBR_Description_list_1_4: "GABUR令牌不会增加",
      GBR_Description_list_1_5: "GABUR令牌将在BEP20网络上运行，遵循BSC标准。",
      GBR_Description_list_1_6: "令牌销毁：将销毁165M GABUR令牌",
      GBR_Description_title_2: "Gabur Tokens分配",
      GBR_Description_list_2_1: "公开销售",
      GBR_Description_list_2_2: "Easy Card储备",
      GBR_Description_list_2_3: "团队",
      GBR_Description_list_2_4: "顾问",
      GBR_Description_list_2_5: "私人销售",
      GBR_Description_list_2_6: "空投",
      //// Contract
      GBR_Contract_subtitle: "Gabur令牌智能合约地址",
      //// Added
      GBR_Added_subtitle: "Gabur令牌计划",
      GBR_Added_title: "Gabor令牌的附加价值是什么？",
      GBR_Added_list_1: "根据我们的路线图承诺开发Easy Exchange Pro",
      GBR_Added_list_2:
        "在Easy Card的发展上创造创新的想法，使其能够成为交易中的重要功能",
      GBR_Added_list_3: "GABUR令牌不会增加",
      GBR_Added_list_4: "创新的发展旨在使数字资产更加实用",
      GBR_Added_list_5: "每季度销毁GABUR令牌",
      GBR_Added_list_6: "代币的锁定期",
      GBR_Added_list_7: "能够欢迎在60多个国家寻找稳定安全的支付方式的用户",
      GBR_Added_list_8:
        "拥有经验丰富的团队，既在加密货币领域，也在金融领域，具有广泛的国际金融联系",
      GBR_Added_list_9: "我们将根据我们的路线图添加新的独特产品",
      // Login
      Login_title: "欢迎来到Easy Pro",
      Login_subtitle: "登录以继续",
      Login_btn_Loading: "登录",
      Login_problem: "登录问题？",
      Login_click_here: "点击这里",
      Login_register: "点击此处注册",
      Login_protected: "该网站受reCAPTCHA和Google的保护",
      Login_privacy: "隐私政策",
      Login_and: "和",
      Login_terms: "服务条款",
      Login_ResetModal_title: "您可以重置您的两步验证或密码",
      Login_ResetModal_Reset2FA_btn: "重置两步验证",
      Login_ResetModal_ForgetPassword: "忘记密码？",
      Login_ResetPassword_title: "输入您的电子邮件以接收重置密码链接",
      Login_Reset2FA_title: "输入您的电子邮件以接收重置两步验证链接",
      Login_OTP_title: "输入收到的OTP",
      //// Reset Password
      ResetPassword_title: "重置您的密码",
      ResetPassword_NewPassword_input: "新密码",
      ResetPassword_ConfirmPassword_input: "确认新密码",
      // Privacy And Policy
      Privacy_1_title: "INTRODUCTION",
      Privacy_1_desciption:
        "Thanks indeed for visiting Easyexchangepro.com, as a digital asset trading website. The purpose of this Privacy Policy is to inform you (user, customer) with an understanding that how Easyexchangepro (or we, or our, or us or platform) collect personal information from our clients and prospective clients to provide them with our products and services and ensure that we can meet their needs when providing these products and services, as well as when providing them with any respective information. This privacy policy applies to the Site and all Services offered by Easyexchangepro.",
      Privacy_2_title: "PERSONALLY IDENTIFIABLE",
      Privacy_2_desciption:
        "We may collect personally identifiable information from Users in a variety of ways, including, but not limited to, when Users visit our Site, register on the Site, place an order, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Site anonymously. When interacting with us on the Site, Users can always refuse to supply personal data to us, except that it may prevent them from engaging in certain Site related activities or transactions. We may collect non-personally identifiable information about Users when they interact with our Site.",
      Privacy_3_title: "WHAT INFORMATION DO WE COLLECT?",
      Privacy_3_desciption_1:
        "Easyexchangepro collects information about you when you use our websites (including the Site) and other online products and services and throughout other interactions and services, you have with us. We will collect your personal data in accordance with the legislative purpose of the DPA. We may also collect and store certain information automatically when you visit the Site. Examples include the internet protocol (IP) address used to connect your computer or device to the internet, connection information such as browser type and version, your operating system and platform, a unique reference number linked to the data you enter on our system, log in details, the full URL clickstream to, through and from the Site (including date and time), cookie number and your activity on our Site, including the pages you visited, the searches you made and, if relevant, the products/services you purchased.",
      Privacy_3_desciption_2:
        "We may receive information about you from third parties if you use any websites or social media platforms operated by third parties (for example, Facebook, Instagram, Twitter, etc.) and, if such functionality is available, you have chosen to link your profile on the Site with your profile on those other websites or social media platforms.",
      Privacy_4_title: "INDIVIDUAL AND COMPANY WHICH WE MAY COLLECT INCLUDE:",
      Privacy_4_subtitle_1: "For individual:",
      Privacy_4_list_1_1: "• Email address",
      Privacy_4_list_1_2: "• Mobile phone number",
      Privacy_4_list_1_3:
        "• Full legal name (including former name, and names in local language)",
      Privacy_4_list_1_4: "• Nationality",
      Privacy_4_list_1_5:
        "• Passport number, or any government-issued ID number",
      Privacy_4_list_1_6: "• Date of birth",
      Privacy_4_list_1_7:
        "• Proof of identity (e.g., passport, driver’s license, or government-issued ID)",
      Privacy_4_list_1_8: "• Residential address",
      Privacy_4_list_1_9: "• Proof of residency",
      Privacy_4_list_1_10:
        "• Additional Personal Data or documentation at the discretion of our Compliance Team",
      Privacy_4_subtitle_2: "For companies:",
      Privacy_4_list_2_1:
        "• Company legal name (including the legal name in local language)",
      Privacy_4_list_2_2: "• Incorporation/registration Information",
      Privacy_4_list_2_3:
        "• The full legal name of all beneficial owners, directors, and legal representatives",
      Privacy_4_list_2_4:
        "• Address (principal place of business and/or other physical locations)",
      Privacy_4_list_2_5: "• Proof of legal existence",
      Privacy_4_list_2_6: "• Description of the business",
      Privacy_4_list_2_7:
        "• Percentage of ownership for Individual/company owners",
      Privacy_4_list_2_8:
        "• Contact information of owners, principals, and executive management (as applicable)",
      Privacy_4_list_2_9:
        "• Proof of identity (e.g., passport, driver’s license, or government-issued ID) for the significant individual beneficial owner of the institutional customer entity",
      Privacy_4_list_2_10:
        "• Personal Data for each entity’s significant beneficial owner of the institutional customer entity (see the “Individual Customer” section above for details on what Personal Data we collect for individuals)",
      Privacy_4_list_2_11: "• Source of wealth",
      Privacy_4_list_2_12:
        "• Amount of bitcoin or other digital assets projected to be injected",
      Privacy_5_title: "COOKIES USAGE",
      Privacy_5_desciption:
        "When you access our Sites and Services, we or companies we work with may place cookies on your computer or another device. Of course, you can block or deactivate cookies in your browser settings. But these technologies help us better understand user behavior and inform us about which parts of our websites people have visited. We use first-party and third-party cookies to recognize you as an Easyexchangepro user, customize Coinbase Services, content, and advertising, measure promotional effectiveness, and collect information about your computer or another access device to mitigate risk, helping prevent fraud, and promote trust and safety. We may place cookies from third-party service providers who may use information about your visits to other websites to target advertisements for products and services available from Easyexchangepro. We do not control the types of information collected and stored by these third-party cookies. You should check the third-party's website for more information on how they use cookies.",
      Privacy_6_title: "LAWFUL BASIS FOR PROCESSING YOUR PERSONAL INFORMATION",
      Privacy_6_desciption_1:
        "We will process your personal information on the following bases and for the following purposes:",
      Privacy_6_desciption_2:
        "carrying out your instructions or responding to any inquiry given by (or purported to be given by) you or on your behalf including responding to your inquiries and complaints, or responding to or dealing with your interactions with us; registering you as a customer of Easyexchangepro and/or to deal with, process and/or administer the account that you may open with us, whether a membership account or otherwise, including to facilitate your transactions or activities on the Site or your transactions or activities with us; administering, facilitating, processing and/or dealing with your relationship with us, any transactions or activities carried out by you on the Site or with us. This includes processing your application, orders, and payment transactions; implementing transactions and the supply of products and/or services to you that you have requested; administering, facilitating, processing, and/or dealing in any matters relating to your use or access of the Site, including identifying you for login to the Site, our portals and other online services provided by or on behalf of us; monitoring, processing and/or tracking your use of the Site in order to provide you with a seamless experience, facilitating or administering your use of the Site, and/or to assist us in improving your experience in using the Site; assessing and processing your request for the purchase of and/or subscription to our products and/or services. contacting you or communicating with you via phone/voice call, text message and/or fax message, email and/or postal mail for the purposes of administering and/or managing your use of the Site, your membership and/or account with us, your relationship with us or any transactions made by you with us.",
      Privacy_7_title: "HOW WE USE YOUR PERSONAL DATA",
      Privacy_7_desciption_1:
        "Easyexchangepro uses Personal Data to communicate with you and to administer, deliver, improve, and personalize the Service. Easyexchangepro might also generate generic data out of any Personal Data we collect and use it for our own purposes. We may also use such data to communicate with you in relation to other products or services offered by Easyexchangepro and/or its partners. We do not share your Personal Data with third parties (other than partners in connection with their services to Easyexchangepro) except where you have given your consent and further detailed below.",
      Privacy_7_desciption_2:
        "We may share your Personal Data with third parties:",
      Privacy_7_desciption_3:
        "If we think that sharing it is necessary to enforce the Terms of Service. To comply with government agencies, including regulators, law enforcement, and/or justice departments. To third parties who provide services to Easyexchangepro (such as administration or technical services). In connection with the sale or transfer of our business or any part thereof.",
      Privacy_7_desciption_4:
        "Additionally, we have implemented international standards to prevent money laundering, terrorist financing and will implement final Digital Asset rules and regulations when effective, which will likely require us to undertake due diligence on our customers. This may include the use of third-party data and service providers which we will cross-reference with your personal information.",
      Privacy_8_title: "STORING YOUR PERSONAL DATA",
      Privacy_8_desciption:
        "The data that we collect from you may be transferred to and stored at, a destination outside of the U.A.E. It may also be processed by staff operating outside of U.A.E who work for us or for one of our suppliers. By submitting your personal data, you agree to this transfer, storing, or processing, except for customers located in the EEA, as detailed above. All information you provide to us is stored on our and/or third-party cloud servers",
      Privacy_9_title: "MARKETING COMMUNICATIONS",
      Privacy_9_desciption:
        "We may use your personal information to send you marketing communications by email or other agreed forms (including social media campaigns), to ensure you are always kept up to date with our latest products and services. If we send you marketing communications, we will do so based on your consent and registered marketing preferences. Users can opt out of these marketing communications at any moment. If you do not want to receive these communications, please send an email to support@easyexchangepro.com",
      Privacy_10_title: "DATA SECURITY",
      Privacy_10_desciption:
        "We endeavor to protect Easyexchangepro and you from unauthorized access, alteration, disclosure, or destruction of Personal Data we collect and store. We take various measures to ensure information security, including encryption of the Easyexchangepro communications with SSL; required two-factor authentication for all sessions; periodic review of our Personal Data collection, storage, and processing practices; and restricted access to your Personal Data on a need-to-know basis for our employees and vendors who are subject to strict contractual confidentiality obligations.",
      Privacy_11_title:
        "TRANSFER OF PERSONAL INFORMATION OUTSIDE OF THE EEA and UK and YOUR COUNTRY",
      Privacy_11_desciption:
        "We may transfer your personal information outside the EEA and UK to other Company subsidiaries, service providers, and business partners (i.e., Data Processors) who are engaged on our behalf. To the extent that we transfer your personal information outside of the EEA and UK, we will ensure that the transfer is lawful and that Data Processors in third countries are obliged to comply with the European Union (EU) General Data Protection Act 2016 and the UK Data Protection Act 2018. Also, by using our products and services, you consent to your Personal Data being transferred to other countries, including countries that have differing levels of privacy and data protection laws than your country. In all such transfers, we will protect your personal information as described in this Privacy Notice and ensure that appropriate information sharing contractual agreements are in place.",
      Privacy_12_title: "COMPLIANT PROCESS",
      Privacy_12_desciption:
        "If you have any complaint or grievance regarding how we are handling your personal data or about how we are complying with the CPT (when in force), we welcome you to contact us with your complaint or grievance and should be addressed to CPT@Easyexchangepro.com Where you are sending an email in which you are submitting a complaint, your indication at the subject header that it is a CPT complaint would assist us in attending to your complaint speedily by passing it on to the relevant staff in our organization to handle. We will certainly strive to deal with any complaint or grievance that you may have speedily and fairly.",
      Privacy_13_title: "YOUR ACCEPTANCE",
      Privacy_13_desciption:
        "By using Easyexchangepro Site, you signify your acceptance of this policy and Terms of Use.",
      Privacy_14_title: "CHANGES TO THIS PRIVACY POLICY",
      Privacy_14_desciption:
        "Any changes we may make to our Privacy Policy in the future will be posted on this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.",
      // Product Convert
      Convert_title: "转换",
      Convert_for_each: "每",
      Convert_receive: "您将获得",
      Convert_calc: "计算转换",
      // Product Market Tabs
      Market_tab_Spot: "现货",
      Market_tab_Favorite: "收藏",
      // Product OTC
      OTC_title: "Easy Pro场外交易台",
      OTC_description:
        "希望进行加密货币兑换，尤其是大额交易的交易者通常选择使用场外（OTC）加密货币交易。Easy Pro场外交易台将为您提供保密、安全和竞争力极高的执行和结算服务。",
      OTC_Feature_1: "我们以保密方式进行场外交易，对市场影响不大。",
      OTC_Feature_2: "我们的交易台尊重隐私，所有Easy Pro的安全协议都会被遵守。",
      OTC_Feature_3: "结算发生在Easy Pro生态系统内，并且通常在几分钟内完成。",
      OTC_Feature_4: "结算将在几分钟内完成",
      OTC_Feature_5: "在我们的交易台没有第三方",
      OTC_Feature_6: "交易发生在Easy Pro账户中，您无需创建新钱包。",
      OTC_Feature_7: "您交易的币/代币将由我们的团队手动转入和转出您的账户。",
      OTC_Feature_8: "交易详细信息只能在您账户的“交易历史”部分找到。",
      OTC_Support_title: "OTC支持",
      OTC_Support_description:
        "OTC交易台提供全球覆盖，全天候开放，除主要节假日外OTC@Easyexchangepro.com",
      OTC_list_1: "请填写OTC表格并发送给我们",
      OTC_list_2: "我们的团队将提供报价、交易和结算 ",
      OTC_list_3:
        "一旦您接受我们的报价，交易将得到确认，我们的团队将通过电子邮件向您发送交易的详细信息和指示。",
      OTC_list_4:
        "当我们收到您的交易对方时，OTC交易台将在您的钱包或银行账户上进行结算",
      // Product Trade
      Trade_KYC_Error: "请上传您的KYC",
      Trade_Trading_password_Error: "请启用交易密码",
      Trade_Trading_password_title: "输入您的交易密码",
      Trade_Trading_password_input: "交易密码",
      Trade_Trading_Password_btn: "输入交易密码",
      Trade_goto_security: "转到安全",
      Trade_FAV: "收藏",
      Trade_Open_Orders: "未成交订单",
      Trade_Order_history: "订单历史",
      Trade_Trade_history: "交易历史",
      Trade_Date_Time: "日期和时间",
      Trade_Type: "类型",
      Trade_Action: "操作",
      Trade_Login_Register: "登录/注册",
      Trade_Password_Error: "请输入密码。",
      Trade_Balance_Error: "余额不足，您有",
      Trade_Min_Error: "订单金额应大于20 USDT",
      Trade_approximately: "您将获得约",
      Trade_Market: "市场",
      Trade_Order_List: "订单列表",
      Trade_Order_History: "订单历史",
      Trade_Pairs: "交易对",
      Trade_Open_Chart: "打开图表",
      // Profile
      Profile_profile: "个人资料",
      Profile_security: "安全",
      Profile_history: "历史记录",
      Profile_Security_log_history: "安全日志历史",
      Profile_Login_history: "登录历史",
      Profile_User_info_title: "用户信息",
      Profile_User_info_description: "您需要保护好的独特账户信息。",
      Profile_UID: "UID",
      Profile_Sec_info_title: "安全信息",
      Profile_Activated: "已激活",
      Profile_Disabled: "已禁用",
      Profile_Trading_Password: "交易密码",
      Profile_Account_status_title: "账户状态",
      Profile_KYC: "KYC",
      Profile_Sec_level: "安全级别",
      Profile_Airdrop_point: "空投积分",
      Profile_Referrals_title: "推荐",
      Profile_Num_Referrals: "推荐人数",
      Profile_Referral_ID: "推荐ID",
      Profile_Referral_List_btn: "推荐列表",
      Profile_Commissions_btn: "佣金",
      Profile_User: "用户",
      Profile_KYC_Verified: "已验证",
      Profile_KYC_Pending: "待处理",
      Profile_KYC_Rejected: "已拒绝",
      Profile_KYC_NotUploaded: "未上传",
      Profile_Sec_lvl_Medium: "中等",
      Profile_Sec_lvl_VeryHigh: "非常高",
      Profile_Account_Password_title: "账户密码",
      Profile_Account_Password_description: "您可以在此处更改密码。",
      Profile_Change_Password_btn: "更改密码",
      Profile_Change_your_password_title: "更改您的密码",
      Profile_Current_Password: "当前密码",
      Profile_New_Password: "新密码",
      Profile_Confirm_New_Password: "确认新密码",
      Profile_Sec_Authenticaton_title: "第二因素身份验证",
      Profile_Sec_Authenticaton_description:
        "您可以在此处启用/禁用您的两步验证。",
      Profile_Active_2FA: "启用两步验证",
      Profile_Activating_2FA: "正在启用两步验证",
      Profile_Disable: "禁用",
      Profile_Disable_2FA: "禁用两步验证",
      Profile_2FA_Code: "两步验证代码",
      Profile_Active: "已启用",
      Profile_Trading_Password_description: "您可以在此处更改您的交易密码。",
      Profile_Change_trading_password: "更改交易密码",
      Profile_Change_your_Trading_password_title: "更改您的交易密码",
      Profile_Change_your_Trading_password_description: "您想更改交易密码吗？",
      Profile_forgot_code: "忘记代码",
      Profile_KYC_title: "个人账户KYC",
      Profile_KYC_select_doc: "选择您的文件类型",
      Profile_KYC_select_doc_ID: "身份证",
      Profile_KYC_select_doc_pass: "护照",
      Profile_KYC_select_doc_Drive: "带有ID号码的驾驶执照",
      Profile_KYC_Upload_title: "请上传您的文件：",
      Profile_KYC_Front: "文件正面",
      Profile_KYC_Back: "文件背面",
      Profile_KYC_Selfie: "手持文件的自拍照",
      Profile_KYC_Approved: "您的KYC已通过审核。",
      Profile_KYC_Pending: "您的KYC正在等待审核。",
      Profile_Currency: "货币",
      Profile_Type: "类型",
      Profile_Login: "登录",
      Profile_Region: "地区",
      Profile_Login_device: "登录设备",
      // Register
      Reg_verify_email: "验证电子邮件",
      Reg_enter_otp: "输入OTP",
      Reg_Confirm_Password: "确认密码",
      Reg_Referral_Code: "推荐代码（可选）",
      // Terms Of Use
      Term_1_title: "1-Welcome to Easy Pro",
      Term_1_description_1:
        "Thanks indeed for visiting (Easyexchangepro / we, hereinafter referred to as the Easy pro) By visiting, accessing, or using easyexchangepro.com and associated application program interface or mobile applications (platforms), You (“User” or “You”) consent to the Terms of Service (the “Terms”), so please read them carefully.",
      Term_1_description_2:
        "The Terms constitute the agreement and understanding regarding the use of any or all the Services, and any manner of accessing them, between You and the following service provider (“we,” “us” or “our” platform) Easyexchangepro.",
      Term_2_title: "2-Key Definitions",
      Term_2_description_1:
        "Capitalized terms not otherwise defined in these Terms will have the following meaning:",
      Term_2_description_2:
        "“External Account” means any Digital Token Account: from which you may transfer funds into your Easy Pro  Account, or to which you may transfer funds from your Easy Pro Account and which has been approved by Easy pro for the foregoing purposes.",
      Term_2_description_3: "“Funds” means Digital Tokens.",
      Term_2_description_4: "“Fiat Currency” means any national currency.",
      Term_2_description_5:
        "“Easy Pro Account” means a user account accessible via the Services where Funds may be stored by Easy pro on behalf of a user.",
      Term_2_description_6:
        "“Easy Pro Help Center” means Easy pro’s customer service center with address at Support@easyexchagepro.com -us where you could get online support from Easy pro.",
      Term_2_description_7:
        "“Digital Tokens” means Bitcoins, Ripples, and other digital mediums of exchange that may be purchased, sold, or traded via the Services.",
      Term_2_description_8:
        "“Digital Token Account” means any Digital Token address or account owned or operated by you that is maintained outside of the Services, and is not owned, controlled, or operated by Easyexchangepro.",
      Term_3_title: "3- Accept terms",
      Term_3_description_1:
        "You should read the Terms, and any document referred to in them very carefully. If there is anything that You do not understand in the Terms and any document referred to herein, you should discuss this matter with us and seek the necessary clarification.",
      Term_3_description_2:
        "You are at least 18 years of age and have the full capacity to accept these Terms and enter a transaction involving digital assets. You have not been deprived of the right to use our service and have the full capacity for legal action. If you do not meet the above condition, please do not register our Site or Platform, otherwise, the platforms may suspend or terminate your account at any time.",
      Term_3_description_3:
        "You’re entering and performing these Terms are not prohibited by the laws and regulations of the country or region to which you belong, reside, pay tax or carry out business activities or other business. If you do not meet the above conditions, you should immediately terminate the registration or stop using our Platforms services.",
      Term_3_description_4:
        "Our Platform has the right to modify these Terms from time to time or to formulate and modify various specific rules according to these Terms and publish them in the relevant system sections of Platforms without separately notifying you.",
      Term_3_description_5:
        "You should pay attention to the changes in these Terms and the specific rules from time to time. If you continue to use the Service after the changes in the content of these Terms and the specific rules, you are deemed to have fully read, understood, and accepted the amended Terms and the specific rules and to use the services of our Platforms in accordance therewith.",
      Term_3_description_6:
        "You also agree that Easy Pro may, by giving notice, at its sole discretion immediately terminate your access to our Platform and to your account. You agree and acknowledge our right to limit, suspend or terminate the service and your user account, prohibit your access to our Platform and their content, services, and tools, delay or remove hosted content, and take technical and legal steps to keep you off our Platform or any further steps to recover any loss or damages caused if we determine at our sole discretion that you are or may be in breach of any laws, regulations, the rights of third parties, or any of these Terms or Platform’s policies. Easy Pro shall not be liable for any loss or damage caused to you or any third party because of exercising our rights under this clause.",
      Term_4_title: "4- Our Trading Services",
      Term_4_description:
        "Easy Pro provides an online platform with a simple and convenient way to trade one type of digital asset or another type of digital asset. Easy Pro provides you with a platform that matches your orders with open orders from other users of our Services.",
      Term_4_subheader_1: "Spot.",
      Term_4_subtitle_1: "Market Order:",
      Term_4_description_1:
        "A Market Order is placed to buy or sell a Specified Quantity or a Specific Gross Amount of Targeted tokens at the best available Latest Market Price.",
      Term_4_subtitle_2: "Limit Order:",
      Term_4_description_2:
        "A Limit Order is placed to buy or sell a Specific Quantity of the Targeted Token at a Specific Price and shall be filled at such Specific Quantity and Specific Price.",
      Term_4_subtitle_3: "Stop Market Order:",
      Term_4_description_3:
        "A Stop Market Order is placed to buy a Specific Quantity of Targeted Token or to buy a Specific Gross Amount of Targeted Token at the Latest Market Price when the upper limit of the Preset Stop Price is triggered. This means that when the Latest Market Price reach or beyond the upper limit of the Preset Stop Price, this order will be executed and filled at the Latest Market Price which may end up higher than the upper limit of the Preset Stop Price.",
      Term_4_subtitle_4: "Stop Limit Order:",
      Term_4_description_4:
        "A Stop Limit Order is placed to sell a Specific Quantity of Targeted Token at the Specific Price when the inferior limit of Preset Stop Price is triggered. This means that when the Latest Market Price reaches or is below the inferior limit of the Preset Stop Price, this order will be executed and filled at the Specific Quantity as well as the Specific Price.",
      Term_4_subheader_2: "Easy card",
      Term_4_description_5:
        "Refers to transactions in which Gabur tokens are exchanged for fiat currency and deposited in the account of the user.",
      Term_5_title: "5- Scope of Service and Your Rights and Obligations",
      Term_5_description_1:
        "Users shall not register multiple accounts for any purpose. Users may not use another User’s accounts. Users are prohibited in any form from utilizing the Site to engage in illegal activities. Without the authorization or permission of Easy Pro, the User shall not use any of the commercial activities in the name of this Site or in any form of the Site as a place, platform, or medium for engaging in commercial activities.",
      Term_5_description_2:
        "Users shall comply with all laws and regulations and bear the responsibility and legal consequences of any of their own actions involving the Site and Services.",
      Term_5_description_3:
        "Users have the right to view the information regarding their accounts on our Platform and have the right to operate the functions provided by our Platform. The user understands and agrees that our Platform can adjust the service content, service type, and service form on the Platform at any time according to the actual situation at our sole discretion. Our Platform is not responsible for any negative impact or loss caused to you or any third party due to Platform’s adjustments.",
      Term_5_description_4:
        "Depending on your country of residence, you may not be able to use all the functions of the Site or the Platform. Without prejudice to our right of suspending certain services to users from a specific country, it is your responsibility to ensure that in accessing our Site, Platform, and services, you are complying with the laws and regulations in your country of residence and/or the country from which you access this Site, the Platform, and our services. you are responsible for any disputes or losses caused by your failure to promptly modify or confirm the status of the transaction or failure to submit the relevant application. Our Platforms do not assume any responsibility.",
      Term_5_description_5:
        "Engaging in trades in cryptographic tokens may be highly risky. Please do not use Easy Pro’s services for trading in Digital assets if you do not understand these risks.",
      Term_6_title: "6- Registration Process",
      Term_6_description_1:
        "You agree to provide a valid email address, a mobile phone number, and other information in accordance with the requirements on the user registration page of this platform. You can use the email address, mobile phone number or any other manner permitted to log in to this platform. Where it is necessary and in accordance with the requirements of applicable laws and regulations of relevant jurisdictions, you shall provide your real name, identity card, and other information required by applicable laws, regulations, the Terms of Privacy, and anti-money-laundering terms, and constantly update your registration data so that they will be timely, detailed, and accurate as is required. All the original typed data will be referenced as registration information. You shall be responsible for the authenticity, integrity, and accuracy of such information and bear any direct or indirect loss and adverse consequences arising out of it.",
      Term_6_description_2:
        "If any of the applicable laws, regulations, rules, orders, and other regulatory documents of the sovereign country or region in which you are based requires that mobile phone accounts must be based on real names, you hereby confirm that the mobile phone number you provide for registration purposes has gone through the real-name registration procedure. If you cannot provide such a mobile phone number as is required, any direct or indirect losses and adverse consequences arising therefrom and affecting you shall be borne by you.",
      Term_6_description_3:
        "After you provide the required registration information in a legal, complete, and valid manner and such information passes relevant verification, you shall have the right to obtain an account and a password of Easy exchange pro. Upon obtaining such an account and password, your registration shall be deemed as successful, and you can log into the platform as a member thereof. You agree to receive emails and/or short messages sent by the platform related to the management and operation thereof.",
      Term_6_description_4:
        "Particularly during periods of high volume, illiquidity, fast movement, or volatility in the marketplace for any, or one or more Digital Tokens, the actual market rate at which a trade is executed may be different from the prevailing rate indicated via the Services at the time of your order or trade. You understand that we are not liable for any such price fluctuations. In the event of a market disruption or Force Majeure event, Easy exchange pro may do one or more of the following: suspend access to the Services; or prevent you from completing any actions via the Services, including closing any open positions. Following any such event, when trading resumes, you acknowledge that prevailing market rates may differ significantly from the rates available prior to such an event.",
      Term_6_description_5:
        "You hereby authorize Easy exchange pro to attempt and/or perform any such correction, reversal, cancellation, voiding, or amendment described in this section. Easy exchange pro. provides no guarantee or warranty that any such attempt will be successful and will have no responsibility or liability for the Manifest Error or the making or failure of any correction attempt, except if caused by the gross negligence, willful default, or fraud of Easy exchange pro. There is a risk that you may be prejudiced by any action or omission of Easy exchange pro in this regard, and you accept that you have no recourse whatsoever against Easy exchange pro.",
      Term_7_title: "7- Fees",
      Term_7_description_1:
        "You agree to pay for transactions through our Fees Structure service, which may change from time to time. Changes to the Fee Structure are effective as of the effective date indicated in the posting of the revised Fee Structure to the Services and will apply prospectively to any trades that take place following the effective date of such revised Fee Structure.",
      Term_7_description_2:
        "In addition to the costs, your external account may incur costs in connection with your use of your designated external account through the Services. Costs incurred by your external account provider are not reflected on the Transactions page, which contains information about applicable costs. You are solely responsible for paying any fees imposed by an External Account provider.",
      Term_7_description_3:
        "You authorize us, or our designated payment processor, to charge or deduct your Easy Pro Account Funds for any applicable Fees owed in connection with trades you complete via the Services.",
      Term_8_title: "8- Communication",
      Term_8_description_1:
        "You agree and consent to receive electronically all communications, agreements, documents, receipts, notices, and disclosures (collectively, “Communications”) that Easy Pro provides in connection with your Easy Pro Account and/or use of the Easy Pro Services.",
      Term_8_description_2:
        "Updating Contact Information. It is your responsibility to keep your email address on file with Easy Pro up to date so that Easy exchange pro can communicate with you electronically.",
      Term_9_title: "9- Intellectual Property",
      Term_9_description:
        "Unless otherwise indicated by us, all copyright and other intellectual property rights in all content and other materials contained on our website/platform or provided in connection with the Services, including, without limitation, the Easy Pro logo and all designs, text, graphics, pictures, information, data, software, sound files, other files and the selection and arrangement thereof are the proprietary property of Easy Pro or our licensors or suppliers and are protected by international copyright laws and other intellectual property rights laws.",
      Term_10_title: "10- Unclaimed or Disputed Property",
      Term_10_description_1:
        "If for any reason Easy Pro is holding Funds in your Easy  Account, such Funds remain unclaimed, and Easy Pro is unable to return your Funds to your designated External Account after a period of three years after your last login to Easy Pro or any specified period notified by Easy Pro, and Easy Pro determines in good faith that it is not able to trace you, you agree that all such Funds shall be deemed to have been abandoned by you in favor of Easy Pro and may be appropriated by Easy Pro to and for itself, and you thereafter shall have no right to claim such Funds.",
      Term_10_description_2:
        "If Easy Pro receives notice that any Digital Tokens held in your Easy Pro Account are alleged to have been stolen or otherwise are not lawfully possessed by you, Easy Pro may, but has no obligation to, place an administrative hold on or freeze the affected Digital Tokens or your Easy Pro Account. If Easy Pro does place an administrative hold on or freeze some or all your Digital Tokens, Easy Pro may continue such hold until such time as the dispute has been resolved and evidence of the resolution acceptable to Easy Pro has been provided to Easy Pro in a form acceptable to Easy Pro. Easy Pro will not involve itself in any such dispute or the resolution of the dispute. You agree that Easy Pro will have no liability or responsibility for any losses, damages, or prejudice arising from or in connection with such hold or freeze, or for your inability to withdraw Digital Tokens or execute trades during the period of any such hold or freeze.",
      Term_11_title: "11- Complains about your local laws",
      Term_11_description:
        "It is your sole responsibility to determine whether, and to what extent, any taxes apply to any transactions You conduct through the Services, and to withhold, collect, report, and remit the correct amounts of taxes to the appropriate tax authorities. When using our Services, you acknowledge that your actions are in a legal and proper manner and your financial sources are not from illegal activities. We may discretionarily or in coordination with local law enforcement authorities seize, restrict or close out your account, fiat, and/or cryptocurrencies.",
      Term_12_title: "12- Suspension; Termination",
      Term_12_description_1:
        "In the event of any Force Majeure Event breach of these. Terms or Conditions, or any other event where the provision of services to Easy pro is commercially unreasonable, we may, at our sole discretion and without liability to you, with or without prior notice, grant you access to all or part of Suspend it. We may also immediately, without notice, terminate your access to the Services at our sole discretion and delete or deactivate your Easy exchange pro account and all related information and files on this account without liability to you, including in cases where you are violating one of these conditions. In the event of discontinuation of all Services or termination of your access to the Services or deletion or deactivation of your Easy Pro Account: all amounts payable by you to Easy Pro will immediately become due. the platform can cancel any open orders or other transaction requests that are pending at the time of discontinuation or termination or deletion or deactivation. the platform will, where possible, return any Funds stored in your Easy Exchange pro Account not otherwise owed to Easy Pro and/or will use commercially reasonable efforts to provide you with a period 4 months to transfer affected Digital Tokens from your Easy Pro Account, unless prohibited by applicable laws or regulations or by order of law enforcement or governmental authority, or Easy Pro believes you have committed fraud, negligence or other misconduct; and/or may take such other steps as Easy Pro deems necessary or desirable to protect its own interests. We are not and shall not be responsible or liable for any loss or damages incurred because of or arising from any actions taken under this section.",
      Term_12_description_2:
        "Also, if the user has not made any deposits, transactions, or logins for a period of three months by any reason, we may delete or deactivate the user's account, without notifying the User and without assuming any responsibility",
      Term_13_title: "13- Descramble of liability",
      Term_13_subtitle_1: "13.1 Network Service Provider:",
      Term_13_description_1:
        'as a third-party platform for "network service provider", does not guarantee the information and services provided on this Site can fully meet the needs of users. We shall not be liable for errors, insults, defamation, nonfeasance, obscenity, pornography, or blasphemy that may occur during the process of acceptance of the Parties’ web service.',
      Term_13_subtitle_2: "13. 2 Service Interruption:",
      Term_13_description_2:
        "Based on the special nature of the Internet, we do not guarantee that the service will not be interrupted; the timeliness and security of the service are also not guaranteed.",
      Term_13_subtitle_3: "13.3 Safety of the Network:",
      Term_13_description_3:
        "We try to provide a safe network environment to the users; however, we do not guarantee that the Site or its servers are free of viruses or other potentially harmful factors; therefore, the user should use the industry’s recognized software to check and kill any virus in the files downloaded from the Site.",
      Term_13_subtitle_4: "13. 4 User Information:",
      Term_13_description_4:
        "we are not responsible for the failure of preservation, modification, deletion, or storage of the information provided by the user. Nor will we be liable for the typographical errors, negligence, etc. We have the right but no obligation to improve or correct any omission, error of any part of this Site.",
      Term_13_subtitle_5:
        "13. 5 Accuracy, Completeness, and Reliability of the Site:",
      Term_13_description_5:
        "Unless we have expressly agreed in writing, we shall not guarantee the accuracy, completeness, reliability of any content, such as but not limited to, advertising from the platform in any manner (including but not limited to, containing, connecting, by way of, or downloading) from the Site; we are not responsible for any products, services, information or materials purchased or obtained by the user according to the content information on this website. The user bears the risk of using the content of this website.",
      Term_13_subtitle_6: "13. 6 User Opinion:",
      Term_13_description_6:
        "The user comments published by users of the Site, are only on behalf of the user’s personal point of view. It does not mean that this Site agrees with their views or confirms their description. This Site does not bear any legal responsibility caused by any user comments.",
      Term_13_subtitle_7: "13. 7 Announcements:",
      Term_13_description_7:
        "Regarding notices issued to the users, we will deliver these notices through a formal page announcement, station letter, e-mail, customer service phone call, SMS, or regular mail delivery. We do not bear any legal responsibility for any winning, discount activities or information that are delivered by other channels.",
      Term_13_subtitle_8:
        "13. 8 Charges, Commissions, Transactions, and Other Fees:",
      Term_13_description_8:
        "we have the right to adjust the recharge, commission, transaction, and other fees according to market conditions, and terminate any promotional efforts early.",
      Term_14_title: "14- Jurisdiction",
      Term_14_description:
        "These Terms shall be governed by and construed in accordance with the laws of Dubai. The courts of Dubai shall have exclusive jurisdiction to settle any dispute arising from or connected with these Terms (including a dispute relating to the existence, validity, or termination of these Terms or the consequences of their nullity or any non-contractual obligation arising out of or in connection with these Terms).",
      Term_15_title: "15. Miscellaneous",
      Term_15_description_1:
        "These Terms set forth the complete understanding of the parties with respect to the subject matter hereof and supersedes all prior understandings and communications relating thereto our Platforms reserve the right of final interpretation of these Terms within the scope permitted by law. These Terms and related pages of our Platforms may refer to each other. If there is any conflict, these Terms shall prevail. In addition, if some of the provisions in these Terms are deemed invalid or unenforceable, the other provisions in these Terms will remain in effect. In the event of any conflict between these Terms and any other agreement, you may have with Easy Exchange pro the terms of that other agreement will prevail only if these Terms are specifically identified and declared to be overridden by such other agreement.",
      Term_15_description_2:
        "Any failure or delay by Easy Exchange Pro to enforce any of these Terms or to exercise any right hereunder shall not be construed as a waiver to any extent of our rights.",
      Term_16_title: "16- Limitation of Liability",
      Term_16_description:
        "Except as otherwise required by law, in no event shall Easy exchange pro, our directors, officers, employees, agents, Brokers or contractors be liable for any special, indirect, or consequential damages, or any other damages of any kind, including but not limited to loss of use, loss of profits or loss of data, whether in an action in contract, tort (including but not limited to negligence) or otherwise, arising out of or in any way connected with the use of or inability to use our Services or the Easy exchange pro Materials, including without limitation any damages caused by or resulting from reliance by any user on any information obtained from Easy exchange pro , or that result from mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses, delays in operation or transmission or any failure of performance, whether or not resulting from a force majeure event, communications failure, theft of, destruction of or unauthorized access to Easy exchange pro’s records, programs or services. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the limitations of this section may not apply to you.",
      Term_17_title: "17- Miscellany",
      Term_17_subtitle_1: "17 -1 Agency",
      Term_17_description_1:
        "Nothing in this Agreement shall be deemed to have created, implied, or otherwise treated us as your agent, trustee, or another representative unless it is provided otherwise in this Agreement.",
      Term_17_subtitle_2: "17- 2 Waiver",
      Term_17_description_2:
        "Our or your waiver of the right to hold the other party liable for breaches of agreement or any other liability as is agreed upon in this Agreement shall not be construed or deemed as a waiver of the right to hold the other party for other breaches of contract; a failure to exercise any right or remedy shall not be construed in any way as a waiver of such right or remedy.",
      Term_17_subtitle_3: "17- 3 Goodwill",
      Term_17_description_3:
        "You acknowledge and agree that, before filing any dispute or claim, you will contact us through support@easyexchangepro.com to solve the dispute in an informal way where we will try to solve the relevant dispute internally as soon as possible; The Parties agree to negotiate and solve any dispute in good faith (such negotiation shall be confidential and protected under applicable rules, and will not be used as evidence in any proceeding).",
      Term_17_subtitle_4: "17- 4 Assignment of rights",
      Term_17_description_4:
        "The rights and obligations agreed in this Agreement shall be equally binding on the assignees, the heirs, executors, and administrators of the parties hereto who benefit from the rights and obligations. Without our consent, you may not transfer to any third party any of your rights or obligations hereunder, provided, however, we may, at any time, assign our rights and obligations under this Agreement to any third party with forty (40) days' notices to you.",
      Term_17_subtitle_5: "17- 5 Severability",
      Term_17_description_5:
        "The invalidity or unenforceability of any of these Terms shall not affect the validity or enforceability of any other of these Terms, all of which shall remain in full force and effect.",
      Term_17_subtitle_6: "17- 6 Governing language and translations",
      Term_17_description_6:
        "These Terms, the Privacy Policy, and other agreements or communications notified through the Services have been drafted in English. Although translations in other languages of any of the foregoing documents may be available, such translations may not be up to date or complete. Accordingly, you agree that in the event of any conflict between the English language version of the foregoing documents and any other translations thereof, the English language version of such documents shall govern and prevail.",
      // Token Listing
      TL_title: "填写代币上市申请表",
      TL_Exchange_info: "交易所信息",
      TL_Token_Name: "代币名称",
      TL_Token_Name_Error: "请输入代币名称。",
      TL_Token_symbol: "代币符号",
      TL_Token_symbol_Error: "请输入代币符号。",
      TL_Token_Symbol_upload_Error: "请上传代币符号。",
      TL_NFT: "NFT",
      TL_Total_supply_Error: "请输入总供应量。",
      TL_Date_of_issue: "发行日期",
      TL_Date_of_issue_Error: "请输入发行日期。",
      TL_Total_circulation: "总流通量",
      TL_Total_circulation_Error: "请输入总流通量。",
      TL_Market_cap_circulation: "市值流通量",
      TL_Market_cap_circulation_Error: "请输入市值流通量。",
      TL_Source_code: "源代码",
      TL_Source_code_Error: "请输入源代码。",
      TL_Whitepaper: "白皮书",
      TL_Whitepaper_Error: "请上传白皮书。",
      TL_Communities: "社区",
      TL_Telegram: "Telegram",
      TL_Telegram_Error: "请输入Telegram。",
      TL_Twitter: "Twitter",
      TL_Twitter_Error: "请输入Twitter。",
      TL_Facebook: "Facebook",
      TL_Facebook_Error: "请输入Facebook。",
      TL_Reddit: "Reddit",
      TL_Reddit_Error: "请输入Reddit。",
      TL_Discord: "Discord",
      TL_Discord_Error: "请输入Discord。",
      TL_Submitter_info: "提交人信息",
      TL_Role: "角色",
      TL_Role_Error: "请输入角色。",
      TL_Personal_ID: "个人身份证",
      TL_Personal_ID_Error: "请上传个人身份证。",
      TL_About_project: "关于项目",
      TL_About_project_Error: "请输入关于项目。",
      TL_About_Private: "关于私募、ICO/IEO/IDO",
      TL_About_Private_Error: "请输入关于私募、ICO/IEO/IDO。",
      TL_Distribution_plan: "分配计划",
      TL_Distribution_plan_Error: "请输入分配计划。",
      TL_CEO_info: "CEO信息/CV Linkedin",
      TL_CEO_info_Error: "请输入CEO信息/CV Linkedin。",
      TL_Policy: "提交表格即表示您接受我们的隐私政策",
      // Deposit
      Deposit_select_Token: "请选择代币：",
      Deposit_select_Network: "请选择网络：",
      Deposit_Address: "地址",
      Deposit_attention: "请注意提现地址和选择的网络。",
      Deposit_Network: "网络",
      Deposit_Warning:
        "如果您向错误的地址或使用错误的网络进行存款，您将丢失资产。",
      // Transfer
      Transfer_Transfer: "转账",
      Transfer_select_Error: "请选择代币",
      Transfer_Balance_Error: "余额不足。",
      Transfer_Main_to_Trade: "主账户到交易账户",
      Transfer_Trade_to_Main: "交易账户到主账户",
      // Withdraw
      Withdraw_balance_Error: "余额不足",
      Withdraw_Amount_low_Error: "提现金额不能小于",
      Withdraw_Amount_high_Error: "提现金额不能大于",
      Withdraw_2FA_Error_1: "您需要激活您的",
      Withdraw_2FA_Error_2: "两步验证（2FA）",
      Withdraw_2FA_Error_3: "才能进行提现。",
      Withdraw_KYC_Error_1: "您需要通过",
      Withdraw_KYC_Error_2: "KYC流程",
      Withdraw_KYC_Error_3: "才能进行提现。",
      Withdraw_Goto_Profile: "点击前往个人资料页面",
      Withdraw_min: "最小提现金额",
      Withdraw_max: "最大提现金额",
      Withdraw_fee: "提现手续费",
      Withdraw_Unconfirmed_attention_1: "如果您发送到错误的",
      Withdraw_Unconfirmed_attention_3: "和选择的",
      Withdraw_Unconfirmed_attention_4: "网络",
      Withdraw_Unconfirmed_attention_5:
        "，您将失去您的资产，EasyPro平台对于丢失的资产概不负责。",
      Withdraw_Selected_token: "选择的代币",
      Withdraw_Selected_Network: "选择的网络",
      Withdraw_Confirm_Mail_2FA: "确认邮件和Google身份验证",
      Withdraw_Confirmed: "已确认提现",
      Withdraw_Confirmed_attention_1: "请检查您的",
      Withdraw_Confirmed_attention_2: "电子邮箱收件箱",
      Withdraw_Confirmed_attention_3: "和",
      Withdraw_Confirmed_attention_4: "批准",
      Withdraw_Confirmed_attention_5: "已发送到您的电子邮箱，请完成提现流程。",
    },
  },
  ar: {
    translation: {
      ///////////////////// GLOBAL
      Already_Purchased: "مباع",
      Global_Products: "منتجات",
      Global_Transaction: "عملية",
      Global_Icon: "أيقونة",
      Global_Pair: "زوج",
      Global_LastPrice: "آخر سعر",
      Global_Price: "السعر",
      Global_24h: "24 ساعة",
      Global_High: "الأعلى",
      Global_Low: "الأدنى",
      Global_Change: "التغيير",
      Global_Volume: "الحجم",
      Global_Trade: "تداول",
      Global_EasyGuarantee: "ضمان سهل",
      Global_ApplicationForm: "نموذج الطلب",
      Global_EasyCard: "بطاقة سهلة",
      Global_Secure: "آمن",
      Global_Fast: "سريع",
      Global_Convert: "تحويل",
      Global_Gabur: "جابور",
      Global_Token: "رمز",
      Global_Total: "الإجمالي",
      Global_Amount: "المبلغ",
      Global_USDT: "USDT",
      Global_Stop: "توقف",
      Global_Limit: "حد",
      Global_Supply: "التوريد",
      Global_Whitepaper: "ورقة بيضاء",
      Global_Circulation: "التداول",
      Global_Website: "موقع الويب",
      Global_Buy: "شراء",
      Global_Sell: "بيع",
      Global_Loading: "جارٍ التحميل...",
      Global_Submit: "إرسال",
      Global_Cancel: "إلغاء",
      Global_Confirm: "تأكيد",
      Global_Close: "إغلاق",
      Global_OTP: "OTP",
      Global_Email: "البريد الإلكتروني",
      Global_2FA: "2FA",
      Global_Password: "كلمة المرور",
      Global_FName: "الاسم الأول",
      Global_LName: "الاسم الأخير",
      Global_IDNum: "رقم الهوية",
      Global_Login: "تسجيل الدخول",
      Global_Register: "التسجيل",
      Global_Transfer: "تحويل",
      Global_UID: "معرف المستخدم",
      Global_Purpose: "الغرض",
      Global_Mobile: "الهاتف المحمول",
      Global_Deposit: "الإيداع",
      Global_Withdraw: "السحب",
      Global_Fee: "الرسوم",
      Global_Fees: "الرسوم",
      Global_OTC: "OTC",
      Global_Support: "الدعم",
      Global_Download: "تحميل",
      Global_From: "من",
      Global_To: "إلى",
      Global_Now: "الآن",
      Global_Digital: "رقمي",
      Global_crypto: "عملة رقمية",
      Global_cash: "نقدي",
      Global_wallet: "محفظة",
      Global_account: "حساب",
      Global_Exchange: "تبادل",
      Global_trading: "تداول",
      Global_Accept_Terms:
        "بالتحقق من المربع، ستوافق على الشروط والأحكام الخاصة بنا.",
      Global_Available: "متاح",
      Global_Username: "اسم المستخدم",
      Global_Time: "الوقت",
      Global_Status: "الحالة",
      Global_Network_Address: "عنوان الشبكة",
      Global_Max_upload: "أقصى حجم للملف الواحد هو 10 ميجابايت",

      Global_Policy:
        "بالموافقة على إرسال النموذج، ستوافق على سياسة الخصوصية لدينا.",
      Global_UID_Error: "يرجى إدخال معرف المستخدم.",
      Global_IDNum_Error: "يرجى إدخال رقم الهوية.",
      Global_Mobile_Error: "يرجى إدخال رقم الجوال.",
      Global_Price_Error: "يرجى إدخال السعر.",
      Global_Stop_Error: "يرجى إدخال سعر التوقف.",
      Global_Amount_Error: "يرجى إدخال المبلغ.",
      Global_Total_Error: "يرجى إدخال الإجمالي.",
      Global_Email_Error: "يرجى إدخال البريد الإلكتروني.",
      Global_Password_Error: "يرجى إدخال كلمة المرور.",
      Global_FName_Error: "يرجى إدخال الاسم الأول.",
      Global_LName_Error: "يرجى إدخال الاسم الأخير.",
      Global_Website_Error: "يرجى إدخال عنوان الموقع الإلكتروني.",
      Global_Purpose_Error: "يرجى شرح الغرض من الطلب.",
      Global_Accept_Terms_Error: "يجب الموافقة قبل الإرسال.",
      Global_about: 'معلومات عنا',
      Global_coin_info: "معلومات عن العملة",
      Global_token_listing: "قائمة الرموز",
      Global_logout: "تسجيل خروج",
      // Home
      //// Top
      Home_Top_Download_From: "تحميل من",
      Home_Top_Playstore: "متجر Play",
      Home_Top_Download_APK: "تحميل APK",
      Home_Top_APPstore: "متجر APP",
      Home_Top_Register_Now: "سجل الآن",
      //// carousel
      Home_Carousel_1_title: "الشفافية والأمان",
      Home_Carousel_1_description:
        "قمنا بتوضيح تفاصيل ورقة البيضاء لرمز جابور لتسهيل قرارك بالاستثمار. بالإضافة إلى ذلك، تمت مراجعة عقد الذكاء الاصطناعي لرمز جابور لضمان أعلى مستوى من الأمان",
      Home_Carousel_2_title: "ضمان سهل",
      Home_Carousel_2_description:
        "لأول مرة في العالم الرقمي، يقدم موقع Easy Exchange Pro نظام الضمان السهل لنقل الأموال بين الشركات ولعقود الأعمال",
      Home_Carousel_3_title: "الدعم على مدار الساعة",
      Home_Carousel_3_description:
        "يمكنك التواصل مع فريق الدعم من خلال نظام التذاكر أو إرسال بريد إلكتروني إلى support@easyexchangepro.com",
      //// Easy Guarantee
      Home_EG_subtitle: "أول ضمان رقمي في عالم العملات الرقمية",
      Home_EG_description:
        "لأول مرة في العالم الرقمي، يقدم موقع Easy Exchange Pro نظام الضمان السهل لنقل الأموال بين الشركات ولعقود الأعمال.",
      Home_EG_more_info_btn: "مزيد من المعلومات",
      //// Easy Card
      Home_EC_subtitle_1: "الطريقة الوحيدة لشراء",
      Home_EC_subtitle_2: "العملات الرقمية بالنقد",
      Home_EC_description_1:
        "احصل على رمز بطاقة Easy Card الدفع المسبق بالقرب منك، وقم بإيداع",
      Home_EC_description_2:
        "رموز جابور في محفظتك. آمنة وموثوقة بدون الحاجة إلى",
      Home_EC_description_3:
        "حساب مصرفي أو بطاقة ائتمان. إنها الطريقة الوحيدة لشراء العملات الرقمية نقدًا.",
      Home_EC_Feature_1_title: "مستقل",
      Home_EC_Feature_1_description_1: "شراء العملات الرقمية بدون حساب مصرفي",
      Home_EC_Feature_1_description_2: "أو بطاقة ائتمان",
      Home_EC_Feature_2_description_1: "جميع أصولك تحت سيطرتك",
      Home_EC_Feature_2_description_2: "الكاملة",
      Home_EC_Feature_3_description_1: "إيداع النقدية في محفظتك",
      Home_EC_Feature_3_description_2: "في ثوانٍ معدودة",
      Home_EC_Feature_4_title: "سهل",
      Home_EC_Feature_4_description_1: "تفعل ما كنت تفعله دائمًا",
      Home_EC_Feature_4_description_2: "",
      Home_EC_Feature_5_title: "لا رسوم إيداع",
      Home_EC_Feature_5_description_1: "إيداع بدون أي تكاليف",
      Home_EC_Feature_5_description_2: "",
      Home_EC_Feature_6_description_1: "يمكنك تحويل رمز جابور إلى",
      Home_EC_Feature_6_description_2: "عملات أخرى.",
      Home_EC_Where_to_buy: "أين تشتري",
      //// Mobile Section
      Home_MS_title:
        "ابدأ التداول بسهولة باستخدام تطبيق Easy Exchange Pro على الهاتف المحمول.",
      Home_MS_list_1: "الوصول إلى أصولك على مدار الساعة وفي أي مكان",
      Home_MS_list_2: "آمن ومحمي",
      Home_MS_list_3: "واجهة سهلة الاستخدام",
      Home_MS_list_4: "أدوات تداول متقدمة",
      Home_MS_list_5: "تطبيق شامل",
      Home_MS_dl_for_ios: "تحميل لنظام iOS",
      Home_MS_dl_for_android: "تحميل لنظام Android",
      //about
      //// A Top video
      About_top_title: "مهمتنا",
      About_top_desciption:
        "تتمثل مهمتنا في بناء بيئة تتيح للجميع الوصول بسهولة إلى عالم العملات الرقمية وبالتالي الحرية المالية.",
      About_top_Feature_1: "600,000",
      About_top_Feature_1_description_1: "المستخدمون المسجلون",
      About_top_Feature_1_description_2: "",
      About_top_Feature_2: "2 مواقع",
      About_top_Feature_2_description: "مواقعنا في 2 دول",
      About_top_Feature_3: "+28 بلد",
      About_top_Feature_3_description: "خدمات في أكثر من 28 بلد",
      ///// A Second
      About_second_title: "لماذا تختار Easy Exchange Pro؟",
      About_second_subtitle: "سهل للمبتدئين ومتقدم للمحترفين",
      About_second_description_1:
        "على الرغم من أن عالم العملات الرقمية قد يكون معقدًا قليلاً، إلا أننا هنا للمساعدة! منصتنا تسهل التداول.",
      About_second_description_2:
        "نوفر لك أدوات لشراء وبيع أشهر العملات الرقمية جميعها في مكان واحد، مع الحماية من خلال أحدث التقنيات الأمنية.",
      About_second_Feature_1_description_1: "محمي بأمان صناعة الأمان",
      About_second_Feature_1_description_2: "بناءً على تقنية التشفير",
      About_second_Feature_2_title: "سهل الاستخدام",
      About_second_Feature_2_description_1: "لقد صممنا منصة بسيطة وسهلة",
      About_second_Feature_2_description_2: "لتكون في متناول يدك",
      About_second_Feature_3_title: "استخدام متقدم (للمحترفين)",
      About_second_Feature_3_description_1: "قمنا بتطوير واجهة تداول متقدمة",
      About_second_Feature_3_description_2: "توفر تجربة متقدمة للمستخدمين",
      About_second_Feature_4_description_1: "تداول العملات المفضلة لديك",
      About_second_Feature_4_description_2: "في لحظات",
      About_second_Feature_5_title: "الشراء نقدًا",
      About_second_Feature_5_description_1: "أسهل طريقة لشراء العملات الرقمية",
      About_second_Feature_5_description_2:
        "نقدًا متاحة فقط عبر بطاقة Easy Card",
      About_second_Feature_6_title: "24/7",
      About_second_Feature_6_description_1:
        "ابدأ في تداول العملات الرقمية وستحصل على دعمنا في أي وقت.",
      About_second_Feature_6_description_2: "",
      //// Road Map
      About_road_title: "خارطة الطريق",
      About_road_description_1:
        "تم تنظيم تطوير منصة Easy Exchange Pro على مدار ثلاث سنوات.",
      About_road_description_2:
        "هنا ستجد نظرة عامة على الأهداف التي وضعناها لتحقيقها في السنوات القادمة.",
      About_road_year_1: "2022",
      About_road_year_1_description_1: "منتج فريد: Easy Guarantee",
      About_road_year_1_description_2: "+ 6 لغات",
      About_road_year_1_description_3: "+ 10 وسطاء في 5 دول",
      About_road_year_1_description_4: "+ 100 موظف آخر",
      About_road_year_1_description_5: "+ 10 أزواج آخرين",
      About_road_year_2: "2023",
      About_road_year_2_description_1: "منتج جديد، Easy Wallet",
      About_road_year_2_description_2: "Easy Pro Academy",
      About_road_year_2_description_3: "واجهة برمجة التطبيقات لدمج الأعمال",
      About_road_year_2_description_4: "ستكون بطاقة Easy وسيلة فعالة للمعاملات",
      About_road_year_2_description_5: "+ 6 لغات",
      About_road_year_2_description_6: "+ 200 وسيط في 16 دولة",
      About_road_year_2_description_7: "+ 5 عملات فات",
      About_road_year_2_description_8: "+ 250 موظفًا",
      About_road_year_2_description_9: "+ 30 زوجًا",
      About_road_year_2_description_10: "+100 عملة موثوقة",
      About_road_year_2_description_11: "إدراج GABUR في منصات عالمية أخرى",
      About_road_year_3: "2024",
      About_road_year_3_description_1: "منتج فريد: Crypto Bank",
      About_road_year_3_description_2: "+ 6 لغات",
      About_road_year_3_description_3: "+ 1000 موظف",
      About_road_year_3_description_4: "+ 100 زوج",
      About_road_year_3_description_5: "+ 130 عملة موثوقة",
      About_road_year_3_description_6: "إدراج GABUR في منصات عالمية أخرى",
      // API Documents
      Api_title: "وثائق واجهة البرمجة",
      Api_1: "ملخص",
      Api_2: "الأصول",
      Api_3: "سعر الصرف",
      Api_4: "سجل الطلبات",
      Api_5: "الصفقات",
      Api_6: "حجم التداول خلال 24 ساعة",
      // Asset
      Asset_Tab_1: "جميع الأصول",
      Asset_Tab_2: "محفظة رئيسية",
      Asset_Tab_3: "محفظة تداول",
      Asset_Total_BTC: "إجمالي الأصول بالبيتكوين",
      Asset_In_Withdraw: "قيد السحب",
      Asset_In_Order: "قيد الطلب",
      // Contact us
      ContactUs_email_title: "تواصل معنا:",
      ContactUs_email: "Support@easyexchangepro.com",
      ContactUs_faq_title:
        "يمكنك مراجعة صفحة الأسئلة الشائعة للعثور على إجابتك:",
      ContactUs_faq: "",
      ContactUs_ticket_title: "أو يمكنك فتح تذكرة:",
      ContactUs_ticket: "",
      ContactUs_social_title: "ابحث عنا واتبعنا على:",

      // Coin info
      CoinInfo_Issue_Time: "Issue Time :",
      CoinInfo_White_paper: "Whitepaper:",
      CoinInfo_Website: "Website:",
      CoinInfo_Block_Explorer: "Block Explorer:",
      CoinInfo_AAVE:
        "Aave is an open source and non-custodial protocol enabling the creation of money markets. Users can earn interest on deposits and borrow assets. Deposit interest rate and loan interest rate of both borrowers and lenders are calculated through algorithms based on the platform's borrowing volume and deposit volume, and the platform uses Chainlink's oracles to ensure the fairness of the collateral price.",
      CoinInfo_ADA:
        "Cardano is a decentralised public blockchain and cryptocurrency project and is fully open source. Cardano is developing a smart contract platform which seeks to deliver more advanced features than any protocol previously developed. It is the first blockchain platform to evolve out of a scientific philosophy and a research-first driven approach.",
      CoinInfo_Atom:
        "In a nutshell, Cosmos bills itself as a project that solves some of the “hardest problems” facing the blockchain industry. It aims to offer an antidote to “slow, expensive, unscalable and environmentally harmful” proof-of-work protocols, like those used by Bitcoin, by offering an ecosystem of connected blockchains. The project’s other goals include making blockchain technology less complex and difficult for developers thanks to a modular framework that demystifies decentralized apps. Last but not least, an Interblockchain Communication protocol makes it easier for blockchain networks to communicate with each other — preventing fragmentation in the industry. Cosmos’ origins can be dated back to 2014, when Tendermint, a core contributor to the network, was founded. In 2016, a white paper for Cosmos was published — and a token sale was held the following year. ATOM tokens are earned through a hybrid proof-of-stake algorithm, and they help to keep the Cosmos Hub, the project’s flagship blockchain, secure. This cryptocurrency also has a role in the network’s governance.",
      CoinInfo_AVAX:
        "Avalanche is a layer one blockchain that functions as a platform for decentralized applications and custom blockchain networks. It is one of Ethereum’s rivals, aiming to unseat Ethereum as the most popular blockchain for smart contracts. It aims to do so by having a higher transaction output of up to 6,500 transactions per second while not compromising scalability. This is made possible by Avalanche’s unique architecture. The Avalanche network consists of three individual blockchains: the X-Chain, C-Chain and P-Chain. Each chain has a distinct purpose, which is radically different from the approach Bitcoin and Ethereum use, namely having all nodes validate all transactions. Avalanche blockchains even use different consensus mechanisms based on their use cases. After its mainnet launch in 2020, Avalanche has worked on developing its own ecosystem of DApps and DeFi. Different Ethereum-based projects such as SushiSwap and TrueUSD have integrated with Avalanche. Furthermore, the platform is constantly working on improving interoperability between its own ecosystem and Ethereum, like through the development of bridges.",
      CoinInfo_BNB:
        "From the ICO, Binance issued its own coin called the Binance Coin, with BNB as the symbol. BNB runs natively on Binance chain. The coin was established with a total supply of 200 million. Every quarter, we will destroy BNB based on the trading volume on our crypto-to-crypto platform until we destroy 50% of all the BNB. All transactions will be on the blockchain. We eventually will destroy 100MM BNB, leaving 100MM BNB remaining. The BNB coin itself has multiple forms of utility, essentially being the underlying gas that powers the Binance Ecosystem. The current most prominent use cases include using BNB to: - Pay for trading fees on the exchange, obtaining the equivalent of a 50% discount on trades (during the first year). - Crypto.com has included support for Binance’s BNB token to its platform which includes the MCO Visa Card and mobile app.",
      CoinInfo_BTC:
        "Bitcoin is a cryptocurrency and a digital payment system invented by an unknown programmer, or a group of programmers, under the name Satoshi Nakamoto.It was released as open-source software in 2009.The system is peer-to-peer, and transactions take place between users directly, without an intermediary. These transactions are verified by network nodes and recorded in a public distributed ledger called a blockchain. Since the system works without a central repository or single administrator, bitcoin is called the first decentralized digital currency.Besides being created as a reward for mining, bitcoin can be exchanged for other currencies, products, and services in legal or black markets.As of February 2015, over 100,000 merchants and vendors accepted bitcoin as payment. According to research produced by Cambridge University in 2017, there are 2.9 to 5.8 million unique users using a cryptocurrency wallet, most of them using bitcoin.",
      CoinInfo_Cake:
        "PancakeSwap, built on Binance Smart Chain (BSC), is a decentralized exchange for swapping BEP-20 tokens. It is fast, cheap, and allows anyone to participate. Users can get FLIP (LP token) for staking and get CAKE token as reward. CAKE holders can swap CAKE for SYRUP for additional incentivized staking. PancakeSwap uses an automated market maker (AMM) model. It means that while you trade digital assets on the platform, there isn’t an order book where you’re matched with someone else. Instead, you trade against a liquidity pool. Traders deposit funds into the pool, receiving liquidity provider (or LP) tokens in return. They can use those tokens to reclaim their share, plus a portion of the trading fees.",
      CoinInfo_Doge:
        "Dogecoin is a cryptocurrency focused on actual utility as a currency. We provide fast block times and very low fees which make Dogecoin suitable for usage in micro-transactions but also as payment option for online shops. Dogecoin has been adopted as such by online retailers and can be used easily as means of consumer to consumer money transfer too.",
      CoinInfo_Dot:
        'Polkadot is a global well-known blockchain cross-chain project, which is created by Ethereum co-founder and Ethereum yellow book author Gavin Wood. It aims to solve the communication and data transmission obstacles between different blockchains and was rated as one of the "4 major projects that decide the direction of digital currency development in 2019".',
      CoinInfo_ETC:
        "Ethereum classNameic (ETC) is a hard fork of Ethereum (ETH) that launched in July 2016. Its main function is as a smart contract network, with the ability to host and support decentralized applications (DApps). Its native token is ETC. Since its launch, Ethereum classNameic has sought to differentiate itself from Ethereum, with the two networks’ technical roadmap diverging further and further from each other with time. Ethereum classNameic first set out to preserve the integrity of the existing Ethereum blockchain after a major hacking event led to the theft of 3.6 million ETH.",
      CoinInfo_ETH:
        'Ethereum (ETH) is an open-source, public, blockchain-based distributed computing platform featuring smart contract (scripting) functionality, which facilitates online contractual agreements. Ethereum also provides a cryptocurrency token called "ether", which can be transferred between accounts and used to compensate participant nodes for computations performed. "Gas", an internal transaction pricing mechanism, is used to mitigate spamand allocate resources on the network.The value token of the Ethereum blockchain is called ether. It is listed under the diminutive ETH and traded on cryptocurrency exchanges. It is also used to pay for transaction fees and computational services on the Ethereum network.',
      CoinInfo_Fil:
        "The InterPlanetary File system (IPFS) is a global, peer-to-peer distributed version of the file system that aims to supplement (or even replace) the hypertext transfer protocol (HTTP) that currently dominates the internet, by connecting all computing devices with the same file system. The principle is to replace the address based on the domain name with a content-based address, that is, the user is looking for content that is not an address but stored in a certain place. There is no need to verify the identity of the sender, but only the hash of the content. It can ensure the web to be faster, safer, more robust and more durable.Filecoin is an incentive layer on IPFS and a a decentralized storage market built on IPFS based on the token incentive model.FIL6 is the Filecoin contract issued 6 months after its launch",
      CoinInfo_GBR:
        "Gabur (GBR) is a utility token for the Easy Exchange Pro platform. The Gabur is using for the Easy Card, a system to buy crypto without cash, and also Easy Guarantee which is a system to guarantee payment between companies and their customers.",
      CoinInfo_LTC:
        "Litecoin is a peer-to-peer Internet currency that enables instant, near-zero cost payments to anyone in the world. Litecoin is an open source, global payment network that is fully decentralized without any central authorities. Mathematics secures the network and empowers individuals to control their own finances. Litecoin features faster transaction confirmation times and improved storage efficiency than the leading math-based currency. With substantial industry support, trade volume and liquidity, Litecoin is a proven medium of commerce complementary to Bitcoin",
      CoinInfo_Matic:
        "Matic Network is a layer-2 scaling solution with side-chains for off-chain computation, while ensuring asset security using the Plasma framework and a decentralized network of Proof-of-Stake (PoS) validators. It aims to offer scalable and instant blockchain transactions. By utilizing adapted version of the Plasma framework, the system is built on PoS checkpoints that are pushed to the Ethereum main-chain. As a result, it is expected to allow each Matic sidechain to theoretically achieve 2^16 transactions per block. Matic Network has been a significant contributor to the Ethereum ecosystem, having worked on implementations of Plasma MVP (Minimum Viable Plasma), the WalletConnect protocol, and Dagger, a popular Ethereum event notification engine. Many projects have already been building applications on and integrating with Matic. MATIC is an ERC-20 token running on Ethereum, it used to pay for services on the Matic Network but also serves as a settlement currency between users who operate within the Matic ecosystem.",
      CoinInfo_MKR:
        "Maker (MKR) is the governance token of the MakerDAO and Maker Protocol — respectively a decentralized organization and a software platform, both based on the Ethereum blockchain — that allows users to issue and manage the DAI stablecoin. Initially conceived in 2015 and fully launched in December 2017, Maker is a project whose task is to operate DAI, a community-managed decentralized cryptocurrency with a stable value soft-pegged to the US dollar. MKR tokens act as a kind of voting share for the organization that manages DAI; while they do not pay dividends to their holders, they do give the holders voting rights over the development of Maker Protocol and are expected to appreciate in value in accordance with the success of DAI itself. The Maker ecosystem is one of the earliest projects on the decentralized finance (DeFi) scene: the industry that seeks to build decentralized financial products on top of smart-contract-enabled blockchains, such as Ethereum.",
      CoinInfo_Near:
        "NEAR Protocol is a layer-one blockchain that was designed as a community-run cloud computing platform and that eliminates some of the limitations that have been bogging competing blockchains, such as low transaction speeds, low throughput and poor interoperability. This provides the ideal environment for DApps and creates a developer and user-friendly platform. For instance, NEAR uses human-readable account names, unlike the cryptographic wallet addresses common to Ethereum. NEAR also introduces unique solutions to scaling problems and has its own consensus mechanism called “Doomslug.” NEAR Protocol is being built by the NEAR Collective, its community that is updating the initial code and releasing updates to the ecosystem. Its declared goal is to build a platform that is “secure enough to manage high value assets like money or identity and performant enough to make them useful for everyday people.” Flux, a protocol that allows developers to create markets based on assets, commodities, real-world events, and Mintbase, an NFT minting platform are examples of projects being built on NEAR Protocol.",
      CoinInfo_TRX:
        "Tron is one of the largest blockchain based operating systems in the world. TRON is an ambitious project dedicated to building the infrastructure for a truly decentralized Internet. The Tron Protocol, one of the largest blockchain based operating systems in the world, offers scalable, high-availability and high-throughput support that underlies all the decentralized applications in the TRON ecosystem. TRON Protocol and the TVM allow anyone to develop DAPPs for themselves or their communities, with smart contracts making decentralized crowdfunding and token issuance easier than ever. Tron DAPP projects already include Peiwo, Obike, Uplive, game.com, Kitty live and Mico, with 100M+ active users from more than 100 countries and regions around the world.",
      CoinInfo_XLM:
        "Stellar network is a free, open-source network that connects diverse financial systems and lets anyone build low-cost financial services—payments, savings, loans, insurance—for their community. It is supported by Stellar.org, a Silicon Valley based non-profit organization. The Stellar network enables money to move directly between people, companies and financial institutions as easily as email. This inter-connectivity means more access for individuals, lower costs for banks, and more revenue for businesses. Token use: Stellar is an open-source, decentralized protocol for digital currency to fiat currency transfers which allows cross-border transactions between any pair of currencies.",
      CoinInfo_XRP:
        "Ripple (XRP) is an independent digital asset that is native to the Ripple Consensus Ledger. With proven governance and the fastest transaction confirmation of its kind, XRP is said to be the most efficient settlement option for financial institutions and liquidity providers seeking global reach, accessibility and fast settlement finality for interbank flows.",
      CoinInfo_ZEC:
        "NZcash is a decentralized cryptocurrency focused on privacy and anonymity. It uses the zk-SNARK zero-knowledge proof technology that allows nodes on the network to verify transactions without revealing any sensitive information about those transactions. Contrary to a common misunderstanding, the majority of cryptocurrencies on the market, including Bitcoin (BTC), are not anonymous, but rather pseudonymous; while they do not explicitly reveal the identities of their users, each user has their own public address or addresses which can be traced back to them via the methods of data science and blockchain forensics. Zcash transactions, on the other hand, still have to be relayed via a public blockchain, but unlike pseudonymous cryptocurrencies, ZEC transactions by default do not reveal the sending and receiving addresses or the amount being sent. There is an option, however, to reveal this data for the purposes of auditing or regulatory compliance. Zcash was first released on October 28, 2016, and it was originally based on Bitcoin’s codebase.",
      // Easy Card
      //// Where To Buy
      ///// Top
      EC_WT_Title: "شراء بطاقة إيزي كارد",
      EC_WT_Description:
        "يمكنك شراء بطاقة إيزي كارد من أحد وسطاءنا بالقرب منك وشحن حسابك بدون فيزا أو بطاقة ائتمان",
      EC_WT_Find_Title: "ابحث عن وسيط بالقرب منك",
      EC_WT_Find_btn: "ابحث",
      EC_WT_Find_input: "أدخل مدينتك",
      ///// Apply
      EC_WA_title:
        "قدّم طلبًا للحصول على ترخيص بطاقة إيزي كارد وكن أحد شركائنا واستفد.",
      EC_WA_Decription: "تقديم الطلب",
      ///// Start
      EC_WS_Question: "كيف يعمل إيزي كارد؟",
      EC_WS_title: "ابدأ في 3 خطوات سهلة",
      EC_WS_SubTitle: "كل العملية في أقل من 3 دقائق.",
      EC_WS_Feature_1_description:
        "ابحث عن وسيط بالقرب منك واشتري بطاقة إيزي كارد",
      EC_WS_Feature_2_description:
        "أدخل رمز بطاقة إيزي الخاصة بك لإيداع رموز Gabur في محفظتك",
      EC_WS_Feature_3_description: "الآن يمكنك البدء في التداول",
      //// Already Purchased
      EC_AP_description:
        "أدخل رمزك هنا ، سيتم إيداع رموز Gabur في محفظتك تلقائيًا.",
      EC_AP_Code: "أدخل الرمز الخاص بك",
      EC_AP_Code_Error: "يرجى إدخال رمز مكون من 20 رقمًا.",
      //// Broker
      ///// Register
      EC_BR_title: "سجل كوسيط",
      ///// Form
      EC_BR_Exchange_info: "معلومات الصرافة",
      EC_BR_Legal: "الاسم القانوني",
      EC_BR_Legal_Error: "يرجى إدخال الاسم القانوني المسجل للبلد.",
      EC_BR_RegCountry: "البلد المسجل",
      EC_BR_RegCountry_Error: "يرجى إدخال البلد المسجل.",
      EC_BR_RegNum: "رقم التسجيل",
      EC_BR_RegNum_Error: "يرجى إدخال رقم التسجيل.",
      EC_BR_MonTxVal: "قيمة العمليات الشهرية",
      EC_BR_MonTxVal_Error: "يرجى إدخال قيمة العمليات الشهرية.",
      EC_BR_ECMonDis: "توزيع بطاقة إيزي الشهري",
      EC_BR_ECMonDis_Error: "يرجى إدخال توزيع بطاقة إيزي الشهري.",
      EC_BR_Website: "الموقع الإلكتروني (إذا كان لديك)",
      EC_BR_Submitter_info: "معلومات المقدم",
      EC_BR_Job: "المسمى الوظيفي",
      EC_BR_Job_Error: "يرجى إدخال المسمى الوظيفي.",
      EC_BR_Birth: "تاريخ الميلاد",
      EC_BR_Birth_Error: "يرجى إدخال تاريخ الميلاد.",
      EC_BR_Country: "بلد الإقامة",
      EC_BR_Country_Error: "يرجى إدخال بلد الإقامة.",
      EC_BR_Citizen: "بلد الجنسية",
      EC_BR_Citizen_Error: "يرجى إدخال بلد الجنسية.",
      EC_BR_Upload_title: "يرجى تحميل المستندات الخاصة بالصرافة والشخصية:",
      EC_BR_Exchange_Doc: "وثيقة تسجيل الصرافة",
      EC_BR_Exchange_Doc_Error: "يرجى تحميل وثيقة تسجيل الصرافة.",
      EC_BR_Peronal_ID_Doc: "وثيقة الهوية الشخصية",
      EC_BR_Peronal_ID_Doc_Error: "يرجى تحميل وثيقة الهوية الشخصية.",
      // Easy Guarantee
      //// Easy Guarantee
      ////// top
      EG_EGTop_title: "ضمان إيزي",
      EG_EGTop_Description_1: "الضمان الرقمي الوحيد للعملات المشفرة",
      EG_EGTop_Description_2: "لأول مرة على الإطلاق في العالم",
      ////// Text
      EG_EGText_title: "ما هو ضمان إيزي؟",
      EG_EGText_Description_1:
        "الضمان الإيزي هو نوع من الضمانات المدعومة بـ USDT التي تقدمها إيزي إكسشينجبرو، حيث يضمن الدائن أن التزامات المدين ستتم الوفاء بها. بمعنى آخر، إذا فشل المدين في تسوية دين ما، ستقوم إيزي إكسشينجبرو بتغطيته.",
      EG_EGText_Description_2:
        "يتيح الضمان الإيزي للمتقدم الحصول على البضائع، شراء المعدات، استخدام القروض، أو ضمان الدفعة المقدمة، إلخ.",
      EG_EGText_Feature_1_description:
        "سوف تكون قادرًا على إصدار ضمان إيزي لأغراض عملك في غضون 5 دقائق.",
      EG_EGText_Feature_2_title: "موثوقة",
      EG_EGText_Feature_2_description:
        "الضمان الإيزي ليس مجرد وثيقة التزام بالدفع؛ بل سيتم إيداع USDT في حساب المستفيد كأكثر العملات الرقمية سيولة.",
      EG_EGText_Feature_3_description:
        "الضمان الإيزي الذي تم إصداره آمن ومحمي بنسبة 100٪ للمستفيد ويمكن تحويله أو سحبه عند الاستحقاق.",
      ////// Steps
      EG_EGSteps_title: "يمكنك إصدار ضمان إيزي في 4 خطوات سهلة:",
      EG_EGSteps_Feature_1_title: "الخطوة 1",
      EG_EGSteps_Feature_1_description_1: "/",
      EG_EGSteps_Feature_1_description_2: "في منصتنا",
      EG_EGSteps_Feature_2_title: "الخطوة 2",
      EG_EGSteps_Feature_2_description_1: "املأ نموذج الطلب",
      EG_EGSteps_Feature_2_description_2: "وقدمه",
      EG_EGSteps_Feature_3_title: "الخطوة 3",
      EG_EGSteps_Feature_3_description_1: "قم بتحويل مبلغ الضمان",
      EG_EGSteps_Feature_3_description_2: "الإيزي بواسطة USDT",
      EG_EGSteps_Feature_4_title: "الخطوة 4",
      EG_EGSteps_Feature_4_description_1: "تم إصدار الضمان و",
      EG_EGSteps_Feature_4_description_2: "تم إيداع USDT وتأمينه في",
      EG_EGSteps_Feature_4_description_3: "حساب المستفيد",
      ////// Bot
      EG_EGBot_title: "للشركات والأفراد",
      EG_EGBot_description:
        "يمكن للشركات والأفراد كمستخدمين لدينا إصدار ضمان بمبالغ صغيرة أو كبيرة لأغراض عملهم لصالح المستفيد.",
      //// FAQ
      EG_EGFaq_Q1: "ما هو ضمان إيزي (Easy Guarantee)؟",
      EG_EGFaq_A1:
        "ضمان إيزي هو نوع من الضمانات التي تستخدم العملة المشفرة (USDT) كضمان، حيث يقوم المقترضون / المشترين بضمان أن ديونهم للدائن ستتم سدادها.",
      EG_EGFaq_Q2: "من هو المتقدم؟",
      EG_EGFaq_A2: "المتقدم هو الطرف الذي سيقوم بطلب إصدار ضمان إيزي.",
      EG_EGFaq_Q3: "ما هي العملة المقبولة لإصدار الضمان؟",
      EG_EGFaq_A3: "فقط USDT.",
      EG_EGFaq_Q4: "هل يتم قبول أي عنوان محفظة USDT؟",
      EG_EGFaq_A4:
        "لا، يتم قبول فقط عنوان محفظة USDT الخاص بالمتقدم والمستفيد في Easy Exchange Pro.",
      EG_EGFaq_Q5: "من هو المستفيد؟",
      EG_EGFaq_A5: "الطرف الذي تم إصدار الضمان لصالحه.",
      EG_EGFaq_Q6: "ما هو الضمان الإيزي المشروط؟",
      EG_EGFaq_A6: "يجب أن يتمتع المستفيد بموافقة المتقدم لإصدار مبلغ الضمان.",
      EG_EGFaq_Q7: "ما هو الضمان الإيزي غير المشروط؟",
      EG_EGFaq_A7:
        "سيتم إطلاق مبلغ الضمان الإيزي بناءً على طلب المستفيد الأول.",
      EG_EGFaq_Q8: "كيف يمكن للمستخدمين التقديم على الضمان الإيزي؟",
      EG_EGFaq_A8: "ملء نموذج الطلب وتقديمه.",
      EG_EGFaq_Q9: "ماذا سيحدث بعد تقديم النموذج؟",
      EG_EGFaq_A9:
        "سيتلقى المتقدم بريدًا إلكترونيًا أولاً لتأكيد الطلب، ثم بريدًا إلكترونيًا ثانيًا يحتوي على عنوان محفظة USDT لتحويل مبلغ الضمان الإيزي.",
      EG_EGFaq_Q10: "كم يمكن للمستخدم إصدار ضمان إيزي؟",
      EG_EGFaq_A10:
        "يمكن للمستخدم إصدار ضمان إيزي بقيمة رصيد USDT الموجود في حسابه.",
      EG_EGFaq_Q11: "هل يحتاج المتقدم إلى رصيد USDT لطلب وإصدار ضمان إيزي؟",
      EG_EGFaq_A11:
        "نعم، يمكن للمستخدمين طلب إصدار ضمان إيزي بقيمة رصيد USDT الموجود في حسابهم.",
      EG_EGFaq_Q12: "متى سيتم إصدار الضمان الإيزي؟",
      EG_EGFaq_A12:
        "عند تحويل المتقدم USDT، سيتم إصدار الضمان الإيزي فورًا وسيتم تجميد مبلغ USDT في حساب المستفيد بناءً على فترة الصلاحية.",
      EG_EGFaq_Q13:
        "كيف سيتم حل أي نزاع بشأن إطلاق الضمان بين المتقدم والمستفيد؟",
      EG_EGFaq_A13:
        "في حالة وجود أي نزاع، يمكن لأيٍ من الأطراف أن يرسل اعتراضهما إلى easyguarantee@easyexchangepro.com وسيقوم قسمنا القانوني باتخاذ قرار في غضون شهر واحد بعد استعراض وثائق الأطراف.",
      EG_EGFaq_Q14:
        "هل يجب أن يكون المتقدم والمستفيد على حساب في منصة Easyexchangepro؟",
      EG_EGFaq_A14: "نعم، يجب أن يكونوا على حساب في المنصة.",
      EG_EGFaq_Q15: "متى يمكن للمستفيد طلب إطلاق مبلغ الضمان الإيزي؟",
      EG_EGFaq_A15:
        "يمكن للمستفيد طلب إطلاق مبلغ الضمان الإيزي في حسابه خلال فترة صلاحية الضمان الإيزي.",
      EG_EGFaq_Q16:
        "إذا لم يطلب المستفيد إطلاق مبلغ الضمان خلال فترة صلاحية الضمان الإيزي، ماذا سيحدث؟",
      EG_EGFaq_A16: "سيتم تحويل مبلغ الضمان الإيزي إلى حساب المتقدم تلقائيًا.",
      EG_EGFaq_Q17: "كم يستغرق إصدار ضمان إيزي؟",
      EG_EGFaq_A17: "أقل من 5 دقائق.",
      EG_EGFaq_Q18: "كم تكلف إصدار ضمان إيزي؟",
      EG_EGFaq_A18: "0.4٪ من قيمة ضمان إيزي.",
      EG_EGFaq_Q19: "من يتحمل تكلفة الرسوم؟",
      EG_EGFaq_A19: "المتقدم.",
      EG_EGFaq_Application_form: "نموذج الطلب",
      //// Application Form
      EG_AF_title: "نموذج طلب ضمان إيزي",
      EG_AF_description:
        "ملاحظة: يرجى قراءة قسم أسئلة الضمان الإيزي بعناية قبل ملء نموذج الطلب.",
      EG_AP_Applicant: "المتقدم:",
      EG_AP_SDate: "تاريخ البدء",
      EG_AP_SDate_Error: "يرجى إدخال تاريخ البدء.",
      EG_AP_EDate: "تاريخ الانتهاء",
      EG_AP_EDate_Error: "يرجى إدخال تاريخ الانتهاء.",
      EG_AP_WalletApp: "عنوان محفظة USDT في Easy Exchange Pro",
      EG_AP_WalletApp_Error: "يرجى إدخال عنوان محفظة USDT.",
      EG_AP_Amount: "مبلغ USDT",
      EG_AP_Amount_Error: "يرجى إدخال مبلغ USDT.",
      EG_AP_VPD: "عدد أيام فترة الصلاحية",
      EG_AP_VPD_Error: "يرجى إدخال عدد أيام فترة الصلاحية.",
      EG_AP_Select_Conditional: "شرطي",
      EG_AP_Select_Unconditional: "غير شرطي",
      EG_AP_Beneficiary: "المستفيد:",
      EG_AP_BenUID: "معرف المستفيد",
      EG_AP_BenUID_Error: "يرجى إدخال معرف المستفيد.",
      EG_AP_BenEmail: "عنوان بريد المستفيد الإلكتروني",
      EG_AP_BenEmail_Error: "يرجى إدخال عنوان بريد المستفيد الإلكتروني.",
      EG_AP_WalletBen: "عنوان محفظة USDT للمستفيد في Easy Exchange Pro",
      EG_AP_WalletBen_Error: "يرجى إدخال عنوان محفظة USDT للمستفيد.",
      // Fee
      Fee_title_1: "أوامر الصانع",
      Fee_description_1:
        "تقوم أوامر الصانع بإنشاء (جعل) السيولة في السوق عن طريق إدخالها في سجل الطلبات. بعبارة أخرى، فإن أوامر الصانع لا تتم ملئها عند وضعها، ولكنها تنتظر حتى يتم وضع أمر مستقبلي يتطابق معها. يمكن أن تكون أمر الصانع لشراء أو بيع. عندما يتطابق أمر موجود بالفعل في سجل الطلبات مع أمر مضاف حديثًا (المستقبل)، يتم تحصيل رسوم صانع الأمر في الصفقة. إذا لم يتطابق الأمر الجديد الذي تم إدخاله بشكل فوري مع أي أوامر موجودة بالفعل أو لم يتم استيفاؤه بالكامل من خلال الأوامر الموجودة، يتم وضع الأمر غير المستوفى في سجل الطلبات ويصبح أمرًا صانعًا لصفقة مستقبلية.",
      Fee_title_2: "أوامر المستهلك",
      Fee_description_2:
        "تقوم أوامر المستهلك بتقليل (أخذ) السيولة في السوق. تُنفذ أوامر المستهلك على الفور وتقوم بخفض حجم الطلبات في سجل الطلبات. يمكن أن تكون أمر المستهلك لشراء أو بيع. عندما يتم وضع أمر جديد ويتطابق مع أمر آخر موجود بالفعل في سجل الطلبات (الصانع)، يتم تحصيل رسوم المستهلك في الصفقة.",
      Fee_title_3: "رسوم المعاملات",
      Fee_subtitle_3_1: "البلوكشين:",
      Fee_description_3_1:
        "قد تنطوي شبكة البلوكشين الخاصة بالعملة المحددة على رسوم معينة عندما يقوم المستخدم بتحريك الرموز على البلوكشين من وإلى Easy Exchange Pro.",
      Fee_description_3_2:
        "لا يتم فرض رسوم على الودائع في Easy Exchange Pro. يرجى ملاحظة أن بعض الرموز أو العملات يتطلب منا نقل أموالك إلى عنوان آخر قبل أن يتم اعتمادها. هذا يعني أن شبكة العملة أو الرمز ستفرض عليك رسوم المعاملة العادية لنقل الأموال، والتي لا يمكن لـ Easy Exchange Pro تجنبها.",
      Fee_description_3_3:
        "تفرض Easy Exchange Pro رسومًا صغيرة على السحب للرمز أو العملة التي تحتوي على رسوم نقل الشبكة المدمجة. يمكن رؤية رسوم الشبكة عن طريق النقر على زر السحب في نافذة السحب لكل رمز أو عملة تحتوي على رسوم نقل الشبكة المدمجة. يمكن رؤية رسوم الشبكة عن طريق النقر على زر السحب في نافذة السحب لكل رمز أو عملة.",
      Fee_table_1_col_1: "المستوى",
      Fee_table_1_col_2: "حجم التداول خلال 30 يومًا (BTC)",
      Fee_table_1_col_3: "صانع الأمر",
      Fee_table_1_col_4: "المستهلك",
      Fee_table_2_col_1: "رمز العملة",
      Fee_table_2_col_2: "اسم العملة",
      // Gabur
      //// Top
      GBR_top_title_1: "EASY EXCHANGE PRO",
      GBR_top_title_3: "تطور الشمول المالي",
      //// Second
      GBR_Second_title_1: "عقد ذكي آمن",
      GBR_Second_title_2: "تدقيق رمز Gabur",
      GBR_Second_description:
        "يتألف العقد الذكي من تعليمات خاصة مخزنة في سلسلة الكتل. يمكن لهذا العقد تنفيذ عدة إجراءات وفقًا لسلسلة من المعلمات المبرمجة مسبقًا بطريقة آمنة تمامًا غير قابلة للتغيير وشفافة. لذلك، تم تدقيق عقد Gabur Tokens لضمان أعلى مستويات الأمان.",
      GBR_Second_Security_btn: "تدقيق الأمان",
      //// Description
      GBR_Description_title_1: "وصف Gabur Tokens",
      GBR_Description_list_1_1: "اسم الرمز الخاص بالرمز الأماني: GABUR",
      GBR_Description_list_1_2: "الرمز: GBR",
      GBR_Description_list_1_3: "إجمالي عرض Gabur Tokens: 550 مليون",
      GBR_Description_list_1_4: "لا يخضع Gabur Tokens للزيادة",
      GBR_Description_list_1_5:
        "سوف يعمل Gabur Tokens بشكل طبيعي على شبكة BEP20 وفقًا لمعيار BSC.",
      GBR_Description_list_1_6: "حرق الرموز: سيتم حرق 165 مليون Gabur Tokens",
      GBR_Description_title_2: "توزيع Gabur Tokens",
      GBR_Description_list_2_1: "البيع العام",
      GBR_Description_list_2_2: "احتياطي بطاقة Easy",
      GBR_Description_list_2_3: "فريق العمل",
      GBR_Description_list_2_4: "المستشارون",
      GBR_Description_list_2_5: "البيع الخاص",
      GBR_Description_list_2_6: "الهبة",
      //// Contract
      GBR_Contract_subtitle: "عنوان عقد Gabur Token",
      //// Added
      GBR_Added_subtitle: "خطة Gabur Token",
      GBR_Added_title: "ما هو القيمة المضافة لرمز Gabur؟",
      GBR_Added_list_1:
        "تطوير منصة Easy Exchange Pro وفقًا لالتزاماتنا في خريطة الطريق",
      GBR_Added_list_2:
        "خلق أفكار مبتكرة في تطوير بطاقة Easy بهدف جعلها ميزة هامة يمكن استخدامها في المعاملات",
      GBR_Added_list_3: "لا يخضع Gabur Tokens للزيادة",
      GBR_Added_list_4: "تطوير مبتكر يهدف إلى جعل الأصول الرقمية أكثر ملاءمة",
      GBR_Added_list_5: "حرق Gabur Tokens ربع سنويًا",
      GBR_Added_list_6: "فترة قفل الرموز",
      GBR_Added_list_7:
        "القدرة على استقبال المستخدمين الباحثين عن طريقة مستقرة وآمنة لتسوية المدفوعات في أكثر من 60 دولة",
      GBR_Added_list_8:
        "وجود فريق ذو خبرة في مجال العملات المشفرة والمجال المالي، بتواجد علاقات مالية دولية واسعة النطاق",
      GBR_Added_list_9: "سنقوم بإضافة منتجات جديدة وفريدة وفقًا لخريطة طريقنا",
      // Login
      Login_title: "مرحبًا بك في Easy Pro",
      Login_subtitle: "تسجيل الدخول للمتابعة",
      Login_btn_Loading: "تسجيل الدخول",
      Login_problem: "هل تواجه مشكلة في تسجيل الدخول؟",
      Login_click_here: "انقر هنا",
      Login_register: "انقر هنا للتسجيل",
      Login_protected: "تحمي هذه الصفحة بواسطة reCAPTCHA وGoogle",
      Login_privacy: "سياسة الخصوصية",
      Login_and: "و",
      Login_terms: "شروط الخدمة",
      Login_ResetModal_title: "يمكنك إعادة تعيين 2FA أو كلمة المرور",
      Login_ResetModal_Reset2FA_btn: "إعادة تعيين 2FA",
      Login_ResetModal_ForgetPassword: "نسيت كلمة المرور؟",
      Login_ResetPassword_title:
        "أدخل بريدك الإلكتروني لتلقي رابط إعادة تعيين كلمة المرور",
      Login_Reset2FA_title: "أدخل بريدك الإلكتروني لتلقي رابط إعادة تعيين 2FA",
      Login_OTP_title: "أدخل رمز OTP الذي تلقيته",
      //// Reset Password
      ResetPassword_title: "إعادة تعيين كلمة المرور الخاصة بك",
      ResetPassword_NewPassword_input: "كلمة المرور الجديدة",
      ResetPassword_ConfirmPassword_input: "تأكيد كلمة المرور الجديدة",
      // Privacy And Policy
      Privacy_1_title: "INTRODUCTION",
      Privacy_1_desciption:
        "Thanks indeed for visiting Easyexchangepro.com, as a digital asset trading website. The purpose of this Privacy Policy is to inform you (user, customer) with an understanding that how Easyexchangepro (or we, or our, or us or platform) collect personal information from our clients and prospective clients to provide them with our products and services and ensure that we can meet their needs when providing these products and services, as well as when providing them with any respective information. This privacy policy applies to the Site and all Services offered by Easyexchangepro.",
      Privacy_2_title: "PERSONALLY IDENTIFIABLE",
      Privacy_2_desciption:
        "We may collect personally identifiable information from Users in a variety of ways, including, but not limited to, when Users visit our Site, register on the Site, place an order, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Site anonymously. When interacting with us on the Site, Users can always refuse to supply personal data to us, except that it may prevent them from engaging in certain Site related activities or transactions. We may collect non-personally identifiable information about Users when they interact with our Site.",
      Privacy_3_title: "WHAT INFORMATION DO WE COLLECT?",
      Privacy_3_desciption_1:
        "Easyexchangepro collects information about you when you use our websites (including the Site) and other online products and services and throughout other interactions and services, you have with us. We will collect your personal data in accordance with the legislative purpose of the DPA. We may also collect and store certain information automatically when you visit the Site. Examples include the internet protocol (IP) address used to connect your computer or device to the internet, connection information such as browser type and version, your operating system and platform, a unique reference number linked to the data you enter on our system, log in details, the full URL clickstream to, through and from the Site (including date and time), cookie number and your activity on our Site, including the pages you visited, the searches you made and, if relevant, the products/services you purchased.",
      Privacy_3_desciption_2:
        "We may receive information about you from third parties if you use any websites or social media platforms operated by third parties (for example, Facebook, Instagram, Twitter, etc.) and, if such functionality is available, you have chosen to link your profile on the Site with your profile on those other websites or social media platforms.",
      Privacy_4_title: "INDIVIDUAL AND COMPANY WHICH WE MAY COLLECT INCLUDE:",
      Privacy_4_subtitle_1: "For individual:",
      Privacy_4_list_1_1: "• Email address",
      Privacy_4_list_1_2: "• Mobile phone number",
      Privacy_4_list_1_3:
        "• Full legal name (including former name, and names in local language)",
      Privacy_4_list_1_4: "• Nationality",
      Privacy_4_list_1_5:
        "• Passport number, or any government-issued ID number",
      Privacy_4_list_1_6: "• Date of birth",
      Privacy_4_list_1_7:
        "• Proof of identity (e.g., passport, driver’s license, or government-issued ID)",
      Privacy_4_list_1_8: "• Residential address",
      Privacy_4_list_1_9: "• Proof of residency",
      Privacy_4_list_1_10:
        "• Additional Personal Data or documentation at the discretion of our Compliance Team",
      Privacy_4_subtitle_2: "For companies:",
      Privacy_4_list_2_1:
        "• Company legal name (including the legal name in local language)",
      Privacy_4_list_2_2: "• Incorporation/registration Information",
      Privacy_4_list_2_3:
        "• The full legal name of all beneficial owners, directors, and legal representatives",
      Privacy_4_list_2_4:
        "• Address (principal place of business and/or other physical locations)",
      Privacy_4_list_2_5: "• Proof of legal existence",
      Privacy_4_list_2_6: "• Description of the business",
      Privacy_4_list_2_7:
        "• Percentage of ownership for Individual/company owners",
      Privacy_4_list_2_8:
        "• Contact information of owners, principals, and executive management (as applicable)",
      Privacy_4_list_2_9:
        "• Proof of identity (e.g., passport, driver’s license, or government-issued ID) for the significant individual beneficial owner of the institutional customer entity",
      Privacy_4_list_2_10:
        "• Personal Data for each entity’s significant beneficial owner of the institutional customer entity (see the “Individual Customer” section above for details on what Personal Data we collect for individuals)",
      Privacy_4_list_2_11: "• Source of wealth",
      Privacy_4_list_2_12:
        "• Amount of bitcoin or other digital assets projected to be injected",
      Privacy_5_title: "COOKIES USAGE",
      Privacy_5_desciption:
        "When you access our Sites and Services, we or companies we work with may place cookies on your computer or another device. Of course, you can block or deactivate cookies in your browser settings. But these technologies help us better understand user behavior and inform us about which parts of our websites people have visited. We use first-party and third-party cookies to recognize you as an Easyexchangepro user, customize Coinbase Services, content, and advertising, measure promotional effectiveness, and collect information about your computer or another access device to mitigate risk, helping prevent fraud, and promote trust and safety. We may place cookies from third-party service providers who may use information about your visits to other websites to target advertisements for products and services available from Easyexchangepro. We do not control the types of information collected and stored by these third-party cookies. You should check the third-party's website for more information on how they use cookies.",
      Privacy_6_title: "LAWFUL BASIS FOR PROCESSING YOUR PERSONAL INFORMATION",
      Privacy_6_desciption_1:
        "We will process your personal information on the following bases and for the following purposes:",
      Privacy_6_desciption_2:
        "carrying out your instructions or responding to any inquiry given by (or purported to be given by) you or on your behalf including responding to your inquiries and complaints, or responding to or dealing with your interactions with us; registering you as a customer of Easyexchangepro and/or to deal with, process and/or administer the account that you may open with us, whether a membership account or otherwise, including to facilitate your transactions or activities on the Site or your transactions or activities with us; administering, facilitating, processing and/or dealing with your relationship with us, any transactions or activities carried out by you on the Site or with us. This includes processing your application, orders, and payment transactions; implementing transactions and the supply of products and/or services to you that you have requested; administering, facilitating, processing, and/or dealing in any matters relating to your use or access of the Site, including identifying you for login to the Site, our portals and other online services provided by or on behalf of us; monitoring, processing and/or tracking your use of the Site in order to provide you with a seamless experience, facilitating or administering your use of the Site, and/or to assist us in improving your experience in using the Site; assessing and processing your request for the purchase of and/or subscription to our products and/or services. contacting you or communicating with you via phone/voice call, text message and/or fax message, email and/or postal mail for the purposes of administering and/or managing your use of the Site, your membership and/or account with us, your relationship with us or any transactions made by you with us.",
      Privacy_7_title: "HOW WE USE YOUR PERSONAL DATA",
      Privacy_7_desciption_1:
        "Easyexchangepro uses Personal Data to communicate with you and to administer, deliver, improve, and personalize the Service. Easyexchangepro might also generate generic data out of any Personal Data we collect and use it for our own purposes. We may also use such data to communicate with you in relation to other products or services offered by Easyexchangepro and/or its partners. We do not share your Personal Data with third parties (other than partners in connection with their services to Easyexchangepro) except where you have given your consent and further detailed below.",
      Privacy_7_desciption_2:
        "We may share your Personal Data with third parties:",
      Privacy_7_desciption_3:
        "If we think that sharing it is necessary to enforce the Terms of Service. To comply with government agencies, including regulators, law enforcement, and/or justice departments. To third parties who provide services to Easyexchangepro (such as administration or technical services). In connection with the sale or transfer of our business or any part thereof.",
      Privacy_7_desciption_4:
        "Additionally, we have implemented international standards to prevent money laundering, terrorist financing and will implement final Digital Asset rules and regulations when effective, which will likely require us to undertake due diligence on our customers. This may include the use of third-party data and service providers which we will cross-reference with your personal information.",
      Privacy_8_title: "STORING YOUR PERSONAL DATA",
      Privacy_8_desciption:
        "The data that we collect from you may be transferred to and stored at, a destination outside of the U.A.E. It may also be processed by staff operating outside of U.A.E who work for us or for one of our suppliers. By submitting your personal data, you agree to this transfer, storing, or processing, except for customers located in the EEA, as detailed above. All information you provide to us is stored on our and/or third-party cloud servers",
      Privacy_9_title: "MARKETING COMMUNICATIONS",
      Privacy_9_desciption:
        "We may use your personal information to send you marketing communications by email or other agreed forms (including social media campaigns), to ensure you are always kept up to date with our latest products and services. If we send you marketing communications, we will do so based on your consent and registered marketing preferences. Users can opt out of these marketing communications at any moment. If you do not want to receive these communications, please send an email to support@easyexchangepro.com",
      Privacy_10_title: "DATA SECURITY",
      Privacy_10_desciption:
        "We endeavor to protect Easyexchangepro and you from unauthorized access, alteration, disclosure, or destruction of Personal Data we collect and store. We take various measures to ensure information security, including encryption of the Easyexchangepro communications with SSL; required two-factor authentication for all sessions; periodic review of our Personal Data collection, storage, and processing practices; and restricted access to your Personal Data on a need-to-know basis for our employees and vendors who are subject to strict contractual confidentiality obligations.",
      Privacy_11_title:
        "TRANSFER OF PERSONAL INFORMATION OUTSIDE OF THE EEA and UK and YOUR COUNTRY",
      Privacy_11_desciption:
        "We may transfer your personal information outside the EEA and UK to other Company subsidiaries, service providers, and business partners (i.e., Data Processors) who are engaged on our behalf. To the extent that we transfer your personal information outside of the EEA and UK, we will ensure that the transfer is lawful and that Data Processors in third countries are obliged to comply with the European Union (EU) General Data Protection Act 2016 and the UK Data Protection Act 2018. Also, by using our products and services, you consent to your Personal Data being transferred to other countries, including countries that have differing levels of privacy and data protection laws than your country. In all such transfers, we will protect your personal information as described in this Privacy Notice and ensure that appropriate information sharing contractual agreements are in place.",
      Privacy_12_title: "COMPLIANT PROCESS",
      Privacy_12_desciption:
        "If you have any complaint or grievance regarding how we are handling your personal data or about how we are complying with the CPT (when in force), we welcome you to contact us with your complaint or grievance and should be addressed to CPT@Easyexchangepro.com Where you are sending an email in which you are submitting a complaint, your indication at the subject header that it is a CPT complaint would assist us in attending to your complaint speedily by passing it on to the relevant staff in our organization to handle. We will certainly strive to deal with any complaint or grievance that you may have speedily and fairly.",
      Privacy_13_title: "YOUR ACCEPTANCE",
      Privacy_13_desciption:
        "By using Easyexchangepro Site, you signify your acceptance of this policy and Terms of Use.",
      Privacy_14_title: "CHANGES TO THIS PRIVACY POLICY",
      Privacy_14_desciption:
        "Any changes we may make to our Privacy Policy in the future will be posted on this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.",
      // Product Convert
      Convert_title: "تحويل",
      Convert_for_each: "لكل",
      Convert_receive: "ستتلقى",
      Convert_calc: "حساب التحويل",
      // Product Market Tabs
      Market_tab_Spot: "سبوت",
      Market_tab_Favorite: "المفضلة",
      // Product OTC
      OTC_title: "مكتب Easy Pro OTC",
      OTC_description:
        "يقرر المتداولون الذين يرغبون في تبادل العملات المشفرة، وخاصة بحجوم كبيرة، استخدام التداول خارج البورصة (OTC). سيوفر مكتب Easy Pro OTC لك خدمات التنفيذ والتسوية بطريقة سرية وآمنة ومنافسة للغاية.",
      OTC_Feature_1:
        "نقوم بإجراء صفقات OTC بطريقة سرية دون تأثير كبير على الأسواق.",
      OTC_Feature_2:
        "مكتب التداول لدينا يحترم الخصوصية ويتبع جميع بروتوكولات الأمان على Easy Pro.",
      OTC_Feature_3:
        "تتم التسوية داخل نظام Easy Pro وعادة ما تكتمل في غضون بضع دقائق.",
      OTC_Feature_4: "سيتم إكمال التسوية في غضون بضع دقائق",
      OTC_Feature_5: "لا يوجد جهة ثالثة في مكتبنا",
      OTC_Feature_6:
        "تتم المعاملة في حساب Easy Pro ولا حاجة لإنشاء محفظة جديدة.",
      OTC_Feature_7:
        "سيتم نقل العملات/الرموز التي تتم المتاجرة بها ببساطة داخل وخارج حسابك يدويًا من قبل فريقنا.",
      OTC_Feature_8:
        'يمكن العثور على تفاصيل التداول في قسم "تاريخ التوزيع" في حسابك فقط.',
      OTC_Support_title: "دعم التداول خارج البورصة",
      OTC_Support_description:
        "يقدم مكتب التداول خارج البورصة تغطية عالمية على مدار الساعة طوال أيام الأسبوع، باستثناء العطلات الرئيسية OTC@Easyexchangepro.com",
      OTC_list_1: "يرجى ملء نموذج التداول خارج البورصة وإرساله لنا",
      OTC_list_2: "فريقنا متاح لتقديم عروض أسعار وتنفيذ وتسوية",
      OTC_list_3:
        "بمجرد قبول عرضنا، يتم تأكيد التداول وسيقوم فريقنا بإرسال تفاصيل وتعليمات التداول عبر البريد الإلكتروني.",
      OTC_list_4:
        "عندما نتلقى نصف صفقة التداول الخاصة بك، سيتابع مكتب التداول خارج البورصة إجراء تسوية نصفنا على محفظتك أو حسابك المصرفي",
      // Product Trade
      Trade_KYC_Error: "يرجى تحميل معرفك الشخصي",
      Trade_Trading_password_Error: "يرجى تمكين كلمة مرور التداول",
      Trade_Trading_password_title: "أدخل كلمة مرور التداول الخاصة بك",
      Trade_Trading_password_input: "كلمة مرور التداول",
      Trade_Trading_Password_btn: "أدخل كلمة مرور التداول",
      Trade_goto_security: "الانتقال إلى الأمان",
      Trade_FAV: "المفضلة",
      Trade_Open_Orders: "الطلبات المفتوحة",
      Trade_Order_history: "تاريخ الطلب",
      Trade_Trade_history: "تاريخ التداول",
      Trade_Date_Time: "التاريخ والوقت",
      Trade_Type: "النوع",
      Trade_Action: "الإجراء",
      Trade_Login_Register: "تسجيل الدخول/التسجيل",
      Trade_Password_Error: "يرجى إدخال كلمة المرور الخاصة بك.",
      Trade_Balance_Error: "الرصيد غير كافٍ، لديك",
      Trade_Min_Error: "يجب أن يكون الطلب أعلى من 20 USDT",
      Trade_approximately: "ستتلقى تقريبًا",
      Trade_Market: "السوق",
      Trade_Order_List: "قائمة الطلبات",
      Trade_Order_History: "سجل الطلبات",
      Trade_Pairs: "الأزواج",
      Trade_Open_Chart: "فتح الرسم البياني",
      // Profile
      Profile_profile: "الملف الشخصي",
      Profile_security: "الأمان",
      Profile_history: "التاريخ",
      Profile_Security_log_history: "سجل تاريخ الأمان",
      Profile_Login_history: "سجل تاريخ تسجيل الدخول",
      Profile_User_info_title: "معلومات المستخدم",
      Profile_User_info_description:
        "معلومات حسابك الفريدة، التي يجب أن تحافظ عليها بشكل آمن.",
      Profile_UID: "معرف المستخدم",
      Profile_Sec_info_title: "معلومات الأمان",
      Profile_Activated: "مفعل",
      Profile_Disabled: "معطل",
      Profile_Trading_Password: "كلمة مرور التداول",
      Profile_Account_status_title: "حالة الحساب",
      Profile_KYC: "KYC",
      Profile_Sec_level: "مستوى الأمان",
      Profile_Airdrop_point: "نقاط الهبة",
      Profile_Referrals_title: "الإحالات",
      Profile_Num_Referrals: "عدد الإحالات",
      Profile_Referral_ID: "معرف الإحالة",
      Profile_Referral_List_btn: "قائمة الإحالات",
      Profile_Commissions_btn: "العمولات",
      Profile_User: "المستخدم",
      Profile_KYC_Verified: "تم التحقق",
      Profile_KYC_Pending: "قيد الانتظار",
      Profile_KYC_Rejected: "مرفوض",
      Profile_KYC_NotUploaded: "لم يتم التحميل",
      Profile_Sec_lvl_Medium: "متوسط",
      Profile_Sec_lvl_VeryHigh: "عالي جدًا",
      Profile_Account_Password_title: "كلمة مرور الحساب",
      Profile_Account_Password_description:
        "يمكنك تغيير كلمة المرور الخاصة بك هنا.",
      Profile_Change_Password_btn: "تغيير كلمة المرور",
      Profile_Change_your_password_title: "تغيير كلمة المرور الخاصة بك",
      Profile_Current_Password: "كلمة المرور الحالية",
      Profile_New_Password: "كلمة المرور الجديدة",
      Profile_Confirm_New_Password: "تأكيد كلمة المرور الجديدة",
      Profile_Sec_Authenticaton_title: "المصادقة بعاملين",
      Profile_Sec_Authenticaton_description:
        "يمكنك تفعيل/تعطيل عاملي التوثيق الثنائي هنا.",
      Profile_Active_2FA: "تفعيل عاملي التوثيق الثنائي",
      Profile_Activating_2FA: "تفعيل 2FA",
      Profile_Disable: "تعطيل",
      Profile_Disable_2FA: "تعطيل 2FA",
      Profile_2FA_Code: "رمز 2FA",
      Profile_Active: "فعال",
      Profile_Trading_Password_description:
        "يمكنك تغيير كلمة مرور التداول الخاصة بك هنا.",
      Profile_Change_trading_password: "تغيير كلمة مرور التداول",
      Profile_Change_your_Trading_password_title:
        "تغيير كلمة مرور التداول الخاصة بك",
      Profile_Change_your_Trading_password_description:
        "هل ترغب في تغيير كلمة مرور التداول الخاصة بك؟",
      Profile_forgot_code: "نسيت الرمز",
      Profile_KYC_title: "KYC للحساب الشخصي",
      Profile_KYC_select_doc: "حدد نوع الوثيقة الخاصة بك",
      Profile_KYC_select_doc_ID: "بطاقة الهوية",
      Profile_KYC_select_doc_pass: "جواز السفر",
      Profile_KYC_select_doc_Drive: "رخصة القيادة مع رقم الهوية",
      Profile_KYC_Upload_title: "يرجى تحميل وثائقك:",
      Profile_KYC_Front: "الجزء الأمامي من الوثيقة",
      Profile_KYC_Back: "الجزء الخلفي من الوثيقة",
      Profile_KYC_Selfie: "صورة شخصية مع الوثيقة في يدك",
      Profile_KYC_Approved: "تم الموافقة على KYC الخاص بك بالفعل.",
      Profile_KYC_Pending: "KYC الخاص بك في انتظار الموافقة.",
      Profile_Currency: "العملة",
      Profile_Type: "النوع",
      Profile_Login: "تسجيل الدخول",
      Profile_Region: "المنطقة",
      Profile_Login_device: "جهاز تسجيل الدخول",
      // Register
      Reg_verify_email: "التحقق من البريد الإلكتروني",
      Reg_enter_otp: "إدخال رمز OTP",
      Reg_Confirm_Password: "تأكيد كلمة المرور",
      Reg_Referral_Code: "رمز الإحالة (اختياري)",
      // Terms Of Use
      Term_1_title: "1-Welcome to Easy Pro",
      Term_1_description_1:
        "Thanks indeed for visiting (Easyexchangepro / we, hereinafter referred to as the Easy pro) By visiting, accessing, or using easyexchangepro.com and associated application program interface or mobile applications (platforms), You (“User” or “You”) consent to the Terms of Service (the “Terms”), so please read them carefully.",
      Term_1_description_2:
        "The Terms constitute the agreement and understanding regarding the use of any or all the Services, and any manner of accessing them, between You and the following service provider (“we,” “us” or “our” platform) Easyexchangepro.",
      Term_2_title: "2-Key Definitions",
      Term_2_description_1:
        "Capitalized terms not otherwise defined in these Terms will have the following meaning:",
      Term_2_description_2:
        "“External Account” means any Digital Token Account: from which you may transfer funds into your Easy Pro  Account, or to which you may transfer funds from your Easy Pro Account and which has been approved by Easy pro for the foregoing purposes.",
      Term_2_description_3: "“Funds” means Digital Tokens.",
      Term_2_description_4: "“Fiat Currency” means any national currency.",
      Term_2_description_5:
        "“Easy Pro Account” means a user account accessible via the Services where Funds may be stored by Easy pro on behalf of a user.",
      Term_2_description_6:
        "“Easy Pro Help Center” means Easy pro’s customer service center with address at Support@easyexchagepro.com -us where you could get online support from Easy pro.",
      Term_2_description_7:
        "“Digital Tokens” means Bitcoins, Ripples, and other digital mediums of exchange that may be purchased, sold, or traded via the Services.",
      Term_2_description_8:
        "“Digital Token Account” means any Digital Token address or account owned or operated by you that is maintained outside of the Services, and is not owned, controlled, or operated by Easyexchangepro.",
      Term_3_title: "3- Accept terms",
      Term_3_description_1:
        "You should read the Terms, and any document referred to in them very carefully. If there is anything that You do not understand in the Terms and any document referred to herein, you should discuss this matter with us and seek the necessary clarification.",
      Term_3_description_2:
        "You are at least 18 years of age and have the full capacity to accept these Terms and enter a transaction involving digital assets. You have not been deprived of the right to use our service and have the full capacity for legal action. If you do not meet the above condition, please do not register our Site or Platform, otherwise, the platforms may suspend or terminate your account at any time.",
      Term_3_description_3:
        "You’re entering and performing these Terms are not prohibited by the laws and regulations of the country or region to which you belong, reside, pay tax or carry out business activities or other business. If you do not meet the above conditions, you should immediately terminate the registration or stop using our Platforms services.",
      Term_3_description_4:
        "Our Platform has the right to modify these Terms from time to time or to formulate and modify various specific rules according to these Terms and publish them in the relevant system sections of Platforms without separately notifying you.",
      Term_3_description_5:
        "You should pay attention to the changes in these Terms and the specific rules from time to time. If you continue to use the Service after the changes in the content of these Terms and the specific rules, you are deemed to have fully read, understood, and accepted the amended Terms and the specific rules and to use the services of our Platforms in accordance therewith.",
      Term_3_description_6:
        "You also agree that Easy Pro may, by giving notice, at its sole discretion immediately terminate your access to our Platform and to your account. You agree and acknowledge our right to limit, suspend or terminate the service and your user account, prohibit your access to our Platform and their content, services, and tools, delay or remove hosted content, and take technical and legal steps to keep you off our Platform or any further steps to recover any loss or damages caused if we determine at our sole discretion that you are or may be in breach of any laws, regulations, the rights of third parties, or any of these Terms or Platform’s policies. Easy Pro shall not be liable for any loss or damage caused to you or any third party because of exercising our rights under this clause.",
      Term_4_title: "4- Our Trading Services",
      Term_4_description:
        "Easy Pro provides an online platform with a simple and convenient way to trade one type of digital asset or another type of digital asset. Easy Pro provides you with a platform that matches your orders with open orders from other users of our Services.",
      Term_4_subheader_1: "Spot.",
      Term_4_subtitle_1: "Market Order:",
      Term_4_description_1:
        "A Market Order is placed to buy or sell a Specified Quantity or a Specific Gross Amount of Targeted tokens at the best available Latest Market Price.",
      Term_4_subtitle_2: "Limit Order:",
      Term_4_description_2:
        "A Limit Order is placed to buy or sell a Specific Quantity of the Targeted Token at a Specific Price and shall be filled at such Specific Quantity and Specific Price.",
      Term_4_subtitle_3: "Stop Market Order:",
      Term_4_description_3:
        "A Stop Market Order is placed to buy a Specific Quantity of Targeted Token or to buy a Specific Gross Amount of Targeted Token at the Latest Market Price when the upper limit of the Preset Stop Price is triggered. This means that when the Latest Market Price reach or beyond the upper limit of the Preset Stop Price, this order will be executed and filled at the Latest Market Price which may end up higher than the upper limit of the Preset Stop Price.",
      Term_4_subtitle_4: "Stop Limit Order:",
      Term_4_description_4:
        "A Stop Limit Order is placed to sell a Specific Quantity of Targeted Token at the Specific Price when the inferior limit of Preset Stop Price is triggered. This means that when the Latest Market Price reaches or is below the inferior limit of the Preset Stop Price, this order will be executed and filled at the Specific Quantity as well as the Specific Price.",
      Term_4_subheader_2: "Easy card",
      Term_4_description_5:
        "Refers to transactions in which Gabur tokens are exchanged for fiat currency and deposited in the account of the user.",
      Term_5_title: "5- Scope of Service and Your Rights and Obligations",
      Term_5_description_1:
        "Users shall not register multiple accounts for any purpose. Users may not use another User’s accounts. Users are prohibited in any form from utilizing the Site to engage in illegal activities. Without the authorization or permission of Easy Pro, the User shall not use any of the commercial activities in the name of this Site or in any form of the Site as a place, platform, or medium for engaging in commercial activities.",
      Term_5_description_2:
        "Users shall comply with all laws and regulations and bear the responsibility and legal consequences of any of their own actions involving the Site and Services.",
      Term_5_description_3:
        "Users have the right to view the information regarding their accounts on our Platform and have the right to operate the functions provided by our Platform. The user understands and agrees that our Platform can adjust the service content, service type, and service form on the Platform at any time according to the actual situation at our sole discretion. Our Platform is not responsible for any negative impact or loss caused to you or any third party due to Platform’s adjustments.",
      Term_5_description_4:
        "Depending on your country of residence, you may not be able to use all the functions of the Site or the Platform. Without prejudice to our right of suspending certain services to users from a specific country, it is your responsibility to ensure that in accessing our Site, Platform, and services, you are complying with the laws and regulations in your country of residence and/or the country from which you access this Site, the Platform, and our services. you are responsible for any disputes or losses caused by your failure to promptly modify or confirm the status of the transaction or failure to submit the relevant application. Our Platforms do not assume any responsibility.",
      Term_5_description_5:
        "Engaging in trades in cryptographic tokens may be highly risky. Please do not use Easy Pro’s services for trading in Digital assets if you do not understand these risks.",
      Term_6_title: "6- Registration Process",
      Term_6_description_1:
        "You agree to provide a valid email address, a mobile phone number, and other information in accordance with the requirements on the user registration page of this platform. You can use the email address, mobile phone number or any other manner permitted to log in to this platform. Where it is necessary and in accordance with the requirements of applicable laws and regulations of relevant jurisdictions, you shall provide your real name, identity card, and other information required by applicable laws, regulations, the Terms of Privacy, and anti-money-laundering terms, and constantly update your registration data so that they will be timely, detailed, and accurate as is required. All the original typed data will be referenced as registration information. You shall be responsible for the authenticity, integrity, and accuracy of such information and bear any direct or indirect loss and adverse consequences arising out of it.",
      Term_6_description_2:
        "If any of the applicable laws, regulations, rules, orders, and other regulatory documents of the sovereign country or region in which you are based requires that mobile phone accounts must be based on real names, you hereby confirm that the mobile phone number you provide for registration purposes has gone through the real-name registration procedure. If you cannot provide such a mobile phone number as is required, any direct or indirect losses and adverse consequences arising therefrom and affecting you shall be borne by you.",
      Term_6_description_3:
        "After you provide the required registration information in a legal, complete, and valid manner and such information passes relevant verification, you shall have the right to obtain an account and a password of Easy exchange pro. Upon obtaining such an account and password, your registration shall be deemed as successful, and you can log into the platform as a member thereof. You agree to receive emails and/or short messages sent by the platform related to the management and operation thereof.",
      Term_6_description_4:
        "Particularly during periods of high volume, illiquidity, fast movement, or volatility in the marketplace for any, or one or more Digital Tokens, the actual market rate at which a trade is executed may be different from the prevailing rate indicated via the Services at the time of your order or trade. You understand that we are not liable for any such price fluctuations. In the event of a market disruption or Force Majeure event, Easy exchange pro may do one or more of the following: suspend access to the Services; or prevent you from completing any actions via the Services, including closing any open positions. Following any such event, when trading resumes, you acknowledge that prevailing market rates may differ significantly from the rates available prior to such an event.",
      Term_6_description_5:
        "You hereby authorize Easy exchange pro to attempt and/or perform any such correction, reversal, cancellation, voiding, or amendment described in this section. Easy exchange pro. provides no guarantee or warranty that any such attempt will be successful and will have no responsibility or liability for the Manifest Error or the making or failure of any correction attempt, except if caused by the gross negligence, willful default, or fraud of Easy exchange pro. There is a risk that you may be prejudiced by any action or omission of Easy exchange pro in this regard, and you accept that you have no recourse whatsoever against Easy exchange pro.",
      Term_7_title: "7- Fees",
      Term_7_description_1:
        "You agree to pay for transactions through our Fees Structure service, which may change from time to time. Changes to the Fee Structure are effective as of the effective date indicated in the posting of the revised Fee Structure to the Services and will apply prospectively to any trades that take place following the effective date of such revised Fee Structure.",
      Term_7_description_2:
        "In addition to the costs, your external account may incur costs in connection with your use of your designated external account through the Services. Costs incurred by your external account provider are not reflected on the Transactions page, which contains information about applicable costs. You are solely responsible for paying any fees imposed by an External Account provider.",
      Term_7_description_3:
        "You authorize us, or our designated payment processor, to charge or deduct your Easy Pro Account Funds for any applicable Fees owed in connection with trades you complete via the Services.",
      Term_8_title: "8- Communication",
      Term_8_description_1:
        "You agree and consent to receive electronically all communications, agreements, documents, receipts, notices, and disclosures (collectively, “Communications”) that Easy Pro provides in connection with your Easy Pro Account and/or use of the Easy Pro Services.",
      Term_8_description_2:
        "Updating Contact Information. It is your responsibility to keep your email address on file with Easy Pro up to date so that Easy exchange pro can communicate with you electronically.",
      Term_9_title: "9- Intellectual Property",
      Term_9_description:
        "Unless otherwise indicated by us, all copyright and other intellectual property rights in all content and other materials contained on our website/platform or provided in connection with the Services, including, without limitation, the Easy Pro logo and all designs, text, graphics, pictures, information, data, software, sound files, other files and the selection and arrangement thereof are the proprietary property of Easy Pro or our licensors or suppliers and are protected by international copyright laws and other intellectual property rights laws.",
      Term_10_title: "10- Unclaimed or Disputed Property",
      Term_10_description_1:
        "If for any reason Easy Pro is holding Funds in your Easy  Account, such Funds remain unclaimed, and Easy Pro is unable to return your Funds to your designated External Account after a period of three years after your last login to Easy Pro or any specified period notified by Easy Pro, and Easy Pro determines in good faith that it is not able to trace you, you agree that all such Funds shall be deemed to have been abandoned by you in favor of Easy Pro and may be appropriated by Easy Pro to and for itself, and you thereafter shall have no right to claim such Funds.",
      Term_10_description_2:
        "If Easy Pro receives notice that any Digital Tokens held in your Easy Pro Account are alleged to have been stolen or otherwise are not lawfully possessed by you, Easy Pro may, but has no obligation to, place an administrative hold on or freeze the affected Digital Tokens or your Easy Pro Account. If Easy Pro does place an administrative hold on or freeze some or all your Digital Tokens, Easy Pro may continue such hold until such time as the dispute has been resolved and evidence of the resolution acceptable to Easy Pro has been provided to Easy Pro in a form acceptable to Easy Pro. Easy Pro will not involve itself in any such dispute or the resolution of the dispute. You agree that Easy Pro will have no liability or responsibility for any losses, damages, or prejudice arising from or in connection with such hold or freeze, or for your inability to withdraw Digital Tokens or execute trades during the period of any such hold or freeze.",
      Term_11_title: "11- Complains about your local laws",
      Term_11_description:
        "It is your sole responsibility to determine whether, and to what extent, any taxes apply to any transactions You conduct through the Services, and to withhold, collect, report, and remit the correct amounts of taxes to the appropriate tax authorities. When using our Services, you acknowledge that your actions are in a legal and proper manner and your financial sources are not from illegal activities. We may discretionarily or in coordination with local law enforcement authorities seize, restrict or close out your account, fiat, and/or cryptocurrencies.",
      Term_12_title: "12- Suspension; Termination",
      Term_12_description_1:
        "In the event of any Force Majeure Event breach of these. Terms or Conditions, or any other event where the provision of services to Easy pro is commercially unreasonable, we may, at our sole discretion and without liability to you, with or without prior notice, grant you access to all or part of Suspend it. We may also immediately, without notice, terminate your access to the Services at our sole discretion and delete or deactivate your Easy exchange pro account and all related information and files on this account without liability to you, including in cases where you are violating one of these conditions. In the event of discontinuation of all Services or termination of your access to the Services or deletion or deactivation of your Easy Pro Account: all amounts payable by you to Easy Pro will immediately become due. the platform can cancel any open orders or other transaction requests that are pending at the time of discontinuation or termination or deletion or deactivation. the platform will, where possible, return any Funds stored in your Easy Exchange pro Account not otherwise owed to Easy Pro and/or will use commercially reasonable efforts to provide you with a period 4 months to transfer affected Digital Tokens from your Easy Pro Account, unless prohibited by applicable laws or regulations or by order of law enforcement or governmental authority, or Easy Pro believes you have committed fraud, negligence or other misconduct; and/or may take such other steps as Easy Pro deems necessary or desirable to protect its own interests. We are not and shall not be responsible or liable for any loss or damages incurred because of or arising from any actions taken under this section.",
      Term_12_description_2:
        "Also, if the user has not made any deposits, transactions, or logins for a period of three months by any reason, we may delete or deactivate the user's account, without notifying the User and without assuming any responsibility",
      Term_13_title: "13- Descramble of liability",
      Term_13_subtitle_1: "13.1 Network Service Provider:",
      Term_13_description_1:
        'as a third-party platform for "network service provider", does not guarantee the information and services provided on this Site can fully meet the needs of users. We shall not be liable for errors, insults, defamation, nonfeasance, obscenity, pornography, or blasphemy that may occur during the process of acceptance of the Parties’ web service.',
      Term_13_subtitle_2: "13. 2 Service Interruption:",
      Term_13_description_2:
        "Based on the special nature of the Internet, we do not guarantee that the service will not be interrupted; the timeliness and security of the service are also not guaranteed.",
      Term_13_subtitle_3: "13.3 Safety of the Network:",
      Term_13_description_3:
        "We try to provide a safe network environment to the users; however, we do not guarantee that the Site or its servers are free of viruses or other potentially harmful factors; therefore, the user should use the industry’s recognized software to check and kill any virus in the files downloaded from the Site.",
      Term_13_subtitle_4: "13. 4 User Information:",
      Term_13_description_4:
        "we are not responsible for the failure of preservation, modification, deletion, or storage of the information provided by the user. Nor will we be liable for the typographical errors, negligence, etc. We have the right but no obligation to improve or correct any omission, error of any part of this Site.",
      Term_13_subtitle_5:
        "13. 5 Accuracy, Completeness, and Reliability of the Site:",
      Term_13_description_5:
        "Unless we have expressly agreed in writing, we shall not guarantee the accuracy, completeness, reliability of any content, such as but not limited to, advertising from the platform in any manner (including but not limited to, containing, connecting, by way of, or downloading) from the Site; we are not responsible for any products, services, information or materials purchased or obtained by the user according to the content information on this website. The user bears the risk of using the content of this website.",
      Term_13_subtitle_6: "13. 6 User Opinion:",
      Term_13_description_6:
        "The user comments published by users of the Site, are only on behalf of the user’s personal point of view. It does not mean that this Site agrees with their views or confirms their description. This Site does not bear any legal responsibility caused by any user comments.",
      Term_13_subtitle_7: "13. 7 Announcements:",
      Term_13_description_7:
        "Regarding notices issued to the users, we will deliver these notices through a formal page announcement, station letter, e-mail, customer service phone call, SMS, or regular mail delivery. We do not bear any legal responsibility for any winning, discount activities or information that are delivered by other channels.",
      Term_13_subtitle_8:
        "13. 8 Charges, Commissions, Transactions, and Other Fees:",
      Term_13_description_8:
        "we have the right to adjust the recharge, commission, transaction, and other fees according to market conditions, and terminate any promotional efforts early.",
      Term_14_title: "14- Jurisdiction",
      Term_14_description:
        "These Terms shall be governed by and construed in accordance with the laws of Dubai. The courts of Dubai shall have exclusive jurisdiction to settle any dispute arising from or connected with these Terms (including a dispute relating to the existence, validity, or termination of these Terms or the consequences of their nullity or any non-contractual obligation arising out of or in connection with these Terms).",
      Term_15_title: "15. Miscellaneous",
      Term_15_description_1:
        "These Terms set forth the complete understanding of the parties with respect to the subject matter hereof and supersedes all prior understandings and communications relating thereto our Platforms reserve the right of final interpretation of these Terms within the scope permitted by law. These Terms and related pages of our Platforms may refer to each other. If there is any conflict, these Terms shall prevail. In addition, if some of the provisions in these Terms are deemed invalid or unenforceable, the other provisions in these Terms will remain in effect. In the event of any conflict between these Terms and any other agreement, you may have with Easy Exchange pro the terms of that other agreement will prevail only if these Terms are specifically identified and declared to be overridden by such other agreement.",
      Term_15_description_2:
        "Any failure or delay by Easy Exchange Pro to enforce any of these Terms or to exercise any right hereunder shall not be construed as a waiver to any extent of our rights.",
      Term_16_title: "16- Limitation of Liability",
      Term_16_description:
        "Except as otherwise required by law, in no event shall Easy exchange pro, our directors, officers, employees, agents, Brokers or contractors be liable for any special, indirect, or consequential damages, or any other damages of any kind, including but not limited to loss of use, loss of profits or loss of data, whether in an action in contract, tort (including but not limited to negligence) or otherwise, arising out of or in any way connected with the use of or inability to use our Services or the Easy exchange pro Materials, including without limitation any damages caused by or resulting from reliance by any user on any information obtained from Easy exchange pro , or that result from mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses, delays in operation or transmission or any failure of performance, whether or not resulting from a force majeure event, communications failure, theft of, destruction of or unauthorized access to Easy exchange pro’s records, programs or services. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the limitations of this section may not apply to you.",
      Term_17_title: "17- Miscellany",
      Term_17_subtitle_1: "17 -1 Agency",
      Term_17_description_1:
        "Nothing in this Agreement shall be deemed to have created, implied, or otherwise treated us as your agent, trustee, or another representative unless it is provided otherwise in this Agreement.",
      Term_17_subtitle_2: "17- 2 Waiver",
      Term_17_description_2:
        "Our or your waiver of the right to hold the other party liable for breaches of agreement or any other liability as is agreed upon in this Agreement shall not be construed or deemed as a waiver of the right to hold the other party for other breaches of contract; a failure to exercise any right or remedy shall not be construed in any way as a waiver of such right or remedy.",
      Term_17_subtitle_3: "17- 3 Goodwill",
      Term_17_description_3:
        "You acknowledge and agree that, before filing any dispute or claim, you will contact us through support@easyexchangepro.com to solve the dispute in an informal way where we will try to solve the relevant dispute internally as soon as possible; The Parties agree to negotiate and solve any dispute in good faith (such negotiation shall be confidential and protected under applicable rules, and will not be used as evidence in any proceeding).",
      Term_17_subtitle_4: "17- 4 Assignment of rights",
      Term_17_description_4:
        "The rights and obligations agreed in this Agreement shall be equally binding on the assignees, the heirs, executors, and administrators of the parties hereto who benefit from the rights and obligations. Without our consent, you may not transfer to any third party any of your rights or obligations hereunder, provided, however, we may, at any time, assign our rights and obligations under this Agreement to any third party with forty (40) days' notices to you.",
      Term_17_subtitle_5: "17- 5 Severability",
      Term_17_description_5:
        "The invalidity or unenforceability of any of these Terms shall not affect the validity or enforceability of any other of these Terms, all of which shall remain in full force and effect.",
      Term_17_subtitle_6: "17- 6 Governing language and translations",
      Term_17_description_6:
        "These Terms, the Privacy Policy, and other agreements or communications notified through the Services have been drafted in English. Although translations in other languages of any of the foregoing documents may be available, such translations may not be up to date or complete. Accordingly, you agree that in the event of any conflict between the English language version of the foregoing documents and any other translations thereof, the English language version of such documents shall govern and prevail.",
      // Token Listing
      TL_title: "ملء النموذج لإدراج رمزك",
      TL_Exchange_info: "معلومات الصرف",
      TL_Token_Name: "اسم الرمز",
      TL_Token_Name_Error: "يرجى إدخال اسم الرمز.",
      TL_Token_symbol: "رمز الرمز",
      TL_Token_symbol_Error: "يرجى إدخال رمز الرمز.",
      TL_Token_Symbol_upload_Error: "يرجى تحميل رمز الرمز.",
      TL_NFT: "NFT",
      TL_Total_supply_Error: "يرجى إدخال إجمالي العرض.",
      TL_Date_of_issue: "تاريخ الإصدار",
      TL_Date_of_issue_Error: "يرجى إدخال تاريخ الإصدار.",
      TL_Total_circulation: "إجمالي التداول",
      TL_Total_circulation_Error: "يرجى إدخال إجمالي التداول.",
      TL_Market_cap_circulation: "قيمة سوق التداول",
      TL_Market_cap_circulation_Error: "يرجى إدخال قيمة سوق التداول.",
      TL_Source_code: "شفرة المصدر",
      TL_Source_code_Error: "يرجى إدخال شفرة المصدر.",
      TL_Whitepaper: "الورقة البيضاء",
      TL_Whitepaper_Error: "يرجى تحميل الورقة البيضاء.",
      TL_Communities: "المجتمعات",
      TL_Telegram: "تيليجرام",
      TL_Telegram_Error: "يرجى إدخال تيليجرام.",
      TL_Twitter: "تويتر",
      TL_Twitter_Error: "يرجى إدخال تويتر.",
      TL_Facebook: "فيسبوك",
      TL_Facebook_Error: "يرجى إدخال فيسبوك.",
      TL_Reddit: "ريديت",
      TL_Reddit_Error: "يرجى إدخال ريديت.",
      TL_Discord: "ديسكورد",
      TL_Discord_Error: "يرجى إدخال ديسكورد.",
      TL_Submitter_info: "معلومات المقدم",
      TL_Role: "الدور",
      TL_Role_Error: "يرجى إدخال الدور.",
      TL_Personal_ID: "الهوية الشخصية",
      TL_Personal_ID_Error: "يرجى تحميل الهوية الشخصية.",
      TL_About_project: "حول المشروع",
      TL_About_project_Error: "يرجى إدخال حول المشروع.",
      TL_About_Private: "حول البيع الخاص، ICO/IEO/IDO",
      TL_About_Private_Error: "يرجى إدخال حول البيع الخاص، ICO/IEO/IDO.",
      TL_Distribution_plan: "خطة التوزيع",
      TL_Distribution_plan_Error: "يرجى إدخال خطة التوزيع.",
      TL_CEO_info: "معلومات المدير التنفيذي/CV Linkedin",
      TL_CEO_info_Error: "يرجى إدخال معلومات المدير التنفيذي/CV Linkedin.",
      TL_Policy: "عند تقديم النموذج، فإنك توافق على سياسة الخصوصية الخاصة بنا.",
      // Deposit
      Deposit_select_Token: "يرجى تحديد الرمز:",
      Deposit_select_Network: "يرجى تحديد الشبكة:",
      Deposit_Address: "العنوان",
      Deposit_attention: "يرجى الانتباه إلى عنوان السحب والشبكة المحددة.",
      Deposit_Network: "الشبكة",
      Deposit_Warning: "إذا أودعت إلى عنوان خاطئ أو بشبكة خاطئة، ستفقد أصولك.",
      // Transfer
      Transfer_Transfer: "تحويل",
      Transfer_select_Error: "يرجى تحديد الرمز",
      Transfer_Balance_Error: "رصيد غير كافٍ.",
      Transfer_Main_to_Trade: "الرئيسية إلى التجارة",
      Transfer_Trade_to_Main: "التجارة إلى الرئيسية",
      // Withdraw
      Withdraw_balance_Error: "رصيد غير كافٍ",
      Withdraw_Amount_low_Error: "لا يمكن أن يكون مبلغ السحب أقل من ",
      Withdraw_Amount_high_Error: "لا يمكن أن يكون مبلغ السحب أكبر من ",
      Withdraw_2FA_Error_1: "تحتاج إلى تفعيل ",
      Withdraw_2FA_Error_2: "المصادقة ذات العاملين (2FA)",
      Withdraw_2FA_Error_3: "للسحب.",
      Withdraw_KYC_Error_1: "تحتاج إلى اجتياز ",
      Withdraw_KYC_Error_2: "عملية التحقق من الهوية (KYC)",
      Withdraw_KYC_Error_3: "للسحب.",
      Withdraw_Goto_Profile: "انقر للانتقال إلى صفحة الملف الشخصي",
      Withdraw_min: "الحد الأدنى لمبلغ السحب",
      Withdraw_max: "الحد الأقصى لمبلغ السحب",
      Withdraw_fee: "رسوم السحب",
      Withdraw_Unconfirmed_attention_1: "يرجى التحقق من ",
      Withdraw_Unconfirmed_attention_3: "المحدد و ",
      Withdraw_Unconfirmed_attention_4: "الشبكة المحددة",
      Withdraw_Unconfirmed_attention_5:
        "إذا قمت بإرسالها إلى عنوان الشبكة غير الصحيحة، فسوف تفقد أصولك وEasyPro Platform ليست لها أي مسؤولية عن الأصول المفقودة.",
      Withdraw_Selected_token: "الرمز المحدد",
      Withdraw_Selected_Network: "الشبكة المحددة",
      Withdraw_Confirm_Mail_2FA:
        "تأكيد البريد الإلكتروني والمصادقة الخاصة بـ Google",
      Withdraw_Confirmed: "تم تأكيد السحب",
      Withdraw_Confirmed_attention_1: "يرجى التحقق من ",
      Withdraw_Confirmed_attention_2: "صندوق الوارد للبريد الإلكتروني الخاص بك",
      Withdraw_Confirmed_attention_3: " و ",
      Withdraw_Confirmed_attention_4: "الموافقة",
      Withdraw_Confirmed_attention_5:
        "على البريد الإلكتروني الذي تم إرساله لك لإنهاء عملية السحب.",
    },
  },
  de: {
    translation: {
      ///////////////////// GLOBAL
      Already_Purchased: "Bereits gekauft",
      Global_Products: "Produkte",
      Global_Transaction: "Transaktion",
      Global_Icon: "Symbol",
      Global_Pair: "Paar",
      Global_LastPrice: "Letzter Preis",
      Global_Price: "Preis",
      Global_24h: "24h",
      Global_High: "Hoch",
      Global_Low: "Tief",
      Global_Change: "Veränderung",
      Global_Volume: "Volumen",
      Global_Trade: "Handel",
      Global_EasyGuarantee: "Einfache Garantie",
      Global_ApplicationForm: "Antragsformular",
      Global_EasyCard: "Einfache Karte",
      Global_Secure: "Sicher",
      Global_Fast: "Schnell",
      Global_Convert: "Umwandeln",
      Global_Gabur: "Gabur",
      Global_Token: "Token",
      Global_Total: "Gesamt",
      Global_Amount: "Menge",
      Global_USDT: "USDT",
      Global_Stop: "Stopp",
      Global_Limit: "Limit",
      Global_Supply: "Angebot",
      Global_Whitepaper: "Weißbuch",
      Global_Circulation: "Umlauf",
      Global_Website: "Website",
      Global_Buy: "Kaufen",
      Global_Sell: "Verkaufen",
      Global_Loading: "Wird geladen...",
      Global_Submit: "Absenden",
      Global_Cancel: "Abbrechen",
      Global_Confirm: "Bestätigen",
      Global_Close: "Schließen",
      Global_OTP: "OTP",
      Global_Email: "E-Mail",
      Global_2FA: "2FA",
      Global_Password: "Passwort",
      Global_FName: "Vorname",
      Global_LName: "Nachname",
      Global_IDNum: "Ausweisnummer",
      Global_Login: "Anmelden",
      Global_Register: "Registrieren",
      Global_Transfer: "Übertragen",
      Global_UID: "Benutzer-ID",
      Global_Purpose: "Zweck",
      Global_Mobile: "Handynummer",
      Global_Deposit: "Einzahlung",
      Global_Withdraw: "Abheben",
      Global_Fee: "Gebühr",
      Global_Fees: "Gebühren",
      Global_OTC: "OTC",
      Global_Support: "Support",
      Global_Download: "Herunterladen",
      Global_From: "Von",
      Global_To: "An",
      Global_Now: "Jetzt",
      Global_Digital: "Digital",
      Global_crypto: "Krypto",
      Global_cash: "Bargeld",
      Global_wallet: "Wallet",
      Global_account: "Konto",
      Global_Exchange: "Börse",
      Global_trading: "Handel",
      Global_Accept_Terms:
        "Durch Anklicken des Kästchens akzeptieren Sie unsere Allgemeinen Geschäftsbedingungen.",
      Global_Available: "Verfügbar",
      Global_Username: "Benutzername",
      Global_Time: "Zeit",
      Global_Status: "Status",
      Global_Network_Address: "Netzwerkadresse",
      Global_Max_upload: "Maximale Dateigröße pro Datei: 10 MB",
      Global_Policy:
        "Durch Absenden Ihres Formulars akzeptieren Sie unsere Datenschutzrichtlinie",
      Global_UID_Error: "Bitte geben Sie die Benutzer-ID ein.",
      Global_IDNum_Error: "Bitte geben Sie die Ausweisnummer ein.",
      Global_Mobile_Error: "Bitte geben Sie die Mobiltelefonnummer ein.",
      Global_Price_Error: "Bitte geben Sie den Preis ein.",
      Global_Stop_Error: "Bitte geben Sie den Stop-Preis ein.",
      Global_Amount_Error: "Bitte geben Sie den Betrag ein.",
      Global_Total_Error: "Bitte geben Sie den Gesamtbetrag ein.",
      Global_Email_Error: "Bitte geben Sie die E-Mail-Adresse ein.",
      Global_Password_Error: "Bitte geben Sie das Passwort ein.",
      Global_FName_Error: "Bitte geben Sie den Vornamen ein.",
      Global_LName_Error: "Bitte geben Sie den Nachnamen ein.",
      Global_Website_Error: "Bitte geben Sie die Webadresse ein.",
      Global_Purpose_Error: "Bitte geben Sie den Zweck der Anfrage an.",
      Global_Accept_Terms_Error: "Sie müssen zustimmen, bevor Sie fortfahren.",
      Global_about: 'Über uns',
      Global_coin_info: "Münzinfo",
      Global_token_listing: "Token-Auflistung",
      Global_logout: "Ausloggen",
      // Home
      //// Top
      Home_Top_Download_From: "Herunterladen von",
      Home_Top_Playstore: "Playstore",
      Home_Top_Download_APK: "APK herunterladen",
      Home_Top_APPstore: "APPstore",
      Home_Top_Register_Now: "Jetzt registrieren",

      //// Carousel
      Home_Carousel_1_title: "Transparenz und Sicherheit",
      Home_Carousel_1_description:
        "Wir haben transparente Details über den Gabur Token im Whitepaper veröffentlicht, um Ihnen bei Ihrer Investitionsentscheidung zu helfen. Darüber hinaus wurde der Smart Contract des Gabur Tokens auditiert, um das höchste Maß an Sicherheit zu gewährleisten.",
      Home_Carousel_2_title: "Easy Guarantee",
      Home_Carousel_2_description:
        "Easy Exchange Pro bietet erstmalig in der digitalen Welt das Easy Guarantee-System an, um Geldtransfers zwischen Unternehmen und für Geschäftsverträge abzusichern.",
      Home_Carousel_3_title: "24/7 Support",
      Home_Carousel_3_description:
        "Sie können das Support-Team über das Ticket-System kontaktieren oder eine E-Mail an support@easyexchangepro.com senden.",

      //// Easy Guarantee
      Home_EG_subtitle: "Erste digitale Garantie in der Kryptowelt",
      Home_EG_description:
        "Easy Exchange Pro bietet erstmalig in der digitalen Welt das Easy Guarantee-System an, um Geldtransfers zwischen Unternehmen und für Geschäftsverträge abzusichern.",
      Home_EG_more_info_btn: "Weitere Informationen",

      //// Easy Card
      Home_EC_subtitle_1: "Der einzige Weg, um",
      Home_EC_subtitle_2: "Kryptowährungen in bar zu kaufen",
      Home_EC_description_1:
        "Erhalten Sie den Easy Card Prepaid-Code in Ihrer Nähe und laden Sie die Gabur Tokens in Ihre Wallet. Sicher und zuverlässig, ohne Bank oder Kreditkarte. Es ist der einzige Weg, um Kryptowährungen in bar zu kaufen.",
      Home_EC_Feature_1_title: "Unabhängig",
      Home_EC_Feature_1_description_1:
        "Kauf von Kryptowährungen ohne Bankkonto oder",
      Home_EC_Feature_1_description_2: "Kreditkarte",
      Home_EC_Feature_2_description_1:
        "Alle Ihre Vermögenswerte sind unter Ihrer",
      Home_EC_Feature_2_description_2: "eigenen Kontrolle",
      Home_EC_Feature_3_description_1: "Bareinzahlung auf Ihre Wallet",
      Home_EC_Feature_3_description_2: "in Sekundenschnelle",
      Home_EC_Feature_4_title: "Einfach",
      Home_EC_Feature_4_description_1: "Sie tun, was Sie schon immer",
      Home_EC_Feature_4_description_2: "getan haben",
      Home_EC_Feature_5_title: "Keine Einzahlungsgebühr",
      Home_EC_Feature_5_description_1: "Einzahlen, ohne dass es Sie",
      Home_EC_Feature_5_description_2: "etwas kostet",
      Home_EC_Feature_6_description_1: "Sie können den Gabur-Token in",
      Home_EC_Feature_6_description_2: "andere Währungen umwandeln.",
      Home_EC_Where_to_buy: "Wo kaufen",
      //// Mobile Section
      Home_MS_title:
        "Starten Sie den einfachen Handel mit der Easy Exchange Pro Mobile App.",
      Home_MS_list_1: "24/7 Zugriff auf Ihre Vermögenswerte überall",
      Home_MS_list_2: "Sicher und geschützt",
      Home_MS_list_3: "Benutzerfreundliche Oberfläche",
      Home_MS_list_4: "Erweiterte Handelstools",
      Home_MS_list_5: "Alles-in-einer-App",
      Home_MS_dl_for_ios: "Für iOS herunterladen",
      Home_MS_dl_for_android: "Für Android herunterladen",
      // Über uns
      //// Ein Top-Video
      About_top_title: "Unsere Mission",
      About_top_desciption:
        "Unsere Mission besteht darin, ein Ökosystem aufzubauen, das jedem das Recht gibt, einfach auf die Welt der digitalen Währungen zuzugreifen und somit die finanzielle Freiheit zu erlangen.",
      About_top_Feature_1: "600,000",
      About_top_Feature_1_description_1:
        "Geregistreerde gebruikers",
      About_top_Feature_1_description_2: " ",
      About_top_Feature_2: "2 Standorte",
      About_top_Feature_2_description: "Unsere Standorte in 2 Ländern",
      About_top_Feature_3: "+28 Länder",
      About_top_Feature_3_description: "Dienstleistungen in über 28 Ländern",
      ///// A Second
      About_second_title: "Warum Easy Exchange Pro wählen?",
      About_second_subtitle:
        "Einfach für Anfänger und fortgeschritten für Profis",
      About_second_description_1:
        "Obwohl die Welt der Kryptowährungen etwas komplex sein kann, sind wir hier, um zu helfen! Unsere Plattform erleichtert den Handel.",
      About_second_description_2:
        "Wir bieten Ihnen Tools zum Kauf und Verkauf der beliebtesten Kryptowährungen an einem Ort, während Sie durch branchenführende Sicherheitsmaßnahmen geschützt sind.",
      About_second_Feature_1_description_1:
        "Geschützt durch branchenführende Sicherheitsmaßnahmen",
      About_second_Feature_1_description_2:
        "auf Basis kryptografischer Technologie",
      About_second_Feature_2_title: "Einfach zu bedienen",
      About_second_Feature_2_description_1:
        "Wir haben eine einfache und benutzerfreundliche",
      About_second_Feature_2_description_2:
        "Plattform entwickelt und stellen sie Ihnen zur Verfügung",
      About_second_Feature_3_title: "Erweiterte Nutzung (für Profis)",
      About_second_Feature_3_description_1:
        "Wir haben eine fortschrittliche Handelsoberfläche entwickelt",
      About_second_Feature_3_description_2:
        "die unseren Benutzern ein professionelles Erlebnis bietet",
      About_second_Feature_4_description_1:
        "Handeln Sie Ihre Lieblingswährungen",
      About_second_Feature_4_description_2: "in Sekundenschnelle",
      About_second_Feature_5_title: "Barzahlung",
      About_second_Feature_5_description_1:
        "Der einfachste Weg, Kryptowährungen",
      About_second_Feature_5_description_2:
        "in bar zu kaufen, ist exklusiv mit der Easy Card",
      About_second_Feature_6_title: "24/7",
      About_second_Feature_6_description_1:
        "Beginnen Sie mit dem Handel von Kryptowährungen und Sie erhalten jederzeit Unterstützung von uns.",
      About_second_Feature_6_description_2: "",

      //// Road Map
      About_road_title: "Roadmap",
      About_road_description_1:
        "Die Entwicklung von Easy Exchange Pro wurde auf einen Zeitraum von drei Jahren verteilt.",
      About_road_description_2:
        "Hier finden Sie eine Übersicht unserer Ziele, die in den kommenden Jahren erreicht werden sollen.",
      About_road_year_1: "2022",
      About_road_year_1_description_1: "Einzigartiges Produkt: Easy Guarantee",
      About_road_year_1_description_2: "+ 6 Sprachen",
      About_road_year_1_description_3: "+ 10 Broker in 5 Ländern",
      About_road_year_1_description_4: "+ 100 weitere Mitarbeiter",
      About_road_year_1_description_5: "+ 10 weitere Handelspaare",
      About_road_year_2: "2023",
      About_road_year_2_description_1: "Neues Produkt: Easy Wallet",
      About_road_year_2_description_2: "Easy Pro Academy",
      About_road_year_2_description_3: "API für Unternehmensintegration",
      About_road_year_2_description_4:
        "Die Easy Card wird eine effiziente Methode für Transaktionen sein",
      About_road_year_2_description_5: "+ 6 Sprachen",
      About_road_year_2_description_6: "+ 200 Broker in 16 Ländern",
      About_road_year_2_description_7: "+ 5 Fiat-Währungen",
      About_road_year_2_description_8: "+ 250 Mitarbeiter",
      About_road_year_2_description_9: "+ 30 Handelspaare",
      About_road_year_2_description_10: "+ 100 Glaubwürdigkeitsmünzen",
      About_road_year_2_description_11:
        "Notierung von GABUR auf anderen erstklassigen Plattformen",
      About_road_year_3: "2024",
      About_road_year_3_description_1: "Einzigartiges Produkt: Crypto Bank",
      About_road_year_3_description_2: "+ 6 Sprachen",
      About_road_year_3_description_3: "+ 1000 Mitarbeiter",
      About_road_year_3_description_4: "+ 100 Handelspaare",
      About_road_year_3_description_5: "+ 130 Glaubwürdigkeitsmünzen",
      About_road_year_3_description_6:
        "Notierung von GABUR auf anderen erstklassigen Plattformen",

      // API-Dokumente
      Api_title: "API-Dokumente",
      Api_1: "Zusammenfassung",
      Api_2: "Vermögenswerte",
      Api_3: "Ticker",
      Api_4: "Orderbuch",
      Api_5: "Trades",
      Api_6: "24-Stunden-Volumen",

      // Vermögenswerte
      Asset_Tab_1: "Alle Vermögenswerte",
      Asset_Tab_2: "Haupt-Wallet",
      Asset_Tab_3: "Handels-Wallet",
      Asset_Total_BTC: "Gesamtwert der Vermögenswerte in BTC",
      Asset_In_Withdraw: "In Auszahlung",
      Asset_In_Order: "In Auftrag",

      // Kontaktieren Sie uns
      ContactUs_email_title: "Kontaktieren Sie uns:",
      ContactUs_email: "Support@easyexchangepro.com",
      ContactUs_faq_title:
        "Sie können unsere FAQ-Seite überprüfen, um Ihre Antwort zu finden:",
      ContactUs_faq: "",
      ContactUs_ticket_title: "Oder Sie können ein Ticket eröffnen:",
      ContactUs_ticket: "",
      ContactUs_social_title: "Finden und folgen Sie uns auf:",

      // Coin info
      CoinInfo_Issue_Time: "Issue Time :",
      CoinInfo_White_paper: "Whitepaper:",
      CoinInfo_Website: "Website:",
      CoinInfo_Block_Explorer: "Block Explorer:",
      CoinInfo_AAVE:
        "Aave is an open source and non-custodial protocol enabling the creation of money markets. Users can earn interest on deposits and borrow assets. Deposit interest rate and loan interest rate of both borrowers and lenders are calculated through algorithms based on the platform's borrowing volume and deposit volume, and the platform uses Chainlink's oracles to ensure the fairness of the collateral price.",
      CoinInfo_ADA:
        "Cardano is a decentralised public blockchain and cryptocurrency project and is fully open source. Cardano is developing a smart contract platform which seeks to deliver more advanced features than any protocol previously developed. It is the first blockchain platform to evolve out of a scientific philosophy and a research-first driven approach.",
      CoinInfo_Atom:
        "In a nutshell, Cosmos bills itself as a project that solves some of the “hardest problems” facing the blockchain industry. It aims to offer an antidote to “slow, expensive, unscalable and environmentally harmful” proof-of-work protocols, like those used by Bitcoin, by offering an ecosystem of connected blockchains. The project’s other goals include making blockchain technology less complex and difficult for developers thanks to a modular framework that demystifies decentralized apps. Last but not least, an Interblockchain Communication protocol makes it easier for blockchain networks to communicate with each other — preventing fragmentation in the industry. Cosmos’ origins can be dated back to 2014, when Tendermint, a core contributor to the network, was founded. In 2016, a white paper for Cosmos was published — and a token sale was held the following year. ATOM tokens are earned through a hybrid proof-of-stake algorithm, and they help to keep the Cosmos Hub, the project’s flagship blockchain, secure. This cryptocurrency also has a role in the network’s governance.",
      CoinInfo_AVAX:
        "Avalanche is a layer one blockchain that functions as a platform for decentralized applications and custom blockchain networks. It is one of Ethereum’s rivals, aiming to unseat Ethereum as the most popular blockchain for smart contracts. It aims to do so by having a higher transaction output of up to 6,500 transactions per second while not compromising scalability. This is made possible by Avalanche’s unique architecture. The Avalanche network consists of three individual blockchains: the X-Chain, C-Chain and P-Chain. Each chain has a distinct purpose, which is radically different from the approach Bitcoin and Ethereum use, namely having all nodes validate all transactions. Avalanche blockchains even use different consensus mechanisms based on their use cases. After its mainnet launch in 2020, Avalanche has worked on developing its own ecosystem of DApps and DeFi. Different Ethereum-based projects such as SushiSwap and TrueUSD have integrated with Avalanche. Furthermore, the platform is constantly working on improving interoperability between its own ecosystem and Ethereum, like through the development of bridges.",
      CoinInfo_BNB:
        "From the ICO, Binance issued its own coin called the Binance Coin, with BNB as the symbol. BNB runs natively on Binance chain. The coin was established with a total supply of 200 million. Every quarter, we will destroy BNB based on the trading volume on our crypto-to-crypto platform until we destroy 50% of all the BNB. All transactions will be on the blockchain. We eventually will destroy 100MM BNB, leaving 100MM BNB remaining. The BNB coin itself has multiple forms of utility, essentially being the underlying gas that powers the Binance Ecosystem. The current most prominent use cases include using BNB to: - Pay for trading fees on the exchange, obtaining the equivalent of a 50% discount on trades (during the first year). - Crypto.com has included support for Binance’s BNB token to its platform which includes the MCO Visa Card and mobile app.",
      CoinInfo_BTC:
        "Bitcoin is a cryptocurrency and a digital payment system invented by an unknown programmer, or a group of programmers, under the name Satoshi Nakamoto.It was released as open-source software in 2009.The system is peer-to-peer, and transactions take place between users directly, without an intermediary. These transactions are verified by network nodes and recorded in a public distributed ledger called a blockchain. Since the system works without a central repository or single administrator, bitcoin is called the first decentralized digital currency.Besides being created as a reward for mining, bitcoin can be exchanged for other currencies, products, and services in legal or black markets.As of February 2015, over 100,000 merchants and vendors accepted bitcoin as payment. According to research produced by Cambridge University in 2017, there are 2.9 to 5.8 million unique users using a cryptocurrency wallet, most of them using bitcoin.",
      CoinInfo_Cake:
        "PancakeSwap, built on Binance Smart Chain (BSC), is a decentralized exchange for swapping BEP-20 tokens. It is fast, cheap, and allows anyone to participate. Users can get FLIP (LP token) for staking and get CAKE token as reward. CAKE holders can swap CAKE for SYRUP for additional incentivized staking. PancakeSwap uses an automated market maker (AMM) model. It means that while you trade digital assets on the platform, there isn’t an order book where you’re matched with someone else. Instead, you trade against a liquidity pool. Traders deposit funds into the pool, receiving liquidity provider (or LP) tokens in return. They can use those tokens to reclaim their share, plus a portion of the trading fees.",
      CoinInfo_Doge:
        "Dogecoin is a cryptocurrency focused on actual utility as a currency. We provide fast block times and very low fees which make Dogecoin suitable for usage in micro-transactions but also as payment option for online shops. Dogecoin has been adopted as such by online retailers and can be used easily as means of consumer to consumer money transfer too.",
      CoinInfo_Dot:
        'Polkadot is a global well-known blockchain cross-chain project, which is created by Ethereum co-founder and Ethereum yellow book author Gavin Wood. It aims to solve the communication and data transmission obstacles between different blockchains and was rated as one of the "4 major projects that decide the direction of digital currency development in 2019".',
      CoinInfo_ETC:
        "Ethereum classNameic (ETC) is a hard fork of Ethereum (ETH) that launched in July 2016. Its main function is as a smart contract network, with the ability to host and support decentralized applications (DApps). Its native token is ETC. Since its launch, Ethereum classNameic has sought to differentiate itself from Ethereum, with the two networks’ technical roadmap diverging further and further from each other with time. Ethereum classNameic first set out to preserve the integrity of the existing Ethereum blockchain after a major hacking event led to the theft of 3.6 million ETH.",
      CoinInfo_ETH:
        'Ethereum (ETH) is an open-source, public, blockchain-based distributed computing platform featuring smart contract (scripting) functionality, which facilitates online contractual agreements. Ethereum also provides a cryptocurrency token called "ether", which can be transferred between accounts and used to compensate participant nodes for computations performed. "Gas", an internal transaction pricing mechanism, is used to mitigate spamand allocate resources on the network.The value token of the Ethereum blockchain is called ether. It is listed under the diminutive ETH and traded on cryptocurrency exchanges. It is also used to pay for transaction fees and computational services on the Ethereum network.',
      CoinInfo_Fil:
        "The InterPlanetary File system (IPFS) is a global, peer-to-peer distributed version of the file system that aims to supplement (or even replace) the hypertext transfer protocol (HTTP) that currently dominates the internet, by connecting all computing devices with the same file system. The principle is to replace the address based on the domain name with a content-based address, that is, the user is looking for content that is not an address but stored in a certain place. There is no need to verify the identity of the sender, but only the hash of the content. It can ensure the web to be faster, safer, more robust and more durable.Filecoin is an incentive layer on IPFS and a a decentralized storage market built on IPFS based on the token incentive model.FIL6 is the Filecoin contract issued 6 months after its launch",
      CoinInfo_GBR:
        "Gabur (GBR) is a utility token for the Easy Exchange Pro platform. The Gabur is using for the Easy Card, a system to buy crypto without cash, and also Easy Guarantee which is a system to guarantee payment between companies and their customers.",
      CoinInfo_LTC:
        "Litecoin is a peer-to-peer Internet currency that enables instant, near-zero cost payments to anyone in the world. Litecoin is an open source, global payment network that is fully decentralized without any central authorities. Mathematics secures the network and empowers individuals to control their own finances. Litecoin features faster transaction confirmation times and improved storage efficiency than the leading math-based currency. With substantial industry support, trade volume and liquidity, Litecoin is a proven medium of commerce complementary to Bitcoin",
      CoinInfo_Matic:
        "Matic Network is a layer-2 scaling solution with side-chains for off-chain computation, while ensuring asset security using the Plasma framework and a decentralized network of Proof-of-Stake (PoS) validators. It aims to offer scalable and instant blockchain transactions. By utilizing adapted version of the Plasma framework, the system is built on PoS checkpoints that are pushed to the Ethereum main-chain. As a result, it is expected to allow each Matic sidechain to theoretically achieve 2^16 transactions per block. Matic Network has been a significant contributor to the Ethereum ecosystem, having worked on implementations of Plasma MVP (Minimum Viable Plasma), the WalletConnect protocol, and Dagger, a popular Ethereum event notification engine. Many projects have already been building applications on and integrating with Matic. MATIC is an ERC-20 token running on Ethereum, it used to pay for services on the Matic Network but also serves as a settlement currency between users who operate within the Matic ecosystem.",
      CoinInfo_MKR:
        "Maker (MKR) is the governance token of the MakerDAO and Maker Protocol — respectively a decentralized organization and a software platform, both based on the Ethereum blockchain — that allows users to issue and manage the DAI stablecoin. Initially conceived in 2015 and fully launched in December 2017, Maker is a project whose task is to operate DAI, a community-managed decentralized cryptocurrency with a stable value soft-pegged to the US dollar. MKR tokens act as a kind of voting share for the organization that manages DAI; while they do not pay dividends to their holders, they do give the holders voting rights over the development of Maker Protocol and are expected to appreciate in value in accordance with the success of DAI itself. The Maker ecosystem is one of the earliest projects on the decentralized finance (DeFi) scene: the industry that seeks to build decentralized financial products on top of smart-contract-enabled blockchains, such as Ethereum.",
      CoinInfo_Near:
        "NEAR Protocol is a layer-one blockchain that was designed as a community-run cloud computing platform and that eliminates some of the limitations that have been bogging competing blockchains, such as low transaction speeds, low throughput and poor interoperability. This provides the ideal environment for DApps and creates a developer and user-friendly platform. For instance, NEAR uses human-readable account names, unlike the cryptographic wallet addresses common to Ethereum. NEAR also introduces unique solutions to scaling problems and has its own consensus mechanism called “Doomslug.” NEAR Protocol is being built by the NEAR Collective, its community that is updating the initial code and releasing updates to the ecosystem. Its declared goal is to build a platform that is “secure enough to manage high value assets like money or identity and performant enough to make them useful for everyday people.” Flux, a protocol that allows developers to create markets based on assets, commodities, real-world events, and Mintbase, an NFT minting platform are examples of projects being built on NEAR Protocol.",
      CoinInfo_TRX:
        "Tron is one of the largest blockchain based operating systems in the world. TRON is an ambitious project dedicated to building the infrastructure for a truly decentralized Internet. The Tron Protocol, one of the largest blockchain based operating systems in the world, offers scalable, high-availability and high-throughput support that underlies all the decentralized applications in the TRON ecosystem. TRON Protocol and the TVM allow anyone to develop DAPPs for themselves or their communities, with smart contracts making decentralized crowdfunding and token issuance easier than ever. Tron DAPP projects already include Peiwo, Obike, Uplive, game.com, Kitty live and Mico, with 100M+ active users from more than 100 countries and regions around the world.",
      CoinInfo_XLM:
        "Stellar network is a free, open-source network that connects diverse financial systems and lets anyone build low-cost financial services—payments, savings, loans, insurance—for their community. It is supported by Stellar.org, a Silicon Valley based non-profit organization. The Stellar network enables money to move directly between people, companies and financial institutions as easily as email. This inter-connectivity means more access for individuals, lower costs for banks, and more revenue for businesses. Token use: Stellar is an open-source, decentralized protocol for digital currency to fiat currency transfers which allows cross-border transactions between any pair of currencies.",
      CoinInfo_XRP:
        "Ripple (XRP) is an independent digital asset that is native to the Ripple Consensus Ledger. With proven governance and the fastest transaction confirmation of its kind, XRP is said to be the most efficient settlement option for financial institutions and liquidity providers seeking global reach, accessibility and fast settlement finality for interbank flows.",
      CoinInfo_ZEC:
        "NZcash is a decentralized cryptocurrency focused on privacy and anonymity. It uses the zk-SNARK zero-knowledge proof technology that allows nodes on the network to verify transactions without revealing any sensitive information about those transactions. Contrary to a common misunderstanding, the majority of cryptocurrencies on the market, including Bitcoin (BTC), are not anonymous, but rather pseudonymous; while they do not explicitly reveal the identities of their users, each user has their own public address or addresses which can be traced back to them via the methods of data science and blockchain forensics. Zcash transactions, on the other hand, still have to be relayed via a public blockchain, but unlike pseudonymous cryptocurrencies, ZEC transactions by default do not reveal the sending and receiving addresses or the amount being sent. There is an option, however, to reveal this data for the purposes of auditing or regulatory compliance. Zcash was first released on October 28, 2016, and it was originally based on Bitcoin’s codebase.",
      // Easy Card
      //// Where To Buy
      ///// Top
      EC_WT_Title: "Easy Card kaufen",
      EC_WT_Description:
        "Sie können die Easy Card bei einem unserer Broker in Ihrer Nähe kaufen und Ihr Konto ohne Visa oder Kreditkarte aufladen",
      EC_WT_Find_Title: "Broker in Ihrer Nähe finden",
      EC_WT_Find_btn: "Finden",
      EC_WT_Find_input: "Geben Sie Ihre Stadt ein",
      ///// Apply
      EC_WA_title:
        "Bewerben Sie sich für die Easy Card-Lizenz und werden Sie einer unserer Partner und profitieren Sie davon.",
      EC_WA_Decription: "Bewerben",
      ///// Start
      EC_WS_Question: "WIE FUNKTIONIERT DIE EASY CARD?",
      EC_WS_title: "In 3 einfachen Schritten loslegen",
      EC_WS_SubTitle: "Der gesamte Prozess dauert weniger als 3 Minuten.",
      EC_WS_Feature_1_description:
        "Finden Sie einen Broker in Ihrer Nähe und kaufen Sie die Easy Card",
      EC_WS_Feature_2_description:
        "Geben Sie Ihren Easy Card-Code ein, um Ihre Gabur Tokens in Ihre Wallet einzuzahlen",
      EC_WS_Feature_3_description: "Jetzt können Sie mit dem Handel beginnen",
      //// Already Purchased
      EC_AP_description:
        "Geben Sie hier Ihren Code ein, die Gabur Tokens werden automatisch in Ihre Wallet eingezahlt.",
      EC_AP_Code: "Geben Sie Ihren Code ein",
      EC_AP_Code_Error: "Bitte geben Sie einen 20-stelligen Code ein.",
      //// Broker
      ///// Register
      EC_BR_title: "Als Broker registrieren",
      ///// Form
      EC_BR_Exchange_info: "Börseninformationen",
      EC_BR_Legal: "Rechtsname",
      EC_BR_Legal_Error:
        "Bitte geben Sie den registrierten Rechtsnamen des Landes ein.",
      EC_BR_RegCountry: "Registriertes Land",
      EC_BR_RegCountry_Error: "Bitte geben Sie das registrierte Land ein.",
      EC_BR_RegNum: "Registrierungsnummer",
      EC_BR_RegNum_Error: "Bitte geben Sie die Registrierungsnummer ein.",
      EC_BR_MonTxVal: "Monatliches Transaktionsvolumen",
      EC_BR_MonTxVal_Error:
        "Bitte geben Sie das monatliche Transaktionsvolumen ein.",
      EC_BR_ECMonDis: "Easy Card monatliche Verteilung",
      EC_BR_ECMonDis_Error:
        "Bitte geben Sie die monatliche Verteilung der Easy Card ein.",
      EC_BR_Website: "Website (falls vorhanden)",
      EC_BR_Submitter_info: "Angaben des Antragstellers",
      EC_BR_Job: "Berufsbezeichnung",
      EC_BR_Job_Error: "Bitte geben Sie die Berufsbezeichnung ein.",
      EC_BR_Birth: "Geburtsdatum",
      EC_BR_Birth_Error: "Bitte geben Sie das Geburtsdatum ein.",
      EC_BR_Country: "Land des Wohnsitzes",
      EC_BR_Country_Error: "Bitte geben Sie das Land des Wohnsitzes ein.",
      EC_BR_Citizen: "Land der Staatsbürgerschaft",
      EC_BR_Citizen_Error:
        "Bitte geben Sie das Land der Staatsbürgerschaft ein.",
      EC_BR_Upload_title:
        "Bitte laden Sie Börsen- und persönliche Dokumente hoch:",
      EC_BR_Exchange_Doc: "Dokument zur Börsenregistrierung",
      EC_BR_Exchange_Doc_Error:
        "Bitte laden Sie das Dokument zur Börsenregistrierung hoch.",
      EC_BR_Peronal_ID_Doc: "Personalausweis-Dokument",
      EC_BR_Peronal_ID_Doc_Error:
        "Bitte laden Sie das Personalausweis-Dokument hoch.",
      // Easy Guarantee
      //// Easy Guarantee
      ////// top
      EG_EGTop_title: "Easy Guarantee",
      EG_EGTop_Description_1: "Die einzige digitale Kryptogarantie",
      EG_EGTop_Description_2: "Erstmals weltweit",

      ////// Text
      EG_EGText_title: "Was ist die Easy Guarantee?",
      EG_EGText_Description_1:
        "Die Easy Guarantee ist eine Art USDT-Absicherung, die von Easyexchangepro angeboten wird. Dabei stellt der Kreditgeber sicher, dass die Verbindlichkeiten eines Schuldners erfüllt werden. Mit anderen Worten, wenn der Schuldner eine Schuld nicht begleicht, übernimmt Easyexchangepro diese.",
      EG_EGText_Description_2:
        "Eine Easy Guarantee ermöglicht es dem Antragsteller, Waren zu erwerben, Ausrüstung zu kaufen, einen Kredit aufzunehmen oder eine Anzahlung zu leisten, usw.",

      EG_EGText_Feature_1_description:
        "Sie können in 5 Minuten eine Easy Guarantee für geschäftliche Zwecke ausstellen.",
      EG_EGText_Feature_2_title: "Verlässlich",
      EG_EGText_Feature_2_description:
        "Die Easy Guarantee ist nicht nur ein Zahlungsverpflichtungsdokument. Vielmehr wird USDT als die liquideste digitale Währung auf das Konto des Begünstigten hinterlegt.",
      EG_EGText_Feature_3_description:
        "Die ausgestellte Easy Guarantee ist zu 100% sicher und geschützt für den Begünstigten und kann bei Fälligkeit umgewandelt oder abgehoben werden.",

      ////// Steps
      EG_EGSteps_title:
        "So können Sie eine Easy Guarantee in 4 einfachen Schritten ausstellen:",
      EG_EGSteps_Feature_1_title: "Schritt 1",
      EG_EGSteps_Feature_1_description_1: "/",
      EG_EGSteps_Feature_1_description_2: "In unserer Plattform",
      EG_EGSteps_Feature_2_title: "Schritt 2",
      EG_EGSteps_Feature_2_description_1: "Füllen Sie das Antragsformular aus",
      EG_EGSteps_Feature_2_description_2: "und senden Sie es ab",
      EG_EGSteps_Feature_3_title: "Schritt 3",
      EG_EGSteps_Feature_3_description_1: "Überweisen Sie den Betrag der",
      EG_EGSteps_Feature_3_description_2: "Easy Guarantee mit USDT",
      EG_EGSteps_Feature_4_title: "Schritt 4",
      EG_EGSteps_Feature_4_description_1: "Die Garantie wurde ausgestellt und",
      EG_EGSteps_Feature_4_description_2:
        "die USDT wurden eingezahlt und gesperrt",
      EG_EGSteps_Feature_4_description_3: "im Konto des Begünstigten",

      ////// Bot
      EG_EGBot_title: "Für Unternehmen und Einzelpersonen",
      EG_EGBot_description:
        "Unternehmen und Einzelpersonen können als unsere Benutzer eine Garantie in kleinen oder großen Beträgen für geschäftliche Zwecke zugunsten des Begünstigten ausstellen.",
      //// FAQ
      EG_EGFaq_Q1: "Was ist die Easy Guarantee?",
      EG_EGFaq_A1:
        "Easy Guarantee ist eine Art Garantie mit Kryptowährung (USDT) als Sicherheit. Dabei garantieren die Kreditnehmer/Käufer, dass ihre Schulden gegenüber dem Gläubiger beglichen werden.",
      EG_EGFaq_Q2: "Wer ist der Antragsteller?",
      EG_EGFaq_A2:
        "Der Antragsteller ist die Partei, die beantragt, die Easy Guarantee auszustellen.",
      EG_EGFaq_Q3:
        "Welche Währung wird für die Ausstellung einer Garantie akzeptiert?",
      EG_EGFaq_A3: "Nur USDT.",
      EG_EGFaq_Q4: "Wird jede USDT-Wallet-Adresse akzeptiert?",
      EG_EGFaq_A4:
        "Nein, nur die USDT-Wallet-Adressen des Antragstellers und des Begünstigten werden von Easy Exchange Pro akzeptiert.",
      EG_EGFaq_Q5: "Wer ist der Begünstigte?",
      EG_EGFaq_A5:
        "Die Partei, zu deren Gunsten die Garantie ausgestellt wurde.",
      EG_EGFaq_Q6: "Was ist die bedingte Easy Guarantee?",
      EG_EGFaq_A6:
        "Der Antrag auf Freigabe des Garantiebetrags durch den Begünstigten muss vom Antragsteller genehmigt werden.",
      EG_EGFaq_Q7: "Was ist die bedingungslose Easy Guarantee?",
      EG_EGFaq_A7:
        "Der Garantiebetrag wird auf erstes Verlangen des Begünstigten freigegeben.",
      EG_EGFaq_Q8: "Wie können Benutzer einen Antrag stellen?",
      EG_EGFaq_A8: "Füllen Sie das Antragsformular aus und senden Sie es ab.",
      EG_EGFaq_Q9: "Was passiert nach dem Absenden des Formulars?",
      EG_EGFaq_A9:
        "Der Antragsteller erhält eine erste E-Mail zur Bestätigung des Antrags, dann eine zweite E-Mail mit der USDT-Wallet-Adresse, um den Betrag der Easy Guarantee zu überweisen.",
      EG_EGFaq_Q10: "Wie viel kann der Benutzer als Easy Guarantee ausstellen?",
      EG_EGFaq_A10:
        "Der Benutzer kann eine Easy Guarantee in Höhe seines USDT-Guthabens in seinem Konto ausstellen.",
      EG_EGFaq_Q11:
        "Muss der Antragsteller über ein USDT-Guthaben verfügen, um eine Easy Guarantee anzufordern und auszustellen?",
      EG_EGFaq_A11:
        "Ja, die Benutzer können beantragen, eine Easy Guarantee in Höhe ihres USDT-Guthabens in ihrem Konto auszustellen.",
      EG_EGFaq_Q12: "Wann wird die Easy Guarantee ausgestellt?",
      EG_EGFaq_A12:
        "Nach der Überweisung des USDT durch den Antragsteller wird die Easy Guarantee sofort ausgestellt und der USDT-Betrag wird basierend auf der Gültigkeitsdauer im Konto des Begünstigten gesperrt.",
      EG_EGFaq_Q13:
        "Wie wird ein Streit bezüglich der Freigabe der Garantie zwischen dem Antragsteller und dem Begünstigten gelöst?",
      EG_EGFaq_A13:
        "Im Falle eines Streits kann jede der Parteien ihren Einspruch an easygu arantee@easyexchangepro.com senden, und unsere Rechtsabteilung wird innerhalb eines Monats nach Prüfung der Unterlagen der Parteien eine Entscheidung treffen.",
      EG_EGFaq_Q14:
        "Müssen der Antragsteller und der Begünstigte beide Benutzer der Easyexchangepro-Plattform sein?",
      EG_EGFaq_A14: "Ja, das ist erforderlich.",
      EG_EGFaq_Q15:
        "Wann kann der Begünstigte die Freigabe des Easy Guarantee-Betrags beantragen?",
      EG_EGFaq_A15:
        "Der Begünstigte kann während der Gültigkeitsdauer der Easy Guarantee die Freigabe des Betrags im Konto beantragen.",
      EG_EGFaq_Q16:
        "Was passiert, wenn der Begünstigte während der Gültigkeitsdauer der Easy Guarantee nicht die Freigabe des Garantiebetrags beantragt?",
      EG_EGFaq_A16:
        "Der Betrag der Easy Guarantee wird automatisch dem Konto des Antragstellers gutgeschrieben.",
      EG_EGFaq_Q17: "Wie lange dauert es, eine Easy Guarantee auszustellen?",
      EG_EGFaq_A17: "Weniger als 5 Minuten.",
      EG_EGFaq_Q18:
        "Wie hoch sind die Kosten für die Ausstellung einer Easy Guarantee?",
      EG_EGFaq_A18: "0,4% des Easy Guarantee-Betrags.",
      EG_EGFaq_Q19: "Wer trägt die Kosten?",
      EG_EGFaq_A19: "Der Antragsteller.",
      EG_EGFaq_Application_form: "Antragsformular",
      //// Application Form
      EG_AF_title: "Easy Guarantee Antragsformular",
      EG_AF_description:
        "Hinweis: Bitte lesen Sie die Fragen zur Easy Guarantee sorgfältig durch, bevor Sie das Antragsformular ausfüllen.",
      EG_AP_Applicant: "Antragsteller:",
      EG_AP_SDate: "Startdatum",
      EG_AP_SDate_Error: "Bitte geben Sie das Startdatum ein.",
      EG_AP_EDate: "Enddatum",
      EG_AP_EDate_Error: "Bitte geben Sie das Enddatum ein.",
      EG_AP_WalletApp: "USDT Wallet-Adresse bei Easy Exchange Pro",
      EG_AP_WalletApp_Error: "Bitte geben Sie die USDT Wallet-Adresse ein.",
      EG_AP_Amount: "USDT Betrag",
      EG_AP_Amount_Error: "Bitte geben Sie den USDT Betrag ein.",
      EG_AP_VPD: "Gültigkeitsdauer in Tagen",
      EG_AP_VPD_Error: "Bitte geben Sie die Gültigkeitsdauer in Tagen ein.",
      EG_AP_Select_Conditional: "Bedingt",
      EG_AP_Select_Unconditional: "Unbedingt",
      EG_AP_Beneficiary: "Begünstigter:",
      EG_AP_BenUID: "Benutzer-ID des Begünstigten",
      EG_AP_BenUID_Error:
        "Bitte geben Sie die Benutzer-ID des Begünstigten ein.",
      EG_AP_BenEmail: "E-Mail-Adresse des Begünstigten",
      EG_AP_BenEmail_Error:
        "Bitte geben Sie die E-Mail-Adresse des Begünstigten ein.",
      EG_AP_WalletBen:
        "USDT-Wallet-Adresse des Begünstigten bei Easy Exchange Pro",
      EG_AP_WalletBen_Error:
        "Bitte geben Sie die USDT-Wallet-Adresse des Begünstigten ein.",
      // Fee
      Fee_title_1: "Maker-Aufträge",
      Fee_description_1:
        "Maker-Aufträge stellen Liquidität auf dem Markt her, indem sie in das Orderbuch eingetragen werden. Mit anderen Worten: Maker-Aufträge werden nicht sofort ausgeführt, sondern warten auf einen zukünftigen Auftrag, der dazu passt. Ein Maker-Auftrag kann entweder ein Kauf- oder ein Verkaufsauftrag sein. Wenn ein bereits vorhandener Auftrag im Orderbuch mit einem neu platzierten Auftrag (dem Taker) übereinstimmt, wird dem Maker-Auftrag in der Transaktion die Maker-Gebühr berechnet. Wenn ein neu eingegebener Auftrag nicht sofort mit vorhandenen Aufträgen übereinstimmt oder nicht vollständig von vorhandenen Aufträgen ausgeführt wird, wird der nicht ausgeführte Auftrag im Orderbuch platziert und wird zu einem Maker-Auftrag für einen zukünftigen Handel.",
      Fee_title_2: "Taker-Aufträge",
      Fee_description_2:
        "Taker-Aufträge verringern die Liquidität auf dem Markt. Taker-Aufträge werden sofort ausgeführt und nehmen Volumen aus dem Orderbuch. Ein Taker-Auftrag kann entweder ein Kauf- oder ein Verkaufsauftrag sein. Wenn ein neuer Auftrag platziert wird und er mit einem anderen bereits im Orderbuch vorhandenen Auftrag (dem Maker) übereinstimmt, wird dem Taker in der Transaktion die Taker-Gebühr berechnet.",
      Fee_title_3: "Transaktionsgebühren",
      Fee_subtitle_3_1: "Blockchain:",
      Fee_description_3_1:
        "Bei der Übertragung von Token auf der Blockchain vonund zu Easy Exchange Pro können spezifische Gebühren des Blockchain-Netzwerks anfallen.",
      Fee_description_3_2:
        "Easy Exchange Pro erhebt keine Gebühren für Einzahlungen. Bitte beachten Sie, dass einige Token oder Coins erfordern, dass wir Ihre Mittel vor der Gutschrift auf ein anderes Konto transferieren. Dies bedeutet, dass das Netzwerk der Münze oder des Tokens Ihnen die normale Transaktionsgebühr für den Geldtransfer in Rechnung stellt, die Easy Exchange Pro nicht umgehen kann.",
      Fee_description_3_3:
        "Easy Exchange Pro erhebt eine geringe Gebühr für Auszahlungen von Token oder Coins mit einer eingebauten Netzwerkübertragungsgebühr. Die Netzwerkgebühr ist sichtbar, indem Sie auf die Schaltfläche Abheben im Auszahlungsfenster für jeden Token oder Coin mit einer eingebauten Netzwerkübertragungsgebühr klicken. Die Netzwerkgebühr ist sichtbar, indem Sie auf die Schaltfläche 'Abheben' im Auszahlungsfenster für jeden Token oder Coin klicken.",
      Fee_table_1_col_1: "Stufe",
      Fee_table_1_col_2: "Handelsvolumen (BTC) in den letzten 30 Tagen",
      Fee_table_1_col_3: "Maker",
      Fee_table_1_col_4: "Taker",
      Fee_table_2_col_1: "Währungssymbol",
      Fee_table_2_col_2: "Währungsname",
      // Gabur
      //// Top
      GBR_top_title_1: "EASY EXCHANGE PRO",
      GBR_top_title_3: "Die Evolution der Finanzen",
      //// Second
      GBR_Second_title_1: "SICHERER SMART CONTRACT",
      GBR_Second_title_2: "Gabur Token Audit",
      GBR_Second_description:
        "Ein Smart Contract besteht aus speziellen Anweisungen, die in der Blockchain gespeichert sind. Dieser Vertrag kann gemäß einer Reihe vorprogrammierter Parameter mehrere Aktionen durchführen, und zwar auf eine unveränderliche, transparente und völlig sichere Weise. Daher wurde der Smart Contract für GABUR Tokens auf Sicherheit geprüft, um höchste Sicherheitsstandards zu gewährleisten.",
      GBR_Second_Security_btn: "Sicherheitsaudit",
      //// Description
      GBR_Description_title_1: "Beschreibung der Gabur Tokens",
      GBR_Description_list_1_1: "Nutzer-Token: GABUR",
      GBR_Description_list_1_2: "Symbol: GBR",
      GBR_Description_list_1_3: "Gesamtversorgung von GABUR Tokens: 550 Mio.",
      GBR_Description_list_1_4: "GABUR Tokens unterliegen keiner Erhöhung",
      GBR_Description_list_1_5:
        "GABUR Tokens werden nativ auf dem BEP20-Netzwerk gemäß dem BSC-Standard ausgeführt.",
      GBR_Description_list_1_6:
        "Token-Burn: 165 Mio. GABUR Tokens werden verbrannt",
      GBR_Description_title_2: "Verteilung der Gabur Tokens",
      GBR_Description_list_2_1: "Öffentlicher Verkauf",
      GBR_Description_list_2_2: "Easy Card Reserve",
      GBR_Description_list_2_3: "Team",
      GBR_Description_list_2_4: "Berater",
      GBR_Description_list_2_5: "Privatverkauf",
      GBR_Description_list_2_6: "Airdrop",
      //// Contract
      GBR_Contract_subtitle: "Gabur Token Vertragsadresse",
      //// Added
      GBR_Added_subtitle: "Gabur Token Plan",
      GBR_Added_title: "Welchen Mehrwert bietet Gabor Token?",
      GBR_Added_list_1:
        "Entwicklung von Easy Exchange Pro gemäß unserer Roadmap-Verpflichtung",
      GBR_Added_list_2:
        "Entwicklung innovativer Ideen für die Entwicklung der Easy Card, die sie zu einem bedeutenden Feature für Transaktionen macht",
      GBR_Added_list_3: "GABUR Tokens unterliegen keiner Erhöhung",
      GBR_Added_list_4:
        "Innovative Entwicklung mit dem Ziel, digitale Assets praktischer zu machen",
      GBR_Added_list_5: "Vierteljährliches Verbrennen von GABUR Tokens",
      GBR_Added_list_6: "Sperrfrist für Tokens",
      GBR_Added_list_7:
        "Möglichkeit, Benutzer willkommen zu heißen, die eine stabile und sichere Möglichkeit zur Abwicklung von Zahlungen in mehr als 60 Ländern suchen",
      GBR_Added_list_8:
        "Ein erfahrenes Team sowohl im Bereich der Kryptowährungen als auch im Finanzbereich mit umfangreichen internationalen Finanzverbindungen",
      GBR_Added_list_9:
        "Wir werden gemäß unserer Roadmap neue und einzigartige Produkte hinzufügen",
      // Login
      Login_title: "Willkommen bei Easy Pro",
      Login_subtitle: "Anmeldung, um fortzufahren",
      Login_btn_Loading: "Anmelden",
      Login_problem: "Problem bei der Anmeldung?",
      Login_click_here: "Klicken Sie hier",
      Login_register: "Klicken Sie hier, um sich zu registrieren",
      Login_protected: "Diese Seite ist durch reCAPTCHA und Google geschützt",
      Login_privacy: "Datenschutzerklärung",
      Login_and: "und",
      Login_terms: "Nutzungsbedingungen",
      Login_ResetModal_title:
        "Sie können Ihre 2FA oder Ihr Passwort zurücksetzen",
      Login_ResetModal_Reset2FA_btn: "2FA zurücksetzen",
      Login_ResetModal_ForgetPassword: "Passwort vergessen?",
      Login_ResetPassword_title:
        "Geben Sie Ihre E-Mail-Adresse ein, um einen Link zum Zurücksetzen des Passworts zu erhalten",
      Login_Reset2FA_title:
        "Geben Sie Ihre E-Mail-Adresse ein, um einen Link zum Zurücksetzen der 2FA zu erhalten",
      Login_OTP_title: "Geben Sie den empfangenen OTP ein",
      //// Reset Password
      ResetPassword_title: "Setzen Sie Ihr Passwort zurück",
      ResetPassword_NewPassword_input: "Neues Passwort",
      ResetPassword_ConfirmPassword_input: "Neues Passwort bestätigen",
      // Privacy And Policy
      Privacy_1_title: "INTRODUCTION",
      Privacy_1_desciption:
        "Thanks indeed for visiting Easyexchangepro.com, as a digital asset trading website. The purpose of this Privacy Policy is to inform you (user, customer) with an understanding that how Easyexchangepro (or we, or our, or us or platform) collect personal information from our clients and prospective clients to provide them with our products and services and ensure that we can meet their needs when providing these products and services, as well as when providing them with any respective information. This privacy policy applies to the Site and all Services offered by Easyexchangepro.",
      Privacy_2_title: "PERSONALLY IDENTIFIABLE",
      Privacy_2_desciption:
        "We may collect personally identifiable information from Users in a variety of ways, including, but not limited to, when Users visit our Site, register on the Site, place an order, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Site anonymously. When interacting with us on the Site, Users can always refuse to supply personal data to us, except that it may prevent them from engaging in certain Site related activities or transactions. We may collect non-personally identifiable information about Users when they interact with our Site.",
      Privacy_3_title: "WHAT INFORMATION DO WE COLLECT?",
      Privacy_3_desciption_1:
        "Easyexchangepro collects information about you when you use our websites (including the Site) and other online products and services and throughout other interactions and services, you have with us. We will collect your personal data in accordance with the legislative purpose of the DPA. We may also collect and store certain information automatically when you visit the Site. Examples include the internet protocol (IP) address used to connect your computer or device to the internet, connection information such as browser type and version, your operating system and platform, a unique reference number linked to the data you enter on our system, log in details, the full URL clickstream to, through and from the Site (including date and time), cookie number and your activity on our Site, including the pages you visited, the searches you made and, if relevant, the products/services you purchased.",
      Privacy_3_desciption_2:
        "We may receive information about you from third parties if you use any websites or social media platforms operated by third parties (for example, Facebook, Instagram, Twitter, etc.) and, if such functionality is available, you have chosen to link your profile on the Site with your profile on those other websites or social media platforms.",
      Privacy_4_title: "INDIVIDUAL AND COMPANY WHICH WE MAY COLLECT INCLUDE:",
      Privacy_4_subtitle_1: "For individual:",
      Privacy_4_list_1_1: "• Email address",
      Privacy_4_list_1_2: "• Mobile phone number",
      Privacy_4_list_1_3:
        "• Full legal name (including former name, and names in local language)",
      Privacy_4_list_1_4: "• Nationality",
      Privacy_4_list_1_5:
        "• Passport number, or any government-issued ID number",
      Privacy_4_list_1_6: "• Date of birth",
      Privacy_4_list_1_7:
        "• Proof of identity (e.g., passport, driver’s license, or government-issued ID)",
      Privacy_4_list_1_8: "• Residential address",
      Privacy_4_list_1_9: "• Proof of residency",
      Privacy_4_list_1_10:
        "• Additional Personal Data or documentation at the discretion of our Compliance Team",
      Privacy_4_subtitle_2: "For companies:",
      Privacy_4_list_2_1:
        "• Company legal name (including the legal name in local language)",
      Privacy_4_list_2_2: "• Incorporation/registration Information",
      Privacy_4_list_2_3:
        "• The full legal name of all beneficial owners, directors, and legal representatives",
      Privacy_4_list_2_4:
        "• Address (principal place of business and/or other physical locations)",
      Privacy_4_list_2_5: "• Proof of legal existence",
      Privacy_4_list_2_6: "• Description of the business",
      Privacy_4_list_2_7:
        "• Percentage of ownership for Individual/company owners",
      Privacy_4_list_2_8:
        "• Contact information of owners, principals, and executive management (as applicable)",
      Privacy_4_list_2_9:
        "• Proof of identity (e.g., passport, driver’s license, or government-issued ID) for the significant individual beneficial owner of the institutional customer entity",
      Privacy_4_list_2_10:
        "• Personal Data for each entity’s significant beneficial owner of the institutional customer entity (see the “Individual Customer” section above for details on what Personal Data we collect for individuals)",
      Privacy_4_list_2_11: "• Source of wealth",
      Privacy_4_list_2_12:
        "• Amount of bitcoin or other digital assets projected to be injected",
      Privacy_5_title: "COOKIES USAGE",
      Privacy_5_desciption:
        "When you access our Sites and Services, we or companies we work with may place cookies on your computer or another device. Of course, you can block or deactivate cookies in your browser settings. But these technologies help us better understand user behavior and inform us about which parts of our websites people have visited. We use first-party and third-party cookies to recognize you as an Easyexchangepro user, customize Coinbase Services, content, and advertising, measure promotional effectiveness, and collect information about your computer or another access device to mitigate risk, helping prevent fraud, and promote trust and safety. We may place cookies from third-party service providers who may use information about your visits to other websites to target advertisements for products and services available from Easyexchangepro. We do not control the types of information collected and stored by these third-party cookies. You should check the third-party's website for more information on how they use cookies.",
      Privacy_6_title: "LAWFUL BASIS FOR PROCESSING YOUR PERSONAL INFORMATION",
      Privacy_6_desciption_1:
        "We will process your personal information on the following bases and for the following purposes:",
      Privacy_6_desciption_2:
        "carrying out your instructions or responding to any inquiry given by (or purported to be given by) you or on your behalf including responding to your inquiries and complaints, or responding to or dealing with your interactions with us; registering you as a customer of Easyexchangepro and/or to deal with, process and/or administer the account that you may open with us, whether a membership account or otherwise, including to facilitate your transactions or activities on the Site or your transactions or activities with us; administering, facilitating, processing and/or dealing with your relationship with us, any transactions or activities carried out by you on the Site or with us. This includes processing your application, orders, and payment transactions; implementing transactions and the supply of products and/or services to you that you have requested; administering, facilitating, processing, and/or dealing in any matters relating to your use or access of the Site, including identifying you for login to the Site, our portals and other online services provided by or on behalf of us; monitoring, processing and/or tracking your use of the Site in order to provide you with a seamless experience, facilitating or administering your use of the Site, and/or to assist us in improving your experience in using the Site; assessing and processing your request for the purchase of and/or subscription to our products and/or services. contacting you or communicating with you via phone/voice call, text message and/or fax message, email and/or postal mail for the purposes of administering and/or managing your use of the Site, your membership and/or account with us, your relationship with us or any transactions made by you with us.",
      Privacy_7_title: "HOW WE USE YOUR PERSONAL DATA",
      Privacy_7_desciption_1:
        "Easyexchangepro uses Personal Data to communicate with you and to administer, deliver, improve, and personalize the Service. Easyexchangepro might also generate generic data out of any Personal Data we collect and use it for our own purposes. We may also use such data to communicate with you in relation to other products or services offered by Easyexchangepro and/or its partners. We do not share your Personal Data with third parties (other than partners in connection with their services to Easyexchangepro) except where you have given your consent and further detailed below.",
      Privacy_7_desciption_2:
        "We may share your Personal Data with third parties:",
      Privacy_7_desciption_3:
        "If we think that sharing it is necessary to enforce the Terms of Service. To comply with government agencies, including regulators, law enforcement, and/or justice departments. To third parties who provide services to Easyexchangepro (such as administration or technical services). In connection with the sale or transfer of our business or any part thereof.",
      Privacy_7_desciption_4:
        "Additionally, we have implemented international standards to prevent money laundering, terrorist financing and will implement final Digital Asset rules and regulations when effective, which will likely require us to undertake due diligence on our customers. This may include the use of third-party data and service providers which we will cross-reference with your personal information.",
      Privacy_8_title: "STORING YOUR PERSONAL DATA",
      Privacy_8_desciption:
        "The data that we collect from you may be transferred to and stored at, a destination outside of the U.A.E. It may also be processed by staff operating outside of U.A.E who work for us or for one of our suppliers. By submitting your personal data, you agree to this transfer, storing, or processing, except for customers located in the EEA, as detailed above. All information you provide to us is stored on our and/or third-party cloud servers",
      Privacy_9_title: "MARKETING COMMUNICATIONS",
      Privacy_9_desciption:
        "We may use your personal information to send you marketing communications by email or other agreed forms (including social media campaigns), to ensure you are always kept up to date with our latest products and services. If we send you marketing communications, we will do so based on your consent and registered marketing preferences. Users can opt out of these marketing communications at any moment. If you do not want to receive these communications, please send an email to support@easyexchangepro.com",
      Privacy_10_title: "DATA SECURITY",
      Privacy_10_desciption:
        "We endeavor to protect Easyexchangepro and you from unauthorized access, alteration, disclosure, or destruction of Personal Data we collect and store. We take various measures to ensure information security, including encryption of the Easyexchangepro communications with SSL; required two-factor authentication for all sessions; periodic review of our Personal Data collection, storage, and processing practices; and restricted access to your Personal Data on a need-to-know basis for our employees and vendors who are subject to strict contractual confidentiality obligations.",
      Privacy_11_title:
        "TRANSFER OF PERSONAL INFORMATION OUTSIDE OF THE EEA and UK and YOUR COUNTRY",
      Privacy_11_desciption:
        "We may transfer your personal information outside the EEA and UK to other Company subsidiaries, service providers, and business partners (i.e., Data Processors) who are engaged on our behalf. To the extent that we transfer your personal information outside of the EEA and UK, we will ensure that the transfer is lawful and that Data Processors in third countries are obliged to comply with the European Union (EU) General Data Protection Act 2016 and the UK Data Protection Act 2018. Also, by using our products and services, you consent to your Personal Data being transferred to other countries, including countries that have differing levels of privacy and data protection laws than your country. In all such transfers, we will protect your personal information as described in this Privacy Notice and ensure that appropriate information sharing contractual agreements are in place.",
      Privacy_12_title: "COMPLIANT PROCESS",
      Privacy_12_desciption:
        "If you have any complaint or grievance regarding how we are handling your personal data or about how we are complying with the CPT (when in force), we welcome you to contact us with your complaint or grievance and should be addressed to CPT@Easyexchangepro.com Where you are sending an email in which you are submitting a complaint, your indication at the subject header that it is a CPT complaint would assist us in attending to your complaint speedily by passing it on to the relevant staff in our organization to handle. We will certainly strive to deal with any complaint or grievance that you may have speedily and fairly.",
      Privacy_13_title: "YOUR ACCEPTANCE",
      Privacy_13_desciption:
        "By using Easyexchangepro Site, you signify your acceptance of this policy and Terms of Use.",
      Privacy_14_title: "CHANGES TO THIS PRIVACY POLICY",
      Privacy_14_desciption:
        "Any changes we may make to our Privacy Policy in the future will be posted on this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.",
      // Product Convert
      Convert_title: "Umwandeln",
      Convert_for_each: "Für jeden",
      Convert_receive: "erhalten Sie",
      Convert_calc: "Umwandlung berechnen",
      Market_tab_Spot: "Spot",
      Market_tab_Favorite: "Favorit",
      OTC_title: "Easy Pro OTC-Schalter",
      OTC_description:
        "Händler, die Kryptowährungen austauschen möchten, insbesondere in großen Mengen, entscheiden sich in der Regel für den OTC-Handel (Over-the-Counter) mit Kryptowährungen. Der Easy Pro OTC-Schalter bietet Ihnen diskrete, sichere und wettbewerbsfähige Ausführungs- und Abwicklungsdienstleistungen.",
      OTC_Feature_1:
        "Wir führen OTC-Geschäfte vertraulich durch, ohne signifikante Auswirkungen auf die Märkte.",
      OTC_Feature_2:
        "Unser Handelsschalter respektiert die Privatsphäre mit allen Sicherheitsprotokollen auf Easy Pro.",
      OTC_Feature_3:
        "Die Abwicklung erfolgt innerhalb des Easy Pro-Ökosystems und wird in der Regel abgeschlossen.",
      OTC_Feature_4:
        "Die Abwicklung wird innerhalb weniger Minuten abgeschlossen sein.",
      OTC_Feature_5: "Es gibt keine Drittanbieter an unserem Schalter.",
      OTC_Feature_6:
        "Der Handel erfolgt über das Easy Pro-Konto, daher müssen Sie keine neue Brieftasche erstellen.",
      OTC_Feature_7:
        "Die Münzen/Token, die Sie handeln, werden einfach manuell von unserem Team in Ihr Konto übertragen.",
      OTC_Feature_8:
        "Handelsdetails finden Sie nur im Abschnitt „Verteilungsverlauf“ in Ihrem Konto.",
      OTC_Support_title: "Unterstützung OTC",
      OTC_Support_description:
        "Der OTC-Schalter bietet weltweite Abdeckung und ist rund um die Uhr geöffnet, mit Ausnahme von Feiertagen OTC@Easyexchangepro.com",
      OTC_list_1: "Bitte füllen Sie das OTC-Formular aus und senden Sie es uns",
      OTC_list_2:
        "Unser Team steht Ihnen zur Angebotserstellung, zum Handel und zur Abwicklung zur Verfügung",
      OTC_list_3:
        "Sobald Sie unser Angebot akzeptieren, wird der Handel bestätigt und unser Team sendet Ihnen per E-Mail Details und Anweisungen zum Handel.",
      OTC_list_4:
        "Wenn wir Ihren Teil des Geschäfts erhalten, wird der OTC-Schalter unsere Abwicklung in Ihre Brieftasche oder auf Ihr Bankkonto vornehmen",
      // Product Trade
      Trade_KYC_Error: "Bitte laden Sie Ihre KYC-Unterlagen hoch",
      Trade_Trading_password_Error: "Bitte aktivieren Sie das Handelspasswort",
      Trade_Trading_password_title: "Geben Sie Ihr Handelspasswort ein",
      Trade_Trading_password_input: "Handelspasswort",
      Trade_Trading_Password_btn: "Handelspasswort eingeben",
      Trade_goto_security: "Zur Sicherheit gehen",
      Trade_FAV: "FAV",
      Trade_Open_Orders: "Offene Aufträge",
      Trade_Order_history: "Auftragsverlauf",
      Trade_Trade_history: "Handelsverlauf",
      Trade_Date_Time: "Datum & Uhrzeit",
      Trade_Type: "Typ",
      Trade_Action: "Aktion",
      Trade_Login_Register: "Anmelden/Registrieren",
      Trade_Password_Error: "Bitte geben Sie Ihr Passwort ein.",
      Trade_Balance_Error: "Unzureichendes Guthaben, Sie haben",
      Trade_Min_Error: "Der Auftrag muss höher als 20 USDT sein",
      Trade_approximately: "Sie erhalten ungefähr",
      Trade_Market: "Markt",
      Trade_Order_List: "Auftragsliste",
      Trade_Order_History: "Auftragsverlauf",
      Trade_Pairs: "Paare",
      Trade_Open_Chart: "Chart öffnen",
      Profile_profile: "Profil",
      Profile_security: "Sicherheit",
      Profile_history: "Verlauf",
      Profile_Security_log_history: "Sicherheitsprotokoll-Verlauf",
      Profile_Login_history: "Anmeldeverlauf",
      Profile_User_info_title: "Benutzerinformationen",
      Profile_User_info_description:
        "Ihre eindeutigen Kontoinformationen, die Sie sicher aufbewahren müssen.",
      Profile_UID: "Benutzer-ID",
      Profile_Sec_info_title: "Sicherheitsinformationen",
      Profile_Activated: "Aktiviert",
      Profile_Disabled: "Deaktiviert",
      Profile_Trading_Password: "Handelspasswort",
      Profile_Account_status_title: "Kontostatus",
      Profile_KYC: "KYC",
      Profile_Sec_level: "Sicherheitsstufe",
      Profile_Airdrop_point: "Airdrop-Punkte",
      Profile_Referrals_title: "Empfehlungen",
      Profile_Num_Referrals: "Anzahl der Empfehlungen",
      Profile_Referral_ID: "Empfehlungs-ID",
      Profile_Referral_List_btn: "Empfehlungsliste",
      Profile_Commissions_btn: "Provisionen",
      Profile_User: "Benutzer",
      Profile_KYC_Verified: "Verifiziert",
      Profile_KYC_Pending: "Ausstehend",
      Profile_KYC_Rejected: "Abgelehnt",
      Profile_KYC_NotUploaded: "Nicht hochgeladen",
      Profile_Sec_lvl_Medium: "Mittel",
      Profile_Sec_lvl_VeryHigh: "Sehr hoch",
      Profile_Account_Password_title: "Kontopasswort",
      Profile_Account_Password_description:
        "Hier können Sie Ihr Passwort ändern.",
      Profile_Change_Password_btn: "Passwort ändern",
      Profile_Change_your_password_title: "Ändern Sie Ihr Passwort",
      Profile_Current_Password: "Aktuelles Passwort",
      Profile_New_Password: "Neues Passwort",
      Profile_Confirm_New_Password: "Neues Passwort bestätigen",
      Profile_Sec_Authenticaton_title: "Zweifaktor-Authentifizierung",
      Profile_Sec_Authenticaton_description:
        "Hier können Sie Ihre 2FA aktivieren/deaktivieren.",
      Profile_Active_2FA: "2FA aktivieren",
      Profile_Activating_2FA: "2FA aktivieren",
      Profile_Disable: "Deaktivieren",
      Profile_Disable_2FA: "2FA deaktivieren",
      Profile_2FA_Code: "2FA-Code",
      Profile_Active: "Aktiv",
      Profile_Trading_Password_description:
        "Hier können Sie Ihr Handelspasswort ändern.",
      Profile_Change_trading_password: "Handelspasswort ändern",
      Profile_Change_your_Trading_password_title:
        "Ändern Sie Ihr Handelspasswort",
      Profile_Change_your_Trading_password_description:
        "Möchten Sie Ihr Handelspasswort ändern?",
      Profile_forgot_code: "Code vergessen",
      Profile_KYC_title: "Persönliche Kontoverifizierung (KYC)",
      Profile_KYC_select_doc: "Wählen Sie Ihren Dokumententyp",
      Profile_KYC_select_doc_ID: "Ausweis",
      Profile_KYC_select_doc_pass: "Pass",
      Profile_KYC_select_doc_Drive: "Führerschein mit Ausweisnummer",
      Profile_KYC_Upload_title: "Bitte laden Sie Ihre Dokumente hoch:",
      Profile_KYC_Front: "Vorderseite des Dokuments",
      Profile_KYC_Back: "Rückseite des Dokuments",
      Profile_KYC_Selfie: "Selfie mit Dokument in Ihrer Hand",
      Profile_KYC_Approved: "Ihre KYC-Verifizierung wurde bereits genehmigt.",
      Profile_KYC_Pending: "Ihre KYC-Verifizierung steht aus.",
      Profile_Currency: "Währung",
      Profile_Type: "Typ",
      Profile_Login: "Anmeldung",
      Profile_Region: "Region",
      Profile_Login_device: "Anmeldegerät",
      // Register
      Reg_verify_email: "E-Mail bestätigen",
      Reg_enter_otp: "OTP eingeben",
      Reg_Confirm_Password: "Passwort bestätigen",
      Reg_Referral_Code: "Empfehlungscode (optional)",
      // Terms Of Use
      Term_1_title: "1-Welcome to Easy Pro",
      Term_1_description_1:
        "Thanks indeed for visiting (Easyexchangepro / we, hereinafter referred to as the Easy pro) By visiting, accessing, or using easyexchangepro.com and associated application program interface or mobile applications (platforms), You (“User” or “You”) consent to the Terms of Service (the “Terms”), so please read them carefully.",
      Term_1_description_2:
        "The Terms constitute the agreement and understanding regarding the use of any or all the Services, and any manner of accessing them, between You and the following service provider (“we,” “us” or “our” platform) Easyexchangepro.",
      Term_2_title: "2-Key Definitions",
      Term_2_description_1:
        "Capitalized terms not otherwise defined in these Terms will have the following meaning:",
      Term_2_description_2:
        "“External Account” means any Digital Token Account: from which you may transfer funds into your Easy Pro  Account, or to which you may transfer funds from your Easy Pro Account and which has been approved by Easy pro for the foregoing purposes.",
      Term_2_description_3: "“Funds” means Digital Tokens.",
      Term_2_description_4: "“Fiat Currency” means any national currency.",
      Term_2_description_5:
        "“Easy Pro Account” means a user account accessible via the Services where Funds may be stored by Easy pro on behalf of a user.",
      Term_2_description_6:
        "“Easy Pro Help Center” means Easy pro’s customer service center with address at Support@easyexchagepro.com -us where you could get online support from Easy pro.",
      Term_2_description_7:
        "“Digital Tokens” means Bitcoins, Ripples, and other digital mediums of exchange that may be purchased, sold, or traded via the Services.",
      Term_2_description_8:
        "“Digital Token Account” means any Digital Token address or account owned or operated by you that is maintained outside of the Services, and is not owned, controlled, or operated by Easyexchangepro.",
      Term_3_title: "3- Accept terms",
      Term_3_description_1:
        "You should read the Terms, and any document referred to in them very carefully. If there is anything that You do not understand in the Terms and any document referred to herein, you should discuss this matter with us and seek the necessary clarification.",
      Term_3_description_2:
        "You are at least 18 years of age and have the full capacity to accept these Terms and enter a transaction involving digital assets. You have not been deprived of the right to use our service and have the full capacity for legal action. If you do not meet the above condition, please do not register our Site or Platform, otherwise, the platforms may suspend or terminate your account at any time.",
      Term_3_description_3:
        "You’re entering and performing these Terms are not prohibited by the laws and regulations of the country or region to which you belong, reside, pay tax or carry out business activities or other business. If you do not meet the above conditions, you should immediately terminate the registration or stop using our Platforms services.",
      Term_3_description_4:
        "Our Platform has the right to modify these Terms from time to time or to formulate and modify various specific rules according to these Terms and publish them in the relevant system sections of Platforms without separately notifying you.",
      Term_3_description_5:
        "You should pay attention to the changes in these Terms and the specific rules from time to time. If you continue to use the Service after the changes in the content of these Terms and the specific rules, you are deemed to have fully read, understood, and accepted the amended Terms and the specific rules and to use the services of our Platforms in accordance therewith.",
      Term_3_description_6:
        "You also agree that Easy Pro may, by giving notice, at its sole discretion immediately terminate your access to our Platform and to your account. You agree and acknowledge our right to limit, suspend or terminate the service and your user account, prohibit your access to our Platform and their content, services, and tools, delay or remove hosted content, and take technical and legal steps to keep you off our Platform or any further steps to recover any loss or damages caused if we determine at our sole discretion that you are or may be in breach of any laws, regulations, the rights of third parties, or any of these Terms or Platform’s policies. Easy Pro shall not be liable for any loss or damage caused to you or any third party because of exercising our rights under this clause.",
      Term_4_title: "4- Our Trading Services",
      Term_4_description:
        "Easy Pro provides an online platform with a simple and convenient way to trade one type of digital asset or another type of digital asset. Easy Pro provides you with a platform that matches your orders with open orders from other users of our Services.",
      Term_4_subheader_1: "Spot.",
      Term_4_subtitle_1: "Market Order:",
      Term_4_description_1:
        "A Market Order is placed to buy or sell a Specified Quantity or a Specific Gross Amount of Targeted tokens at the best available Latest Market Price.",
      Term_4_subtitle_2: "Limit Order:",
      Term_4_description_2:
        "A Limit Order is placed to buy or sell a Specific Quantity of the Targeted Token at a Specific Price and shall be filled at such Specific Quantity and Specific Price.",
      Term_4_subtitle_3: "Stop Market Order:",
      Term_4_description_3:
        "A Stop Market Order is placed to buy a Specific Quantity of Targeted Token or to buy a Specific Gross Amount of Targeted Token at the Latest Market Price when the upper limit of the Preset Stop Price is triggered. This means that when the Latest Market Price reach or beyond the upper limit of the Preset Stop Price, this order will be executed and filled at the Latest Market Price which may end up higher than the upper limit of the Preset Stop Price.",
      Term_4_subtitle_4: "Stop Limit Order:",
      Term_4_description_4:
        "A Stop Limit Order is placed to sell a Specific Quantity of Targeted Token at the Specific Price when the inferior limit of Preset Stop Price is triggered. This means that when the Latest Market Price reaches or is below the inferior limit of the Preset Stop Price, this order will be executed and filled at the Specific Quantity as well as the Specific Price.",
      Term_4_subheader_2: "Easy card",
      Term_4_description_5:
        "Refers to transactions in which Gabur tokens are exchanged for fiat currency and deposited in the account of the user.",
      Term_5_title: "5- Scope of Service and Your Rights and Obligations",
      Term_5_description_1:
        "Users shall not register multiple accounts for any purpose. Users may not use another User’s accounts. Users are prohibited in any form from utilizing the Site to engage in illegal activities. Without the authorization or permission of Easy Pro, the User shall not use any of the commercial activities in the name of this Site or in any form of the Site as a place, platform, or medium for engaging in commercial activities.",
      Term_5_description_2:
        "Users shall comply with all laws and regulations and bear the responsibility and legal consequences of any of their own actions involving the Site and Services.",
      Term_5_description_3:
        "Users have the right to view the information regarding their accounts on our Platform and have the right to operate the functions provided by our Platform. The user understands and agrees that our Platform can adjust the service content, service type, and service form on the Platform at any time according to the actual situation at our sole discretion. Our Platform is not responsible for any negative impact or loss caused to you or any third party due to Platform’s adjustments.",
      Term_5_description_4:
        "Depending on your country of residence, you may not be able to use all the functions of the Site or the Platform. Without prejudice to our right of suspending certain services to users from a specific country, it is your responsibility to ensure that in accessing our Site, Platform, and services, you are complying with the laws and regulations in your country of residence and/or the country from which you access this Site, the Platform, and our services. you are responsible for any disputes or losses caused by your failure to promptly modify or confirm the status of the transaction or failure to submit the relevant application. Our Platforms do not assume any responsibility.",
      Term_5_description_5:
        "Engaging in trades in cryptographic tokens may be highly risky. Please do not use Easy Pro’s services for trading in Digital assets if you do not understand these risks.",
      Term_6_title: "6- Registration Process",
      Term_6_description_1:
        "You agree to provide a valid email address, a mobile phone number, and other information in accordance with the requirements on the user registration page of this platform. You can use the email address, mobile phone number or any other manner permitted to log in to this platform. Where it is necessary and in accordance with the requirements of applicable laws and regulations of relevant jurisdictions, you shall provide your real name, identity card, and other information required by applicable laws, regulations, the Terms of Privacy, and anti-money-laundering terms, and constantly update your registration data so that they will be timely, detailed, and accurate as is required. All the original typed data will be referenced as registration information. You shall be responsible for the authenticity, integrity, and accuracy of such information and bear any direct or indirect loss and adverse consequences arising out of it.",
      Term_6_description_2:
        "If any of the applicable laws, regulations, rules, orders, and other regulatory documents of the sovereign country or region in which you are based requires that mobile phone accounts must be based on real names, you hereby confirm that the mobile phone number you provide for registration purposes has gone through the real-name registration procedure. If you cannot provide such a mobile phone number as is required, any direct or indirect losses and adverse consequences arising therefrom and affecting you shall be borne by you.",
      Term_6_description_3:
        "After you provide the required registration information in a legal, complete, and valid manner and such information passes relevant verification, you shall have the right to obtain an account and a password of Easy exchange pro. Upon obtaining such an account and password, your registration shall be deemed as successful, and you can log into the platform as a member thereof. You agree to receive emails and/or short messages sent by the platform related to the management and operation thereof.",
      Term_6_description_4:
        "Particularly during periods of high volume, illiquidity, fast movement, or volatility in the marketplace for any, or one or more Digital Tokens, the actual market rate at which a trade is executed may be different from the prevailing rate indicated via the Services at the time of your order or trade. You understand that we are not liable for any such price fluctuations. In the event of a market disruption or Force Majeure event, Easy exchange pro may do one or more of the following: suspend access to the Services; or prevent you from completing any actions via the Services, including closing any open positions. Following any such event, when trading resumes, you acknowledge that prevailing market rates may differ significantly from the rates available prior to such an event.",
      Term_6_description_5:
        "You hereby authorize Easy exchange pro to attempt and/or perform any such correction, reversal, cancellation, voiding, or amendment described in this section. Easy exchange pro. provides no guarantee or warranty that any such attempt will be successful and will have no responsibility or liability for the Manifest Error or the making or failure of any correction attempt, except if caused by the gross negligence, willful default, or fraud of Easy exchange pro. There is a risk that you may be prejudiced by any action or omission of Easy exchange pro in this regard, and you accept that you have no recourse whatsoever against Easy exchange pro.",
      Term_7_title: "7- Fees",
      Term_7_description_1:
        "You agree to pay for transactions through our Fees Structure service, which may change from time to time. Changes to the Fee Structure are effective as of the effective date indicated in the posting of the revised Fee Structure to the Services and will apply prospectively to any trades that take place following the effective date of such revised Fee Structure.",
      Term_7_description_2:
        "In addition to the costs, your external account may incur costs in connection with your use of your designated external account through the Services. Costs incurred by your external account provider are not reflected on the Transactions page, which contains information about applicable costs. You are solely responsible for paying any fees imposed by an External Account provider.",
      Term_7_description_3:
        "You authorize us, or our designated payment processor, to charge or deduct your Easy Pro Account Funds for any applicable Fees owed in connection with trades you complete via the Services.",
      Term_8_title: "8- Communication",
      Term_8_description_1:
        "You agree and consent to receive electronically all communications, agreements, documents, receipts, notices, and disclosures (collectively, “Communications”) that Easy Pro provides in connection with your Easy Pro Account and/or use of the Easy Pro Services.",
      Term_8_description_2:
        "Updating Contact Information. It is your responsibility to keep your email address on file with Easy Pro up to date so that Easy exchange pro can communicate with you electronically.",
      Term_9_title: "9- Intellectual Property",
      Term_9_description:
        "Unless otherwise indicated by us, all copyright and other intellectual property rights in all content and other materials contained on our website/platform or provided in connection with the Services, including, without limitation, the Easy Pro logo and all designs, text, graphics, pictures, information, data, software, sound files, other files and the selection and arrangement thereof are the proprietary property of Easy Pro or our licensors or suppliers and are protected by international copyright laws and other intellectual property rights laws.",
      Term_10_title: "10- Unclaimed or Disputed Property",
      Term_10_description_1:
        "If for any reason Easy Pro is holding Funds in your Easy  Account, such Funds remain unclaimed, and Easy Pro is unable to return your Funds to your designated External Account after a period of three years after your last login to Easy Pro or any specified period notified by Easy Pro, and Easy Pro determines in good faith that it is not able to trace you, you agree that all such Funds shall be deemed to have been abandoned by you in favor of Easy Pro and may be appropriated by Easy Pro to and for itself, and you thereafter shall have no right to claim such Funds.",
      Term_10_description_2:
        "If Easy Pro receives notice that any Digital Tokens held in your Easy Pro Account are alleged to have been stolen or otherwise are not lawfully possessed by you, Easy Pro may, but has no obligation to, place an administrative hold on or freeze the affected Digital Tokens or your Easy Pro Account. If Easy Pro does place an administrative hold on or freeze some or all your Digital Tokens, Easy Pro may continue such hold until such time as the dispute has been resolved and evidence of the resolution acceptable to Easy Pro has been provided to Easy Pro in a form acceptable to Easy Pro. Easy Pro will not involve itself in any such dispute or the resolution of the dispute. You agree that Easy Pro will have no liability or responsibility for any losses, damages, or prejudice arising from or in connection with such hold or freeze, or for your inability to withdraw Digital Tokens or execute trades during the period of any such hold or freeze.",
      Term_11_title: "11- Complains about your local laws",
      Term_11_description:
        "It is your sole responsibility to determine whether, and to what extent, any taxes apply to any transactions You conduct through the Services, and to withhold, collect, report, and remit the correct amounts of taxes to the appropriate tax authorities. When using our Services, you acknowledge that your actions are in a legal and proper manner and your financial sources are not from illegal activities. We may discretionarily or in coordination with local law enforcement authorities seize, restrict or close out your account, fiat, and/or cryptocurrencies.",
      Term_12_title: "12- Suspension; Termination",
      Term_12_description_1:
        "In the event of any Force Majeure Event breach of these. Terms or Conditions, or any other event where the provision of services to Easy pro is commercially unreasonable, we may, at our sole discretion and without liability to you, with or without prior notice, grant you access to all or part of Suspend it. We may also immediately, without notice, terminate your access to the Services at our sole discretion and delete or deactivate your Easy exchange pro account and all related information and files on this account without liability to you, including in cases where you are violating one of these conditions. In the event of discontinuation of all Services or termination of your access to the Services or deletion or deactivation of your Easy Pro Account: all amounts payable by you to Easy Pro will immediately become due. the platform can cancel any open orders or other transaction requests that are pending at the time of discontinuation or termination or deletion or deactivation. the platform will, where possible, return any Funds stored in your Easy Exchange pro Account not otherwise owed to Easy Pro and/or will use commercially reasonable efforts to provide you with a period 4 months to transfer affected Digital Tokens from your Easy Pro Account, unless prohibited by applicable laws or regulations or by order of law enforcement or governmental authority, or Easy Pro believes you have committed fraud, negligence or other misconduct; and/or may take such other steps as Easy Pro deems necessary or desirable to protect its own interests. We are not and shall not be responsible or liable for any loss or damages incurred because of or arising from any actions taken under this section.",
      Term_12_description_2:
        "Also, if the user has not made any deposits, transactions, or logins for a period of three months by any reason, we may delete or deactivate the user's account, without notifying the User and without assuming any responsibility",
      Term_13_title: "13- Descramble of liability",
      Term_13_subtitle_1: "13.1 Network Service Provider:",
      Term_13_description_1:
        'as a third-party platform for "network service provider", does not guarantee the information and services provided on this Site can fully meet the needs of users. We shall not be liable for errors, insults, defamation, nonfeasance, obscenity, pornography, or blasphemy that may occur during the process of acceptance of the Parties’ web service.',
      Term_13_subtitle_2: "13. 2 Service Interruption:",
      Term_13_description_2:
        "Based on the special nature of the Internet, we do not guarantee that the service will not be interrupted; the timeliness and security of the service are also not guaranteed.",
      Term_13_subtitle_3: "13.3 Safety of the Network:",
      Term_13_description_3:
        "We try to provide a safe network environment to the users; however, we do not guarantee that the Site or its servers are free of viruses or other potentially harmful factors; therefore, the user should use the industry’s recognized software to check and kill any virus in the files downloaded from the Site.",
      Term_13_subtitle_4: "13. 4 User Information:",
      Term_13_description_4:
        "we are not responsible for the failure of preservation, modification, deletion, or storage of the information provided by the user. Nor will we be liable for the typographical errors, negligence, etc. We have the right but no obligation to improve or correct any omission, error of any part of this Site.",
      Term_13_subtitle_5:
        "13. 5 Accuracy, Completeness, and Reliability of the Site:",
      Term_13_description_5:
        "Unless we have expressly agreed in writing, we shall not guarantee the accuracy, completeness, reliability of any content, such as but not limited to, advertising from the platform in any manner (including but not limited to, containing, connecting, by way of, or downloading) from the Site; we are not responsible for any products, services, information or materials purchased or obtained by the user according to the content information on this website. The user bears the risk of using the content of this website.",
      Term_13_subtitle_6: "13. 6 User Opinion:",
      Term_13_description_6:
        "The user comments published by users of the Site, are only on behalf of the user’s personal point of view. It does not mean that this Site agrees with their views or confirms their description. This Site does not bear any legal responsibility caused by any user comments.",
      Term_13_subtitle_7: "13. 7 Announcements:",
      Term_13_description_7:
        "Regarding notices issued to the users, we will deliver these notices through a formal page announcement, station letter, e-mail, customer service phone call, SMS, or regular mail delivery. We do not bear any legal responsibility for any winning, discount activities or information that are delivered by other channels.",
      Term_13_subtitle_8:
        "13. 8 Charges, Commissions, Transactions, and Other Fees:",
      Term_13_description_8:
        "we have the right to adjust the recharge, commission, transaction, and other fees according to market conditions, and terminate any promotional efforts early.",
      Term_14_title: "14- Jurisdiction",
      Term_14_description:
        "These Terms shall be governed by and construed in accordance with the laws of Dubai. The courts of Dubai shall have exclusive jurisdiction to settle any dispute arising from or connected with these Terms (including a dispute relating to the existence, validity, or termination of these Terms or the consequences of their nullity or any non-contractual obligation arising out of or in connection with these Terms).",
      Term_15_title: "15. Miscellaneous",
      Term_15_description_1:
        "These Terms set forth the complete understanding of the parties with respect to the subject matter hereof and supersedes all prior understandings and communications relating thereto our Platforms reserve the right of final interpretation of these Terms within the scope permitted by law. These Terms and related pages of our Platforms may refer to each other. If there is any conflict, these Terms shall prevail. In addition, if some of the provisions in these Terms are deemed invalid or unenforceable, the other provisions in these Terms will remain in effect. In the event of any conflict between these Terms and any other agreement, you may have with Easy Exchange pro the terms of that other agreement will prevail only if these Terms are specifically identified and declared to be overridden by such other agreement.",
      Term_15_description_2:
        "Any failure or delay by Easy Exchange Pro to enforce any of these Terms or to exercise any right hereunder shall not be construed as a waiver to any extent of our rights.",
      Term_16_title: "16- Limitation of Liability",
      Term_16_description:
        "Except as otherwise required by law, in no event shall Easy exchange pro, our directors, officers, employees, agents, Brokers or contractors be liable for any special, indirect, or consequential damages, or any other damages of any kind, including but not limited to loss of use, loss of profits or loss of data, whether in an action in contract, tort (including but not limited to negligence) or otherwise, arising out of or in any way connected with the use of or inability to use our Services or the Easy exchange pro Materials, including without limitation any damages caused by or resulting from reliance by any user on any information obtained from Easy exchange pro , or that result from mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses, delays in operation or transmission or any failure of performance, whether or not resulting from a force majeure event, communications failure, theft of, destruction of or unauthorized access to Easy exchange pro’s records, programs or services. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the limitations of this section may not apply to you.",
      Term_17_title: "17- Miscellany",
      Term_17_subtitle_1: "17 -1 Agency",
      Term_17_description_1:
        "Nothing in this Agreement shall be deemed to have created, implied, or otherwise treated us as your agent, trustee, or another representative unless it is provided otherwise in this Agreement.",
      Term_17_subtitle_2: "17- 2 Waiver",
      Term_17_description_2:
        "Our or your waiver of the right to hold the other party liable for breaches of agreement or any other liability as is agreed upon in this Agreement shall not be construed or deemed as a waiver of the right to hold the other party for other breaches of contract; a failure to exercise any right or remedy shall not be construed in any way as a waiver of such right or remedy.",
      Term_17_subtitle_3: "17- 3 Goodwill",
      Term_17_description_3:
        "You acknowledge and agree that, before filing any dispute or claim, you will contact us through support@easyexchangepro.com to solve the dispute in an informal way where we will try to solve the relevant dispute internally as soon as possible; The Parties agree to negotiate and solve any dispute in good faith (such negotiation shall be confidential and protected under applicable rules, and will not be used as evidence in any proceeding).",
      Term_17_subtitle_4: "17- 4 Assignment of rights",
      Term_17_description_4:
        "The rights and obligations agreed in this Agreement shall be equally binding on the assignees, the heirs, executors, and administrators of the parties hereto who benefit from the rights and obligations. Without our consent, you may not transfer to any third party any of your rights or obligations hereunder, provided, however, we may, at any time, assign our rights and obligations under this Agreement to any third party with forty (40) days' notices to you.",
      Term_17_subtitle_5: "17- 5 Severability",
      Term_17_description_5:
        "The invalidity or unenforceability of any of these Terms shall not affect the validity or enforceability of any other of these Terms, all of which shall remain in full force and effect.",
      Term_17_subtitle_6: "17- 6 Governing language and translations",
      Term_17_description_6:
        "These Terms, the Privacy Policy, and other agreements or communications notified through the Services have been drafted in English. Although translations in other languages of any of the foregoing documents may be available, such translations may not be up to date or complete. Accordingly, you agree that in the event of any conflict between the English language version of the foregoing documents and any other translations thereof, the English language version of such documents shall govern and prevail.",
      // Token Listing
      TL_title: "Füllen Sie das Formular zur Auflistung Ihres Tokens aus",
      TL_Exchange_info: "Börseninformationen",
      TL_Token_Name: "Token-Name",
      TL_Token_Name_Error: "Bitte geben Sie den Token-Namen ein.",
      TL_Token_symbol: "Token-Symbol",
      TL_Token_symbol_Error: "Bitte geben Sie das Token-Symbol ein.",
      TL_Token_Symbol_upload_Error: "Bitte laden Sie das Token-Symbol hoch.",
      TL_NFT: "NFT",
      TL_Total_supply_Error: "Bitte geben Sie das Gesamtangebot ein.",
      TL_Date_of_issue: "Ausgabedatum",
      TL_Date_of_issue_Error: "Bitte geben Sie das Ausgabedatum ein.",
      TL_Total_circulation: "Gesamtkreislauf",
      TL_Total_circulation_Error: "Bitte geben Sie den Gesamtkreislauf ein.",
      TL_Market_cap_circulation: "Marktkapitalisierung",
      TL_Market_cap_circulation_Error:
        "Bitte geben Sie die Marktkapitalisierung ein.",
      TL_Source_code: "Quellcode",
      TL_Source_code_Error: "Bitte geben Sie den Quellcode ein.",
      TL_Whitepaper: "Whitepaper",
      TL_Whitepaper_Error: "Bitte laden Sie das Whitepaper hoch.",
      TL_Communities: "Gemeinschaften",
      TL_Telegram: "Telegramm",
      TL_Telegram_Error: "Bitte geben Sie Telegramm ein.",
      TL_Twitter: "Twitter",
      TL_Twitter_Error: "Bitte geben Sie Twitter ein.",
      TL_Facebook: "Facebook",
      TL_Facebook_Error: "Bitte geben Sie Facebook ein.",
      TL_Reddit: "Reddit",
      TL_Reddit_Error: "Bitte geben Sie Reddit ein.",
      TL_Discord: "Discord",
      TL_Discord_Error: "Bitte geben Sie Discord ein.",
      TL_Submitter_info: "Einreichungsinformationen",
      TL_Role: "Rolle",
      TL_Role_Error: "Bitte geben Sie die Rolle ein.",
      TL_Personal_ID: "Persönliche ID",
      TL_Personal_ID_Error: "Bitte laden Sie Ihre persönliche ID hoch.",
      TL_About_project: "Über das Projekt",
      TL_About_project_Error:
        "Bitte geben Sie Informationen über das Projekt ein.",
      TL_About_Private: "Über den privaten Verkauf, ICO/IEO/IDO",
      TL_About_Private_Error:
        "Bitte geben Sie Informationen über den privaten Verkauf, ICO/IEO/IDO ein.",
      TL_Distribution_plan: "Verteilungsplan",
      TL_Distribution_plan_Error: "Bitte geben Sie den Verteilungsplan ein.",
      TL_CEO_info: "CEO-Informationen/CV Linkedin",
      TL_CEO_info_Error: "Bitte geben Sie CEO-Informationen/CV Linkedin ein.",
      TL_Policy:
        "Durch das Absenden Ihres Formulars akzeptieren Sie unsere Datenschutzrichtlinie",
      // Deposit
      Deposit_select_Token: "Bitte wählen Sie den Token aus:",
      Deposit_select_Network: "Bitte wählen Sie das Netzwerk aus:",
      Deposit_Address: "Adresse",
      Deposit_attention:
        "Bitte achten Sie auf die Abhebungsadresse und das ausgewählte Netzwerk.",
      Deposit_Network: "Netzwerk",
      Deposit_Warning:
        "Wenn Sie an die falsche Adresse oder mit dem falschen Netzwerk einzahlen, verlieren Sie Ihre Vermögenswerte.",
      // Transfer
      Transfer_Transfer: "Transfer",
      Transfer_select_Error: "Bitte wählen Sie den Token aus",
      Transfer_Balance_Error: "Unzureichendes Guthaben.",
      Transfer_Main_to_Trade: "Haupt- zu Handelskonto",
      Transfer_Trade_to_Main: "Handels- zu Hauptkonto",
      // Withdraw
      Withdraw_balance_Error: "Unzureichendes Guthaben",
      Withdraw_Amount_low_Error:
        "Der Auszahlungsbetrag darf nicht kleiner sein als ",
      Withdraw_Amount_high_Error:
        "Der Auszahlungsbetrag darf nicht größer sein als ",
      Withdraw_2FA_Error_1: "Sie müssen Ihre",
      Withdraw_2FA_Error_2: "Zwei-Faktor-Authentifizierung (2FA)",
      Withdraw_2FA_Error_3: "für die Auszahlung aktivieren.",
      Withdraw_KYC_Error_1: "Sie müssen den",
      Withdraw_KYC_Error_2: "KYC-Prozess",
      Withdraw_KYC_Error_3: "für die Auszahlung bestehen.",
      Withdraw_Goto_Profile: "Klicken Sie hier, um zur Profilseite zu gelangen",
      Withdraw_min: "Mindestauszahlungsbetrag",
      Withdraw_max: "Maximaler Auszahlungsbetrag",
      Withdraw_fee: "Auszahlungsgebühr",
      Withdraw_Unconfirmed_attention_1: "Bitte prüfen Sie Ihre ausgewählte ",
      Withdraw_Unconfirmed_attention_3: " und das ausgewählte ",
      Withdraw_Unconfirmed_attention_4: "NETZWERK",
      Withdraw_Unconfirmed_attention_5:
        "Wenn Sie an eine Adresse im falschen Netzwerk senden, verlieren Sie Ihre Vermögenswerte und EasyPro Platform übernimmt keine Verantwortung für verlorene Vermögenswerte.",
      Withdraw_Selected_token: "Ausgewählter Token",
      Withdraw_Selected_Network: "Ausgewähltes Netzwerk",
      Withdraw_Confirm_Mail_2FA:
        "E-Mail und Google-Authentifizierung bestätigen",
      Withdraw_Confirmed: "Auszahlung bestätigt",
      Withdraw_Confirmed_attention_1: "Bitte überprüfen Sie Ihr ",
      Withdraw_Confirmed_attention_2: "E-MAIL-POSTFACH",
      Withdraw_Confirmed_attention_3: " und ",
      Withdraw_Confirmed_attention_4: "BESTÄTIGEN",
      Withdraw_Confirmed_attention_5:
        "die E-Mail, die Ihnen zur Beendigung des Auszahlungsprozesses gesendet wurde.",
    },
  },
  fr: {
    translation: {
      ///////////////////// GLOBAL
      Already_Purchased: "Déjà acheté",
      Global_Products: "Des produits",
      Global_Transaction: "Transaction",
      Global_Icon: "Icône",
      Global_Pair: "Paire",
      Global_LastPrice: "Dernier prix",
      Global_Price: "Prix",
      Global_24h: "24h",
      Global_High: "Haut",
      Global_Low: "Bas",
      Global_Change: "Changement",
      Global_Volume: "Volume",
      Global_Trade: "Trade",
      Global_EasyGuarantee: "Garantie facile",
      Global_ApplicationForm: "Formulaire de demande",
      Global_EasyCard: "Easy Card",
      Global_Secure: "Sécurisé",
      Global_Fast: "Rapide",
      Global_Convert: "Convertir",
      Global_Gabur: "Gabur",
      Global_Token: "Token",
      Global_Total: "Total",
      Global_Amount: "Montant",
      Global_USDT: "USDT",
      Global_Stop: "Stop",
      Global_Limit: "Limite",
      Global_Supply: "Offre",
      Global_Whitepaper: "Livre blanc",
      Global_Circulation: "Circulation",
      Global_Website: "Site Web",
      Global_Buy: "Acheter",
      Global_Sell: "Vendre",
      Global_Loading: "Chargement...",
      Global_Submit: "Soumettre",
      Global_Cancel: "Annuler",
      Global_Confirm: "Confirmer",
      Global_Close: "Fermer",
      Global_OTP: "OTP",
      Global_Email: "Email",
      Global_2FA: "2FA",
      Global_Password: "Mot de passe",
      Global_FName: "Prénom",
      Global_LName: "Nom de famille",
      Global_IDNum: "Numéro d'identité",
      Global_Login: "Connexion",
      Global_Register: "S'inscrire",
      Global_Transfer: "Transférer",
      Global_UID: "Identifiant utilisateur",
      Global_Purpose: "Objectif",
      Global_Mobile: "Mobile",
      Global_Deposit: "Dépôt",
      Global_Withdraw: "Retrait",
      Global_Fee: "Frais",
      Global_Fees: "Frais",
      Global_OTC: "OTC",
      Global_Support: "Assistance",
      Global_Download: "Télécharger",
      Global_From: "De",
      Global_To: "À",
      Global_Now: "Maintenant",
      Global_Digital: "Numérique",
      Global_crypto: "crypto",
      Global_cash: "espèces",
      Global_wallet: "portefeuille",
      Global_account: "compte",
      Global_Exchange: "Échange",
      Global_trading: "négociation",
      Global_Accept_Terms:
        "En cochant la case, vous acceptez nos conditions générales.",
      Global_Available: "Disponible",
      Global_Username: "Nom d'utilisateur",
      Global_Time: "Heure",
      Global_Status: "Statut",
      Global_Network_Address: "Adresse du réseau",
      Global_Max_upload:
        "Taille maximale de téléchargement pour chaque fichier : 10 Mo",
      Global_Policy:
        "En soumettant votre formulaire, vous acceptez notre politique de confidentialité",
      Global_UID_Error: "Veuillez entrer votre identifiant utilisateur.",
      Global_IDNum_Error: "Veuillez entrer votre numéro d'identité.",
      Global_Mobile_Error: "Veuillez entrer votre numéro de téléphone.",
      Global_Price_Error: "Veuillez entrer un prix.",
      Global_Stop_Error: "Veuillez entrer un prix de stop.",
      Global_Amount_Error: "Veuillez entrer un montant.",
      Global_Total_Error: "Veuillez entrer un total.",
      Global_Email_Error: "Veuillez entrer votre adresse e-mail.",
      Global_Password_Error: "Veuillez entrer votre mot de passe.",
      Global_FName_Error: "Veuillez entrer votre prénom.",
      Global_LName_Error: "Veuillez entrer votre nom de famille.",
      Global_Website_Error: "Veuillez entrer l'adresse de votre site Web.",
      Global_Purpose_Error: "Veuillez expliquer l'objet de votre demande.",
      Global_Accept_Terms_Error: "Vous devez accepter avant de soumettre.",
      Global_about: 'À propos de nous',
      Global_coin_info: "Informations sur les pièces",
      Global_token_listing: "Liste des jetons",
      Global_logout: "Se déconnecter",
      // Home
      //// Top
      Home_Top_Download_From: "Télécharger depuis",
      Home_Top_Playstore: "Playstore",
      Home_Top_Download_APK: "Télécharger l'APK",
      Home_Top_APPstore: "APPstore",
      Home_Top_Register_Now: "S'inscrire maintenant",
      Home_Carousel_1_title: "Transparence et sécurité",
      Home_Carousel_1_description:
        "Nous avons rendu publics les détails transparents sur le token Gabur dans le Livre blanc pour vous permettre de prendre une décision d'investissement éclairée. De plus, le contrat intelligent du token Gabur a été audité pour garantir un niveau de sécurité élevé.",
      Home_Carousel_2_title: "Garantie facile",
      Home_Carousel_2_description:
        "Pour la première fois dans le monde numérique, Easy Exchange Pro propose le système de garantie Easy Guarantee pour le transfert de fonds entre les entreprises et les contrats commerciaux.",
      Home_Carousel_3_title: "Support 24/7",
      Home_Carousel_3_description:
        "Vous pouvez contacter l'équipe de support via le système de ticket ou envoyer un email à support@easyexchangepro.com",
      //// Easy Guarantee
      Home_EG_subtitle:
        "Première garantie numérique dans le monde de la crypto",
      Home_EG_description:
        "Pour la première fois dans le monde numérique, Easy Exchange Pro propose le système Easy Guarantee pour le transfert garanti d'argent entre les entreprises et les contrats commerciaux.",
      Home_EG_more_info_btn: "Plus d'infos",
      Home_EC_subtitle_1: "La seule façon d'",
      Home_EC_subtitle_2: "acheter de la crypto en espèces",
      Home_EC_description_1:
        "Obtenez le code prépayé Easy Card près de chez vous et déposez",
      Home_EC_description_2:
        "les Gabur Tokens dans votre portefeuille. Sûr et fiable, sans",
      Home_EC_description_3:
        "banque ni carte de crédit. C'est la seule façon d'acheter de la crypto en espèces.",
      Home_EC_Feature_1_title: "Indépendant",
      Home_EC_Feature_1_description_1:
        "Achetez de la crypto sans compte bancaire",
      Home_EC_Feature_1_description_2: "ou carte de crédit",
      Home_EC_Feature_2_description_1: "Tous vos actifs sont sous votre",
      Home_EC_Feature_2_description_2: "contrôle total",
      Home_EC_Feature_3_description_1: "Déposez de l'argent liquide dans",
      Home_EC_Feature_3_description_2:
        "votre portefeuille en quelques secondes",
      Home_EC_Feature_4_title: "Facile",
      Home_EC_Feature_4_description_1: "Faites ce que vous avez toujours",
      Home_EC_Feature_4_description_2: "fait",
      Home_EC_Feature_5_title: "Pas de frais de dépôt",
      Home_EC_Feature_5_description_1: "Déposez sans frais supplémentaires",
      Home_EC_Feature_5_description_2: "pour vous",
      Home_EC_Feature_6_description_1: "Vous pouvez convertir le token Gabur",
      Home_EC_Feature_6_description_2: "en d'autres devises.",
      Home_EC_Where_to_buy: "Où acheter",
      Home_MS_title:
        "Commencez à trader facilement avec l'application mobile Easy Exchange Pro.",
      Home_MS_list_1: "Accès 24/7 à vos actifs n'importe où",
      Home_MS_list_2: "Sûr et sécurisé",
      Home_MS_list_3: "Interface conviviale",
      Home_MS_list_4: "Outils de trading avancés",
      Home_MS_list_5: "Application tout-en-un",
      Home_MS_dl_for_ios: "Télécharger pour iOS",
      Home_MS_dl_for_android: "Télécharger pour Android",
      //about
      //// A Top video
      About_top_title: "Notre mission",
      About_top_desciption:
        "Notre mission consiste à créer un écosystème offrant à tous le droit d'accéder facilement au monde des devises numériques, et donc à la liberté financière.",
      About_top_Feature_1: "600,000",
      About_top_Feature_1_description_1:
        "Utilisateurs enregistrés",
      About_top_Feature_1_description_2: " ",
      About_top_Feature_2: "2 emplacements",
      About_top_Feature_2_description: "Nos emplacements dans 2 pays",
      About_top_Feature_3: "+28 pays",
      About_top_Feature_3_description: "Services dans plus de 28 pays",
      About_second_title: "Pourquoi choisir Easy Exchange Pro ?",
      About_second_subtitle:
        "Facile pour les débutants et avancé pour les professionnels",
      About_second_description_1:
        "Bien que le monde des crypto-monnaies puisse être un peu complexe, nous sommes là pour vous aider ! Notre plateforme facilite les échanges.",
      About_second_description_2:
        "Nous vous fournissons les outils nécessaires pour acheter et vendre les crypto-monnaies les plus populaires au même endroit, tout en bénéficiant d'une sécurité de pointe.",
      About_second_Feature_1_description_1:
        "Protégé par une sécurité de pointe",
      About_second_Feature_1_description_2:
        "basée sur la technologie de cryptographie",
      About_second_Feature_2_title: "Facilité d'utilisation",
      About_second_Feature_2_description_1:
        "Nous avons conçu une plateforme simple et facile",
      About_second_Feature_2_description_2:
        "et nous la mettons à votre disposition",
      About_second_Feature_3_title:
        "Utilisation avancée (pour les professionnels)",
      About_second_Feature_3_description_1:
        "Nous avons développé une interface de trading avancée",
      About_second_Feature_3_description_2:
        "qui offre une expérience professionnelle à nos utilisateurs",
      About_second_Feature_4_description_1: "Échangez vos devises préférées",
      About_second_Feature_4_description_2: "en un instant",
      About_second_Feature_5_title: "Achat en espèces",
      About_second_Feature_5_description_1:
        "La façon la plus simple d'acheter des crypto-monnaies",
      About_second_Feature_5_description_2:
        "en espèces est exclusive à Easy Card",
      About_second_Feature_6_title: "24/7",
      About_second_Feature_6_description_1:
        "Commencez à trader des crypto-monnaies et bénéficiez de notre assistance en tout temps.",
      About_road_title: "Feuille de route",
      About_road_description_1:
        "Le développement d'Easy Exchange Pro a été planifié sur trois ans.",
      About_road_description_2:
        "Vous trouverez ici un aperçu de nos objectifs à accomplir dans les années à venir.",
      About_road_year_1: "2022",
      About_road_year_1_description_1: "Produit unique : Easy Guarantee",
      About_road_year_1_description_2: "+ 6 langues",
      About_road_year_1_description_3: "+ 10 courtiers dans 5 pays",
      About_road_year_1_description_4: "+ 100 employés supplémentaires",
      About_road_year_1_description_5: "+ 10 paires supplémentaires",
      About_road_year_2: "2023",
      About_road_year_2_description_1: "Nouveau produit : Easy Wallet",
      About_road_year_2_description_2: "Easy Pro Academy",
      About_road_year_2_description_3: "API pour l'intégration commerciale",
      About_road_year_2_description_4:
        "Easy Card sera une méthode efficace pour les transactions",
      About_road_year_2_description_5: "+ 6 langues",
      About_road_year_2_description_6: "+ 200 courtiers dans 16 pays",
      About_road_year_2_description_7: "+ 5 devises fiduciaires",
      About_road_year_2_description_8: "+ 250 employés",
      About_road_year_2_description_9: "+ 30 paires",
      About_road_year_2_description_10: "+ 100 coins de crédibilité",
      About_road_year_2_description_11:
        "Listage de GABUR sur d'autres plateformes de renommée mondiale",
      About_road_year_3: "2024",
      About_road_year_3_description_1: "Produit unique : Crypto Bank",
      About_road_year_3_description_2: "+ 6 langues",
      About_road_year_3_description_3: "+ 1000 employés",
      About_road_year_3_description_4: "+ 100 paires",
      About_road_year_3_description_5: "+ 130 coins de crédibilité",
      About_road_year_3_description_6:
        "Listage de GABUR sur d'autres plateformes de renommée mondiale",
      Api_title: "Documents API",
      Api_1: "Résumé",
      Api_2: "Actifs",
      Api_3: "Cours",
      Api_4: "Carnet d'ordres",
      Api_5: "Transactions",
      Api_6: "Volume sur 24h",
      Asset_Tab_1: "Tous les actifs",
      Asset_Tab_2: "Portefeuille principal",
      Asset_Tab_3: "Portefeuille de trading",
      Asset_Total_BTC: "Total des actifs en BTC",
      Asset_In_Withdraw: "En retrait",
      Asset_In_Order: "En ordre",
      ContactUs_email_title: "Contactez-nous :",
      ContactUs_email: "Support@easyexchangepro.com",
      ContactUs_faq_title:
        "Vous pouvez consulter notre page FAQ pour trouver votre réponse :",
      ContactUs_faq: "",
      ContactUs_ticket_title: "Ou vous pouvez ouvrir un ticket :",
      ContactUs_ticket: "",
      ContactUs_social_title: "Retrouvez-nous et suivez-nous sur :",

      // Coin info
      CoinInfo_Issue_Time: "Issue Time :",
      CoinInfo_White_paper: "Whitepaper:",
      CoinInfo_Website: "Website:",
      CoinInfo_Block_Explorer: "Block Explorer:",
      CoinInfo_AAVE:
        "Aave is an open source and non-custodial protocol enabling the creation of money markets. Users can earn interest on deposits and borrow assets. Deposit interest rate and loan interest rate of both borrowers and lenders are calculated through algorithms based on the platform's borrowing volume and deposit volume, and the platform uses Chainlink's oracles to ensure the fairness of the collateral price.",
      CoinInfo_ADA:
        "Cardano is a decentralised public blockchain and cryptocurrency project and is fully open source. Cardano is developing a smart contract platform which seeks to deliver more advanced features than any protocol previously developed. It is the first blockchain platform to evolve out of a scientific philosophy and a research-first driven approach.",
      CoinInfo_Atom:
        "In a nutshell, Cosmos bills itself as a project that solves some of the “hardest problems” facing the blockchain industry. It aims to offer an antidote to “slow, expensive, unscalable and environmentally harmful” proof-of-work protocols, like those used by Bitcoin, by offering an ecosystem of connected blockchains. The project’s other goals include making blockchain technology less complex and difficult for developers thanks to a modular framework that demystifies decentralized apps. Last but not least, an Interblockchain Communication protocol makes it easier for blockchain networks to communicate with each other — preventing fragmentation in the industry. Cosmos’ origins can be dated back to 2014, when Tendermint, a core contributor to the network, was founded. In 2016, a white paper for Cosmos was published — and a token sale was held the following year. ATOM tokens are earned through a hybrid proof-of-stake algorithm, and they help to keep the Cosmos Hub, the project’s flagship blockchain, secure. This cryptocurrency also has a role in the network’s governance.",
      CoinInfo_AVAX:
        "Avalanche is a layer one blockchain that functions as a platform for decentralized applications and custom blockchain networks. It is one of Ethereum’s rivals, aiming to unseat Ethereum as the most popular blockchain for smart contracts. It aims to do so by having a higher transaction output of up to 6,500 transactions per second while not compromising scalability. This is made possible by Avalanche’s unique architecture. The Avalanche network consists of three individual blockchains: the X-Chain, C-Chain and P-Chain. Each chain has a distinct purpose, which is radically different from the approach Bitcoin and Ethereum use, namely having all nodes validate all transactions. Avalanche blockchains even use different consensus mechanisms based on their use cases. After its mainnet launch in 2020, Avalanche has worked on developing its own ecosystem of DApps and DeFi. Different Ethereum-based projects such as SushiSwap and TrueUSD have integrated with Avalanche. Furthermore, the platform is constantly working on improving interoperability between its own ecosystem and Ethereum, like through the development of bridges.",
      CoinInfo_BNB:
        "From the ICO, Binance issued its own coin called the Binance Coin, with BNB as the symbol. BNB runs natively on Binance chain. The coin was established with a total supply of 200 million. Every quarter, we will destroy BNB based on the trading volume on our crypto-to-crypto platform until we destroy 50% of all the BNB. All transactions will be on the blockchain. We eventually will destroy 100MM BNB, leaving 100MM BNB remaining. The BNB coin itself has multiple forms of utility, essentially being the underlying gas that powers the Binance Ecosystem. The current most prominent use cases include using BNB to: - Pay for trading fees on the exchange, obtaining the equivalent of a 50% discount on trades (during the first year). - Crypto.com has included support for Binance’s BNB token to its platform which includes the MCO Visa Card and mobile app.",
      CoinInfo_BTC:
        "Bitcoin is a cryptocurrency and a digital payment system invented by an unknown programmer, or a group of programmers, under the name Satoshi Nakamoto.It was released as open-source software in 2009.The system is peer-to-peer, and transactions take place between users directly, without an intermediary. These transactions are verified by network nodes and recorded in a public distributed ledger called a blockchain. Since the system works without a central repository or single administrator, bitcoin is called the first decentralized digital currency.Besides being created as a reward for mining, bitcoin can be exchanged for other currencies, products, and services in legal or black markets.As of February 2015, over 100,000 merchants and vendors accepted bitcoin as payment. According to research produced by Cambridge University in 2017, there are 2.9 to 5.8 million unique users using a cryptocurrency wallet, most of them using bitcoin.",
      CoinInfo_Cake:
        "PancakeSwap, built on Binance Smart Chain (BSC), is a decentralized exchange for swapping BEP-20 tokens. It is fast, cheap, and allows anyone to participate. Users can get FLIP (LP token) for staking and get CAKE token as reward. CAKE holders can swap CAKE for SYRUP for additional incentivized staking. PancakeSwap uses an automated market maker (AMM) model. It means that while you trade digital assets on the platform, there isn’t an order book where you’re matched with someone else. Instead, you trade against a liquidity pool. Traders deposit funds into the pool, receiving liquidity provider (or LP) tokens in return. They can use those tokens to reclaim their share, plus a portion of the trading fees.",
      CoinInfo_Doge:
        "Dogecoin is a cryptocurrency focused on actual utility as a currency. We provide fast block times and very low fees which make Dogecoin suitable for usage in micro-transactions but also as payment option for online shops. Dogecoin has been adopted as such by online retailers and can be used easily as means of consumer to consumer money transfer too.",
      CoinInfo_Dot:
        'Polkadot is a global well-known blockchain cross-chain project, which is created by Ethereum co-founder and Ethereum yellow book author Gavin Wood. It aims to solve the communication and data transmission obstacles between different blockchains and was rated as one of the "4 major projects that decide the direction of digital currency development in 2019".',
      CoinInfo_ETC:
        "Ethereum classNameic (ETC) is a hard fork of Ethereum (ETH) that launched in July 2016. Its main function is as a smart contract network, with the ability to host and support decentralized applications (DApps). Its native token is ETC. Since its launch, Ethereum classNameic has sought to differentiate itself from Ethereum, with the two networks’ technical roadmap diverging further and further from each other with time. Ethereum classNameic first set out to preserve the integrity of the existing Ethereum blockchain after a major hacking event led to the theft of 3.6 million ETH.",
      CoinInfo_ETH:
        'Ethereum (ETH) is an open-source, public, blockchain-based distributed computing platform featuring smart contract (scripting) functionality, which facilitates online contractual agreements. Ethereum also provides a cryptocurrency token called "ether", which can be transferred between accounts and used to compensate participant nodes for computations performed. "Gas", an internal transaction pricing mechanism, is used to mitigate spamand allocate resources on the network.The value token of the Ethereum blockchain is called ether. It is listed under the diminutive ETH and traded on cryptocurrency exchanges. It is also used to pay for transaction fees and computational services on the Ethereum network.',
      CoinInfo_Fil:
        "The InterPlanetary File system (IPFS) is a global, peer-to-peer distributed version of the file system that aims to supplement (or even replace) the hypertext transfer protocol (HTTP) that currently dominates the internet, by connecting all computing devices with the same file system. The principle is to replace the address based on the domain name with a content-based address, that is, the user is looking for content that is not an address but stored in a certain place. There is no need to verify the identity of the sender, but only the hash of the content. It can ensure the web to be faster, safer, more robust and more durable.Filecoin is an incentive layer on IPFS and a a decentralized storage market built on IPFS based on the token incentive model.FIL6 is the Filecoin contract issued 6 months after its launch",
      CoinInfo_GBR:
        "Gabur (GBR) is a utility token for the Easy Exchange Pro platform. The Gabur is using for the Easy Card, a system to buy crypto without cash, and also Easy Guarantee which is a system to guarantee payment between companies and their customers.",
      CoinInfo_LTC:
        "Litecoin is a peer-to-peer Internet currency that enables instant, near-zero cost payments to anyone in the world. Litecoin is an open source, global payment network that is fully decentralized without any central authorities. Mathematics secures the network and empowers individuals to control their own finances. Litecoin features faster transaction confirmation times and improved storage efficiency than the leading math-based currency. With substantial industry support, trade volume and liquidity, Litecoin is a proven medium of commerce complementary to Bitcoin",
      CoinInfo_Matic:
        "Matic Network is a layer-2 scaling solution with side-chains for off-chain computation, while ensuring asset security using the Plasma framework and a decentralized network of Proof-of-Stake (PoS) validators. It aims to offer scalable and instant blockchain transactions. By utilizing adapted version of the Plasma framework, the system is built on PoS checkpoints that are pushed to the Ethereum main-chain. As a result, it is expected to allow each Matic sidechain to theoretically achieve 2^16 transactions per block. Matic Network has been a significant contributor to the Ethereum ecosystem, having worked on implementations of Plasma MVP (Minimum Viable Plasma), the WalletConnect protocol, and Dagger, a popular Ethereum event notification engine. Many projects have already been building applications on and integrating with Matic. MATIC is an ERC-20 token running on Ethereum, it used to pay for services on the Matic Network but also serves as a settlement currency between users who operate within the Matic ecosystem.",
      CoinInfo_MKR:
        "Maker (MKR) is the governance token of the MakerDAO and Maker Protocol — respectively a decentralized organization and a software platform, both based on the Ethereum blockchain — that allows users to issue and manage the DAI stablecoin. Initially conceived in 2015 and fully launched in December 2017, Maker is a project whose task is to operate DAI, a community-managed decentralized cryptocurrency with a stable value soft-pegged to the US dollar. MKR tokens act as a kind of voting share for the organization that manages DAI; while they do not pay dividends to their holders, they do give the holders voting rights over the development of Maker Protocol and are expected to appreciate in value in accordance with the success of DAI itself. The Maker ecosystem is one of the earliest projects on the decentralized finance (DeFi) scene: the industry that seeks to build decentralized financial products on top of smart-contract-enabled blockchains, such as Ethereum.",
      CoinInfo_Near:
        "NEAR Protocol is a layer-one blockchain that was designed as a community-run cloud computing platform and that eliminates some of the limitations that have been bogging competing blockchains, such as low transaction speeds, low throughput and poor interoperability. This provides the ideal environment for DApps and creates a developer and user-friendly platform. For instance, NEAR uses human-readable account names, unlike the cryptographic wallet addresses common to Ethereum. NEAR also introduces unique solutions to scaling problems and has its own consensus mechanism called “Doomslug.” NEAR Protocol is being built by the NEAR Collective, its community that is updating the initial code and releasing updates to the ecosystem. Its declared goal is to build a platform that is “secure enough to manage high value assets like money or identity and performant enough to make them useful for everyday people.” Flux, a protocol that allows developers to create markets based on assets, commodities, real-world events, and Mintbase, an NFT minting platform are examples of projects being built on NEAR Protocol.",
      CoinInfo_TRX:
        "Tron is one of the largest blockchain based operating systems in the world. TRON is an ambitious project dedicated to building the infrastructure for a truly decentralized Internet. The Tron Protocol, one of the largest blockchain based operating systems in the world, offers scalable, high-availability and high-throughput support that underlies all the decentralized applications in the TRON ecosystem. TRON Protocol and the TVM allow anyone to develop DAPPs for themselves or their communities, with smart contracts making decentralized crowdfunding and token issuance easier than ever. Tron DAPP projects already include Peiwo, Obike, Uplive, game.com, Kitty live and Mico, with 100M+ active users from more than 100 countries and regions around the world.",
      CoinInfo_XLM:
        "Stellar network is a free, open-source network that connects diverse financial systems and lets anyone build low-cost financial services—payments, savings, loans, insurance—for their community. It is supported by Stellar.org, a Silicon Valley based non-profit organization. The Stellar network enables money to move directly between people, companies and financial institutions as easily as email. This inter-connectivity means more access for individuals, lower costs for banks, and more revenue for businesses. Token use: Stellar is an open-source, decentralized protocol for digital currency to fiat currency transfers which allows cross-border transactions between any pair of currencies.",
      CoinInfo_XRP:
        "Ripple (XRP) is an independent digital asset that is native to the Ripple Consensus Ledger. With proven governance and the fastest transaction confirmation of its kind, XRP is said to be the most efficient settlement option for financial institutions and liquidity providers seeking global reach, accessibility and fast settlement finality for interbank flows.",
      CoinInfo_ZEC:
        "NZcash is a decentralized cryptocurrency focused on privacy and anonymity. It uses the zk-SNARK zero-knowledge proof technology that allows nodes on the network to verify transactions without revealing any sensitive information about those transactions. Contrary to a common misunderstanding, the majority of cryptocurrencies on the market, including Bitcoin (BTC), are not anonymous, but rather pseudonymous; while they do not explicitly reveal the identities of their users, each user has their own public address or addresses which can be traced back to them via the methods of data science and blockchain forensics. Zcash transactions, on the other hand, still have to be relayed via a public blockchain, but unlike pseudonymous cryptocurrencies, ZEC transactions by default do not reveal the sending and receiving addresses or the amount being sent. There is an option, however, to reveal this data for the purposes of auditing or regulatory compliance. Zcash was first released on October 28, 2016, and it was originally based on Bitcoin’s codebase.",
      // Easy Card
      //// Where To Buy
      ///// Top
      EC_WT_Title: "Acheter la carte Easy",
      EC_WT_Description:
        "Vous pouvez acheter la carte Easy auprès d'un de nos courtiers près de chez vous et recharger votre compte sans carte Visa ou carte de crédit.",
      EC_WT_Find_Title: "Trouver un courtier près de chez vous",
      EC_WT_Find_btn: "Trouver",
      EC_WT_Find_input: "Entrez votre ville",
      EC_WA_title: "Demandez une licence de carte Easy",
      EC_WA_Decription: "Postuler",
      EC_WS_Question: "COMMENT FONCTIONNE LA CARTE EASY ?",
      EC_WS_title: "Commencez en 3 étapes faciles",
      EC_WS_SubTitle: "Tout le processus en moins de 3 minutes.",
      EC_WS_Feature_1_description:
        "Trouvez un courtier près de chez vous et achetez la carte Easy",
      EC_WS_Feature_2_description:
        "Saisissez votre code de carte Easy pour déposer vos jetons Gabur dans votre portefeuille",
      EC_WS_Feature_3_description: "Maintenant, vous pouvez commencer à trader",
      EC_AP_description:
        "Saisissez votre code ici, les jetons Gabur seront automatiquement déposés dans votre portefeuille.",
      EC_AP_Code: "Entrez votre code",
      EC_AP_Code_Error: "Veuillez entrer un code de 20 chiffres.",
      EC_BR_title: "Inscrivez-vous en tant que courtier",
      EC_BR_Exchange_info: "Informations sur l'échange",
      EC_BR_Legal: "Nom légal",
      EC_BR_Legal_Error: "Veuillez entrer le nom légal enregistré du pays.",
      EC_BR_RegCountry: "Pays enregistré",
      EC_BR_RegCountry_Error: "Veuillez entrer le pays enregistré.",
      EC_BR_RegNum: "Numéro d'enregistrement",
      EC_BR_RegNum_Error: "Veuillez entrer le numéro d'enregistrement.",
      EC_BR_MonTxVal: "Volume de transactions mensuelles",
      EC_BR_MonTxVal_Error:
        "Veuillez entrer le volume de transactions mensuelles.",
      EC_BR_ECMonDis: "Distribution mensuelle de la carte Easy",
      EC_BR_ECMonDis_Error:
        "Veuillez entrer la distribution mensuelle de la carte Easy.",
      EC_BR_Website: "Site web (si vous en avez un)",
      EC_BR_Submitter_info: "Informations sur le soumissionnaire",
      EC_BR_Job: "Intitulé du poste",
      EC_BR_Job_Error: "Veuillez entrer l'intitulé du poste.",
      EC_BR_Birth: "Date de naissance",
      EC_BR_Birth_Error: "Veuillez entrer la date de naissance.",
      EC_BR_Country: "Pays de résidence",
      EC_BR_Country_Error: "Veuillez entrer le pays de résidence.",
      EC_BR_Citizen: "Pays de citoyenneté",
      EC_BR_Citizen_Error: "Veuillez entrer le pays de citoyenneté.",
      EC_BR_Upload_title:
        "Veuillez télécharger les documents d'échange et personnels :",
      EC_BR_Exchange_Doc: "Document d'enregistrement de l'échange",
      EC_BR_Exchange_Doc_Error:
        "Veuillez télécharger le document d'enregistrement de l'échange.",
      EC_BR_Peronal_ID_Doc: "Document d'identification personnel",
      EC_BR_Peronal_ID_Doc_Error:
        "Veuillez télécharger le document d'identification personnel.",
      // Easy Guarantee
      //// Easy Guarantee
      ////// top
      EG_EGTop_title: "Easy Guarantee",
      EG_EGTop_Description_1: "La seule garantie numérique crypto",
      EG_EGTop_Description_2: "Pour la première fois dans le monde",
      EG_EGText_title: "Qu'est-ce que l'Easy Guarantee ?",
      EG_EGText_Description_1:
        "L'Easy Guarantee est un type de garantie en USDT proposée par Easyexchangepro, où le prêteur s'engage à ce que les obligations d'un débiteur soient respectées. En d'autres termes, si le débiteur ne parvient pas à rembourser une dette, Easyexchangepro la couvrira.",
      EG_EGText_Description_2:
        "Une Easy Guarantee permet à l'emprunteur d'acquérir des biens, d'acheter du matériel, d'obtenir un prêt, ou contre un acompte, etc.",
      EG_EGText_Feature_1_description:
        "Vous pourrez émettre une Easy Guarantee pour vos besoins professionnels en 5 minutes.",
      EG_EGText_Feature_2_title: "Fiable",
      EG_EGText_Feature_2_description:
        "L'Easy Guarantee n'est pas seulement un document d'engagement de paiement ; au contraire, des USDT seront déposés sur le compte du bénéficiaire sous forme de devise numérique la plus liquide.",
      EG_EGText_Feature_3_description:
        "L'Easy Guarantee émise est à 100% sûre et sécurisée pour le bénéficiaire et peut être convertie ou retirée à échéance.",
      EG_EGSteps_title:
        "Vous pouvez émettre une Easy Guarantee en 4 étapes faciles :",
      EG_EGSteps_Feature_1_title: "Étape 1",
      EG_EGSteps_Feature_1_description_1: "Inscrivez-vous sur notre plateforme",
      EG_EGSteps_Feature_2_title: "Étape 2",
      EG_EGSteps_Feature_2_description_1: "Remplissez le formulaire de demande",
      EG_EGSteps_Feature_2_description_2: "et soumettez-le",
      EG_EGSteps_Feature_3_title: "Étape 3",
      EG_EGSteps_Feature_3_description_1: "Transférez le montant de",
      EG_EGSteps_Feature_3_description_2: "l'Easy Guarantee en USDT",
      EG_EGSteps_Feature_4_title: "Étape 4",
      EG_EGSteps_Feature_4_description_1: "La garantie a été émise et",
      EG_EGSteps_Feature_4_description_2:
        "les USDT ont été déposés et verrouillés dans",
      EG_EGSteps_Feature_4_description_3: "le compte du bénéficiaire",
      ////// Bot
      EG_EGBot_title: "Pour les entreprises et les particuliers",
      EG_EGBot_description:
        "Les entreprises et les particuliers peuvent émettre une garantie en petites ou grandes quantités dans le cadre de leurs activités commerciales en faveur du bénéficiaire.",
      EG_EGFaq_Q1: "Qu'est-ce que l'Easy Guarantee ?",
      EG_EGFaq_A1:
        "L'Easy Guarantee est un type de garantie avec une contrepartie en crypto-monnaie (USDT), selon lequel les emprunteurs/acheteurs garantissent que leurs dettes envers le créancier seront remplies.",
      EG_EGFaq_Q2: "Qui est le demandeur ?",
      EG_EGFaq_A2:
        "Le demandeur est la partie qui demandera l'émission de l'Easy Guarantee.",
      EG_EGFaq_Q3: "Quelle devise est acceptée pour émettre une garantie ?",
      EG_EGFaq_A3: "Seule l'USDT est acceptée.",
      EG_EGFaq_Q4: "Toute adresse de portefeuille USDT est-elle acceptée ?",
      EG_EGFaq_A4:
        "Non, seules les adresses de portefeuille USDT du demandeur et du bénéficiaire sont acceptées dans Easy Exchange Pro.",
      EG_EGFaq_Q5: "Qui est le bénéficiaire ?",
      EG_EGFaq_A5: "La partie en faveur de laquelle la garantie a été émise.",
      EG_EGFaq_Q6: "Qu'est-ce que l'Easy guarantee conditionnelle ?",
      EG_EGFaq_A6:
        "La demande de libération du montant de la garantie par le bénéficiaire doit être approuvée par le demandeur.",
      EG_EGFaq_Q7: "Qu'est-ce que l'Easy guarantee inconditionnelle ?",
      EG_EGFaq_A7:
        "Le montant de l'Easy Guarantee sera libéré sur la première demande du bénéficiaire.",
      EG_EGFaq_Q8: "Comment les utilisateurs peuvent-ils faire une demande ?",
      EG_EGFaq_A8: "Remplissez le formulaire de demande et soumettez-le.",
      EG_EGFaq_Q9: "Que se passera-t-il après avoir soumis le formulaire ?",
      EG_EGFaq_A9:
        "Le demandeur recevra un premier e-mail pour confirmer la demande, puis un deuxième e-mail contenant l'adresse du portefeuille USDT pour transférer le montant de l'Easy Guarantee.",
      EG_EGFaq_Q10: "Combien l'utilisateur peut-il émettre d'Easy guarantee ?",
      EG_EGFaq_A10:
        "L'utilisateur peut émettre une Easy Guarantee jusqu'au montant du solde USDT dans son compte.",
      EG_EGFaq_Q11:
        "L'utilisateur doit-il avoir un solde USDT pour demander et émettre une Easy Guarantee ?",
      EG_EGFaq_A11:
        "Oui, les utilisateurs peuvent demander d'émettre une Easy Guarantee jusqu'au montant du solde USDT dans leur compte.",
      EG_EGFaq_Q12: "Quand sera émise l'Easy Guarantee ?",
      EG_EGFaq_A12:
        "Dès que le demandeur transfère l'USDT, l'Easy Guarantee est émise immédiatement et le montant de l'USDT est bloqué sur le compte du bénéficiaire pendant la période de validité.",
      EG_EGFaq_Q13:
        "Comment les litiges concernant la libération de la garantie seront-ils résolus entre le demandeur et le bénéficiaire ?",
      EG_EGFaq_A13:
        "En cas de litige, l'une des parties peut envoyer son objection à easyguarantee@easyexchangepro.com et notre service juridique prendra une décision dans un délai d'un mois après avoir examiné les documents des parties.",
      EG_EGFaq_Q14:
        "Le demandeur et le bénéficiaire doivent-ils tous deux être des utilisateurs de la plateforme Easyexchangepro ?",
      EG_EGFaq_A14: "Oui, c'est obligatoire.",
      EG_EGFaq_Q15:
        "Quand le bénéficiaire peut-il demander à libérer le montant de l'Easy Guarantee ?",
      EG_EGFaq_A15:
        "Le bénéficiaire peut demander la libération du montant de l'Easy Guarantee sur son compte pendant la période de validité de l'Easy Guarantee.",
      EG_EGFaq_Q16:
        "Que se passe-t-il si le bénéficiaire ne demande pas la libération du montant de la garantie pendant la période de validité de l'Easy Guarantee ?",
      EG_EGFaq_A16:
        "Le montant de l'Easy Guarantee sera automatiquement crédité sur le compte du demandeur.",
      EG_EGFaq_Q17:
        "Combien de temps faut-il pour émettre une Easy Guarantee ?",
      EG_EGFaq_A17: "Moins de 5 minutes.",
      EG_EGFaq_Q18: "Combien coûte l'émission d'une Easy Guarantee ?",
      EG_EGFaq_A18: "0,4% du montant de l'Easy Guarantee.",
      EG_EGFaq_Q19: "Qui doit payer les frais ?",
      EG_EGFaq_A19: "Le demandeur.",
      EG_EGFaq_Application_form: "Formulaire de demande",
      //// Application Form
      EG_AF_title: "Formulaire de demande d'Easy Guarantee",
      EG_AF_description:
        "Remarque : Veuillez lire attentivement la section des questions sur l'Easy Guarantee avant de remplir le formulaire de demande.",
      EG_AP_Applicant: "Demandeur :",
      EG_AP_SDate: "Date de début",
      EG_AP_SDate_Error: "Veuillez entrer la date de début.",
      EG_AP_EDate: "Date de fin",
      EG_AP_EDate_Error: "Veuillez entrer la date de fin.",
      EG_AP_WalletApp: "Adresse du portefeuille USDT Easy Exchange Pro",
      EG_AP_WalletApp_Error: "Veuillez entrer l'adresse du portefeuille USDT.",
      EG_AP_Amount: "Montant USDT",
      EG_AP_Amount_Error: "Veuillez entrer le montant USDT.",
      EG_AP_VPD: "Durée de validité en jours",
      EG_AP_VPD_Error: "Veuillez entrer la durée de validité en jours.",
      EG_AP_Select_Conditional: "Conditionnelle",
      EG_AP_Select_Unconditional: "Inconditionnelle",
      EG_AP_Beneficiary: "Bénéficiaire :",
      EG_AP_BenUID: "Identifiant utilisateur du bénéficiaire",
      EG_AP_BenUID_Error:
        "Veuillez entrer l'identifiant utilisateur du bénéficiaire.",
      EG_AP_BenEmail: "Adresse e-mail du bénéficiaire",
      EG_AP_BenEmail_Error: "Veuillez entrer l'adresse e-mail du bénéficiaire.",
      EG_AP_WalletBen:
        "Adresse du portefeuille USDT du bénéficiaire dans Easy Exchange Pro",
      EG_AP_WalletBen_Error:
        "Veuillez entrer l'adresse du portefeuille USDT du bénéficiaire.",
      Fee_title_1: "Ordres initiateurs",
      Fee_description_1:
        "Les ordres initiateurs créent de la liquidité sur un marché en étant placés dans le carnet d'ordres. En d'autres termes, les ordres initiateurs ne sont pas exécutés lorsqu'ils sont placés, mais attendent plutôt qu'un futur ordre soit placé correspondant. Un ordre initiateur peut être un ordre d'achat ou de vente. Lorsqu'un ordre existant dans le carnet d'ordres correspond à un nouvel ordre placé (l'emporteur), l'ordre initiateur dans la transaction est facturé de frais initiateurs. Si un nouvel ordre entré ne correspond pas immédiatement à un ordre existant ou n'est pas entièrement rempli par des ordres existants, l'ordre non exécuté est placé dans le carnet d'ordres et devient un ordre initiateur pour une transaction future.",
      Fee_title_2: "Ordres preneurs",
      Fee_description_2:
        "Les ordres preneurs réduisent la liquidité sur un marché. Les ordres preneurs sont exécutés immédiatement et retirent du volume du carnet d'ordres. Un ordre preneur peut être un ordre d'achat ou de vente. Lorsqu'un nouvel ordre est placé et qu'il correspond à un autre ordre déjà présent dans le carnet d'ordres (l'initiateur), le preneur dans la transaction est facturé de frais preneurs.",
      Fee_title_3: "Frais de transaction",
      Fee_subtitle_3_1: "Blockchain :",
      Fee_description_3_1:
        "Desfrais spécifiques liés à la blockchain peuvent être facturés lorsque l'utilisateur déplace des jetons sur la blockchain depuis et vers Easy Exchange Pro.",
      Fee_description_3_2:
        "Easy Exchange Pro ne facture pas de frais de dépôt. Veuillez noter que certains jetons ou pièces nécessitent que nous transférions vos fonds vers une autre adresse avant qu'ils ne soient crédités. Cela signifie que le réseau de la pièce ou du jeton vous facturera les frais de transaction normaux pour le transfert de fonds, ce que Easy Exchange Pro ne peut éviter.",
      Fee_description_3_3:
        "Easy Exchange Pro facture des frais réduits sur les retraits pour un jeton ou une pièce avec des frais de transfert réseau intégrés. Les frais de réseau peuvent être consultés en cliquant sur le bouton de retrait dans la fenêtre de retrait pour chaque jeton ou pièce avec des frais de transfert réseau intégrés.",
      Fee_table_1_col_1: "Niveau",
      Fee_table_1_col_2: "Volume de trading sur 30 jours (BTC)",
      Fee_table_1_col_3: "Initiateur",
      Fee_table_1_col_4: "Preneur",
      Fee_table_2_col_1: "Symbole de la devise",
      Fee_table_2_col_2: "Nom de la devise",
      // Gabur
      //// Top
      GBR_top_title_1: "EASY EXCHANGE PRO",
      GBR_top_title_3: "L'évolution de la finance",
      //// Second
      GBR_Second_title_1: "SMART CONTRACT DE SÉCURITÉ",
      GBR_Second_title_2: "Audit du token Gabur",
      GBR_Second_description:
        "Un smart contract est composé d'instructions spéciales stockées dans la blockchain. Ce contrat peut effectuer plusieurs actions selon une série de paramètres préprogrammés de manière immuable, transparente et totalement sécurisée. Par conséquent, le smart contract des tokens GABUR a été audité pour garantir les plus hauts niveaux de sécurité.",
      GBR_Second_Security_btn: "Audit de sécurité",
      //// Description
      GBR_Description_title_1: "Description des tokens Gabur",
      GBR_Description_list_1_1: "Nom du token utilitaire : GABUR",
      GBR_Description_list_1_2: "Symbole : GBR",
      GBR_Description_list_1_3: "Offre totale de tokens GABUR : 550 millions",
      GBR_Description_list_1_4:
        "Les tokens GABUR ne sont pas sujets à une augmentation",
      GBR_Description_list_1_5:
        "Les tokens GABUR fonctionneront nativement sur le réseau BEP20 selon la norme BSC.",
      GBR_Description_list_1_6:
        "Token burn : 165 millions de tokens GABUR seront brûlés",
      GBR_Description_title_2: "Répartition des tokens Gabur",
      GBR_Description_list_2_1: "Vente publique",
      GBR_Description_list_2_2: "Réserve Easy Card",
      GBR_Description_list_2_3: "Équipe",
      GBR_Description_list_2_4: "Conseillers",
      GBR_Description_list_2_5: "Vente privée",
      GBR_Description_list_2_6: "Airdrop",
      //// Contract
      GBR_Contract_subtitle: "Adresse du smart contract du token Gabur",
      //// Added
      GBR_Added_subtitle: "Plan du token Gabur",
      GBR_Added_title: "Quelle est la valeur ajoutée du token Gabur ?",
      GBR_Added_list_1:
        "Développement d'Easy Exchange Pro conformément à notre engagement de roadmap",
      GBR_Added_list_2:
        "Création d'idées innovantes sur le développement d'Easy Card, qui devrait lui permettre de devenir une fonctionnalité importante utilisée dans les transactions",
      GBR_Added_list_3:
        "Les tokens GABUR ne sont pas sujets à une augmentation",
      GBR_Added_list_4:
        "Développement innovant visant à rendre les actifs numériques plus pratiques",
      GBR_Added_list_5: "Brûlage trimestriel des tokens GABUR",
      GBR_Added_list_6: "Période de verrouillage des tokens",
      GBR_Added_list_7:
        "Capacité à accueillir les utilisateurs à la recherche d'un moyen stable et sécurisé pour régler les paiements dans plus de 60 pays",
      GBR_Added_list_8:
        "Avoir une équipe expérimentée à la fois dans le domaine des cryptomonnaies et dans le domaine financier, avec des connexions financières internationales étendues",
      GBR_Added_list_9:
        "Nous ajouterons de nouveaux produits uniques conformément à notre roadmap",
      // Login
      Login_title: "Bienvenue sur Easy Pro",
      Login_subtitle: "Connectez-vous pour continuer",
      Login_btn_Loading: "Connexion",
      Login_problem: "Problème de connexion ?",
      Login_click_here: "Cliquez ici",
      Login_register: "Cliquez ici pour vous inscrire",
      Login_protected:
        "Ce site est protégé par reCAPTCHA et par la politique de confidentialité de Google",
      Login_privacy: "Politique de confidentialité",
      Login_and: "et",
      Login_terms: "Conditions d'utilisation",
      Login_ResetModal_title:
        "Vous pouvez réinitialiser votre 2FA ou votre mot de passe",
      Login_ResetModal_Reset2FA_btn: "Réinitialiser le 2FA",
      Login_ResetModal_ForgetPassword: "Mot de passe oublié ?",
      Login_ResetPassword_title:
        "Entrez votre adresse e-mail pour recevoir le lien de réinitialisation du mot de passe",
      Login_Reset2FA_title:
        "Entrez votre adresse e-mail pour recevoir le lien de réinitialisation du 2FA",
      Login_OTP_title: "Saisissez le code OTP reçu",
      //// Reset Password
      ResetPassword_title: "Réinitialisez votre mot de passe",
      ResetPassword_NewPassword_input: "Nouveau mot de passe",
      ResetPassword_ConfirmPassword_input: "Confirmer le nouveau mot de passe",
      // Privacy And Policy
      Privacy_1_title: "INTRODUCTION",
      Privacy_1_desciption:
        "Thanks indeed for visiting Easyexchangepro.com, as a digital asset trading website. The purpose of this Privacy Policy is to inform you (user, customer) with an understanding that how Easyexchangepro (or we, or our, or us or platform) collect personal information from our clients and prospective clients to provide them with our products and services and ensure that we can meet their needs when providing these products and services, as well as when providing them with any respective information. This privacy policy applies to the Site and all Services offered by Easyexchangepro.",
      Privacy_2_title: "PERSONALLY IDENTIFIABLE",
      Privacy_2_desciption:
        "We may collect personally identifiable information from Users in a variety of ways, including, but not limited to, when Users visit our Site, register on the Site, place an order, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Site anonymously. When interacting with us on the Site, Users can always refuse to supply personal data to us, except that it may prevent them from engaging in certain Site related activities or transactions. We may collect non-personally identifiable information about Users when they interact with our Site.",
      Privacy_3_title: "WHAT INFORMATION DO WE COLLECT?",
      Privacy_3_desciption_1:
        "Easyexchangepro collects information about you when you use our websites (including the Site) and other online products and services and throughout other interactions and services, you have with us. We will collect your personal data in accordance with the legislative purpose of the DPA. We may also collect and store certain information automatically when you visit the Site. Examples include the internet protocol (IP) address used to connect your computer or device to the internet, connection information such as browser type and version, your operating system and platform, a unique reference number linked to the data you enter on our system, log in details, the full URL clickstream to, through and from the Site (including date and time), cookie number and your activity on our Site, including the pages you visited, the searches you made and, if relevant, the products/services you purchased.",
      Privacy_3_desciption_2:
        "We may receive information about you from third parties if you use any websites or social media platforms operated by third parties (for example, Facebook, Instagram, Twitter, etc.) and, if such functionality is available, you have chosen to link your profile on the Site with your profile on those other websites or social media platforms.",
      Privacy_4_title: "INDIVIDUAL AND COMPANY WHICH WE MAY COLLECT INCLUDE:",
      Privacy_4_subtitle_1: "For individual:",
      Privacy_4_list_1_1: "• Email address",
      Privacy_4_list_1_2: "• Mobile phone number",
      Privacy_4_list_1_3:
        "• Full legal name (including former name, and names in local language)",
      Privacy_4_list_1_4: "• Nationality",
      Privacy_4_list_1_5:
        "• Passport number, or any government-issued ID number",
      Privacy_4_list_1_6: "• Date of birth",
      Privacy_4_list_1_7:
        "• Proof of identity (e.g., passport, driver’s license, or government-issued ID)",
      Privacy_4_list_1_8: "• Residential address",
      Privacy_4_list_1_9: "• Proof of residency",
      Privacy_4_list_1_10:
        "• Additional Personal Data or documentation at the discretion of our Compliance Team",
      Privacy_4_subtitle_2: "For companies:",
      Privacy_4_list_2_1:
        "• Company legal name (including the legal name in local language)",
      Privacy_4_list_2_2: "• Incorporation/registration Information",
      Privacy_4_list_2_3:
        "• The full legal name of all beneficial owners, directors, and legal representatives",
      Privacy_4_list_2_4:
        "• Address (principal place of business and/or other physical locations)",
      Privacy_4_list_2_5: "• Proof of legal existence",
      Privacy_4_list_2_6: "• Description of the business",
      Privacy_4_list_2_7:
        "• Percentage of ownership for Individual/company owners",
      Privacy_4_list_2_8:
        "• Contact information of owners, principals, and executive management (as applicable)",
      Privacy_4_list_2_9:
        "• Proof of identity (e.g., passport, driver’s license, or government-issued ID) for the significant individual beneficial owner of the institutional customer entity",
      Privacy_4_list_2_10:
        "• Personal Data for each entity’s significant beneficial owner of the institutional customer entity (see the “Individual Customer” section above for details on what Personal Data we collect for individuals)",
      Privacy_4_list_2_11: "• Source of wealth",
      Privacy_4_list_2_12:
        "• Amount of bitcoin or other digital assets projected to be injected",
      Privacy_5_title: "COOKIES USAGE",
      Privacy_5_desciption:
        "When you access our Sites and Services, we or companies we work with may place cookies on your computer or another device. Of course, you can block or deactivate cookies in your browser settings. But these technologies help us better understand user behavior and inform us about which parts of our websites people have visited. We use first-party and third-party cookies to recognize you as an Easyexchangepro user, customize Coinbase Services, content, and advertising, measure promotional effectiveness, and collect information about your computer or another access device to mitigate risk, helping prevent fraud, and promote trust and safety. We may place cookies from third-party service providers who may use information about your visits to other websites to target advertisements for products and services available from Easyexchangepro. We do not control the types of information collected and stored by these third-party cookies. You should check the third-party's website for more information on how they use cookies.",
      Privacy_6_title: "LAWFUL BASIS FOR PROCESSING YOUR PERSONAL INFORMATION",
      Privacy_6_desciption_1:
        "We will process your personal information on the following bases and for the following purposes:",
      Privacy_6_desciption_2:
        "carrying out your instructions or responding to any inquiry given by (or purported to be given by) you or on your behalf including responding to your inquiries and complaints, or responding to or dealing with your interactions with us; registering you as a customer of Easyexchangepro and/or to deal with, process and/or administer the account that you may open with us, whether a membership account or otherwise, including to facilitate your transactions or activities on the Site or your transactions or activities with us; administering, facilitating, processing and/or dealing with your relationship with us, any transactions or activities carried out by you on the Site or with us. This includes processing your application, orders, and payment transactions; implementing transactions and the supply of products and/or services to you that you have requested; administering, facilitating, processing, and/or dealing in any matters relating to your use or access of the Site, including identifying you for login to the Site, our portals and other online services provided by or on behalf of us; monitoring, processing and/or tracking your use of the Site in order to provide you with a seamless experience, facilitating or administering your use of the Site, and/or to assist us in improving your experience in using the Site; assessing and processing your request for the purchase of and/or subscription to our products and/or services. contacting you or communicating with you via phone/voice call, text message and/or fax message, email and/or postal mail for the purposes of administering and/or managing your use of the Site, your membership and/or account with us, your relationship with us or any transactions made by you with us.",
      Privacy_7_title: "HOW WE USE YOUR PERSONAL DATA",
      Privacy_7_desciption_1:
        "Easyexchangepro uses Personal Data to communicate with you and to administer, deliver, improve, and personalize the Service. Easyexchangepro might also generate generic data out of any Personal Data we collect and use it for our own purposes. We may also use such data to communicate with you in relation to other products or services offered by Easyexchangepro and/or its partners. We do not share your Personal Data with third parties (other than partners in connection with their services to Easyexchangepro) except where you have given your consent and further detailed below.",
      Privacy_7_desciption_2:
        "We may share your Personal Data with third parties:",
      Privacy_7_desciption_3:
        "If we think that sharing it is necessary to enforce the Terms of Service. To comply with government agencies, including regulators, law enforcement, and/or justice departments. To third parties who provide services to Easyexchangepro (such as administration or technical services). In connection with the sale or transfer of our business or any part thereof.",
      Privacy_7_desciption_4:
        "Additionally, we have implemented international standards to prevent money laundering, terrorist financing and will implement final Digital Asset rules and regulations when effective, which will likely require us to undertake due diligence on our customers. This may include the use of third-party data and service providers which we will cross-reference with your personal information.",
      Privacy_8_title: "STORING YOUR PERSONAL DATA",
      Privacy_8_desciption:
        "The data that we collect from you may be transferred to and stored at, a destination outside of the U.A.E. It may also be processed by staff operating outside of U.A.E who work for us or for one of our suppliers. By submitting your personal data, you agree to this transfer, storing, or processing, except for customers located in the EEA, as detailed above. All information you provide to us is stored on our and/or third-party cloud servers",
      Privacy_9_title: "MARKETING COMMUNICATIONS",
      Privacy_9_desciption:
        "We may use your personal information to send you marketing communications by email or other agreed forms (including social media campaigns), to ensure you are always kept up to date with our latest products and services. If we send you marketing communications, we will do so based on your consent and registered marketing preferences. Users can opt out of these marketing communications at any moment. If you do not want to receive these communications, please send an email to support@easyexchangepro.com",
      Privacy_10_title: "DATA SECURITY",
      Privacy_10_desciption:
        "We endeavor to protect Easyexchangepro and you from unauthorized access, alteration, disclosure, or destruction of Personal Data we collect and store. We take various measures to ensure information security, including encryption of the Easyexchangepro communications with SSL; required two-factor authentication for all sessions; periodic review of our Personal Data collection, storage, and processing practices; and restricted access to your Personal Data on a need-to-know basis for our employees and vendors who are subject to strict contractual confidentiality obligations.",
      Privacy_11_title:
        "TRANSFER OF PERSONAL INFORMATION OUTSIDE OF THE EEA and UK and YOUR COUNTRY",
      Privacy_11_desciption:
        "We may transfer your personal information outside the EEA and UK to other Company subsidiaries, service providers, and business partners (i.e., Data Processors) who are engaged on our behalf. To the extent that we transfer your personal information outside of the EEA and UK, we will ensure that the transfer is lawful and that Data Processors in third countries are obliged to comply with the European Union (EU) General Data Protection Act 2016 and the UK Data Protection Act 2018. Also, by using our products and services, you consent to your Personal Data being transferred to other countries, including countries that have differing levels of privacy and data protection laws than your country. In all such transfers, we will protect your personal information as described in this Privacy Notice and ensure that appropriate information sharing contractual agreements are in place.",
      Privacy_12_title: "COMPLIANT PROCESS",
      Privacy_12_desciption:
        "If you have any complaint or grievance regarding how we are handling your personal data or about how we are complying with the CPT (when in force), we welcome you to contact us with your complaint or grievance and should be addressed to CPT@Easyexchangepro.com Where you are sending an email in which you are submitting a complaint, your indication at the subject header that it is a CPT complaint would assist us in attending to your complaint speedily by passing it on to the relevant staff in our organization to handle. We will certainly strive to deal with any complaint or grievance that you may have speedily and fairly.",
      Privacy_13_title: "YOUR ACCEPTANCE",
      Privacy_13_desciption:
        "By using Easyexchangepro Site, you signify your acceptance of this policy and Terms of Use.",
      Privacy_14_title: "CHANGES TO THIS PRIVACY POLICY",
      Privacy_14_desciption:
        "Any changes we may make to our Privacy Policy in the future will be posted on this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.",
      // Product Convert
      Convert_title: "Convertir",
      Convert_for_each: "Pour chaque",
      Convert_receive: "vous recevrez",
      Convert_calc: "Calculer la conversion",
      // Product Market Tabs
      Market_tab_Spot: "Spot",
      Market_tab_Favorite: "Favoris",
      // Product OTC
      OTC_title: "Bureau OTC Easy Pro",
      OTC_description:
        "Les traders qui souhaitent échanger des cryptomonnaies, notamment en grandes quantités, décident généralement d'utiliser le trading de cryptomonnaies de gré à gré (OTC). Le bureau OTC Easy Pro vous propose des services d'exécution et de règlement confidentiels, sécurisés et très compétitifs.",
      OTC_Feature_1:
        "Nous effectuons des transactions OTC de manière confidentielle, sans impact significatif sur les marchés.",
      OTC_Feature_2:
        "Notre bureau de trading respecte la confidentialité avec tous les protocoles de sécurité sur Easy Pro.",
      OTC_Feature_3:
        "Les règlements s'effectuent dans l'écosystème Easy Pro et sont généralement finalisés.",
      OTC_Feature_4: "Le règlement sera effectué en quelques minutes.",
      OTC_Feature_5: "Il n'y a pas de tiers sur notre bureau.",
      OTC_Feature_6:
        "Les échanges se font dans le compte Easy Pro, vous n'avez donc pas besoin de créer un nouveau portefeuille.",
      OTC_Feature_7:
        "Les cryptomonnaies que vous échangez seront simplement déplacées dans et hors de votre compte manuellement par notre équipe.",
      OTC_Feature_8:
        "Les détails des transactions ne peuvent être consultés que dans la section « Historique des transactions » de votre compte.",
      OTC_Support_title: "Support OTC",
      OTC_Support_description:
        "Le bureau OTC offre une couverture mondiale et est ouvert 24h/24, 7j/7, à l'exception des jours fériés importants. OTC@Easyexchangepro.com",
      OTC_list_1: "Veuillez remplir le formulaire OTC et nous l'envoyer.",
      OTC_list_2:
        "Notre équipe est disponible pour vous fournir un devis, effectuer des transactions et régler.",
      OTC_list_3:
        "Une fois que vous avez accepté notre offre, la transaction est confirmée et notre équipe vous enverra les détails et les instructions de la transaction par e-mail.",
      OTC_list_4:
        "Lorsque nous recevons votre part de l'accord, le bureau OTC procédera au règlement de notre part sur votre portefeuille ou votre compte bancaire.",
      // Product Trade
      Trade_KYC_Error: "Veuillez télécharger votre KYC",
      Trade_Trading_password_Error:
        "Veuillez activer le mot de passe de trading",
      Trade_Trading_password_title: "Entrez votre mot de passe de trading",
      Trade_Trading_password_input: "Mot de passe de trading",
      Trade_Trading_Password_btn: "Entrer le mot de passe de trading",
      Trade_goto_security: "Aller à la sécurité",
      Trade_FAV: "FAV",
      Trade_Open_Orders: "Ordres ouverts",
      Trade_Order_history: "Historique des ordres",
      Trade_Trade_history: "Historique des transactions",
      Trade_Date_Time: "Date et heure",
      Trade_Type: "Type",
      Trade_Action: "Action",
      Trade_Login_Register: "Connexion/Inscription",
      Trade_Password_Error: "Veuillez entrer votre mot de passe.",
      Trade_Balance_Error: "Solde insuffisant, vous avez",
      Trade_Min_Error: "L'ordre doit être supérieur à 20 USDT",
      Trade_approximately: "Vous recevrez environ",
      Trade_Market: "Marché",
      Trade_Order_List: "Liste des ordres",
      Trade_Order_History: "Historique des ordres",
      Trade_Pairs: "Paires",
      Trade_Open_Chart: "Ouvrir le graphique",
      // Profile
      Profile_profile: "profil",
      Profile_security: "sécurité",
      Profile_history: "historique",
      Profile_Security_log_history: "Historique des journaux de sécurité",
      Profile_Login_history: "Historique de connexion",
      Profile_User_info_title: "Informations sur l'utilisateur",
      Profile_User_info_description:
        "Vos informations de compte uniques, que vous devez garder en sécurité.",
      Profile_UID: "UID",
      Profile_Sec_info_title: "Informations de sécurité",
      Profile_Activated: "Activé",
      Profile_Disabled: "Désactivé",
      Profile_Trading_Password: "Mot de passe de trading",
      Profile_Account_status_title: "Statut du compte",
      Profile_KYC: "KYC",
      Profile_Sec_level: "Niveau de sécurité",
      Profile_Airdrop_point: "Points Airdrop",
      Profile_Referrals_title: "Parrainages",
      Profile_Num_Referrals: "Nombre de parrainages",
      Profile_Referral_ID: "ID de parrainage",
      Profile_Referral_List_btn: "Liste de parrainage",
      Profile_Commissions_btn: "Commissions",
      Profile_User: "Utilisateur",
      Profile_KYC_Verified: "Vérifié",
      Profile_KYC_Pending: "En attente",
      Profile_KYC_Rejected: "Rejeté",
      Profile_KYC_NotUploaded: "Non téléchargé",
      Profile_Sec_lvl_Medium: "Moyen",
      Profile_Sec_lvl_VeryHigh: "Très élevé",
      Profile_Account_Password_title: "Mot de passe du compte",
      Profile_Account_Password_description:
        "Vous pouvez changer votre mot de passe ici.",
      Profile_Change_Password_btn: "Changer le mot de passe",
      Profile_Change_your_password_title: "Changer votre mot de passe",
      Profile_Current_Password: "Mot de passe actuel",
      Profile_New_Password: "Nouveau mot de passe",
      Profile_Confirm_New_Password: "Confirmer le nouveau mot de passe",
      Profile_Sec_Authenticaton_title: "Authentification à deux facteurs",
      Profile_Sec_Authenticaton_description:
        "Vous pouvez activer/désactiver votre 2FA ici.",
      Profile_Active_2FA: "Activer le 2FA",
      Profile_Activating_2FA: "Activation du 2FA",
      Profile_Disable: "Désactiver",
      Profile_Disable_2FA: "Désactiver le 2FA",
      Profile_2FA_Code: "Code 2FA",
      Profile_Active: "Actif",
      Profile_Trading_Password_description:
        "Vous pouvez changer votre mot de passe de trading ici.",
      Profile_Change_trading_password: "Changer le mot de passe de trading",
      Profile_Change_your_Trading_password_title:
        "Changer votre mot de passe de trading",
      Profile_Change_your_Trading_password_description:
        "Voulez-vous changer votre mot de passe de trading ?",
      Profile_forgot_code: "code oublié",
      Profile_KYC_title: "KYC du compte personnel",
      Profile_KYC_select_doc: "Sélectionnez votre type de document",
      Profile_KYC_select_doc_ID: "Carte d'identité",
      Profile_KYC_select_doc_pass: "Passeport",
      Profile_KYC_select_doc_Drive:
        "Permis de conduire avec numéro d'identification",
      Profile_KYC_Upload_title: "Veuillez télécharger vos documents :",
      Profile_KYC_Front: "Recto du document",
      Profile_KYC_Back: "Verso du document",
      Profile_KYC_Selfie: "Selfie avec le document dans votre main",
      Profile_KYC_Approved: "Votre KYC est déjà approuvé.",
      Profile_KYC_Pending: "Votre KYC est en attente.",
      Profile_Currency: "Devise",
      Profile_Type: "Type",
      Profile_Login: "Connexion",
      Profile_Region: "Région",
      Profile_Login_device: "Appareil de connexion",
      // Register
      Reg_verify_email: "Vérifier l'e-mail",
      Reg_enter_otp: "Entrez le code OTP",
      Reg_Confirm_Password: "Confirmez le mot de passe",
      Reg_Referral_Code: "Code de parrainage (facultatif)",
      // Terms Of Use
      Term_1_title: "1-Welcome to Easy Pro",
      Term_1_description_1:
        "Thanks indeed for visiting (Easyexchangepro / we, hereinafter referred to as the Easy pro) By visiting, accessing, or using easyexchangepro.com and associated application program interface or mobile applications (platforms), You (“User” or “You”) consent to the Terms of Service (the “Terms”), so please read them carefully.",
      Term_1_description_2:
        "The Terms constitute the agreement and understanding regarding the use of any or all the Services, and any manner of accessing them, between You and the following service provider (“we,” “us” or “our” platform) Easyexchangepro.",
      Term_2_title: "2-Key Definitions",
      Term_2_description_1:
        "Capitalized terms not otherwise defined in these Terms will have the following meaning:",
      Term_2_description_2:
        "“External Account” means any Digital Token Account: from which you may transfer funds into your Easy Pro  Account, or to which you may transfer funds from your Easy Pro Account and which has been approved by Easy pro for the foregoing purposes.",
      Term_2_description_3: "“Funds” means Digital Tokens.",
      Term_2_description_4: "“Fiat Currency” means any national currency.",
      Term_2_description_5:
        "“Easy Pro Account” means a user account accessible via the Services where Funds may be stored by Easy pro on behalf of a user.",
      Term_2_description_6:
        "“Easy Pro Help Center” means Easy pro’s customer service center with address at Support@easyexchagepro.com -us where you could get online support from Easy pro.",
      Term_2_description_7:
        "“Digital Tokens” means Bitcoins, Ripples, and other digital mediums of exchange that may be purchased, sold, or traded via the Services.",
      Term_2_description_8:
        "“Digital Token Account” means any Digital Token address or account owned or operated by you that is maintained outside of the Services, and is not owned, controlled, or operated by Easyexchangepro.",
      Term_3_title: "3- Accept terms",
      Term_3_description_1:
        "You should read the Terms, and any document referred to in them very carefully. If there is anything that You do not understand in the Terms and any document referred to herein, you should discuss this matter with us and seek the necessary clarification.",
      Term_3_description_2:
        "You are at least 18 years of age and have the full capacity to accept these Terms and enter a transaction involving digital assets. You have not been deprived of the right to use our service and have the full capacity for legal action. If you do not meet the above condition, please do not register our Site or Platform, otherwise, the platforms may suspend or terminate your account at any time.",
      Term_3_description_3:
        "You’re entering and performing these Terms are not prohibited by the laws and regulations of the country or region to which you belong, reside, pay tax or carry out business activities or other business. If you do not meet the above conditions, you should immediately terminate the registration or stop using our Platforms services.",
      Term_3_description_4:
        "Our Platform has the right to modify these Terms from time to time or to formulate and modify various specific rules according to these Terms and publish them in the relevant system sections of Platforms without separately notifying you.",
      Term_3_description_5:
        "You should pay attention to the changes in these Terms and the specific rules from time to time. If you continue to use the Service after the changes in the content of these Terms and the specific rules, you are deemed to have fully read, understood, and accepted the amended Terms and the specific rules and to use the services of our Platforms in accordance therewith.",
      Term_3_description_6:
        "You also agree that Easy Pro may, by giving notice, at its sole discretion immediately terminate your access to our Platform and to your account. You agree and acknowledge our right to limit, suspend or terminate the service and your user account, prohibit your access to our Platform and their content, services, and tools, delay or remove hosted content, and take technical and legal steps to keep you off our Platform or any further steps to recover any loss or damages caused if we determine at our sole discretion that you are or may be in breach of any laws, regulations, the rights of third parties, or any of these Terms or Platform’s policies. Easy Pro shall not be liable for any loss or damage caused to you or any third party because of exercising our rights under this clause.",
      Term_4_title: "4- Our Trading Services",
      Term_4_description:
        "Easy Pro provides an online platform with a simple and convenient way to trade one type of digital asset or another type of digital asset. Easy Pro provides you with a platform that matches your orders with open orders from other users of our Services.",
      Term_4_subheader_1: "Spot.",
      Term_4_subtitle_1: "Market Order:",
      Term_4_description_1:
        "A Market Order is placed to buy or sell a Specified Quantity or a Specific Gross Amount of Targeted tokens at the best available Latest Market Price.",
      Term_4_subtitle_2: "Limit Order:",
      Term_4_description_2:
        "A Limit Order is placed to buy or sell a Specific Quantity of the Targeted Token at a Specific Price and shall be filled at such Specific Quantity and Specific Price.",
      Term_4_subtitle_3: "Stop Market Order:",
      Term_4_description_3:
        "A Stop Market Order is placed to buy a Specific Quantity of Targeted Token or to buy a Specific Gross Amount of Targeted Token at the Latest Market Price when the upper limit of the Preset Stop Price is triggered. This means that when the Latest Market Price reach or beyond the upper limit of the Preset Stop Price, this order will be executed and filled at the Latest Market Price which may end up higher than the upper limit of the Preset Stop Price.",
      Term_4_subtitle_4: "Stop Limit Order:",
      Term_4_description_4:
        "A Stop Limit Order is placed to sell a Specific Quantity of Targeted Token at the Specific Price when the inferior limit of Preset Stop Price is triggered. This means that when the Latest Market Price reaches or is below the inferior limit of the Preset Stop Price, this order will be executed and filled at the Specific Quantity as well as the Specific Price.",
      Term_4_subheader_2: "Easy card",
      Term_4_description_5:
        "Refers to transactions in which Gabur tokens are exchanged for fiat currency and deposited in the account of the user.",
      Term_5_title: "5- Scope of Service and Your Rights and Obligations",
      Term_5_description_1:
        "Users shall not register multiple accounts for any purpose. Users may not use another User’s accounts. Users are prohibited in any form from utilizing the Site to engage in illegal activities. Without the authorization or permission of Easy Pro, the User shall not use any of the commercial activities in the name of this Site or in any form of the Site as a place, platform, or medium for engaging in commercial activities.",
      Term_5_description_2:
        "Users shall comply with all laws and regulations and bear the responsibility and legal consequences of any of their own actions involving the Site and Services.",
      Term_5_description_3:
        "Users have the right to view the information regarding their accounts on our Platform and have the right to operate the functions provided by our Platform. The user understands and agrees that our Platform can adjust the service content, service type, and service form on the Platform at any time according to the actual situation at our sole discretion. Our Platform is not responsible for any negative impact or loss caused to you or any third party due to Platform’s adjustments.",
      Term_5_description_4:
        "Depending on your country of residence, you may not be able to use all the functions of the Site or the Platform. Without prejudice to our right of suspending certain services to users from a specific country, it is your responsibility to ensure that in accessing our Site, Platform, and services, you are complying with the laws and regulations in your country of residence and/or the country from which you access this Site, the Platform, and our services. you are responsible for any disputes or losses caused by your failure to promptly modify or confirm the status of the transaction or failure to submit the relevant application. Our Platforms do not assume any responsibility.",
      Term_5_description_5:
        "Engaging in trades in cryptographic tokens may be highly risky. Please do not use Easy Pro’s services for trading in Digital assets if you do not understand these risks.",
      Term_6_title: "6- Registration Process",
      Term_6_description_1:
        "You agree to provide a valid email address, a mobile phone number, and other information in accordance with the requirements on the user registration page of this platform. You can use the email address, mobile phone number or any other manner permitted to log in to this platform. Where it is necessary and in accordance with the requirements of applicable laws and regulations of relevant jurisdictions, you shall provide your real name, identity card, and other information required by applicable laws, regulations, the Terms of Privacy, and anti-money-laundering terms, and constantly update your registration data so that they will be timely, detailed, and accurate as is required. All the original typed data will be referenced as registration information. You shall be responsible for the authenticity, integrity, and accuracy of such information and bear any direct or indirect loss and adverse consequences arising out of it.",
      Term_6_description_2:
        "If any of the applicable laws, regulations, rules, orders, and other regulatory documents of the sovereign country or region in which you are based requires that mobile phone accounts must be based on real names, you hereby confirm that the mobile phone number you provide for registration purposes has gone through the real-name registration procedure. If you cannot provide such a mobile phone number as is required, any direct or indirect losses and adverse consequences arising therefrom and affecting you shall be borne by you.",
      Term_6_description_3:
        "After you provide the required registration information in a legal, complete, and valid manner and such information passes relevant verification, you shall have the right to obtain an account and a password of Easy exchange pro. Upon obtaining such an account and password, your registration shall be deemed as successful, and you can log into the platform as a member thereof. You agree to receive emails and/or short messages sent by the platform related to the management and operation thereof.",
      Term_6_description_4:
        "Particularly during periods of high volume, illiquidity, fast movement, or volatility in the marketplace for any, or one or more Digital Tokens, the actual market rate at which a trade is executed may be different from the prevailing rate indicated via the Services at the time of your order or trade. You understand that we are not liable for any such price fluctuations. In the event of a market disruption or Force Majeure event, Easy exchange pro may do one or more of the following: suspend access to the Services; or prevent you from completing any actions via the Services, including closing any open positions. Following any such event, when trading resumes, you acknowledge that prevailing market rates may differ significantly from the rates available prior to such an event.",
      Term_6_description_5:
        "You hereby authorize Easy exchange pro to attempt and/or perform any such correction, reversal, cancellation, voiding, or amendment described in this section. Easy exchange pro. provides no guarantee or warranty that any such attempt will be successful and will have no responsibility or liability for the Manifest Error or the making or failure of any correction attempt, except if caused by the gross negligence, willful default, or fraud of Easy exchange pro. There is a risk that you may be prejudiced by any action or omission of Easy exchange pro in this regard, and you accept that you have no recourse whatsoever against Easy exchange pro.",
      Term_7_title: "7- Fees",
      Term_7_description_1:
        "You agree to pay for transactions through our Fees Structure service, which may change from time to time. Changes to the Fee Structure are effective as of the effective date indicated in the posting of the revised Fee Structure to the Services and will apply prospectively to any trades that take place following the effective date of such revised Fee Structure.",
      Term_7_description_2:
        "In addition to the costs, your external account may incur costs in connection with your use of your designated external account through the Services. Costs incurred by your external account provider are not reflected on the Transactions page, which contains information about applicable costs. You are solely responsible for paying any fees imposed by an External Account provider.",
      Term_7_description_3:
        "You authorize us, or our designated payment processor, to charge or deduct your Easy Pro Account Funds for any applicable Fees owed in connection with trades you complete via the Services.",
      Term_8_title: "8- Communication",
      Term_8_description_1:
        "You agree and consent to receive electronically all communications, agreements, documents, receipts, notices, and disclosures (collectively, “Communications”) that Easy Pro provides in connection with your Easy Pro Account and/or use of the Easy Pro Services.",
      Term_8_description_2:
        "Updating Contact Information. It is your responsibility to keep your email address on file with Easy Pro up to date so that Easy exchange pro can communicate with you electronically.",
      Term_9_title: "9- Intellectual Property",
      Term_9_description:
        "Unless otherwise indicated by us, all copyright and other intellectual property rights in all content and other materials contained on our website/platform or provided in connection with the Services, including, without limitation, the Easy Pro logo and all designs, text, graphics, pictures, information, data, software, sound files, other files and the selection and arrangement thereof are the proprietary property of Easy Pro or our licensors or suppliers and are protected by international copyright laws and other intellectual property rights laws.",
      Term_10_title: "10- Unclaimed or Disputed Property",
      Term_10_description_1:
        "If for any reason Easy Pro is holding Funds in your Easy  Account, such Funds remain unclaimed, and Easy Pro is unable to return your Funds to your designated External Account after a period of three years after your last login to Easy Pro or any specified period notified by Easy Pro, and Easy Pro determines in good faith that it is not able to trace you, you agree that all such Funds shall be deemed to have been abandoned by you in favor of Easy Pro and may be appropriated by Easy Pro to and for itself, and you thereafter shall have no right to claim such Funds.",
      Term_10_description_2:
        "If Easy Pro receives notice that any Digital Tokens held in your Easy Pro Account are alleged to have been stolen or otherwise are not lawfully possessed by you, Easy Pro may, but has no obligation to, place an administrative hold on or freeze the affected Digital Tokens or your Easy Pro Account. If Easy Pro does place an administrative hold on or freeze some or all your Digital Tokens, Easy Pro may continue such hold until such time as the dispute has been resolved and evidence of the resolution acceptable to Easy Pro has been provided to Easy Pro in a form acceptable to Easy Pro. Easy Pro will not involve itself in any such dispute or the resolution of the dispute. You agree that Easy Pro will have no liability or responsibility for any losses, damages, or prejudice arising from or in connection with such hold or freeze, or for your inability to withdraw Digital Tokens or execute trades during the period of any such hold or freeze.",
      Term_11_title: "11- Complains about your local laws",
      Term_11_description:
        "It is your sole responsibility to determine whether, and to what extent, any taxes apply to any transactions You conduct through the Services, and to withhold, collect, report, and remit the correct amounts of taxes to the appropriate tax authorities. When using our Services, you acknowledge that your actions are in a legal and proper manner and your financial sources are not from illegal activities. We may discretionarily or in coordination with local law enforcement authorities seize, restrict or close out your account, fiat, and/or cryptocurrencies.",
      Term_12_title: "12- Suspension; Termination",
      Term_12_description_1:
        "In the event of any Force Majeure Event breach of these. Terms or Conditions, or any other event where the provision of services to Easy pro is commercially unreasonable, we may, at our sole discretion and without liability to you, with or without prior notice, grant you access to all or part of Suspend it. We may also immediately, without notice, terminate your access to the Services at our sole discretion and delete or deactivate your Easy exchange pro account and all related information and files on this account without liability to you, including in cases where you are violating one of these conditions. In the event of discontinuation of all Services or termination of your access to the Services or deletion or deactivation of your Easy Pro Account: all amounts payable by you to Easy Pro will immediately become due. the platform can cancel any open orders or other transaction requests that are pending at the time of discontinuation or termination or deletion or deactivation. the platform will, where possible, return any Funds stored in your Easy Exchange pro Account not otherwise owed to Easy Pro and/or will use commercially reasonable efforts to provide you with a period 4 months to transfer affected Digital Tokens from your Easy Pro Account, unless prohibited by applicable laws or regulations or by order of law enforcement or governmental authority, or Easy Pro believes you have committed fraud, negligence or other misconduct; and/or may take such other steps as Easy Pro deems necessary or desirable to protect its own interests. We are not and shall not be responsible or liable for any loss or damages incurred because of or arising from any actions taken under this section.",
      Term_12_description_2:
        "Also, if the user has not made any deposits, transactions, or logins for a period of three months by any reason, we may delete or deactivate the user's account, without notifying the User and without assuming any responsibility",
      Term_13_title: "13- Descramble of liability",
      Term_13_subtitle_1: "13.1 Network Service Provider:",
      Term_13_description_1:
        'as a third-party platform for "network service provider", does not guarantee the information and services provided on this Site can fully meet the needs of users. We shall not be liable for errors, insults, defamation, nonfeasance, obscenity, pornography, or blasphemy that may occur during the process of acceptance of the Parties’ web service.',
      Term_13_subtitle_2: "13. 2 Service Interruption:",
      Term_13_description_2:
        "Based on the special nature of the Internet, we do not guarantee that the service will not be interrupted; the timeliness and security of the service are also not guaranteed.",
      Term_13_subtitle_3: "13.3 Safety of the Network:",
      Term_13_description_3:
        "We try to provide a safe network environment to the users; however, we do not guarantee that the Site or its servers are free of viruses or other potentially harmful factors; therefore, the user should use the industry’s recognized software to check and kill any virus in the files downloaded from the Site.",
      Term_13_subtitle_4: "13. 4 User Information:",
      Term_13_description_4:
        "we are not responsible for the failure of preservation, modification, deletion, or storage of the information provided by the user. Nor will we be liable for the typographical errors, negligence, etc. We have the right but no obligation to improve or correct any omission, error of any part of this Site.",
      Term_13_subtitle_5:
        "13. 5 Accuracy, Completeness, and Reliability of the Site:",
      Term_13_description_5:
        "Unless we have expressly agreed in writing, we shall not guarantee the accuracy, completeness, reliability of any content, such as but not limited to, advertising from the platform in any manner (including but not limited to, containing, connecting, by way of, or downloading) from the Site; we are not responsible for any products, services, information or materials purchased or obtained by the user according to the content information on this website. The user bears the risk of using the content of this website.",
      Term_13_subtitle_6: "13. 6 User Opinion:",
      Term_13_description_6:
        "The user comments published by users of the Site, are only on behalf of the user’s personal point of view. It does not mean that this Site agrees with their views or confirms their description. This Site does not bear any legal responsibility caused by any user comments.",
      Term_13_subtitle_7: "13. 7 Announcements:",
      Term_13_description_7:
        "Regarding notices issued to the users, we will deliver these notices through a formal page announcement, station letter, e-mail, customer service phone call, SMS, or regular mail delivery. We do not bear any legal responsibility for any winning, discount activities or information that are delivered by other channels.",
      Term_13_subtitle_8:
        "13. 8 Charges, Commissions, Transactions, and Other Fees:",
      Term_13_description_8:
        "we have the right to adjust the recharge, commission, transaction, and other fees according to market conditions, and terminate any promotional efforts early.",
      Term_14_title: "14- Jurisdiction",
      Term_14_description:
        "These Terms shall be governed by and construed in accordance with the laws of Dubai. The courts of Dubai shall have exclusive jurisdiction to settle any dispute arising from or connected with these Terms (including a dispute relating to the existence, validity, or termination of these Terms or the consequences of their nullity or any non-contractual obligation arising out of or in connection with these Terms).",
      Term_15_title: "15. Miscellaneous",
      Term_15_description_1:
        "These Terms set forth the complete understanding of the parties with respect to the subject matter hereof and supersedes all prior understandings and communications relating thereto our Platforms reserve the right of final interpretation of these Terms within the scope permitted by law. These Terms and related pages of our Platforms may refer to each other. If there is any conflict, these Terms shall prevail. In addition, if some of the provisions in these Terms are deemed invalid or unenforceable, the other provisions in these Terms will remain in effect. In the event of any conflict between these Terms and any other agreement, you may have with Easy Exchange pro the terms of that other agreement will prevail only if these Terms are specifically identified and declared to be overridden by such other agreement.",
      Term_15_description_2:
        "Any failure or delay by Easy Exchange Pro to enforce any of these Terms or to exercise any right hereunder shall not be construed as a waiver to any extent of our rights.",
      Term_16_title: "16- Limitation of Liability",
      Term_16_description:
        "Except as otherwise required by law, in no event shall Easy exchange pro, our directors, officers, employees, agents, Brokers or contractors be liable for any special, indirect, or consequential damages, or any other damages of any kind, including but not limited to loss of use, loss of profits or loss of data, whether in an action in contract, tort (including but not limited to negligence) or otherwise, arising out of or in any way connected with the use of or inability to use our Services or the Easy exchange pro Materials, including without limitation any damages caused by or resulting from reliance by any user on any information obtained from Easy exchange pro , or that result from mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses, delays in operation or transmission or any failure of performance, whether or not resulting from a force majeure event, communications failure, theft of, destruction of or unauthorized access to Easy exchange pro’s records, programs or services. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the limitations of this section may not apply to you.",
      Term_17_title: "17- Miscellany",
      Term_17_subtitle_1: "17 -1 Agency",
      Term_17_description_1:
        "Nothing in this Agreement shall be deemed to have created, implied, or otherwise treated us as your agent, trustee, or another representative unless it is provided otherwise in this Agreement.",
      Term_17_subtitle_2: "17- 2 Waiver",
      Term_17_description_2:
        "Our or your waiver of the right to hold the other party liable for breaches of agreement or any other liability as is agreed upon in this Agreement shall not be construed or deemed as a waiver of the right to hold the other party for other breaches of contract; a failure to exercise any right or remedy shall not be construed in any way as a waiver of such right or remedy.",
      Term_17_subtitle_3: "17- 3 Goodwill",
      Term_17_description_3:
        "You acknowledge and agree that, before filing any dispute or claim, you will contact us through support@easyexchangepro.com to solve the dispute in an informal way where we will try to solve the relevant dispute internally as soon as possible; The Parties agree to negotiate and solve any dispute in good faith (such negotiation shall be confidential and protected under applicable rules, and will not be used as evidence in any proceeding).",
      Term_17_subtitle_4: "17- 4 Assignment of rights",
      Term_17_description_4:
        "The rights and obligations agreed in this Agreement shall be equally binding on the assignees, the heirs, executors, and administrators of the parties hereto who benefit from the rights and obligations. Without our consent, you may not transfer to any third party any of your rights or obligations hereunder, provided, however, we may, at any time, assign our rights and obligations under this Agreement to any third party with forty (40) days' notices to you.",
      Term_17_subtitle_5: "17- 5 Severability",
      Term_17_description_5:
        "The invalidity or unenforceability of any of these Terms shall not affect the validity or enforceability of any other of these Terms, all of which shall remain in full force and effect.",
      Term_17_subtitle_6: "17- 6 Governing language and translations",
      Term_17_description_6:
        "These Terms, the Privacy Policy, and other agreements or communications notified through the Services have been drafted in English. Although translations in other languages of any of the foregoing documents may be available, such translations may not be up to date or complete. Accordingly, you agree that in the event of any conflict between the English language version of the foregoing documents and any other translations thereof, the English language version of such documents shall govern and prevail.",
      // Token Listing
      TL_title: "Remplissez le formulaire pour répertorier votre jeton",
      TL_Exchange_info: "Informations sur l'échange",
      TL_Token_Name: "Nom du jeton",
      TL_Token_Name_Error: "Veuillez entrer le nom du jeton.",
      TL_Token_symbol: "Symbole du jeton",
      TL_Token_symbol_Error: "Veuillez entrer le symbole du jeton.",
      TL_Token_Symbol_upload_Error: "Veuillez télécharger le symbole du jeton.",
      TL_NFT: "NFT",
      TL_Total_supply_Error: "Veuillez entrer l'offre totale.",
      TL_Date_of_issue: "Date d'émission",
      TL_Date_of_issue_Error: "Veuillez entrer la date d'émission.",
      TL_Total_circulation: "Circulation totale",
      TL_Total_circulation_Error: "Veuillez entrer la circulation totale.",
      TL_Market_cap_circulation: "Capitalisation boursière en circulation",
      TL_Market_cap_circulation_Error:
        "Veuillez entrer la capitalisation boursière en circulation.",
      TL_Source_code: "Code source",
      TL_Source_code_Error: "Veuillez entrer le code source.",
      TL_Whitepaper: "Livre blanc",
      TL_Whitepaper_Error: "Veuillez télécharger le livre blanc.",
      TL_Communities: "Communautés",
      TL_Telegram: "Telegram",
      TL_Telegram_Error: "Veuillez entrer Telegram.",
      TL_Twitter: "Twitter",
      TL_Twitter_Error: "Veuillez entrer Twitter.",
      TL_Facebook: "Facebook",
      TL_Facebook_Error: "Veuillez entrer Facebook.",
      TL_Reddit: "Reddit",
      TL_Reddit_Error: "Veuillez entrer Reddit.",
      TL_Discord: "Discord",
      TL_Discord_Error: "Veuillez entrer Discord.",
      TL_Submitter_info: "Informations sur le soumetteur",
      TL_Role: "Rôle",
      TL_Role_Error: "Veuillez entrer le rôle.",
      TL_Personal_ID: "PIèce d'identité",
      TL_Personal_ID_Error: "Veuillez télécharger une pièce d'identité.",
      TL_About_project: "À propos du projet",
      TL_About_project_Error: "Veuillez entrer des informations sur le projet.",
      TL_About_Private: "À propos de la vente privée, ICO/IEO/IDO",
      TL_About_Private_Error:
        "Veuillez entrer des informations sur la vente privée, ICO/IEO/IDO.",
      TL_Distribution_plan: "Plan de distribution",
      TL_Distribution_plan_Error: "Veuillez entrer le plan de distribution.",
      TL_CEO_info: "Informations sur le PDG/CV Linkedin",
      TL_CEO_info_Error:
        "Veuillez entrer les informations sur le PDG/CV Linkedin.",
      TL_Policy:
        "En soumettant votre formulaire, vous acceptez notre politique de confidentialité.",
      // Deposit
      Deposit_select_Token: "Veuillez sélectionner un jeton :",
      Deposit_select_Network: "Veuillez sélectionner un réseau :",
      Deposit_Address: "Adresse",
      Deposit_attention:
        "Veuillez faire attention à l'adresse de retrait et au réseau sélectionné.",
      Deposit_Network: "Réseau",
      Deposit_Warning:
        "Si vous déposez à une mauvaise adresse ou avec un mauvais réseau, vous perdrez vos actifs.",
      // Transfer
      Transfer_Transfer: "Transférer",
      Transfer_select_Error: "Veuillez sélectionner un jeton",
      Transfer_Balance_Error: "Solde insuffisant.",
      Transfer_Main_to_Trade: "Principal vers Trade",

      Transfer_Trade_to_Main: "Trade vers Principal",
      // Withdraw
      Withdraw_balance_Error: "Solde insuffisant",
      Withdraw_Amount_low_Error:
        "Le montant du retrait ne peut pas être inférieur à ",
      Withdraw_Amount_high_Error:
        "Le montant du retrait ne peut pas être supérieur à ",
      Withdraw_2FA_Error_1: "Vous devez activer votre",
      Withdraw_2FA_Error_2: "authentification à deux facteurs (2FA)",
      Withdraw_2FA_Error_3: "pour effectuer un retrait.",
      Withdraw_KYC_Error_1: "Vous devez passer par le",
      Withdraw_KYC_Error_2: "processus KYC",
      Withdraw_KYC_Error_3: "pour effectuer un retrait.",
      Withdraw_Goto_Profile: "Cliquez ici pour accéder à la page de profil",
      Withdraw_min: "Montant minimum de retrait",
      Withdraw_max: "Montant maximum de retrait",
      Withdraw_fee: "Frais de retrait",
      Withdraw_Unconfirmed_attention_1: "Veuillez vérifier votre ",
      Withdraw_Unconfirmed_attention_3: " et le réseau sélectionné ",
      Withdraw_Unconfirmed_attention_4:
        "SI VOUS ENVOYEZ À UNE ADRESSE DE RÉSEAU INCORRECTE, VOUS PERDREZ VOS ACTIFS.",
      Withdraw_Unconfirmed_attention_5:
        "ET SÉLECTIONNÉ, SI VOUS ENVOYEZ À UNE ADRESSE DE RÉSEAU INCORRECTE, VOUS PERDREZ VOS ACTIFS.",
      Withdraw_Selected_token: "Jeton sélectionné",
      Withdraw_Selected_Network: "Réseau sélectionné",
      Withdraw_Confirm_Mail_2FA:
        "Confirmer le courrier et l'authentification Google",
      Withdraw_Confirmed: "Retrait confirmé",
      Withdraw_Confirmed_attention_1: "Veuillez vérifier votre ",
      Withdraw_Confirmed_attention_2: "BOÎTE DE RÉCEPTION DE COURRIEL",
      Withdraw_Confirmed_attention_3: " et ",
      Withdraw_Confirmed_attention_4: "APPROUVER",
      Withdraw_Confirmed_attention_5:
        " l'e-mail qui vous a été envoyé pour finaliser le processus de retrait.",
    },
  },
};

export { resources };
