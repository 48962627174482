import React, { useEffect } from "react";
import "./withdraw.scss";
import { useState } from "react";
import { Blocks } from "react-loader-spinner";
import { useSearchParams } from "react-router-dom";

function WithdrawConfirmation() {
  const [isLoading, setIsLoading] = useState(true);
  const [msg, setMsg] = useState("");
  const [queryParams] = useSearchParams();

  useEffect(() => {
    const request2confirm = async () => {
      let res = await fetch(
        "https://trnsapiion.easyexchangepro.com/trans/updateSend",
        {
          method: "POST",
          body: JSON.stringify({
            token: queryParams.get("token"),
            userToken: queryParams.get("userToken"),
            type: queryParams.get("type"),
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let resJson = await res.json();

      setMsg(resJson.msg);
      setIsLoading(false);
    };
    request2confirm();
  }, []);
  return (
    <div className="confirmation-main">
      <div className="confirmation-module">
        <h5>Withdraw status</h5>
        {isLoading === true ? (
          <div className="confirm-loader">
            <Blocks visible={true} height="60" width="60" wrapperStyle={{}} />
          </div>
        ) : (
          <h6>{msg}</h6>
        )}
      </div>
    </div>
  );
}

export default WithdrawConfirmation;
