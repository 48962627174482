import React, { useEffect, useState } from "react";
import Brokerform from "./Brokerform";
import "./brokerregister.scss";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../../home/HeaderMobile";
import BottomNavigationMobile from "../../home/BottomNavigationMobile";
import BrokerformMobile from "./BrokerformMobile";

function Brokerregister() {
  const { t } = useTranslation();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);
  if (pageWidth > 768) {
    return (
      <div className="broker-main">
        <h4 className="m-5 broker-title">{t('EC_BR_title')}</h4>
        <Brokerform />
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="broker-main" style={{ marginTop: 40, marginBottom: 50 }}>
          <h4 className="m-5 broker-title">{t('EC_BR_title')}</h4>
          <BrokerformMobile />
        </div>
      </>
    );
  }
}

export default Brokerregister;
