import React, { useEffect, useState } from "react";

import Markettabs from "./Markettabs";
import "./market.scss";
import HeaderMobile from "../../home/HeaderMobile";
import BottomNavigationMobile from "../../home/BottomNavigationMobile";
import MarketTable from "./MarketTable";
function Market() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);
  if (pageWidth > 768) {
    return (
      <>

        <div className="market-main mt-4">
          <div className="container market-table">
            <Markettabs />
          </div>
        </div>
      </>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="market-main-mobile">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <MarketTable />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Market;
