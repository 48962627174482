import React from "react";
import { useTranslation } from "react-i18next";

function PairTitle() {
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col d-flex justify-content-between">
      <div className="top-title-orderlist-first">{t('Global_Pair')}</div>
        <div className="top-title-orderlist-second">{t('Global_Price')}</div>
        <div className="top-title-orderlist-third">{t('Global_Volume')}</div>
      </div>
    </div>
  );
}

export default PairTitle;
