import React from "react";
import Image from "../../../assets/images/company-individual.png";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
function EGbot() {
  const { t } = useTranslation();
  return (
    <>
      <div className="container-fluid companies-main">
        <div className="row justify-content-around">
          <h3>{t('EG_EGBot_title')}</h3>
          <div className="col-8 col-md-4 company-content mt-5">
            <p>
              {t('EG_EGBot_description')}
            </p>
          </div>
          <div className="col-8 col-md-4">
            <img src={Image} alt="" className="img-fluid mt-5 mb-5" />
          </div>
        </div>
      </div>
    </>
  );
}

export default EGbot;
