
import "./profile.scss";
import { useTranslation } from "react-i18next";
import WithdrawHistory from "./WithdrawHistory";
import DepositHistory from "./DepositHistory";
function createData(time, type, login, region) {
  return { time, type, login, region };
}

const rows = [
  createData(
    "Istanbul, Istanbul (85.*.*.110)",
    "Windows Chrome",
    "04:10:48 - Apr 16, 2023",
    "Online"
  ),
];

export default function LoginHistory() {
  const { t } = useTranslation();

  return (
    <>
      <p className="p-w-history">Withdraw History</p>
      <div className="withdraw-history-p">
        <WithdrawHistory />
      </div>
      <p className="p-w-history mt-5">Deposit History</p>
      <div className="withdraw-history-p">
        <DepositHistory />
      </div>
    </>
  );
}
