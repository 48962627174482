import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import BrushRoundedIcon from "@mui/icons-material/BrushRounded";
import { IconButton } from "@mui/material";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { NavLink } from "react-router-dom";
import { ItemNames, getStorage, setStorage } from "./RSS";
import { useEffect } from "react";

export default function SelectTheme() {
  const [selectValue, setSelectValue] = useState("");
  const [selectedTheme, setSelectedTheme] = useState("");

  useEffect(() => {
    const storedTheme = getStorage(ItemNames.THEMES);

    if (storedTheme) {
      document.body.setAttribute("data-theme", storedTheme);
      setSelectedTheme(storedTheme);
    }
  }, []);

  const themeLight = (event) => {
    document.body.setAttribute("data-theme", "light-theme");
    const value = event.target.value;
    setSelectValue(value);
    setStorage(ItemNames.THEMES, "light-theme");
  };

  const themeDark = (event) => {
    document.body.setAttribute("data-theme", "dark-theme");
    const value = event.target.value;
    setSelectValue(value);
    setStorage(ItemNames.THEMES, "dark-theme");
  };

  const themeVintage = (event) => {
    document.body.setAttribute("data-theme", "vintage-theme");
    const value = event.target.value;
    setSelectValue(value);
    setStorage(ItemNames.THEMES, "vintage-theme");
  };

  const themeCool = (event) => {
    document.body.setAttribute("data-theme", "cool-theme");
    const value = event.target.value;
    setSelectValue(value);
    setStorage(ItemNames.THEMES, "cool-theme");
  };
  return (
    <PopupState
      variant="popover"
      popupId="demo-popup-popover"
      className="theme-selector-pop"
    >
      {(popupState) => (
        <div>
          <div>
            <NavLink className="nav-link-sec">
              <IconButton
                className="icon-round"
                size="medium"
                color="info"
                aria-label="profile"
                {...bindTrigger(popupState)}
              >
                <BrushRoundedIcon fontSize="medium" />
              </IconButton>
            </NavLink>
          </div>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
              className: "theme-selector-pop",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{ style: { width: "90px", height: "160px" } }}
          >
            <div>
              <Box
                noValidate
                component="form"
                className="theme-selector-pop"
                sx={{
                  flexDirection: "column",
                  m: "auto",
                  width: "fit-content",
                }}
              >
                <Button
                  size="medium"
                  color="info"
                  variant=" text"
                  onClick={themeLight}
                  sx={{ marginRight: "10px" }}
                >
                  Light
                </Button>
                <Button
                  size="medium"
                  color="info"
                  variant=" text"
                  onClick={themeDark}
                  sx={{ marginRight: "10px" }}
                >
                  Dark
                </Button>
                <Button
                  size="medium"
                  color="info"
                  variant=" text"
                  onClick={themeVintage}
                  sx={{ marginRight: "10px" }}
                >
                  Vintage
                </Button>
                <Button
                  size="medium"
                  color="info"
                  variant=" text"
                  onClick={themeCool}
                >
                  Cool
                </Button>
              </Box>{" "}
            </div>
          </Popover>{" "}
        </div>
      )}
    </PopupState>
  );
}
