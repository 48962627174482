import { useContext, useEffect, useState } from "react";
import { UserContext } from "./contexts/providers/user";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import Home from "./pages/home/Home";
import Wheretobuy from "./pages/easycard/wheretobuy/Wheretobuy";
import Alreadypurchased from "./pages/easycard/alreadypurchased/Alreadypurchased";
import Brokerregister from "./pages/easycard/broker/Brokerregister";
import Easyguarantee from "./pages/easyguarantee/easyguarantee/Easyguarantee";
import Guaranteefaq from "./pages/easyguarantee/guaranteefaq/Guaranteefaq";
import Guaranteeform from "./pages/easyguarantee/applicationform/Guaranteeform";
import Market from "./pages/products/market/Market";
import Convert from "./pages/products/convert/Convert";
import Withdraw from "./pages/transaction/withdraw/Withdraw";
import Deposit from "./pages/transaction/deposit/Deposit";
// import Gabur from "./pages/gabur/Gabur";
import Assets from "./pages/assets/Assets";
import Profile from "./pages/profile/Profile";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Notificationpage from "./pages/notification/Notificationpage";
import Aboutus from "./pages/about/Aboutus";
import Announcement from "./pages/announcement/Announcement";
import TokenListing from "./pages/tokenlisting/TokenListing";
import CoinInfo from "./pages/coininfo/CoinInfo";
import TermsOfUse from "./pages/termsofuse/TermsOfUse";
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";
import Faq from "./pages/faq/Faq";
import ContactUs from "./pages/contact/ContactUs";
import ApiDocument from "./pages/apidocuments/ApiDocument";
import FeePage from "./pages/fee/FeePage";
import Trade from "./pages/products/trade/Trade";
import Otc from "./pages/products/otc/Otc";
import { ItemNames, getStorage, isLoggedIn, setStorage } from "./utils/RSS";
import ModalGlobal from "./contexts/modal/Modal";
import InputDialogGlobal from "./contexts/dialog/dialog";
import SnackbarGlobal from "./contexts/snack/SnackGlobal";
import CommissionPage from "./pages/profile/CommissionPage";
import Transfer from "./pages/transaction/transfer/Transfer";
import InputDialogGlobal2 from "./contexts/dialog/DialogDouble";
import ChartComponent from "./pages/products/trade/TradeGBRChart";
import "./App.css";
import { Blocks } from "react-loader-spinner";
import WithdrawConfirmation from "./pages/transaction/withdraw/WithdrawConfirmation";
import ResetPassword from "./pages/login/ResetPassword";
import { useTranslation } from "react-i18next";
import MobileTradeChart from "./pages/products/mobilechart/Mobile_Trade_Chart";
import TradingView from "./pages/tradingview/TradingView";
import MenuMobile from "./pages/home/MenuMobile";

export function RoutesMap(props) {
  const { state, dispatch } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (state.isLoggedIn === true) {
      fetch("https://api.easyexchangepro.com/api/v2/user/info", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 1) {
            dispatch({ type: "set:profile", profile: data.user });
          } else {
            dispatch({ type: "Logout" });
          }
        })
        .catch((error) => dispatch({ type: "Logout" }));
    }
  }, [state.isLoggedIn, dispatch]);

  useEffect(() => {
    const getCurrencyImages = async () => {
      const response = await fetch(
        "https://traapi.easyexchangepro.com/api/currency/images"
      );
      const images = await response.json();

      dispatch({ type: "load:currency:image", images: images });
    };

    getCurrencyImages();
  }, [dispatch]);

  useEffect(() => {
    const getCurrencyInfo = async () => {
      const response = await fetch(
        "https://api.easyexchangepro.com/api/v2/public/currencies"
      );
      const data = await response.json();

      dispatch({ type: "load:currency:details", currencies: data.data });
    };

    getCurrencyInfo();
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 1000);
  }, [dispatch]);

  useEffect(() => {
    if (state.currency.details["USDT"] !== undefined) {
      setIsLoading(false);
    }
  }, [state.currency.details]);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("language"));

    return () => { };
  }, []);

  useEffect(() => {
    if (getStorage(ItemNames.THEMES) === null) {
      setStorage(ItemNames.THEMES, "light-theme");
    }
  }, []);

  const NeedAuthentication = ({ checkAuthFunc, redirectPath = "/login" }) => {
    if (!checkAuthFunc()) {
      return <Navigate to={redirectPath} replace />;
    }
    return <Outlet />;
  };
  return (
    <>
      {state.isOpenModal && (
        <ModalGlobal
          content={state.modalContent}
          closeModal={() => dispatch({ type: "toggle_modal", content: "" })}
        />
      )}
      {state.isOpenDialog && (
        <InputDialogGlobal
          closeDialog={() => dispatch({ type: "open_dialog", content: "" })}
        />
      )}
      {state.isOpenDialog2 && (
        <InputDialogGlobal2
          closeDialog2={() => dispatch({ type: "open_dialog2", content: "" })}
        />
      )}
      {state.isOpenSnack && (
        <SnackbarGlobal
          closeSnack={() => dispatch({ type: "open_snack", snackMessage: "" })}
        />
      )}
      <>
        {isLoading === true ? (
          <div className="loading container-fluid">
            <div className="row justify-content-center">
              <div className="col loader">
                <div className="loading-img"></div>
                <Blocks
                  visible={true}
                  height="60"
                  width="60"
                  // ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                // wrapperClass="blocks-wrapper"
                />
              </div>
            </div>
          </div>
        ) : (
          <Routes>
            <Route path="/">
              <Route path="test" element={<ChartComponent />} />
              <Route index element={<Home />} />
              <Route path="wheretobuy" element={<Wheretobuy />} />
              <Route path="easyguarantee" element={<Easyguarantee />} />
              <Route path="easyguaranteefaq" element={<Guaranteefaq />} />
              <Route path="mobilemenu" element={<MenuMobile />} />
              {/* <Route path="gabur" element={<Gabur />} /> */}
              {isLoggedIn() ? (
                <>
                  <Route path="login" element={<Home />} />
                  <Route path="register" element={<Home />} />
                </>
              ) : (
                <>
                  <Route path="login" element={<Login />} />
                  <Route path="register" element={<Register />} />
                </>
              )}
              <Route path="about" element={<Aboutus />} />
              {/* <Route path="announcement" element={<Announcement />} /> */}
              <Route path="coininfo" element={<CoinInfo />} />
              <Route path="termsofuse" element={<TermsOfUse />} />
              <Route path="privacy" element={<PrivacyPolicy />} />
              <Route path="faq" element={<Faq />} />
              <Route path="contact" element={<ContactUs />} />
              <Route path="api" element={<ApiDocument />} />
              <Route path="fee" element={<FeePage />} />
              <Route path="trade/:tradePair" element={<Trade />} />
              <Route path="reset_password" element={<ResetPassword />} />
              <Route path="tradingview" element={<TradingView />} />
              <Route
                path="withdrawconfirmation"
                element={<WithdrawConfirmation />}
              />
              <Route
                path="chart/:tradePair"
                element={<MobileTradeChart />}
              />
              <Route path="market" element={<Market />} />
              {/* Auth Pages Below Here */}
              <Route
                element={<NeedAuthentication checkAuthFunc={isLoggedIn} />}
              >
                <Route path="alreadypurchased" element={<Alreadypurchased />} />
                <Route path="brokerregister" element={<Brokerregister />} />
                <Route path="easyguaranteeform" element={<Guaranteeform />} />
                <Route path="convert" element={<Convert />} />
                <Route path="withdraw" element={<Withdraw />} />
                <Route path="deposit" element={<Deposit />} />
                <Route path="assets" element={<Assets />} />
                <Route path="profile" element={<Profile />} />
                <Route path="notification" element={<Notificationpage />} />
                <Route path="tokenlisting" element={<TokenListing />} />
                <Route path="otc" element={<Otc />} />
                <Route path="commission" element={<CommissionPage />} />
                <Route path="transfer" element={<Transfer />} />
              </Route>
            </Route>
          </Routes>
        )}
      </>
    </>
  );
}
