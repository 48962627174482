import React, { useContext, useEffect, useLayoutEffect } from "react";
import "./trade.scss";
import { UserContext } from "../../../contexts/providers/user";
import { useParams } from "react-router";
import { listenToEvent } from "../../../contexts/reducer/socket";
import { ItemNames, getStorage } from "../../../utils/RSS";
import { useState } from "react";
import TopPriceData from "./TopPriceData";
import UserOrderList from "./UserOrderList";
import TradeChart from "./TradeChart";
import BuySellModule from "./BuySellModule";
import PairListTrade from "./PairListTrade";
import OrderHistory from "./OrderHistory";
import OpenOrderModule from "./OpenOrderModule";
// style B
import TopPriceDataB from "./styleB/TopPriceDataB";
import UserOrderListB from "./styleB/UserOrderListB";
import TradeChartB from "./styleB/TradeChartB";
import BuySellModuleB from "./styleB/BuySellModuleB";
import PairListTradeB from "./styleB/PairListTradeB";
import OrderHistoryB from "./styleB/OrderHistoryB";
import OpenOrderModuleB from "./styleB/OpenOrderModuleB";
// style C
import TopPriceDataC from "./styleC/TopPriceDataC";
import UserOrderListC from "./styleC/UserOrderListC";
import TradeChartC from "./styleC/TradeChartC";
import BuySellModuleC from "./styleC/BuySellModuleC";
import PairListTradeC from "./styleC/PairListTradeC";
import OrderHistoryC from "./styleC/OrderHistoryC";
import OpenOrderModuleC from "./styleC/OpenOrderModuleC";
// style D
import TopPriceDataD from "./styleD/TopPriceDataD";
import BuySellModuleD from "./styleD/BuySellModuleD";
import BotSectionD from "./styleD/BotSectionD";
import ChartDropdown from "./styleD/ChartDropdown";
import OpenOrderModuleD from "./styleD/OpenOrderModuleD";
// style E
import TopPriceDataE from "./styleE/TopPriceDataE";
import BuySellModuleE from "./styleE/BuySellModuleE";
import BotSectionE from "./styleE/BotSectionE";
import ChartDropdownE from "./styleE/ChartDropdown";
import OpenOrderModuleE from "./styleE/OpenOrderModuleE";
import HeaderMobile from "../../home/HeaderMobile";
import BottomNavigationMobile from "../../home/BottomNavigationMobile";

function Trade() {
  const { state, stateIO, dispatchIO, dispatch } = useContext(UserContext);
  const { tradePair } = useParams();
  const [style, setStyle] = useState(style_A());

  useEffect(() => {
    let uri = `https://traapi.easyexchangepro.com/trade/pair/${tradePair}`;
    fetch(uri)
      .then((data) => data.json())
      .then((data) => {
        dispatchIO({
          type: "set_trade_info",
          buys: data.trade_info.buys,
          sells: data.trade_info.sells,
          histories: data.trade_info.tradeHists,
        });
        dispatchIO({ type: "set:trade_pair:details", details: data.info24h });
      });
  }, [tradePair, dispatchIO]);

  useLayoutEffect(() => {
    if (stateIO.trade_pair !== tradePair) {
      dispatchIO({ type: "LISTEN:pair_info", trade_pair: tradePair });
    }
  }, [dispatchIO, stateIO, tradePair]);

  useEffect(() => {
    const pairInfoCallback = (e) => {
      if (e.pair === stateIO.trade_pair) {
        dispatchIO({
          type: "set_trade_info",
          buys: e.buys,
          sells: e.sells,
          histories: e.tradeHists,
        });
      }
    };

    const pairDetailsCallback = (e) => {
      if (e.pair === stateIO.trade_pair) {
        dispatchIO({ type: "set:trade_pair:details", details: e });
      }
    };

    const listPairsCallback = (e) => {
      dispatchIO({ type: "set:trade_pairs", pairs: e.trade });
    };

    const userTradingWalletCallback = (e) => {
      dispatchIO({ type: "set:user:balances", wallets: e.wallets });
    };

    const userTradingHistoryCallback = (e) => {
      dispatchIO({ type: "set:user:orders", orders: e.history });
    };

    const tradeResponseCallback = (e) => {
      if (e.status !== 1) {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: e.msg,
        });
      } else {
        dispatch({
          type: "open_snack",
          snackColor: "success",
          snackMessage: e.msg,
        });
      }
    };

    if (stateIO.socket === null) {
      dispatchIO({ type: "start_socket", trade_pair: tradePair });
    } else {
      listenToEvent("pairResponse", pairInfoCallback);
      listenToEvent("pairDetails", pairDetailsCallback);
      listenToEvent("emitPairsResponse", listPairsCallback);
      listenToEvent("user:trade:response", tradeResponseCallback);
      listenToEvent("createResponse", tradeResponseCallback);
      if (state.isLoggedIn === true) {
        listenToEvent("user:info:trading_wallet", userTradingWalletCallback);
        listenToEvent("user:info:trading_history", userTradingHistoryCallback);
        var data = { token: getStorage(ItemNames.TOKEN) };
        stateIO.socket?.emit("user:join:channel", data);
      }
    }
    return () => {
      stateIO.socket?.off("pairResponse", pairInfoCallback);
      stateIO.socket?.off("pairDetails", pairDetailsCallback);
      stateIO.socket?.off("emitPairsResponse", listPairsCallback);
    };
  }, [
    state.isLoggedIn,
    stateIO.socket,
    stateIO.trade_pair,
    dispatchIO,
    tradePair,
    dispatch,
  ]);

  useEffect(() => {
    function handleSizeOfPage() {
      if (window.innerWidth > 1255) {
        setStyle(style_A());
      } else if (1055 <= window.innerWidth && window.innerWidth <= 1255) {
        setStyle(style_B());
      } else if (825 <= window.innerWidth && window.innerWidth < 1055) {
        setStyle(style_C());
      } else if (500 <= window.innerWidth && window.innerWidth < 825) {
        setStyle(style_D());
      } else if (window.innerWidth < 500) {
        setStyle(style_E());
      }
    }

    handleSizeOfPage();

    window.addEventListener("resize", handleSizeOfPage);
    return () => window.removeEventListener("resize", handleSizeOfPage);
  }, []);

  function style_A() {
    return (
      <div className="trade-main">
        <div className="trade-module p-0">
          <div className="topprice-in-trade">
            <TopPriceData />
          </div>
          <div className="row trade-section-controller">
            <div className="col-3">
              <UserOrderList />
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-12">
                  <TradeChart />
                </div>
                <div className="col-12">
                  <BuySellModule />
                </div>
              </div>
            </div>
            <div className="col-3">
              <PairListTrade />
              <div className="spacing"></div>
              <OrderHistory />
            </div>
          </div>
        </div>
        <div className="container-openorder p-0 ">
          <div className="row p-0 m-0">
            <div className="col openorder-main p-0 m-0">
              <OpenOrderModule />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function style_B() {
    return (
      <div className="trade-main">
        <div className="trade-moduleB">
          <div className="topprice-in-trade">
            <TopPriceDataB />
          </div>
          <div className="row style-b justify-content-between">
            <div className="col-6 chart-b text-left">
              <TradeChartB />
            </div>
            <div className="col-6 buysell-b text-right">
              <BuySellModuleB />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <UserOrderListB />
            </div>
            <div className="col-3 ml-3 pl-4">
              <OrderHistoryB />
            </div>
            <div className="col-6">
              <PairListTradeB />
            </div>
          </div>
        </div>
        <div className="container-openorderB">
          <div className="row p-0 m-0">
            <div className="col openorder-main p-0 m-0">
              <OpenOrderModuleB />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function style_C() {
    return (
      <div className="trade-main">
        <div className="trade-moduleC">
          <div className="topprice-in-tradeC">
            <TopPriceDataC />
          </div>
          <div className="row style-b justify-content-between">
            <div className="col-5 chart-c text-left">
              <TradeChartC />
            </div>
            <div className="col-5 buysell-b text-right">
              <BuySellModuleC />
            </div>
          </div>
          <div className="container-fluid bottom-containerC">
            <div className="row">
              <div className="col-4">
                <UserOrderListC />
              </div>
              <div className="col-4">
                <OrderHistoryC />
              </div>
              <div className="col-4">
                <PairListTradeC />
              </div>
            </div>
          </div>
        </div>
        <div className="container-openorderC">
          <div className="row p-0 m-0">
            <div className="col openorder-main p-0 m-0">
              <OpenOrderModuleC />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function style_D() {
    return (
      <div className="trade-main">
        <div className="trade-moduleD">
          <div className="topprice-in-tradeD">
            <TopPriceDataD />
          </div>
          <div className="container-fluid">
            <div className="row style-d justify-content-between">
              <div className="col chart-d text-center">
                <ChartDropdown />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {" "}
              <BuySellModuleD />{" "}
            </div>
          </div>
          <div className="container-fluid bottom-containerD">
            <div className="row">
              <div className="col mb-5">
                <BotSectionD />
              </div>
            </div>
          </div>
        </div>
        <div className="container-openorderD">
          <div className="row">
            <div className="col openorder-main">
              <OpenOrderModuleD />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function style_E() {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="trade-main mt-3">
          <div className="trade-moduleD">
            <div className="topprice-in-tradeD">
              <TopPriceDataE />
            </div>
            <div className="container-fluid">
              <div className="row style-d justify-content-between">
                <div className="col chart-d text-center">
                  <ChartDropdownE />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {" "}
                <BuySellModuleE />{" "}
              </div>
            </div>
            <div className="container-fluid bottom-containerD">
              <div className="row">
                <div className="col mb-5">
                  <BotSectionE />
                </div>
              </div>
            </div>
          </div>
          <div className="container-openorderD">
            <div className="row">
              <div className="col openorder-main">
                <OpenOrderModuleE />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return <div>{style}</div>;
}
export default Trade;
