import React, { useRef } from "react";
import { Button } from "react-bootstrap";

const CopyButton = ({ text }) => {
  const inputRef = useRef(null);

  const handleCopy = () => {
    inputRef.current.select();
    document.execCommand("copy");
  };

  return (
    <div>
      <input
        ref={inputRef}
        type="text"
        value={text}
        readOnly
        style={{ position: "absolute", left: "-9999px" }}
      />
      <Button onClick={handleCopy}>Copy</Button>
    </div>
  );
};

export default CopyButton;
