import React from "react";
import FirstPic from "../../assets/images/independent.png";
import SecondPic from "../../assets/images/fast.png";
import ThirdPic from "../../assets/images/secure.png";
import FourthPic from "../../assets/images/easy.png";
import FifthPic from "../../assets/images/nofee.png";
import SixthPic from "../../assets/images/convert.png";
import { Link, NavLink } from "react-router-dom";
import ScrollToTop from "../../utils/ScrollToTop";
import { useTranslation } from "react-i18next";
function EasyCard() {
  const { t } = useTranslation();
  return (
    <>
      <div className="container-fluid bg-slide">
        <div className="container">
          <div className="row py-5 align-items-center">
            <div className="col-lg-7">
              <h1 className="mb-4">{t('Global_EasyCard')}</h1>
              <h3 className="mb-4">
              {t('Home_EC_subtitle_1')}
                <br />
                {t('Home_EC_subtitle_2')}
              </h3>
              <p className="mb-4">
              {t('Home_EC_description_1')}
                 <br /> 
                 {t('Home_EC_description_2')}
                <br />
                {t('Home_EC_description_3')}
                
              </p>
            </div>
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-easy">
        <div className="container">
          <div className="row justify-content-center easy-home">
            <div className="col-lg-4">
              <img
                src={FirstPic}
                height="80px"
                className="img-home-easy"
                alt="easycard"
              />
              <h6>{t('Home_EC_Feature_1_title')}</h6>
              <p>
              {t('Home_EC_Feature_1_description_1')}
                 <br /> 
                 {t('Home_EC_Feature_1_description_2')}
              </p>
            </div>
            <div className="col-lg-4">
              <img src={ThirdPic} height="80px" alt="easycard" />
              <h6> {t('Global_Secure')} </h6>
              <p>
              {t('Home_EC_Feature_2_description_1')}
                 <br /> 
                 {t('Home_EC_Feature_2_description_2')}
              </p>
            </div>
            <div className="col-lg-4">
              <img src={SecondPic} height="80px" alt="easycard" />
              <h6> {t('Global_Fast')} </h6>
              <p>
              {t('Home_EC_Feature_3_description_1')}
                 <br /> 
                 {t('Home_EC_Feature_3_description_2')}
              </p>
            </div>
            <div className="col-lg-4">
              <img src={FourthPic} height="80px" alt="easycard" />
              <h6> {t('Home_EC_Feature_4_title')} </h6>
              <p>
              {t('Home_EC_Feature_4_description_1')}
                 <br /> 
                 {t('Home_EC_Feature_4_description_2')}
              </p>
            </div>
            <div className="col-lg-4">
              <img src={FifthPic} height="80px" alt="easycard" />
              <h6> {t('Home_EC_Feature_5_title')} </h6>
              <p>
              {t('Home_EC_Feature_5_description_1')}
                 <br /> 
                 {t('Home_EC_Feature_5_description_2')}
              </p>
            </div>
            <div className="col-lg-4">
              <img src={SixthPic} height="80px" alt="easycard" />
              <h6> {t('Global_Convert')} </h6>
              <p>
              {t('Home_EC_Feature_6_description_1')}
                 <br /> 
                 {t('Home_EC_Feature_6_description_2')}
              </p>
            </div>
          </div>
        </div>
        <div className="easy-btn-buy">
          <NavLink as={Link} to="/wheretobuy" className="nav-link">
          {t('Home_EC_Where_to_buy')}
            <ScrollToTop />
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default EasyCard;
