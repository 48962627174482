import React, { useContext } from "react";
import OrderHistoryTitle from "./OrderHistoryTitleC";
import EachOrderHistory from "./EachOrderHistoryC";
import SimpleBar from "simplebar-react";
import { UserContext } from "../../../../contexts/providers/user";

function OrderHistory() {
  const { stateIO } = useContext(UserContext);
  return (
    <div className="orderhistory-mainC">
      <div className="row ">
        <OrderHistoryTitle />
        <div className="col orderhistory-module">
          <SimpleBar
            style={{ maxHeight: 248, paddingRight: 5, marginRight: 25 }}
            autoHide={false}
          >
            {stateIO.histories.map(function (item, index) {
              return (
                <EachOrderHistory key={`order_history_${index}`} info={item} />
              );
            })}
          </SimpleBar>
        </div>
      </div>
    </div>
  );
}

export default OrderHistory;
