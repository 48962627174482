import React from "react";
import AppImage from "../../assets/1576.png";
import google from "../../assets/json/google-play.json";
import apple from "../../assets/json/apple.json";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";

function MobileSection() {
  const { t } = useTranslation();
  return (
    <div className="container-fluid bg-yellow">
      <div className="container">
        <div className="row py-5 align-items-center">
          <div className="col-lg-7 mb-3 mt-5">
            <div className="hlapp">
              <h1>{t('Home_MS_title')}</h1>
            </div>
            <div className="ulapp">
              <ul>
                <li className="appli">{t('Home_MS_list_1')}</li>
                <li className="appli">{t('Home_MS_list_2')}</li>
                <li className="appli">{t('Home_MS_list_3')}</li>
                <li className="appli">{t('Home_MS_list_4')}</li>
                <li className="appli">{t('Home_MS_list_5')}</li>
              </ul>
            </div>
            <div className="rdiv d-flex mt-4">
              <a
                className="btn-app ios-playstore-btn mb-3 d-inline-block text-decoration-none"
                href="https://apps.apple.com/in/app/easy-exchange-pro/id1620538502"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="row">
                  <div className="appicon col-3 mt-3 ms-2">
                    <Lottie animationData={apple} width={25} height={25} />
                  </div>
                  <div className="col-8 mt-1">{t('Home_MS_dl_for_ios')}</div>
                </div>
              </a>
              <a
                className="btn-app ios-playstore-btn mb-3 d-inline-block text-decoration-none"
                href="https://play.google.com/store/apps/details?id=easyexchangepro.com.easy_pro"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="row">
                  <div className="appicon col-3 mt-3 ms-2">
                    <Lottie animationData={google} width={25} height={25} />
                  </div>
                  <div className="col-8 mt-1 ">{t('Home_MS_dl_for_android')}</div>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-5 overflow-hidden">
            <img
              src={AppImage}
              className="d-block mx-auto img-fluid"
              alt="easycard"
              height={150}
              width={220}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileSection;
