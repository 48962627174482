import React from 'react'
import './home.scss'
import { Menu } from '@mui/icons-material'
import { Button } from 'react-bootstrap'
import SelectTheme from '../../utils/Themeselector'
import { useNavigate } from 'react-router-dom'
import LanguageSelector from '../../components/translation/LanguageSelector'
function HeaderMobile() {
    const navigate = useNavigate()
    const goToPage = (path) => {
        navigate(path)
    }
    return (
        <div className='header-mobile'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-8 text-start mt-2">
                        <Button className='menu-btn-mobile' onClick={() => goToPage('/mobilemenu')}> <Menu className='menu-icon' /></Button>
                    </div>
                    <div className="col-2 text-end mt-1">
                        <LanguageSelector />
                    </div>
                    <div className="col-2 text-end mt-1">
                        <SelectTheme />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HeaderMobile
