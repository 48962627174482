import { Button, Divider } from '@mui/material'
import './home.scss'
import { ArrowBackIosNewOutlined, CurrencyBitcoinOutlined, EditNoteOutlined, FingerprintOutlined, GroupOutlined, InfoOutlined, Person4Outlined, PolicyOutlined, QuestionMarkOutlined, RequestQuoteOutlined, Security, SecurityOutlined, TableBarOutlined, TableChartOutlined, VolumeUpOutlined } from '@mui/icons-material'
import LanguageSelector from '../../components/translation/LanguageSelector'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SelectTheme from '../../utils/Themeselector';

function MenuMobile() {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const goToPage = (path) => {
        navigate(path)
    }
    return (
        <div>
            <div className="container-fluid mobile-menu">
                <div className="row">
                    <div className="col-8">
                        <Button className='back-btn-m' onClick={() => goToPage('/')}><ArrowBackIosNewOutlined className='back-btn-m' /></Button>
                    </div>
                    <div className="col-2 text-end mt-2">
                        <LanguageSelector />
                    </div>
                    <div className="col-2 text-end mt-2">
                        <SelectTheme />
                    </div>
                </div>
                <Divider className='divider-menu' />
                <div className="row">
                    <div className="col-12 text-start">
                        <Button className='menu-btn-m' onClick={() => goToPage('/profile#1')}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-1 me-3">
                                        <SecurityOutlined className='each-icon-menu-m' />
                                    </div>
                                    <div className="col-9 each-text-menu-m mt-1">
                                        <p>{t('Profile_security')}</p>
                                    </div>
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-start">
                        <Button className='menu-btn-m' onClick={() => goToPage('/profile#2')}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-1 me-3">
                                        <FingerprintOutlined className='each-icon-menu-m' />
                                    </div>
                                    <div className="col-9 each-text-menu-m mt-1">
                                        <p>{t('Profile_KYC')}</p>
                                    </div>
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-start">
                        <Button className='menu-btn-m' onClick={() => goToPage('/profile#0')}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-1 me-3">
                                        <GroupOutlined className='each-icon-menu-m' />
                                    </div>
                                    <div className="col-9 each-text-menu-m mt-1">
                                        <p>{t('Profile_Referrals_title')}</p>
                                    </div>
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-start">
                        <Button className='menu-btn-m' onClick={() => goToPage('/otc')}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-1 me-3">
                                        <TableChartOutlined className='each-icon-menu-m' />
                                    </div>
                                    <div className="col-9 each-text-menu-m mt-1">
                                        <p>{t('Global_OTC')}</p>
                                    </div>
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-start">
                        <Button className='menu-btn-m' onClick={() => goToPage('/brokerregister')}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-1 me-3">
                                        <Person4Outlined className='each-icon-menu-m' />
                                    </div>
                                    <div className="col-9 each-text-menu-m mt-1">
                                        <p>{t('EC_BR_title')}</p>
                                    </div>
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-12 text-start">
                        <Button className='menu-btn-m' onClick={() => goToPage('/announcement')}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-1 me-3">
                                        <VolumeUpOutlined className='each-icon-menu-m' />
                                    </div>
                                    <div className="col-9 each-text-menu-m mt-1">
                                        <p>Announcements</p>
                                    </div>
                                </div>
                            </div>
                        </Button>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-12 text-start">
                        <Button className='menu-btn-m' onClick={() => goToPage('/faq')}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-1 me-3">
                                        <QuestionMarkOutlined className='each-icon-menu-m' />
                                    </div>
                                    <div className="col-9 each-text-menu-m mt-1">
                                        <p>FAQ</p>
                                    </div>
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-start">
                        <Button className='menu-btn-m' onClick={() => goToPage('/termsofuse')}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-1 me-3">
                                        <EditNoteOutlined className='each-icon-menu-m' />
                                    </div>
                                    <div className="col-9 each-text-menu-m mt-1">
                                        <p>{t('Login_terms')}</p>
                                    </div>
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-start">
                        <Button className='menu-btn-m' onClick={() => goToPage('/privacy')}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-1 me-3">
                                        <PolicyOutlined className='each-icon-menu-m' />
                                    </div>
                                    <div className="col-9 each-text-menu-m mt-1">
                                        <p>{t('Login_privacy')}</p>
                                    </div>
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-start">
                        <Button className='menu-btn-m' onClick={() => goToPage('/fee')}>
                            <div className="container-fluid">
                                <div className="row d-flex">
                                    <div className="col-1 me-3">
                                        <RequestQuoteOutlined className='each-icon-menu-m' />
                                    </div>
                                    <div className="col-9 each-text-menu-m mt-1">
                                        <p>{t('Global_Fees')}</p>
                                    </div>
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-start">
                        <Button className='menu-btn-m' onClick={() => goToPage('/about')}>
                            <div className="container-fluid">
                                <div className="row ">
                                    <div className="col-1 me-3">
                                        <InfoOutlined className='each-icon-menu-m' />
                                    </div>
                                    <div className="col-9 each-text-menu-m mt-1">
                                        <p>{t('Global_about')}</p>
                                    </div>
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-start">
                        <Button className='menu-btn-m' onClick={() => goToPage('/coininfo')}>
                            <div className="container-fluid">
                                <div className="row ">
                                    <div className="col-1 me-3">
                                        <CurrencyBitcoinOutlined className='each-icon-menu-m' />
                                    </div>
                                    <div className="col-9 each-text-menu-m mt-1">
                                        <p>{t('Global_coin_info')}</p>
                                    </div>
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default MenuMobile
