import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./assets.scss";
import {
  getMainWallet,
  getTradeWallet,
  getTransferWallet,
} from "../../utils/Api";
import "./assets.scss";
import { UserContext } from "../../contexts/providers/user";
import { useContext } from "react";
import Paper from "@mui/material/Paper";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { toFixed } from "../../utils/math";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../home/HeaderMobile";
import BottomNavigationMobile from "../home/BottomNavigationMobile";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: ` e-tab-${index}`,
    "aria-controls": ` tabpanel-${index}`,
  };
}

export default function Wallets() {
  const errorCatch = (error) => { };
  const [wallets, setWallets] = useState({});

  useEffect(() => {
    getTransferWallet(setWallets, errorCatch);
  }, [setWallets]);

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [mainWallet, setMainWallet] = useState([]);
  const [tradeWallet, setTradeWallet] = useState([]);
  const [bitcoinPrice, setBitcoinPrice] = useState(0);
  const { state } = useContext(UserContext);
  useEffect(() => {
    if (mainWallet.length == 0) {
      getMainWallet(setMainWallet, errorCatch);
    }
  }, []);

  if (mainWallet.length > 0 && bitcoinPrice == 0) {
    const temp = mainWallet.filter((item) => {
      return item.symbol === "BTC";
    })[0].EstimatedUSDT;
    setBitcoinPrice(temp);
  }

  useEffect(() => {
    if (tradeWallet.length == 0) {
      getTradeWallet(setTradeWallet, errorCatch);
    }
  }, []);

  const mainAllAssetsUSDT = mainWallet.reduce((acc, item) => {
    const totalAmount2USDT = item.amount * item.EstimatedUSDT;
    return acc + totalAmount2USDT;
  }, 0);

  const tradeAllAssetsUSDT = tradeWallet.reduce((acc, item) => {
    const totalAmount2USDT = item.amount * item.EstimatedUSDT;
    return acc + totalAmount2USDT;
  }, 0);

  const userBTCinMain = mainAllAssetsUSDT / bitcoinPrice;
  const userBTCinTrade = tradeAllAssetsUSDT / bitcoinPrice;
  const { t } = useTranslation();

  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);
  const [moduleDecider, setModuleDecider] = useState(0)
  if (pageWidth > 768) {
    return (
      <div className="wallet-scroll">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs variant="scrollable" value={value} onChange={handleChange}>
              <Tab label={t('Asset_Tab_1')} {...a11yProps(0)} />
              <Tab label={t('Asset_Tab_2')} {...a11yProps(1)} />
              <Tab label={t('Asset_Tab_3')} {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TableContainer
              component={Paper}
              sx={{ minWidth: 580 }}
              style={{ height: 650 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{t('Global_Icon')}</TableCell>
                    <TableCell align="left">{t('Global_Token')}</TableCell>
                    <TableCell align="left">{t('Global_Total')} {t('Global_Amount')}</TableCell>
                    <TableCell align="left">{t('Global_Total')} {t('Global_USDT')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(wallets)
                    .sort((a, b) => a.localeCompare(b))
                    .map((item, index) => (
                      <TableRow
                        key={`wallet${index}`}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell align="left">
                          <img
                            src={wallets[item].image}
                            style={{ width: 25, height: 25, marginRight: 10 }}
                            alt={"currency"}
                          />
                        </TableCell>
                        <TableCell align="left">{wallets[item].symbol}</TableCell>
                        <TableCell align="left">
                          {toFixed(
                            parseFloat(
                              `${wallets[item].main_amount +
                              wallets[item].trading_amount
                              }`
                            ),
                            state.currency.details[wallets[item].symbol]
                              .amount_decimal || 6
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {toFixed(
                            parseFloat(
                              `${wallets[item].EstimatedUSDT *
                              (wallets[item].main_amount +
                                wallets[item].trading_amount)
                              }`
                            ),
                            2
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="sumofallassets">
              <div className="row">
                <div className="col">
                  <p>{t('Global_Total')} {t('Global_USDT')}: {mainAllAssetsUSDT.toFixed(2)}</p>
                  <p>{t('Asset_Total_BTC')}: {userBTCinMain.toFixed(5)}</p>
                </div>
              </div>
            </div>
            <TableContainer
              component={Paper}
              sx={{ minWidth: 580 }}
              style={{ height: 570 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{t('Global_Icon')}</TableCell>
                    <TableCell align="left">{t('Global_Token')}</TableCell>
                    <TableCell align="left">{t('Global_Total')} {t('Global_Amount')}</TableCell>

                    <TableCell align="left">{t('Global_Total')} {t('Global_USDT')}</TableCell>
                    <TableCell align="left">{t('Asset_In_Withdraw')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mainWallet
                    .sort((a, b) => a.symbol.localeCompare(b.symbol))
                    .map((item, index) => (
                      <TableRow
                        key={`mainWallet${index}`}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell align="left">
                          <img
                            src={item.image}
                            style={{ width: 25, height: 25, marginRight: 10 }}
                            alt={"currency"}
                          />
                        </TableCell>
                        <TableCell align="left">{item.symbol}</TableCell>
                        <TableCell align="left">
                          {toFixed(
                            parseFloat(`${item.amount}`),
                            state.currency.details[item.symbol].amount_decimal ||
                            6
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {toFixed(
                            parseFloat(`${item.EstimatedUSDT * item.amount}`),
                            2
                          )}
                          { }
                        </TableCell>
                        <TableCell align="left">
                          {toFixed(
                            parseFloat(`${0}`),
                            state.currency.details[item.symbol].amount_decimal ||
                            6
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="sumofallassets">
              <div className="row">
                <div className="col">
                  <p>{t('Global_Total')} {t('Global_USDT')}: {tradeAllAssetsUSDT.toFixed(2)}</p>
                  <p>{t('Asset_Total_BTC')}: {userBTCinTrade.toFixed(5)}</p>
                </div>
              </div>
            </div>

            <TableContainer
              component={Paper}
              sx={{ minWidth: 580 }}
              style={{ height: 570 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{t('Global_Icon')}</TableCell>
                    <TableCell align="left">{t('Global_Token')}</TableCell>
                    <TableCell align="left">{t('Global_Total')} {t('Global_Amount')}</TableCell>

                    <TableCell align="left">{t('Global_Total')} {t('Global_USDT')}</TableCell>
                    <TableCell align="left">{t('Asset_In_Order')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tradeWallet
                    .sort((a, b) => a.symbol.localeCompare(b.symbol))
                    .map((item, index) => (
                      <TableRow
                        key={`tradeWallet${index}`}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell align="left">
                          <img
                            src={item.image}
                            style={{ width: 25, height: 25, marginRight: 10 }}
                            alt={"currency"}
                          />
                        </TableCell>
                        <TableCell align="left">{item.symbol}</TableCell>
                        <TableCell align="left">
                          {toFixed(
                            parseFloat(`${item.amount}`),
                            state.currency.details[item.symbol].amount_decimal ||
                            6
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {toFixed(
                            parseFloat(`${item.EstimatedUSDT * item.amount}`),
                            2
                          )}
                          { }
                        </TableCell>
                        <TableCell align="left">
                          {toFixed(
                            parseFloat(`${0}`),
                            state.currency.details[item.symbol].amount_decimal ||
                            6
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </Box>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <div className="container-fluid" style={{ marginTop: 90 }}>
          <div className="row">
            <div className="col-4">
              {moduleDecider === 0 ? <Button className="btn-assets-mobile-active" onClick={() => setModuleDecider(0)}>All</Button> : <Button className="btn-assets-mobile" onClick={() => setModuleDecider(0)}>All</Button>}
            </div>
            <div className="col-4">
              {moduleDecider === 1 ? <Button className="btn-assets-mobile-active" onClick={() => setModuleDecider(1)}>Main</Button> : <Button className="btn-assets-mobile" onClick={() => setModuleDecider(1)}>Main</Button>}
            </div>
            <div className="col-4">
              {moduleDecider === 2 ? <Button className="btn-assets-mobile-active" onClick={() => setModuleDecider(2)}>Trading</Button> : <Button className="btn-assets-mobile" onClick={() => setModuleDecider(2)}>Trading</Button>}
            </div>
          </div>
        </div>
        {moduleDecider === 0 ?
          <>
            <div className="sumofallassets mt-3" style={{ fontSize: 13 }}>
              <div className="row">
                <div className="col-12">
                  <p style={{ marginBottom: 4 }}>
                    {t('Global_Total')} {t('Global_USDT')}: {(parseFloat(mainAllAssetsUSDT) + parseFloat(tradeAllAssetsUSDT)).toFixed(2)}
                  </p>

                  <p>{t('Asset_Total_BTC')}: {(parseFloat(userBTCinMain) + parseFloat(userBTCinTrade)).toFixed(5)}</p>
                </div>
              </div>
            </div>
            <div className="sumofallassets mt-3" style={{ fontSize: 14 }}>
              {Object.keys(wallets)
                .sort((a, b) => a.localeCompare(b))
                .map((item, index) => (
                  <div key={`wallet${index}`} className="each-asset-mobile">
                    <div className="row">
                      <div className="col-1">
                        <img
                          src={wallets[item].image}
                          style={{ width: 25, height: 25, marginRight: 8 }}
                          alt={"currency"}
                        />
                      </div>
                      <div className="col-7" style={{ fontWeight: 700 }}>
                        {wallets[item].symbol}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-6">
                        {t('Global_Total')} {t('Global_Amount')}
                      </div>
                      <div className="col-6 text-end">
                        {toFixed(
                          parseFloat(
                            `${wallets[item].main_amount +
                            wallets[item].trading_amount
                            }`
                          ),
                          state.currency.details[wallets[item].symbol]
                            .amount_decimal || 6
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        {t('Global_Total')} {t('Global_USDT')}
                      </div>
                      <div className="col-6 text-end">
                        {toFixed(
                          parseFloat(
                            `${wallets[item].EstimatedUSDT *
                            (wallets[item].main_amount +
                              wallets[item].trading_amount)
                            }`
                          ),
                          2
                        )}
                      </div>
                    </div>
                  </div>

                ))}
            </div>
          </> : <></>}
        {moduleDecider === 1 ?
          <>
            <div className="sumofallassets mt-3" style={{ fontSize: 13 }}>
              <div className="row">
                <div className="col-12">
                  <p style={{ marginBottom: 4 }}>{t('Global_Total')} {t('Global_USDT')}: {mainAllAssetsUSDT.toFixed(2)}</p>
                  <p>{t('Asset_Total_BTC')}: {userBTCinMain.toFixed(5)}</p>
                </div>
              </div>
            </div>
            <div className="sumofallassets mt-3" style={{ fontSize: 14 }}>

              {mainWallet
                .sort((a, b) => a.symbol.localeCompare(b.symbol))
                .map((item, index) => (
                  <div key={`mainWallet${index}`} className="each-asset-mobile">
                    <div className="row">
                      <div className="col-1">
                        <img
                          src={item.image}
                          style={{ width: 25, height: 25, marginRight: 10 }}
                          alt={"currency"}
                        />
                      </div>
                      <div className="col-7" style={{ fontWeight: 700 }}>
                        {item.symbol}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-6">
                        {t('Global_Total')} {t('Global_Amount')}
                      </div>
                      <div className="col-6 text-end">
                        {toFixed(
                          parseFloat(`${item.amount}`),
                          state.currency.details[item.symbol].amount_decimal ||
                          6
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        {t('Global_Total')} {t('Global_USDT')}
                      </div>
                      <div className="col-6 text-end">
                        {toFixed(
                          parseFloat(`${item.EstimatedUSDT * item.amount}`),
                          2
                        )}
                      </div>
                    </div>

                  </div>

                ))}
            </div>
          </> : <></>}
        {moduleDecider === 2 ?
          <>
            <div className="sumofallassets mt-3" style={{ fontSize: 13 }}>
              <div className="row">
                <div className="col-12">
                  <p style={{ marginBottom: 4 }}>{t('Global_Total')} {t('Global_USDT')}: {tradeAllAssetsUSDT.toFixed(2)}</p>
                  <p>{t('Asset_Total_BTC')}: {userBTCinTrade.toFixed(5)}</p>
                </div>
              </div>
            </div>
            <div className="sumofallassets mt-3" style={{ fontSize: 14 }}>

              {tradeWallet
                .sort((a, b) => a.symbol.localeCompare(b.symbol))
                .map((item, index) => (
                  <div key={`mainWallet${index}`} className="each-asset-mobile">
                    <div className="row">
                      <div className="col-1">
                        <img
                          src={item.image}
                          style={{ width: 25, height: 25, marginRight: 10 }}
                          alt={"currency"}
                        />
                      </div>
                      <div className="col-7" style={{ fontWeight: 700 }}>
                        {item.symbol}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-6">
                        {t('Global_Total')} {t('Global_Amount')}
                      </div>
                      <div className="col-6 text-end">
                        {toFixed(
                          parseFloat(`${item.amount}`),
                          state.currency.details[item.symbol].amount_decimal ||
                          6
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        {t('Global_Total')} {t('Global_USDT')}
                      </div>
                      <div className="col-6 text-end">
                        {toFixed(
                          parseFloat(`${item.EstimatedUSDT * item.amount}`),
                          2
                        )}
                      </div>
                    </div>

                  </div>

                ))}
            </div>
          </> : <></>}
      </>
    );
  }
}
