import { useState } from "react";
import "./withdraw.scss";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Button, Form, Modal, ModalFooter } from "react-bootstrap";
import { Alert, Grid, Snackbar, Stack } from "@mui/material";
import { getWithdrawInfo } from "../../../utils/Api";
import { useEffect } from "react";
import { ItemNames, getStorage } from "../../../utils/RSS";
// import { UserContext } from "../../../contexts/providers/user";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../../home/HeaderMobile";
import BottomNavigationMobile from "../../home/BottomNavigationMobile";

function Withdraw() {
  const { t } = useTranslation();

  const [errorMsg, setErrorMsg] = useState(null);
  const errorCatch = (error) => {
    setErrorMsg(error);
  };

  const [currencies, setCurrencies] = useState({});

  useEffect(() => {
    getWithdrawInfo(setCurrencies, errorCatch);
  }, []);
  // const { dispatch } = useContext(UserContext);
  const [selectedToken, setSelectedToken] = useState({});
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [address, setAddress] = useState(null);
  const [amount, setAmount] = useState(null);
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);
  const [fee, setFee] = useState(null);
  const [mailOTP, setMailOTP] = useState(null);
  const [gOTP, setGOTP] = useState(null);
  const handleTokenChange = (event) => {
    setSelectedToken(event.target.value);
    setSelectedNetwork(event.target.value.networks[0]);
    setMin(event.target.value.min);
    setMax(event.target.value.max);
    setFee(event.target.value.fee);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showOTP, setShowOTP] = useState(false);
  const handleCloseOTP = () => setShowOTP(false);
  const handleShowOTP = () => setShowOTP(true);

  const [showFinal, setShowFinal] = useState(false);
  const handleCloseFinal = () => setShowFinal(false);
  const handleShowFinal = () => setShowFinal(true);

  const [openSnackBar, setOpenSnackBar] = useState({
    show: false,
    severity: "warning",
    message: "",
  });
  const handleSnackBarClose = () => {
    setOpenSnackBar({
      ...openSnackBar,
      show: false,
    });
  };
  const checkAllOptions = () => {
    if (
      selectedToken === "" ||
      selectedNetwork === "" ||
      address === null ||
      amount === null
    ) {
      return true;
    }
  };

  const errorsSnack = () => {
    //check forms
    if (amount > selectedToken?.balance) {
      setOpenSnackBar({
        ...openSnackBar,
        show: true,
        severity: "warning",
        message: t('Withdraw_balance_Error'),
      });
    } else if (amount < min) {
      setOpenSnackBar({
        ...openSnackBar,
        show: true,
        severity: "warning",
        message: `${t('Withdraw_Amount_low_Error')} ${min}`,
      });
    } else if (amount > max) {
      setOpenSnackBar({
        ...openSnackBar,
        show: true,
        severity: "warning",
        message: `${t('Withdraw_Amount_high_Error')} ${max}`,
      });
    } else {
      handleShow();
    }
  };

  const sendRequestOTP = async (event) => {
    event.preventDefault();

    try {
      let data = { otp_type: "email_only" };
      let res = await fetch(
        "https://trnsapiion.easyexchangepro.com/mblTrans/send_withdraw_confirm",
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
          },
        }
      );
      let resJson = await res.json();

      if (resJson.success === 1) {
        setOpenSnackBar({
          ...openSnackBar,
          show: true,
          severity: "success",
          message: resJson.msg,
        });
        handleClose();
        handleShowOTP();
      } else {
        setOpenSnackBar({
          ...openSnackBar,
          show: true,
          severity: "warning",
          message: resJson.msg,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const finalizeWithdraw = async (event) => {
    event.preventDefault();
    try {
      // const data = new FormData(event.currentTarget);
      let res = await fetch(
        "https://trnsapiion.easyexchangepro.com/trans/sendAmount",
        {
          method: "POST",
          body: JSON.stringify({
            tfa_code: gOTP,
            email_verify_code: mailOTP,
            network: selectedNetwork,
            currency: selectedToken.symbol,
            amount: amount,
            address: address,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
          },
        }
      );
      let resJson = await res.json();
      if (resJson.status === 1) {
        setOpenSnackBar({
          ...openSnackBar,
          show: true,
          severity: "success",
          message: resJson.msg,
        });
        handleCloseOTP();
        handleShowFinal();
        getWithdrawInfo(setCurrencies, errorCatch);
        setAddress("");
        setAmount("");
        setSelectedNetwork("");
        setSelectedToken({});
      } else {
        setOpenSnackBar({
          ...openSnackBar,
          show: true,
          severity: "warning",
          message: resJson.msg,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  function fixNetworkNames(item) {
    if (item.toString().toUpperCase() === "BEP20") {
      return "Binance BEP20";
    } else if (item.toString().toUpperCase() === "TRX") {
      return "Tron TRC20";
    } else if (item.toString().toUpperCase() === "BTC") {
      return "Bitcoin Network";
    } else if (item.toString().toUpperCase() === "ETH") {
      return "Ethereum ERC20";
    } else if (item.toString().toUpperCase() === "DOGE") {
      return "Dogecoin";
    } else if (item.toString().toUpperCase() === "LTC") {
      return "Litecoin Network";
    } else {
      return item.toString().toUpperCase();
    }
  }
  const navigate = useNavigate();
  const gotToProfile = () => {
    navigate("/profile", { replace: true });
  };

  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);

  if (errorMsg !== null) {
    return (
      <div className="when-error">
        <div className="error-module">
          <div className="attention-withdraw">
            {errorMsg === "Please enable TFA" ? (
              <p className="p-2">
                {t('Withdraw_2FA_Error_1')}
                <span> {t('Withdraw_2FA_Error_2')} </span> {t('Withdraw_2FA_Error_3')}
              </p>
            ) : (
              <p className="p-2">
                {t('Withdraw_KYC_Error_1')}
                <span> {t('Withdraw_KYC_Error_2')} </span> {t('Withdraw_KYC_Error_3')}
              </p>
            )}
          </div>
          <div className="error-btn">
            <Button className="btn-withdraw-error" onClick={gotToProfile}>
              {t('Withdraw_Goto_Profile')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (pageWidth > 768) {
    return (
      <div className="withdraw-main">
        <div className="container withdraw">
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 withdraw-module m-4">
              <div className="row m-3">
                <div className="col">
                  <h5>{t('Global_Withdraw')}</h5>
                  <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                    <FormControl fullWidth>
                      <Select value={selectedToken} onChange={handleTokenChange}>
                        {Object.keys(currencies).map((token) => (
                          <MenuItem key={token} value={currencies[token]}>
                            <img
                              src={currencies[token].image}
                              style={{ width: 25, height: 25, marginRight: 10 }}
                              alt={"currency"}
                            />
                            {token}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="mt-2">
                      <FormControl fullWidth>
                        <Select
                          value={selectedNetwork}
                          onChange={(event) =>
                            setSelectedNetwork(event.target.value)
                          }
                        >
                          {selectedToken?.networks?.map((network) => (
                            <MenuItem key={network} value={network}>
                              {fixNetworkNames(network)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Box>
                  <div className="col d-flex">
                    <h6>{t('Global_Available')}: </h6>{" "}
                    <h6 className="ms-2"> {selectedToken?.balance}</h6>
                  </div>
                  <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                    <FormControl fullWidth>
                      <TextField
                        className="withdraw-texts"
                        InputProps={{ sx: { height: 57 } }}
                        id="address"
                        label={t('Global_Network_Address')}
                        size="medium"
                        name="usernetwork"
                        value={address}
                        onChange={(event) => setAddress(event.target.value)}
                        InputLabelProps={{
                          shrink: true,
                          className: "withdraw-texts",
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                    <FormControl fullWidth>
                      <TextField
                        className="withdraw-texts"
                        InputProps={{ sx: { height: 57 } }}
                        id="amount"
                        label={t('Global_Amount')}
                        size="medium"
                        value={amount}
                        onChange={(event) => setAmount(event.target.value)}
                        InputLabelProps={{
                          shrink: true,
                          className: "withdraw-texts",
                        }}
                      />
                    </FormControl>
                  </Box>
                  <div className="withdraw-bottom">
                    <div className="col d-flex">
                      <h6>{t('Global_Available')}: </h6>{" "}
                      <h6 className="ms-2"> {selectedToken?.balance}</h6>
                    </div>
                    <div className="col d-flex">
                      <h6>{t('Withdraw_min')}: </h6>{" "}
                      <h6 className="ms-2"> {selectedToken?.min}</h6>
                    </div>
                    <div className="col d-flex">
                      <h6>{t('Withdraw_max')}: </h6>{" "}
                      <h6 className="ms-2"> {selectedToken?.max}</h6>
                    </div>
                    <div className="col d-flex">
                      <h6>{t('Withdraw_fee')}: </h6>{" "}
                      <h6 className="ms-2"> {selectedToken?.fee}</h6>
                    </div>
                  </div>
                  <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                  >
                    <Button onClick={errorsSnack} disabled={checkAllOptions()}>
                      {t('Global_Submit')}
                    </Button>
                  </Grid>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    className="modal-withdraw"
                    backdrop="static"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{t('Global_Withdraw')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-list">
                      <p className="attention-withdraw">
                        {t('Withdraw_Unconfirmed_attention_1')} <span>{t('Global_Token')}</span> {t('Withdraw_Unconfirmed_attention_3')}
                        <span>{t('Withdraw_Unconfirmed_attention_4')}</span> {t('Withdraw_Unconfirmed_attention_5')}
                      </p>
                      <p className="attention-withdraw">
                        {t('Withdraw_Selected_token')}:{" "}
                        <span className="ms-2">{selectedToken.symbol}</span>
                      </p>
                      <p className="attention-withdraw">
                        {t('Withdraw_Selected_Network')}:{" "}
                        <span className="ms-2">
                          {fixNetworkNames(selectedNetwork)}
                        </span>
                      </p>
                      <p className="attention-withdraw">
                        {t('Global_Network_Address')}: <span className="ms-2">{address}</span>
                      </p>
                    </Modal.Body>
                    <ModalFooter>
                      <Button
                        className="confirm-withdraw"
                        onClick={sendRequestOTP}
                      >
                        {t('Global_Confirm')}
                      </Button>
                      <Button className="cancel-withdraw" onClick={handleClose}>
                        {t('Global_Cancel')}
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <Modal
                    show={showOTP}
                    onHide={handleCloseOTP}
                    className="modal-withdraw"
                    backdrop="static"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        {t('Withdraw_Confirm_Mail_2FA')}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-list">
                      <Form>
                        <FormControl fullWidth>
                          <TextField
                            className="withdraw-texts"
                            InputProps={{ sx: { height: 57 } }}
                            id="mailotp"
                            name="mailotp"
                            label={t('Global_Email') + " " + t('Global_OTP')}
                            size="medium"
                            onChange={(event) => setMailOTP(event.target.value)}
                            InputLabelProps={{
                              shrink: true,
                              className: "withdraw-texts",
                            }}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <TextField
                            className="withdraw-texts mt-3"
                            InputProps={{ sx: { height: 57 } }}
                            id="gotp"
                            name="gotp"
                            label={t('Global_2FA') + " " + t('Global_OTP')}
                            size="medium"
                            onChange={(event) => setGOTP(event.target.value)}
                            InputLabelProps={{
                              shrink: true,
                              className: "withdraw-texts",
                            }}
                          />
                        </FormControl>
                      </Form>
                    </Modal.Body>
                    <ModalFooter>
                      <Button
                        className="confirm-withdraw"
                        onClick={finalizeWithdraw}
                      >
                        {t('Global_Confirm')}
                      </Button>
                      <Button
                        className="cancel-withdraw"
                        onClick={handleCloseOTP}
                      >
                        {t('Global_Cancel')}
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <Modal
                    show={showFinal}
                    onHide={handleCloseFinal}
                    className="modal-withdraw"
                    backdrop="static"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{t('Withdraw_Confirmed')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-list">
                      <p className="attention-withdraw">
                        {t('Withdraw_Confirmed_attention_1')}<span>{t('Withdraw_Confirmed_attention_2')}</span> {t('Withdraw_Confirmed_attention_3')}
                        <span>{t('Withdraw_Confirmed_attention_4')}</span> {t('Withdraw_Confirmed_attention_5')}
                      </p>
                    </Modal.Body>
                    <ModalFooter>
                      <Button
                        className="confirm-withdraw"
                        onClick={handleCloseFinal}
                      >
                        {t('Global_Close')}
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <Stack spacing={2} sx={{ maxWidth: 300 }}>
                    <Snackbar
                      open={openSnackBar.show}
                      onClose={handleSnackBarClose}
                      // TransitionComponent={TransitionUp}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      autoHideDuration={5000}
                    >
                      <Alert
                        severity={openSnackBar.severity}
                        sx={{ fontSize: 18, maxWidth: 400 }}
                        variant="filled"
                      >
                        {openSnackBar.message}
                      </Alert>
                    </Snackbar>
                  </Stack>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="withdraw-main">
          <div className="container withdraw" style={{ marginTop: 100, marginBottom: 100 }}>
            <div className="row justify-content-center">
              <div className="col-10 col-md-6 withdraw-module m-4">
                <div className="row m-3">
                  <div className="col">
                    <h5>{t('Global_Withdraw')}</h5>
                    <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                      <FormControl fullWidth>
                        <Select value={selectedToken} onChange={handleTokenChange}>
                          {Object.keys(currencies).map((token) => (
                            <MenuItem key={token} value={currencies[token]}>
                              <img
                                src={currencies[token].image}
                                style={{ width: 25, height: 25, marginRight: 10 }}
                                alt={"currency"}
                              />
                              {token}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <div className="mt-2">
                        <FormControl fullWidth>
                          <Select
                            value={selectedNetwork}
                            onChange={(event) =>
                              setSelectedNetwork(event.target.value)
                            }
                          >
                            {selectedToken?.networks?.map((network) => (
                              <MenuItem key={network} value={network}>
                                {fixNetworkNames(network)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Box>
                    <div className="col d-flex">
                      <h6>{t('Global_Available')}: </h6>{" "}
                      <h6 className="ms-2"> {selectedToken?.balance}</h6>
                    </div>
                    <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                      <FormControl fullWidth>
                        <TextField
                          className="withdraw-texts"
                          InputProps={{ sx: { height: 57 } }}
                          id="address"
                          label={t('Global_Network_Address')}
                          size="medium"
                          name="usernetwork"
                          value={address}
                          onChange={(event) => setAddress(event.target.value)}
                          InputLabelProps={{
                            shrink: true,
                            className: "withdraw-texts",
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                      <FormControl fullWidth>
                        <TextField
                          className="withdraw-texts"
                          InputProps={{ sx: { height: 57 } }}
                          id="amount"
                          label={t('Global_Amount')}
                          size="medium"
                          value={amount}
                          onChange={(event) => setAmount(event.target.value)}
                          InputLabelProps={{
                            shrink: true,
                            className: "withdraw-texts",
                          }}
                        />
                      </FormControl>
                    </Box>
                    <div className="withdraw-bottom">
                      <div className="col d-flex">
                        <h6>{t('Global_Available')}: </h6>{" "}
                        <h6 className="ms-2"> {selectedToken?.balance}</h6>
                      </div>
                      <div className="col d-flex">
                        <h6>{t('Withdraw_min')}: </h6>{" "}
                        <h6 className="ms-2"> {selectedToken?.min}</h6>
                      </div>
                      <div className="col d-flex">
                        <h6>{t('Withdraw_max')}: </h6>{" "}
                        <h6 className="ms-2"> {selectedToken?.max}</h6>
                      </div>
                      <div className="col d-flex">
                        <h6>{t('Withdraw_fee')}: </h6>{" "}
                        <h6 className="ms-2"> {selectedToken?.fee}</h6>
                      </div>
                    </div>
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt={2}
                    >
                      <Button onClick={errorsSnack} disabled={checkAllOptions()}>
                        {t('Global_Submit')}
                      </Button>
                    </Grid>
                    <Modal
                      show={show}
                      onHide={handleClose}
                      className="modal-withdraw"
                      backdrop="static"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{t('Global_Withdraw')}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="modal-list">
                        <p className="attention-withdraw">
                          {t('Withdraw_Unconfirmed_attention_1')} <span>{t('Global_Token')}</span> {t('Withdraw_Unconfirmed_attention_3')}
                          <span>{t('Withdraw_Unconfirmed_attention_4')}</span> {t('Withdraw_Unconfirmed_attention_5')}
                        </p>
                        <p className="attention-withdraw">
                          {t('Withdraw_Selected_token')}:{" "}
                          <span className="ms-2">{selectedToken.symbol}</span>
                        </p>
                        <p className="attention-withdraw">
                          {t('Withdraw_Selected_Network')}:{" "}
                          <span className="ms-2">
                            {fixNetworkNames(selectedNetwork)}
                          </span>
                        </p>
                        <p className="attention-withdraw">
                          {t('Global_Network_Address')}: <span className="ms-2">{address}</span>
                        </p>
                      </Modal.Body>
                      <ModalFooter>
                        <Button
                          className="confirm-withdraw"
                          onClick={sendRequestOTP}
                        >
                          {t('Global_Confirm')}
                        </Button>
                        <Button className="cancel-withdraw" onClick={handleClose}>
                          {t('Global_Cancel')}
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <Modal
                      show={showOTP}
                      onHide={handleCloseOTP}
                      className="modal-withdraw"
                      backdrop="static"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          {t('Withdraw_Confirm_Mail_2FA')}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="modal-list">
                        <Form>
                          <FormControl fullWidth>
                            <TextField
                              className="withdraw-texts"
                              InputProps={{ sx: { height: 57 } }}
                              id="mailotp"
                              name="mailotp"
                              label={t('Global_Email') + " " + t('Global_OTP')}
                              size="medium"
                              onChange={(event) => setMailOTP(event.target.value)}
                              InputLabelProps={{
                                shrink: true,
                                className: "withdraw-texts",
                              }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              className="withdraw-texts mt-3"
                              InputProps={{ sx: { height: 57 } }}
                              id="gotp"
                              name="gotp"
                              label={t('Global_2FA') + " " + t('Global_OTP')}
                              size="medium"
                              onChange={(event) => setGOTP(event.target.value)}
                              InputLabelProps={{
                                shrink: true,
                                className: "withdraw-texts",
                              }}
                            />
                          </FormControl>
                        </Form>
                      </Modal.Body>
                      <ModalFooter>
                        <Button
                          className="confirm-withdraw"
                          onClick={finalizeWithdraw}
                        >
                          {t('Global_Confirm')}
                        </Button>
                        <Button
                          className="cancel-withdraw"
                          onClick={handleCloseOTP}
                        >
                          {t('Global_Cancel')}
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <Modal
                      show={showFinal}
                      onHide={handleCloseFinal}
                      className="modal-withdraw"
                      backdrop="static"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{t('Withdraw_Confirmed')}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="modal-list">
                        <p className="attention-withdraw">
                          {t('Withdraw_Confirmed_attention_1')}<span>{t('Withdraw_Confirmed_attention_2')}</span> {t('Withdraw_Confirmed_attention_3')}
                          <span>{t('Withdraw_Confirmed_attention_4')}</span> {t('Withdraw_Confirmed_attention_5')}
                        </p>
                      </Modal.Body>
                      <ModalFooter>
                        <Button
                          className="confirm-withdraw"
                          onClick={handleCloseFinal}
                        >
                          {t('Global_Close')}
                        </Button>
                      </ModalFooter>
                    </Modal>

                    <Stack spacing={2} sx={{ maxWidth: 300 }}>
                      <Snackbar
                        open={openSnackBar.show}
                        onClose={handleSnackBarClose}
                        // TransitionComponent={TransitionUp}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={5000}
                      >
                        <Alert
                          severity={openSnackBar.severity}
                          sx={{ fontSize: 18, maxWidth: 400 }}
                          variant="filled"
                        >
                          {openSnackBar.message}
                        </Alert>
                      </Snackbar>
                    </Stack>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Withdraw;
