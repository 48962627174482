import React, { useEffect, useState } from "react";
import "./termsofuse.scss";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../home/HeaderMobile";
import BottomNavigationMobile from "../home/BottomNavigationMobile";
function TermsOfUse() {
  const { t } = useTranslation();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);
  if (pageWidth > 768) {
    return (
      <div className="terms-main">
        <div className="terms-module container">
          <h1 className="h1-m-terms">{t('Term_1_title')}</h1>

          <p>
            {t('Term_1_description_1')}
          </p>

          <p>
            {t('Term_1_description_2')}
          </p>

          <h1 className="h1-m-terms">{t('Term_2_title')}</h1>

          <p>
            {t('Term_2_description_1')}
          </p>

          <p>
            {t('Term_2_description_2')}
          </p>

          <p>{t('Term_2_description_3')}</p>
          <p>{t('Term_2_description_4')}</p>
          <p>
            {t('Term_2_description_5')}
          </p>
          <p>
            {t('Term_2_description_6')}
          </p>
          <p>
            {t('Term_2_description_7')}
          </p>
          <p>
            {t('Term_2_description_8')}
          </p>

          <h1 className="h1-m-terms">{t('Term_3_title')}</h1>

          <p>
            {t('Term_3_description_1')}
          </p>

          <p>
            {t('Term_3_description_2')}
          </p>

          <p>
            {t('Term_3_description_3')}
          </p>

          <p>
            {t('Term_3_description_4')}
          </p>

          <p>
            {t('Term_3_description_5')}
          </p>

          <p>
            {t('Term_3_description_6')}
          </p>

          <h1 className="h1-m-terms">{t('Term_4_title')}</h1>
          <p>
            {t('Term_4_description')}
          </p>

          <h2>{t('Term_4_subheader_1')}</h2>

          <h2>{t('Term_4_subtitle_1')}</h2>

          <p>
            {t('Term_4_description_1')}

          </p>

          <h2>{t('Term_4_subtitle_2')}</h2>

          <p>
            {t('Term_4_description_2')}

          </p>

          <h2>{t('Term_4_subtitle_3')}</h2>

          <p>
            {t('Term_4_description_3')}

          </p>

          <h2>{t('Term_4_subtitle_4')}</h2>

          <p>
            {t('Term_4_description_4')}

          </p>

          <h1 className="h1-m-terms">{t('Term_4_subheader_2')}</h1>

          <p>
            {t('Term_4_description_5')}

          </p>

          <h1 className="h1-m-terms">{t('Term_5_title')}</h1>

          <p>
            {t('Term_5_description_1')}

          </p>

          <p>
            {t('Term_5_description_2')}
          </p>

          <p>
            {t('Term_5_description_3')}
          </p>

          <p>
            {t('Term_5_description_4')}
          </p>

          <p>
            {t('Term_5_description_5')}
          </p>

          <h1 className="h1-m-terms">{t('Term_6_title')}</h1>

          <p>
            {t('Term_6_description_1')}
          </p>

          <p>
            {t('Term_6_description_2')}
          </p>

          <p>
            {t('Term_6_description_3')}
          </p>

          <p>
            {t('Term_6_description_4')}
          </p>

          <p>
            {t('Term_6_description_5')}
          </p>

          <h1 className="h1-m-terms">{t('Term_7_title')}</h1>
          <p>
            {t('Term_7_description_1')}
          </p>
          <p>
            {t('Term_7_description_2')}
          </p>
          <p>
            {t('Term_7_description_3')}
          </p>

          <h1 className="h1-m-terms">{t('Term_8_title')}</h1>

          <p>
            {t('Term_8_description_1')}
          </p>

          <p>
            {t('Term_8_description_2')}
          </p>

          <h1 className="h1-m-terms">{t('Term_9_title')}</h1>

          <p>
            {t('Term_9_description')}
          </p>

          <h1 className="h1-m-terms">{t('Term_10_title')}</h1>

          <p>
            {t('Term_10_description_1')}
          </p>
          <p>
            {t('Term_10_description_2')}
          </p>

          <h1 className="h1-m-terms">{t('Term_11_title')}</h1>

          <p>
            {t('Term_11_description')}
          </p>

          <h1 className="h1-m-terms">{t('Term_12_title')}</h1>
          <p>
            {t('Term_12_description_1')}
          </p>
          <p>
            {t('Term_12_description_2')}
          </p>

          <h1 className="h1-m-terms">{t('Term_13_title')}</h1>

          <h2>{t('Term_13_subtitle_1')}</h2>
          <p>
            {t('Term_13_description_1')}
          </p>

          <h2>{t('Term_13_subtitle_2')}</h2>
          <p>
            {" "}
            {t('Term_13_description_2')}
          </p>

          <h2>{t('Term_13_subtitle_3')}</h2>
          <p>
            {" "}
            {t('Term_13_description_3')}
          </p>

          <h2>{t('Term_13_subtitle_4')}</h2>
          <p>
            {" "}
            {t('Term_13_description_4')}
          </p>

          <h2>{t('Term_13_subtitle_5')}</h2>
          <p>
            {" "}
            {t('Term_13_description_5')}
          </p>

          <h2>{t('Term_13_subtitle_6')}</h2>
          <p>
            {" "}
            {t('Term_13_description_6')}
          </p>

          <h2>{t('Term_13_subtitle_7')}</h2>
          <p>
            {" "}
            {t('Term_13_description_7')}
          </p>

          <h2>{t('Term_13_subtitle_8')}</h2>
          <p>
            {" "}
            {t('Term_13_description_8')}
          </p>

          <h1 className="h1-m-terms">{t('Term_14_title')}</h1>

          <p>
            {t('Term_14_description')}
          </p>

          <h1 className="h1-m-terms">{t('Term_15_title')}</h1>

          <p>
            {t('Term_15_description_1')}
          </p>

          <p>
            {t('Term_15_description_2')}
          </p>

          <h1 className="h1-m-terms">{t('Term_16_title')}</h1>

          <p>
            {t('Term_16_description')}
          </p>

          <h1 className="h1-m-terms">{t('Term_17_title')}</h1>

          <h2>{t('Term_17_subtitle_1')}</h2>

          <p>
            {t('Term_17_description_1')}
          </p>

          <h2>{t('Term_17_subtitle_2')}</h2>

          <p>
            {t('Term_17_description_2')}
          </p>

          <h2>{t('Term_17_subtitle_3')}</h2>

          <p>
            {t('Term_17_description_3')}
          </p>

          <h2>{t('Term_17_subtitle_4')}</h2>

          <p>
            {t('Term_17_description_4')}
          </p>

          <h2>{t('Term_17_subtitle_5')}</h2>
          <p>
            {t('Term_17_description_5')}
          </p>

          <h2>{t('Term_17_subtitle_6')}</h2>
          <p>
            {t('Term_17_description_6')}
          </p>
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="terms-main" >
          <div className="terms-module-m container" style={{ marginTop: 150, marginBottom: 150 }}>
            <h1 className="h1-m-terms">{t('Term_1_title')}</h1>

            <p>
              {t('Term_1_description_1')}
            </p>

            <p>
              {t('Term_1_description_2')}
            </p>

            <h1 className="h1-m-terms">{t('Term_2_title')}</h1>

            <p>
              {t('Term_2_description_1')}
            </p>

            <p>
              {t('Term_2_description_2')}
            </p>

            <p>{t('Term_2_description_3')}</p>
            <p>{t('Term_2_description_4')}</p>
            <p>
              {t('Term_2_description_5')}
            </p>
            <p>
              {t('Term_2_description_6')}
            </p>
            <p>
              {t('Term_2_description_7')}
            </p>
            <p>
              {t('Term_2_description_8')}
            </p>

            <h1 className="h1-m-terms">{t('Term_3_title')}</h1>

            <p>
              {t('Term_3_description_1')}
            </p>

            <p>
              {t('Term_3_description_2')}
            </p>

            <p>
              {t('Term_3_description_3')}
            </p>

            <p>
              {t('Term_3_description_4')}
            </p>

            <p>
              {t('Term_3_description_5')}
            </p>

            <p>
              {t('Term_3_description_6')}
            </p>

            <h1 className="h1-m-terms">{t('Term_4_title')}</h1>
            <p>
              {t('Term_4_description')}
            </p>

            <h2>{t('Term_4_subheader_1')}</h2>

            <h2>{t('Term_4_subtitle_1')}</h2>

            <p>
              {t('Term_4_description_1')}

            </p>

            <h2>{t('Term_4_subtitle_2')}</h2>

            <p>
              {t('Term_4_description_2')}

            </p>

            <h2>{t('Term_4_subtitle_3')}</h2>

            <p>
              {t('Term_4_description_3')}

            </p>

            <h2>{t('Term_4_subtitle_4')}</h2>

            <p>
              {t('Term_4_description_4')}

            </p>

            <h1 className="h1-m-terms">{t('Term_4_subheader_2')}</h1>

            <p>
              {t('Term_4_description_5')}

            </p>

            <h1 className="h1-m-terms">{t('Term_5_title')}</h1>

            <p>
              {t('Term_5_description_1')}

            </p>

            <p>
              {t('Term_5_description_2')}
            </p>

            <p>
              {t('Term_5_description_3')}
            </p>

            <p>
              {t('Term_5_description_4')}
            </p>

            <p>
              {t('Term_5_description_5')}
            </p>

            <h1 className="h1-m-terms">{t('Term_6_title')}</h1>

            <p>
              {t('Term_6_description_1')}
            </p>

            <p>
              {t('Term_6_description_2')}
            </p>

            <p>
              {t('Term_6_description_3')}
            </p>

            <p>
              {t('Term_6_description_4')}
            </p>

            <p>
              {t('Term_6_description_5')}
            </p>

            <h1 className="h1-m-terms">{t('Term_7_title')}</h1>
            <p>
              {t('Term_7_description_1')}
            </p>
            <p>
              {t('Term_7_description_2')}
            </p>
            <p>
              {t('Term_7_description_3')}
            </p>

            <h1 className="h1-m-terms">{t('Term_8_title')}</h1>

            <p>
              {t('Term_8_description_1')}
            </p>

            <p>
              {t('Term_8_description_2')}
            </p>

            <h1 className="h1-m-terms">{t('Term_9_title')}</h1>

            <p>
              {t('Term_9_description')}
            </p>

            <h1 className="h1-m-terms">{t('Term_10_title')}</h1>

            <p>
              {t('Term_10_description_1')}
            </p>
            <p>
              {t('Term_10_description_2')}
            </p>

            <h1 className="h1-m-terms">{t('Term_11_title')}</h1>

            <p>
              {t('Term_11_description')}
            </p>

            <h1 className="h1-m-terms">{t('Term_12_title')}</h1>
            <p>
              {t('Term_12_description_1')}
            </p>
            <p>
              {t('Term_12_description_2')}
            </p>

            <h1 className="h1-m-terms">{t('Term_13_title')}</h1>

            <h2>{t('Term_13_subtitle_1')}</h2>
            <p>
              {t('Term_13_description_1')}
            </p>

            <h2>{t('Term_13_subtitle_2')}</h2>
            <p>
              {" "}
              {t('Term_13_description_2')}
            </p>

            <h2>{t('Term_13_subtitle_3')}</h2>
            <p>
              {" "}
              {t('Term_13_description_3')}
            </p>

            <h2>{t('Term_13_subtitle_4')}</h2>
            <p>
              {" "}
              {t('Term_13_description_4')}
            </p>

            <h2>{t('Term_13_subtitle_5')}</h2>
            <p>
              {" "}
              {t('Term_13_description_5')}
            </p>

            <h2>{t('Term_13_subtitle_6')}</h2>
            <p>
              {" "}
              {t('Term_13_description_6')}
            </p>

            <h2>{t('Term_13_subtitle_7')}</h2>
            <p>
              {" "}
              {t('Term_13_description_7')}
            </p>

            <h2>{t('Term_13_subtitle_8')}</h2>
            <p>
              {" "}
              {t('Term_13_description_8')}
            </p>

            <h1 className="h1-m-terms">{t('Term_14_title')}</h1>

            <p>
              {t('Term_14_description')}
            </p>

            <h1 className="h1-m-terms">{t('Term_15_title')}</h1>

            <p>
              {t('Term_15_description_1')}
            </p>

            <p>
              {t('Term_15_description_2')}
            </p>

            <h1 className="h1-m-terms">{t('Term_16_title')}</h1>

            <p>
              {t('Term_16_description')}
            </p>

            <h1 className="h1-m-terms">{t('Term_17_title')}</h1>

            <h2>{t('Term_17_subtitle_1')}</h2>

            <p>
              {t('Term_17_description_1')}
            </p>

            <h2>{t('Term_17_subtitle_2')}</h2>

            <p>
              {t('Term_17_description_2')}
            </p>

            <h2>{t('Term_17_subtitle_3')}</h2>

            <p>
              {t('Term_17_description_3')}
            </p>

            <h2>{t('Term_17_subtitle_4')}</h2>

            <p>
              {t('Term_17_description_4')}
            </p>

            <h2>{t('Term_17_subtitle_5')}</h2>
            <p>
              {t('Term_17_description_5')}
            </p>

            <h2>{t('Term_17_subtitle_6')}</h2>
            <p>
              {t('Term_17_description_6')}
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default TermsOfUse;
