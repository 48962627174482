import React, { useEffect, useState } from "react";
import "./privacy.scss";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../home/HeaderMobile";
import BottomNavigationMobile from "../home/BottomNavigationMobile";
function PrivacyPolicy() {
  const { t } = useTranslation();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);
  if (pageWidth > 768) {
    return (
      <div className="privacy-main">
        <div className="privacy-module container">
          <h1>{t('Privacy_1_title')}</h1>
          <p>
            {t('Privacy_1_desciption')}

          </p>

          <h1>{t('Privacy_2_title')}</h1>
          <p>
            {t('Privacy_2_desciption')}
          </p>

          <h1>{t('Privacy_3_title')}</h1>
          <p>
            {t('Privacy_3_desciption_1')}
          </p>
          <p>
            {t('Privacy_3_desciption_2')}
          </p>

          <h1>{t('Privacy_4_title')}</h1>
          <h2>{t('Privacy_4_subtitle_1')}</h2>
          <p>{t('Privacy_4_list_1_1')}</p>
          <p>{t('Privacy_4_list_1_2')}</p>
          <p>{t('Privacy_4_list_1_3')}</p>
          <p>{t('Privacy_4_list_1_4')}</p>
          <p>{t('Privacy_4_list_1_5')}</p>
          <p>{t('Privacy_4_list_1_6')}</p>
          <p>{t('Privacy_4_list_1_7')}</p>
          <p>{t('Privacy_4_list_1_8')}</p>
          <p>{t('Privacy_4_list_1_9')}</p>
          <p>{t('Privacy_4_list_1_10')}</p>
          <h2>{t('Privacy_4_subtitle_2')}</h2>
          <p>{t('Privacy_4_list_2_1')}</p>
          <p>{t('Privacy_4_list_2_2')}</p>
          <p>{t('Privacy_4_list_2_3')}</p>
          <p>{t('Privacy_4_list_2_4')}</p>
          <p>{t('Privacy_4_list_2_5')}</p>
          <p>{t('Privacy_4_list_2_6')}</p>
          <p>{t('Privacy_4_list_2_7')}</p>
          <p>{t('Privacy_4_list_2_8')}</p>
          <p>{t('Privacy_4_list_2_9')}</p>
          <p>{t('Privacy_4_list_2_10')}</p>
          <p>{t('Privacy_4_list_2_11')}</p>
          <p>{t('Privacy_4_list_2_12')}</p>

          <h1>{t('Privacy_5_title')}</h1>
          <p>{t('Privacy_5_desciption')}</p>

          <h1>{t('Privacy_6_title')}</h1>
          <p>
            {t('Privacy_6_desciption_1')}
          </p>
          <p>
            {t('Privacy_6_desciption_2')}
          </p>

          <h1>{t('Privacy_7_title')}</h1>
          <p>
            {t('Privacy_7_desciption_1')}
          </p>
          <p>{t('Privacy_7_desciption_2')}</p>
          <p>
            {t('Privacy_7_desciption_3')}
          </p>
          <p>
            {t('Privacy_7_desciption_4')}
          </p>

          <h1>{t('Privacy_8_title')}</h1>
          <p>
            {t('Privacy_8_desciption')}
          </p>
          <h1>{t('Privacy_9_title')}</h1>
          <p>
            {t('Privacy_9_desciption')}
          </p>
          <h1>{t('Privacy_10_title')}</h1>
          <p>
            {t('Privacy_10_desciption')}
          </p>
          <h1>
            {" "}
            {t('Privacy_11_title')}
          </h1>
          <p>
            {t('Privacy_11_desciption')}
          </p>

          <h1>{t('Privacy_12_title')}</h1>
          <p>
            {t('Privacy_12_desciption')}

          </p>
          <h1>{t('Privacy_13_title')}</h1>
          <p>
            {t('Privacy_13_desciption')}
          </p>
          <h1>{t('Privacy_14_title')}</h1>
          <p>
            {t('Privacy_14_desciption')}
          </p>
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="privacy-main">
          <div className="terms-module-m container" style={{ marginTop: 150, marginBottom: 150 }}>
            <h1>{t('Privacy_1_title')}</h1>
            <p>
              {t('Privacy_1_desciption')}

            </p>

            <h1>{t('Privacy_2_title')}</h1>
            <p>
              {t('Privacy_2_desciption')}
            </p>

            <h1>{t('Privacy_3_title')}</h1>
            <p>
              {t('Privacy_3_desciption_1')}
            </p>
            <p>
              {t('Privacy_3_desciption_2')}
            </p>

            <h1>{t('Privacy_4_title')}</h1>
            <h2>{t('Privacy_4_subtitle_1')}</h2>
            <p>{t('Privacy_4_list_1_1')}</p>
            <p>{t('Privacy_4_list_1_2')}</p>
            <p>{t('Privacy_4_list_1_3')}</p>
            <p>{t('Privacy_4_list_1_4')}</p>
            <p>{t('Privacy_4_list_1_5')}</p>
            <p>{t('Privacy_4_list_1_6')}</p>
            <p>{t('Privacy_4_list_1_7')}</p>
            <p>{t('Privacy_4_list_1_8')}</p>
            <p>{t('Privacy_4_list_1_9')}</p>
            <p>{t('Privacy_4_list_1_10')}</p>
            <h2>{t('Privacy_4_subtitle_2')}</h2>
            <p>{t('Privacy_4_list_2_1')}</p>
            <p>{t('Privacy_4_list_2_2')}</p>
            <p>{t('Privacy_4_list_2_3')}</p>
            <p>{t('Privacy_4_list_2_4')}</p>
            <p>{t('Privacy_4_list_2_5')}</p>
            <p>{t('Privacy_4_list_2_6')}</p>
            <p>{t('Privacy_4_list_2_7')}</p>
            <p>{t('Privacy_4_list_2_8')}</p>
            <p>{t('Privacy_4_list_2_9')}</p>
            <p>{t('Privacy_4_list_2_10')}</p>
            <p>{t('Privacy_4_list_2_11')}</p>
            <p>{t('Privacy_4_list_2_12')}</p>

            <h1>{t('Privacy_5_title')}</h1>
            <p>{t('Privacy_5_desciption')}</p>

            <h1>{t('Privacy_6_title')}</h1>
            <p>
              {t('Privacy_6_desciption_1')}
            </p>
            <p>
              {t('Privacy_6_desciption_2')}
            </p>

            <h1>{t('Privacy_7_title')}</h1>
            <p>
              {t('Privacy_7_desciption_1')}
            </p>
            <p>{t('Privacy_7_desciption_2')}</p>
            <p>
              {t('Privacy_7_desciption_3')}
            </p>
            <p>
              {t('Privacy_7_desciption_4')}
            </p>

            <h1>{t('Privacy_8_title')}</h1>
            <p>
              {t('Privacy_8_desciption')}
            </p>
            <h1>{t('Privacy_9_title')}</h1>
            <p>
              {t('Privacy_9_desciption')}
            </p>
            <h1>{t('Privacy_10_title')}</h1>
            <p>
              {t('Privacy_10_desciption')}
            </p>
            <h1>
              {" "}
              {t('Privacy_11_title')}
            </h1>
            <p>
              {t('Privacy_11_desciption')}
            </p>

            <h1>{t('Privacy_12_title')}</h1>
            <p>
              {t('Privacy_12_desciption')}

            </p>
            <h1>{t('Privacy_13_title')}</h1>
            <p>
              {t('Privacy_13_desciption')}
            </p>
            <h1>{t('Privacy_14_title')}</h1>
            <p>
              {t('Privacy_14_desciption')}
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default PrivacyPolicy;
