import React from "react";
import ImgOne from "../../assets/images/bitcoin.png";
import ImgTwo from "../../assets/images/bloomberg.png";
import ImgThree from "../../assets/images/cmc.png";
import ImgFour from "../../assets/images/coindesk.png";
import ImgFive from "../../assets/images/coing.png";
import ImgSix from "../../assets/images/cointelegraph.png";
import ImgSeven from "../../assets/images/cryptodaily.png";
import ImgEight from "../../assets/images/download.png";

function Trademarks() {
  return (
    <div className="row container-fluid otherparties justify-content-center text-center">
      <div className="imageMain img-fluid col-md-3 col-6">
        <img src={ImgOne} className="img-fluid" alt="" />
      </div>
      <div className="imageMain img-fluid col-md-3 col-6">
        <img src={ImgTwo} className="img-fluid" alt="" />
      </div>
      <div className="imageMain img-fluid col-md-3 col-6">
        <img src={ImgThree} className="img-fluid" alt="" />
      </div>
      <div className="imageMain img-fluid col-md-3 col-6">
        <img src={ImgFour} className="img-fluid" alt="" />
      </div>
      <div className="imageMain img-fluid col-md-3 col-6">
        <img src={ImgFive} className="img-fluid" alt="" />
      </div>
      <div className="imageMain img-fluid col-md-3 col-6">
        <img src={ImgSix} className="img-fluid" alt="" />
      </div>
      <div className="imageMain img-fluid col-md-3 col-6">
        <img src={ImgSeven} className="img-fluid" alt="" />
      </div>
      <div className="imageMain img-fluid col-md-3 col-6">
        <img src={ImgEight} className="img-fluid" alt="" />
      </div>
    </div>
  );
}

export default Trademarks;
