import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { UserContext } from "../providers/user";
import "./dialog.scss";

function InputDialogGlobal2({ closeDialog2 }) {
  const { state, dispatch } = useContext(UserContext);

  return (
    <>
      <Modal show={state.isOpenDialog2} onHide={closeDialog2} backdrop="static">
        <Modal.Header>
          <Modal.Title>{state.dialogTitle1}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={state.submitDialog2}>
            <Form.Group className="mb-3">
              <Form.Control
                type="number"
                placeholder={state.dialogContent1}
                autoFocus
                name={state.dialogName1}
              />
              <Form.Control
                type="number"
                placeholder={state.dialogContent2}
                autoFocus
                name={state.dialogName2}
                className="mt-3"
              />
            </Form.Group>
            <div className="row justify-content-around text-center">
              <div className="col">
                <Button variant="secondary" onClick={closeDialog2}>
                  Close
                </Button>
              </div>
              <div className="col">
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default InputDialogGlobal2;
