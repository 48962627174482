import network from "../../assets/json/network.json";
import document from "../../assets/json/business.json";
import support from "../../assets/json/message-service.json";
import React from "react";
import { Carousel } from "react-bootstrap";
import Lottie from "lottie-react";
import "../carousel/carousel.scss";
import { useTranslation } from "react-i18next";

function HomeCarousel() {
  const { t } = useTranslation();
  return (
    <Carousel variant="dark" controls={false}>
      {/* <Carousel.Item>
        <div className="row d-flex ">
          <div className="carousel-img col col-md-6 ">
            <Lottie
              animationData={network}
              style={{ height: "300px", width: "300px" }}
            />
          </div>
          <div className="col col-md-6 mt-5 ">
            <h1>{t("Home_Carousel_1_title")}</h1>
            <h4 className="text-start">{t("Home_Carousel_1_description")}</h4>
          </div>
        </div>
      </Carousel.Item> */}
      <Carousel.Item>
        <div className="row d-flex  ">
          <div className="carousel-img col col-md-6 ">
            <Lottie
              animationData={document}
              style={{ height: "300px", width: "300px" }}
            />
          </div>
          <div className="col col-md-6 mt-5">
            <h1>{t("Home_Carousel_2_title")}</h1>
            <h4 className="text-start">{t("Home_Carousel_2_description")}</h4>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="row d-flex  ">
          <div className="carousel-img col col-md-6 ">
            <Lottie
              animationData={support}
              style={{ height: "300px", width: "300px" }}
            />
          </div>
          <div className="col col-md-6 mt-5">
            <h1>{t("Home_Carousel_3_title")}</h1>
            <h4 className="text-start">{t("Home_Carousel_3_description")}</h4>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default HomeCarousel;
