import React, { useContext, useEffect, useState } from "react";

import "./register.scss";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Button, Spinner } from "react-bootstrap";
import CountryList from "../../utils/Countrylist";
import { ItemNames, getStorage, setStorage } from "../../utils/RSS";
import { UserContext } from "../../contexts/providers/user";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../home/HeaderMobile";
import BottomNavigationMobile from "../home/BottomNavigationMobile";

function Register() {
  const navigate = useNavigate();
  const { dispatch } = useContext(UserContext);
  const [checkMail, setCheckMail] = useState(false);
  const [userName, setUsername] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [refCode, setRefCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const sendOTP = async (event) => {
    event.preventDefault();
    if (country === "") {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "Please select your country",
      });
    } else {
      try {
        setIsLoading(true);
        let res = await fetch(
          "https://api.easyexchangepro.com/api/v2/send/otp",
          {
            method: "POST",
            body: JSON.stringify({
              email,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        let resJson = await res.json();
        if (resJson.status === 1) {
          dispatch({
            type: "open_snack",
            snackColor: "success",
            snackMessage: resJson.msg,
          });
          showOTP();
        } else {
          dispatch({
            type: "open_snack",
            snackColor: "warning",
            snackMessage: resJson.msg,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const showOTP = () => {
    dispatch({
      type: "open_dialog",
      content: t('Global_Email') + " " + t('Global_OTP'),
      title: t('Reg_enter_otp'),
      submitDialog: verifyEmailRequest,
      btnText: t('Global_Confirm'),
      dialogName: "email_otp",
    });
  };
  const { t } = useTranslation();

  const verifyEmailRequest = async (event) => {
    event.preventDefault();

    try {
      const data = new FormData(event.currentTarget);
      let res = await fetch(
        "https://api.easyexchangepro.com/api/v2/verify/email",
        {
          method: "POST",
          body: JSON.stringify({
            email: email,
            otp: data.get("email_otp"),
          }),
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
          },
        }
      );
      let resJson = await res.json();
      if (resJson.status === 1) {
        dispatch({
          type: "open_snack",
          snackColor: "success",
          snackMessage: resJson.msg,
        });
        setCheckMail(true);
        dispatch({
          type: "close_dialog",
        });
      } else {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.msg,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const signUp = async (event) => {
    event.preventDefault();

    try {
      setIsLoading2(true);
      let res = await fetch("https://api.easyexchangepro.com/api/v2/signup", {
        method: "POST",
        body: JSON.stringify({
          username: userName,
          country: country[0],
          email: email,
          password: password,
          confirm_password: confirmPassword,
          ref_code: refCode,
          device_type: "web",
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      });
      let resJson = await res.json();
      if (resJson.status === 1) {
        dispatch({
          type: "open_snack",
          snackColor: "success",
          snackMessage: resJson.msg,
        });
        setStorage(ItemNames.TOKEN, resJson.token);
        dispatch({ type: "login" });
        navigate("/", { replace: true });
      } else {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.msg,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading2(false);
    }
  };

  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);

  if (pageWidth > 768) {
    return (
      <div className="register-main">
        <div className="container">
          <div className="row register-module">
            <div className="col text-center">
              <h5 className="mt-4 register-title">{t('Global_Register')}</h5>
              <Form onSubmit={sendOTP}>
                <InputGroup className="mt-5">
                  <Form.Control
                    type="text"
                    placeholder={t('Global_Username')}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </InputGroup>
                <div className="col country-btn">
                  <CountryList
                    selected={country}
                    onChange={(e) => {
                      setCountry(e);
                    }}
                  />
                </div>
                <InputGroup className="mt-3">
                  <Form.Control
                    type="text"
                    placeholder={t('Global_Email')}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </InputGroup>
                {checkMail === false ? (
                  <div className="col">
                    <Button
                      className="submit-btn m-3"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}
                      {isLoading ? t('Global_Loading') : t('Reg_verify_email')}
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </Form>
              {checkMail === true ? (
                <Form onSubmit={signUp}>
                  <InputGroup className="mt-2">
                    <Form.Control
                      type="text"
                      placeholder={t('Global_Password')}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </InputGroup>
                  <InputGroup className="mt-3">
                    <Form.Control
                      type="text"
                      placeholder={t('Reg_Confirm_Password')}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </InputGroup>
                  <InputGroup className="mt-3">
                    <Form.Control
                      type="text"
                      placeholder={t('Reg_Referral_Code')}
                      onChange={(e) => setRefCode(e.target.value)}
                    />
                  </InputGroup>
                  <div className="col">
                    <Button
                      className="submit-btn m-3"
                      disabled={isLoading2}
                      type="submit"
                    >
                      {isLoading2 ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}

                      {isLoading2 ? t('Global_Loading') : t('Global_Register')}
                    </Button>
                  </div>
                </Form>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="register-main mt-2">
          <div className="container">
            <div className="row register-module">
              <div className="col text-center">
                <h5 className="mt-4 register-title">{t('Global_Register')}</h5>
                <Form onSubmit={sendOTP}>
                  <InputGroup className="mt-5">
                    <Form.Control
                      type="text"
                      placeholder={t('Global_Username')}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </InputGroup>
                  <div className="col country-btn">
                    <CountryList
                      selected={country}
                      onChange={(e) => {
                        setCountry(e);
                      }}
                    />
                  </div>
                  <InputGroup className="mt-3">
                    <Form.Control
                      type="text"
                      placeholder={t('Global_Email')}
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </InputGroup>
                  {checkMail === false ? (
                    <div className="col">
                      <Button
                        className="submit-btn m-3"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : null}
                        {isLoading ? t('Global_Loading') : t('Reg_verify_email')}
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </Form>
                {checkMail === true ? (
                  <Form onSubmit={signUp}>
                    <InputGroup className="mt-2">
                      <Form.Control
                        type="text"
                        placeholder={t('Global_Password')}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </InputGroup>
                    <InputGroup className="mt-3">
                      <Form.Control
                        type="text"
                        placeholder={t('Reg_Confirm_Password')}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                    </InputGroup>
                    <InputGroup className="mt-3">
                      <Form.Control
                        type="text"
                        placeholder={t('Reg_Referral_Code')}
                        onChange={(e) => setRefCode(e.target.value)}
                      />
                    </InputGroup>
                    <div className="col">
                      <Button
                        className="submit-btn m-3"
                        disabled={isLoading2}
                        type="submit"
                      >
                        {isLoading2 ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : null}

                        {isLoading2 ? t('Global_Loading') : t('Global_Register')}
                      </Button>
                    </div>
                  </Form>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Register;
