import React from "react";
import "./contact.scss";
import { useTranslation } from "react-i18next";

function ContactUs() {
  const { t } = useTranslation();
  return (
    <div className="contact-main">
      <div className="contact-module container">
        <div className="text-contact">
          <h2>{t('ContactUs_email_title')}</h2>
          <p>{t('ContactUs_email')}</p>
          <p>{t('ContactUs_faq_title')}</p>
          {t('ContactUs_faq')}
          <p>{t('ContactUs_ticket_title')}</p>
          {t('ContactUs_ticket')}
        </div>
        <div className="icons-main">
          <div>
            <h2 className="head">{t('ContactUs_social_title')}</h2>
          </div>
          <div className="icons">
            <a
              href="https://t.me/easyexchangepro"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-telegram "></i>&nbsp; Telegram
            </a>
            <a
              href="https://www.facebook.com/Easy-Exchange-Pro-108867761704428"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-facebook "></i>&nbsp; Facebook
            </a>
            <a
              href="https://www.youtube.com/channel/UCzBm-lKfNO-v4ZxgRQvYfYg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-youtube "></i>&nbsp; Youtube
            </a>
            <a
              href="https://twitter.com/EasyExchangePro"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-twitter "></i>&nbsp; Twitter
            </a>
            <a
              href="https://instagram.com/EasyExchangePro"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-instagram "></i>&nbsp; Instagram
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
