import React from "react"; 
import "./mobileinstant.scss";
import { useParams } from "react-router-dom";
import MiniChart from "./MiniChart";

function MobileTradeChart() {
  const params = useParams();

  return (
    <div className="chart-main">
      <div className="chart">
        <MiniChart
           
        />
      </div>
    </div>
  );
}

export default MobileTradeChart;
