import React from "react";
import WhereBotImage from "../../../assets/images/wemade.png";
function Botimagepart() {
  return (
    <div className="sec-global">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="pt-5">
              <img src={WhereBotImage} alt="" className="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Botimagepart;
