import React, { useEffect, useState } from "react";

import Wallets from "./Wallets";
import "./assets.scss";
import HeaderMobile from "../home/HeaderMobile";
import BottomNavigationMobile from "../home/BottomNavigationMobile";
function Assets() {

  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);

  if (pageWidth > 768) {
    return (
      <div className="assets-main">
        <div className="container wallets">
          <Wallets />
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="container-fluid assets-main-mobile">
          <div className="row">
            <div className="col-12" style={{ marginBottom: 70 }}>
              <Wallets />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Assets;
