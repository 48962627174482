import { Star, StarBorder } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { UserContext } from "../../../../contexts/providers/user";

function EachPair({ pair }) {
  const { state, dispatch } = useContext(UserContext);
  const [blink, setBlink] = useState(false);

  useEffect(() => {
    setBlink(true);
    setTimeout(() => {
      setBlink(false);
    }, 1000);
  }, [pair.price]);

  const favBotton = (crypto_pair) => {
    const favToggle = (action) => {
      return () => {
        dispatch({
          type: "pair:fav:set",
          currency: crypto_pair,
          action: action,
        });
      };
    };

    if (state.pair_fav.includes(crypto_pair)) {
      return (
        <Star
          sx={{ fontSize: 40 }}
          className={"star-icon " + (blink === true ? "blink" : "")}
          onClick={favToggle("remove")}
        />
      );
    }
    return (
      <StarBorder
        sx={{ fontSize: 40 }}
        className={"star-icon " + (blink === true ? "blink" : "")}
        onClick={favToggle("add")}
      />
    );
  };
  const colorInTrade = () => {
    if (pair?.clr === "text-danger") {
      return "textdanger";
    } else if (pair?.clr === "text-success") {
      return "textsuccess";
    }
  };
  return (
    <div className="row">
      <div className={"col d-flex justify-content-between "}>
        <div className={`order-price-in-orderlist ${colorInTrade()} d-flex`}>
          {favBotton(pair?.pair)}
          <NavLink
            as={Link}
            to={`/trade/${pair.pair}`}
            className={"nav-link " + (blink === true ? "blink" : "")}
          >
            {pair?.pair.replace("_", "/")}
          </NavLink>
        </div>
        <div
          className={
            "order-amount-in-orderlist " + (blink === true ? "blink" : "")
          }
        >
          {pair?.price}
        </div>
        <div className="order-total-in-orderlist">
          {pair?.volume.toFixed(2)}
        </div>
      </div>
    </div>
  );
}

export default EachPair;
