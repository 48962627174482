import React from "react";
import "./wheretobuy.scss";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import { usePlacesWidget } from "react-google-autocomplete";

function WHtop() {
  // const { ref } = usePlacesWidget({
  //   onPlaceSelected: (place) => {},
  // });
  const { t } = useTranslation();
  return (
    <div className="where-to-buy-top container-fluid">
      <div className="container">
        <div className="row justify-content-start">
          <div className="col col-md-6 findbox">
            <h2 className="pt-4 ps-3 title-find">{t('EC_WT_Title')}</h2>
            <p className="content-find">
            {t('EC_WT_Description')}
            </p>
            <p className="content-find mt-5">{t('EC_WT_Find_Title')}</p>
            <InputGroup>
              <Form.Control type="text" placeholder={t('EC_WT_Find_input')} />
              <Button className="p-3 find-btn" id="">
              {t('EC_WT_Find_btn')}
              </Button>
            </InputGroup>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WHtop;
