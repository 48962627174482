import React from "react";
import EGImage from "../../../assets/images/handshake-icon.svg";
import "./easyguarantee.scss";
import { useTranslation } from "react-i18next";

function EGTop() {
  const { t } = useTranslation();
  return (
    <div className="container-fluid">
      <div className="row section-top-guarantee">
        <div className="col-md-6 top-title">
          <h1 className="h1-title-top">{t('EG_EGTop_title')}</h1>
          <p className="p-title-top">
          {t('EG_EGTop_Description_1')} <br /> {t('EG_EGTop_Description_2')}
          </p>
        </div>
        <div className="col-md-5">
          <img className="img-fluid" src={EGImage} alt="" />
        </div>
      </div>
    </div>
  );
}

export default EGTop;
