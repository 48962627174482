import { React, useState, useContext, useEffect } from "react";
import "./login.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ItemNames, getStorage, isLoggedIn, setStorage } from "../../utils/RSS";
import { UserContext } from "../../contexts/providers/user";
import { InputGroup, Modal, ModalFooter, Spinner } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { IconButton } from "@mui/material";
import { GridVisibilityOffIcon } from "@mui/x-data-grid";
import { VisibilityOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../home/HeaderMobile";
import BottomNavigationMobile from "../home/BottomNavigationMobile";

const SITE_KEY = "6LfRKdIlAAAAAOzdlD2OqlHquQWlhFt389XGNsvE";

export default function Login() {
  const [validated, setValidated] = useState(false);
  const { dispatch } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () { }
    );
  }, []);

  //////////////////// login function

  const handleLogin = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    const form = event.currentTarget;
    const loginInformation = new FormData(event.currentTarget);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "/" })
        .then(async (token) => {
          if (form.checkValidity() === false) {
            setValidated(true);
            setIsLoading(false);
          } else {
            /////////////////// fetch data
            try {
              let res = await fetch(
                "https://api.easyexchangepro.com/api/v2/web/login",
                {
                  method: "POST",
                  body: JSON.stringify({
                    email: loginInformation.get("email"),
                    password: loginInformation.get("password"),
                    recaptcha: token,
                  }),
                  headers: { "Content-Type": "application/json" },
                }
              );

              let resJson = await res.json();

              /////////////////// login result

              if (resJson.status === 4) {
                dispatch({
                  type: "open_snack",
                  snackColor: "success",
                  snackMessage: resJson.msg,
                });
                setMailOTP(true);
                setStorage(ItemNames.TTOKEN, resJson.ttoken);
                setIsLoading(false);
              } else if (resJson.status === 2) {
                dispatch({
                  type: "open_snack",
                  snackColor: "success",
                  snackMessage: resJson.msg,
                });
                setGOTP(true);
                setStorage(ItemNames.TTOKEN, resJson.ttoken);
                setIsLoading(false);
              } else {
                dispatch({
                  snackColor: "warning",
                  snackMessage: resJson.msg,
                  type: "open_snack",
                });
                setIsLoading(false);
              }
            } catch (err) {
              console.log(err);
              setIsLoading(false);
            }
          }
        });
    });
  };

  /////////////////////email tfa

  const onSubmitMailOTP = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    event.stopPropagation();
    const mailOTP = new FormData(event.currentTarget);
    try {
      let res = await fetch("https://api.easyexchangepro.com/api/v2/tfalogin", {
        method: "POST",
        body: JSON.stringify({
          verify_code: mailOTP.get("mailotp"),
          ttoken: getStorage(ItemNames.TTOKEN),
        }),
        headers: { "Content-Type": "application/json" },
      });

      let resJson = await res.json();

      ///////////////////   result

      if (resJson.status === 0) {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.msg,
        });
        setIsLoading(false);
      } else if (resJson.status === 1) {
        dispatch({
          type: "open_snack",
          snackColor: "success",
          snackMessage: resJson.msg,
        });

        setStorage(ItemNames.TOKEN, resJson.token);
        isLoggedIn();
        setIsLoading(false);
        setMailOTP(false);
        navigate("/", { replace: true });
      } else {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.msg,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const onSubmitWith2FA = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    const mailOTP = new FormData(event.currentTarget);
    try {
      let res = await fetch("https://api.easyexchangepro.com/api/v2/tfalogin", {
        method: "POST",
        body: JSON.stringify({
          verify_code: mailOTP.get("mailotp"),
          tfa_code: mailOTP.get("gotp"),
          ttoken: getStorage(ItemNames.TTOKEN),
        }),
        headers: { "Content-Type": "application/json" },
      });

      let resJson = await res.json();

      ///////////////////   result

      if (resJson.status === 0) {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.msg,
        });
        setIsLoading(false);
      } else if (resJson.status === 1) {
        dispatch({
          type: "open_snack",
          snackColor: "success",
          snackMessage: resJson.msg,
        });
        setIsLoading(false);
        setStorage(ItemNames.TOKEN, resJson.token);
        isLoggedIn();
        dispatch({ type: "login" });
        navigate("/", { replace: true });
      } else {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.msg,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const [showForgot, setShowForgot] = useState(false);
  const handleCloseForgot = () => setShowForgot(false);
  const handleShowForgot = () => setShowForgot(true);

  const [showResetEmail, setShowResetEmail] = useState(false);
  const handleCloseResetEmail = () => setShowResetEmail(false);
  const handleShowResetEmail = () => setShowResetEmail(true);

  const [showReset2FA, setShowReset2FA] = useState(false);
  const handleCloseReset2FA = () => setShowReset2FA(false);

  const [mail, setMail] = useState(null);

  const [isLoading, setIsLoading] = useState();

  const [showMailOTP, setMailOTP] = useState(false);
  const handleCloseMailOTP = () => setMailOTP(false);

  const [showGOTP, setGOTP] = useState(false);
  const handleCloseGOTP = () => setGOTP(false);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const [showIcon, setShowIcon] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setShowIcon(!showIcon);
  };

  const reset2FA = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    setShowForgot(false);
    try {
      let res = await fetch(
        "https://api.easyexchangepro.com/api/v2/forgot/2fa",
        {
          body: JSON.stringify({
            email: mail,
          }),
          method: "POST",
          headers: { "Content-Type": "application/json" },
        }
      );

      let resJson = await res.json();
      if (resJson.status === 1) {
        dispatch({
          type: "open_snack",
          snackColor: "success",
          snackMessage: resJson.msg,
        });
        setIsLoading(false);
        setShowReset2FA(false);
        setMail("");
      } else {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.msg,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const forgotPassword = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowForgot(false);
    setIsLoading(true);
    try {
      let res = await fetch(
        "https://api.easyexchangepro.com/api/v2/forgot/password",
        {
          body: JSON.stringify({
            email: mail,
          }),
          method: "POST",
          headers: { "Content-Type": "application/json" },
        }
      );

      let resJson = await res.json();
      if (resJson.status === 1) {
        dispatch({
          type: "open_snack",
          snackColor: "success",
          snackMessage: resJson.msg,
        });
        setIsLoading(false);
        setShowResetEmail(false);
        setMail("");
      } else {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.msg,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const checkValidation = (event, callback) => {
    if (mail === null || mail === "") {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: t('Global_Email_Error'),
      });
    } else {
      callback(event);
    }
  };
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);
  ///////////// UI
  const { t } = useTranslation();
  if (pageWidth > 768) {
    return (
      <div className="login-main">
        <div className="login-module">
          <div className="row">
            <div className="col text-center m-5">
              <h4 className="login-title">{t('Login_title')}</h4>
              <h5 className="login-title mb-5">{t('Login_subtitle')}</h5>
              <div
                className="g-recaptcha"
                data-sitekey="6LfRKdIlAAAAAOzdlD2OqlHquQWlhFt389XGNsvE"
                data-size="invisible"
              ></div>
              <Form noValidate validated={validated} onSubmit={handleLogin}>
                <Form.Group md="6" controlId="emailvalidation" className="mb-3">
                  <InputGroup className="input-login">
                    <Form.Control
                      type="email"
                      placeholder={t('Global_Email')}
                      required
                      name="email"
                      className="input-login"
                    />
                    <Form.Control.Feedback type="invalid">
                      {t('Global_Email_Error')}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group md="6" controlId="passwordvalidation">
                  <InputGroup className="input-login">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder={t('Global_Password')}
                      required
                      name="password"
                      onChange={handlePasswordChange}
                      className="input-login"
                    />
                    <IconButton
                      aria-label="show-eye"
                      onClick={togglePasswordVisibility}
                    >
                      {showIcon ? (
                        <GridVisibilityOffIcon />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                    <Form.Control.Feedback type="invalid">
                      {t('Global_Password_Error')}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Button className="login-btn" type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}
                  {isLoading ? t('Global_Loading') : t('Login_btn_Loading')}
                </Button>
              </Form>
            </div>
            <div className="d-flex justify-content-between">
              <div className="forgot-pass">
                <h6>
                  {t('Login_problem')}{" "}
                  <Button
                    variant="outline"
                    onClick={handleShowForgot}
                    className="forgot-btn"
                  >
                    {t('Login_click_here')}
                  </Button>
                </h6>
              </div>
              <div className="forgot-pass">
                <NavLink as={Link} to="/register" className="register-btn">
                  <h6 className="pt-2">{t('Login_register')}</h6>
                </NavLink>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p>
                  {t('Login_protected')}
                  <a href="https://policies.google.com/privacy">
                    {" "}{t('Login_privacy')}
                    {" "}
                  </a>
                  {t('Login_and')}
                  <a href="https://policies.google.com/terms">
                    {" "}{t('Login_terms')}
                    {" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* open reset links */}

        <Modal
          show={showForgot}
          onHide={handleCloseForgot}
          className="modal-withdraw"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t('Login_ResetModal_title')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-list"></Modal.Body>
          <ModalFooter>
            <Button className="confirm-withdraw" onClick={setShowReset2FA}>
              {t('Login_ResetModal_Reset2FA_btn')}
            </Button>
            <Button className="cancel-withdraw" onClick={handleShowResetEmail}>
              {t('Login_ResetModal_ForgetPassword')}
            </Button>
          </ModalFooter>
        </Modal>

        {/* open form for reset password */}

        <Modal
          show={showResetEmail}
          onHide={handleCloseForgot}
          className="modal-withdraw"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t('Login_ResetPassword_title')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-list">
            <Form>
              <FormControl fullWidth>
                <TextField
                  className="withdraw-texts"
                  InputProps={{ sx: { height: 57 } }}
                  id="mail"
                  name="mail"
                  label={t('Global_Email')}
                  size="medium"
                  required
                  onChange={(event) => setMail(event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                    className: "withdraw-texts",
                  }}
                />
              </FormControl>
            </Form>
          </Modal.Body>
          <ModalFooter>
            <Button className="cancel-withdraw" onClick={handleCloseResetEmail}>
              {t('Global_Cancel')}
            </Button>
            <Button
              className="confirm-withdraw"
              onClick={(event) => checkValidation(event, forgotPassword)}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              {isLoading ? t('Global_Loading') : t('Global_Submit')}
            </Button>
          </ModalFooter>
        </Modal>

        {/* open reset 2fa */}
        <Modal
          show={showReset2FA}
          onHide={handleCloseReset2FA}
          className="modal-withdraw"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('Login_Reset2FA_title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-list">
            <Form>
              <FormControl fullWidth>
                <TextField
                  className="withdraw-texts"
                  InputProps={{ sx: { height: 57 } }}
                  id="mail"
                  name="mail"
                  label={t('Global_Email')}
                  size="medium"
                  required
                  onChange={(event) => setMail(event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                    className: "withdraw-texts",
                  }}
                />
              </FormControl>
            </Form>
          </Modal.Body>
          <ModalFooter>
            <Button className="cancel-withdraw" onClick={handleCloseReset2FA}>
              {t('Global_Cancel')}
            </Button>
            <Button
              className="confirm-withdraw"
              onClick={(event) => checkValidation(event, reset2FA)}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              {isLoading ? t('Global_Loading') : t('Global_Submit')}
            </Button>
          </ModalFooter>
        </Modal>

        {/* open login just mail  */}

        <Modal show={showMailOTP} onHide={handleCloseMailOTP} backdrop="static">
          <Modal.Header>
            <Modal.Title>{t('Login_OTP_title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={onSubmitMailOTP}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="number"
                  placeholder={t('Global_Email') + ' ' + t('Global_OTP')}
                  autoFocus
                  name="mailotp"
                  required
                />
              </Form.Group>

              <div className="row justify-content-around text-center">
                <div className="col">
                  <Button variant="secondary" onClick={handleCloseMailOTP}>
                    {t('Global_Close')}
                  </Button>
                </div>
                <div className="col">
                  <Button variant="primary" type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {isLoading ? t('Global_Loading') : t('Global_Submit')}
                  </Button>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* open login 2fa and mail */}

        <Modal show={showGOTP} onHide={handleCloseGOTP} backdrop="static">
          <Modal.Header>
            <Modal.Title>{t('Login_OTP_title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={onSubmitWith2FA}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="number"
                  placeholder={t('Global_Email') + ' ' + t('Global_OTP')}
                  autoFocus
                  name="mailotp"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="number"
                  placeholder={t('Global_2FA') + ' ' + t('Global_OTP')}
                  autoFocus
                  name="gotp"
                  required
                />
              </Form.Group>
              <div className="row justify-content-around text-center">
                <div className="col">
                  <Button variant="secondary" onClick={handleCloseGOTP}>
                    {t('Global_Close')}
                  </Button>
                </div>
                <div className="col">
                  <Button variant="primary" type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {isLoading ? t('Global_Loading') : t('Global_Submit')}
                  </Button>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="login-main mt-4">
          <div className="login-module">
            <div className="row">
              <div className="col text-center m-5">
                <h4 className="login-title">{t('Login_title')}</h4>
                <h5 className="login-title mb-5">{t('Login_subtitle')}</h5>
                <div
                  className="g-recaptcha"
                  data-sitekey="6LfRKdIlAAAAAOzdlD2OqlHquQWlhFt389XGNsvE"
                  data-size="invisible"
                ></div>
                <Form noValidate validated={validated} onSubmit={handleLogin}>
                  <Form.Group md="6" controlId="emailvalidation" className="mb-3">
                    <InputGroup className="input-login">
                      <Form.Control
                        type="email"
                        placeholder={t('Global_Email')}
                        required
                        name="email"
                        className="input-login"
                      />
                      <Form.Control.Feedback type="invalid">
                        {t('Global_Email_Error')}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group md="6" controlId="passwordvalidation">
                    <InputGroup className="input-login">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder={t('Global_Password')}
                        required
                        name="password"
                        onChange={handlePasswordChange}
                        className="input-login"
                      />
                      <IconButton
                        aria-label="show-eye"
                        onClick={togglePasswordVisibility}
                      >
                        {showIcon ? (
                          <GridVisibilityOffIcon />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </IconButton>
                      <Form.Control.Feedback type="invalid">
                        {t('Global_Password_Error')}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Button className="login-btn" type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {isLoading ? t('Global_Loading') : t('Login_btn_Loading')}
                  </Button>
                </Form>
              </div>
              <div className="d-flex justify-content-between">
                <div className="forgot-pass">
                  <h6>
                    {t('Login_problem')}{" "}
                    <Button
                      variant="outline"
                      onClick={handleShowForgot}
                      className="forgot-btn"
                    >
                      {t('Login_click_here')}
                    </Button>
                  </h6>
                </div>
                <div className="forgot-pass">
                  <NavLink as={Link} to="/register" className="register-btn">
                    <h6 className="pt-2">{t('Login_register')}</h6>
                  </NavLink>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p>
                    {t('Login_protected')}
                    <a href="https://policies.google.com/privacy">
                      {" "}{t('Login_privacy')}
                      {" "}
                    </a>
                    {t('Login_and')}
                    <a href="https://policies.google.com/terms">
                      {" "}{t('Login_terms')}
                      {" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* open reset links */}

          <Modal
            show={showForgot}
            onHide={handleCloseForgot}
            className="modal-withdraw"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {t('Login_ResetModal_title')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-list"></Modal.Body>
            <ModalFooter>
              <Button className="confirm-withdraw" onClick={setShowReset2FA}>
                {t('Login_ResetModal_Reset2FA_btn')}
              </Button>
              <Button className="cancel-withdraw" onClick={handleShowResetEmail}>
                {t('Login_ResetModal_ForgetPassword')}
              </Button>
            </ModalFooter>
          </Modal>

          {/* open form for reset password */}

          <Modal
            show={showResetEmail}
            onHide={handleCloseForgot}
            className="modal-withdraw"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {t('Login_ResetPassword_title')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-list">
              <Form>
                <FormControl fullWidth>
                  <TextField
                    className="withdraw-texts"
                    InputProps={{ sx: { height: 57 } }}
                    id="mail"
                    name="mail"
                    label={t('Global_Email')}
                    size="medium"
                    required
                    onChange={(event) => setMail(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                      className: "withdraw-texts",
                    }}
                  />
                </FormControl>
              </Form>
            </Modal.Body>
            <ModalFooter>
              <Button className="cancel-withdraw" onClick={handleCloseResetEmail}>
                {t('Global_Cancel')}
              </Button>
              <Button
                className="confirm-withdraw"
                onClick={(event) => checkValidation(event, forgotPassword)}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                {isLoading ? t('Global_Loading') : t('Global_Submit')}
              </Button>
            </ModalFooter>
          </Modal>

          {/* open reset 2fa */}
          <Modal
            show={showReset2FA}
            onHide={handleCloseReset2FA}
            className="modal-withdraw"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t('Login_Reset2FA_title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-list">
              <Form>
                <FormControl fullWidth>
                  <TextField
                    className="withdraw-texts"
                    InputProps={{ sx: { height: 57 } }}
                    id="mail"
                    name="mail"
                    label={t('Global_Email')}
                    size="medium"
                    required
                    onChange={(event) => setMail(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                      className: "withdraw-texts",
                    }}
                  />
                </FormControl>
              </Form>
            </Modal.Body>
            <ModalFooter>
              <Button className="cancel-withdraw" onClick={handleCloseReset2FA}>
                {t('Global_Cancel')}
              </Button>
              <Button
                className="confirm-withdraw"
                onClick={(event) => checkValidation(event, reset2FA)}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                {isLoading ? t('Global_Loading') : t('Global_Submit')}
              </Button>
            </ModalFooter>
          </Modal>

          {/* open login just mail  */}

          <Modal show={showMailOTP} onHide={handleCloseMailOTP} backdrop="static">
            <Modal.Header>
              <Modal.Title>{t('Login_OTP_title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={onSubmitMailOTP}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    placeholder={t('Global_Email') + ' ' + t('Global_OTP')}
                    autoFocus
                    name="mailotp"
                    required
                  />
                </Form.Group>

                <div className="row justify-content-around text-center">
                  <div className="col">
                    <Button variant="secondary" onClick={handleCloseMailOTP}>
                      {t('Global_Close')}
                    </Button>
                  </div>
                  <div className="col">
                    <Button variant="primary" type="submit" disabled={isLoading}>
                      {isLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}
                      {isLoading ? t('Global_Loading') : t('Global_Submit')}
                    </Button>
                  </div>
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          {/* open login 2fa and mail */}

          <Modal show={showGOTP} onHide={handleCloseGOTP} backdrop="static">
            <Modal.Header>
              <Modal.Title>{t('Login_OTP_title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={onSubmitWith2FA}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    placeholder={t('Global_Email') + ' ' + t('Global_OTP')}
                    autoFocus
                    name="mailotp"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    placeholder={t('Global_2FA') + ' ' + t('Global_OTP')}
                    autoFocus
                    name="gotp"
                    required
                  />
                </Form.Group>
                <div className="row justify-content-around text-center">
                  <div className="col">
                    <Button variant="secondary" onClick={handleCloseGOTP}>
                      {t('Global_Close')}
                    </Button>
                  </div>
                  <div className="col">
                    <Button variant="primary" type="submit" disabled={isLoading}>
                      {isLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}
                      {isLoading ? t('Global_Loading') : t('Global_Submit')}
                    </Button>
                  </div>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
}
