import React, { useEffect, useState } from "react";

import ATopvideo from "./ATopvideo";
import ASecond from "./ASecond";
import ARoadmap from "./ARoadmap";
import HeaderMobile from "../home/HeaderMobile";
import BottomNavigationMobile from "../home/BottomNavigationMobile";
function Aboutus() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);
  if (pageWidth > 768) {
    return (
      <div className="about-main">
        <ATopvideo />
        <ASecond />
        <ARoadmap />
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="about-main">
          <ATopvideo />
          <ASecond />
          <ARoadmap />
        </div>
      </>
    );
  }
}

export default Aboutus;
