import React from "react";
import "../footer/footer.scss";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YoutubeIcon from "@mui/icons-material/YouTube";
import Accordion from "react-bootstrap/Accordion";
import { Link, NavLink, useLocation } from "react-router-dom";

function Footer() {
  const { pathname } = useLocation();

  const regex = new RegExp("/trade/.*");
  const regex2 = new RegExp("/chart/.*");

  if (regex.test(pathname) || regex2.test(pathname)) return null;
  return (
    <div className="footer-main container-fluid">
      <div className="row d-none d-xl-flex">
        <div className="col-12 col-md-2 logoherefooter">
          {/* <img src={Logo} width={180} alt="logo" className="mt-4" /> */}
        </div>
        <div className="col-12 col-md-2 mt-4">
          <h3>About Us</h3>
          <ul>
            <li>
              <NavLink as={Link} to="/about" className="nav-link-footer">
                About
              </NavLink>
            </li>
            {/* <li>
              <NavLink as={Link} to="/announcement" className="nav-link-footer">
                Announcement
              </NavLink>
            </li> */}
          </ul>
        </div>
        <div className="col-12 col-md-2 mt-4">
          <h3>Products</h3>
          <ul>
            <li>
              <NavLink
                as={Link}
                to="/alreadypurchased"
                className="nav-link-footer"
              >
                Easy Card
              </NavLink>
            </li>
            <li>
              <NavLink
                as={Link}
                to="/easyguarantee"
                className="nav-link-footer"
              >
                Easy Guarantee
              </NavLink>
            </li>
            <li>
              <NavLink
                as={Link}
                to="/trade/BTC_USDT"
                className="nav-link-footer"
              >
                Trade
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/convert" className="nav-link-footer">
                Convert
              </NavLink>
            </li>

            {/* <li>
              <NavLink as={Link} to="/gabur" className="nav-link-footer">
                Gabur
              </NavLink>
            </li> */}
          </ul>
        </div>
        <div className="col-12 col-md-2 mt-4">
          <h3>Services</h3>
          <ul>
            <li>
              <NavLink as={Link} to="/fee" className="nav-link-footer">
                Fees
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/tokenlisting" className="nav-link-footer">
                Token Listing
              </NavLink>
            </li>
            <li>
              <NavLink
                as={Link}
                to="/brokerregister"
                className="nav-link-footer"
              >
                Broker Register
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/coininfo" className="nav-link-footer">
                Coin Info
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="col-12 col-md-2 mt-4">
          <h3>Legal & Support</h3>
          <ul>
            <li>
              <NavLink as={Link} to="/termsofuse" className="nav-link-footer">
                Terms of Use
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/privacy" className="nav-link-footer">
                Privacy Policy
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/faq" className="nav-link-footer">
                FAQ
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/contact" className="nav-link-footer">
                Contact Us
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/api" className="nav-link-footer">
                API Documentation
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="col-12 col-md-2 mt-4">
          <h3>Official Media</h3>
          <ul>
            <a
              href="https://t.me/easyexchangepro"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TelegramIcon />
            </a>
            <a
              href="https://www.instagram.com/easyexchangeproofficial/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.facebook.com/Easy-Exchange-Pro-108867761704428"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://twitter.com/EasyExchangePro"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </a>
            <a
              href="https://www.youtube.com/@easyexchangepro"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YoutubeIcon />
            </a>
            {/* <a
              href="https://coinmarketcap.com/currencies/gabur/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <img
                  src="https://coinmarketcap.com/favicon.ico"
                  width="34"
                  height="34"
                  className="p-2"
                  alt=""
                />{" "}
              </span>
            </a> */}
            {/* <a
              href="https://www.coingecko.com/en/coins/gabur"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <img
                  src="https://www.coingecko.com/favicon.ico"
                  width="34"
                  height="34"
                  className="p-2"
                  alt=""
                />{" "}
              </span>
            </a> */}
          </ul>
          <NavLink as={Link} to="/tradingview" className="trading-footer">
            Trading View
          </NavLink>
        </div>
      </div>
      <div className="footer-accordion container">
        <div className="row d-block d-xl-none footer-mobile">
          <div className="logofootermobile">
            {/* <img src={Logo} width={150} height={120} alt="logo" /> */}
          </div>
          <Accordion flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>About Us</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <NavLink as={Link} to="/about" className="nav-link-footer">
                      About
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      as={Link}
                      to="/announcement"
                      className="nav-link-footer"
                    >
                      Announcements
                    </NavLink>
                  </li> */}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Products</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <NavLink
                      as={Link}
                      to="/alreadypurchased"
                      className="nav-link-footer"
                    >
                      Easy Card
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={Link}
                      to="/easyguarantee"
                      className="nav-link-footer"
                    >
                      Easy Guarantee
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={Link}
                      to="/trade/BTC_USDT"
                      className="nav-link-footer"
                    >
                      Trade
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={Link}
                      to="/convert"
                      className="nav-link-footer"
                    >
                      Convert
                    </NavLink>
                  </li>

                  {/* <li>
                    <NavLink as={Link} to="/gabur" className="nav-link-footer">
                      Gabur
                    </NavLink>
                  </li> */}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Services</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <NavLink as={Link} to="/fee" className="nav-link-footer">
                      Fees
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={Link}
                      to="/tokenlisting"
                      className="nav-link-footer"
                    >
                      Token Listing
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={Link}
                      to="/brokerregister"
                      className="nav-link-footer"
                    >
                      Broker Register
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={Link}
                      to="/coininfo"
                      className="nav-link-footer"
                    >
                      Coin Info
                    </NavLink>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Legal & Support</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <NavLink
                      as={Link}
                      to="/termsofuse"
                      className="nav-link-footer"
                    >
                      Terms of Use
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={Link}
                      to="/privacy"
                      className="nav-link-footer"
                    >
                      Privacy Policy
                    </NavLink>
                  </li>
                  <li>
                    <NavLink as={Link} to="/faq" className="nav-link-footer">
                      FAQ
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={Link}
                      to="/contact"
                      className="nav-link-footer"
                    >
                      Contact Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink as={Link} to="/api" className="nav-link-footer">
                      API Documentation
                    </NavLink>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Official Media</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <a
                    href="https://t.me/easyexchangepro"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TelegramIcon />
                  </a>
                  <a
                    href="https://www.instagram.com/easyexchangeproofficial/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon />
                  </a>
                  <a
                    href="https://www.facebook.com/Easy-Exchange-Pro-108867761704428"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    href="https://twitter.com/EasyExchangePro"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterIcon />
                  </a>
                  <a
                    href="https://www.youtube.com/@easyexchangepro"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <YoutubeIcon />
                  </a>
                  {/* <a
                    href="https://coinmarketcap.com/currencies/gabur/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <img
                        src="https://coinmarketcap.com/favicon.ico"
                        width="34"
                        height="34"
                        className="p-2"
                        alt=""
                      />{" "}
                    </span>
                  </a>
                  <a
                    href="https://www.coingecko.com/en/coins/gabur"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <img
                        src="https://www.coingecko.com/favicon.ico"
                        width="34"
                        height="34"
                        className="p-2"
                        alt=""
                      />{" "}
                    </span>
                  </a> */}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Footer;
