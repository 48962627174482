import React, { useState } from "react";

import Form from "react-bootstrap/Form";
import "./countrylist.scss";

import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";

function Countrylist({ onChange, selected }) {
  var countries = [
    "Armenia",
    "Azerbaijan",
    "Bahrain",
    "Bangladesh",
    "China",
    "Cyprus",
    "Djibouti",
    "Egypt",
    "India",
    "Iraq",
    "Jordan",
    "Kazakhstan",
    "Kuwait",
    "Kyrgyzstan",
    "Libya",
    "Malta",
    "Mauritania",
    "Oman",
    "Qatar",
    "Saudi Arabia",
    "Tajikistan",
    "the Comoros Islands",
    "the United Arab Emirates",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Uzbekistan",
  ];
  const [singleSelections, setSingleSelections] = useState([]);

  return (
    <>
      <Form.Group>
        <Typeahead
          id="basic-typeahead-single"
          labelKey="name"
          onChange={onChange}
          options={countries}
          placeholder="Select your country"
          selected={selected}
          clearButton={true}
        />
      </Form.Group>
    </>
  );
}

export default Countrylist;
