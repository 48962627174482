import "../home/home.scss";
import Marketinhome from "./Marketinhome";
import Guarantee from "./Guarantee";
import HomeTop from "./HomeTop";
import EasyCard from "./EasyCard";
import MobileSection from "./MobileSection";
import Trademarks from "./Trademarks";
import { useContext, useEffect, useState } from "react";
import HeaderMobile from "./HeaderMobile";
import ImageSliderMobile from "./ImagesliderMobile";
import { Button } from "react-bootstrap";
import PriceSliderMobile from "./PriceSliderMobile";
import BottomNavigationMobile from "./BottomNavigationMobile";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../../utils/RSS";
import { UserContext } from "../../contexts/providers/user";
import { useTranslation } from "react-i18next";

function home() {
  const navigate = useNavigate()
  const { state, dispatch } = useContext(UserContext);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };
  const { t } = useTranslation();
  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);
  const goToPage = (path) => {
    navigate(path)
  }

  if (pageWidth > 768) {
    return (
      <div className="home-page">
        <div className="home-container">
          <div className="home-body container-fluid">
            <HomeTop />
          </div>
          <div className="market-home-page container mt-5">
            <Marketinhome />
          </div>
          <Guarantee />
          <EasyCard />
          <MobileSection />
          <Trademarks />
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <div className="home-main-mobile">
        <HeaderMobile />
        <div className="container" style={{ marginTop: 60 }}>
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <ImageSliderMobile />
            </div>
          </div>
        </div>
        <div className="container-fluid btn-places-mobile">
          <div className="row justify-content-around mt-2">
            <div className="col-4 text-center">
              <Button className="btns-home-mobile-places" onClick={() => goToPage('/deposit')}>
                <div className="row">
                  <div className="col-12 mt-1">
                    <svg id="_1_px" data-name="1 px" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" className="svg-icon">
                      <path class="cls-1" d="M288,448H53.3c-29.4,0-53.3-23.9-53.3-53.3V117.3c0-29.4,23.9-53.3,53.3-53.3h405.3c29.4,0,53.3,23.9,53.3,53.3v192c0,5.9-4.8,10.7-10.7,10.7s-10.7-4.8-10.7-10.7V117.3c0-17.6-14.4-32-32-32H53.3c-17.6,0-32,14.4-32,32v277.3c0,17.6,14.4,32,32,32h234.7c5.9,0,10.7,4.8,10.7,10.7s-4.8,10.7-10.7,10.7Z" />
                      <path class="cls-1" d="M160,341.3H10.7c-5.9,0-10.7-4.8-10.7-10.7v-149.3c0-5.9,4.8-10.7,10.7-10.7h149.3c29.4,0,53.3,23.9,53.3,53.3v64c0,29.5-23.9,53.4-53.3,53.4ZM21.3,320h138.7c17.6,0,32-14.4,32-32v-64c0-17.6-14.4-32-32-32H21.3v128Z" />
                      <path class="cls-1" d="M106.7,298.7c-23.6,0-42.7-19.2-42.7-42.7s19.1-42.7,42.7-42.7,42.7,19.1,42.7,42.7-19.2,42.7-42.7,42.7ZM106.7,234.7c-11.8,0-21.3,9.6-21.3,21.3s9.6,21.3,21.3,21.3,21.3-9.5,21.3-21.3-9.6-21.3-21.3-21.3Z" />
                      <path class="cls-2" d="M426.7,512h-64c-11.8,0-21.3-9.6-21.3-21.3v-106.7h-53.4c-4.3,0-8.2-2.6-9.9-6.6-1.7-4-.7-8.6,2.3-11.6l106.7-106.7c4.2-4.2,10.9-4.2,15.1,0l106.7,106.7c3.1,3.1,4,7.6,2.3,11.6-1.6,4-5.5,6.6-9.8,6.6h-53.4v106.7c0,11.7-9.6,21.3-21.3,21.3ZM313.7,362.7h38.3c5.9,0,10.7,4.8,10.7,10.7v117.3h64v-117.4c0-5.9,4.8-10.7,10.7-10.7h38.3l-80.9-80.9-81.1,81Z" />
                    </svg>
                  </div>
                  <div className="col-12 mt-1">
                    <p>{t('Global_Deposit')}</p>
                  </div>
                </div>
              </Button>
            </div>

            <div className="col-4 text-center">
              <Button className="btns-home-mobile-places" onClick={() => goToPage('/convert')}>
                <div className="row">
                  <div className="col-12 mt-1">
                    <svg id="_1_px" data-name="1 px" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" className="svg-icon">
                      <path class="cls-1" d="M41.1,223.5c2.7,1.1,5.8,1.1,8.4-.2l83.3-37.9c5.3-2.6,7.6-9,5-14.3-2.5-5.1-8.5-7.5-13.8-5.1l-58.3,26.6c27.8-83.3,105.4-139,194.7-139s175.4,62.5,198.9,152c1.5,5.7,7.4,9.1,13,7.6,5.7-1.5,9.1-7.4,7.6-13-26-98.8-116.3-167.9-219.5-167.9S76.3,93.7,45.4,185.7l-24.5-61.3c-1.8-5.5-7.9-8.6-13.4-6.8-5.5,1.8-8.6,7.9-6.8,13.4.1.4.3.9.5,1.3l34,85.3c.9,2.6,3.1,4.8,5.9,5.9Z" />
                      <path class="cls-2" d="M511.8,413.1c-.1-.2-.2-.4-.2-.6l-34-85.3c-1.1-2.7-3.2-4.9-6-6-2.7-1.1-5.8-1-8.4.2l-83.4,37.8c-5.4,2.2-8,8.5-5.8,14s8.5,8,14,5.8c.2-.1.4-.2.6-.3l58.3-26.6c-28.1,83.3-105.7,139-194.9,139s-175.4-62.5-198.9-152c-1.5-5.7-7.4-9.1-13-7.6-5.7,1.5-9.1,7.4-7.6,13,25.9,98.9,116.3,167.9,219.5,167.9s184-61.4,215-153.4l24.5,61.3c2,5.5,8.1,8.4,13.7,6.4,5.7-2,8.6-8,6.6-13.6Z" />
                    </svg>
                  </div>
                  <div className="col-12 mt-1">
                    <p>{t('Global_Convert')}</p>
                  </div>
                </div>
              </Button>
            </div>

            <div className="col-4 text-center">
              <Button className="btns-home-mobile-places" onClick={() => goToPage('/otc')}>
                <div className="row">
                  <div className="col-12 mt-1">
                    <svg id="_1_px" data-name="1 px" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" className="svg-icon">
                      <g id="_26" data-name="26">
                        <path class="cls-2" d="M267.6,244.2c5.3,0,9.7-4.3,9.7-9.7v-30.4c0-18.3-9.5-34.8-24.9-42.9-8.6-4.6-32-15.6-38-18.4-3-2.8-6.7-6.3-7.9-8.8,14.1-10.8,23.4-29.2,23.4-50,0-33.1-23.6-60.1-52.6-60.1s-52.6,27-52.6,60.1,8.9,38.4,22.5,49.3c-1.8,2.9-4.5,6.5-7.5,9.7-6.6,3.1-29.2,13.7-37.6,18.2-15.3,8.2-24.9,24.6-24.9,42.9v30.4c0,5.3,4.3,9.7,9.7,9.7s9.7-4.3,9.7-9.7v-30.4c0-11.2,5.6-21.1,14.6-25.9,6.6-3.5,23.5-11.5,32.5-15.8,7.8,7,23,19.4,33.5,19.4s25.7-12.4,33.5-19.4c9,4.3,25.9,12.3,32.5,15.8,9,4.8,14.6,14.7,14.6,25.9v30.4c.2,5.4,4.5,9.7,9.8,9.7ZM194.9,150.7c-3,2.5-13.8,11.3-17.4,11.9-4.1-.6-15.3-9.7-18.4-12.4,1.8-2.3,3.7-5,5.3-7.8,3.5,1,8.7,1.9,12.9,1.9s8.1-.6,11.9-1.6c1.2,2.4,2.3,4.2,5.7,8h0ZM190.7,121.4c-8.4,4.7-18.5,4.6-27,0,0,0-.1,0-.1,0-11.5-6.4-19.6-20.6-19.6-37.1s14.9-40.8,33.3-40.8,33.3,18.3,33.3,40.8c0,16.7-8.2,31-19.9,37.3Z" />
                        <path class="cls-1" d="M439.5,260.3H72.5c-26.6,0-48.3,21.7-48.3,48.3v116.4c0,14.7,11.1,26.8,25.3,28.6v8c0,14.5,11.8,26.4,26.4,26.4h26.1c14.5,0,26.4-11.8,26.4-26.4v-7.6h14.6c5.3,0,9.7-4.3,9.7-9.7s-4.3-9.7-9.7-9.7-91.2,0-89.8,0c-5.3,0-9.7-4.3-9.7-9.7v-56h72.2c5.3,0,9.7-4.3,9.7-9.7s-4.3-9.7-9.7-9.7H43.5v-41.1c0-16,13-29,29-29h367.1c16,0,29,13,29,29v41.1h-72.2c-5.3,0-9.7,4.3-9.7,9.7s4.3,9.7,9.7,9.7h72.2v56c0,5.3-4.3,9.7-9.7,9.7h-89.8c-5.3,0-9.7,4.3-9.7,9.7s4.3,9.7,9.7,9.7h14.7v7.6c0,14.5,11.8,26.4,26.4,26.4h26.1c14.5,0,26.4-11.8,26.4-26.4v-8c14.2-1.8,25.3-13.9,25.3-28.6v-116.4c-.2-26.6-21.8-48.3-48.5-48.3ZM68.8,454h40.2v7.6c0,3.9-3.2,7-7,7h-26.2c-3.9,0-7-3.2-7-7v-7.6ZM443.2,461.6c0,3.9-3.2,7-7,7h-26.1c-3.9,0-7-3.2-7-7v-7.6h40.2v7.6h-.1Z" />
                        <path class="cls-1" d="M256,297.4c-52.6,0-95.3,42.7-95.3,95.2s42.8,95.3,95.3,95.3,95.3-42.8,95.3-95.3-42.7-95.2-95.3-95.2ZM256,468.7c-41.9,0-76-34.1-76-76s34.1-75.9,76-75.9,76,34.1,76,75.9-34.1,76-76,76Z" />
                        <path class="cls-1" d="M426.5,71.9l-47.7,47.7c-.5.5-1.4.5-1.9,0l-17.9-17.9c-3.8-3.8-9.9-3.8-13.7,0-3.8,3.8-3.8,9.9,0,13.7l17.9,17.9c4,4,9.3,6,14.6,6s10.6-2,14.6-6l47.7-47.7c3.8-3.8,3.8-9.9,0-13.7-3.8-3.7-9.9-3.7-13.6,0Z" />
                      </g>
                      <g id="_26-2" data-name="26">
                        <path class="cls-2" d="M271.6,351h-6.3v-5.8c0-5.3-4.3-9.7-9.7-9.7s-9.7,4.3-9.7,9.7v5.8c-1.2,0-22.7,0-21.7,0-5.3,0-9.7,4.3-9.7,9.7s4.3,9.7,9.7,9.7h5.6c0,1.6,0,50.4,0,47.3h-5.5c-5.3,0-9.7,4.3-9.7,9.7s4.3,9.7,9.7,9.7,22.8,0,21.7,0v5.8c0,5.3,4.3,9.7,9.7,9.7s9.7-4.3,9.7-9.7v-5.8h6.3c14.5,0,26.3-11.8,26.3-26.3s-2.4-12.1-6.1-16.7c3.8-4.6,6.1-10.3,6.1-16.7,0-14.6-11.9-26.4-26.4-26.4ZM271.6,417.7h-22.4v-14h22.4c3.9,0,7,3.1,7,7s-3.1,7-7,7ZM271.6,384.3h-22.4v-14h22.4c3.9,0,7,3.1,7,7s-3.1,7-7,7Z" />
                        <path class="cls-1" d="M457.1,25.3h-128.2c-17.1,0-31,13.9-31,31v87.4c0,17.2,14,31.1,31.1,31.1h13.6c2.4,0,4.4,2,4.4,4.4v24.4c0,7.8,5,14.7,12.5,17.1,7.5,2.4,15.5-.4,20-6.8l25.4-36.2c1.3-1.8,3.4-2.9,5.6-2.9h46.6c17.1,0,31-13.9,31-31V56.3c0-17.1-13.9-31-31-31ZM468.8,143.9c0,6.5-5.2,11.7-11.7,11.7h-46.6c-8.5,0-16.5,4.2-21.4,11.1l-22.8,32.4v-19.8c0-13.1-10.7-23.7-23.8-23.7h-13.6c-6.5,0-11.8-5.3-11.8-11.8V56.3c0-6.5,5.2-11.7,11.7-11.7h128.2c6.5,0,11.7,5.2,11.7,11.7v87.6h0Z" />
                      </g>
                    </svg>
                  </div>
                  <div className="col-12 mt-1">
                    <p>OTC</p>
                  </div>
                </div>
              </Button>
            </div>

          </div>
          <div className="row justify-content-around">
            <div className="col-4 text-center">
              <Button className="btns-home-mobile-places" onClick={() => goToPage('/withdraw')}>
                <div className="row">
                  <div className="col-12 mt-1">
                    <svg id="_1_px" data-name="1 px" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" className="svg-icon">
                      <path class="cls-1" d="M245.3,448H53.3c-29.4,0-53.3-23.9-53.3-53.3V117.3c0-29.4,23.9-53.3,53.3-53.3h405.3c29.4,0,53.3,23.9,53.3,53.3v213.3c0,5.9-4.8,10.7-10.7,10.7s-10.7-4.8-10.7-10.7V117.3c0-17.6-14.4-32-32-32H53.3c-17.6,0-32,14.4-32,32v277.3c0,17.6,14.4,32,32,32h192c5.9,0,10.7,4.8,10.7,10.7s-4.8,10.7-10.7,10.7Z" />
                      <path class="cls-1" d="M160,341.3H10.7c-5.9,0-10.7-4.8-10.7-10.7v-149.3c0-5.9,4.8-10.7,10.7-10.7h149.3c29.4,0,53.3,23.9,53.3,53.3v64c0,29.5-23.9,53.4-53.3,53.4ZM21.3,320h138.7c17.6,0,32-14.4,32-32v-64c0-17.6-14.4-32-32-32H21.3v128Z" />
                      <path class="cls-1" d="M106.7,298.7c-23.6,0-42.7-19.2-42.7-42.7s19.1-42.7,42.7-42.7,42.7,19.1,42.7,42.7-19.2,42.7-42.7,42.7ZM106.7,234.7c-11.8,0-21.3,9.6-21.3,21.3s9.6,21.3,21.3,21.3,21.3-9.5,21.3-21.3-9.6-21.3-21.3-21.3Z" />
                      <path class="cls-2" d="M394.8,510.1c-2.7,0-5.5-1-7.6-3.1l-106.7-106.7c-3.1-3.1-4-7.6-2.3-11.6s5.5-6.6,9.9-6.6h53.3v-106.7c0-11.8,9.6-21.3,21.3-21.3h64c11.8,0,21.3,9.6,21.3,21.3v106.7h53.3c4.3,0,8.2,2.6,9.9,6.6,1.6,4,.7,8.6-2.3,11.6l-106.6,106.7c-2.1,2.1-4.8,3.1-7.5,3.1ZM313.8,403.4l80.9,80.9,80.9-80.9h-38.3c-5.9,0-10.7-4.8-10.7-10.7v-117.3h-64v117.3c0,5.9-4.8,10.7-10.7,10.7h-38.1Z" />
                    </svg>
                  </div>
                  <div className="col-12 mt-1">
                    <p >{t('Global_Withdraw')}</p>
                  </div>
                </div>
              </Button>
            </div>

            <div className="col-4 text-center">
              <Button className="btns-home-mobile-places" onClick={() => goToPage('/alreadypurchased')}>
                <div className="row">
                  <div className="col-12 mt-1">
                    <svg id="_1_px" data-name="1 px" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" className="svg-icon">
                      <path class="cls-1" d="M509,214.3c6.1-16.1,2.7-35-10.2-47.9L345.6,13.2c-17.5-17.5-46.1-17.5-63.6,0L109.8,185.4H45c-24.8,0-45,20.2-45,45v236.6c0,24.8,20.2,45,45,45h422c24.8,0,45-20.2,45-45v-236.6c0-5.7-1.1-11.1-3-16.1h0ZM476.4,186.4c-3-.7-6.2-1-9.4-1h-32.5l20.5-20.5,21.4,21.5ZM433.7,143.7l-41.7,41.7h-42.4l62.9-62.9,21.2,21.2ZM303.2,34.4c2.9-2.9,6.8-4.4,10.6-4.4s7.7,1.5,10.6,4.4l66.9,66.9-84.1,84.1h-155L303.2,34.4ZM482,467c0,8.3-6.7,15-15,15H45c-8.3,0-15-6.7-15-15v-236.6c0-8.3,6.7-15,15-15h422c8.3,0,15,6.7,15,15v236.6Z" />
                      <g>
                        <path class="cls-2" d="M48.3,425h69.9v30H48.3v-30Z" />
                        <path class="cls-2" d="M156.3,425h69.9v30h-69.9v-30Z" />
                        <path class="cls-2" d="M264.4,425h69.9v30h-69.9v-30Z" />
                        <path class="cls-2" d="M372.4,425h69.9v30h-69.9v-30Z" />
                        <path class="cls-2" d="M125.6,273.8h-51.3c-13.8,0-25,11.2-25,25v40c0,13.8,11.2,25,25,25h51.3c13.8,0,25-11.2,25-25v-40c0-13.8-11.2-25-25-25h0ZM120.6,333.8h-41.3v-30h41.3v30Z" />
                      </g>
                    </svg>
                  </div>
                  <div className="col-12 mt-1">
                    <p>{t('Global_EasyCard')}</p>
                  </div>
                </div>
              </Button>
            </div>

            <div className="col-4 text-center">
              <Button className="btns-home-mobile-places" onClick={() => goToPage('/tokenlisting')}>
                <div className="row">
                  <div className="col-12 mt-1">
                    <svg id="_1_px" data-name="1 px" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 128 128" className="svg-icon">
                      <path class="cls-1" d="M113.8.1h-13.7c.5,1.7.7,1.3.7,3.1s-.2.4-.7,2.1h13.6v116.9H38.9l-6.2-15.2c0-2.1-1.7-3.8-3.8-3.8l-14.4-4.4V5.2h13.5c-.5-1.7-.7-.3-.7-2.1S27.5,1.7,28,0h-13.7C12.2,0,10.5,1.7,10.5,3.8v93.7c0,.9.4,2,1.1,2.7l24.9,24.9c.7.7,1.7,1.1,2.7,1.1h74.7c2.1,0,3.8-1.7,3.8-3.8V3.8c0-2-1.7-3.7-3.8-3.7h-.1Z" />
                      <path class="cls-2" d="M56.9,83.6c1.7,1.7,4.6,1.7,6.4,0l21.7-21.7c1.7-1.7,1.7-4.6,0-6.4h0c-1.7-1.7-4.6-1.7-6.4,0h0l-18.5,18.5-7.9-7.9c-1.7-1.7-4.6-1.7-6.4,0-1.7,1.7-1.7,4.6,0,6.4h0s11.2,11,11.2,11ZM53.3,40.3h24c4.5,0,8-3.6,8-8s-3.6-8-8-8h-4.1v-3c0-4.5-3.6-8-8-8s-8,3.6-8,8v3h-4c-4.5,0-8,3.6-8,8s3.6,8,8,8ZM53.3,29.7h24c1.4,0,2.6,1.1,2.6,2.6s-1.1,2.6-2.6,2.6h-24c-1.4,0-2.6-1.1-2.6-2.6s1.1-2.6,2.6-2.6Z" />
                    </svg>
                  </div>
                  <div className="col-12 mt-1">
                    <p>{t('Global_token_listing')}</p>
                  </div>
                </div>
              </Button>
            </div>

          </div>
          <div className="row justify-content-around">
            <div className="col-4 text-center">
              <Button className="btns-home-mobile-places" onClick={() => goToPage('/transfer')}>
                <div className="row">
                  <div className="col-12 mt-1">
                    <svg id="_1_px" data-name="1 px" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" className="svg-icon">
                      <path class="cls-1" d="M242.6,264h-92.4v48.9c0,3.1-1.8,5.9-4.6,7.3-2.8,1.3-6.2.9-8.5-1.2L2.9,205.2c-1.8-1.5-2.8-3.8-2.8-6.1s1-4.6,2.8-6.1l134.1-113.8c2.4-2,5.7-2.5,8.5-1.2s4.6,4.1,4.6,7.3v48.9h219.6c4.4,0,8,3.6,8,8s-3.6,8-8,8h-227.5c-4.4,0-8-3.6-8-8v-39.6L20.4,199.1l113.8,96.5v-39.6c0-4.4,3.6-8,8-8h99.8l.6,16Z" />
                      <path class="cls-2" d="M242.4,247.8h119.8v-48.9c0-3.1,1.8-5.9,4.6-7.3,2.8-1.3,6.2-.9,8.5,1.2l134.2,113.8c1.8,1.5,2.8,3.8,2.8,6.1h0c0,2.4-1,4.6-2.8,6.1l-134.1,113.8c-1.5,1.3-3.3,1.9-5.2,1.9s-2.3-.2-3.4-.7c-2.8-1.3-4.6-4.1-4.6-7.3v-48.9h-219.6c-4.4,0-8-3.6-8-8s3.6-8,8-8h227.6c4.4,0,8,3.6,8,8v39.6l113.8-96.5-113.8-96.5v39.6c0,4.4-3.6,8-8,8h-127.3l-.5-16Z" />
                    </svg>
                  </div>
                  <div className="col-12 mt-1">
                    <p>{t('Global_Transfer')}</p>
                  </div>
                </div>
              </Button>
            </div>

            <div className="col-4 text-center">
              <Button className="btns-home-mobile-places" onClick={() => goToPage('/easyguarantee')}>
                <div className="row">
                  <div className="col-12 mt-1">
                    <svg id="_1_px" data-name="1 px" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" className="svg-icon">
                      <path class="cls-1" d="M360.8,200.6v-74.7c.2-33.8-13.3-66.3-37.4-90C300.1,12.7,269.6,0,237.3,0h-1.6c-68.9.1-124.9,56.5-124.9,125.9v74.7c-26,3.1-45,24.9-45,51.3v167.8c0,28.5,22.8,52,51.3,52h237.5c28.5,0,51.3-23.5,51.3-52v-167.8c0-26.3-19.1-48.2-45.1-51.3ZM130.7,125.9h.1c0-58.4,47.1-106.2,105-106.2h.1c27.5-.1,53.9,10.8,73.4,30.2,20.3,20.1,31.6,47.5,31.4,76v74.8h-22v-74.8c.2-22.7-8.8-44.5-24.9-60.5-15.2-15.2-35.8-23.8-57.3-23.8h-.6c-46,0-83.2,37.8-83.2,84.2v74.9h-22v-74.8h0ZM298.8,125.9v74.8h-126v-74.8c0-35.4,28.2-64.2,63.2-64.2h.6c16.2,0,31.8,6.5,43.3,18,12.2,12.2,19.1,28.9,18.9,46.2ZM386.8,420h0c0,17.5-14.2,31.7-31.7,31.7H117.5c-17.5,0-31.7-14.2-31.7-31.7v-167.6c0-17.5,14.2-31.7,31.7-31.7h237.6c17.5,0,31.7,14.2,31.7,31.7v167.6h0Z" />
                      <path class="cls-2" d="M268.6,308c-4.4-15.5-18.5-26.1-34.6-26.1s-36.1,16.1-36.1,36.1,10.6,30.2,26.1,34.6v28c0,5.5,4.5,10,10,10s10-4.5,10-10v-28c19.1-5.5,30.2-25.5,24.6-44.6ZM234,334c-8.9,0-16.1-7.2-16.1-16.1s7.2-16.1,16.1-16.1,16.1,7.2,16.1,16.1c0,8.9-7.2,16.1-16.1,16.1Z" />
                    </svg>
                  </div>
                  <div className="col-12 mt-1">
                    <p>{t('Global_EasyGuarantee')}</p>
                  </div>
                </div>
              </Button>
            </div>

            <div className="col-4 text-center">

            </div>

          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-6 text-center">
              {!isLoggedIn() ? <Button className="btn-home-log-m" onClick={() => goToPage('/login')}><p>{t('Global_Login')}</p></Button> :
                <></>
              }
            </div>
            <div className="col-6 text-center">
              {!isLoggedIn() ? <Button className="btn-home-log-m" onClick={() => goToPage('/register')}><p>{t('Global_Register')}</p></Button> : <></>}
            </div>
            {isLoggedIn() ? <div className="col-12 text-center">
              <Button
                className="btn-home-log-m"
                onClick={() => dispatch({ type: "Logout" })}
              >
                <p>{t('Global_logout')}</p>
              </Button>
            </div> : <></>}
          </div>
        </div>
        <div className="container-fluid carousel-price-mobile">
          <div className="row mt-4">
            <div className="col-12" >
              <PriceSliderMobile />
            </div>
          </div>
        </div>
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default home;
