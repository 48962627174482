export const toFixed = (num, decimal = 6) => {
  if (num === null || num.toString() === null) {
    return 0;
  }
  try {
    var with2Decimals = num
      .toString()
      .match(new RegExp(`^-?\\d+(?:\\.\\d{0,${decimal}})?`))[0];
    let [num1, num2] = with2Decimals.split(".");
    if (num2 === undefined) {
      num2 = `${"0".repeat(decimal)}`;
      return `${num1}.${num2}`;
    } else if (num2.length !== decimal) {
      let diff = decimal - num2.length;
      num2 = `${num2}${"0".repeat(diff)}`;
      return `${num1}.${num2}`;
    } else {
      return with2Decimals;
    }
  } catch {
    return 0;
  }
};
