import React, { useEffect, useState } from "react";
import "./fee.scss";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../home/HeaderMobile";
import BottomNavigationMobile from "../home/BottomNavigationMobile";

function FeePage() {
  const { t } = useTranslation();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);
  if (pageWidth > 768) {
    return (
      <div className="fee-main">
        <div className="fees-module">
          <div className="container">
            <div className="col-12 row m-0">
              <div className="table-responsive dept-history-table mb-4">
                <div className="fee_top mt-5">
                  <h4>{t('Fee_title_1')}</h4>
                  <p className="mb-4">
                    {t('Fee_description_1')}
                  </p>
                  <h4>{t('Fee_title_2')}</h4>
                  <p className="mb-4">
                    {t('Fee_description_2')}
                  </p>
                  <h4>{t('Fee_title_3')}</h4>
                  <p>
                    <span className="font-weight-bold">{t('Fee_subtitle_3_1')}</span>
                    {t('Fee_description_3_1')}
                  </p>
                  <p>
                    <span className="font-weight-bold">{t('Global_Deposit')}:</span>
                    {t('Fee_description_3_2')}
                  </p>
                  <p>
                    <span className="font-weight-bold">{t('Global_Withdraw')}:</span>
                    {t('Fee_description_3_3')}
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <h5 className="mt-5 mb-3">{t('Global_Trade')} {t('Global_Fees')} </h5>
                </div>
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">{t('Fee_table_1_col_1')}</th>
                      <th scope="col">{t('Fee_table_1_col_2')}</th>
                      <th scope="col">{t('Fee_table_1_col_3')}</th>
                      <th scope="col">{t('Fee_table_1_col_4')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>VIP 0</td>
                      <td>{"<"}50 BTC</td>
                      <td>0.1 %</td>
                      <td>0.1 %</td>
                    </tr>
                    <tr>
                      <td>VIP 1</td>
                      <td>{">"}=50 BTC</td>
                      <td>0.09 %</td>
                      <td>0.1 %</td>
                    </tr>
                    <tr>
                      <td>VIP 2</td>
                      <td>{">"}=500 BTC</td>
                      <td>0.08 %</td>
                      <td>0.1 %</td>
                    </tr>
                    <tr>
                      <td>VIP 3</td>
                      <td>{">"}=1500 BTC</td>
                      <td>0.07 %</td>
                      <td>0.1 %</td>
                    </tr>
                    <tr>
                      <td>VIP 4</td>
                      <td>{">"}=4500 BTC</td>
                      <td>0.07 %</td>
                      <td>0.09 %</td>
                    </tr>
                    <tr>
                      <td>VIP 5</td>
                      <td>{">"}=10000 BTC</td>
                      <td>0.06 %</td>
                      <td>0.08 %</td>
                    </tr>
                  </tbody>
                </table>
                <div className="d-flex align-items-center">
                  <h5 className="mt-5 mb-3">{t('Global_Deposit')} & {t('Global_Withdraw')} {t('Global_Fees')}</h5>
                </div>
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th>{t('Fee_table_2_col_1')}</th>
                      <th>{t('Fee_table_2_col_2')}</th>
                      <th>{t('Global_Deposit')} {t('Global_Fee')}</th>
                      <th>{t('Global_Withdraw')} {t('Global_Fee')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>AAVE</th>
                      <th>Aave</th>
                      <th>0</th>
                      <th>0.0013</th>
                    </tr>
                    <tr>
                      <th>ADA</th>
                      <th>Cardano</th>
                      <th>0</th>
                      <th>0.18</th>
                    </tr>
                    <tr>
                      <th>ATOM</th>
                      <th>Cosmos</th>
                      <th>0</th>
                      <th>0.03</th>
                    </tr>
                    <tr>
                      <th>AVAX</th>
                      <th>Avalanche</th>
                      <th>0</th>
                      <th>0.02</th>
                    </tr>
                    <tr>
                      <th>BCH</th>
                      <th>Bitcoin Cash</th>
                      <th>0</th>
                      <th>0.0015</th>
                    </tr>
                    <tr>
                      <th>BNB</th>
                      <th>Binance Smart Chain </th>
                      <th>0</th>
                      <th>0.005</th>
                    </tr>
                    <tr>
                      <th>BTC</th>
                      <th>Bitcoin</th>
                      <th>0</th>
                      <th>0.0005</th>
                    </tr>
                    <tr>
                      <th>BUSD</th>
                      <th>Binance USD </th>
                      <th>0</th>
                      <th>0.85</th>
                    </tr>
                    <tr>
                      <th>DOGE</th>
                      <th>Dogecoin</th>
                      <th>0</th>
                      <th>1</th>
                    </tr>
                    <tr>
                      <th>EOS</th>
                      <th>EOS</th>
                      <th>0</th>
                      <th>0.2</th>
                    </tr>
                    <tr>
                      <th>ETC</th>
                      <th>Ethereum classNameic BSC</th>
                      <th>0</th>
                      <th>0.015</th>
                    </tr>
                    <tr>
                      <th>ETH</th>
                      <th>Ethereum BSC</th>
                      <th>0</th>
                      <th>0.002</th>
                    </tr>
                    <tr>
                      <th>ETH</th>
                      <th>Ethereum</th>
                      <th>0</th>
                      <th>0.002</th>
                    </tr>
                    <tr>
                      <th>FIL</th>
                      <th>Filecoin</th>
                      <th>0</th>
                      <th>0.01</th>
                    </tr>
                    {/* <tr>
                    <th>GBR</th>
                    <th>Gabur</th>
                    <th>0</th>
                    <th>7</th>
                  </tr> */}
                    <tr>
                      <th>LINK</th>
                      <th>Chainlink</th>
                      <th>0</th>
                      <th>0.012</th>
                    </tr>
                    <tr>
                      <th>LTC</th>
                      <th>Litecoin</th>
                      <th>0</th>
                      <th>0.003</th>
                    </tr>
                    <tr>
                      <th>MKR</th>
                      <th>Maker</th>
                      <th>0</th>
                      <th>0.002</th>
                    </tr>
                    <tr>
                      <th>NEAR</th>
                      <th>NEAR Protocol</th>
                      <th>0</th>
                      <th>0.15</th>
                    </tr>
                    <tr>
                      <th>TRX</th>
                      <th>Tron</th>
                      <th>0</th>
                      <th>2</th>
                    </tr>
                    <tr>
                      <th>USDT</th>
                      <th>Tether</th>
                      <th>0</th>
                      <th>0.85</th>
                    </tr>
                    <tr>
                      <th>ZEC</th>
                      <th>Zcash</th>
                      <th>0</th>
                      <th>0.01</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="fee-main-m">
          <div className="fees-module">
            <div className="container">
              <div className="col-12 row m-0">
                <div className="table-responsive dept-history-table mb-4">
                  <div className="fee_top mt-5">
                    <h4>{t('Fee_title_1')}</h4>
                    <p className="mb-4">
                      {t('Fee_description_1')}
                    </p>
                    <h4>{t('Fee_title_2')}</h4>
                    <p className="mb-4">
                      {t('Fee_description_2')}
                    </p>
                    <h4>{t('Fee_title_3')}</h4>
                    <p>
                      <span className="font-weight-bold">{t('Fee_subtitle_3_1')}</span>
                      {t('Fee_description_3_1')}
                    </p>
                    <p>
                      <span className="font-weight-bold">{t('Global_Deposit')}:</span>
                      {t('Fee_description_3_2')}
                    </p>
                    <p>
                      <span className="font-weight-bold">{t('Global_Withdraw')}:</span>
                      {t('Fee_description_3_3')}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <h5 className="mt-5 mb-3">{t('Global_Trade')} {t('Global_Fees')} </h5>
                  </div>
                  <table className="table">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">{t('Fee_table_1_col_1')}</th>
                        <th scope="col">{t('Fee_table_1_col_2')}</th>
                        <th scope="col">{t('Fee_table_1_col_3')}</th>
                        <th scope="col">{t('Fee_table_1_col_4')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>VIP 0</td>
                        <td>{"<"}50 BTC</td>
                        <td>0.1 %</td>
                        <td>0.1 %</td>
                      </tr>
                      <tr>
                        <td>VIP 1</td>
                        <td>{">"}=50 BTC</td>
                        <td>0.09 %</td>
                        <td>0.1 %</td>
                      </tr>
                      <tr>
                        <td>VIP 2</td>
                        <td>{">"}=500 BTC</td>
                        <td>0.08 %</td>
                        <td>0.1 %</td>
                      </tr>
                      <tr>
                        <td>VIP 3</td>
                        <td>{">"}=1500 BTC</td>
                        <td>0.07 %</td>
                        <td>0.1 %</td>
                      </tr>
                      <tr>
                        <td>VIP 4</td>
                        <td>{">"}=4500 BTC</td>
                        <td>0.07 %</td>
                        <td>0.09 %</td>
                      </tr>
                      <tr>
                        <td>VIP 5</td>
                        <td>{">"}=10000 BTC</td>
                        <td>0.06 %</td>
                        <td>0.08 %</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="d-flex align-items-center">
                    <h5 className="mt-5 mb-3">{t('Global_Deposit')} & {t('Global_Withdraw')} {t('Global_Fees')}</h5>
                  </div>
                  <table className="table">
                    <thead className="thead-dark">
                      <tr>
                        <th>{t('Fee_table_2_col_1')}</th>
                        <th>{t('Fee_table_2_col_2')}</th>
                        <th>{t('Global_Deposit')} {t('Global_Fee')}</th>
                        <th>{t('Global_Withdraw')} {t('Global_Fee')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>AAVE</th>
                        <th>Aave</th>
                        <th>0</th>
                        <th>0.0013</th>
                      </tr>
                      <tr>
                        <th>ADA</th>
                        <th>Cardano</th>
                        <th>0</th>
                        <th>0.18</th>
                      </tr>
                      <tr>
                        <th>ATOM</th>
                        <th>Cosmos</th>
                        <th>0</th>
                        <th>0.03</th>
                      </tr>
                      <tr>
                        <th>AVAX</th>
                        <th>Avalanche</th>
                        <th>0</th>
                        <th>0.02</th>
                      </tr>
                      <tr>
                        <th>BCH</th>
                        <th>Bitcoin Cash</th>
                        <th>0</th>
                        <th>0.0015</th>
                      </tr>
                      <tr>
                        <th>BNB</th>
                        <th>Binance Smart Chain </th>
                        <th>0</th>
                        <th>0.005</th>
                      </tr>
                      <tr>
                        <th>BTC</th>
                        <th>Bitcoin</th>
                        <th>0</th>
                        <th>0.0005</th>
                      </tr>
                      <tr>
                        <th>BUSD</th>
                        <th>Binance USD </th>
                        <th>0</th>
                        <th>0.85</th>
                      </tr>
                      <tr>
                        <th>DOGE</th>
                        <th>Dogecoin</th>
                        <th>0</th>
                        <th>1</th>
                      </tr>
                      <tr>
                        <th>EOS</th>
                        <th>EOS</th>
                        <th>0</th>
                        <th>0.2</th>
                      </tr>
                      <tr>
                        <th>ETC</th>
                        <th>Ethereum classNameic BSC</th>
                        <th>0</th>
                        <th>0.015</th>
                      </tr>
                      <tr>
                        <th>ETH</th>
                        <th>Ethereum BSC</th>
                        <th>0</th>
                        <th>0.002</th>
                      </tr>
                      <tr>
                        <th>ETH</th>
                        <th>Ethereum</th>
                        <th>0</th>
                        <th>0.002</th>
                      </tr>
                      <tr>
                        <th>FIL</th>
                        <th>Filecoin</th>
                        <th>0</th>
                        <th>0.01</th>
                      </tr>
                      {/* <tr>
                    <th>GBR</th>
                    <th>Gabur</th>
                    <th>0</th>
                    <th>7</th>
                  </tr> */}
                      <tr>
                        <th>LINK</th>
                        <th>Chainlink</th>
                        <th>0</th>
                        <th>0.012</th>
                      </tr>
                      <tr>
                        <th>LTC</th>
                        <th>Litecoin</th>
                        <th>0</th>
                        <th>0.003</th>
                      </tr>
                      <tr>
                        <th>MKR</th>
                        <th>Maker</th>
                        <th>0</th>
                        <th>0.002</th>
                      </tr>
                      <tr>
                        <th>NEAR</th>
                        <th>NEAR Protocol</th>
                        <th>0</th>
                        <th>0.15</th>
                      </tr>
                      <tr>
                        <th>TRX</th>
                        <th>Tron</th>
                        <th>0</th>
                        <th>2</th>
                      </tr>
                      <tr>
                        <th>USDT</th>
                        <th>Tether</th>
                        <th>0</th>
                        <th>0.85</th>
                      </tr>
                      <tr>
                        <th>ZEC</th>
                        <th>Zcash</th>
                        <th>0</th>
                        <th>0.01</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FeePage;
