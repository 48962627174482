import React from "react";

import WHtop from "./WHtop";
import Applybtn from "./Applybtn";
import WHstart3 from "./WHstart3";
import "./wheretobuy.scss";
import Botimagepart from "./Botimagepart";
function Wheretobuy() {
  return (
    <div className="wheretobuymain">
      <WHtop />
      <Applybtn />
      <WHstart3 />
      <Botimagepart />
    </div>
  );
}

export default Wheretobuy;
