import React from 'react'
import Trading from '../../assets/images/tradingview.png'
import Trading2 from '../../assets/images/trading2.svg'
import './tradingview.scss'
function TradingView() {
    return (
        <div>
            <div className="container-fluid tradingview">
                <div className="row justify-content-center mt-5 tradingview-m">
                    <h6>Trading View</h6>
                    <div className="col-12 text-center">
                        <img src={Trading} alt="" className='img-fluid' />
                    </div>
                    <div className="col-11 col-md-8 p-5">
                        <h5><strong>Easy Pro</strong> utilizes <strong>
                            <a
                                href="https://www.tradingview.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >TradingView</a>
                        </strong> technology to present trading data on charts. TradingView, a charting platform embraced by millions of traders and investors worldwide. It provides cutting-edge charting tools and a collaborative space for individuals immersed in the world of finance. Here, users can explore and understand <a
                            href="https://www.tradingview.com/economic-calendar/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >How to read the forex economic calendar</a>, converse, analyze, and equip themselves for trading endeavors.</h5>
                    </div>

                </div>
                <div className="row justify-content-center mb-4">
                    <div className="col-10 col-md-4">
                        <h5>Technical analysis, done right</h5>
                        <p>Our platform comes with hundreds of built-in indicators and strategies, intelligent drawing tools and tools for in-depth market analysis covering the most popular trading concepts</p>
                        <ul>
                            <li>
                                400+ built-in indicators and strategies
                            </li>
                            <li>
                                100,000+ public indicators
                            </li>
                            <li>
                                110+ smart drawing tools
                            </li>
                            <li>
                                Volume Profile indicators
                            </li>
                            <li>
                                Candlestick patterns recognition
                            </li>
                            <li>
                                Multi-timeframe analysis
                            </li>
                            <li>
                                Auto Chart Patterns
                            </li>
                        </ul>
                    </div>
                    <div className="col-10 col-md-4">
                        <h5>Bar Replay</h5>
                        <p>Watch how pricing data unfolded historically. Rewind markets and review at a speed and resolution you desire.</p>
                        <ul>
                            <li>
                                Simulated trading on historical data
                            </li>
                            <li>
                                4 replay speeds
                            </li>
                            <li>
                                Autoplay and step-by-step mode
                            </li>
                            <li>
                                You can also apply drawing objects and indicators
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-11 text-center">
                        <img src={Trading2} alt="" className='img-fluid' />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TradingView