import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function OrderHistoryTitle() {
  const { tradePair } = useParams();
  const [from, to] = tradePair.split("_");
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col d-flex justify-content-between">
      <div className="top-title-orderhistory-first">{t('Global_Price')}({to})</div>
        <div className="top-title-orderhistory-second">{t('Global_Amount')}({from})</div>
        <div className="top-title-orderhistory-third">{t('Global_Time')}</div>
      </div>
    </div>
  );
}

export default OrderHistoryTitle;
