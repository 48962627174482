import { useEffect, useRef } from "react";
import { widget } from "../../../../charting_library/";
import { UDFCompatibleDatafeed } from "../../../../datafeeds/udf/src/udf-compatible-datafeed.ts";
import { useState } from "react";
import { ItemNames, getStorage } from "../../../../utils/RSS";
function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export default function GBRChart() {
  const chartContainerRef = useRef();
  const onLoadScriptRef = useRef();
  const [chartTheme, setChartTheme] = useState("light");

  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const calculatedWidth = pageWidth - 50;
  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (
      getStorage(ItemNames.THEMES) === "light-theme" ||
      getStorage(ItemNames.THEMES) === "" ||
      getStorage(ItemNames.THEMES) === undefined
    ) {
      setChartTheme("light");
    } else {
      setChartTheme("dark");
    }
  });

  const defaultProps = {
    height: 367,
    width: { calculatedWidth },
    symbol: "GBR_USDT",
    interval: "1",
    datafeedUrl: "https://traapi.easyexchangepro.com/trade/chart",
    libraryPath: "/charting_library/",
    // chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: false,
    studiesOverrides: {},
    autosize: true,
    timezone: "Etc/UTC",

    style: "8",
    locale: "en",
    toolbar_bg: "#f1f3f6",
    enable_publishing: false,
  };

  useEffect(() => {
    const widgetOptions = {
      symbol: defaultProps.symbol,
      datafeed: new UDFCompatibleDatafeed(defaultProps.datafeedUrl),
      interval: defaultProps.interval,
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath,
      locale: getLanguageFromURL() || "en",
      disabled_features: ["use_localstorage_for_settings"],
      enabled_features: ["study_templates"],
      // charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: false,
      studies_overrides: defaultProps.studiesOverrides,
      height: 367,
      width: 460,
      theme: chartTheme,
      drawing_access: false,
      wick: true,
      style: 8,
      logo: {
        image: "https://s3p.easyexchangepro.com/public/logo-chart.png",
        link: "https://easyexchangepro.com/",
      },
      toolbar_bg: "transparent",
      disabled_features: [
        "use_localstorage_for_settings",
        "study_templates",
        // "header_symbol_search",
        "timeframes_toolbar",
        "dome_widget",
        "display_market_status",
        // "display_header_toolbar_chart",
        "header_compare",
        "header_undo_redo",
        "compare_symbol",
        "header_settings",
        "study_dialog_search_control",
        "caption_buttons_text_if_possible",
        "border_around_the_chart",
      ],
      overrides: {
        // "paneProperties.background": "#303034",
        //"paneProperties.vertGridProperties.color": "#454545",
        //"paneProperties.horzGridProperties.color": "#454545",
        "symbolWatermarkProperties.transparency": 90,
        "scalesProperties.textColor": "#8f98ad",
        "scalesProperties.showRightScale": true,
        "symbolWatermarkProperties.color": "rgba(0, 0, 0, 0)",
        "paneProperties.vertGridProperties.color": "#454545",
        "paneProperties.horzGridProperties.color": "#454545",
        "paneProperties.crossHairProperties.color": "#454545",
        "paneProperties.crossHairProperties.style": 2,
        "mainSeriesProperties.style": 8,
        "mainSeriesProperties.showCountdown": false,
        "scalesProperties.showSeriesLastValue": true,
        "mainSeriesProperties.visible": true,
        "mainSeriesProperties.showPriceLine": true,
        "mainSeriesProperties.priceLineWidth": 1,
        "mainSeriesProperties.lockScale": false,
        "mainSeriesProperties.minTick": "default",
        "mainSeriesProperties.extendedHours": false,
        volumePaneSize: "small",
        "paneProperties.topMargin": 5,
        "paneProperties.bottomMargin": 5,
        "paneProperties.leftAxisProperties.autoScale": true,
        "paneProperties.leftAxisProperties.autoScaleDisabled": false,
        "paneProperties.leftAxisProperties.percentage": false,
        "paneProperties.leftAxisProperties.percentageDisabled": false,
        "paneProperties.leftAxisProperties.log": false,
        "paneProperties.leftAxisProperties.logDisabled": false,
        "paneProperties.leftAxisProperties.alignLabels": true,
        "paneProperties.legendProperties.showStudyArguments": true,
        "paneProperties.legendProperties.showStudyTitles": true,
        "paneProperties.legendProperties.showStudyValues": true,
        "paneProperties.legendProperties.showSeriesTitle": false,
        "paneProperties.legendProperties.showSeriesOHLC": true,
        "paneProperties.legendProperties.showLegend": true,
        "scalesProperties.showLeftScale": true,
        "scalesProperties.showRightScale": true,
        // "scalesProperties.backgroundColor": "#20334d",
        "scalesProperties.lineColor": "#46587b",
        "scalesProperties.textColor": "#8f98ad",
        "scalesProperties.scaleSeriesOnly": false,
        "mainSeriesProperties.priceAxisProperties.autoScale": true,
        "mainSeriesProperties.priceAxisProperties.autoScaleDisabled": false,
        "mainSeriesProperties.priceAxisProperties.percentage": false,
        "mainSeriesProperties.priceAxisProperties.percentageDisabled": false,
        "mainSeriesProperties.priceAxisProperties.log": false,
        "mainSeriesProperties.priceAxisProperties.logDisabled": false,
        "mainSeriesProperties.candleStyle.upColor": "#3fcfb4",
        "mainSeriesProperties.candleStyle.downColor": "#fe4761",
        "mainSeriesProperties.candleStyle.drawWick": true,
        "mainSeriesProperties.candleStyle.drawBorder": true,
        "mainSeriesProperties.candleStyle.borderColor": "#3fcfb4",
        "mainSeriesProperties.candleStyle.borderUpColor": "#3fcfb4",
        "mainSeriesProperties.candleStyle.borderDownColor": "#fe4761",
        "mainSeriesProperties.candleStyle.wickColor": "#737375",
        "mainSeriesProperties.candleStyle.wickUpColor": "#3fcfb4",
        "mainSeriesProperties.candleStyle.wickDownColor": "#fe4761",
        "mainSeriesProperties.candleStyle.barColorsOnPrevClose": false,
        "mainSeriesProperties.hollowCandleStyle.upColor": "#3fcfb4",
        "mainSeriesProperties.hollowCandleStyle.downColor": "#fe4761",
        "mainSeriesProperties.hollowCandleStyle.drawWick": true,
        "mainSeriesProperties.hollowCandleStyle.drawBorder": true,
        "mainSeriesProperties.hollowCandleStyle.borderColor": "#3fcfb4",
        "mainSeriesProperties.hollowCandleStyle.borderUpColor": "#3fcfb4",
        "mainSeriesProperties.hollowCandleStyle.borderDownColor": "#fe4761",
        "mainSeriesProperties.hollowCandleStyle.wickColor": "#737375",
        "mainSeriesProperties.hollowCandleStyle.wickUpColor": "#3fcfb4",
        "mainSeriesProperties.hollowCandleStyle.wickDownColor": "#fe4761",
        "mainSeriesProperties.haStyle.upColor": "#3fcfb4",
        "mainSeriesProperties.haStyle.downColor": "#fe4761",
        "mainSeriesProperties.haStyle.drawWick": true,
        "mainSeriesProperties.haStyle.drawBorder": true,
        "mainSeriesProperties.haStyle.borderColor": "#3fcfb4",
        "mainSeriesProperties.haStyle.borderUpColor": "#3fcfb4",
        "mainSeriesProperties.haStyle.borderDownColor": "#fe4761",
        "mainSeriesProperties.haStyle.wickColor": "#737375",
        "mainSeriesProperties.haStyle.wickUpColor": "#3fcfb4",
        "mainSeriesProperties.haStyle.wickDownColor": "#fe4761",
        "mainSeriesProperties.haStyle.barColorsOnPrevClose": false,
        "mainSeriesProperties.barStyle.upColor": "#3fcfb4",
        "mainSeriesProperties.barStyle.downColor": "#fe4761",
        "mainSeriesProperties.barStyle.barColorsOnPrevClose": false,
        "mainSeriesProperties.barStyle.dontDrawOpen": false,
        "mainSeriesProperties.lineStyle.color": "#0cbef3",
        "mainSeriesProperties.lineStyle.linestyle": 0,
        "mainSeriesProperties.lineStyle.linewidth": 1,
        "mainSeriesProperties.lineStyle.priceSource": "close",
        "mainSeriesProperties.areaStyle.color1": "#0cbef3",
        "mainSeriesProperties.areaStyle.color2": "#0098c4",
        "mainSeriesProperties.areaStyle.linecolor": "#0cbef3",
        "mainSeriesProperties.areaStyle.linestyle": 0,
        "mainSeriesProperties.areaStyle.linewidth": 1,
        "mainSeriesProperties.areaStyle.priceSource": "close",
        "mainSeriesProperties.areaStyle.transparency": 80,
      },
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to show a notification popup");
        button.classList.add("apply-common-tooltip");
        button.addEventListener("click", () =>
          tvWidget.showNoticeDialog({
            title: "Notification",
            body: "TradingView Charting Library API works correctly",
            callback: () => {
              console.log("Noticed!");
            },
          })
        );

        button.innerHTML = "Check API";
      });
    });

    return () => {
      tvWidget.remove();
    };
  }, [chartTheme]);

  return <div ref={chartContainerRef} className={"TVChartContainer"} />;
}
