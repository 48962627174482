import moment from "moment/moment";
import React from "react";
import { UserContext } from "../../../../contexts/providers/user";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { toFixed } from "../../../../utils/math";

function EachOrderHistory({ info }) {
  const { state } = useContext(UserContext);
  const { tradePair } = useParams();
  const [from, to] = tradePair.split("_");

  const colorInTrade = () => {
    if (info.cls === "text-danger") {
      return "textdanger";
    } else if (info.cls === "text-success") {
      return "textsuccess";
    }
  };

  return (
    <div className="row">
      <div className="col d-flex justify-content-between">
        <div className={"order-price-in-orderhistory " + colorInTrade()}>
          {toFixed(
            parseFloat(info.price),
            state.currency.details[from].price_decimal || 6
          )}
        </div>
        <div className="order-amount-in-orderhistory">
          {toFixed(
            parseFloat(info.amount),
            state.currency.details[from].amount_decimal || 6
          )}
        </div>
        <div className="order-time-in-orderhistory">
          {moment(info.ctime).format("HH:mm:ss")}
        </div>
      </div>
    </div>
  );
}

export default EachOrderHistory;
