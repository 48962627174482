import * as React from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import "./notification.scss";
import { getNotification } from "../../utils/Api";
import { useState } from "react";
import { useEffect } from "react";
import "./Notificationpage";
import moment from "moment";
import { isLoggedIn } from "../../utils/RSS";
export default function Notificationtop() {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/notification`;
    navigate(path);
  };

  const [notificationList, setNotificationList] = useState({
    notification_count: 0,
    notification_data: [],
  });
  const errorCatch = (error) => {};

  useEffect(() => {
    if (isLoggedIn() === true) {
      getNotification(setNotificationList, errorCatch);
    }
  }, []);

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <div className="icon-round">
            <IconButton
              className="icon-round"
              size="medium"
              // color="info"
              style={{ color: "#38E54D" }}
              aria-label="profile"
              {...bindTrigger(popupState)}
            >
              <NotificationsActiveRoundedIcon className="icon-round" />
            </IconButton>
          </div>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{ style: { width: "400px", height: "400px" } }}
          >
            <div className="mt-4">
              <ul>
                {notificationList?.notification_data?.map((item, index) => (
                  <li className="notif-li" key={`notificationList${index}`}>
                    {item.message} Time:{" "}
                    <span className="time-color">
                      {" "}
                      {moment(item.created_at).format("YYYY/MM/DD-LT")}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
