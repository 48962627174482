import React, { useEffect, useState } from "react";
import { getReferralHistory } from "../../utils/Api";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import "./profile.scss";
import { useTranslation } from "react-i18next";

function CommissionPage() {
  const [referralHistoryList, setReferralHistoryList] = useState([]);
  const errorCatch = (error) => {};

  useEffect(() => {
    if (referralHistoryList.length === 0) {
      getReferralHistory(setReferralHistoryList, errorCatch);
    }
  }, []);
  const { t } = useTranslation();

  return (
    <div className="commission-main">
      <div className="container commission-table">
        <div className="row">
          <div className="col">
            <TableContainer component={Paper} className="commission-table-data">
              <Table
                sx={{ minWidth: 500, maxHeight: 600 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{t('Profile_User')}</TableCell>
                    <TableCell align="left">{t('Global_Amount')}</TableCell>
                    <TableCell align="left">{t('Profile_Currency')}</TableCell>
                    <TableCell align="left">{t('Global_Time')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {referralHistoryList.slice(0, 50).map((item) => (
                    <TableRow
                      key={item.created_at}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{item.refer_name}</TableCell>
                      <TableCell align="left">{item.amount}</TableCell>
                      <TableCell align="left">{item.currency}</TableCell>
                      <TableCell align="left">
                        {moment(item.created_at).format("YYYY/MM/DD")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommissionPage;
