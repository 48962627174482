import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function UserOrderTitle() {
  const { tradePair } = useParams();
  const [from, to] = tradePair.split("_");
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col d-flex justify-content-between p-0 ms-2">
        <div className="top-title-orderlist-first">{t('Global_Price')} {to}</div>
        <div className="top-title-orderlist-second">{t('Global_Amount')} {from}</div>
        <div className="top-title-orderlist-third pe-2">{t('Global_Total')} {to}</div>
      </div>
    </div>
  );
}

export default UserOrderTitle;
