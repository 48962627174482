import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import GBRChart from "./TradeGBRChartC";
import TradeMainChart from "./TradeMainChartC";

function TradeChart() {
  const [showGBRChart, setShowGBRChart] = useState(false);
  const { tradePair } = useParams();
  // console.log(tradePair);
  useEffect(() => {
    if (tradePair === "GBR_USDT") {
      setShowGBRChart(true);
    } else {
      setShowGBRChart(false);
    }
  }, [tradePair]);
  return (
    <div>
      {showGBRChart ? <GBRChart /> : <TradeMainChart tradePair={tradePair} />}
    </div>
  );
}

export default TradeChart;
