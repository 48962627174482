import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../contexts/providers/user";
import Limit from "./Limit";
import Market from "./Market";
import StopLimit from "./StopLimit";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BuySellModule() {
  const [value, setValue] = useState(0);
  const { tradePair } = useParams();
  const [from, to] = tradePair.split("_");

  const { stateIO } = React.useContext(UserContext);

  const getBalance = (symbol) => {
    return stateIO.user_balances[symbol] || 0;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { t } = useTranslation();

  return (
    <div className="buy-sell-module-main">
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={<Typography fontSize={12}>{t('Global_Limit')}</Typography>}
              {...a11yProps(0)}
            />
            <Tab
              label={<Typography fontSize={12}>{t('Trade_Market')}</Typography>}
              {...a11yProps(1)}
            />
            <Tab
              label={<Typography fontSize={12}>{t('Global_Stop')}/{t('Global_Limit')}</Typography>}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className="row buy-sell-module">
            <div className="col col-sm-6 buy-side-module">
              <Limit
                last_price={stateIO?.details.price}
                side={"buy"}
                from={from}
                balance_from={getBalance(from)}
                balance_to={getBalance(to)}
                to={to}
              />

              {/* <LimitBuy from={from} balance_from={getBalance(from)} balance_to={getBalance(to)} to={to} /> */}
            </div>
            <div className="col col-md-6 sell-side-module">
              <Limit
                last_price={stateIO?.details.price}
                side={"sell"}
                from={from}
                balance_from={getBalance(from)}
                balance_to={getBalance(to)}
                to={to}
              />
              {/* <LimitSell from={from} balance_from={getBalance(from)} balance_to={getBalance(to)} to={to}/> */}
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="row buy-sell-module">
            <div className="col col-sm-6 buy-side-module">
              <Market
                last_price={stateIO?.details.price}
                side={"buy"}
                from={from}
                balance_from={getBalance(from)}
                balance_to={getBalance(to)}
                to={to}
              />
            </div>
            <div className="col col-md-6 sell-side-module">
              <Market
                last_price={stateIO?.details.price}
                side={"sell"}
                from={from}
                balance_from={getBalance(from)}
                balance_to={getBalance(to)}
                to={to}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="row buy-sell-module">
            <div className="col col-sm-6 buy-side-module">
              <StopLimit
                side="buy"
                from={from}
                balance_from={getBalance(from)}
                balance_to={getBalance(to)}
                to={to}
              />
            </div>
            <div className="col col-md-6 sell-side-module">
              <StopLimit
                side="sell"
                from={from}
                balance_from={getBalance(from)}
                balance_to={getBalance(to)}
                to={to}
              />
            </div>
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}
