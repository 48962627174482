import React, { useEffect, useState } from "react";

import EGTop from "./EGTop";
import EGtexts from "./EGtexts";
import "./easyguarantee.scss";
import EG3steps from "./EG3steps";
import EGbot from "./EGbot";
import HeaderMobile from "../../home/HeaderMobile";
import BottomNavigationMobile from "../../home/BottomNavigationMobile";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
function Easyguarantee() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const updatePageWidth = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);

  const navigate = useNavigate()
  const goToPage = (path) => {
    navigate(path)
  }

  if (pageWidth > 768) {
    return (
      <div className="easyguarantee-main">
        <EGTop />
        <EGtexts />
        <EG3steps />
        <EGbot />
      </div>
    );
  } else if (pageWidth <= 768) {
    return (
      <>
        <HeaderMobile />
        <div className="container-fluid bottom-navigation">
          <div className="row">
            <div className="col-12">
              <BottomNavigationMobile />
            </div>
          </div>
        </div>
        <div className="easyguarantee-main">
          <div style={{ marginTop: 40, marginBottom: 50 }}>
            <EGTop />
            <EGtexts />
            <EG3steps />
            <EGbot />
            <div className="container-fluid">
              <div className="row" style={{ marginTop: 20, paddingBottom: 38 }}>
                <div className="col-6">
                  <Button className="btn-home-log-m" onClick={() => goToPage('/easyguaranteefaq')}>FAQ</Button>
                </div>
                <div className="col-6 text-end">
                  <Button className="btn-home-log-m" onClick={() => goToPage('/easyguaranteeform')}>Form</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Easyguarantee;
