import React from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../../contexts/providers/user";
import { useContext } from "react";
import { toFixed } from "../../../../utils/math";

function UserOrder({ order, side }) {
  const { state } = useContext(UserContext);
  const { tradePair } = useParams();
  const [from, to] = tradePair.split("_");
  return (
    <div className="row">
      <div className="col d-flex justify-content-between">
        <div
          className={
            "order-price-in-orderlist " +
            (side === "sell" ? "textdanger" : "textsuccess")
          }
        >
          {toFixed(
            parseFloat(order.price),
            state.currency.details[from].price_decimal || 6
          )}
        </div>
        <div className="order-amount-in-orderlist">
          {toFixed(
            parseFloat(order.amount),
            state.currency.details[from].amount_decimal || 6
          )}
        </div>
        <div className="order-total-in-orderlist">
          {toFixed(
            parseFloat(`${order.price * order.amount}`),
            state.currency.details[from].total_decimal || 6
          )}
        </div>
      </div>
    </div>
  );
}

export default UserOrder;
